import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'st-checkbox-toggle-v2',
  templateUrl: './checkbox-toggle.component.html',
  styleUrls: ['./checkbox-toggle.component.scss']
})
export class CheckboxToggleComponent implements OnInit, OnDestroy, OnChanges {
  @Input() checked = false;
  @Input() readOnly = false;
  @Input() disabledText = 'Disabled';
  @Input() enabledText = 'Enabled';
  @Input() showText = true;
  @Input() size: 'small'|'large' = 'large';

  checkbox;

  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    this.checkbox = this.checked;
  }

  ngOnDestroy() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked && changes.checked.previousValue !== changes.checked.currentValue) {
      this.checkbox = changes.checked.currentValue;
      this.cd.detectChanges();
    }
  }

  onChanged() {
    this.changed.emit(this.checkbox);
  }

  toggle(value: boolean) {
    if (this.readOnly) {
      return;
    }

    this.checkbox = value;
    this.onChanged();
  }
}
