import { ModuleWithProviders, NgModule } from '@angular/core';
import { SentryTrackingService } from './sentry.service';
import { RudderTrackingService } from './tracking.service';
import { SmartLookTrackingService } from '@app/shared/tracking/smartlook.service';
import { C6AttributionService } from '@app/shared/tracking/c6-attribution.service';

@NgModule()
export class TrackingModule {
  static forRoot(): ModuleWithProviders<TrackingModule> {
    return {
      ngModule: TrackingModule,
      providers: [RudderTrackingService, SentryTrackingService, SmartLookTrackingService, C6AttributionService],
    };
  }
}
