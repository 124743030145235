<div class="dropdown-wrapper"
  [class.dropdown-wrapper--up]="dropdownPosition === 'up'"
  [class.dropdown-wrapper--down]="dropdownPosition === 'down'"
  [class.dropdown-wrapper--opened]="opened"
  [class.dropdown-wrapper--transparent]="color === 'transparent'"
  stOffClick
  [class.dropdown-wrapper--disabled]="disabled"
  (clickOutside)="opened = false">
  <div class="dropdown__title" (click)="toggleDropdown()">
    <div class="dropdown__title-text" *ngIf="selectedOption">{{selectedOption.label || selectedOption}}</div>
    <div *ngIf="!selectedOption" class="dropdown__title-text placeholder" i18n>SELECT</div>
    <div class="dropdown__title-icon"></div>
  </div>
  <div class="dropdown"
    [class.dropdown--up]="dropdownPosition === 'up'"
    [class.dropdown--down]="dropdownPosition === 'down'">
    <div class="dropdown__options">
      <div class="dropdown__option" [ngClass]="{'dropdown__option--disabled': option.disabled}" (click)="selectOption(option)" *ngFor="let option of options"
            [class.dropdown__option--selected]="option === selectedOption">
            {{option.label || option}}
            <span class="dropdown__disabled-option-tag" *ngIf="option.disabled && disabledOptionText">{{disabledOptionText}}</span>
      </div>
    </div>
  </div>
</div>
