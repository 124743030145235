import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';
import { ApiTokenActions } from '@app/shared/data/api-token/api-token.actions';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';
import { AppState } from '@app/shared/data/app-state.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'st-api-keys-rotate-warning-modal',
  templateUrl: './api-keys-rotate-warning-modal.component.html',
  styleUrls: ['./api-keys-rotate-warning-modal.component.scss'],
})
export class ApiKeysRotateWarningModalComponent implements OnInit, OnDestroy {
  apiToken: ApiToken;
  loadingToken: boolean;

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private ngRedux: NgRedux<AppState>,
  ) { }

  ngOnInit() {
    this.ngRedux.select(['tokens', 'currentToken'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((token: ApiToken) => {
        this.apiToken = token;
      });

    this.ngRedux.select(['tokens', 'loadingCurrentToken'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading: boolean) => {
        this.loadingToken = loading;
      });
  }

  ngOnDestroy() {
    this.ngRedux.dispatch(ApiTokenActions.clearSingleToken());
    this.destroy$.next();
    this.destroy$.complete();
  }

  onRotate() {
    this.ngRedux.dispatch(ApiTokenActions.revoke(this.apiToken));
    this.modal.close();
  }
}
