import { BaseModel } from '@app/shared/data/base.models';
import { Credential } from '@app/shared/data/credential/credential.models';
import { User } from '@app/shared/data/user/user.models';

export class SubAccount extends BaseModel {
  static className = 'SubAccount';

  id: number;
  accepted_user_id?: number;
  email: string;
  first_name?: string;
  last_name?: string;
  created_at?: string;
  accepted_at?: string;

  credential?: Credential;
  permissions?: string[];

  public isDeleted() {
    return this.meta('deleted') === true;
  }

  get accepted_user() {
    if (! Boolean(this.accepted_user_id)) {
      return null;
    }

    return new User({
      id: this.accepted_user_id,
      first_name: this.first_name,
      last_name: this.last_name,
    });
  }

  public setDeleted() {
    const tmp = this.clone(SubAccount);
    tmp._meta['deleted'] = true;
    return tmp;
  }
}
