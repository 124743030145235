
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { BaseAPI } from "@app/shared/data/base.api";
import { Plan } from "@app/shared/data/plan/plan.models";

@Injectable()
export class PlanAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public load(params = {}): Observable<Collection<Plan>> {
    return this.http.get(this.constructFullUrl('api/billing/plans'), { params: params }).pipe(
      map(data => Plan.collect<Plan>(data)));
  }
}
