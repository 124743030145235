import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewRef
} from '@angular/core';
import { ICheckboxChanged } from '@app/shared/layout/v2/forms/checkbox/checkbox.component';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { Subject } from 'rxjs';
import { ISearchedProduct } from '@app/shared/data/browse-products/browse-products.models';
import { SortByEnum, SortDirectionEnum, SortOpts } from '@app/client/v2/amazon/browse-products.service';

@Component({
  selector: 'st-browse-products-table',
  templateUrl: './browse-products-table.component.html',
  styleUrls: ['./browse-products-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowseProductsTableComponent implements OnInit, OnDestroy {

  @Input() products: ISearchedProduct[];
  @Input() isSearching: boolean;
  @Input() error: string;
  @Input() productSelectLimit: number|null = null;
  @Input() preselectedProductsAsins: string[];

  numberOfSelected: number = 0;
  /**
   * Event emitted when the product is selected.
   */
  @Output() productsSelected: EventEmitter<ISearchedProduct[]> = new EventEmitter();

  @Output() sortChange: EventEmitter<SortOpts> = new EventEmitter<SortOpts>();

  sortByField = SortByEnum;
  sortDirection: SortDirectionEnum;

  sortOpts: SortOpts;

  maxTableHeight: number = 1000;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private cd: ChangeDetectorRef,
    private rudderTracking: RudderTrackingService,
  ) {
  }

  ngOnInit() {
    this.emitSelectedProducts();
    setTimeout(() => {
      const modal = document.getElementsByClassName('modal')[0];
      this.maxTableHeight = modal?.clientHeight - 420;
    },0);
  }

  selectProduct(event: ICheckboxChanged, product: ISearchedProduct) {
    product.checked = event.checked;

    if (event.checked) {
      this.rudderTracking.trackEvent('Browse Amazon - Product Checked');
    } else {
      this.rudderTracking.trackEvent('Browse Amazon - Product Unchecked');
    }

    this.emitSelectedProducts();
    if (this.canDetectChanges) {
      this.cd.detectChanges();
    }
  }

  public isDisabledToSelect(isChecked: boolean = false) {
    return !isChecked && this.productSelectLimit !== null && this.productSelectLimit <= this.numberOfSelected;
  }

  selectAllBrowseCompetitorProducts(event: ICheckboxChanged) {
    if (!this.products) {
      return;
    }

    this.products.forEach((product: ISearchedProduct) => product.checked = event.checked);

    if (event.checked) {
      this.rudderTracking.trackEvent('Browse Products - Product Unchecked');
    } else {
      this.rudderTracking.trackEvent('Browse Products - Product Unchecked');
    }

    this.emitSelectedProducts();
    if (this.canDetectChanges) {
      this.cd.detectChanges();
    }
  }

  emitSortChange(sort: SortByEnum) {
    this.sortDirection = this.sortDirection === SortDirectionEnum.ASC ? SortDirectionEnum.DESC : SortDirectionEnum.ASC;
    this.sortChange.emit({
      field: sort,
      direction: this.sortDirection
    });
  }

  private emitSelectedProducts() {
    const selected = this.products.filter((product: ISearchedProduct) => product.checked === true);
    this.productsSelected.emit(selected);

    this.numberOfSelected = this.preselectedProductsAsins?.length + selected.length;
  }

  get areAllProductsSelected(): boolean {
    return this.products && this.products.length > 0 && this.products.every((product) => product.checked);
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
