<st-dialog #dialog="dialog">
  <div dialog-header class="header">
    <div class="question">
      <span>
        <span *ngIf="showIcon" class="alert-icon"></span>
        <span [innerHtml]="title"></span>
      </span>
      <span class="close-icon" (click)="cancel()">
        <st-icon [width]="15" [height]="15" icon="cross-v2"></st-icon>
      </span>
    </div>
  </div>
  <div dialog-content>
    <div class="confirm-text"  [innerHTML]="message"></div>

    <div class="warning-text" *ngIf="warningText" [innerHtml]="warningText"></div>
    <div class="confirm-text" *ngIf="confirmText" [innerHtml]="confirmText"></div>
  </div>
  <div dialog-footer class="footer">
    <st-button-v2 (clicked)="cancel()" color="v2_red_empty" label="{{noButtonText | uppercase}}"></st-button-v2>
    <st-button-v2 (clicked)="confirm()" color="v2_red" label="{{yesButtonText | uppercase}}"></st-button-v2>
  </div>
</st-dialog>
