import { Component, Input } from "@angular/core";
import { Options } from "redux-observable";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-product-graph-item',
  templateUrl: './product-graph.item.component.html',
  styleUrls: ['./product-graph.item.component.scss']
})
export class ProductGraphItemTableItemComponent {
  @Input() item: V2TableDataRecord;

  chartOpts: Options | any;

  public lineOptions = {
    marker: {
      enabled: true,
      radius: 3
    }
  };

  ngOnInit() {
    const asin = {
      rank: this.getAsinMap(this.item.value?.keywordGraph?.rank),
      impressions: this.getAsinMap(this.item.value?.keywordGraph?.impressions),
    }
    this.chartOpts = {
      xAxis: {range: 1 * 30 * 24 * 3600 * 1000},
      tooltip: {
        valueDecimals: 2,
        formatter: function () {
          switch(this.points[0].series.name) {
            case "Rank": {
              return `Rank: <b>${this.y}</b><br>ASIN: <b>${asin.rank[this.x]}</b>`;
            }
            case "Impressions": {
              return `Impressions: <b>${this.y}</b><br>ASIN: <b>${asin.impressions[this.x]}</b>`;
            }
            default: return this.y.toString();
          }
        }
      },
    };
  }

  getAsinMap(array) {
    if (!array) {
      return {};
    }

    return array.reduce((prev,curr) => {
      return Object.assign(prev, {
        [curr[0]]: curr[2]
      })
    },{})
  }
}
