export enum ProductActivityStatusEnum {
  INACTIVE = 0,
  ACTIVE = 1,
  PRIORITY = 2,
}

export interface IProductManagerBadge {
  type: string;
  value: boolean;
}

export interface IProductManagerPrice {
  amount: number;
  currency_code: string;
}

export interface IProductManagerProduct {
  asin: string;
  title: string;
  image_url: string;
  keywords_count: number;
  sales_rank: number;
  sales_rank_change: number;
  last_synced_at: string;
  min_price: IProductManagerPrice;
  max_price: IProductManagerPrice;
  badges: IProductManagerBadge[];
  tags?: string[];
  detailsOpened?: boolean;
  checked?: boolean;
  resettingProduct?: boolean;
  activeTab?: 'graph'|'details';
  status?: ProductActivityStatusEnum;
  index_check_at: string|null;
  index_check_finished_at: string;
  is_foreign: boolean;
}

export interface IProductManagerOtherProduct extends IProductManagerProduct { }

export interface IProductManagerProductsResponse {
  data: IProductManagerProduct[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    per_page: number;
    path: string;
    total: number;
    to: number;
  };
  isForeignFetch: boolean;
}

export interface IProductManagerKeywordSFRProduct {
  position: number;
  asin: string;
  title: string;
  click_share: number;
  conversion_share: number;
}

export interface IProductManagerKeywordSFRData {
  search_frequency_rank: number;
  products: IProductManagerKeywordSFRProduct[];
}

export interface IProductManagerState {
  activeProduct: IProductManagerProduct;
  productsState: {
    products: IProductManagerProduct[];
    otherProducts: IProductManagerProduct[];
    current_page: number;
    path: string;
    per_page: number;
    to: number;
    from: number;
    total: number;
    foreign_total: number;
    last_page: number;
    closeAddProductsModal: boolean;
  };
  productVariationsState: {
    products: IProductManagerVariation[];
    current_page: number;
    path: string;
    per_page: number;
    to: number;
    from: number;
    total: number;
    last_page: number;
  };
  graphData: {
    dataPoints: number[][];
  };
  competitorsList: {
    customerProducts: IProductManagerVariation[],
    loadingCustomerProducts: boolean;
    customerProductsError: boolean;
  };
  listingManager: {
    allData: IProductManagerDraftListing[];
    draft: IProductManagerDraft;
    listings: IProductManagerListing[];
  };
  keywords: {
    phrases: IProductManagerKeyword[];
    words: any;
    progress: number;
    sfrGraphData: IProductManagerKeywordSFRProduct[];
  };
  keywordGraph: {
    rank: any[][],
    impressions: any[][],
  };
  syncingProductsWithAmazon: boolean;
  loadingProducts: boolean;
  loadingProductVariations: boolean;
  loadingGraphData: boolean;
  loadingSingleProduct: boolean;
  loadingDraftsAndListings: boolean;
  loadingKeywords: boolean;
  loadingKeywordsWS: boolean;
  loadingKeywordsGraph: boolean;
  addingCompetitorsProducts: boolean;
  removingCompetitorsProducts: boolean;
  updatingKeywordsPriority: boolean;
  settingProductStatus: boolean;
  setProductStatusError: boolean;
  settingProductTags: boolean;
  setProductTagsError: boolean;
  removingProductTags: boolean;
  removingProductTagsError: boolean;
  removingKeyword: boolean;
  upgradePlanBannerVisible: boolean;
  upgradePlanBannerTitle: string;
  upgradePlanBannerMessage: string;
  loadingAvailableTagFilters: boolean;
  availableTagFilters: any[];
  deletingProduct: boolean;
  deletingProductError: any;
  deletingProductSuccess: boolean;
}

export interface IProductManagerVariation {
  asin: string;
  sku?: string;
  title: string;
  image_url: string;
  reviews_count: number;
  sales_rank: number;
  price: {
    amount: number;
    currency_code: string;
  };
  checked?: boolean;
}

export interface IAddedCompetitorProduct extends IProductManagerVariation {
  error: {
    message: string,
    error_code: string,
  };
}

export interface IProductManagerVariationsResponse {
  data: IProductManagerVariation[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    per_page: number;
    path: string;
    total: number;
    to: number;
  };
}

export interface IProductManagerGraphDataValues {
  date_time: string;
  value: number;
}

export interface IProductManagerGraphData {
  type: 'bsr';
  values: IProductManagerGraphDataValues[];
}

export interface IProductManagerGraphDataResponse {
  data: IProductManagerGraphData;
}

export interface IProductManagerDraft {
  sku?: string;
  title: string;
  description: string;
  search_terms: string;
  bullet_point_1: string;
  bullet_point_2: string;
  bullet_point_3: string;
  bullet_point_4: string;
  bullet_point_5: string;
}

export interface IProductManagerListing {
  sku: string;
  title: string;
  description: string;
  search_terms: string;
  bullet_point_1: string;
  bullet_point_2: string;
  bullet_point_3: string;
  bullet_point_4: string;
  bullet_point_5: string;
  created_at: string;
}

export interface IProductManagerDraftListing {
  asin: string;
  image_url: string;
  draft: IProductManagerDraft;
  listings: IProductManagerListing[];
}

export interface IProductManagerDraftListingResponse {
  data: IProductManagerDraftListing[];
}

export interface IProductManagerKeyword {
  sfr?: boolean;
  keyword?: string;
  value: string;
  value_corrected: string;
  priority: IProductManagerTagPriority;
  sfr_rank: number;
  sfr_date: string;
  impressions: number;
  impressions_predicted_date: string;
  volume_calculated: number;
  indexed: boolean;
  indexed_date: string;
  ranked_asin: string;
  ranked_results: number;
  amazons_choice: boolean;
  tags: string[];
  rank: number;
  rank_change: number;
  sales_rate: number;
  sales: number;
  number_of_competitors: number;
  click_dominance: number;
  search_dominance: number;
  trend: number[];
  checked?: boolean;
  graphOpened?: boolean;
  density_score?: number;
  correctingKeyword?: boolean;
  sfrGraphOpened?: boolean;
  sfrGraphLoading?: boolean;
}

export interface IProductManagerKeywordGraphData {
  value: number;
  date: string;
  asin: string;
}

export interface IProductManagerKeywordGraphResponse {
  data: {
    impressions: IProductManagerKeywordGraphData[];
    rank: IProductManagerKeywordGraphData[];
  };
}

export type IProductManagerTagPriority = 'H'|'M'|'L'|'VL';

export interface IProductManagerWord {
  value: string;
  priority: IProductManagerTagPriority;
}

export interface IProductManagerPriorityChange {
  value: string;
  oldPriority: IProductManagerTagPriority;
  newPriority: IProductManagerTagPriority;
}

export interface IProductManagerKeywordsResponse {
  data: IProductManagerKeyword[];
}

export interface IProductManagerUpdateKeywordsPayload {
  keyword: string;
  priority: IProductManagerTagPriority;
}

export interface IProductManagerResetProductSuccess {
  eventAction: '[PRODUCT]RESET_PRODUCT_COMPLETE';
  credential_id: number;
  source: string;
  name: string;
  asin: string;
  product: {
    asin: string;
    short_name: string;
    keywords: {
      count: number;
      change: number;
    };
    active: boolean;
  };
}
