<div class="overlay modal-container"
    [class.modal-container--auto-height]="height === 'auto'"
    [class.modal-container--small]="size === 'small'"
    [class.modal-container--medium]="size === 'medium'"
    [class.modal-container--large]="size === 'large'"
    [class.minimize]="enableMinimize"
    [class.closing]="closing" #backdrop>
    <div class="modal horizontal-layout">
        <aside *ngIf="showInstructions && !showOnlyBody" class="vertical-layout">
            <ng-content select="[modal-instructions]"></ng-content>
        </aside>
        <div class="main vertical-layout">
            <header class="horizontal-layout" *ngIf="!showOnlyBody && !hideHeader">
                <div class="header">
                    <ng-content select="[modal-header]"></ng-content>
                </div>
                <button class="close" (click)="close()">
                    <st-icon [icon]="'cross'" [pointer]="true"></st-icon>
                </button>
            </header>
            <div class="modal-content" [class.scrollable-modal]="scrollable">
                <ng-content select="[modal-body]"></ng-content>
            </div>
            <div class="footer" *ngIf="!showOnlyBody && !hideFooter">
                <ng-content select="[modal-footer]"></ng-content>
            </div>

            <div *ngIf="showModalOverlay$ | async" class="modal-overlay">
                <div class="loading-circles" *ngIf="enableLoader"></div>
                <ng-content select="[modal-overlay]"></ng-content>
            </div>
        </div>
    </div>
</div>
