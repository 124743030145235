import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropdownDirective } from "@app/shared/layout/dropdown/dropdown.directive";
import { DropdownOpenDirective } from "@app/shared/layout/dropdown/dropdown-open.directive";
import { DropdownNotClosableZoneDirective } from "@app/shared/layout/dropdown/dropdown-not-closable-zone.directive";

let DIRECTIVES = [
  DropdownOpenDirective,
  DropdownNotClosableZoneDirective,
  DropdownDirective,
];

@NgModule({
  imports     : [
    CommonModule
  ],
  declarations: [
    ...DIRECTIVES
  ],
  exports  : [
    ...DIRECTIVES
  ]
})
export class DropdownModule {}
