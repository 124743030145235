import { NgRedux } from '@angular-redux/store';
import { Component, Input, OnInit } from '@angular/core';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { ApiTokenActions } from '@app/shared/data/api-token/api-token.actions';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';
import { AppState } from '@app/shared/data/app-state.model';
import { ApiKeysRotateWarningModalComponent } from '../api-keys-rotate-warning-modal/api-keys-rotate-warning-modal.component';
import { ApiKeysWarningModalComponent } from '../api-keys-warning-modal/api-keys-warning-modal.component';

@Component({
  selector: 'st-api-keys-table',
  templateUrl: './api-keys-table.component.html',
  styleUrls: ['./api-keys-table.component.scss']
})
export class ApiKeysTableComponent implements OnInit {
  @Input() apiKeys: ApiToken[];
  @Input() isLoading: boolean;
  @Input() liveKeys: boolean;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private modalService: ModalService,
  ) { }

  ngOnInit() { }

  openWarningModal(token: ApiToken) {
    this.ngRedux.dispatch(ApiTokenActions.getSingle(token.credential_id, token.value));
    this.modalService.open(ApiKeysWarningModalComponent);
  }

  onRotate(token: ApiToken) {
    this.ngRedux.dispatch(ApiTokenActions.getSingle(token.credential_id, token.value));
    this.modalService.open(ApiKeysRotateWarningModalComponent);
  }
}
