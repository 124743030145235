import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ApiKeysComponent } from '@app/client/api-keys/api-keys.component';

const routes: Routes = [
  {
    path     : '',
    component: ApiKeysComponent,
    data: {
       breadcrumbs: ['API Keys']
    }
  },
];

@NgModule({
  imports  : [
    RouterModule.forChild(routes)
  ],
  exports  : [
    RouterModule
  ],
  providers: []
})
export class ApiKeysRoutingModule {
}
