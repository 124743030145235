import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";

export enum ButtonIconEnum {
  RETRY = 'retry.svg',
  RELOAD = 'reload.svg',
  ARCHIVE = 'archive.svg',
  RESET = 'reset.svg',
  SAVE = 'save-disk.svg',
  RESTORE = 'restore.svg',
  NEXT = 'v2/next.svg',
  EXPORT = 'v2/export.svg',
  EXPORT_WHITE = 'v2/export-white.svg',
  V2_ARROW_RIGHT = 'v2/arrow-right.svg',
  V2_RESET = 'v2/reset.svg',
  V2_RESET_WHITE = 'v2/reset-white.svg',
  V2_BACK_BLUE = 'v2/back-blue.svg',
  V2_BACK_WHITE = 'v2/back-white.svg',
  SAVE_EMPTY = 'save-empty.svg',
  AMAZON_WHITE = 'amazon_new_white.svg',
  C6_WHITE = 'c6-logo-white.svg'
}

@Component({
  selector: 'st-button-v2',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnDestroy {
  @Input() color: 'primary'|'light-blue'|'empty'|'transparent'|'gray'|'light-gray'|'v2_red_empty'|'v2_red'|'v2_blue_empty'|'v2_blue'|'v2_light_blue'|'black'|'v2_purple'|'v2_teal' = 'primary';
  @Input() edges: 'all-round'|'one-corner' = 'all-round';
  @Input() size: 'small'|'normal'|'large'|'x-large'|'dynamic'|'dynamic-large' = 'normal';
  @Input() label: string;
  @Input() disabled = false;
  @Input() icon?: ButtonIconEnum;
  @Input() hoverIcon?: ButtonIconEnum;
  @Input() iconPosition: 'after'|'before' = 'before';
  @Input() fullWidth = false;
  @Input() justify: 'space-between'|'center' = 'center';
  @Input() loading: boolean = false;
  @Input() type: string = null;

  @Output() clicked: EventEmitter<void> = new EventEmitter();

  @ViewChild('button', {read: ElementRef})
  button: ElementRef;

  focus() {
    this.button.nativeElement.focus();
  }

  showIcon: ButtonIconEnum;
  constructor() { }

  ngOnInit() {
    if (this.icon) {
      this.showIcon = this.icon;
    }
  }

  ngOnDestroy() { }

  hovered() {
    if (this.hoverIcon) {
      this.showIcon = this.hoverIcon;
    }
  }

  unhovered() {
    if (this.icon) {
      this.showIcon = this.icon;
    }
  }

  handleClick() {
    if (this.disabled) {
      return;
    }

    this.clicked.emit();
  }

  get iconPath(): string {
    return `/assets/img/icons/${this.showIcon}`;
  }
}
