import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';
import { Action } from 'redux';

@Injectable()
export class ApiTokenActions {
  static readonly TYPES = {
    LOAD_ALL          :     '[API_TOKEN]LOAD_ALL',
    LOAD_ALL_SUCCEEDED:     '[API_TOKEN]LOAD_ALL_SUCCEEDED',

    GET_SINGLE: '[API_TOKEN]GET_SINGLE',
    GET_SINGLE_SUCCESS: '[API_TOKEN]GET_SINGLE_SUCCESS',

    CLEAR_SINGLE_TOKEN: '[API_TOKEN]CLEAR_SINGLE_TOKEN',

    LOAD          : '[API_TOKEN]LOAD',
    LOAD_SUCCEEDED: '[API_TOKEN]LOAD_SUCCEEDED',
    LOAD_FAILED   : '[API_TOKEN]LOAD_FAILED',

    CREATE          : '[API_TOKEN]CREATE',
    CREATE_SUCCEEDED: '[API_TOKEN]CREATE_SUCCEEDED',
    CREATE_FAILED   : '[API_TOKEN]CREATE_FAILED',

    REVOKE          : '[API_TOKEN]REVOKE',
    REVOKE_SUCCEEDED: '[API_TOKEN]REVOKE_SUCCEEDED',
    REVOKE_FAILED   : '[API_TOKEN]REVOKE_FAILED',
  };

  static loadAll(): StAction {
    return {
      type: ApiTokenActions.TYPES.LOAD_ALL,
      data: {
      },
    };
  }

  static loadAllSucceeded(tokens: Collection<ApiToken>): StAction {
    return {
      type: ApiTokenActions.TYPES.LOAD_ALL_SUCCEEDED,
      data: tokens
    };
  }

  static load(credential_id:number, data = {}): StAction {
    return {
      type: ApiTokenActions.TYPES.LOAD,
      data: {
        credential_id: credential_id,
        params: data
      },
    };
  }

  static getSingle(credentialId: number, obfuscatedTokenValue: string): StAction {
    return {
      type: ApiTokenActions.TYPES.GET_SINGLE,
      data: {
        credentialId,
        obfuscatedTokenValue,
      },
    };
  }

  static getSingleSuccess(token: ApiToken): StAction {
    return {
      type: ApiTokenActions.TYPES.GET_SINGLE_SUCCESS,
      data: token
    };
  }

  static getSingleFailure(error: any): StAction {
    return {
      type: ApiTokenActions.TYPES.GET_SINGLE_SUCCESS,
      data: error
    };
  }

  static clearSingleToken(): Action {
    return {
      type: ApiTokenActions.TYPES.CLEAR_SINGLE_TOKEN,
    }
  }

  static loadSucceeded(tokens: Collection<ApiToken>): StAction {
    return {
      type: ApiTokenActions.TYPES.LOAD_SUCCEEDED,
      data: tokens
    };
  }

  static loadFailed(error: any): StAction {
    return {
      type: ApiTokenActions.TYPES.LOAD_FAILED,
      data: error
    };
  }

  static create(credentialId, testing: boolean): StAction {
    return {
      type: ApiTokenActions.TYPES.CREATE,
      data: {
        credentialId: credentialId,
        testing: testing,
      }
    };
  }

  static createSucceeded(token: ApiToken): StAction {
    return {
      type: ApiTokenActions.TYPES.CREATE_SUCCEEDED,
      data: token
    };
  }

  static createFailed(error): StAction {
    return {
      type: ApiTokenActions.TYPES.CREATE_FAILED,
      data: error
    };
  }

  static revoke(token: ApiToken): StAction {
    return {
      type: ApiTokenActions.TYPES.REVOKE,
      data: token
    };
  }

  static revokeSucceeded(token: ApiToken): StAction {
    return {
      type: ApiTokenActions.TYPES.REVOKE_SUCCEEDED,
      data: token
    };
  }

  static revokeFailed(error): StAction {
    return {
      type: ApiTokenActions.TYPES.REVOKE_FAILED,
      data: error
    };
  }
}
