import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { AdminSurvey } from "@app/shared/data/admin/survey/survey.models";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { AdminSurveyAPI } from "@app/shared/data/admin/survey/survey.api";
import { AdminSurveyActions } from "@app/shared/data/admin/survey/survey.actions";
import { ToastService } from "@app/core/services/toast.service";
import { ActivatedRoute, Router } from "@angular/router";

@Injectable()
export class AdminSurveyEpics {
  constructor(private adminSurveyApi: AdminSurveyAPI,
              private adminSurveyActions: AdminSurveyActions,
              private toaster: ToastService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              private ngRedux: NgRedux<AppState>) {}

  public createEpic() {
    return [
      this.loadPage,
      this.getSurvey,
      this.saveSurvey,
      this.getSurveyResults,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PaginationActions.getPagActionTypes(AdminSurvey).LOAD_PAGE) {
        let state = store.getState()['admin']['surveys'];
        let params = { ...state['_be_pagination'], ...state['_filters'] };

        this.adminSurveyApi.load(params)
          .subscribe(
            (surveys: Collection<AdminSurvey>) => {
              this.ngRedux.dispatch(this.adminSurveyActions.setPage(surveys));
            },
            (response) => {
              this.ngRedux.dispatch(this.adminSurveyActions.loadPageFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  getSurvey = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == AdminSurveyActions.TYPES.GET) {
        this.adminSurveyApi.get(action.data)
          .subscribe(
            (survey: AdminSurvey) => {
              this.ngRedux.dispatch(AdminSurveyActions.getSurveySucceeded(survey));
            },
            (response) => {
              this.toaster.error("Error!", response);
              this.ngRedux.dispatch(AdminSurveyActions.getSurveyFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  getSurveyResults = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == AdminSurveyActions.TYPES.GET_RESULTS) {
        this.adminSurveyApi.getResults(action.data)
          .subscribe(
            (survey: AdminSurvey) => {
              this.ngRedux.dispatch(AdminSurveyActions.getSurveyResultsSucceeded(survey));
            },
            (response) => {
              this.toaster.error("Error!", response);
              this.ngRedux.dispatch(AdminSurveyActions.getSurveyResultsFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  saveSurvey = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == AdminSurveyActions.TYPES.SAVE) {
        this.adminSurveyApi.save(action.data)
          .subscribe(
            (survey: AdminSurvey) => {
              this.toaster.success("Successfully saved!", '');
              this.ngRedux.dispatch(AdminSurveyActions.saveSuccedded(survey));
            },
            (error) => {
              this.toaster.error("Failed to save!", error.message);
              this.ngRedux.dispatch(AdminSurveyActions.saveFailed(error));
            },
            () => {}
          );
      }

      return next(action);
    }
  };
}
