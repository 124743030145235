import { BaseModel, Collection } from '@app/shared/data/base.models';
import { AdminPlan } from '@app/shared/data/admin/plan/plan.models';
import { Credential } from '@app/shared/data/credential/credential.models';

export class UserAdmin extends BaseModel {
  static className = 'UserAdmin';
  id: number;

  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  is_risky: boolean;

  plans: any[];
  additionalPlans: any[];
  subscriptions: any[];

  credentials: Collection<Credential>;

  permissions: any[];

  default_commission_ratio?: number;
  default_coupon_percent_off?: number;

  local_time?: string;

  wallet_balance: number;
  stripe_id: string;
  wallet_verified_at: string|null;

  is_paying?: boolean;
  is_active?: boolean;
  is_subuser?: boolean;
  has_credentials?: boolean;
  is_verified?: boolean;

  public getSubscriptions() {
    return new Collection<any>(this.subscriptions);
  }

  public getAdditionalPlans() {
    return new Collection<AdminPlan>(this.additionalPlans);
  }

  /**
   * Get User's full name
   * @return {string}
   */
  public getFullName(): string {
    if (this.first_name && this.last_name) {
      return this.first_name + ' ' + this.last_name;
    }

    return '';
  }

  get full_name() {
    return this.getFullName();
  }
}
