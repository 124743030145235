
import {of as observableOf,  BehaviorSubject ,  Observable ,  Subject } from 'rxjs';

import {takeUntil, map, merge} from 'rxjs/operators';
import {
  AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, Directive, ElementRef,
  EventEmitter,
  HostListener, Input, OnDestroy,
  Output,
  QueryList,
  TemplateRef, ViewChild,
} from '@angular/core';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import { ActivatedRoute, Router } from '@angular/router';


@Directive({selector: '[modal-overlay]'})
export class ModalOverlay {
}

@Component({
  selector: 'st-modal',
  exportAs: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModalComponent implements AfterViewInit, OnDestroy {
  private onDestroy$ = new Subject();

  public showModalOverlay$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  public closing = false;

  @Input()
  backdropClose = true;

  @Input()
  showInstructions = true;

  @Input()
  showOnlyBody: boolean = false;

  @Input() hideFooter = false;
  @Input() hideHeader = false;

  @Input()
  scrollable = true;

  @Input()
  enableMinimize = false;

  @Input()
  enableLoader: boolean = true;

  @Output()
  onBackdropClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input()
  onCloseInterceptor: (result: any) => boolean = () => true

  @Input() size: 'small'|'medium'|'large' = 'large';

  @Input() height: 'normal'|'auto' = 'normal';

  @ViewChild('backdrop', { read: ElementRef, static: true })
  backdrop: ElementRef = null;

  @ContentChildren(ModalOverlay)
  overlay: QueryList<TemplateRef<any>> = new QueryList();

  constructor(public activeModal: ActiveModal,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private cd: ChangeDetectorRef) {
  }

  ngOnDestroy(): void {
    document.getElementsByTagName('html').item(0).style.overflowY = 'auto';
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit(): void {
    this.overlay.changes.pipe(
      merge(observableOf(this.overlay)),
      map(l => l.length > 0),
      takeUntil(this.onDestroy$),)
      .subscribe(this.showModalOverlay$);

    this.cd.detectChanges();
    document.getElementsByTagName('html').item(0).style.overflowY = 'hidden';
  }

  @HostListener('document:click', ['$event'])
  outsideClick(e: MouseEvent) {
    if (this.backdrop.nativeElement === e.target) {
      this.onBackdropClick.next(true);
      if (this.backdropClose) {
        this.close();
      }
    }
  }

  @HostListener('document:keydown.escape', ['$event'])
  onEscKeydown() {
    this.onBackdropClick.next(true);
    if (this.backdropClose) {
      this.close();
    }
  }


  close(result?: any, minimize?: boolean) {
    if (this.onCloseInterceptor(result)) {
      this.closing = true;

      if ((minimize === undefined && this.enableMinimize) || (minimize !== undefined && minimize)) {
        setTimeout(this._close.bind(this, result), 450);
      } else {
        this._close(result);
      }
    }

  }

  private _close(result?: any) {
    if (this.activeModal.hasOwnUrl()) {
      this.router.navigate([{ outlets: { modal: null } }], {
        relativeTo: this.activatedRoute.parent
      });
    }

    this.activeModal.close(result);
    this.closing = false;
  }
}
