import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { Collection } from '@app/shared/data/base.models';
import { ApiPromotionApi } from '@app/shared/data/api-promotion/api-promotion.api';
import { ApiPromotion, ProductChild } from '@app/shared/data/api-promotion/api-promotion.models';
import { ApiPromotionActions } from '@app/shared/data/api-promotion/api-promotion.actions';

@Injectable()
export class ApiPromotionEpics {
  constructor(private apiPromotionApi: ApiPromotionApi,
              private apiPromotionsActions: ApiPromotionActions,
              private store: NgRedux<AppState>,
  ) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
      this.productSearch,
      this.delete,
      this.fetch,
      this.save,
      this.saveCoupons,
    ];
  }

  /**
   *
   * @param store
   */
  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === ApiPromotionActions.getPagActionTypes(ApiPromotion).LOAD_PAGE) {
        this.apiPromotionApi.all(action.data.pagination)
          .subscribe(
            (apiPromotions: Collection<ApiPromotion>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.apiPromotionsActions.setPage(apiPromotions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.apiPromotionsActions.loadPageFailed(response));
            },
            () => {
            },
          );
      }
      return next(action);
    };
  }

  /**
   *
   * @param store
   */
  fetch = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const nextAction = next(action);

      if (action.type === ApiPromotionActions.TYPES.FETCH_STATS) {
        if (action.data.apiPromotion.id === 'new') {
          store.dispatch(ApiPromotionActions.fetchSucceeded(action.data.apiPromotion));
        } else {
          let params = {};
          params = { 'with-stats': true,
                      'timezone': action.data.timezone
                   };
          this.apiPromotionApi.get(action.data.apiPromotion, params)
            .subscribe(
              (apiPromotion: ApiPromotion) => {
                store.dispatch(ApiPromotionActions.fetchSucceeded(apiPromotion));
              },
              (response) => {
                // this.toaster.error("Error!", response);
                store.dispatch(ApiPromotionActions.fetchFailed(response));
              },
              () => {}
            );
        }
      }

      if (action.type === ApiPromotionActions.TYPES.FETCH) {
        if (action.data.id === 'new') {
          store.dispatch(ApiPromotionActions.fetchSucceeded(action.data));
        } else {
          const params = {};

          this.apiPromotionApi.get(action.data, params)
            .subscribe(
              (apiPromotion: ApiPromotion) => {
                store.dispatch(ApiPromotionActions.fetchSucceeded(apiPromotion));
              },
              (response) => {
                // this.toaster.error("Error!", response);
                store.dispatch(ApiPromotionActions.fetchFailed(response));
              },
              () => {}
            );
        }
      }

      return nextAction;
    };
  }

  /**
   *
   * @param store
   */
  delete = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === ApiPromotionActions.TYPES.DELETE) {
        this.apiPromotionApi.delete(action.data)
          .subscribe((apiPromotions: ApiPromotion) => {
            this.store.dispatch(ApiPromotionActions.deleteSucceeded(apiPromotions));
          }, (err) => {
            this.store.dispatch(ApiPromotionActions.deleteFailed(err));
          });
      }

      return next(action);
    };
  }

  /**
   *
   * @param store
   */
  save = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === ApiPromotionActions.TYPES.SAVE) {

        let subscription = this.apiPromotionApi.update(action.data);
        if (action.data.id === 'new') {
          subscription = this.apiPromotionApi.save(action.data);
        }

        subscription
          .subscribe((apiPromotions: ApiPromotion) => {
            this.store.dispatch(ApiPromotionActions.saveSucceeded(apiPromotions));
          }, (err) => {
            this.store.dispatch(ApiPromotionActions.saveFailed(err));
          });
      }

      return next(action);
    };
  }

  /**
   *
   * @param store
   */
  saveCoupons = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === ApiPromotionActions.TYPES.SAVE_COUPONS) {
        this.apiPromotionApi.saveCoupons(action.data)
          .subscribe((apiPromotions: ApiPromotion) => {
            this.store.dispatch(ApiPromotionActions.saveCouponsSucceeded(apiPromotions));
          }, (err) => {
            this.store.dispatch(ApiPromotionActions.saveCouponsFailed(err.message));
          });
      }

      return next(action);
    };
  }

  /**
   *
   * @param store
   */
  productSearch = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === ApiPromotionActions.TYPES.SEARCHING_FOR_PRODUCT) {
        const params = {
          favorite: '*',
          'tags[]': [],
          searchTerm: action.data.query
        };

        this.apiPromotionApi.products(params)
            .subscribe(
              (products: Collection<ProductChild>) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.apiPromotionsActions.searchForProductSucceeded(products));
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.apiPromotionsActions.searchForProductFailed(response));
              },
              () => {
              }
            );
      }
      return next(action);
    };
  }
}
