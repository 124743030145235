// tslint:disable-next-line: max-line-length
import { stripCampaignName } from '@app/client/campaign-manager/campaign-manager-setup/campaign-manager-setup-product-selection-campaign-name/campaign-manager-setup-product-selection-campaign-name.component';
import { StAction } from '../st-action';
import { CampaignManagerActions } from './campaign-manager.actions';
import {
  CampaignStatusEnum,
  FileTypeEnum,
  ICampaign,
  ICampaignManagerResponse,
  ICampaignManagerState,
  ICampaignState,
  ILoadAvailableFiltersResponse,
  ISavedFiltersListResponse,
  ISaveFilterResponse,
  OrdersUrlEnum,
  ReviewUrlEnum,
  rewardTypeToCampaignTypeMap,
  SortByEnum
} from './campaign-manager.models';

const initialCampaignState: ICampaignState = {
  destination: null,
  theme: {
    chatbot_theme: 'purple-orange'
  },
  order_verification: {
    status: 'shipped'
  },
  included_customers: {
    phone_number_rate: ['A', 'B', 'C'],
    // customer_rate: ['A', 'B', 'C'],
  },
  urls: {
    orders_url: OrdersUrlEnum.FILTERED_BY_PRODUCT,
    review_url: ReviewUrlEnum.PRODUCT_REVIEW,
  }
};

const INITIAL_STATE: ICampaignManagerState = {
  campaignList: [],
  currentPage: 1,
  lastPage: null,
  perPage: 30,
  sort: SortByEnum.NONE,
  total: 0,
  from: 0,
  to: 0,

  appliedFilters: [],
  savedFilters: [],
  availableFilters: [],
  searchTerm: '',
  credentialId: null,

  loadingCampaignList: false,
  savingFilters: false,
  savingFiltersError: false,
  loadingSavedFilters: false,
  loadingAvailableFilters: false,
  deletingView: false,

  imageUploaded: null,
  imageUploadingError: false,

  error: false,

  campaignManagerState: initialCampaignState,
  campaignManagerCampaignNameConfirmed: false,
  updatingCampaignManagerState: false,
  campaignNameError: false,
  checkingCampaignName: false,
  loadingCampaign: false,
  errorLoadingCampaign: false,
};

export function campaignManagerReducer(state: ICampaignManagerState = INITIAL_STATE, action: StAction): ICampaignManagerState {
  switch (action.type) {
    case CampaignManagerActions.TYPES.LOAD_CAMPAIGN_LIST:
      let updates = { ...action.data };

      if (action.data.currentPage === null) {
        delete updates.currentPage;
      }
      if (action.data.perPage === null) {
        delete updates.perPage;
      }
      if (action.data.sort === null) {
        delete updates.sort;
      }

      return {
        ...state,
        ...updates,
        loadingCampaignList: true,
      };

    case CampaignManagerActions.TYPES.CAMPAIGN_LIST_LOADED:
      const data: ICampaignManagerResponse = action.data;
      return {
        ...state,
        campaignList: data.data,
        currentPage: data.meta.current_page,
        lastPage: data.meta.last_page,
        perPage: data.meta.per_page,
        total: data.meta.total || 0,
        from: data.meta.from || 0,
        to: data.meta.to || 0,
        loadingCampaignList: false,
      };

      case CampaignManagerActions.TYPES.TOGGLE_CAMPAIGN:
        return {
          ...state,
          campaignList: (state.campaignList || []).map((campaign: ICampaign) => {
              return {
                ...campaign,
                detailsOpened: action.data.id === campaign.id ? !campaign.detailsOpened : false,
              };
            }),
        };

    case CampaignManagerActions.TYPES.SET_FILTERS:
      return {
        ...state,
        appliedFilters: action.data.filters,
      };

    case CampaignManagerActions.TYPES.SEARCH:
      return {
        ...state,
        searchTerm: action.data.searchTerm,
      };

    case CampaignManagerActions.TYPES.UPLOAD_FILE:
      return {
        ...state,
        imageUploaded: null,
        imageUploadingError: false
      };

    case CampaignManagerActions.TYPES.UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        imageUploaded: action.data,
        campaignManagerState: {
          ...state.campaignManagerState,
          qr_code: {
            ...state.campaignManagerState.qr_code,
            logo_url: action.data,
          }
        }
      };

    case CampaignManagerActions.TYPES.UPLOAD_FILE_ERROR:
      return {
        ...state,
        imageUploaded: null,
        imageUploadingError: true
      };

    case CampaignManagerActions.TYPES.UPLOAD_THEME_LOGO_FILE_SUCCESS:
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          theme: {
            ...state.campaignManagerState.theme,
            logo_url: action.data
          }
        }
      };

    case CampaignManagerActions.TYPES.SET_THEME_LOGO_FILE:
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          theme: {
            ...state.campaignManagerState.theme,
            logo_file: action.data
          }
        }
      };

      case CampaignManagerActions.TYPES.RESET_THEME_LOGO_FILE:
        return {
          ...state,
          campaignManagerState: {
            ...state.campaignManagerState,
            theme: {
              ...state.campaignManagerState.theme,
              logo_url: null,
              logo_file: null
            }
          }
        };

    case CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE_SUCCESS:
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          reward: {
            ...state.campaignManagerState.reward,
            ebook_reward_settings: {
              pdf_url: (action.data.type === FileTypeEnum.PDF) ? action.data.url : (state.campaignManagerState.reward.ebook_reward_settings && state.campaignManagerState.reward.ebook_reward_settings.pdf_url),
              mobi_url: (action.data.type === FileTypeEnum.MOBI) ? action.data.url : (state.campaignManagerState.reward.ebook_reward_settings && state.campaignManagerState.reward.ebook_reward_settings.mobi_url),
              epub_url: (action.data.type === FileTypeEnum.EPUB) ? action.data.url : (state.campaignManagerState.reward.ebook_reward_settings && state.campaignManagerState.reward.ebook_reward_settings.epub_url),
            }
          }
        }
      };

    case CampaignManagerActions.TYPES.SAVE_FILTERS:
      return {
        ...state,
        savingFilters: true,
        savingFiltersError: false
      };

    case CampaignManagerActions.TYPES.SAVE_FILTERS_SUCCESS:
      const response: ISaveFilterResponse = action.data;

      const savedFilters = [
        ...state.savedFilters,
        response.data
      ];

      return {
        ...state,
        savedFilters,
        savingFilters: false,
      };

    case CampaignManagerActions.TYPES.SAVE_FILTERS_ERROR:
      return {
        ...state,
        savingFilters: false,
        savingFiltersError: true
      };

    case CampaignManagerActions.TYPES.LOAD_SAVED_FILTERS:
      return {
        ...state,
        loadingSavedFilters: true,
      };

    case CampaignManagerActions.TYPES.LOAD_SAVED_FILTERS_SUCCESS:
      const savedFiltersListResponse: ISavedFiltersListResponse = action.data;

      return {
        ...state,
        loadingSavedFilters: false,
        savedFilters: savedFiltersListResponse.data
      };

    case CampaignManagerActions.TYPES.LOAD_AVAILABLE_FILTERS:
      return {
        ...state,
        loadingAvailableFilters: true,
      };

    case CampaignManagerActions.TYPES.LOAD_AVAILABLE_FILTERS_SUCCESS:
      const availableFiltersResponse: ILoadAvailableFiltersResponse = action.data;

      return {
        ...state,
        loadingAvailableFilters: false,
        availableFilters: availableFiltersResponse.data
      };

    case CampaignManagerActions.TYPES.LOAD_AVAILABLE_FILTERS_ERROR:
      return {
        ...state,
        loadingAvailableFilters: false,
      };

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_TYPE:
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          type: rewardTypeToCampaignTypeMap[action.data],
          reward: {
            ...state.campaignManagerState.reward || { },
            type: action.data,
          }
        },
      };

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_DESTINATION:
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          destination: action.data,
        },
      };

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_TRUST_RATES: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          included_customers: {
            ...state.campaignManagerState.included_customers,
            phone_number_rate: action.data.phoneNumberTrustRates,
            customer_rate: action.data.customerTrustRates
          },
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_URLS_ORDERS_URL: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          urls: {
            ...state.campaignManagerState.urls || {},
            orders_url: action.data,
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_URLS_REVIEWS_URL: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          urls: {
            ...state.campaignManagerState.urls || {},
            review_url: action.data,
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_THANK_YOU_PAGE_HEADLINE: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          thank_you_page: {
            ...state.campaignManagerState.thank_you_page || {},
            headline: action.data,
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_THANK_YOU_PAGE_SUB_HEADLINE: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          thank_you_page: {
            ...state.campaignManagerState.thank_you_page || {},
            sub_headline: action.data,
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_VIDEO_REWARD_URL: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          reward: {
            ...state.campaignManagerState.reward,
            video_reward_settings: {
              ...state.campaignManagerState.reward.video_reward_settings || {},
              url: action.data,
            }
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_WARRANTY_DURATION: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          reward: {
            ...state.campaignManagerState.reward,
            warranty_reward_settings: {
              ...state.campaignManagerState.reward.warranty_reward_settings || {},
              duration: action.data,
            }
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_WARRANTY_LIFETIME: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          reward: {
            ...state.campaignManagerState.reward,
            warranty_reward_settings: {
              ...state.campaignManagerState.reward.warranty_reward_settings || {},
              lifetime: action.data,
            }
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          name: action.data,
          slug: stripCampaignName(action.data),
        }
      };
    }

    case CampaignManagerActions.TYPES.CONFIRM_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME: {
      return {
        ...state,
        campaignManagerCampaignNameConfirmed: true,
      };
    }

    case CampaignManagerActions.TYPES.ADD_CAMPAIGN: {
      return {
        ...state,
        updatingCampaignManagerState: true,
      };
    }

    case CampaignManagerActions.TYPES.UPDATE_CAMPAIGN: {
      return {
        ...state,
        updatingCampaignManagerState: true,
      };
    }

    case CampaignManagerActions.TYPES.ADD_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        updatingCampaignManagerState: false,
        campaignManagerCampaignNameConfirmed: true,
        campaignManagerState: {
          ...state.campaignManagerState,
          id: action.data.id,
        }
      };
    }

    case CampaignManagerActions.TYPES.ADD_CAMPAIGN_ERROR: {
      return {
        ...state,
        updatingCampaignManagerState: false,
      };
    }

    case CampaignManagerActions.TYPES.UPDATE_CAMPAIGN_ERROR: {
      return {
        ...state,
        updatingCampaignManagerState: false,
      };
    }

    case CampaignManagerActions.TYPES.UPDATE_CAMPAIGN_SUCCESS: {
      return {
        ...state,
        updatingCampaignManagerState: false,
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_QR_CODE_COLOR: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          qr_code: {
            ...state.campaignManagerState.qr_code,
            color: action.data,
          },
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_QR_CODE_DENSITY: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          qr_code: {
            ...state.campaignManagerState.qr_code,
            density: action.data,
          },
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_QR_CODE_LOGO_URL: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          qr_code: {
            ...state.campaignManagerState.qr_code,
            logo_url: action.data,
          },
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_PRODUCT: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          product: {
            short_name: action.data && action.data.product.short_title.substring(0, 64),
            asin: action.data && action.data.product.asin,
            children: action.data && action.data.children || [],
            image: action.data && action.data.product.image_url,
            full_name: action.data && action.data.product.title
          },
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_PRODUCT: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          reward: {
            ...state.campaignManagerState.reward,
            mcf_gift_reward_settings: {
              ...state.campaignManagerState.reward.mcf_gift_reward_settings,
              product: {
                parent: action.data && action.data.product.asin,
                variations: action.data && action.data.children.map(c => {
                  return {
                    sku: c.sku,
                    quantity: 1,
                    asin: c.asin,
                    full_name: c.title,
                    image: c.image_url,
                  };
                })
              },
            }
          }
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_REWARD_SHIPPING: {
      if (action.meta && action.meta.asFallback === true) {
        if (state.campaignManagerState.reward
          && state.campaignManagerState.reward.mcf_gift_reward_settings
          && state.campaignManagerState.reward.mcf_gift_reward_settings.shipping) {
          return state;
        }
      }

      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          reward: {
            ...state.campaignManagerState.reward,
            mcf_gift_reward_settings: {
              ...state.campaignManagerState.reward.mcf_gift_reward_settings,
              shipping: action.data
            }
          }
        }
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_THEME: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          theme: {
            ...state.campaignManagerState.theme,
            chatbot_theme: action.data
          },
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_CONTACT_EMAIL: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          contact_email: action.data,
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REJECTION_BODY: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          included_customers: {
            ...state.campaignManagerState.included_customers,
            rejection_message: {
              ...state.campaignManagerState.included_customers?.rejection_message,
              body: action.data
            }
          }
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REJECTION_HEADLINE: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          included_customers: {
            ...state.campaignManagerState.included_customers,
            rejection_message: {
              ...state.campaignManagerState.included_customers?.rejection_message,
              headline: action.data
            }
          }
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_PRODUCT_SHORT_NAME: {
      return {
        ...state,
        campaignManagerState: {
          ...state.campaignManagerState,
          product: {
            ...state.campaignManagerState.product,
            short_name: action.data,
          }
        },
      };
    }

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_CHECK_SLUG:
      return {
        ...state,
        checkingCampaignName: true,
        campaignNameError: false,
      };

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_CHECK_SLUG_SUCCESS:
      return {
        ...state,
        checkingCampaignName: false,
        campaignNameError: !action.data.available,
      };

    case CampaignManagerActions.TYPES.SET_CAMPAIGN_CHECK_SLUG_ERROR:
      return {
        ...state,
        checkingCampaignName: false,
        campaignNameError: true,
      };

    case CampaignManagerActions.TYPES.GET_CAMPAIGN:
      return {
        ...state,
        loadingCampaign: true,
        errorLoadingCampaign: false
      };

    case CampaignManagerActions.TYPES.GET_CAMPAIGN_SUCCESS:
      const campaign = action.data.data;

      return {
        ...state,
        campaignManagerState: {
          ...campaign,
          active: campaign.status === CampaignStatusEnum.ACTIVE,
        },
        campaignManagerCampaignNameConfirmed: true,
        loadingCampaign: false,
      };

      case CampaignManagerActions.TYPES.GET_CAMPAIGN_ERROR:
        return {
          ...state,
          loadingCampaign: false,
          errorLoadingCampaign: true
        };

    case CampaignManagerActions.TYPES.RESET_STATE: {
      return {
        ...state,
        campaignManagerCampaignNameConfirmed: false,
        campaignManagerState: initialCampaignState,
      };
    }

    default:
      return state;
  }
}
