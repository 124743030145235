import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";

@Injectable()
export class DecisionActions {

  static readonly TYPES = {
    LOAD_DECISIONS              : '[DECISION]LOAD_DECISIONS',
    LOAD_DECISIONS_SUCCEEDED    : '[DECISION]LOAD_DECISIONS_SUCCEEDED',
    LOAD_DECISIONS_FAILED       : '[DECISION]LOAD_DECISIONS_FAILED',
    SET_RELEVANT                : '[DECISION]SET_RELEVANT',
    SET_IRRELEVANT              : '[DECISION]SET_IRRELEVANT',
    SAVE_DECISIONS              : '[DECISION]SAVE_DECISIONS',
    SAVE_DECISIONS_SUCCEEDED    : '[DECISION]SAVE_DECISIONS_SUCCEEDED',
    SAVE_DECISIONS_FAILED       : '[DECISION]SAVE_DECISIONS_FAILED',
  };

  load(asin:string): StAction {
    return {
      type: DecisionActions.TYPES.LOAD_DECISIONS,
      data: {
        asin
      }
    }
  }

  loadFailed(error): StAction {
    return {
      type: DecisionActions.TYPES.LOAD_DECISIONS_FAILED,
      data: {
        error: error.error
      }
    }
  }

  loadSucceeded(data): StAction {
    return {
      type: DecisionActions.TYPES.LOAD_DECISIONS_SUCCEEDED,
      data: data
    }
  }

  setRelevant(kw): StAction {
    return {
      type: DecisionActions.TYPES.SET_RELEVANT,
      data: {kw}
    }
  }

  setIrrelevant(kw): StAction {
    return {
      type: DecisionActions.TYPES.SET_IRRELEVANT,
      data: {kw}
    }
  }

  save(): StAction {
    return {
      type: DecisionActions.TYPES.SAVE_DECISIONS,
      data: {}
    }
  }

  saveFailed(error): StAction {
    return {
      type: DecisionActions.TYPES.SAVE_DECISIONS_FAILED,
      data: {
        error: error.error
      }
    }
  }

  saveSucceeded(): StAction {
    return {
      type: DecisionActions.TYPES.SAVE_DECISIONS_SUCCEEDED,
      data: {}
    }
  }
}
