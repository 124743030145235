<table class="flex-table {{ theme }}">
  <thead>
      <tr>
        <ng-container *ngFor="let col of colms" [colmBuild]="col" [head]="true"></ng-container>
      </tr>
  </thead>
  <tbody [class.garyout]="disableWhenLoading && loading">
      <st-line-loader [loading]="(loading || !_ready) && showLineLoader"></st-line-loader>
      <tr *ngFor="let item of data | stSort:sort; let i = index;" [class.disable]="isDisabled(item)" [classList]="getClassList(item)">
        <ng-container *ngFor="let col of colms" [colmBuild]="col" [context]="{$implicit: item}"></ng-container>

        <ng-container *ngIf="accordionTemplate">
          <div class="accordion-content" *ngIf="item.opened">
            <ng-container [ngTemplateOutlet]="accordionTemplate.template"
                          [ngTemplateOutletContext]="{$implicit: item}"
            ></ng-container>
          </div>
        </ng-container>

      </tr>


      <div *ngIf="!loading && _ready && data?.length <= 0" class="no-content">
          <ng-container *ngIf="nocontent;else nocontentdefault" [ngTemplateOutlet]="nocontent.template"></ng-container>
          <ng-template #nocontentdefault><div class="no-content-text" i18n>No content</div></ng-template>
      </div>
      <st-line-loader *ngIf="data && data.length > 0" [loading]="(loading || !_ready) && showLineLoader" ></st-line-loader>
  </tbody>
</table>

<div class="footer">
    <st-pager class="pager" *ngIf="paginator === 'default'" [(currentPage)]="page" [lastPage]="data.getLastPage()"
        (currentPageChange)="pageChanged()"></st-pager>

    <st-simple-pager class="pager" *ngIf="paginator === 'simple'" [(currentPage)]="page" [hasMore]="data.hasMore()" [loading]="loading" (currentPageChange)="pageChanged()"></st-simple-pager>

    <div class="range" [class.foot-margin]="paginator === 'none'">
        <span *ngIf="showCounters()">
            <span i18n>Showing</span>
            <span class="current-range">{{ data.getCurrentRange().from }}-{{ data.getCurrentRange().to }}</span>
            <ng-container *ngIf="showTotal()">
                <span i18n>of</span>
                <span class="total">{{ data.getTotal() }}</span>
            </ng-container>
        </span>
        <st-select [(ngModel)]="perPage" (ngModelChange)="perPageChanged()">
            <st-option [value]="10">10</st-option>
            <st-option [value]="20">20</st-option>
            <st-option [value]="30">30</st-option>
            <st-option [value]="50">50</st-option>
        </st-select>
    </div>
</div>
