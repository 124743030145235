<div class="progress-bar-widget keywords-limit">
  <div class="texts loading" *ngIf="loading">
    <span class="label">
      <st-loader></st-loader>
    </span>
  </div>
  <div class="texts" *ngIf="!loading">
    <span class="label">{{ label }}</span>
    <div class="progress-bar" [ngClass]="style" *ngIf="newLayout">
      <span class="done"
        [style.width.%]="(displayedPercentage | async) > 100 ? 100: (displayedPercentage | async)"></span>
    </div>
    <span class="numbers">
      <span *ngIf="rightLabel">{{rightLabel}}</span>
      <span *ngIf="value !== null && max !== null">
        <span class="value">{{ value | number }}</span> <span>/</span> <span>{{ max.length ? max: (max | number) }}</span>
      </span>
      <ng-container *ngIf="!newLayout">({{ displayedPercentage | async }}%)</ng-container>
    </span>
  </div>
  <div class="progress-bar" [ngClass]="style" *ngIf="!newLayout">
    <span class="done"
      [style.width.%]="(displayedPercentage | async) > 100 ? 100: (displayedPercentage | async)"></span>
  </div>
</div>
