import { Component, EventEmitter, Input, Output } from "@angular/core";
import { IDropdownOption } from "@app/shared/layout/v2/forms/models/IDropdownOptions";

@Component({
  selector: 'st-pagination-info',
  templateUrl: './pagination-info.component.html',
  styleUrls: ['./pagination-info.component.scss']
})
export class PaginationInfoComponent {
  @Input() text: string;
  @Input() disabled: boolean = false;
  @Input() options: IDropdownOption[];
  @Input() preSelectedOption: IDropdownOption;
  @Output() onPageChange: EventEmitter<IDropdownOption> = new EventEmitter();

  pageChange(event: IDropdownOption) {
    this.onPageChange.emit(event);
  }
}
