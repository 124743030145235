import {ExportService} from '@app/shared/services/export.service';
import {PrizmProduct} from '@app/shared/data/prizm/prizm.models';

export class ExportPrizmService extends ExportService {
  static transformKeywords(products: PrizmProduct[]) {
    const keys = [
      'ASIN',
      'Title',
      'Price',
      'BSR',
      'Reviews',
      'Review Rating',
      'Sales',
      'Revenue',
      'Fulfillment',
      ''
    ];

    const values = [];

    for (const product of products) {
      values.push([
        product.asin,
        product.title || '',
        product.price || '',
        product.bsr || '',
        product.review_count || '',
        product.review_rating || '',
        this.round(product.monthly_sales_units) || '',
        this.round(product.monthly_revenue) || '',
        product.fulfillment || '',
      ]);
    }

    return [keys, ...values];
  }

  static exportToCsv(filename: string, products: PrizmProduct[]) {
    ExportService.exportToCsv(filename, this.transformKeywords(products));
  }

  static round(value) {
    return Math.round(value * 100) / 100;
  }
}
