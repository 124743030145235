import { Injectable } from '@angular/core';
import { PastedAsin } from '@app/client/v2/product-manager/product-list-add-products-manually-modal/product-list-add-products-manually-modal.component';
import {
  IFieldOrder,
} from '@app/client/v2/product-manager/product-manager-product-list/product-manager-product-list.component';
import { Action } from 'redux';
import { StAction } from '../st-action';
import {
  IProductManagerDraftListingResponse,
  IProductManagerGraphDataResponse,
  IProductManagerProduct,
  IProductManagerProductsResponse,
  IProductManagerVariation,
  IProductManagerVariationsResponse,
  IProductManagerDraft,
  IProductManagerKeywordsResponse,
  IProductManagerUpdateKeywordsPayload,
  IProductManagerKeywordGraphResponse, ProductActivityStatusEnum,
} from './product-manager.models';

@Injectable()
export class ProductManagerActions {
  static readonly _events = {
    reset_products_success: '[PRODUCT]RESET_PRODUCT_COMPLETE',
    competition_research_keyword_search_finished: 'competition_research_keyword_search:finished',
    keyword_manager_priority_changes: 'keyword_manager_priority_changes',
    keyword_manager_hydration_finished: 'keyword_manager_hydration_finished',
    keyword_manager_priority_limit_reached: 'keyword_manager_priority_limit_reached',
    keyword_manager_non_priority_limit_reached: 'keyword_manager_non_priority_limit_reached',
    keyword_manager_per_product_limit_reached: 'keyword_manager_per_product_limit_reached',
    product_manager_keyword_index_check_finished: 'keyword_index_check_finished',
    foreign_product_add_finished: 'foreign_product_add_finished',
  };

  static readonly TYPES = {
    GET_PRODUCTS: '[PRODUCT_MANAGER]GET_PRODUCTS',
    GET_PRODUCTS_SUCCESS: '[PRODUCT_MANAGER]GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR: '[PRODUCT_MANAGER]GET_PRODUCTS_ERROR',

    RESYNC_PRODUCTS: '[PRODUCT_MANAGER]RESYNC_PRODUCTS',
    RESYNC_PRODUCTS_COMPLETE: '[PRODUCT_MANAGER]GET_PRODUCTS_SUCCESS',

    GET_PRODUCTS_COPY_KEYWORDS: '[PRODUCT_MANAGER]GET_PRODUCTS_COPY_KEYWORDS',
    GET_PRODUCTS_COPY_KEYWORDS_SUCCESS: '[PRODUCT_MANAGER]GET_PRODUCTS_COPY_KEYWORDS_SUCCESS',
    GET_PRODUCTS_COPY_KEYWORDS_ERROR: '[PRODUCT_MANAGER]GET_PRODUCTS_COPY_KEYWORDS_ERROR',

    ADD_FOREIGN_PRODUCTS: '[PRODUCT_MANAGER]ADD_FOREIGN_PRODUCTS',
    ADD_FOREIGN_PRODUCTS_CLOSE_MODAL: '[PRODUCT_MANAGER]ADD_FOREIGN_PRODUCTS_CLOSE_MODAL',
    ADD_FOREIGN_PRODUCTS_SUCCESS: '[PRODUCT_MANAGER]ADD_FOREIGN_PRODUCTS_SUCCESS',
    ADD_FOREIGN_PRODUCTS_ERROR: '[PRODUCT_MANAGER]ADD_FOREIGN_PRODUCTS_ERROR',

    GET_PRODUCT: '[PRODUCT_MANAGER]GET_PRODUCT',
    SET_ACTIVE_PRODUCT: '[PRODUCT_MANAGER]SET_ACTIVE_PRODUCT',
    CLEAR_ACTIVE_PRODUCT: '[PRODUCT_MANAGER]CLEAR_ACTIVE_PRODUCT',
    RESET_STATE: '[PRODUCT_MANAGER]RESET_STATE',
    RESET_ACIVE_PRODUCT: '[PRODUCT_MANAGER]RESET_ACIVE_PRODUCT',

    ANALYZE_KEYWORDS: '[PRODUCT_MANAGER]ANALYZE_KEYWORDS',
    ANALYZE_KEYWORDS_ERROR: '[PRODUCT_MANAGER]ANALYZE_KEYWORDS_ERROR',

    ARCHIVE_PRODUCTS: '[PRODUCT_MANAGER]ARCHIVE_PRODUCTS',
    ARCHIVE_PRODUCTS_SUCCESS: '[PRODUCT_MANAGER]ARCHIVE_PRODUCTS_SUCCESS',
    ARCHIVE_PRODUCTS_ERROR: '[PRODUCT_MANAGER]ARCHIVE_PRODUCTS_ERROR',

    RESET_PRODUCTS: '[PRODUCT_MANAGER]RESET_PRODUCTS',
    RESET_PRODUCTS_ERROR: '[PRODUCT_MANAGER]RESET_PRODUCTS_ERROR',

    SET_ACTIVE_TAB: '[PRODUCT_MANAGER]SET_ACTIVE_TAB',
    TOGGLE_PRODUCT: '[PRODUCT_MANAGER]TOGGLE_PRODUCT',

    TOGGLE_VARIATION_CHECK: '[PRODUCT_MANAGER]TOGGLE_VARIATION_CHECK',

    UNCHECK_ALL_PRODUCTS: '[PRODUCT_MANAGER]UNCHECK_ALL_PRODUCTS',

    TOGGLE_ALL_TABLE_ITEMS: '[PRODUCT_MANAGER]TOGGLE_ALL_TABLE_ITEMS',
    TOGGLE_SINGLE_TABLE_ITEM: '[PRODUCT_MANAGER]TOGGLE_SINGLE_TABLE_ITEM',

    SET_PRODUCT_STATUS: '[PRODUCT_MANAGER]SET_PRODUCT_STATUS',
    SET_PRODUCT_STATUS_SUCCESS: '[PRODUCT_MANAGER]SET_PRODUCT_STATUS_SUCCESS',
    SET_PRODUCT_STATUS_ERROR: '[PRODUCT_MANAGER]SET_PRODUCT_STATUS_ERROR',

    SET_PRODUCT_TAGS: '[PRODUCT_MANAGER]SET_PRODUCT_TAGS',
    SET_PRODUCT_TAGS_SUCCESS: '[PRODUCT_MANAGER]SET_PRODUCT_TAGS_SUCCESS',
    SET_PRODUCT_TAGS_ERROR: '[PRODUCT_MANAGER]SET_PRODUCT_TAGS_ERROR',

    REMOVE_PRODUCT_TAGS: '[PRODUCT_MANAGER]REMOVE_PRODUCT_TAGS',
    REMOVE_PRODUCT_TAGS_SUCCESS: '[PRODUCT_MANAGER]REMOVE_PRODUCT_TAGS_SUCCESS',
    REMOVE_PRODUCT_TAGS_ERROR: '[PRODUCT_MANAGER]REMOVE_PRODUCT_TAGS_ERROR',

    LOAD_AVAILABLE_TAG_FILTERS: '[PRODUCT_MANAGER]LOAD_AVAILABLE_TAG_FILTERS',
    LOAD_AVAILABLE_TAG_FILTERS_SUCCESS: '[PRODUCT_MANAGER]LOAD_AVAILABLE_TAG_FILTERS_SUCCESS',
    LOAD_AVAILABLE_TAG_FILTERS_ERROR: '[PRODUCT_MANAGER]LOAD_AVAILABLE_TAG_FILTERS_ERROR',

    DELETE_PRODUCT: '[PRODUCT_MANAGER]DELETE_PRODUCT',
    DELETE_PRODUCT_SUCCESS: '[PRODUCT_MANAGER]DELETE_PRODUCT_SUCCESS',
    DELETE_PRODUCT_ERROR: '[PRODUCT_MANAGER]DELETE_PRODUCT_ERROR',

    // Variations
    GET_VARIATIONS: '[PRODUCT_MANAGER]GET_VARIATIONS',
    GET_VARIATIONS_SUCCESS: '[PRODUCT_MANAGER]GET_VARIATIONS_SUCCESS',
    GET_VARIATIONS_ERROR: '[PRODUCT_MANAGER]GET_VARIATIONS_ERROR',

    TOGGLE_ALL_VARIATIONS: '[PRODUCT_MANAGER]TOGGLE_ALL_VARIATIONS',

    // Graph
    GET_GRAPH: '[PRODUCT_MANAGER]GET_GRAPH',
    GET_GRAPH_SUCCESS: '[PRODUCT_MANAGER]GET_GRAPH_SUCCESS',
    GET_GRAPH_ERROR: '[PRODUCT_MANAGER]GET_GRAPH_ERROR',

    // Competitors List
    GET_COMPETITION_PRODUCTS: '[PRODUCT_MANAGER]GET_COMPETITION_PRODUCTS',
    GET_COMPETITION_PRODUCTS_SUCCESS: '[PRODUCT_MANAGER]GET_COMPETITION_PRODUCTS_SUCCESS',
    GET_COMPETITION_PRODUCTS_ERROR: '[PRODUCT_MANAGER]GET_COMPETITION_PRODUCTS_ERROR',

    ADD_COMPETITOR_PRODUCT: '[PRODUCT_MANAGER]ADD_COMPETITOR_PRODUCT',
    ADD_COMPETITOR_PRODUCT_SUCCESS: '[PRODUCT_MANAGER]ADD_COMPETITOR_PRODUCT_SUCCESS',
    ADD_COMPETITOR_PRODUCT_ERROR: '[PRODUCT_MANAGER]ADD_COMPETITOR_PRODUCT_ERROR',

    REMOVE_COMPETITOR_PRODUCT: '[PRODUCT_MANAGER]REMOVE_COMPETITOR_PRODUCT',
    REMOVE_COMPETITOR_PRODUCT_SUCCESS: '[PRODUCT_MANAGER]REMOVE_COMPETITOR_PRODUCT_SUCCESS',
    REMOVE_COMPETITOR_PRODUCT_ERROR: '[PRODUCT_MANAGER]REMOVE_COMPETITOR_PRODUCT_ERROR',

    // Listings
    GET_DRAFT_AND_LISTINGS: '[PRODUCT_MANAGER]GET_DRAFT_AND_LISTINGS',
    GET_DRAFT_AND_LISTINGS_SUCCESS: '[PRODUCT_MANAGER]GET_DRAFT_AND_LISTINGS_SUCCESS',
    GET_DRAFT_AND_LISTINGS_ERROR: '[PRODUCT_MANAGER]GET_DRAFT_AND_LISTINGS_ERROR',
    SET_CURRENT_DRAFT_VERSION: '[PRODUCT_MANAGER]SET_CURRENT_DRAFT_VERSION',

    SAVE_DRAFT: '[PRODUCT_MANAGER]SAVE_DRAFT',
    SAVE_DRAFT_SUCCESS: '[PRODUCT_MANAGER]SAVE_DRAFT_SUCCESS',
    SAVE_DRAFT_ERROR: '[PRODUCT_MANAGER]SAVE_DRAFT_ERROR',

    PUBLISH_DRAFT: '[PRODUCT_MANAGER]PUBLISH_DRAFT',
    PUBLISH_DRAFT_SUCCESS: '[PRODUCT_MANAGER]PUBLISH_DRAFT_SUCCESS',
    PUBLISH_DRAFT_ERROR: '[PRODUCT_MANAGER]PUBLISH_DRAFT_ERROR',

    // Keywords
    GET_KEYWORDS: '[PRODUCT_MANAGER]GET_KEYWORDS',
    GET_KEYWORDS_SUCCESS: '[PRODUCT_MANAGER]GET_KEYWORDS_SUCCESS',
    GET_KEYWORDS_ERROR: '[PRODUCT_MANAGER]GET_KEYWORDS_ERROR',

    GET_KEYWORD_GRAPH: '[PRODUCT_MANAGER]GET_KEYWORD_GRAPH',
    GET_KEYWORD_GRAPH_SUCCESS: '[PRODUCT_MANAGER]GET_KEYWORD_GRAPH_SUCCESS',
    GET_KEYWORD_GRAPH_ERROR: '[PRODUCT_MANAGER]GET_KEYWORD_GRAPH_ERROR',

    GET_KEYWORDS_HYDRATION_FINISHED: '[PRODUCT_MANAGER]GET_KEYWORDS_HYDRATION_FINISHED',

    UPDATE_KEYWORDS: '[PRODUCT_MANAGER]UPDATE_KEYWORDS',
    UPDATE_KEYWORDS_SUCCESS: '[PRODUCT_MANAGER]UPDATE_KEYWORDS_SUCCESS',
    UPDATE_KEYWORDS_ERROR: '[PRODUCT_MANAGER]UPDATE_KEYWORDS_ERROR',

    ADD_KEYWORDS: '[PRODUCT_MANAGER]ADD_KEYWORDS',
    ADD_KEYWORDS_SUCCESS: '[PRODUCT_MANAGER]ADD_KEYWORDS_SUCCESS',
    ADD_KEYWORDS_ERROR: '[PRODUCT_MANAGER]ADD_KEYWORDS_ERROR',

    REFRESH_INDEX: '[PRODUCT_MANAGER]REFRESH_INDEX',
    REFRESH_INDEX_SUCCESS: '[PRODUCT_MANAGER]REFRESH_INDEX_SUCCESS',
    REFRESH_INDEX_ERROR: '[PRODUCT_MANAGER]REFRESH_INDEX_ERROR',

    CORRECT_KEYWORD: '[PRODUCT_MANAGER]CORRECT_KEYWORD',
    CORRECT_KEYWORD_SUCCESS: '[PRODUCT_MANAGER]CORRECT_KEYWORD_SUCCESS',
    CORRECT_KEYWORD_ERROR: '[PRODUCT_MANAGER]CORRECT_KEYWORD_ERROR',

    GET_BRAND_ANALYTICS_KEYWORD: '[PRODUCT_MANAGER]GET_BRAND_ANALYTICS_KEYWORD',
    GET_BRAND_ANALYTICS_KEYWORD_SUCCESS: '[PRODUCT_MANAGER]GET_BRAND_ANALYTICS_KEYWORD_SUCCESS',
    GET_BRAND_ANALYTICS_KEYWORD_ERROR: '[PRODUCT_MANAGER]GET_BRAND_ANALYTICS_KEYWORD_ERROR',

    SHOW_UPGRADE_PLAN_BANNER: '[PRODUCT_MANAGER]SHOW_UPGRADE_PLAN_BANNER',
    HIDE_UPGRADE_PLAN_BANNER: '[PRODUCT_MANAGER]HIDE_UPGRADE_PLAN_BANNER',

    // BULK ACTIONS
    BULK_COPY_KEYWORDS: '[PRODUCT_MANAGER]BULK_COPY_KEYWORDS',
    BULK_COPY_KEYWORDS_SUCCESS: '[PRODUCT_MANAGER]BULK_COPY_KEYWORDS_SUCCESS',
    BULK_COPY_KEYWORDS_ERROR: '[PRODUCT_MANAGER]BULK_COPY_KEYWORDS_ERROR',

    BULK_DELETE_KEYWORDS: '[PRODUCT_MANAGER]BULK_DELETE_KEYWORDS',
    BULK_DELETE_KEYWORDS_SUCCESS: '[PRODUCT_MANAGER]BULK_DELETE_KEYWORDS_SUCCESS',
    BULK_DELETE_KEYWORDS_ERROR: '[PRODUCT_MANAGER]BULK_DELETE_KEYWORDS_ERROR',

    BULK_RECALCULATE_PRIORITY: '[PRODUCT_MANAGER]BULK_RECALCULATE_PRIORITY',
    BULK_RECALCULATE_PRIORITY_SUCCESS: '[PRODUCT_MANAGER]BULK_RECALCULATE_PRIORITY_SUCCESS',
    BULK_RECALCULATE_PRIORITY_ERROR: '[PRODUCT_MANAGER]BULK_RECALCULATE_PRIORITY_ERROR',
  };

  getProducts(page: number = 1, perPage: number = 20, sort: IFieldOrder[], foreign = false,
              term: string = null, tags: string[] = [], status?: ProductActivityStatusEnum,
  ): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_PRODUCTS,
      data: {
        page,
        perPage,
        sort,
        foreign,
        term,
        tags,
        status,
      }
    };
  }

  resyncProducts(): Action {
    return {
      type: ProductManagerActions.TYPES.RESYNC_PRODUCTS,
    };
  }

  resyncProductsStopped(): Action {
    return {
      type: ProductManagerActions.TYPES.RESYNC_PRODUCTS_COMPLETE,
    };
  }

  addForeignProducts(asins: PastedAsin[]): StAction {
    return {
      type: ProductManagerActions.TYPES.ADD_FOREIGN_PRODUCTS,
      data: asins,
    };
  }

  addForeignProductsSuccess(): Action {
    return {
      type: ProductManagerActions.TYPES.ADD_FOREIGN_PRODUCTS_SUCCESS
    };
  }

  addForeignProductsCloseModal(): Action {
    return {
      type: ProductManagerActions.TYPES.ADD_FOREIGN_PRODUCTS_CLOSE_MODAL
    };
  }

  addForeignProductsError(error): StAction {
    return {
      type: ProductManagerActions.TYPES.ADD_FOREIGN_PRODUCTS_ERROR,
      data: error,
    };
  }

  toggleAllProducts(toggle: boolean): StAction {
    return {
      type: ProductManagerActions.TYPES.TOGGLE_ALL_TABLE_ITEMS,
      data: toggle,
    };
  }

  toggleSingleTableItem(value: string, toggle: boolean): StAction {
    return {
      type: ProductManagerActions.TYPES.TOGGLE_SINGLE_TABLE_ITEM,
      data: {
        value,
        toggle
      },
    };
  }

  getSingleProduct(asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_PRODUCT,
      data: {
        asin,
      }
    };
  }

  setActiveTab(tab: 'graph' | 'details', asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_ACTIVE_TAB,
      data: {
        tab,
        asin
      },
    };
  }

  toggleProduct(asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.TOGGLE_PRODUCT,
      data: {
        asin
      },
    };
  }

  toggleVariationCheck(asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.TOGGLE_VARIATION_CHECK,
      data: asin
    };
  }

  uncheckAllProducts(): Action {
    return {
      type: ProductManagerActions.TYPES.UNCHECK_ALL_PRODUCTS,
    };
  }

  setActiveProduct(product: IProductManagerProduct): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_ACTIVE_PRODUCT,
      data: {
        product,
      }
    };
  }

  setProductStatus(status: ProductActivityStatusEnum, asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_PRODUCT_STATUS,
      data: {
        status,
        asin
      },
    };
  }

  setProductStatusSuccess(response): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_PRODUCT_STATUS_SUCCESS,
      data: {
        response
      },
    };
  }

  setProductStatusError(error): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_PRODUCT_STATUS_ERROR,
      data: {
        error
      }
    };
  }

  setProductTags(tags: string[], asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_PRODUCT_TAGS,
      data: {
        tags,
        asin
      },
    };
  }

  setProductTagsSuccess(response): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_PRODUCT_TAGS_SUCCESS,
      data: {
        ...response
      },
    };
  }

  setProductTagsError(error): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_PRODUCT_TAGS_ERROR,
      data: {
        error
      }
    };
  }

  removeProductTags(tags: string[], asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.REMOVE_PRODUCT_TAGS,
      data: {
        tags,
        asin
      },
    };
  }

  removeProductTagsSuccess(response): StAction {
    return {
      type: ProductManagerActions.TYPES.REMOVE_PRODUCT_TAGS_SUCCESS,
      data: {
        ...response
      },
    };
  }

  removeProductTagsError(error): StAction {
    return {
      type: ProductManagerActions.TYPES.REMOVE_PRODUCT_TAGS_ERROR,
      data: {
        error
      }
    };
  }

  clearActiveProduct(): Action {
    return {
      type: ProductManagerActions.TYPES.CLEAR_ACTIVE_PRODUCT,
    };
  }

  analyzeKeywords(asins: string[], addToAsin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.ANALYZE_KEYWORDS,
      data: {
        asins,
        addToAsin,
      }
    };
  }

  analyzeKeywordsError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.ANALYZE_KEYWORDS,
      data: response,
    };
  }

  archiveProducts(asins: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.ARCHIVE_PRODUCTS,
      data: asins,
    };
  }

  archiveProductsSuccess(response): StAction {
    return {
      type: ProductManagerActions.TYPES.ARCHIVE_PRODUCTS_SUCCESS,
      data: response,
    };
  }

  archiveProductsError(response): StAction {
    return {
      type: ProductManagerActions.TYPES.ARCHIVE_PRODUCTS_ERROR,
      data: response,
    };
  }

  resetProducts(asin: string, reason: string): StAction {
    return {
      type: ProductManagerActions.TYPES.RESET_PRODUCTS,
      data: {
        asin,
        reason
      },
    };
  }

  resetProductsError(response): StAction {
    return {
      type: ProductManagerActions.TYPES.RESET_PRODUCTS_ERROR,
      data: response,
    };
  }

  productManagerProductsLoaded(data: IProductManagerProductsResponse): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_PRODUCTS_SUCCESS,
      data,
    };
  }

  productManagerProductsError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_PRODUCTS_ERROR,
      data: error,
    };
  }

  getProductVariations(asin: string, page: number = 1, limit: number = 20): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_VARIATIONS,
      data: {
        asin,
        limit,
        page,
      }
    };
  }

  productManagerVariationsLoaded(data: IProductManagerVariationsResponse): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_VARIATIONS_SUCCESS,
      data,
    };
  }

  productManagerVariationsError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_VARIATIONS_ERROR,
      data: error,
    };
  }

  getGraphData(asin: string, types: ['bsr'], startDate?: string, endDate?: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_GRAPH,
      data: {
        asin,
        types,
        startDate,
        endDate,
      }
    };
  }

  productManagerGraphDataLoaded(data: IProductManagerGraphDataResponse): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_GRAPH_SUCCESS,
      data,
    };
  }

  productManagerGraphDataError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_GRAPH_ERROR,
      data: error,
    };
  }

  getKeywordGraphData(asin: string, keyword: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_KEYWORD_GRAPH,
      data: {
        asin,
        keyword,
      }
    };
  }

  productManagerKeywordGraphDataLoaded(data: IProductManagerKeywordGraphResponse): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_KEYWORD_GRAPH_SUCCESS,
      data,
    };
  }

  productManagerKeywordGraphDataError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_KEYWORD_GRAPH_ERROR,
      data: error,
    };
  }

  addCompetitorProduct(asin: string, asins: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.ADD_COMPETITOR_PRODUCT,
      data: {
        asin,
        asins,
      },
    };
  }

  addCompetitorProductSuccess(data: { data: IProductManagerVariation }[]): StAction {
    return {
      type: ProductManagerActions.TYPES.ADD_COMPETITOR_PRODUCT_SUCCESS,
      data,
    };
  }

  addCompetitorProductError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.ADD_COMPETITOR_PRODUCT_ERROR,
      data: error,
    };
  }

  removeCompetitorProduct(asin: string, asins: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.REMOVE_COMPETITOR_PRODUCT,
      data: {
        asin,
        asins,
      },
    };
  }

  removeCompetitorProductSuccess(asins: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.REMOVE_COMPETITOR_PRODUCT_SUCCESS,
      data: {
        asins
      },
    };
  }

  removeCompetitorProductError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.REMOVE_COMPETITOR_PRODUCT_ERROR,
      data: error,
    };
  }

  getCompetitorsProducts(asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_COMPETITION_PRODUCTS,
      data: {
        asin,
      }
    };
  }

  getCompetitorsProductsLoaded(data: { data: IProductManagerVariation[] }): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_COMPETITION_PRODUCTS_SUCCESS,
      data,
    };
  }

  getCompetitorsProductsError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_COMPETITION_PRODUCTS_ERROR,
      data: error,
    };
  }

  getDraftAndListings(asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_DRAFT_AND_LISTINGS,
      data: {
        asin,
      },
    };
  }

  getDraftAndListingsSuccess(data: IProductManagerDraftListingResponse, asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_DRAFT_AND_LISTINGS_SUCCESS,
      data: {
        ...data,
        asin,
      }
    };
  }

  getDraftAndListingsError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_DRAFT_AND_LISTINGS_ERROR,
      data: {
        error,
      },
    };
  }

  setCurrentDraft(asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.SET_CURRENT_DRAFT_VERSION,
      data: {asin},
    };
  }

  saveDraft(asin: string, draft: IProductManagerDraft): StAction {
    return {
      type: ProductManagerActions.TYPES.SAVE_DRAFT,
      data: {
        asin,
        draft,
      },
    };
  }

  saveDraftSuccess(draft: IProductManagerDraft): StAction {
    return {
      type: ProductManagerActions.TYPES.SAVE_DRAFT_SUCCESS,
      data: draft
    };
  }

  saveDraftError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.SAVE_DRAFT_ERROR,
      data: error
    };
  }

  publishDraft(asin: string, draft: IProductManagerDraft): StAction {
    return {
      type: ProductManagerActions.TYPES.PUBLISH_DRAFT,
      data: {
        asin,
        draft,
      },
    };
  }

  publishDraftSuccess(draft: IProductManagerDraft): StAction {
    return {
      type: ProductManagerActions.TYPES.PUBLISH_DRAFT_SUCCESS,
      data: draft
    };
  }

  publishDraftError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.PUBLISH_DRAFT_ERROR,
      data: error
    };
  }

  getKeywords(asin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_KEYWORDS,
      data: {
        asin,
      }
    };
  }

  updateKeyword(asin: string, keywords: IProductManagerUpdateKeywordsPayload[]): StAction {
    return {
      type: ProductManagerActions.TYPES.UPDATE_KEYWORDS,
      data: {
        asin,
        keywords,
      },
    };
  }

  updateKeywordSuccess(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.UPDATE_KEYWORDS_SUCCESS,
      data: response,
    };
  }

  updateKeywordError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.UPDATE_KEYWORDS_ERROR,
      data: response,
    };
  }

  addKeywords(asin: string, keywords: string[], minimumPriorityLimit?: string) {
    return {
      type: ProductManagerActions.TYPES.ADD_KEYWORDS,
      data: {
        asin,
        keywords,
        minimumPriorityLimit,
      },
    };
  }

  addKeywordSuccess(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.ADD_KEYWORDS_SUCCESS,
      data: response,
    };
  }

  addKeywordError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.ADD_KEYWORDS_ERROR,
      data: response,
    };
  }

  copyKeywords(asin: string, asins: string[]) {
    return {
      type: ProductManagerActions.TYPES.BULK_COPY_KEYWORDS,
      data: {
        asin,
        asins,
      },
    };
  }

  copyKeywordsSuccess(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.BULK_COPY_KEYWORDS_SUCCESS,
      data: response,
    };
  }

  copyKeywordsError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.BULK_COPY_KEYWORDS_ERROR,
      data: response,
    };
  }

  deleteKeywords(asin: string, keywords: string[]) {
    return {
      type: ProductManagerActions.TYPES.BULK_DELETE_KEYWORDS,
      data: {
        asin,
        keywords,
      },
    };
  }

  deleteKeywordsSuccess(keywords: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.BULK_DELETE_KEYWORDS_SUCCESS,
      data: keywords,
    };
  }

  deleteKeywordsError(keywords: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.BULK_DELETE_KEYWORDS_ERROR,
      data: keywords,
    };
  }

  recalculatePriority(asin: string, keywords: string[]) {
    return {
      type: ProductManagerActions.TYPES.BULK_RECALCULATE_PRIORITY,
      data: {
        asin,
        keywords,
      },
    };
  }

  recalculatePrioritySuccess(keywords: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.BULK_RECALCULATE_PRIORITY_SUCCESS,
      data: keywords,
    };
  }

  recalculatePriorityError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.BULK_RECALCULATE_PRIORITY_ERROR,
      data: response,
    };
  }

  resetState(): Action {
    return {
      type: ProductManagerActions.TYPES.RESET_STATE,
    };
  }

  resetActiveProduct(): Action {
    return {
      type: ProductManagerActions.TYPES.RESET_ACIVE_PRODUCT,
    };
  }

  getKeywordsSuccess(data: IProductManagerKeywordsResponse): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_KEYWORDS_SUCCESS,
      data,
    };
  }

  getKeywordsHydrationFinished(): Action {
    return {
      type: ProductManagerActions.TYPES.GET_KEYWORDS_HYDRATION_FINISHED,
    };
  }

  getKeywordsError(error: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_KEYWORDS_ERROR,
      data: {
        error,
      }
    };
  }

  refreshIndex(asin: string, keywords: string[]): StAction {
    return {
      type: ProductManagerActions.TYPES.REFRESH_INDEX,
      data: {
        asin,
        keywords,
      },
    };
  }

  refreshIndexSuccess(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.REFRESH_INDEX_SUCCESS,
      data: response
    };
  }

  refreshIndexError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.REFRESH_INDEX_ERROR,
      data: response
    };
  }

  correctKeyword(asin: string, keyword: string, updatedKeyword: string): StAction {
    return {
      type: ProductManagerActions.TYPES.CORRECT_KEYWORD,
      data: {
        asin,
        keyword,
        updatedKeyword,
      },
    };
  }

  correctKeywordSuccess(response: { keyword: string, updatedKeyword: string }): StAction {
    return {
      type: ProductManagerActions.TYPES.CORRECT_KEYWORD_SUCCESS,
      data: {
        keyword: response.keyword,
        updatedKeyword: response.updatedKeyword,
      }
    };
  }

  correctKeywordError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.CORRECT_KEYWORD_ERROR,
      data: response
    };
  }

  getKeywordBrandAnalytics(keyword: string): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_BRAND_ANALYTICS_KEYWORD,
      data: {
        keyword,
      },
    };
  }

  getKeywordBrandAnalyticsSuccess(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_BRAND_ANALYTICS_KEYWORD_SUCCESS,
      data: response
    };
  }

  getKeywordBrandAnalyticsError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.GET_BRAND_ANALYTICS_KEYWORD_ERROR,
      data: response
    };
  }

  showUpgradePlanBanner(title: string, message: string): StAction {
    return {
      type: ProductManagerActions.TYPES.SHOW_UPGRADE_PLAN_BANNER,
      data: {
        title,
        message,
      },
    };
  }

  hideUpgradePlanBanner(): Action {
    return {
      type: ProductManagerActions.TYPES.HIDE_UPGRADE_PLAN_BANNER,
    };
  }

  toggleAllVariations(checked: boolean): StAction {
    return {
      type: ProductManagerActions.TYPES.TOGGLE_ALL_VARIATIONS,
      data: checked,
    };
  }

  loadAvailableTagFilters(): StAction {
    return {
      type: ProductManagerActions.TYPES.LOAD_AVAILABLE_TAG_FILTERS,
      data: null
    };
  }

  loadAvailableTagFiltersSuccess(data: any): StAction {
    return {
      type: ProductManagerActions.TYPES.LOAD_AVAILABLE_TAG_FILTERS_SUCCESS,
      data
    };
  }

  loadAvailableTagFiltersError(data: any): StAction {
    return {
      type: ProductManagerActions.TYPES.LOAD_AVAILABLE_TAG_FILTERS_ERROR,
      data
    };
  }

  deleteProduct(productAsin: string): StAction {
    return {
      type: ProductManagerActions.TYPES.DELETE_PRODUCT,
      data: productAsin
    }
  }

  deleteProductSuccess(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.DELETE_PRODUCT_SUCCESS,
      data: response
    };
  }

  deleteProductError(response: any): StAction {
    return {
      type: ProductManagerActions.TYPES.DELETE_PRODUCT_ERROR,
      data: response
    };
  }
}
