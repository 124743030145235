import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { Survey, SurveyQuestion, SurveyQuestionOption } from "@app/shared/data/survey/survey.models";
import { SurveyActions } from "@app/shared/data/survey/survey.actions";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { Subject } from "rxjs";
import { ModalComponent } from "@app/shared/components/modals/modal/modal.component";
import { takeUntil } from 'rxjs/operators';
import { SurveyAppState } from '@app/shared/data/survey/survey.reducer';

@Component({
  selector   : 'st-cancellation-survey',
  templateUrl: './cancellation-survey.component.html',
  styleUrls  : ['./cancellation-survey.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CancellationSurveyComponent implements OnInit, OnDestroy {
  showFeedback$: Subject<boolean> = new Subject<boolean>();

  survey: Survey;
  surveyLoading: boolean;
  surveyError: string;
  surveySubmitting: boolean;

  surveySubmitQuestions: SurveyQuestion[] = [];

  surveyId = 1;

  formSubmitted = false;
  step: number = 1;

  private $destroy: Subject<void> = new Subject<void>();

  constructor(
    private store: NgRedux<AppState>,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit(): void {
    this.store.dispatch(SurveyActions.getSurvey(this.surveyId));

    this.store.select('survey')
      .pipe(
        takeUntil(this.$destroy)
      ).subscribe((surveyState: SurveyAppState) => {
        this.survey = surveyState.data;
        this.surveyLoading = surveyState.loading;
        this.surveyError = surveyState.error;
        this.surveySubmitting = surveyState.submitting;

        if (this.formSubmitted && !surveyState.error && !surveyState.submitting) {
          this.step = 2;
        }

        if (this.canDetectChanges) {
          this.cd.detectChanges();
        }
      });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  public abort(modal: ModalComponent) {
    return modal.close();
  }

  public cancelSubscription(modal: ModalComponent) {
    // with true, will cancel the subscription
    return modal.close(true);
  }

  public submitSurvey() {
    if (!this.canContinue) {
      return;
    }

    this.formSubmitted = true;
    this.store.dispatch(SurveyActions.submit(this.submitFormValues));
  }

  onCheckboxChange(event: { questionId: number, optionId: number }) {
    this.insertSurveySubmitQuestion(event.questionId);

    this.surveySubmitQuestions = this.surveySubmitQuestions.map((question: SurveyQuestion) => {
      if (question.id !== event.questionId) {
        return {
          id: question.id,
          value: question.value,
        };
      }

      const alreadyHasOption = question.value.some((option) => option.id === event.optionId);
      const originalQuestion = this.survey.questions.find((question: SurveyQuestion) => question.id === event.questionId);

      return {
        id: question.id,
        value: alreadyHasOption ?
          question.value.filter((option) => option.id !== event.optionId) :
          [...question.value, { id: originalQuestion.options.find((option: SurveyQuestionOption) => option.id === event.optionId).id }]
      };
    });
  }

  onDrodpownChange(event: { questionId: number, optionId: number }) {
    this.insertSurveySubmitQuestion(event.questionId);

    this.surveySubmitQuestions = this.surveySubmitQuestions.map((question: SurveyQuestion) => {
      if (question.id !== event.questionId) {
        return question;
      }

      const originalQuestion = this.survey.questions.find((question: SurveyQuestion) => question.id === event.questionId);

      return {
        ...question,
        value: [{ id: originalQuestion.options.find((option: SurveyQuestionOption) => option.id === event.optionId).id }]
      };
    });
  }

  onTextChange(event: { questionId: number, value: string }) {
    this.insertSurveySubmitQuestion(event.questionId);
    
    this.surveySubmitQuestions = this.surveySubmitQuestions.map((question: SurveyQuestion) => {
      if (question.id !== event.questionId) {
        return {
          id: question.id,
          value: question.value
        };
      }

      return {
        id: question.id,
        value: event.value
      };
    });
  }

  deleteAccount() {
    this.step = 3;
  }

  private insertSurveySubmitQuestion(questionId): void {
    if (this.surveySubmitQuestions.map((question: SurveyQuestion) => question.id).includes(questionId)) {
      return;
    }

    const question = this.survey.questions.find((question: SurveyQuestion) => question.id === questionId);

    if (!question) {
      return;
    }

    this.surveySubmitQuestions.push({
      id: question.id,
      value: ['short_text', 'long_text'].includes(question.type) ? null : [],
    });
  }

  get submitFormValues(): { id: number, questions: SurveyQuestion[] } {
    return {
      id: this.survey.id,
      questions: this.surveySubmitQuestions,
    }
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }

  get canContinue(): boolean {
    if (!this.survey) {
      return false;
    }

    return this.survey.questions.length === this.surveySubmitQuestions.length;
  }
}
