<st-modal #modal="modal" [showInstructions]="false">
  <div modal-header class="modal-header">
    <div>
      <h2 i18n>Add keywords manually</h2>
      <p i18n>All keywords that are added manually will automatically have the highest priority. You can edit the priority in your Keyword manager.To separate keywords/phrases press Enter.</p>
    </div>
  </div>
  <div modal-body class="content">
    <div class="keywords-wrapper">
      <st-textarea-tags-v2 (tagsChange)="onTagsChange($event)"></st-textarea-tags-v2>
    </div>
  </div>
  <div modal-footer class="footer">
    <st-button-v2 (clicked)="cancel()" color="empty" label="Cancel" i18n-label></st-button-v2>
    <st-button-v2 (clicked)="save()" [disabled]="confirmButtonDisabled" label="Confirm & Add to Keywords manager" i18n-label></st-button-v2>
  </div>
</st-modal>
