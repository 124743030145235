<div class="campaign-manager-white-box__title">
  <span i18n>Product Selection & Campaign Name</span>
  <span class="page-number">2/8</span>
</div>

<st-campaign-manager-browse-products (click)="browseProducts()" [preselectedProduct]="selectedProduct"></st-campaign-manager-browse-products>

<div class="campaign-manager-white-box__section-title mt-2" i18n>Short Product Name</div>
<div class="campaign-manager--counter campaign-manager__input--top-margin">{{(productName && productName.length) || 0}} characters / {{productShortNameMaxLength}}</div>
<input type="text" class="campaign-manager__input" maxlength="productShortNameMaxLength" [(ngModel)]="productName" (onEvent)="updateProductName()" debounceKeyUp [debounceTime]="300" placeholder="DEFINE THE SHORT NAME FOR YOUR PRODUCT" i18n-placeholder data-qa="cm_campaign_short_name_input">
<st-error class="campaign-manager__input--top-margin" *ngIf="productName && productName.length > productShortNameMaxLength" i18n>Maximum {{productShortNameMaxLength}} characters</st-error>

<div class="campaign-manager__separator"></div>

<div class="campaign-manager-white-box__section-title" i18n>Campaign Name</div>
<div class="campaign-manager__body-text" i18n>Give your campaign a unique name which is easy to identify.</div>

<div class="campaign-name-wrapper">
  <input type="text"
    class="campaign-manager__input campaign-manager__input--top-margin"
    [class.confirmed]="campaignManagerNameConfirmed"
    [formControl]="campaignNameControl"
    placeholder="NAME OF YOUR CAMPAIGN"
    i18n-placeholder
    data-qa="cm_campaign_setup_campaign_name_input">
  <div
    [class.campaign-name-wrapper__text--error]="!checkingCampaignName && campaignNameError"
    [class.campaign-name-wrapper__text--success]="(!checkingCampaignName && !campaignNameError) || campaignManagerNameConfirmed"
    class="campaign-name-wrapper__text"
    *ngIf="campaignNameChecked || campaignManagerNameConfirmed">
    <ng-container *ngIf="checkingCampaignName && campaignName" i18n>Checking name availability...</ng-container>
    <ng-container *ngIf="!checkingCampaignName && campaignNameError && campaignName">
      <st-icon icon="red-error"></st-icon>
      <span i18n>Name not available. Try different one.</span>
    </ng-container>
    <ng-container *ngIf="!checkingCampaignName && !campaignNameError && !campaignManagerNameConfirmed">
      <st-icon icon="blue-success"></st-icon>
      <span i18n>Name accepted!</span>
    </ng-container>
    <ng-container *ngIf="campaignManagerNameConfirmed">
      <st-icon icon="blue-success"></st-icon>
      <span i18n>Name confirmed & saved</span>
    </ng-container>
  </div>
</div>

<div class="campaign-manager__url-wrapper">
  <label class="campaign-manager__body-text" i18n>URL Destination</label>

  <div class="campaign-manager__url-destination">{{urlDestinationSlug}}</div>
</div>

<div class="campaign-manager__separator"></div>

<div class="campaign-manager-white-box__section-title" i18n>Support Email</div>
<div class="campaign-manager__body-text" i18n>Provide an email address your customers can use to contact you for support</div>

<input type="text" class="campaign-manager__input campaign-manager__input--top-margin"
  [class.error]="emailAddressControl.value && !emailAddressControl.valid" [formControl]="emailAddressControl"
  placeholder="ENTER SUPPORT EMAIL ADDRESS" i18n-placeholder
  data-qa="cm_campaign_setup_email_input">
