import { ChartEventsOptions, ChartOptions } from "highcharts";
import { ChartEvent, chartEvents } from "@app/shared/components/charts/models/chart.events";
import { ChartComponentInterface } from "@app/shared/components/charts/models/highchart-options.model";


export class Chart extends Object implements ChartOptions {
  renderTo: string | HTMLElement;
  events: ChartEventsOptions;
  type: string;

  constructor(element: HTMLElement, options: any = {}) {
    super();
    Object.assign(this, options);
    this.setRenderTo(element);
  }

  listenForEvents(component: ChartComponentInterface) {
    this.events = {}; // reset events!

    chartEvents.forEach((eventType) => {
      this.events[eventType] = (event: Event) => {
        component[eventType].emit(new ChartEvent(event, this));
      }
    });
  }

  public setRenderTo(element: string | HTMLElement) {
    this.renderTo = element;
  }

  setType(value: string) {
    this.type = value;
  }
}
