import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { AdminSurvey } from "@app/shared/data/admin/survey/survey.models";

@Injectable()
export class AdminSurveyActions extends PaginationActions {
  protected model = AdminSurvey;

  static readonly TYPES = {
    SET_SELECTED: '[ADMINSURVEYS]SET_SELECTED',

    GET          : '[ADMINSURVEYS]GET',
    GET_SUCCEEDED: '[ADMINSURVEYS]GET_SUCCEEDED',
    GET_FAILED   : '[ADMINSURVEYS]GET_FAILED',

    GET_RESULTS          : '[ADMINSURVEYS]GET_RESULTS',
    GET_RESULTS_SUCCEEDED: '[ADMINSURVEYS]GET_RESULTS_SUCCEEDED',
    GET_RESULTS_FAILED   : '[ADMINSURVEYS]GET_RESULTS_FAILED',

    SAVE          : '[ADMINSURVEYS]SAVE',
    SAVE_SUCCEEDED: '[ADMINSURVEYS]SAVE_SUCCEEDED',
    SAVE_FAILED   : '[ADMINSURVEYS]SAVE_FAILED',

    REMOVE          : '[ADMINSURVEYS]REMOVE',
    REMOVE_SUCCEEDED: '[ADMINSURVEYS]REMOVE_SUCCEEDED',
    REMOVE_FAILED   : '[ADMINSURVEYS]REMOVE_FAILED',
  };

  static save(survey: AdminSurvey): StAction {
    return {
      type: AdminSurveyActions.TYPES.SAVE,
      data: survey,
    }
  }

  static saveSuccedded(survey: AdminSurvey): StAction {
    return {
      type: AdminSurveyActions.TYPES.SAVE_SUCCEEDED,
      data: survey
    }
  }

  static saveFailed(error): StAction {
    return {
      type: AdminSurveyActions.TYPES.SAVE_FAILED,
      data: error
    }
  }

  static setSelected(survey: AdminSurvey): StAction {
    return {
      type: AdminSurveyActions.TYPES.SET_SELECTED,
      data: survey
    }
  }

  static getSurvey(survey: AdminSurvey): StAction {
    if (isNaN(survey.id)) {
      return {
        type: AdminSurveyActions.TYPES.GET_SUCCEEDED,
        data: new AdminSurvey({ name: null, description: null, questions: [] })
      };
    }
    return {
      type: AdminSurveyActions.TYPES.GET,
      data: survey
    };
  }

  static getSurveySucceeded(survey: AdminSurvey): StAction {
    return {
      type: AdminSurveyActions.TYPES.GET_SUCCEEDED,
      data: survey
    }
  }

  static getSurveyFailed(error): StAction {
    return {
      type: AdminSurveyActions.TYPES.GET_FAILED,
      data: error
    }
  }

  static getSurveyResults(survey: AdminSurvey): StAction {
    return {
      type: AdminSurveyActions.TYPES.GET_RESULTS,
      data: survey
    };
  }

  static getSurveyResultsSucceeded(survey: AdminSurvey): StAction {
    return {
      type: AdminSurveyActions.TYPES.GET_RESULTS_SUCCEEDED,
      data: survey
    }
  }

  static getSurveyResultsFailed(error): StAction {
    return {
      type: AdminSurveyActions.TYPES.GET_RESULTS_FAILED,
      data: error
    }
  }
}
