import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AccessAcademyComponent } from '@app/shared/layout/access-academy/access-academy.component';

const COMPONENTS = [
  AccessAcademyComponent
];
const DIRECTIVES = [
];

@NgModule({
  imports     : [
    CommonModule,
    RouterModule
  ],
  declarations: [
    ...COMPONENTS, ...DIRECTIVES,
  ],
  exports     : [
    ...COMPONENTS, ...DIRECTIVES,
  ]
})
export class AccessAcademyModule {
}
