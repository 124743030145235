import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { LastLaunchPromotion } from "@app/shared/data/last-launch-promotion/last-launch-promotion.models";
import { StAction } from "@app/shared/data/st-action";
import { LastLaunchSchedule } from "@app/shared/data/last-launch-schedule/last-launch-schedule.models";
import {Filter} from "@app/shared/components/filter-builder/filter/filter";

@Injectable()
export class LastLaunchPromotionActions extends PaginationActions {
  protected model = LastLaunchPromotion;

  static readonly TYPES = {
    FILTER: '[LAST_LAUNCH_PROMOTION]FILTER',

    CANCEL          : '[LAST_LAUNCH_PROMOTION]CANCEL',
    CANCEL_SUCCEEDED: '[LAST_LAUNCH_PROMOTION]CANCEL_SUCCEEDED',
    CANCEL_FAILED   : '[LAST_LAUNCH_PROMOTION]CANCEL_FAILED',

    LOAD_PROMOTION_REPORT          : '[LAST_LAUNCH_PROMOTION]LOAD_PROMOTION_REPORT',
    LOAD_PROMOTION_REPORT_SUCCEEDED: '[LAST_LAUNCH_PROMOTION]LOAD_PROMOTION_REPORT_SUCCEEDED',
    LOAD_PROMOTION_REPORT_FAILED   : '[LAST_LAUNCH_PROMOTION]LOAD_PROMOTION_REPORT_FAILED',
  };

  filter(filters: {}): StAction {
    return {
      type: LastLaunchPromotionActions.TYPES.FILTER,
      data: filters
    }
  }

  cancel(promotion): StAction {
    return {
      type: LastLaunchPromotionActions.TYPES.CANCEL,
      data: {
        promotion: promotion,
      }
    }
  }

  cancelSucceeded(promotion): StAction {
    return {
      type: LastLaunchPromotionActions.TYPES.CANCEL_SUCCEEDED,
      data: {
        promotion: promotion,
      }
    }
  }

  cancelFailed(error): StAction {
    return {
      type: LastLaunchPromotionActions.TYPES.CANCEL_FAILED,
      data: {
        error: error,
      }
    }
  }

  loadPromotionReport(id): StAction {
    return {
      type: LastLaunchPromotionActions.TYPES.LOAD_PROMOTION_REPORT,
      data: {
        id: id,
      }
    }
  }

  loadPromotionReportSucceeded(promotion: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchPromotionActions.TYPES.LOAD_PROMOTION_REPORT_SUCCEEDED,
      data: {
        promotion: promotion,
      }
    }
  }

  loadPromotionReportFailed(error): StAction {
    return {
      type: LastLaunchPromotionActions.TYPES.LOAD_PROMOTION_REPORT_FAILED,
      data: {
        error: error,
      }
    }
  }
}
