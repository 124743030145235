import { BaseModel, Collection } from '@app/shared/data/base.models';
import { Credential } from '@app/shared/data/credential/credential.models';
import { LastLaunchPromotion } from '@app/shared/data/last-launch-promotion/last-launch-promotion.models';
import { UserAdmin } from '@app/shared/data/admin/user/user-admin.models';

export class LastLaunchAdminPromotion extends LastLaunchPromotion {
  static className = 'LastLaunchAdminPromotion';

  id: number;
  type: string;
  clicks: number;
  units: number;
  url: string;
  payment_id?: string;
  paid_at?: string;
  coupon?: string;
  is_paid?: boolean;
  is_refunded: boolean;
  refunded_amount: number;

  user?: UserAdmin;
  credential?: Credential;
  product?: LastLaunchAdminProduct;
  keywords?: Collection<LastLaunchAdminKeyword>;
  coupons?: Collection<LastLaunchAdminCoupon>;
  notes?: Collection<LastLaunchAdminNote>;
  dateDistributions?: any[];
  visible_to_user: boolean;
  accepting_new_emails: boolean;
  is_approved: boolean;

  landing_page_title?: string;
  landing_page_description?: string;
  landing_page_original_price?: number;
  landing_page_discounted_price?: number;

  get payment_status() {
    if (this.is_approved) {
      return 'APPROVED';
    }

    if (this.is_paid) {
      return 'PAID';
    }

    return 'DRAFT';
  }
}

export class LastLaunchAdminKeyword extends BaseModel {
  static className = 'LastLaunchAdminKeyword';

  id: number;
  value: string;
  url?: string;
  desired_clicks: number;
  impressions?: number;
  no_of_competitors?: number;
  relevancy?: string;
  suggested_giveaways?: number;
  clicks: number;
  ranked_asin_ranking_start?: number;
  ranked_asin_ranking_end?: number;
  last_synced_at?: Date;
}

export class LastLaunchAdminProduct extends BaseModel {
  id: number;
  asin: string;
  parent_asin: string;
  title: string;
  image_url: string;

  price_before: number;
  price_after: number;
}

export class LastLaunchAdminSlot extends BaseModel {
  id: number;
  date: string;
  available: number;
  used: number;
}

export class LastLaunchAdminCoupon extends BaseModel {
  id: number;
  value: string;
}

export class LastLaunchAdminNote extends BaseModel {
  id: number;
  author: string;
  content: string;
  created_at: string;
}

export class LastLaunchAdminScheduledEmail extends BaseModel {
  id: number;
  name: string;
  date: string;
  no_of_contacts?: number;

  marketplace: any;

  promotions?: Collection<LastLaunchAdminPromotion>;
}
