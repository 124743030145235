import { Injectable } from '@angular/core';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { StAction } from '@app/shared/data/st-action';
import { ReverseAsin } from '@app/shared/data/reverse-asin/reverse-asin.models';
import { Filter } from '@app/shared/components/filter-builder/filter/filter';

@Injectable()
export class ReverseAsinActions extends PaginationActions {
  static readonly _events = {
    keywords_received: 'reverse_asin_keywords_received',
    progress: 'reverse_asin_progress',
  };

  static readonly FIND_PRODUCTS_FOR_KEYWORDS_TASK_ID = 'r2a';

  static readonly TYPES = {
    SET_ACTIVE_ASIN   : '[REVERSEASIN]SET_ACTIVE_ASIN',
    KEYWORDS_REQUESTED: '[REVERSEASIN]KEYWORDS_REQUESTED',
    LOAD_RECENT       : '[REVERSEASIN]LOAD_RECENT',
    SET_RECENT        : '[REVERSEASIN]SET_RECENT',
    RECENT_FAILED     : '[REVERSEASIN]RECENT_FAILED',
    APPLY_FILTERS     : '[REVERSEASIN]APPLY_FILTERS',
    TOGGLE_UNFINISHED : '[REVERSEASIN]TOGGLE_UNFINISHED',
    SET_COMPLETE_DATA : '[REVERSEASIN]SET_COMPLETE_DATA',
    SET_FILTERED      : '[REVERSEASIN]SET_FILTERED',
    FINISHED          : '[REVERSEASIN]FINISHED',
    RESET             : '[REVERSEASIN]RESET',
    LOAD_HISTORY      : '[REVERSEASIN]LOAD_HISTORY',
    LOAD_HISTORY_SUCCEEDED      : '[REVERSEASIN]LOAD_HISTORY_SUCCEEDED',
    LOAD_HISTORY_FAILED      : '[REVERSEASIN]LOAD_HISTORY_FAILED',
    FIND_PRODUCTS_FOR_KEYWORD: '[REVERSEASIN]FIND_PRODUCTS_FOR_KEYWORD',
    FIND_PRODUCTS_FOR_KEYWORD_SUCCESS: '[REVERSEASIN]FIND_PRODUCTS_FOR_KEYWORD_SUCCESS',
    FIND_PRODUCTS_FOR_KEYWORD_FAILED: '[REVERSEASIN]FIND_PRODUCTS_FOR_KEYWORD_FAILED',
    FIND_PRODUCTS_FOR_KEYWORD_CLEAR: '[REVERSEASIN]FIND_PRODUCTS_FOR_KEYWORD_CLEAR',
    SET_PROGRESS: '[REVERSEASIN]SET_PROGRESS',
  };

  protected model = ReverseAsin;

  setActiveAsin(asin: string, group_id: string, button_loading: boolean): StAction {
    return {
      type: ReverseAsinActions.TYPES.SET_ACTIVE_ASIN,
      data: {
        asin          : asin,
        group_id      : group_id,
        button_loading: button_loading
      }
    };
  }

  requestKeywords(
    asins: string[],
    compare_asin: string,
    group_id: string,
    marketplace: { id: number },
    depth: number,
    resolveChildren: boolean = false
  ): StAction {
    return {
      type: ReverseAsinActions.TYPES.KEYWORDS_REQUESTED,
      data: {
        group_id: group_id,
        marketplace: marketplace,
        depth: depth,
        resolveChildren: resolveChildren,
        asins: asins,
        compare_asin: compare_asin,
      },
    };
  }

  setFinished(info, keywords, stats, groupId): StAction {
    return {
      type: ReverseAsinActions.TYPES.FINISHED,
      data: {
        keywords,
        group_id: groupId,
        info: info,
        stats: stats,
      }
    };
  }

  applyFilters(filters: Filter[]): StAction {
    return {
      type: ReverseAsinActions.TYPES.APPLY_FILTERS,
      data: filters
    };
  }

  toggleUnfinished(show: boolean): StAction {
    return {
      type: ReverseAsinActions.TYPES.TOGGLE_UNFINISHED,
      data: show
    };
  }

  setCompleteData(data) {
    return {
      type: ReverseAsinActions.TYPES.SET_COMPLETE_DATA,
      data: { data }
    };
  }

  setFiltered(data) {
    return {
      type: ReverseAsinActions.TYPES.SET_FILTERED,
      data: { data }
    };
  }

  reset(): StAction {
    return {
      type: ReverseAsinActions.TYPES.RESET,
      data: {}
    };
  }

  loadHistory(): StAction {
    return {
      type: ReverseAsinActions.TYPES.LOAD_HISTORY,
      data: {}
    };
  }

  loadHistorySucceeded(data): StAction {
    return {
      type: ReverseAsinActions.TYPES.LOAD_HISTORY_SUCCEEDED,
      data: data
    }
  }

  loadHistoryFailed(error): StAction {
    return {
      type: ReverseAsinActions.TYPES.LOAD_HISTORY_FAILED,
      data: error
    };
  }

  findProductsForKeyword(keyword: string): StAction {
    return {
      type: ReverseAsinActions.TYPES.FIND_PRODUCTS_FOR_KEYWORD,
      data: {
        keyword: keyword,
      }
    };
  }

  findProductsForKeywordFailed(error: any): StAction {
    return {
      type: ReverseAsinActions.TYPES.FIND_PRODUCTS_FOR_KEYWORD_FAILED,
      data: {
        error: error,
      }
    };
  }

  findProductsForKeywordClear(): StAction {
    return {
      type: ReverseAsinActions.TYPES.FIND_PRODUCTS_FOR_KEYWORD_CLEAR,
      data: {}
    };
  }

  setProgress(step: number, progress: number): StAction {
    return {
      type: ReverseAsinActions.TYPES.SET_PROGRESS,
      data: {
        step,
        progress
      }
    };
  }
}
