
import {catchError,  map ,  skip ,  takeUntil } from 'rxjs/operators';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { ProductManagerAPI } from '@app/shared/data/product-manager/product-manager.api';
import { IProductManagerProduct, IProductManagerProductsResponse } from '@app/shared/data/product-manager/product-manager.models';
import { handleError } from '@app/helpers';

@Component({
  selector: 'st-product-select',
  templateUrl: './product-select.component.html',
  styleUrls: ['./product-select.component.scss']
})
export class ProductSelectComponent implements OnInit, OnDestroy {
  @Input()
  predefinedAsins: string[];

  @Output()
  asinsUpdated = new EventEmitter<string[]>();

  public searchQuery: string;
  public searching$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public searchErrors$: BehaviorSubject<string> = new BehaviorSubject(null);
  public searchResults$: BehaviorSubject<IProductManagerProduct[]> = new BehaviorSubject([]);
  public selectedProducts$: BehaviorSubject<IProductManagerProduct[]> = new BehaviorSubject([]);

  destroy$ = new Subject<void>();

  constructor(private productApi: ProductManagerAPI) { }

  ngOnInit() {
    if (this.predefinedAsins.length > 0) {
      this.search(null, this.predefinedAsins, true);
    }

    this.selectedProducts$
        .pipe(
          takeUntil(this.destroy$),
          skip(1)
        )
      .subscribe(() => {
        this.asinsUpdated.emit(this.getAsinsFromSelected());
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchWithQuery() {
    this.search(this.searchQuery);
  }

  search(searchTerm?: string, asins?: string[], autoSelect: boolean = false) {
    this.searching$.next(true);
    this.productApi.getProducts(1, 50, null, false, searchTerm, null, null, asins)
        .pipe(
          map((response: IProductManagerProductsResponse) => response.data)
        ).pipe(
        catchError(handleError))
        .subscribe(
        (products: IProductManagerProduct[]) => {
          if (autoSelect) {
            this.selectedProducts$.next(products);
          } else {
            this.searchResults$.next(
              // Do not show already selected products
              products.filter(
                (p: IProductManagerProduct) => !this.selectedProducts$.getValue()
                                                   .filter((p1: IProductManagerProduct) => p1.asin === p.asin).length
              )
            );
          }
          this.searching$.next(false);
          this.searchErrors$.next(null);
        },
        (response) => {
          this.searchResults$.next([]);
          this.searching$.next(false);
          this.searchErrors$.next(response.message);
        }
      );
  }

  selectProduct(product: IProductManagerProduct) {
    this.addProduct(product, this.selectedProducts$);
    this.removeProduct(product, this.searchResults$);
  }

  deselectProduct(product: IProductManagerProduct) {
    this.addProduct(product, this.searchResults$);
    this.removeProduct(product, this.selectedProducts$);
  }

  private getAsinsFromSelected() {
    return this.selectedProducts$.getValue().map((p: IProductManagerProduct) => p.asin);
  }

  private addProduct(product: IProductManagerProduct, collection$: BehaviorSubject<IProductManagerProduct[]>) {
    const coll = collection$.getValue();
    coll.push(product);
    collection$.next(coll);
  }

  private removeProduct(product: IProductManagerProduct, collection$: BehaviorSubject<IProductManagerProduct[]>) {
    collection$.next(collection$.getValue().filter((p: IProductManagerProduct) => p.asin !== product.asin));
  }
}
