import { Injectable } from '@angular/core';
import { Collection } from '@app/shared/data/base.models';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { ToastService } from '@app/core/services/toast.service';
import { StAction } from '@app/shared/data/st-action';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { AlertApi } from '@app/shared/data/alert/alert.api';
import { AlertGroup, AlertTemplate, BEAlert, ProductAlerts } from '@app/shared/data/alert/alert.models';
import { AlertActions } from '@app/shared/data/alert/alert.actions';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AlertsService } from '@app/client/alerts/alerts.service';
import { UserAlertActions } from '@app/shared/data/alert/user/user-alert.actions';

@Injectable()
export class AlertEpics {

  constructor(private store: NgRedux<AppState>,
              private alertApi: AlertApi,
              private alertActions: AlertActions,
              private userAlertActions: UserAlertActions,
              private toastService: ToastService,
              private i18n: I18n) {
  }

  public createEpic() {
    return [
      this.loadPage,
      this.loadProductsData,
      this.saveAlert,
      this.deleteAlert,
      this.bulkDeleteAlerts,
      this.loadAlertsToModal,
      this.acknowledgeIssue,
      this.bulkAcknowledgeIssues,
      this.saveTemplate,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === PaginationActions.getPagActionTypes(ProductAlerts).LOAD_PAGE
        || action.type === AlertActions.TYPES.SAVE_SUCCESS
        || action.type === AlertActions.TYPES.REMOVE_SUCCESS
        || action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUES_SUCCESS
      ) {
        // Fixes issue with accordion directive not detecting changes
        this.store.dispatch(
          this.alertActions.resetAlerts(),
        );

        // Request alert products
        const state = store.getState()['alerts'];
        const filters = {
          searchTerm: state['_filters']['search'] || '',
        };
        const params = {
          ...state['_be_pagination'],
          ...filters,
          sort: state['_be_pagination']['sort'].join(','),
        };
        this.alertApi.getProducts(params)
            .subscribe(
              (productsWithAlerts: Collection<ProductAlerts>) => {
                this.store.dispatch(
                  this.alertActions.setAlerts(productsWithAlerts),
                );
              },
              (response) => {
                this.store.dispatch(this.alertActions.loadPageFailed(response));
              },
            );
      }
      return n;
    };
  };

  loadProductsData = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === AlertActions.TYPES.LOAD_PRODUCTS_DATA) {
        this.alertApi.getProductsData(action.data)
            .subscribe(
              (productsWithAlerts: Collection<ProductAlerts>) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(
                  this.alertActions.updateAlerts(productsWithAlerts),
                );
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.alertActions.loadPageFailed(response));
              },
            );
      }
      return n;
    };
  };

  saveAlert = store => next => {
    return (action: StAction) => {
      if (action.type === AlertActions.TYPES.SAVE) {
        if (store.getState()['alerts']['loading']) {
          return;
        }

        const group: AlertGroup = action.data.subscription;
        const source: string = action.data.source;
        this.alertApi.save(group, source).subscribe(
          () => {
            if (source === AlertsService.userSource) {
              this.store.dispatch(this.userAlertActions.saveSuccess());
            } else {
              this.store.dispatch(this.alertActions.saveSuccess());
            }
          },
          (response) => {
            //noinspection TypeScriptValidateTypes
            this.store.dispatch(this.alertActions.saveFailed(response));
            this.toastService.error(
              this.i18n('Error creating alert'),
              this.i18n('Maximum number of alerts reached! Limit is {{val}} alerts.', {val: response.error.error.message}),
            );
          },
          () => {
          },
        );
      }

      return next(action);
    };
  };

  deleteAlert = store => next => {
    return (action: StAction) => {
      if (action.type === AlertActions.TYPES.REMOVE) {
        if (store.getState()['alerts']['loading']) {
          return;
        }

        this.alertApi.delete(action.data.alert, AlertsService.productSource, action.data.asin)
            .subscribe(
              (alert: BEAlert) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.alertActions.removeSuccess());
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.alertActions.removeFailed(response));
              },
              () => {
              },
            );
      }

      return next(action);
    };
  };

  bulkDeleteAlerts = store => next => {
    return (action: StAction) => {
      if (action.type === AlertActions.TYPES.REMOVE_FOR_ASIN
        || action.type === AlertActions.TYPES.REMOVE_ALL) {
        if (store.getState()['alerts']['loading']) {
          return;
        }

        this.alertApi.bulkDelete(action.data)
            .subscribe(
              () => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.alertActions.removeSuccess());
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.alertActions.removeFailed(response));
              },
              () => {
              },
            );
      }

      return next(action);
    };
  };

  loadAlertsToModal = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === AlertActions.TYPES.LOAD_MODAL_DATA) {
        this.alertApi.getProductAlertGroup(action.data.asin)
            .subscribe(
              (group: AlertGroup) => {
                this.store.dispatch(this.alertActions.setModalData(group));
              },
              (response) => {
                this.store.dispatch(this.alertActions.setModalDataFailed(response));
              },
            );
      }
      return n;
    };
  };

  acknowledgeIssue = store => next => {
    return (action: StAction) => {
      if (action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUE) {
        if (store.getState()['alerts']['acknowledging']) {
          return;
        }

        this.alertApi.acknowledgeIssues({id: action.data.issue.id, source: AlertsService.productSource}).subscribe(
          () => {
            this.store.dispatch(this.alertActions.acknowledgedIssueSucceeded(action.data.issue));
          },
          (response) => {
            this.store.dispatch(this.alertActions.acknowledgedIssueFailed(response.error.error.message));
          },
          () => {
          },
        );
      }

      return next(action);
    };
  };

  bulkAcknowledgeIssues = store => next => {
    return (action: StAction) => {
      if (action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUES_FOR_PRODUCT
        || action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUES) {
        if (store.getState()['alerts']['acknowledging']) {
          return;
        }

        this.alertApi.acknowledgeIssues(action.data).subscribe(
          () => {
            this.store.dispatch(this.alertActions.acknowledgedIssuesSucceeded());
          },
          (response) => {
            this.store.dispatch(this.alertActions.acknowledgedIssuesFailed(response.error.error.message));
          },
          () => {
          },
        );
      }

      return next(action);
    };
  };

  saveTemplate = store => next => {
    return (action: StAction) => {
      if (action.type === AlertActions.TYPES.TEMPLATE_SAVE) {
        if (store.getState()['alerts']['loading']) {
          return;
        }

        const template: AlertTemplate = action.data.template;
        this.alertApi.saveTemplate(template).subscribe(
          () => {
            this.store.dispatch(this.alertActions.saveTemplateSuccess());
            this.toastService.success(
              this.i18n('Success'),
              this.i18n('Template saved'));
          },
          (response) => {
            //noinspection TypeScriptValidateTypes
            this.store.dispatch(this.alertActions.saveTemplateFailed(response));
            this.toastService.error(
              this.i18n('Error creating template'),
              this.i18n('Something went wrong when creating template'));
          },
          () => {
          },
        );
      }

      return next(action);
    };
  };
}
