import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { ReverseAsinAPI } from '@app/shared/data/reverse-asin/reverse-asin.api';
import { R2AActionsV2 } from './reverse-asin.actions';
import { of, timer, merge, throwError } from 'rxjs';
import { environment } from '@env/environment';
import { filter, mergeMap, switchMapTo, takeUntil } from 'rxjs/operators';
import { R2AV2InitialState } from './reverse-asin.reducers';
import { ErrorCodesEnum, IRequestKeywordsResponse } from './reverse-asin.models';
import { StAction } from '@app/shared/data/st-action';
import { AsinCompareActions } from '../asin-compare/asin-compare.actions';
import { ProductManagerActions } from '../product-manager/product-manager.actions';
import { ToastService } from '@app/core/services/toast.service';
import { AppState } from '@app/shared/data/app-state.model';
import { I18n } from '@ngx-translate/i18n-polyfill';



@Injectable({
  providedIn: 'root'
})
export class R2AEpicsV2 {
  compareAsin: string;
  groupId: string;

  constructor(
    private r2aActionsV2: R2AActionsV2,
    private reverseAsinAPI: ReverseAsinAPI,
    private store: NgRedux<R2AV2InitialState>,
    private toastService: ToastService,
    private asinCompareActions: AsinCompareActions,
    private i18n: I18n,
  ) {
  }

  public createEpic() {
    return [
      this.socketEvent,
      this.loadHistory,
      this.requestKeywords,
      this.trackKeywords,
      this.trackKeywordHydrationFinished,
      this.requestKeywordsFailed,
    ];
  }

  requestKeywordsFailed = () => next => action => {
    if (action.type === R2AActionsV2.TYPES.REQUEST_KEYWORDS_ERROR) {
      switch (action.data.error_code) {
        case ErrorCodesEnum.ERROR_CODE_RESOLVE_ASINS_FAILED:
          action.data.message = this.i18n('Failed to resolve asins, due to the technical issue!');
          break;
        case ErrorCodesEnum.ERROR_CODE_INVALID_INPUT:
          action.data.validation_error = action.data.message;
          action.data.message = this.i18n('There is a problem with your input!');
          break;
        case ErrorCodesEnum.ERROR_CODE_TIMEOUT:
          action.data.message = this.i18n('R2A search timed out. Please try again later!');
          break;
        case ErrorCodesEnum.ERROR_CODE_FORBIDDEN:
          action.data.message = this.i18n('Access denied for the marketplace');
          break;
        case ErrorCodesEnum.ERROR_CODE_LIMITS_REACHED:
          action.data.message = this.i18n(
            'Maximum number of R2A searches reached! Limit is {{limit}} searches / billing period',
            {limit: action.data.details.limit}
          );
          break;
        default:
          action.data = {};
          action.data.message = this.i18n('Search failed due to application data processing error!');
          break;
      }
    }
    return next(action);
  }

  socketEvent = store => next => {
    return (action: StAction) => {
      const activeGroupId = store.getState().r2a_v2.activeSearch?.group_id;

      if (action.data?.group_id !== activeGroupId) {
        return next(action);
      }

      switch (action.type) {
        case R2AActionsV2._events.keywords_received:
          if (Boolean(action.data.error) && action.data.error.length > 0) {
            this.store.dispatch(this.r2aActionsV2.requestKeywordsError(action.data.error[0]));
            break;
          }

          this.store.dispatch(this.r2aActionsV2.requestKeywordsSuccess({
            keywords: action.data.keywords,
            stats: action.data.stats,
            info: action.data.info
          }));

          if (this.compareAsin) {
            this.store.dispatch(this.asinCompareActions.compare(this.compareAsin, this.groupId));
            this.compareAsin = null;
            this.groupId = null;
          }

          if ('info' in action.data) {
            this.store.dispatch(this.r2aActionsV2.setActiveProduct(action.data.info.map(p => {
              const { title, asin, img } = p;
              return {
                title,
                asin,
                image_url: img,
              };
            })));
          }

          break;

        case R2AActionsV2._events.progress:
          if (action.data.group_id === activeGroupId) {
            this.store.dispatch(this.r2aActionsV2.updateProgress({ progress: action.data.progress, step: action.data.step }));
          }
          break;
      }
      return next(action);
    };
  }

  loadHistory = () => next => {
    return (action) => {
      if (action.type === R2AActionsV2.TYPES.LOAD_HISTORY) {
        this.reverseAsinAPI.loadHistory()
          .subscribe(
            (response) => {
              this.store.dispatch(this.r2aActionsV2.loadHistorySuccess(response));
            },
            (response) => {
              this.store.dispatch(this.r2aActionsV2.loadHistoryError(response));
            },
          );
      }
      return next(action);
    };
  }

  requestKeywords = () => next => action => {
    if (action.type === R2AActionsV2.TYPES.REQUEST_KEYWORDS) {
      this.store.dispatch(this.r2aActionsV2.setActiveSearch(false));
      this.compareAsin = action.data.compare_asin;
      this.groupId = action.data.group_id;

      merge(
        timer(0, environment.R2A_REQUEST_INTERVAL),
        timer(environment.R2A_TIMEOUT).pipe( // Fail after specified timeout
          switchMapTo(throwError({error: {error: {error_code: ErrorCodesEnum.ERROR_CODE_TIMEOUT}}}))
        )
      ).pipe(
        takeUntil(
          this.store.select(['r2a_v2', 'searchDone'])
            .pipe(filter(Boolean))
        ),
        mergeMap(() => {
          return this.reverseAsinAPI.requestKeywords(
            action.data['asins'],
            action.data['compare_asin'],
            action.data['group_id'],
            action.data['marketplace'],
            action.data['depth'],
            action.data['resolveChildren'],
          );
        })
      ).subscribe((data: IRequestKeywordsResponse) => {
        if ('keywords' in data && 'stats' in data) {
          this.store.dispatch(this.r2aActionsV2.requestKeywordsSuccess({
            keywords: data.keywords,
            stats: data.stats,
            info: data.info,
          }));

          if (this.compareAsin) {
            this.store.dispatch(this.asinCompareActions.compare(this.compareAsin, this.groupId));
            this.compareAsin = null;
            this.groupId = null;
          }
        }

        if ('info' in data) {
          this.store.dispatch(this.r2aActionsV2.setActiveProduct(data.info.map(p => {
            const {title, asin, img} = p;
            return {
              title,
              asin,
              image_url: img,
            };
          })));
        }
      }, (error) => {
        this.store.dispatch(this.r2aActionsV2.requestKeywordsError(error.error.error));
      });
    }

    return next(action);
  }

  trackKeywords = () => next => {
    return (action) => {
      if (action.type === R2AActionsV2.TYPES.TRACK_KEYWORDS) {
        this.compareAsin = action.data.asin;
        this.groupId = action.data.groupId;

        this.reverseAsinAPI
          .trackKeywords(action.data.asin, action.data.keywords, action.data.priority)
          .subscribe(() => of());
      } else if (action.type === R2AActionsV2.TYPES.TRACK_KEYWORDS_DELETE) {
        this.reverseAsinAPI.removeKeywordsTracking(action.data.asin, action.data.keywords).subscribe(() => {
          this.store.dispatch(this.r2aActionsV2.removeKeywordMyAsinData(action.data.keywords));
          return of();
        });
      }
      return next(action);
    };
  }

  trackKeywordHydrationFinished = () => next => action => {
    if (action.type === ProductManagerActions._events.keyword_manager_priority_changes) {
      if (action.data?.asin === this.compareAsin) {
        const keywords: string[] = action.data?.changes?.map(kwChange => kwChange.value);
        this.store.dispatch(this.r2aActionsV2.trackKeywordsDone(keywords));
      }
    }

    return next(action);
  }
}
