export class StoreActions {
  static readonly TYPES = {
    ON_CONNECT   : '[SOCKET]ON_CONNECT',
    ON_DISCONNECT: '[SOCKET]ON_DISCONNECT',

    NOTIFICATION: '[SOCKET]NOTIFICATION',
  };

  static onConnect() {
    return {
      type: StoreActions.TYPES.ON_CONNECT,
      data: null,
    };
  }

  static onDisconnect() {
    return {
      type: StoreActions.TYPES.ON_DISCONNECT,
      data: null,
    };
  }
}
