import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { Collection } from "@app/shared/data/base.models";
import { LS } from "@app/shared/services/local-storage.service";
import { UserAdminActions } from "@app/shared/data/admin/user/user-admin.actions";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";

export type UserAdminFilters = {
  searchTerm: string,
  is_subuser: string,
  is_active: string,
  plan: string,
  has_canceled: string,
  has_credentials: string,
};

const INITIAL_STATE: AppState = {
  data             : new Collection<UserAdmin>([]),
  full_user        : new UserAdmin(),
  loading          : false,
  loading_full_user: false,
  adding_trial_days: false,
  impersonating    : false,
  error            : null,
  _filters         : loadFilters(),
  _be_pagination   : {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage('admin-user') || 50
  }
};

function loadFilters() {
  return LS.forActiveUser().getUserAdminFilters()
    || {
      searchTerm     : "",
      is_subuser     : '*',
      is_active      : '*',
      plan           : '*',
      has_canceled   : '*',
      has_credentials: '*',
    }
}

export function userAdminReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == UserAdminActions.TYPES.IMPERSONATE) {
    return {
      ...state,
      impersonating: true
    };
  }

  if (action.type == UserAdminActions.TYPES.IMPERSONATE_SUCCEEDED) {
    return {
      ...state,
      impersonating: false
    };
  }

  if (action.type == UserAdminActions.TYPES.IMPERSONATE_FAILED) {
    return {
      ...state,
      impersonating: false
    };
  }

  if (action.type == PaginationActions.getPagActionTypes(UserAdmin).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-user');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == UserAdminActions.TYPES.FILTER) {
    LS.forActiveUser().setUserAdminFilters(action.data);

    return {
      ...state,
      _filters: action.data,
      loading : true
    }
  }

  if (action.type == UserAdminActions.TYPES.SET_FULL_USER) {
    return {
      ...state,
      loading_full_user: true,
      error            : null,
    }
  }

  if (action.type == UserAdminActions.TYPES.SET_FULL_USER_SUCCEEDED) {
    return {
      ...state,
      full_user        : action.data.user,
      loading_full_user: false,
      error            : null,
    }
  }

  if (action.type == UserAdminActions.TYPES.SET_FULL_USER_FAILED) {
    console.log("Setting Full User failed with error: ", action.data);

    return {
      ...state,
      loading_full_user: false,
      error            : action.data,
    }
  }

  if (action.type == UserAdminActions.TYPES.ADD_TRIAL_DAYS) {
    return {
      ...state,
      adding_trial_days: true
    };
  }

  if (action.type == UserAdminActions.TYPES.ADD_TRIAL_DAYS_SUCCEEDED) {
    return {
      ...state,
      adding_trial_days: false
    };
  }

  if (action.type == UserAdminActions.TYPES.ADD_TRIAL_DAYS_FAILED) {
    console.log("Adding Trial Days failed with error: ", action.data);

    return {
      ...state,
      adding_trial_days: false
    };
  }

  if (action.type == UserAdminActions.TYPES.UPDATE_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].reduce((acc, u) => u.id == action.data.user.id ? [...acc, action.data.user] : [...acc, u], [])
    }
  }

  if (action.type == UserAdminActions.TYPES.ADD_ADDITIONAL_PLAN) {
    return {
      ...state,
      loading_full_user: true,
    }
  }

  if (action.type == UserAdminActions.TYPES.ADD_ADDITIONAL_PLAN_SUCCEEDED) {
    return {
      ...state,
      data             : state['data'].clone().map((user: UserAdmin) => {
        if (user.id == action.data.user.id)
          return user.clone(UserAdmin, { additionalPlans: action.data.plans });
        return user;
      }),
      full_user        : state['full_user'].clone(UserAdmin, { additionalPlans: action.data.plans }),
      loading_full_user: false,
    };
  }

  if (action.type == UserAdminActions.TYPES.ADD_ADDITIONAL_PLAN_FAILED) {
    return {
      ...state,
      loading_full_user: false,
    }
  }

  if (action.type == UserAdminActions.TYPES.REMOVE_ADDITIONAL_PLAN) {
    return {
      ...state,
      loading_full_user: true,
    }
  }

  if (action.type == UserAdminActions.TYPES.REMOVE_ADDITIONAL_PLAN_SUCCEEDED) {
    return {
      ...state,
      data             : state['data'].clone().map((user: UserAdmin) => {
        if (user.id == action.data.user.id)
          return user.clone(UserAdmin, { additionalPlans: action.data.plans });
        return user;
      }),
      full_user        : state['full_user'].clone(UserAdmin, { additionalPlans: action.data.plans }),
      loading_full_user: false,
    };
  }

  if (action.type == UserAdminActions.TYPES.REMOVE_ADDITIONAL_PLAN_FAILED) {
    return {
      ...state,
      loading_full_user: false,
    }
  }

  if (action.type == UserAdminActions.TYPES.UPDATE_PERMISSIONS) {
    return {
      ...state,
      loading_full_user: true,
    }
  }

  if (action.type == UserAdminActions.TYPES.UPDATE_PERMISSIONS_SUCCEEDED) {
    return {
      ...state,
      full_user        : action.data.user,
      loading_full_user: false,
    };
  }

  if (action.type == UserAdminActions.TYPES.UPDATE_PERMISSIONS_FAILED) {
    return {
      ...state,
      loading_full_user: false,
    }
  }

  if (action.type == UserAdminActions.TYPES.UPDATE_USER) {
    return {
      ...state,
      loading_full_user: true,
    }
  }

  if (action.type == UserAdminActions.TYPES.UPDATE_USER_SUCCEEDED) {
    return {
      ...state,
      full_user        : action.data.user,
      loading_full_user: false,
    };
  }

  if (action.type == UserAdminActions.TYPES.UPDATE_USER_FAILED) {
    return {
      ...state,
      loading_full_user: false,
    }
  }

  if (action.type === UserAdminActions.TYPES.TRANSFER_CREDENTIALS_SUCCEEDED) {
    return {
      ...state,
      loading_full_user: false,
    }
  }

  if (action.type === UserAdminActions.TYPES.TRANSFER_CREDENTIALS_FAILED) {
    return {
      ...state,
      loading_full_user: false
    }
  }

  return paginationReducer(UserAdmin)(state, action);
}
