<div class="product-search__table">
  <div class="product-search__table-header">
    <ng-container *ngIf="productSelectLimit">
      <div class="product-search__table-header-column">{{numberOfSelected}}/{{productSelectLimit}}</div>
    </ng-container>
    <ng-container *ngIf="!productSelectLimit">
      <div class="product-search__table-header-column center">
        <st-checkbox-v2 [checked]="areAllProductsSelected"
                        (changed)="selectAllBrowseCompetitorProducts($event)"
                        [disabled]="productSelectLimit !== null"
                        color="red"></st-checkbox-v2>
      </div>
    </ng-container>
    <div class="product-search__table-header-column" (click)="emitSortChange(sortByField.TITLE)" i18n>
      Product Name
      <div class="sort-icon"></div>
    </div>
    <div class="product-search__table-header-column" i18n>ASIN</div>
    <div class="product-search__table-header-column center" (click)="emitSortChange(sortByField.RANK)"
         i18n>BSR
      <div class="sort-icon"></div>
    </div>
    <div class="product-search__table-header-column center" (click)="emitSortChange(sortByField.PRICE)" i18n>
      Price
      <div class="sort-icon"></div>
    </div>
    <div class="product-search__table-header-column center" (click)="emitSortChange(sortByField.REVIEWS)"
         i18n>No. of Reviews
      <div class="sort-icon"></div>
    </div>
  </div>
  <div class="product-search__table-list" [style.maxHeight.px]="maxTableHeight">
    <div class="loader" *ngIf="isSearching; else table">
      <st-loader [lines]="5"></st-loader>
    </div>
    <ng-template #table>
      <ng-container *ngIf="products?.length === 0 && !error">
                      <span class="empty-search-results"
                            i18n>Enter keyword or search phrase to see the products</span>
      </ng-container>
      <ng-container *ngIf="products?.length === 0 && error">
        <span class="empty-search-results empty-search-results--error" i18n>{{error}}</span>
      </ng-container>
      <ng-container *ngIf="products?.length > 0 && !error">
        <div class="product-search__table-list-product"
             *ngFor="let product of products"
             [class.product-search__table-list--selected]="product.checked">
          <div class="product-search__table-list-product-column center">
            <st-checkbox-v2 [checked]="product.checked"
                            [disabled]="isDisabledToSelect(product.checked)"
                            (changed)="selectProduct($event, product)"
                            color="red"></st-checkbox-v2>
          </div>
          <div class="product-search__table-list-product-column product-search__table-list-product-column--ellipsis">
            <div class="product-search__table-list-product-image-wrapper">
              <img [src]="product.image_url" [alt]="product.title"
                   loading="lazy"
                   class="product-search__table-list-product-image"
                   onerror="this.src='/assets/img/placeholder.svg'">
            </div>
            <div class="product-search__table-list-product-column-title">{{product.title}}</div>
          </div>
          <div class="product-search__table-list-product-column">{{product.asin}}</div>
          <div class="product-search__table-list-product-column product-search__table-list-product-column--sales-rank center">
            {{product.sales_rank | number: '1.0-0' || '-'}}</div>
          <div class="product-search__table-list-product-column product-search__table-list-product-column--price center">
            {{product.price?.amount | currency: product.price?.currency_code}}</div>
          <div class="product-search__table-list-product-column center">{{(product.reviews_count | number) || '-'}}</div>
        </div>
      </ng-container>
    </ng-template>
  </div>
</div>
