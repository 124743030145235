
import {takeUntil} from 'rxjs/operators';
import {Injectable, OnDestroy} from '@angular/core';
import {I18n} from '@ngx-translate/i18n-polyfill';
import {Field, Operator, Option} from '@app/shared/components/filter-builder/filter/filter';
import {Subject} from "rxjs";

@Injectable()
export class FilterService implements OnDestroy {

  private onDestroy$ = new Subject();

  private static numericOperators(): Operator[] {
    return [
      {name: '=', value: '='},
      {name: '!=', value: '!='},
      {name: '>', value: '>'},
      {name: '>=', value: '>='},
      {name: '<', value: '<'},
      {name: '<=', value: '<='}
    ];
  }

  private static rangeOperators(): Operator[] {
    return [
      {name: 'between', value: 'between'},
      {name: 'not between', value: 'not between'}
    ];
  }

  public static isRangeOperator(operator: string): boolean {
    return this.rangeOperators().some(e => e.name === operator);
  }

  constructor(private i18n: I18n) {}

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  getOperatorTranslation(operator: string, operatorType?: string) {
    let operatorsFound: Operator[] = [];
    if (operatorType in this.getDefaultOperators()) { // If type is set
      operatorsFound = this.getDefaultOperators()[operatorType].filter(function(fieldOption: Option) {
        return fieldOption.value === operator;
      });
    } else { // If undefined or unknown type is set iteration is needed
      Object.values(this.getDefaultOperators()).forEach((operators: Operator[]) => {
        operatorsFound = operatorsFound.concat(operators.filter(function(filteredOperator: Option) {
          return filteredOperator.value === operator;
        }));
      });
    }

    if (operatorsFound.length > 0) {
      return operatorsFound[0].name;
    }
    return operator;
  }

  getDefaultOperators(): {[key: string]: Operator[]} {
    return {
      string: [
        {name: this.i18n('contains'), value: 'contains'},
        {name: this.i18n('excludes'), value: 'excludes'},
        {name: this.i18n('equal to'), value: 'equal to'},
        {name: this.i18n('not equal to'), value: 'not equal to'}
      ],
      number: FilterService.numericOperators(),
      numberSimple: [{name: '=', value: '='}, {name: '>', value: '>'}, {name: '<', value: '<'}],
      numberSimpleString: [
        {name: this.i18n('equal to'), value: 'equal to'},
        {name: this.i18n('greater than'), value: 'greater than'},
        {name: this.i18n('less than'), value: 'less than'}
      ],
      time: FilterService.numericOperators(),
      date: FilterService.numericOperators(),
      range: [{name: this.i18n('between'), value: 'between'}, {name: this.i18n('not between'), value: 'not between'}],
      rangeSplit: [{name: this.i18n('under'), value: 'under'}, {name: this.i18n('over'), value: 'over'}],
      boolean: [{name: '=', value: '='}],
      select: [{name: this.i18n('is'), value: 'is'}],
      selectProductsTag: [{name: this.i18n('include'), value: 'include'}, {name: this.i18n('exclude'), value: 'exclude'}],
      selectHas: [{name: this.i18n('has'), value: 'has'}],
      selectHave: [{name: this.i18n('have'), value: 'have'}],
      multiselect: [{name: this.i18n('has one of'), value: 'has one of'}, {name: this.i18n('has all'), value: 'has all'}],
      tagsmultiselect: [
        {name: this.i18n('has one of'), value: 'has one of'},
        {name: this.i18n('has all'), value: 'has all'},
        {name: this.i18n('excludes'), value: 'excludes'}
      ],
    };
  }

  getValueTranslation(availableFields: { [key: string]: Field }, field: string, value: string) {
    const fieldConfig = availableFields[field];
    let fieldFound: string = null;
    if ('options' in fieldConfig) {
      fieldConfig.options.pipe(
        takeUntil(this.onDestroy$))
        .subscribe((fieldOptions: Option[]) => {
          const fieldsFound: Option[] = fieldOptions.filter(function(fieldOption: Option) {
            return fieldOption.value === value;
          });
          if (fieldsFound.length > 0) {
            fieldFound = fieldsFound[0].name;
          }
        });
    } else {
      return value;
    }
    return fieldFound;
  }
}
