import { PipeTransform, Injectable, Pipe } from "@angular/core";

@Pipe({
    name: 'stTruncate'
})
@Injectable()
export class TruncatePipe implements PipeTransform {
    transform(value: string, slimit: string, trail : string = '...') : string {
        let limit = parseInt(slimit);

        return value.length > limit ? value.substring(0, limit) + trail : value;
    }
}
