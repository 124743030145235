
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";


@Injectable()
export class WalletAdminAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public resendSecurityCode(refID: string, email: string|null, params: {}) {
    params['ref_id'] = refID;
    if (email) {
      params['email'] = email;
    }
    return this.http.post(this.constructFullUrl('api/admin/wallet/resend-security-code'), params);
  }
}
