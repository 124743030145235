
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux, select } from '@angular-redux/store';
import {AppVersion} from '@app/shared/data/version/version.models';
import {UsersActions} from '@app/shared/data/user/user.actions';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector   : 'st-app-update',
  templateUrl: './app-update.component.html',
  styleUrls  : ['./app-update.component.scss']
})
export class AppUpdateComponent implements OnInit, OnDestroy {

  @select(['user', 'data', 'last_unread_app_update'])
  lastUnreadAppUpdate$: BehaviorSubject<any>;

  public versionIsPresent = false;
  public version: AppVersion;

  private onDestroy$ = new Subject();

  constructor(private ngRedux: NgRedux<AppState>,
              private usersActions: UsersActions,
              private i18n: I18n) { }

  ngOnInit(): void {
    this.lastUnreadAppUpdate$.pipe(takeUntil(this.onDestroy$)).subscribe((version: any) => {
      if (!version || typeof version === 'boolean') {
        this.versionIsPresent = false;
      } else {
        this.versionIsPresent = true;
        this.version = version;
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.ngRedux.dispatch(this.usersActions.updateProfile({
      last_version_checked: true,
      action: 'version_checked'
    }));
  }
}
