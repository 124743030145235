import { BaseModel, Collection } from '@app/shared/data/base.models';
import { UserAdmin } from '@app/shared/data/admin/user/user-admin.models';

class AdminSurvey extends BaseModel {
  static className = 'AdminSurvey';

  id: number;
  name: string;
  description: string;
  _questions: AdminSurveyQuestion[];
  _users: Collection<UserAdmin>;
  created_at: string;
  deleted_at: string;

  set questions(questions: {}[]) {
    this._questions = questions.map((question) => new AdminSurveyQuestion(question));
  }

  set users(users) {
    this._users = UserAdmin.collect(users);
  }

  get users() {
    return this._users;
  }

  get questions() {
    return this._questions;
  }
}

class AdminSurveyQuestion extends BaseModel {
  static className = 'AdminSurveyQuestion';

  id: number;
  value: string;
  required: boolean;
  created_at: string;
  deleted_at: string;
  _sub_question: AdminSurveyQuestion[];
  results?: any[];
  private _type: { value: string, icon: string, text: string, predefined: boolean };

  options: AdminSurveyQuestionOption[];

  set sub_question(questions: {}[]) {
    this._sub_question = questions.map((question) => new AdminSurveyQuestion(question));
  }

  get sub_question() {
    return this._sub_question;
  }

  getOptionById(id: number) {
    return this.options.find((option) => option.id == id);
  }

  set type(value: any) {
    if (typeof value === 'string') {
      this._type = questionTypes
        .filter(type => type.value == value)
        .pop();
    } else {
      this._type = value;
    }
  }

  get type(): any {
    return this._type;
  }
}

class AdminSurveyQuestionOption extends BaseModel {
  static className = 'AdminSurveyQuestion';

  id: number;
  value: string;
  description: string;
  created_at: string;
  deleted_at: string;
}

const questionTypes: { value: string, icon: string, text: string, predefined: boolean }[] = [
  {
    value: 'long_text', icon: 'text_fields', text: 'Long Text', predefined: false
  },
  {
    value: 'multi_select', icon: 'check', text: 'Multiple Choices', predefined: true
  },
  {
    value: 'select', icon: 'expand_more', text: 'Dropdown', predefined: true
  },
  {
    value: 'short_text', icon: 'short_text', text: 'Short Text', predefined: false
  },
];

export {
  AdminSurvey,
  AdminSurveyQuestion,
  questionTypes,
  AdminSurveyQuestionOption
};
