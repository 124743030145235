
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Field, FilterBuilderConfig } from '@app/shared/components/filter-builder/filter/filter';
import { capitalizeFirstLetter } from '@app/helpers';
import { FilterService } from '@app/shared/components/filter-builder/filter/filter.service';
import { BEAlert } from '@app/shared/data/alert/alert.models';
import { IProductManagerProduct } from '@app/shared/data/product-manager/product-manager.models';

@Injectable()
export class AlertsService {
  public static productSource = 'product';
  public static userSource = 'user';

  public static rankingType = 'ranking';
  public static indexType = 'index';
  public static apiPromoCodeCountType = 'api_promo_code_count';

  constructor(private i18n: I18n,
              private filterService: FilterService) {}

  static transformConditionValue(condition: string, conditionValue: any, toObject: boolean, separator: string = ',') {
    if (FilterService.isRangeOperator(condition)) {
      if (toObject) {
        // transforms 10,40 => {from: 10, to: 40}
        const conditions = conditionValue.split(separator);
        return {from: +conditions[0], to: +conditions[1]};
      } else if (conditionValue instanceof Object && 'from' in conditionValue && 'to' in conditionValue) {
        return  conditionValue.from + separator + conditionValue.to;
      } else {
        return conditionValue.replace(/,/g, separator);
      }
    }
    return conditionValue;
  }

  static capitalizeType(type: string): string {
    return capitalizeFirstLetter(type).replace(/_/g, ' ');
  }

  static getSourceURLName(source: string): string {
    return source === AlertsService.userSource ? 'account' : source;
  }

  getBEAlertTranslated(alert: BEAlert): string {
    return this.getFullNameTranslated(alert.type, alert.condition, alert.condition_val, alert.type_val);
  }

  getFullNameTranslated(type: string, condition: string, conditionValue: string, typeVal?: string): string {
    const field: Field = this.getFilterFields()[type];
    const typeTranslated: string = field.name;
    const typeValueTranslated: string = typeVal ? ' ' + this.i18n('for') + ' ' + typeVal : '';
    const conditionTranslated = this.filterService.getOperatorTranslation(condition);
    const valueTranslated = this.filterService.getValueTranslation(
      this.getFilterFields(),
      type,
      AlertsService.transformConditionValue(condition, conditionValue, false, ' ' + this.i18n('and') + ' ')
    );

    return capitalizeFirstLetter(typeTranslated).replace(/_/g, ' ')
      + typeValueTranslated + ' ' + conditionTranslated + ' ' + valueTranslated;
  }

  getFiltersConfig(source: string = AlertsService.productSource, product: IProductManagerProduct = null): FilterBuilderConfig {
    const foreignProduct = product !== null && product.is_foreign;
    const fields = this.getFilterFields();
    const filtered = Object.keys(fields)
                           .filter((key: string) => {
                             const field: Field = fields[key];
                             return field.source === source
                             && (field.onForeignProduct !== false ? true : !foreignProduct);
                           })
                           .reduce((obj, key: string) => {
                             obj[key] = fields[key];
                             return obj;
                           }, {});
    return {
      fields: {...filtered}
    };
  }

  getFilterFields(): { [key: string]: Field; } {
    return {
      [AlertsService.rankingType]: {
        name: this.i18n('Keyword Rank'),
        type: 'range',
        range: { min: observableOf(1), max: observableOf(300)},
        source: 'product'
      },
      [AlertsService.indexType]: {
        name: this.i18n('Keyword Indexation'),
        type: 'select',
        options: observableOf([{name: this.i18n('True'), value: 'True'}, {name: this.i18n('False'), value: 'False'}]),
        source: 'product'
      },
      bsr: {
        name: this.i18n('BSR for ASIN'),
        type: 'range',
        range: { min: observableOf(1), max: observableOf(10000)},
        source: 'product'
      },
      hijacker: {
        name: this.i18n('Hijacker'),
        type: 'select',
        options: observableOf([{name: this.i18n('Present'), value: 'Present'}, {name: this.i18n('Not Present'), value: 'Not Present'}]),
        source: 'product',
        onForeignProduct: false
      },
      buybox: {
        name: this.i18n('Buy Box'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product',
        onForeignProduct: false
      },
      price: {
        name: this.i18n('Price'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product',
        onForeignProduct: false
      },
      is_adult: {
        name: this.i18n('Is Adult product'),
        type: 'select',
        options: observableOf([{name: this.i18n('Set'), value: 'Set'}, {name: this.i18n('Not Set'), value: 'Not Set'}]),
        source: 'product'
      },
      weight: {
        name: this.i18n('Weight'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      dimensions: {
        name: this.i18n('Dimensions'),
        type: 'selectHave',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      color: {
        name: this.i18n('Color Variation'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      image: {
        name: this.i18n('Main image'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      title: {
        name: this.i18n('Title'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      category: {
        name: this.i18n('Category'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      brand: {
        name: this.i18n('Brand'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      manufacturer: {
        name: this.i18n('Manufacturer'),
        type: 'selectHas',
        options: observableOf([{name: this.i18n('Changed'), value: 'Changed'}]),
        source: 'product'
      },
      not_found: {
        name: this.i18n('ASIN Not Found'),
        type: 'select',
        options: observableOf([{name: this.i18n('True'), value: 'True'}]),
        source: 'product'
      },
      out_of_stock: {
        name: this.i18n('Out of stock'),
        type: 'select',
        options: observableOf([{name: this.i18n('True'), value: 'True'}, {name: this.i18n('False'), value: 'False'}]),
        source: 'product'
      },
      amazons_choice: {
        name: this.i18n('Amazon\'s Choice badge'),
        type: 'select',
        options: observableOf([{name: this.i18n('Present'), value: 'Present'}, {name: this.i18n('Not Present'), value: 'Not Present'}]),
        source: 'product'
      },
      best_seller: {
        name: this.i18n('Best Seller badge'),
        type: 'select',
        options: observableOf([{name: this.i18n('Present'), value: 'Present'}, {name: this.i18n('Not Present'), value: 'Not Present'}]),
        source: 'product'
      },
      [AlertsService.apiPromoCodeCountType]: {
        name: this.i18n('API Promotion Coupons'),
        type: 'numberSimpleString',
        source: 'product'
      },
      wallet_balance: {
        name: this.i18n('Wallet balance'),
        type: 'numberSimpleString',
        source: 'user'
      },
    };
  }
}
