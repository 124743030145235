import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { createSelector } from 'reselect';
import { PrizmActions, PrizmProductActions, PrizmKeywordActions } from '@app/shared/data/prizm/prizm.actions';
import { FePaginationCollection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { PrizmProduct, PrizmKeyword } from '@app/shared/data/prizm/prizm.models';

const INITIAL_STATE: AppState = {
  categories: [],
  error: {
    categories: null,
  },
};

const INITIAL_PROD_STATE: AppState = {
  data: [],
  loading: false,
  error: null,
  filters: {},
  pagination: {
    page: 1,
    limit: 30,
    sort: [],
  },
  graph: {
    loading: true,
    data: null
  },
  exampleLoaded: false,
  marketplaceId: 1,
};

const INITIAL_KEYW_STATE: AppState = {
  data: [],
  loading: false,
  error: null,
};

function prizmProductReducers(state: AppState = INITIAL_PROD_STATE, action: StAction): AppState {
  // When search for product started.
  if (action.type === PrizmProductActions.TYPES.GET_RESULTS) {
    // Reset results and set loading to true.
    return {
      ...state,
      data: [],
      loading: true,
      filters: action.data.filters,
      error: null,
      pagination: {
        ...state['pagination'],
        page: 1
      },
      graph: {
        loading: true,
        data: null
      },
      exampleLoaded: true,
    };
  }

  // If search fails reduce state with error message.
  if (action.type === PrizmProductActions.TYPES.GET_RESULTS_FAILED) {
    return {
      ...state,
      data: [],
      loading: false,
      error: 'error' in action.data.error ? action.data.error.error : action.data.error
    };
  }

  // If search succeeded then reduce state with results.
  if (action.type === PrizmProductActions.TYPES.GET_RESULTS_SUCCEEDED) {
    return {
      ...state,
      data: PrizmProduct.collect<PrizmProduct>(action.data.results, PrizmProduct.BACKEND_PAGINATION),
      loading: false
    };
  }

  // Reset results
  if (action.type === PrizmProductActions.TYPES.RESET_RESULTS) {
    return {
      ...state,
      error: null,
      filters: {},
      data: [],
      pagination: {
        ...state['pagination'],
        page: 1,
        sort: [],
      }
    };
  }


  // Set pagination data.
  if (action.type === PaginationActions.getPagActionTypes(PrizmProduct).LOAD_PAGE) {
    return {
      ...state,
      pagination: {
        page: action.data.pagination.page,
        sort: action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading: true,
    };
  }

  // Get history for prizm
  if (action.type === PrizmProductActions.TYPES.GET_HISTORY) {
    return {
      ...state,
      graph: {
        loading: true,
        data: null
      }
    };
  }

  if (action.type === PrizmProductActions.TYPES.GET_HISTORY_SUCCEEDED) {
    return {
      ...state,
      graph: {
        loading: false,
        data: action.data
      }
    };
  }

  if (action.type === PrizmProductActions.TYPES.GET_HISTORY_FAILED) {
    return {
      ...state,
      graph: {
        loading: false,
        data: null
      }
    };
  }

  return state;
}

function prizmKeywordReducers(state: AppState = INITIAL_KEYW_STATE, action: StAction): AppState {
  // Set loading for keywords
  if (action.type === PrizmKeywordActions.TYPES.GET_RESULTS) {
    return {
      ...state,
      loading: true,
      error: null,
      pagination: {
        ...state['pagination'],
        page: 1,
      }
    };
  }

  // Results for keywords
  if (action.type === PrizmKeywordActions.TYPES.GET_RESULTS_SUCCEEDED) {
    return {
      ...state,
      loading: false,
      data: action.data.results,
      error: null
    };
  }

  // Results for keywords
  if (action.type === PrizmKeywordActions.TYPES.GET_RESULTS_FAILED) {
    return {
      ...state,
      loading: false,
      keywords: [],
      error: action.data.error
    };
  }

  // Initialize pagination data.
  if (action.type === PaginationActions.getPagActionTypes(PrizmKeyword).INIT_DATA) {
    return {
      ...state,
      pagination: {}
    };
  }

  // Set pagination data.
  if (action.type === PaginationActions.getPagActionTypes(PrizmKeyword).LOAD_PAGE) {
    return {
      ...state,
      pagination: action.data.pagination
    };
  }

  return state;
}

/**
 * Define prizm reducers.
 *
 * @export
 * @param {AppState} [state=INITIAL_STATE]
 * @param {StAction} action
 * @returns {AppState}
 */
export function prizmReducers(state: AppState = INITIAL_STATE, action: StAction): AppState {

  // Set loading true for categories request.
  if (action.type === PrizmActions.TYPES.GET_CATEGORIES) {
    return {
      ...state,
      loading: true,
      categories: [],
      marketplaceId: action.data.marketplaceId,
      error: {
        ...state['error'],
        categories: null
      }
    };
  }

  // Set categories results when successfully finished.
  if (action.type === PrizmActions.TYPES.GET_CATEGORIES_SUCCEEDED) {
    return {
      ...state,
      categories: action.data.categories,
      loading: false,
    };
  }

  // Reset search and report error when get categories fails.
  if (action.type === PrizmActions.TYPES.GET_CATEGORIES_FAILED) {
    return {
      ...state,
      categories: [],
      loading: false,
      error: {
        ...state['error'],
        categories: action.data.error
      }
    };
  }

  return {
    ...state,
    'product': prizmProductReducers(state['product'], action),
    'keyword': prizmKeywordReducers(state['keyword'], action),
  };
}

/**
 * Selector for selecting prizm results.
 */
export const prizmProductResults = createSelector([
  state => state['prizm']['product']['data']
], (results) => {
  return results || [];
});

/**
 * Selector for selecting prizm paginated results.
 */
export const prizmProductResultsPage = createSelector([
  prizmProductResults
], (results) => {

  return results.map(product => {
    const max = Math.max(...product.sales_trend);
    return {
      ...product,
      norm_trend: !!product.sales_trend ? product.sales_trend.map((v: number) => Math.round(900 - v / max * 900)) : []
    };
  });
});

/**
 * Selector for categories.
 */
export const prizmCategories = createSelector([
  state => state['prizm']['categories']
], (categories) => {
  return categories;
});

/**
 * Selector for errors in prizm.
 */
export const prizmProductError = createSelector([
  state => state['prizm']['product']['error']
], (error) => {
  return error || INITIAL_PROD_STATE['error'];
});

/**
 * Selector for prizm keywords results.
 */
export const prizmKeywordResults = createSelector([
  state => state['prizm']['keyword']['data'],
], (results) => {
  return results || [];
});

/**
 * Selector for selecting prizm paginated keywords results.
 */
export const prizmKeywordResultsPage = createSelector([
  prizmKeywordResults,
  state => state['prizm']['keyword']['pagination']
], (results, pagination) => {
  return (new FePaginationCollection(results)).createPage(pagination);
});

/**
 * Selector for errors in keyword prizm.
 */
export const prizmKeywordError = createSelector([
  state => state['prizm']['keyword']['error']
], (error) => {
  return error || INITIAL_KEYW_STATE['error'];
});

/**
 * Selector for loading state.
 */
export const prizmLoading = createSelector([
  state => state['prizm']['keyword']['loading'],
  state => state['prizm']['product']['loading'],
], (keywordLoading, productLoading) => {
  return keywordLoading || productLoading;
});

/** Selector for prizm product graph loading */
export const prizmGraphLoading = createSelector([
  state => state['prizm']['product']['graph']['loading']
], (loading) => {
  return loading;
});


/**
 * Selector for prizm product graph loading
 * @param field Data field
 * @param holdLast Hold last data to the current date
 */
export const prizmGraphData = (field: string, holdLast = false) => createSelector([
  state => state['prizm']['product']['graph']['data']
], (data) => {
  if (data && field in data) {
    data = data[field];
    // Sort data
    data = data.sort((a: any[], b: any[]) => a[0] - b[0]);
    return holdLast && data.length > 0 ? // If hold last then repeat last value on now time
      [...data, [Date.now(), data[data.length - 1][1]]]
      : data; // If no hold last just return unchanged data
  }
  return [];
});
