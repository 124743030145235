import {
  AfterViewInit,
  Component, ContentChildren,
  Directive, OnInit, QueryList,
  TemplateRef
} from "@angular/core";

@Directive({
  selector: '[cl]',
})
export class CellDirective {
  constructor(public template:TemplateRef<any>) {};
}
