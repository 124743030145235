
import {filter, takeUntil, share} from 'rxjs/operators';
import {
  Component, Input, OnInit, OnChanges, SimpleChanges, ChangeDetectorRef, OnDestroy,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorService } from '@app/core/services/validator.service';
import { BehaviorSubject ,  Observable ,  Subject } from 'rxjs';

@Component({
  selector   : 'st-field-container',
  templateUrl: './field-container.component.html',
  styleUrls  : ['./field-container.component.scss'],
})
export class FieldContainerComponent implements OnInit, OnChanges, OnDestroy {
  @Input() control: FormControl;
  @Input() forceErrorsDisplay: boolean = false;
  @Input() forceErrorsDisplay$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input('errorMessage')
  forcedErrorMessage = null;

  @Input('className')
  className = '';

  errorMessage = '';

  validating = false;

  constructor(private validatorService: ValidatorService,
              private cd: ChangeDetectorRef) {}

  ngOnInit() {
    if(!this.control) return;
    this.control.statusChanges.pipe(share(),takeUntil(this.onDestroy$),).subscribe((status) => {
      this.errorMessage = this.checkError();
      if (status === 'PENDING') {
        this.validating = true;
      } else {
        this.validating = false;
      }
    });

    this.control.parent.statusChanges.pipe(share(),takeUntil(this.onDestroy$),).subscribe((x) => {
      if (this.control.parent['submitted']) {
        this.control.markAsDirty();
        this.errorMessage = this.checkError();
      }
    });

    this.forceErrorsDisplay$.pipe(
      filter(checkErrors => checkErrors),
      takeUntil(this.onDestroy$),)
      .subscribe((e) => {
        this.errorMessage = this.checkError();
      });

    this.errorMessage = this.checkError();
  }

  private onDestroy$ = new Subject();
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngOnChanges(changes: SimpleChanges) {
    if(this.forcedErrorMessage)
      this.errorMessage = this.forcedErrorMessage;
    else
      this.errorMessage = this.checkError();
  }

  private checkError() {
    if (this.control !== undefined) {
      for (let propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) && ((this.control.dirty) || this.forceErrorsDisplay || this.forceErrorsDisplay$.value)) {
          return this.validatorService.getErrorMessage(propertyName, this.control.errors[propertyName]);
        }
      }
    }

    return null;
  }
}
