import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { ToastService } from "@app/core/services/toast.service";
import { ActivatedRoute, Router } from "@angular/router";
import { SurveyActions } from "@app/shared/data/survey/survey.actions";
import { Survey } from "@app/shared/data/survey/survey.models";
import { SurveyAPI } from "@app/shared/data/survey/survey.api";
import { parseError } from "@app/helpers";
import {I18n} from '@ngx-translate/i18n-polyfill';
@Injectable()
export class SurveyEpics {
  constructor(private surveyApi: SurveyAPI,
              private toaster: ToastService,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              private ngRedux: NgRedux<AppState>,
              private i18n: I18n) {}

  public createEpic() {
    return [
      this.getSurvey,
      this.submitSurvey,
    ];
  }

  getSurvey = () => next => {
    return (action) => {
      if (action.type == SurveyActions.TYPES.GET) {
        this.surveyApi.get(action.data)
          .subscribe(
            (survey: Survey) => {
              this.ngRedux.dispatch(SurveyActions.getSurveySucceeded(survey));
            },
            (error) => {
              console.log("res", error);

              this.toaster.error(
                this.i18n('Error!'),
                this.i18n('Something went wrong while getting survey'));
              this.ngRedux.dispatch(SurveyActions.getSurveyFailed(error));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  submitSurvey = () => next => {
    return (action) => {
      if (action.type == SurveyActions.TYPES.SUBMIT) {
        this.surveyApi.submit(action.data)
          .subscribe(
            (survey: Survey) => {
              this.ngRedux.dispatch(SurveyActions.submitSucceeded(survey));
            },
            (error) => {
              console.log("res", error);
              if(error.error.error.code)
              this.toaster.error(
                this.i18n('Error!'),
                parseError(error));
              this.ngRedux.dispatch(SurveyActions.submitFailed(error));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

}
