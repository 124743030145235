import { Injectable } from '@angular/core';
import { IFilter, ISavedFilter } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { Action } from 'redux';
import { ReverseAsin, ReverseAsinStats } from '../reverse-asin/reverse-asin.models';
import { StAction } from '../st-action';
import { ErrorPayload, IR2AHistorySearchItem } from './reverse-asin.models';

@Injectable({
  providedIn: 'root'
})
export class R2AActionsV2 {
  static readonly _events = {
    keywords_received: 'reverse_asin_keywords_received',
    progress: 'reverse_asin_progress',
  };

  static readonly TYPES = {
    LOAD_HISTORY: '[R2A_V2]LOAD_HISTORY',
    LOAD_HISTORY_SUCCESS: '[R2A_V2]LOAD_HISTORY_SUCCESS',
    LOAD_HISTORY_ERROR: '[R2A_V2]LOAD_HISTORY_ERROR',

    REQUEST_KEYWORDS: '[R2A_V2]REQUEST_KEYWORDS',
    REQUEST_KEYWORDS_SUCCESS: '[R2A_V2]REQUEST_KEYWORDS_SUCCESS',
    REQUEST_KEYWORDS_ERROR: '[R2A_V2]REQUEST_KEYWORDS_ERROR',

    UPDATE_PROGRESS: '[R2A_V2]UPDATE_PROGRESS',
    SET_ACTIVE_ASIN: '[R2A_V2]SET_ACTIVE_ASIN',
    SET_ACTIVE_PRODUCT: '[R2A_V2]SET_ACTIVE_PRODUCT',
    SET_COMPARE_ASIN_PRODUCT: '[R2A_V2]SET_COMPARE_ASIN_PRODUCT',
    SET_SEARCH_DONE: '[R2A_V2]SET_SEARCH_DONE',

    APPLY_VIEW: '[R2A_V2]APPLY_VIEW',
    DELETE_VIEW: '[R2A_V2]DELETE_VIEW',
    SAVE_FILTERS: '[R2A_V2]SAVE_FILTERS',

    TRACK_KEYWORDS: '[R2A_V2]TRACK_KEYWORDS',
    TRACK_KEYWORDS_DONE: '[R2A_V2]TRACK_KEYWORDS_DONE',
    TRACK_KEYWORDS_DELETE: '[R2A_V2]TRACK_KEYWORDS_DELETE',

    REMOVE_KEYWORD_MY_ASIN_DATA: '[R2A_V2]REMOVE_KEYWORD_MY_ASIN_DATA',

    CLEAR_ALL: '[R2A_V2]CLEAR_ALL',
    APPLY_FILTERS: '[R2A_V2]APPLY_FILTERS'
  };

  applyView(data: null|ISavedFilter): StAction {
    return {
      type: R2AActionsV2.TYPES.APPLY_VIEW,
      data
    };
  }

  deleteView(data: ISavedFilter): StAction {
    return {
      type: R2AActionsV2.TYPES.DELETE_VIEW,
      data
    };
  }

  saveFilters(data: ISavedFilter): StAction {
    return {
      type: R2AActionsV2.TYPES.SAVE_FILTERS,
      data,
    };
  }

  removeKeywordMyAsinData(keyword: string[]): StAction {
    return {
      type: R2AActionsV2.TYPES.REMOVE_KEYWORD_MY_ASIN_DATA,
      data: keyword,
    };
  }

  trackKeywords(asin: string, keywords: string[], groupId: string, priority?: string): StAction {
    return {
      type: R2AActionsV2.TYPES.TRACK_KEYWORDS,
      data: {
        asin,
        keywords,
        groupId,
        priority
      },
    };
  }

  trackKeywordsDone(keywords: string[]): StAction {
    return {
      type: R2AActionsV2.TYPES.TRACK_KEYWORDS_DONE,
      data: {
        keywords,
      },
    };
  }

  trackKeywordsDelete(asin: string, keywords: string[], groupId: string): StAction {
    return {
      type: R2AActionsV2.TYPES.TRACK_KEYWORDS_DELETE,
      data: {
        asin,
        keywords,
        groupId
      },
    };
  }

  clearAll(): Action {
    return {
      type: R2AActionsV2.TYPES.CLEAR_ALL,
    };
  }

  setActiveSearch(value: boolean): StAction {
    return {
      type: R2AActionsV2.TYPES.SET_SEARCH_DONE,
      data: value,
    };
  }

  setActiveAsin(asins: string[]): StAction {
    return {
      type: R2AActionsV2.TYPES.SET_ACTIVE_ASIN,
      data: {
        asins,
      },
    };
  }

  setActiveProduct(products: any[]): StAction {
    return {
      type: R2AActionsV2.TYPES.SET_ACTIVE_PRODUCT,
      data: {
        products,
      },
    };
  }

  setCompareAsinProduct(product: any): StAction {
    return {
      type: R2AActionsV2.TYPES.SET_COMPARE_ASIN_PRODUCT,
      data: product
    };
  }

  loadHistory(): Action {
    return {
      type: R2AActionsV2.TYPES.LOAD_HISTORY,
    };
  }

  loadHistorySuccess(history: IR2AHistorySearchItem[]): StAction {
    return {
      type: R2AActionsV2.TYPES.LOAD_HISTORY_SUCCESS,
      data: history,
    };
  }

  loadHistoryError(error: any): StAction {
    return {
      type: R2AActionsV2.TYPES.LOAD_HISTORY_SUCCESS,
      data: error,
    };
  }

  updateProgress(action: {
    progress: number,
    step: number
  }): StAction {
    const {
      progress,
      step
    } = action;

    return {
      type: R2AActionsV2.TYPES.UPDATE_PROGRESS,
      data: {
        progress,
        step
      },
    };
  }

  requestKeywords(
    asins: string[],
    compare_asin: string,
    group_id: string,
    marketplace: { id: number },
    depth: number,
    resolveChildren: boolean = false
  ): StAction {
    return {
      type: R2AActionsV2.TYPES.REQUEST_KEYWORDS,
      data: {
        asins,
        compare_asin,
        group_id,
        marketplace,
        depth,
        resolveChildren
      }
    };
  }

  requestKeywordsSuccess(response: {
    keywords: ReverseAsin[],
    stats: ReverseAsinStats,
    info: any
  }): StAction {
    return {
      type: R2AActionsV2.TYPES.REQUEST_KEYWORDS_SUCCESS,
      data: response,
    };
  }

  requestKeywordsError(error: ErrorPayload): StAction {
    return {
      type: R2AActionsV2.TYPES.REQUEST_KEYWORDS_ERROR,
      data: error,
    };
  }

  applyFilters(filters: IFilter[]): StAction {
    return {
      type: R2AActionsV2.TYPES.APPLY_FILTERS,
      data: {
        filters,
      },
    };
  }
}
