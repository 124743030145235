<div class="pagination">
  <div class="pagination-item pagination-item--text" [class.pagination-item--disabled]="isFirstDisabled" (click)="firstPage()" i18n>FIRST</div>
  <div class="pagination-item pagination-item--circle" [class.pagination-item--disabled]="isFirstDisabled" (click)="previousPage()">
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.19022 9.9212L5.34872 9.76271C5.45377 9.65767 5.45377 9.48735 5.34872 9.38228L0.966454 4.99999L5.34872 0.617698C5.45377 0.512654 5.45377 0.342334 5.34872 0.237267L5.19022 0.0787828C5.08517 -0.0262609 4.91485 -0.0262609 4.80981 0.0787828L0.0787828 4.80979C-0.0262609 4.91483 -0.0262609 5.08515 0.0787828 5.19022L4.80981 9.92122C4.91485 10.0263 5.08517 10.0263 5.19022 9.9212Z" fill="#173042"/>
    </svg>
  </div>
  <div class="pagination-item pagination-item--disabled" *ngIf="showBeforeDots">...</div>
  <div class="pagination-item pagination-item--circle"
    [class.pagination-item--current]="currentPage === page"
    [class.pagination-item--disabled]="isPageNumber(page)"
    (click)="changeThePage(page)"
    *ngFor="let page of pagesArray">{{page}}</div>
  <div class="pagination-item pagination-item--disabled" *ngIf="showAfterDots">...</div>
  <div class="pagination-item pagination-item--circle pagination-item--rotate" [class.pagination-item--disabled]="isLastDisabled" (click)="nextPage()">
    <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.19022 9.9212L5.34872 9.76271C5.45377 9.65767 5.45377 9.48735 5.34872 9.38228L0.966454 4.99999L5.34872 0.617698C5.45377 0.512654 5.45377 0.342334 5.34872 0.237267L5.19022 0.0787828C5.08517 -0.0262609 4.91485 -0.0262609 4.80981 0.0787828L0.0787828 4.80979C-0.0262609 4.91483 -0.0262609 5.08515 0.0787828 5.19022L4.80981 9.92122C4.91485 10.0263 5.08517 10.0263 5.19022 9.9212Z" fill="#173042" />
    </svg>
  </div>
  <div class="pagination-item pagination-item--text" [class.pagination-item--disabled]="isLastDisabled" (click)="lastPage()" i18n>LAST</div>
</div>
