import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { SessionLog } from "@app/shared/data/session-logs/session-logs.models";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { SessionLogsActions } from "@app/shared/data/session-logs/session-logs.actions"

interface SessionLogsState extends AppState {
  data: Collection<SessionLog>;
  loading: boolean;
  _filters      : {};
  pagination: {
    current_page: number;
    per_page: number;
    from: number;
    to: number;
    total: number;
    last_page: number;
  };
}

const INITIAL_STATE: SessionLogsState = {
  data   : new Collection(),
  loading: false,

  _filters      : {},
  pagination: {
    current_page : 1,
    per_page: 20,
    from: 0,
    to: 20,
    total: 20,
    last_page: 1,
  }
};

export function sessionLogsReducer(state: SessionLogsState = INITIAL_STATE, action: StAction): AppState {
  switch(action.type) {
    case SessionLogsActions.TYPES.SESSION_LOGS_CHANGE_CURRENT_PAGE:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          current_page: action.data.page,
        }
      }

    case SessionLogsActions.TYPES.SESSION_LOGS_CHANGE_PER_PAGE:
      return {
        ...state,
        loading: true,
        pagination: {
          ...state.pagination,
          per_page: action.data.perPage,
        }
      }

    case SessionLogsActions.TYPES.LOAD_SESSION_LOGS: {
      return {
        ...state,
        loading: true,
      };
    }

    case SessionLogsActions.TYPES.LOAD_SESSION_LOGS_SUCCESS: {
      return {
        ...state,
        data: action.data.data.map((log) => new SessionLog(log)),
        pagination: {
          current_page: action.data.current_page,
          per_page: action.data.per_page,
          from: action.data.from,
          to: action.data.to,
          total: action.data.total,
          last_page: action.data.last_page,
        },
        loading: false,
      };
    }

    case SessionLogsActions.TYPES.LOAD_SESSION_LOGS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    default:
      return state;
  }
}
