<st-dialog #dialog="dialog">
  <div dialog-header class="header">
    <div class="question">{{ title }}</div>
  </div>
  <div dialog-content>
    <p *ngIf="message" [innerHtml]="message"></p>
    <input type="text" class="interface-component field" [(ngModel)]="value" (ngModelChange)="validate()" name="value"
      required #input />
  </div>
  <div dialog-footer class="footer">
    <st-button-v2 (clicked)="cancel()" color="empty" [label]="noButtonText"></st-button-v2>
    <st-button-v2 (clicked)="confirm()" color="primary" [disabled]="!isValid" [label]="yesButtonText">
    </st-button-v2>
  </div>
</st-dialog>
