import { BaseModel } from '@app/shared/data/base.models';
import { User } from '@app/shared/data/user/user.models';
import { marketplaces } from '@app/shared/data/marketplaces';

export class Credential extends BaseModel {
  id: number;
  name: string;
  timezone: string;
  marketplace: string;
  last_mws_sync_at?: Date;
  current_mws_sync_start_at?: Date;
  owner: User;
  valid: boolean;
  amazonMarketplace: { domain: string, currency: string, id?: number };
  is_spa_connected: boolean;
  merchant_id: string;
  limits: any[];

  tools_availability: any;

  // todo(z): remove
  public toolAvailable(tool: string): boolean {
    return this.tools_availability[tool];
  }

  get amazon_domain() {
    if (!this.amazonMarketplace) {
      return '';
    }

    return this.amazonMarketplace.domain;
  }

  get currency() {
    const marketplace = marketplaces.filter(m => m.id === this.amazonMarketplace.id)[0];

    return marketplace.currency;
  }
}
