import { BaseModel } from '@app/shared/data/base.models';

export class LastLaunchPromotion extends BaseModel {
  static className = 'LastLaunchPromotion';

  id: number;
  type: string;
  name: string;
  starts_at: string;
  ends_at: string;
  created_at: string;
  duration?: number;
  cancelled_at?: string;
  no_of_keywords: number;
  coupons_redeem: number;
  coupons_total: number;
  rebate_units_redeem: number;
  units_total: number;
  is_paid?: boolean;
  is_active: boolean;
  report_available?: boolean;

  get status() {
    if (this.cancelled_at) {
      return 'Cancelled';
    }

    if (!this.is_paid) {
      return 'Draft';
    }

    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);

    const tomorrow = new Date(today.getTime());
    tomorrow.setDate(tomorrow.getDate() + 1);


    const sa = this.startsAtAsDate().getTime();
    const ea = this.endsAtAsDate().getTime();
    if (ea < today.getTime()) {
      return 'Past';
    }

    if (sa >= tomorrow.getTime()) {
      return 'Upcoming';
    }

    return 'Active';
  }

  isPast() {
    return this.status === 'Past';
  }

  startsAtAsDate() {
    const dateParts = this.starts_at.split('-');
    return new Date(
      Number.parseInt(dateParts[0]),
      Number.parseInt(dateParts[1]) - 1,
      Number.parseInt(dateParts[2].substr(0, 2)),
      0,
      0,
      0,
      0
    );
  }

  endsAtAsDate() {
    const dateParts = this.ends_at.split('-');
    return new Date(
      Number.parseInt(dateParts[0]),
      Number.parseInt(dateParts[1]) - 1,
      Number.parseInt(dateParts[2].substr(0, 2)),
      0,
      0,
      0,
      0
    );
  }
}
