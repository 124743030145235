<div 
  stTooltip="Select some keywords to see bulk actions"
  [disableTooltip]="selectedKeywords?.length > 0"
  class="dropdown-wrapper"
  [class.dropdown-wrapper--opened]="opened"
  [class.dropdown-wrapper--disabled]="selectedKeywords?.length === 0 || isDisabled"
  stOffClick
  (clickOutside)="opened = false">
  <div class="dropdown__title" (click)="toggleDropdown()">
    <div class="dropdown__title-text" *ngIf="selectedOption">{{selectedOption.label}}</div>
    <div class="dropdown__title-icon"></div>
  </div>
  <div class="dropdown">
    <div class="dropdown__options">
      <ng-container *ngFor="let option of options">
        <div *ngIf="!dontDisplayInDropdown" class="dropdown__option" (click)="optionSelected(option)">
          {{option.label}}
          <div *ngIf="option.canOpen" class="dropdown-icon" [class.opened]="priorityOptionsOpened"></div>
        </div>
      </ng-container>
      <div *ngIf="priorityOptionsOpened" class="priority-container">
          <st-radio-button-v2 *ngFor="let option of priorityOptions" 
                            (click)="changePriority(option.id)" 
                            class="dropdown__option" 
                            [checked]="option === selectedPriorityOption" 
                            [label]="option.label"></st-radio-button-v2>
      </div>
    </div>
  </div>
</div>
