import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { BaseAPI } from "@app/shared/data/base.api";

@Injectable()
export class DecisionAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(asin, params = {}): Observable<{relevant:string[], irrelevant:string[]}> {
    const url = this.constructFullUrl('api/products/' + asin + '/keyword-decisions');
    return this.http.get(url, { params: params }) as Observable<{relevant:string[], irrelevant:string[]}>;
  }

  public store(asin, data): Observable<any> {
    const url = this.constructFullUrl('api/products/' + asin + '/keyword-decisions');
    return this.http.post(url, data);
  }
}
