
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AuthService } from "@app/core/services/auth.service";
import { BaseAPI } from "@app/shared/data/base.api";
import { Observable } from "rxjs";
import { Usage } from "@app/shared/data/usage/usage.models";

@Injectable()
export class UsageApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public get(): Observable<any> {
    return this.http.get(this.constructFullUrl(`api/users/usages`)).pipe(
      map((data: any) => Usage.collect(<Usage>(data.usages))));
  }

}

