import {
  CreditsOptions, LegendOptions, Options, PlotOptions, SeriesOptionsType, TitleOptions,
  TooltipOptions,
  XAxisOptions,
  YAxisOptions
} from "highcharts";
import { mergeDeep } from "@app/shared/components/charts/chart.functions";
import { Chart } from "@app/shared/components/charts/models/chart.model";
import { EventEmitter } from "@angular/core";
import { ChartEvent } from "@app/shared/components/charts/models/chart.events";
import { NavigatorOptions, RangeSelectorOptions } from "highcharts/highstock";

export interface ChartComponentInterface {
  addSeries: EventEmitter<ChartEvent>;
  afterPrint: EventEmitter<ChartEvent>;
  beforePrint: EventEmitter<ChartEvent>;
  drilldown: EventEmitter<ChartEvent>;
  drillup: EventEmitter<ChartEvent>;
  load: EventEmitter<ChartEvent>;
  redraw: EventEmitter<ChartEvent>;
  selection: EventEmitter<ChartEvent>;
}

export class HighchartOptions implements Options {
  credits: CreditsOptions = { enabled: false }; // hide credits by default
  series: SeriesOptionsType[] = [];
  xAxis: XAxisOptions[];
  yAxis: YAxisOptions[];
  title: TitleOptions;
  tooltip: TooltipOptions;
  chart: Chart;
  legend: LegendOptions;
  plotOptions: PlotOptions = {};
  rangeSelector: RangeSelectorOptions;
  navigator: NavigatorOptions;

  constructor(element: HTMLElement) {
    this.chart = new Chart(element);
  }

  public assign(options: any): Options {
    mergeDeep(this, options);
    return this;
  }

  public setCredits(credits: CreditsOptions) {
    this.credits = credits;
  }

  public setTitleText(title: string) {
    this.title = { text: title };
  }

  public setTitle(title: TitleOptions) {
    this.title = title;
  }

  public pushAxis(axis): number {
    if (!this.yAxis) {
      this.yAxis = [];
    }
    this.yAxis.push(axis);

    return this.yAxis.length-1;
  }

  public setRangeSelectorOptions(opts: {}) {
    this.rangeSelector = {
      ...this.rangeSelector,
      ...opts,
    };
  }

  public setNavigatorOptions(opts: {}) {
    this.navigator = {
      ... this.navigator,
      ... opts,
    }
  }

  public setTurboThreshold(value) {
    this.setPlotOptions({
      series: {
        turboThreshold: value,
      },
    });
  }

  setPlotOptions(plotOptions: PlotOptions) {
    mergeDeep(this.plotOptions, plotOptions);
  }
}
