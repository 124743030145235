import {
  Component, ContentChild, DoCheck, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges,
  TemplateRef, ViewChild
} from "@angular/core";
import { HeadDirective } from "app/shared/components/datatable/directives/head.directive";
import { CellDirective } from "app/shared/components/datatable/directives/cell.directive";
import { Subject } from "rxjs";

@Component({
  selector: 'colm',
  template: `
    <ng-template #defHead>
      <th [stTooltip]="headerTooltip" position="top" style="height:100%">
        {{ title }}
      </th>
    </ng-template>
    <ng-template #defSortHead>
      <th class="sortable" [class.sorted-down]="sortDir < 0" [class.sorted-up]="sortDir > 0" [stTooltip]="headerTooltip" position="top">
        <button (click)="changeSort()">
          <span class="sortable-label">{{ title }}</span>
          <span class="sortable-arrows"></span>
        </button>
      </th>
    </ng-template>`
})
export class ColmComponent implements DoCheck, OnChanges{

  @Input()
  public headerTooltip : any = null;

  @Output()
  public titleChange = new EventEmitter<string>();

  @Output()
  public classListChange = new EventEmitter<any[]>();

  @Output()
  public headTemplateChange = new EventEmitter<TemplateRef<any>>();

  ngOnChanges(change:SimpleChanges) {
    if(change.title) {
      this.titleChange.emit(change.title.currentValue);
    }
    if(change.sortField) {
      if(this.title && !(change.sortField.previousValue != null && change.sortField.currentValue != null)){
        this.headTemplateChange.emit(this.getHeadTemplate());
      }
    }
  }

  constructor(private elRef:ElementRef) {}
  @ContentChild(HeadDirective, { static: true })
  public headTemplate: HeadDirective;

  @ContentChild(CellDirective, { static: true })
  public cellTemplate: CellDirective;


  private static compareArray(a1:any[], a2:DOMTokenList) {
    if (a1.length != a2.length) return false;
    for (let a of a1)
      if (!a2.contains(a)) return false;
    return true;
  }

  ngDoCheck() {
    if(!ColmComponent.compareArray(this.classList, this.elRef.nativeElement.classList)) {
      this.classList = Array.from(this.elRef.nativeElement.classList);
      this.classListChange.emit(this.classList);
    }
  }

  @ViewChild('defHead', { static: true })
  private defHeadTemplate: TemplateRef<any>;

  @ViewChild('defSortHead', { static: true })
  private defSortHeadTemplate: TemplateRef<any>;

  public getHeadTemplate(): TemplateRef<any> {
    if(this.headTemplate)
      return this.headTemplate.template;
    if(this.title) {
      if(this.sortField)
        return this.defSortHeadTemplate;
      return this.defHeadTemplate;
    }

    return null;
  }

  public getCellTemplate(): TemplateRef<any> {
    return this.cellTemplate.template;
  }

  protected changeSort() {
    if(this.forceSort)
      this.sortDir = this.sortDir < 0 ? 1 : -1;
    else this.sortDir = ((this.sortDir + 2) % 3) - 1;
    this.sortChange.next(this.sortField);
  }

  @Input('title')
  public title:string = null;

  @Input()
  public sortField: string = null;

  @Input()
  public sortDir: number = 0;

  @Input()
  public forceSort: boolean = false;

  @Input()
  public sortFieldOrder: number = null;

  @Output()
  public sortChange: Subject<string> = new Subject();

  public classList:string[] = [];
}
