import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-priority-item',
  templateUrl: './priority.item.component.html',
  styleUrls: ['./priority.item.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PriorityItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() onPriorityChange: EventEmitter<any> = new EventEmitter();

  priorityChange(priority: string) {
    this.onPriorityChange.emit(priority);
  }
}
