import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'st-header-v2',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() showLogin: boolean = false;

  constructor(private router: Router) { }

  goToLogin() {
    this.router.navigate(['/login']);
  }

}
