import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { AppState } from '../app-state.model';
import { RequestReviewActions } from './review-request.actions';
import { ReviewRequestAPI } from './review-request.api';

@Injectable()
export class ReviewRequestEpics {
  constructor(
    private requestReviewActions: RequestReviewActions,
    private reviewRequestApi: ReviewRequestAPI,
    private ngRedux: NgRedux<AppState>,
  ) { }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.getSettings,
      this.updateSettings,
      this.getProducts,
      this.getSentRequestGraph,
      this.getReviewGrowthGraph,
    ];
  }

  getSettings = () => next => {
    return (action) => {
      if (action.type === RequestReviewActions.TYPES.LOAD_SETTINGS) {
        this.reviewRequestApi.getSettings()
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.requestReviewActions.settingsLoaded(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.requestReviewActions.settingsLoadError(error));
            },
          );
      }
      return next(action);
    };
  }

  updateSettings = () => next => {
    return (action) => {
      if (action.type === RequestReviewActions.TYPES.UPDATE_SETTINGS) {
        this.reviewRequestApi.updateSettings(action.data.settings, action.data.overrides)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.requestReviewActions.settingsUpdated());
              this.ngRedux.dispatch(this.requestReviewActions.updateSettingsSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.requestReviewActions.updateSettingsError(error));
            },
          );
      }
      return next(action);
    };
  }

  getProducts = () => next => {
    return (action) => {
      if (action.type === RequestReviewActions.TYPES.GET_PRODUCTS) {
        this.reviewRequestApi.getProducts(action.data.page, action.data.perPage, action.data.sort, action.data.term)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.requestReviewActions.reviewRequestProductsLoaded(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.requestReviewActions.reviewRequestProductsError(error));
            },
          );
      }
      return next(action);
    };
  }

  getSentRequestGraph = () => next => {
    return (action) => {
      if (action.type === RequestReviewActions.TYPES.GET_SENT_REQUESTS_GRAPH) {
        this.reviewRequestApi.getSentRequestsGraph(action.data.time, action.data.granularity)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.requestReviewActions.getSentRequestGraphSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.requestReviewActions.getSentRequestGraphError(error));
            },
          );
      }
      return next(action);
    };
  }

  getReviewGrowthGraph = () => next => {
    return (action) => {
      if (action.type === RequestReviewActions.TYPES.GET_REVIEW_GROWTH_GRAPH) {
        this.reviewRequestApi.getReviewGrowthGraph(action.data.time)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.requestReviewActions.getReviewGrowthGraphSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.requestReviewActions.getReviewGrowthGraphError(error));
            },
          );
      }
      return next(action);
    };
  }
}
