
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/shared/data/base.api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class AmazonSnapshotApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public show(marketplaceId: number, params = {}): Observable<any> {
    return this.http.get(this.constructFullUrl('api/admin/tools/amazon-snapshot/' + marketplaceId), {params: params}).pipe(
               map((data: any) => {
                 return data;
               }));
  }

}
