import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { ReverseAsinActions } from "@app/shared/data/reverse-asin/reverse-asin.actions";
import { R2aTaskActions } from "@app/shared/data/tasks/r2a-task/r2a-task.actions";

const INITIAL_STATE: AppState = {
  data: []
};

export function r2aTaskReducers(state: AppState = INITIAL_STATE, action: StAction) {
  if(action.type == R2aTaskActions.TYPES.LIST_LOADED) {
    return {
      ...state,
      data: action.data.list.reduce((acc, task) => {
        return {
          ...acc,
          [task.id]: task
        }
      }, state['data'])
    }
  }

  if(action.type == R2aTaskActions.TYPES.REMOVE) {
    let data = {...state['data']};
    delete data[action.data.id];

    return{
      ...state,
      data: data
    }
  }

  if(action.type == ReverseAsinActions._events.keywords_received) {
    const group_id = action.data.group_id;

    return {
        ...state,
        data: {
            ...state['data'],
            [group_id]: {...state['data'][group_id], finished_at: action.data.finished_at}
        }
    }
  }


  return state;
}
