
import {map} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Collection } from '@app/shared/data/base.models';
import { BaseAPI } from '@app/shared/data/base.api';
import { Notification } from '@app/shared/data/notification/notification.models';
import { SocketService } from '@app/core/socket/socket.service';

@Injectable()
export class NotificationAPI extends BaseAPI {
  constructor(protected http: HttpClient,) {
    super();
  }

  public all(params = {}): Observable<Collection<Notification>> {
    return this.http.get(this.constructFullUrl('api/notifications'), { params: params }).pipe(
               map(data => Notification.collect<Notification>(data, Notification.BACKEND_PAGINATION)));
  }

  public delete(notification: Notification): Observable<boolean> {
    return this.http.post(this.constructFullUrl(`api/notifications/${notification.id}/read`), null).pipe(
               map(response => !!response));
  }

  public deleteAll(): Observable<boolean> {
    return this.http.post(this.constructFullUrl(`api/notifications/mark-all-read`), null).pipe(
               map(response => !!response));
  }
}
