import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { AppState } from '../app-state.model';
import { BrowseProductsApi } from '@app/shared/data/browse-products/browse-products.api';
import { BrowseProductsActions } from '@app/shared/data/browse-products/browse-products.actions';

@Injectable()
export class BrowseProductsEpics {
  constructor(
    private browseProductsActions: BrowseProductsActions,
    private browseProductsApi: BrowseProductsApi,
    private ngRedux: NgRedux<AppState>,
  ) {
  }

  public createEpic() {
    return [
      this.browseByAsins,
      this.browseByKeyword,
    ];
  }

  browseByAsins = () => next => {
    return (action) => {
      if (action.type === BrowseProductsActions.TYPES.BROWSE_BY_ASINS) {
        this.browseProductsApi.browseByAsins(action.data.asins)
            .subscribe(() => {
            }, (error) => {
              this.ngRedux.dispatch(this.browseProductsActions.searchCompetitorsProductsError(error));
            });
      }
      return next(action);
    };
  };

  browseByKeyword = () => next => {
    return (action) => {
      if (action.type === BrowseProductsActions.TYPES.BROWSE_BY_KEYWORD) {
        this.browseProductsApi.browseByKeyword(action.data.keyword)
            .subscribe(() => {
            }, (error) => {
              this.ngRedux.dispatch(this.browseProductsActions.searchCompetitorsProductsError(error));
            });
      }
      return next(action);
    };
  };
}
