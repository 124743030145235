
import {takeUntil} from 'rxjs/operators';
import {
  Directive,
  EventEmitter,
  Host,
  HostListener,
  Input,
  Output,
  TemplateRef,
  HostBinding,
  OnInit, OnChanges, SimpleChanges, OnDestroy,
} from '@angular/core';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { BaseModel, Collection } from '@app/shared/data/base.models';

@Directive({
  selector: '[stAccordionTemplate]',
})
export class AccordionTemplateDirective {
  constructor(public template: TemplateRef<any>) {
  }
}

@Directive({
  selector: '[stAccordion]',
})
export class AccordionDirective implements OnInit, OnDestroy {

  private onDestroy$ = new Subject();
  public data: AccordionObject[] = [];

  @Input()
  toggleMultiple = false;

  @Input()
  comparator: string = null;

  @Input()
  toggleObjects$: BehaviorSubject<Collection<any>> = new BehaviorSubject<Collection<any>>(new Collection());

  @Output()
  stOnAccordionOpen = new EventEmitter<AccordionChange>();

  ngOnInit() {
    this.toggleObjects$.pipe(takeUntil(this.onDestroy$)).subscribe((collection: Collection<any>) => {
      if (collection.isNotEmpty()) {
        // Add all received objects as open
        collection.each(object => {
          this.setOpenedItem(new AccordionObject(object, new BehaviorSubject(null)));
        });
      } else {
        // Close all opened
        // .slice is used to make copy of data in which updates are made to iterate it in original size
        this.data.slice().forEach((ao: AccordionObject) => {
          this.setClosedItem(ao);
        });
      }
    });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public setOpenedItem(ao: AccordionObject) {
    const openState = this.addOrUpdate(ao, false, false);
    this.stOnAccordionOpen.emit(new AccordionChange(ao.openedItem, openState));
  }

  public setClosedItem(ao: AccordionObject) {
    const openState = this.addOrUpdate(ao, false, true);
    this.stOnAccordionOpen.emit(new AccordionChange(ao.openedItem, openState));
  }

  public toggleOpenedItem(ao: AccordionObject) {
    const openState = this.addOrUpdate(ao, true, false);
    this.stOnAccordionOpen.emit(new AccordionChange(ao.openedItem, openState));
  }

  public updateItem(item: any) {
    if (this.hasItem(item)) {
      this.replaceItem(item);
      this.stOnAccordionOpen.emit(new AccordionChange(item, true));
    }
  }

  public addOrUpdate(ao: AccordionObject, toggle: boolean, forceClose: boolean): boolean {
    const added = true;
    const removed = false;
    if (this.toggleMultiple) {
      if (!this.hasObject(ao) && !forceClose) {
        this.addObject(ao);
        return added;
      } else if (toggle || forceClose) {
        this.removeObject(ao);
        return removed;
      }
    } else {
      if ((toggle && this.hasObject(ao)) || forceClose) {
        // If exists (is opened) close it
        this.data = [];
        return removed;
      } else {
        // Change opened one with object
        this.data[0] = ao;
        return added;
      }
    }
  }

  private getItemIndex(item: any) {
    if (this.comparator) {
      return this.data.findIndex((ao: AccordionObject) => ao.openedItem[this.comparator] === item[this.comparator]);
    } else {
      return this.data.findIndex((ao: AccordionObject) => ao.openedItem === item);
    }
  }

  public hasObject(ao: AccordionObject) {
    return this.hasItem(ao.openedItem);
  }

  public addObject(ao: AccordionObject) {
    this.data.push(ao);
  }

  public removeObject(ao: AccordionObject) {
    this.data.splice(this.getItemIndex(ao.openedItem), 1);
  }

  public hasItem(item: any) {
    return this.getItemIndex(item) !== -1;
  }

  public replaceItem(item: any) {
    const oldAOIndex: number = this.getItemIndex(item);
    const oldAO: AccordionObject = this.data[oldAOIndex];
    this.data[oldAOIndex] = new AccordionObject(item, oldAO.context$);
  }

  public getObject(item: any) {
    return this.data[this.getItemIndex(item)];
  }

  public getContext(item: any) {
    return this.getObject(item).context$;
  }

  public detectChangesAndUpdate(changes: SimpleChanges) {
    if (changes.stToggleAccordion
      && !changes.stToggleAccordion.isFirstChange()
      && changes.stToggleAccordion.currentValue !== changes.stToggleAccordion.previousValue) {
      this.updateItem(changes.stToggleAccordion.currentValue);
    }
  }
}

@Directive({
  selector: '[stToggleAccordion]',
})
export class AccordionToggleDirective implements OnInit, OnChanges {
  @Input('disabled')
  disabled = false;

  @Input('preOpened')
  preOpened = false;

  constructor(@Host() private accordion: AccordionDirective) {
  }

  ngOnInit() {
    if (this.preOpened === true && !this.accordion?.hasItem(this.stToggleAccordion)) {
      this.toggle();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.accordion.detectChangesAndUpdate(changes);
  }

  @HostBinding('style.cursor') styleCursor = 'pointer';
  @HostBinding('style.display') styleDisplay = 'block';

  @Input()
  stToggleAccordion: any;

  @Input('context')
  context: { [key: string]: any } = {};

  @HostListener('click', ['$event'])
  onClick() {
    this.toggle();
  }

  toggle() {
    if (!this.disabled) {
      this.accordion.toggleOpenedItem(new AccordionObject(this.stToggleAccordion, this.context));
    }
  }
}

@Directive({
  selector: '[stOpenAccordion]',
})
export class AccordionOpenDirective implements OnInit, OnChanges {
  @Input('disabled')
  disabled = false;

  @Input('preOpened')
  preOpened = false;

  constructor(@Host() private accordion: AccordionDirective) {
  }

  ngOnInit() {
    if (this.preOpened === true) {
      this.open();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.accordion.detectChangesAndUpdate(changes);
  }

  @HostBinding('style.cursor') styleCursor = 'pointer';
  @HostBinding('style.display') styleDisplay = 'block';

  @Input()
  stOpenAccordion: any;

  @Input('context')
  context: { [key: string]: any } = {};

  @HostListener('click', ['$event'])
  onClick() {
    if (!this.disabled) {
      this.open();
    }
  }

  open() {
    if (!this.disabled) {
      this.accordion.setOpenedItem(new AccordionObject(this.stOpenAccordion, this.context));
    }
  }
}


export class AccordionObject extends BaseModel {
  openedItem: any;
  context$: BehaviorSubject<{ [key: string]: any }>;

  constructor(item: any, context: { [key: string]: any }) {
    super();
    this.openedItem = item;
    this.context$ = new BehaviorSubject<{ [key: string]: any }>({...context, $implicit: item});
  }
}

export class AccordionChange extends BaseModel {
  item: any;
  opened: boolean;

  constructor(item: any, opened: boolean) {
    super();
    this.item = item;
    this.opened = opened;
  }
}

