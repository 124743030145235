import { Component } from '@angular/core';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

export type DialogButton = {
  'name': string,
  'value': any,
};

@Component({
  selector : 'st-multi-button-dialog',
  templateUrl : './multi-button-dialog.component.html',
  styleUrls: ['./multi-button-dialog.component.scss']
})
export class MultiButtonDialogComponent {
  message = '';
  title = this.i18n('Title');
  buttons: DialogButton[] = [];

  constructor(private activeModal: ActiveModal,
              private i18n: I18n) {}

  cancel(): void {
    this.activeModal.close(false);
  }

  click(button: DialogButton): void {
    this.activeModal.close(button.value);
  }

  public setMessage(message: string): void {
    this.message = message;
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public addButton(button: DialogButton) {
    this.buttons = [...this.buttons, button];
  }

  public addButtons(buttons: DialogButton[]) {
    this.buttons = [...this.buttons, ...buttons];
  }

  public clearButtons() {
    this.buttons = [];
  }

  public removeButton(name: string) {
    this.buttons = this.buttons.filter(x => x.name !== name);
  }
}
