<span *ngIf="!item.value.rank; else hasRank"
  stTooltip="Very low priority item.value. Change priority to check keyword ranking position daily for this item.value."
  i18n-stTooltip>
  -
</span>
<ng-template #hasRank>
  {{item.value.rank === 999 ? '> 300' : item.value.rank}}
  <span class="keyword__rank" *ngIf="item.value.rank_change" [class.keyword__rank--high]="item.value.rank_change >= 0"
    [class.keyword__rank--low]="item.value.rank_change < 0">&nbsp;({{ item.value.rank_change }})</span>
</ng-template>
