<st-dialog #dialog="dialog" [class.dialog--center-text]="centerText">
    <div dialog-header class="header">
        <st-icon *ngIf="successIcon" icon="success-v2"></st-icon>
        <div class="question">{{ title }}</div>
        <span class="close-icon" (click)="close()">
            <st-icon icon="cross-blue"></st-icon>
        </span>
    </div>
    <div dialog-content>
        <p *ngIf="message">{{ message }}</p>
    </div>
    <div dialog-footer class="footer" [class.footer--center]="centerText">
        <st-button-v2 color="v2_red_empty" (click)="close()" [label]="buttonText"></st-button-v2>
    </div>
</st-dialog>
