import { BaseModel } from "@app/shared/data/base.models";

export class Affiliate extends BaseModel {
  static className = 'Affiliate';

  user_id: number;
  first_name: string;
  last_name: string;
  email: string;
  registered_at: Date;
  paypal_email: string;
  scheduled_for: string;
  commisions: number;

  get fullName(): string {
    return this.first_name + ' ' + this.last_name;
  }
}
