<ng-container *ngFor="let action of item.value">
  <ng-container *ngIf="action.type === actionEnum.BUTTON">
    <st-button-v2
      [color]="action.buttonColor || 'primary'"
      [label]="action.buttonText || 'Apply'"
      [disabled]="action.disabled"
      (clicked)="clicked.emit(action.type)"
      i18n-label></st-button-v2>
  </ng-container>
  <ng-container *ngIf="action.type !== actionEnum.BUTTON">
    <button [stTooltip]="action.tooltipText" (click)="clicked.emit(action.type)"
      class="action-button"
      [class.edit]="action.type === actionEnum.EDIT"
      [class.delete]="action.type === actionEnum.DELETE" >
      <st-icon
        class="action-icon"
        [icon]="action.type"></st-icon>
    </button>
  </ng-container>
</ng-container>
