<div class="saved-filters-wrapper">
    <div class="saved-filters-button" [class.saved-filters-button--active]="savedViewsOpened" (click)="toggleSavedViewsModal()" i18n>SAVED VIEWS</div>
    <div class="saved-filters-dropdown" [class.saved-filters-dropdown--visible]="savedViewsOpened">
      <div class="saved-filters-dropdown__content" [class.saved-filters-dropdown__content--empty]="savedViews?.length === 0">
        <div class="saved-filters-dropdown__empty" *ngIf="savedViews?.length === 0"></div>
        <div class="saved-filters-dropdown__empty-text" *ngIf="savedViews?.length === 0" i18n>No Existing Views</div>

        <div class="saved-filters-list">
          <div class="saved-filters-list__filter" [class.saved-filters-list__filter--selected]="appliedView === view" *ngFor="let view of savedViews">
            <div class="saved-filters-list__filter-column saved-filters-list__filter-column--name">{{view.name | uppercase}}</div>
            <div class="saved-filters-list__filter-column">
              <a
                (click)="applyView(_appliedView === view ? null : view)"
                i18n
                [class.disable]="_appliedView === view">
                {{_appliedView === view ? 'Disable View':'Apply View'}}
              </a>
            </div>
            <div class="saved-filters-list__filter-column">
              <st-icon (click)="deleteView(view)" icon="delete-v2" pointer="true"></st-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
