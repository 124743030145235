
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient, HttpParameterCodec, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";
import { User } from "@app/shared/data/user/user.models";
import { Plan } from "@app/shared/data/plan/plan.models";

export class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}

@Injectable()
export class UserAdminAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<UserAdmin>> {
    const p = new HttpParams({
      encoder: new CustomEncoder(),
      fromObject: {
        ...params,
        searchTerm: params['searchTerm'].trim()
      },
    });

    return this.http.get(this.constructFullUrl('api/admin/users'), { params: p }).pipe(
      map(data => UserAdmin.collect<UserAdmin>(data, UserAdmin.BACKEND_PAGINATION)));
  }

  public get(id): Observable<UserAdmin> {
    return this.http.get(this.constructFullUrl('api/admin/users/' + id)).pipe(
      map(d => new UserAdmin(d)));
  }

  public impersonate(user: UserAdmin, privileged: boolean = false): Observable<User> {
    return this.http.post(this.constructFullUrl('api/admin/users/impersonate'), { user_id: user.id, privileged: privileged }).pipe(
      map(data => new User(data)));
  }

  public addTrialDays(user: UserAdmin, days: number): Observable<any> {
    let params = {
      id  : user.id + '',
      days: days + ''
    };

    return this.http.get(this.constructFullUrl('api/admin/subscriptions/add-trial-days'), { params: params });
  }

  public updateUser(userId: string, params: {}) {
    return this.http.put(this.constructFullUrl('api/admin/users/' + userId), params).pipe(map(data => new UserAdmin(data)));
  }

  public updateCommissions(userId: string, commissionRatio: number, percentOff: number) {
    let params = {
      action                    : 'commission',
      default_commission_ratio  : commissionRatio,
      default_coupon_percent_off: percentOff,
    };
    return this.updateUser(userId, params);
  }

  public addAdditionalPlan(user: UserAdmin, plan: Plan): Observable<any> {
    let params = {
      plan_id: plan.id,
    };
    return this.http.put(this.constructFullUrl('api/admin/users/' + user.id + '/additional-plans'), params);
  }

  public removeAdditionalPlan(user: UserAdmin, plan: Plan): Observable<any> {
    let params = {
      plan_id: plan.id + '',
    };
    return this.http.delete(this.constructFullUrl('api/admin/users/' + user.id + '/additional-plans'), { params: params });
  }

  public updatePermissions(id, changes): Observable<UserAdmin> {
    return this.http.put(this.constructFullUrl('api/admin/users/' + id + '/permissions'), { changes: changes }).pipe(
      map(d => new UserAdmin(d)));
  }

  public transferCredentials(credential_id, user): Observable<any> {
    return this.http.put(this.constructFullUrl('api/admin/users/' + user.id + '/transfer-credential'), {credential_id: credential_id});
  }
}
