import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { AdminPlanActions } from "@app/shared/data/admin/plan/plan.actions";
import { AdminPlan } from "@app/shared/data/admin/plan/plan.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { SystemNotificationAdmin } from "@app/shared/data/admin/system-notification/system-notification.models";

const INITIAL_STATE: AppState = {
  data   : new Collection<AdminPlan>([]),
  loading: false,
  error  : null,
  edit   : {
    data    : new AdminPlan(),
    error   : null,
    _loading: false
  },

  _filters      : {},
  _be_pagination: {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage('admin-plans') || 50
  }
};

export function adminPlanReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == PaginationActions.getPagActionTypes(SystemNotificationAdmin).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-plans');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == AdminPlanActions.TYPES.GET) {
    return {
      ...state,
      edit: {
        data: null,
        error: null,
        _loading: true
      }
    }
  }

  if (action.type == AdminPlanActions.TYPES.GET_SUCCEEDED) {
    return {
      ...state,
      edit: {
        data: action.data,
        error: null,
        _loading: false
      }
    }
  }

  if (action.type == AdminPlanActions.TYPES.GET_FAILED) {
    return {
      ...state,
      edit: {
        error: action.data,
        _loading: false
      }
    }
  }

  if (action.type == AdminPlanActions.TYPES.SAVE) {
    return {
      ...state,
      edit: {
        error: null,
        _loading: true
      }
    }
  }

  if (action.type == AdminPlanActions.TYPES.SAVE_FAILED) {
    return {
      ...state,
      edit: {
        error: action.data,
        _loading: false
      }
    }
  }

  if (action.type == AdminPlanActions.TYPES.SAVE_SUCCEEDED) {
    return {
      ...state,
      data    : state['data']
        .filter(plan => plan.id != action.data.id)
        .push(action.data)
        .sort((a:AdminPlan, b: AdminPlan) => {
          return b.id - a.id;
        }),
      edit: {
        data: action.data,
        error: null,
        _loading: false
      }
    }
  }
  //
  // if (action.type == AdminPlanActions.TYPES.LOAD_SUCCEEDED) {
  //   return {

  return paginationReducer(AdminPlan)(state, action);
}
