import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { WalletTransactionsApi } from '@app/shared/data/wallet-transactions/wallet-transactions.api';
import { WalletTransaction, EmailLog } from '@app/shared/data/wallet-transactions/wallet-transactions.models';
import { WalletTransactionsActions } from '@app/shared/data/wallet-transactions/wallet-transactions.actions';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ExportService } from '@app/shared/services/export.service';
import { ToastService } from '@app/core/services/toast.service';
import { GiftCardsApi } from './gift-cards.api';

@Injectable()
export class WalletTransactionsEpics {

  constructor(private store: NgRedux<AppState>,
    private walletTransactionsApi: WalletTransactionsApi,
    private giftCardsApi: GiftCardsApi,
    protected toastService: ToastService,
    private i18n: I18n,
    private walletTransactionsActions: WalletTransactionsActions) {
  }

  public createEpic() {
    return [
      this.loadTransactions,
      this.topUp,
      this.resend,
      this.export,
      this.stats,
      this.sendGiftCard,
      this.markAsAvailableTransaction,
      this.loadEmailLogs,
    ];
  }

  loadTransactions = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === PaginationActions.getPagActionTypes(WalletTransaction).LOAD_PAGE ||
        action.type === WalletTransactionsActions.TYPES.FILTER) {
        const state = store.getState()['wallet_transactions'];

        this.walletTransactionsApi.all({ ...state['_be_pagination'], ...state['_filters'] })
          .subscribe(
            (walletTransactions: Collection<WalletTransaction>) => {
              this.store.dispatch(this.walletTransactionsActions.setPage(walletTransactions));
            },
            (response) => {
              this.store.dispatch(this.walletTransactionsActions.loadPageFailed(response.error));
            },
            () => {
            },
          );
      }

      return n;
    };
  }

  topUp = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === WalletTransactionsActions.TYPES.TOP_UP) {
        this.walletTransactionsApi.create(
          action.data.amount,
          action.data.paymentMethod,
          action.data.checkAmount,
          action.data.remoteId,
          action.data.paymentIntent
        ).subscribe(
          (walletTransaction: WalletTransaction) => {
            const params = store.getState()['wallet_transactions']['_be_pagination'];

            this.store.dispatch(this.walletTransactionsActions.topUpSucceeded(walletTransaction));
            this.store.dispatch(this.walletTransactionsActions.loadPage(params['page'], params['limit'], params['sort']));
          },
          (response) => {
            this.store.dispatch(this.walletTransactionsActions.topUpFailed(response.error.error));
          },
          () => {
          },
        );
      }

      return n;
    };
  }

  resend = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === WalletTransactionsActions.TYPES.RESEND) {
        this.giftCardsApi.resend(action.data.transaction.id, action.data.email)
          .subscribe(
            (walletTransaction: WalletTransaction) => {
              this.toastService.success(this.i18n('Email sent'), this.i18n('Email was resend to {{email}}', {
                email: action.data.email,
              }));

              this.store.dispatch(this.walletTransactionsActions.resendSucceeded(walletTransaction));
            },
            (response) => {
              this.toastService.error(this.i18n('Sorry.'), this.i18n('We could not resend your email at the moment.'));
              this.store.dispatch(this.walletTransactionsActions.resendFailed(action.data.transaction, response.error.error));
            }
          );
      }

      return n;
    };
  }

  export = store => next => {
    return (action) => {
      if (action.type === WalletTransactionsActions.TYPES.EXPORT) {
        this.walletTransactionsApi.export()
          .subscribe(
            (data: any) => {
              ExportService.downloadBlob(data.blob, data.filename != null ? data.filename : 'ST_transactions_export.csv');
            },
            (err) => {
            },
            () => {
              this.store.dispatch(this.walletTransactionsActions.exportFinished());
            }
          );
      }
      return next(action);
    };
  }

  stats = store => next => {
    return (action) => {
      if (action.type === WalletTransactionsActions.TYPES.GET_STATS) {
        this.walletTransactionsApi.stats()
          .subscribe(
            (data) => {
              this.store.dispatch(this.walletTransactionsActions.getStatsSucceeded(data));
            },
            (err) => {
              this.store.dispatch(this.walletTransactionsActions.getStatsFailed(err));
            }
          );
      }
      return next(action);
    };
  }

  sendGiftCard = store => next => {
    return (action) => {
      if (action.type === WalletTransactionsActions.TYPES.SEND_GIFT_CARD) {
        this.giftCardsApi.create({
          amount: Math.round(action.data['amount'] * 100),
          email: action.data['email'],
          phone_number: action.data['phone_number'],
          gift_card: action.data['gift_card'],
          marketplace: action.data['marketplace'],
          email_subject: action.data['email_subject'],
          email_message: action.data['email_message'],
        }).subscribe(
          (transaction: WalletTransaction) => {
            const filters = store.getState()['wallet_transactions']['_filters'];
            this.store.dispatch(this.walletTransactionsActions.filter(filters));
            this.store.dispatch(this.walletTransactionsActions.sendGiftCardSucceeded());

            this.toastService.success(this.i18n('Gift Card'), this.i18n('Gift card was sent to {{identifier}}.', {
              identifier: transaction.receiver_identifier,
            }));
          },
          (err) => {
            if (err.error.error.error_code === 'error.balance_too_low') {
              this.toastService.error(
                this.i18n('Sorry.'),
                this.i18n('Wallet balance is too low. Please top-up your wallet.')
              );
            } else {
              this.toastService.error(
                this.i18n('Sorry.'),
                this.i18n('Creation of Gift Card failed.')
              );
            }
            this.store.dispatch(this.walletTransactionsActions.sendGiftCardFailed(err));
          },
        );
      }
      return next(action);
    };
  }

  markAsAvailableTransaction = store => next => {
    return (action) => {
      if (action.type === WalletTransactionsActions.TYPES.MARK_AS_AVAILABLE) {
        let transactionId = action.data['transactionId'];
        this.walletTransactionsApi.markAsAvailable(transactionId)
          .subscribe(
            () => {
              this.store.dispatch(this.walletTransactionsActions.markAsAvailableSucceeded(transactionId));
              this.toastService.success(this.i18n('Success'), this.i18n('Transaction was marked as available.'))
            },
            (err) => {
              this.store.dispatch(this.walletTransactionsActions.markAsAvailableFailed(transactionId));
              this.toastService.error(
                this.i18n('Sorry.'),
                this.i18n('Transaction was unsuccessfully marked as available.')
              );
            },
          );
      }
      return next(action);
    };
  }

  loadEmailLogs = store => next => {
    return (action) => {
      if (action.type === WalletTransactionsActions.TYPES.LOAD_EMAIL_LOGS) {
        const transaction = action.data['transaction'];

        this.walletTransactionsApi.logs(transaction['hash'])
          .subscribe(
            (logs: Collection<EmailLog>) => {
              this.store.dispatch(this.walletTransactionsActions.loadEmailLogsSucceeded(logs));
            },
            (err) => {
              this.store.dispatch(this.walletTransactionsActions.loadEmailLogsFailed());
              this.toastService.error(
                this.i18n('Sorry.'),
                this.i18n('We could not load Email logs at the moment.')
              );
            },
          );
      }
      return next(action);
    };
  }
}
