
import {of as observableOf, from as observableFrom,  Observable } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '@app/core/services/toast.service';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppState } from '../app-state.model';
import { ProductManagerActions } from './product-manager.actions';
import { ProductManagerAPI } from './product-manager.api';
import { bufferCount, filter, first, flatMap, catchError, map } from 'rxjs/operators';
import {IAddedCompetitorProduct, IProductManagerDraft, IProductManagerState} from '@app/shared/data/product-manager/product-manager.models';
import {IFieldOrder} from '@app/client/v2/product-manager/product-manager-product-list/product-manager-product-list.component';
import { ProductManagerListingManagerPublishedModalComponent } from '@app/client/v2/product-manager/product-manager-listing-manager-published-modal/product-manager-listing-manager-published-modal.component';
import { productManagerDefaultSortBy } from '@app/client/v2/product-manager/product-list-table/product-list-table.component';
import { getTranslationFromResponse } from '@app/helpers';

@Injectable()
export class ProductManagerEpics {
  updateKeywordsTimeout: any;
  updateKeywordsTimeoutDuration = 3000;
  updatedKeyword: {
    keyword: string;
    updatedKeyword: string;
  };
  publishedDraft: IProductManagerDraft;
  sfrGraphOpenedKeyword: string;
  isForeignFetch: boolean;

  constructor(
    private productManagerActions: ProductManagerActions,
    private productManagerApi: ProductManagerAPI,
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private i18n: I18n,
    private toastService: ToastService,
    private modalService: ModalService,
    private dialogService: DialogService,
  ) { }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.getProducts,
      this.resyncProducts,
      this.addForeignProducts,
      this.foreignProductAddFinished,
      this.getProduct,
      this.getVariations,
      this.getGraphData,
      this.addCompetitorProduct,
      this.removeCompetitorProduct,
      this.getCompetitorsProducts,
      this.getDraftAndListings,
      this.saveDraft,
      this.publishDraft,
      this.getKeywords,
      this.updateKeywords,
      this.addKeywords,
      this.archiveProducts,
      this.resetProducts,
      this.analyzeKeywords,
      this.analyzeKeywordsHydrationFinished,
      this.openUpdatePlanComponent,
      this.deleteKeywords,
      this.recalculateKeywordsPriority,
      this.refreshIndex,
      this.copyKeywords,
      this.getKeywordGraphData,
      this.setProductStatus,
      this.setProductTags,
      this.removeProductTags,
      this.correctKeyword,
      this.getBrandAnalytics,
      this.handleFailedKeywordSearch,
      this.loadAvailableTagFilters,
      this.deleteProduct
    ];
  }

  getProducts = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_PRODUCTS) {
        const sortFields = action.data.sort
          .map((fieldOrder: IFieldOrder) => fieldOrder.order + fieldOrder.field)
          .join(',');

        const {
          page, perPage, term, tags, status, foreign
        } = action.data;

        this.isForeignFetch = foreign;

        this.productManagerApi.getProducts(page, perPage, sortFields, foreign, term, tags, status)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerProductsLoaded({...response, isForeignFetch: this.isForeignFetch}));
              this.isForeignFetch = false;
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerProductsError(error));
              this.isForeignFetch = false;
            },
          );
      }
      return next(action);
    };
  }


  resyncProducts = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.RESYNC_PRODUCTS) {
        this.productManagerApi.syncProducts()
            .subscribe(
              () => {},
              (error) => {
                this.toastService.error(
                  this.i18n('Syncing products failed'),
                  getTranslationFromResponse([{
                    code: 'error.in_progress',
                    translation: this.i18n('Product Sync already in progress or completed in last 10 minutes.')
                  }], error)
                );
                if (error.code !== 'error.in_progress') {
                  this.ngRedux.dispatch(this.productManagerActions.resyncProductsStopped());
                }
              });
      }
      return next(action);
    };
  }

  addForeignProducts = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.ADD_FOREIGN_PRODUCTS) {
        this.productManagerApi.addForeignProducts(action.data)
          .subscribe(
            () => {},
            (error) => {
              if (error && error.message) {
                this.toastService.error(this.i18n('Adding products failed'), error.message);
              }
            });
      }
      return next(action);
    };
  }

  foreignProductAddFinished = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions._events.foreign_product_add_finished) {
        if (action.data.error && action.data.error.message) {
          this.toastService.error(action.data.asin, action.data.error.message);
        }

        this.ngRedux.dispatch(this.productManagerActions.addForeignProductsCloseModal());
        return this.ngRedux.dispatch(this.productManagerActions.getProducts(1, 20, productManagerDefaultSortBy, true));
      }
      return next(action);
    }
  }

  getProduct = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_PRODUCT) {
        this.productManagerApi.getProduct(action.data.asin)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.setActiveProduct(response));
            },
            () => {
              this.router.navigate(['/product-manager']);
            },
          );
      }
      return next(action);
    };
  }

  getVariations = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_VARIATIONS) {
        this.productManagerApi.getVariations(action.data.asin, action.data.limit, action.data.page)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerVariationsLoaded(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerVariationsError(error));
            },
          );
      }
      return next(action);
    };
  }

  getGraphData = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_GRAPH) {
        this.productManagerApi.getGraphData(action.data.asin, action.data.types, action.data.start, action.data.end)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerGraphDataLoaded(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerGraphDataError(error));
            },
          );
      }
      return next(action);
    };
  }

  getKeywordGraphData = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_KEYWORD_GRAPH) {
        this.productManagerApi.getKeywordGraphData(action.data.asin, action.data.keyword)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerKeywordGraphDataLoaded(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.productManagerKeywordGraphDataError(error));
            },
          );
      }
      return next(action);
    };
  }

  addCompetitorProduct = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.ADD_COMPETITOR_PRODUCT) {
        this.productManagerApi.addProductToCompetitorList(action.data.asin, action.data.asins)
          .subscribe(
            (response: { data: IAddedCompetitorProduct }[]) => {
              this.ngRedux.dispatch(this.productManagerActions.addCompetitorProductSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.addCompetitorProductError(error));
            }
          );
      }
      return next(action);
    };
  }


  removeCompetitorProduct = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.REMOVE_COMPETITOR_PRODUCT) {
        const removedProductId = action.data.asins;
        this.productManagerApi.removeProductFromCompetitorList(action.data.asin, action.data.asins)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.removeCompetitorProductSuccess(removedProductId));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.removeCompetitorProductError(error));
            },
          );
      }
      return next(action);
    };
  }

  getCompetitorsProducts = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_COMPETITION_PRODUCTS) {
        this.productManagerApi.getCompetitorsProducts(action.data.asin)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.getCompetitorsProductsLoaded(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.getCompetitorsProductsError(error));
            },
          );
      }
      return next(action);
    };
  }

  getDraftAndListings = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_DRAFT_AND_LISTINGS) {
        const asin = action.data.asin;
        this.productManagerApi.getProductDraftAndListings(asin)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.getDraftAndListingsSuccess(response, asin));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.getDraftAndListingsError(error));
            },
          );
      }
      return next(action);
    };
  }

  setProductStatus = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.SET_PRODUCT_STATUS) {
        this.productManagerApi.setStatus(action.data.asin, action.data.status)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.setProductStatusSuccess(response));
            },
            (error) => {
              this.toastService.error(this.i18n('Set product state failed'), error);
              this.ngRedux.dispatch(this.productManagerActions.setProductStatusError(error));
            },
          );
      }
      return next(action);
    };
  }

  setProductTags = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.SET_PRODUCT_TAGS) {
        this.productManagerApi.setTags(action.data.asin, action.data.tags)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.setProductTagsSuccess(response.data));
            },
            (error) => {
              this.toastService.error(this.i18n('Set product tags failed'), error);
              this.ngRedux.dispatch(this.productManagerActions.setProductTagsError(error));
            },
          );
      }
      return next(action);
    };
  }

  removeProductTags = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.REMOVE_PRODUCT_TAGS) {
        this.productManagerApi.removeTags(action.data.asin, action.data.tags)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.removeProductTagsSuccess(response.data));
            },
            (error) => {
              this.toastService.error(this.i18n('Remove product tags failed'), error);
              this.ngRedux.dispatch(this.productManagerActions.removeProductTagsError(error));
            },
          );
      }
      return next(action);
    };
  }

  saveDraft = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.SAVE_DRAFT) {
        this.productManagerApi.saveDraft(action.data.asin, action.data.draft)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.saveDraftSuccess(response));
            },
            (error) => {
              const errors = error.message.join(', ');
              this.toastService.error(this.i18n('Safe draft failed'), errors);
              this.ngRedux.dispatch(this.productManagerActions.saveDraftError(error));
            },
          );
      }
      return next(action);
    };
  }

  publishDraft = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.PUBLISH_DRAFT) {
        this.publishedDraft = action.data.draft;
        this.productManagerApi.publishDraft(action.data.asin, action.data.draft)
          .subscribe(
            () => {
              this.modalService.open(ProductManagerListingManagerPublishedModalComponent);
              this.ngRedux.dispatch(this.productManagerActions.publishDraftSuccess(this.publishedDraft));
              this.publishedDraft = null;
            },
            (error) => {
              error = error.error.error;
              let errors = error.message;
              if (error.message instanceof Array) {
                errors = error.message.join(', ');
              }
              if (error.error_code == "error.lwa_unauthorized") {
                errors = this.i18n('Missing or invalid LWA credentials. Please connect your Amazon account with LWA.');
              }

              this.toastService.error(this.i18n('Publish draft failed'), errors);
              this.ngRedux.dispatch(this.productManagerActions.publishDraftError(error));
              this.publishedDraft = null;
            },
          );
      }
      return next(action);
    };
  }

  getKeywords = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_KEYWORDS) {
        this.productManagerApi.getKeywords(action.data.asin)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.getKeywordsSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.getKeywordsError(error));
            },
          );
      }
      return next(action);
    };
  }

  updateKeywords = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.UPDATE_KEYWORDS) {
        this.productManagerApi.updateKeywords(action.data.asin, action.data.keywords)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.updateKeywordSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.updateKeywordError(error));
            },
          );
      }
      return next(action);
    };
  }

  addKeywords = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.ADD_KEYWORDS) {
        const asin = action.data.asin;
        this.productManagerApi.addKeywords(asin, action.data.keywords, action.data.minimumPriorityLimit)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.getKeywords(asin));
              this.ngRedux.dispatch(this.productManagerActions.addKeywordSuccess(response));
            },
            (error) => {
              if (error && error.message) {
                if (Array.isArray(error.message)) {
                  error.message.forEach((errorMsg) => this.toastService.error('Error', errorMsg));
                } else {
                  this.toastService.error('Error', error.message)
                }
              }
              this.ngRedux.dispatch(this.productManagerActions.getKeywords(asin));
              this.ngRedux.dispatch(this.productManagerActions.addKeywordError(error));
            },
          );
      }
      return next(action);
    };
  }

  archiveProducts = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.ARCHIVE_PRODUCTS) {
        this.productManagerApi.archiveProducts(action.data)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.archiveProductsSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.archiveProductsError(error));
            },
          );
      }
      return next(action);
    };
  }

  resetProducts = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.RESET_PRODUCTS) {
        this.productManagerApi.resetProducts(action.data.asin, action.data.reason)
          .subscribe(
            () => { },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.resetProductsError(error));
            },
          );
      }
      return next(action);
    };
  }

  analyzeKeywords = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.ANALYZE_KEYWORDS) {
        this.productManagerApi.analyzeKeywords(action.data.asins, action.data.addToAsin)
          .subscribe(
            () => { },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.analyzeKeywordsError(error));
            },
          );
      }
      return next(action);
    };
  }

  analyzeKeywordsHydrationFinished = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions._events.keyword_manager_hydration_finished) {
        this.ngRedux.select('product_manager')
          .pipe(
            first(),
            filter((productManagerState: IProductManagerState) => productManagerState.activeProduct !== null),
            filter((productManagerState: IProductManagerState) => productManagerState.activeProduct.asin === action.data.asin),
          )
          .subscribe((productManagerState: IProductManagerState) => {
            this.productManagerApi.getKeywords(action.data.asin)
              .subscribe(
                (response) => {
                  this.ngRedux.dispatch(this.productManagerActions.getKeywordsHydrationFinished());

                  if (!productManagerState.keywords) {
                    this.updateKeywordsTimeout = setTimeout(() => {
                      clearTimeout(this.updateKeywordsTimeout);
                      this.ngRedux.dispatch(this.productManagerActions.getKeywordsSuccess(response));
                    }, this.updateKeywordsTimeoutDuration);
                  }
                },
                (error) => {
                  this.ngRedux.dispatch(this.productManagerActions.getKeywordsError(error));
                },
              );
          });
      }
      return next(action);
    };
  }

  deleteKeywords = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.BULK_DELETE_KEYWORDS) {
        observableFrom(action.data.keywords)
          .pipe(
            // do 10 requests with 50 keywords at a time
            bufferCount(50),
            flatMap((kws: string[]) => {
              return this.productManagerApi.deleteKeywords(action.data.asin, kws)
                .pipe(
                  map(() => ({success: true, kws})),
                  catchError(() => observableOf({success: false, kws})),
                );
            }, null, 10),
          )
          .subscribe(
            (response: {success: boolean, kws: string[]}) => {
              if (response.success) {
                this.ngRedux.dispatch(this.productManagerActions.deleteKeywordsSuccess(response.kws));
              } else {
                this.ngRedux.dispatch(this.productManagerActions.deleteKeywordsError(response.kws));
              }
            }
          );
      }
      return next(action);
    };
  }

  recalculateKeywordsPriority = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.BULK_RECALCULATE_PRIORITY) {
        this.productManagerApi.recalculatePriority(action.data.asin, action.data.keywords)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.recalculatePrioritySuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.recalculatePriorityError(error));
            },
          );
      }
      return next(action);
    };
  }

  refreshIndex = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.REFRESH_INDEX) {
        this.productManagerApi.refreshIndex(action.data.asin, action.data.keywords)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.refreshIndexSuccess(response));
            },
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.refreshIndexError({asin: action.data.asin, error: response.error}));

              if (response.error.error.error_code === 'error.throttle_requests_exception') {
                this.toastService.warning(this.i18n('Index check limit reached'), this.i18n('Index check can be triggered only once per hour.'));
              }
            },
          );
      }

      // Index check finished
      if (action.type === ProductManagerActions._events.product_manager_keyword_index_check_finished) {
        this.ngRedux.select('product_manager')
          .pipe(
            first(),
            filter((productManagerState: IProductManagerState) => productManagerState.activeProduct !== null),
            filter((productManagerState: IProductManagerState) => productManagerState.activeProduct.asin === action.data.asin),
          )
          .subscribe(() => {
            // Refresh keywords
            this.ngRedux.dispatch(this.productManagerActions.getKeywords(action.data.asin));
          });
      }
      return next(action);
    };
  }

  openUpdatePlanComponent = () => next => {
    return (action) => {
      if ([
        ProductManagerActions._events.keyword_manager_priority_limit_reached,
        ProductManagerActions._events.keyword_manager_non_priority_limit_reached,
        ProductManagerActions._events.keyword_manager_per_product_limit_reached,
      ].includes(action.type)) {
        const titleMapper = {
          [ProductManagerActions._events.keyword_manager_priority_limit_reached]: this.i18n('You have reached the number limit of tracked keywords'),
          [ProductManagerActions._events.keyword_manager_non_priority_limit_reached]: this.i18n('You have reached the number limit of tracked keywords'),
          [ProductManagerActions._events.keyword_manager_per_product_limit_reached]: this.i18n('You have reached the number limit of tracked keywords'),
        };

        const messageMapper = {
          [ProductManagerActions._events.keyword_manager_priority_limit_reached]: this.i18n('Please upgrade your subscription plan or remove keywords to be able to add the new ones'),
          [ProductManagerActions._events.keyword_manager_non_priority_limit_reached]: this.i18n('Please upgrade your subscription plan or remove keywords to be able to add the new ones'),
          [ProductManagerActions._events.keyword_manager_per_product_limit_reached]: this.i18n('Please upgrade your subscription plan or remove keywords to be able to add the new ones'),
        };

        this.ngRedux.dispatch(this.productManagerActions.showUpgradePlanBanner(titleMapper[action.type], messageMapper[action.type]));
      }
      return next(action);
    };
  }

  copyKeywords = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.BULK_COPY_KEYWORDS) {
        this.productManagerApi.copyKeywords(action.data.asin, action.data.asins)
          .subscribe(
            (response) => {
              this.dialogService.info(
                this.i18n('Keywords are successfully copied to your product'),
                this.i18n('You’ve just imported new keywords to the selected product'),
                this.i18n('Ok, got it'),
                true,
              );
              this.ngRedux.dispatch(this.productManagerActions.copyKeywordsSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.copyKeywordsError(error));
            },
          );
      }
      return next(action);
    };
  }

  correctKeyword = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.CORRECT_KEYWORD) {
        this.updatedKeyword = {
          keyword: action.data.keyword,
          updatedKeyword: action.data.updatedKeyword,
        };
        this.productManagerApi.correctKeyword(action.data.asin, action.data.keyword)
          .subscribe(
            () => {
              this.ngRedux.dispatch(this.productManagerActions.correctKeywordSuccess(this.updatedKeyword));
              this.updatedKeyword = null;
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.correctKeywordError(error));
              this.updatedKeyword = null;
            },
          );
      }
      return next(action);
    };
  }

  getBrandAnalytics = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.GET_BRAND_ANALYTICS_KEYWORD) {
        this.sfrGraphOpenedKeyword = action.data.keyword;
        this.productManagerApi.getBrandAnalytics(action.data.keyword)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.productManagerActions.getKeywordBrandAnalyticsSuccess({...response, keyword: this.sfrGraphOpenedKeyword}));
              this.sfrGraphOpenedKeyword = null;
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.getKeywordBrandAnalyticsError(error));
              this.sfrGraphOpenedKeyword = null;
            },
          );
      }
      return next(action);
    };
  }

  handleFailedKeywordSearch = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions._events.competition_research_keyword_search_finished) {
        const state = this.ngRedux.getState()['product_manager'];

        if ((!!state.activeProduct && state.activeProduct.asin === action.data.asin) && !!action.data.error && action.data.error.code !== undefined) {
          if (action.data.error.code === 'error.no-new-keywords') {
            this.toastService.warning(this.i18n('Keyword search stopped'), this.i18n('No new keywords found.'));
          } else {
            this.toastService.error(this.i18n('Keyword search failed'), this.i18n('An error occurred during keyword search.'));
          }
        }
      }
      return next(action);
    };
  }

  loadAvailableTagFilters = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.LOAD_AVAILABLE_TAG_FILTERS) {
        this.productManagerApi.loadAvailableTagFilters()
          .subscribe(
            (res) => {
              this.ngRedux.dispatch(this.productManagerActions.loadAvailableTagFiltersSuccess(res));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.loadAvailableTagFiltersError(error));
            }
          )
      }

      return next(action);
    };
  }

  deleteProduct = () => next => {
    return (action) => {
      if (action.type === ProductManagerActions.TYPES.DELETE_PRODUCT) {
        this.productManagerApi.deleteProduct(action.data)
          .subscribe(
            (_) => {
              this.ngRedux.dispatch(this.productManagerActions.deleteProductSuccess(action.data));
            },
            (error) => {
              this.ngRedux.dispatch(this.productManagerActions.deleteProductError(error));
            }
          )
      }

      return next(action);
    };
  }
}
