import { BaseModel, Collection } from '@app/shared/data/base.models';
import { Coupon } from '@app/shared/data/coupon/coupon.models';

export class LastLaunchSchedule extends BaseModel {
  static className = 'LastLaunchSchedule';

  id: number;
  type?: string;
  name: string;
  coupons_total: number;
  coupons_redeem: number;
  no_of_keywords: number;
  clicks: number;
  starts_at?: string;
  created_at: string;
  duration?: number;
  total_price: number;
  discount_amount: number;
  paid_amount: number;
  paid_fee_amount: number;
  paid_at: Date;
  paid: boolean;
  coupon?: Coupon;
  set_moq: boolean; // Max Order Quantity
  cancelled_at: Date;
  is_active: boolean;
  is_editable: boolean;
  is_paid?: boolean;

  product: LastLaunchScheduleProduct;
  keywords: Collection<LastLaunchScheduleKeyword>;
  coupons: Collection<LastLaunchScheduleCoupon>;
  rebateUnits: Collection<LastLaunchRebateUnit>;
  invoices: Collection<LastLaunchScheduleInvoice>;

  unitsCount() {
    const desired = this.keywords.sum((kw: LastLaunchScheduleKeyword) => kw.desired_clicks || 0);

    if (this.type === 'rebate') {
      return desired * this.duration;
    }

    return desired;
  }
}


export class LastLaunchScheduleDate extends BaseModel {
  static className = 'LastLaunchScheduleDate';

  id: number;
  date: string;
  available: boolean;
  used: boolean;
}

export class LastLaunchScheduleProduct extends BaseModel {
  static className = 'LastLaunchScheduleProduct';

  id?: number;
  asin: string;
  parent_asin: string;
  title: string;
  seller_name: string;
  description: string;
  image_url: string;

  price_before: number;
  price_after: number;

  bsr_start: number;
  bsr_end: number;
}

export class LastLaunchScheduleKeyword extends BaseModel {
  static className = 'LastLaunchScheduleKeyword';

  id: number;
  value: string;
  suggested_giveaways?: number;
  desired_clicks: number;
  relevancy?: string;
  power?: string;
  impressions: number;
  no_of_competitors?: number;
  clicks: number;
  ranking_start: number;
  ranking: number;
}

export class LastLaunchScheduleCoupon extends BaseModel {
  static className = 'LastLaunchScheduleCoupon';

  id: number;
  value: string;
}

export class LastLaunchRebateUnit extends BaseModel {
  static className = 'LastLaunchRebateUnit';

  order_id: string;
  used_at?: string;
  order_created_at?: string;
  order_cancelled_at?: string;
  order_refunded_at?: string;
  is_invalid: boolean;
}

export class LastLaunchDateDistribution extends BaseModel {
  static className = 'LastLaunchDateDistribution';

  id: number;
  date: string;
  percentage?: number;
  total_units: number;
  redeemed_units: number;
}

export class LastLaunchScheduleInvoice extends BaseModel {
  static className = 'LastLaunchScheduleInvoice';

  id: string;
  paid_at?: Date;
  coupon?: string;
  stripe_client_secret?: string;
  stripe_payment_method?: string;
  stripe_account?: string;
  amount: number;
  payment_fee: number;
  created_at: Date;

  public isWalletInvoice() {
    return this.id.startsWith('wal_');
  }
}
