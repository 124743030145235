import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Affiliate } from "@app/shared/data/affiliate/affiliate.models";

@Injectable()
export class AffiliateActions extends PaginationActions {
  protected model = Affiliate;

  static readonly TYPES = {};
}
