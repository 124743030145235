<div class="dropdown-wrapper" [class.dropdown-wrapper--opened]="opened" stOffClick (clickOutside)="opened = false">
  <div *ngIf="!displaySearch; else search" class="dropdown__title" (click)="toggleDropdown()">
    <div class="dropdown__title-text" [class.align-left]="displaySelectedOptionsCount">
      <div class="dropdown__title-text--value">{{title}}</div>
      <div class="dropdown__title-text--selected" *ngIf="displaySelectedOptions">{{selectedOptionsValues}}</div>
      <div class="dropdown__title-text--selected" *ngIf="displaySelectedOptionsCount && selectedOptionsValues.length">{{selectedOptionsValuesCount}}</div>
      <div *ngIf="!title && !selectedOptionsValues" class="placeholder" i18n>SELECT</div>
    </div>
    <div class="dropdown__title-icon"></div>
  </div>
  <ng-template #search>
    <div class="search-wrapper" [class.search-wrapper__input--opened]="opened">
      <input #searchInput *ngIf="opened || !selectedOptionsValues.length; else valuesCount" (click)="!opened && toggleDropdown()" class="search-wrapper__input" type="text" [(ngModel)]="searchString" (ngModelChange)="searchOptions()" placeholder="SELECT" i18n-placeholder>
      <ng-template #valuesCount>
        <div (click)="toggleDropdown($event)" class="search-wrapper__input">{{selectedOptionsValuesCount}}</div>
      </ng-template>
      <st-icon class="search-wrapper__icon" icon="search_blue"></st-icon>
    </div>
  </ng-template>
  <div class="dropdown">
    <div class="dropdown__description" *ngIf="description">{{description}}</div>
    <div class="dropdown__options">
      <ng-container  *ngFor="let option of options; trackBy: trackByFn">
        <div class="dropdown__option" [class.dropdown__option--checked]="option.checked" *ngIf="!option.hide">
          <st-checkbox-v2 (changed)="checkboxChanged($event, option)" [checked]="option.checked" [label]="option.label" color="red"></st-checkbox-v2>
        </div>
      </ng-container>
    </div>
  </div>
</div>
