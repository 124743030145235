import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { PayoutByPlanAdmin } from "@app/shared/data/admin/payout-by-plan/payout-by-plan.model";
import { PayoutByPlanAdminAPI } from "@app/shared/data/admin/payout-by-plan/payout-by-plan.api";
import { PayoutByPlanAdminActions } from "@app/shared/data/admin/payout-by-plan/payout-by-plan.actions";

@Injectable()
export class PayoutByPlanAdminEpics {
  constructor(private byPlanActions: PayoutByPlanAdminActions,
              private byPlanAPI: PayoutByPlanAdminAPI,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PayoutByPlanAdminActions.getPagActionTypes(PayoutByPlanAdmin).LOAD_PAGE || action.type == PayoutByPlanAdminActions.TYPES.FILTER) {
        const params = {
          ...action.data.pagination,
          limit: action.data.pagination.perPage,
          sort : action.data.pagination.sort.join(',')
        };
        this.byPlanAPI.byPlanCommision(params)
          .subscribe(
            (byPlanCommisions: Collection<PayoutByPlanAdmin>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.byPlanActions.setPage(byPlanCommisions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.byPlanActions.loadPageFailed(response));
            },
            () => {}
          );
      }
      return next(action);
    }
  };
}
