import { ContentChild, Directive, ElementRef, ViewContainerRef } from "@angular/core";
import { DropdownNotClosableZoneDirective } from "@app/shared/layout/dropdown/dropdown-not-closable-zone.directive";
import { DropdownDirective } from "@app/shared/layout/dropdown/dropdown.directive";
import { SelectComponent } from "@app/shared/components/select/select.component";
import { ButtonComponent } from "@app/shared/layout/forms/button/button.component";

@Directive({
  selector: '.collapse'
})
export class CollapsiblesDirective {
  public _width = 0;
  public li:HTMLElement = null;
  public cz: DropdownNotClosableZoneDirective;
  constructor(public vc: ViewContainerRef, public el:ElementRef) {}

  get dropdown() {
    return this._dropdown || (this._select ? this._select.dropdown : null);
  }

  @ContentChild(DropdownDirective, { static: true })
  public _dropdown:DropdownDirective = null;

  @ContentChild(SelectComponent, { static: true })
  public _select:SelectComponent = null;

  private oldThemes:string[] = [];
  public setTheme(val:string) {
    if(this._select && val != this._select.theme) {
      this.oldThemes.unshift(this._select.theme);
      this._select.setTheme(val);
    } else if(this.el) {
      this.el.nativeElement.classList.add(val + '-bg');
      this.oldThemes.unshift(val);
    }
  }
  public resetTheme() {
    if(this._select && this.oldThemes.length > 0) {
      this._select.setTheme(this.oldThemes.shift());
    } else if(this.el && this.oldThemes.length > 0) {
      this.el.nativeElement.classList.remove(this.oldThemes.shift() + '-bg');
    }
  }

}
