import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FullStoryService } from '@app/core/services/full-story.service';
import { ToastService } from '@app/core/services/toast.service';
import { HttpClientModule } from '@angular/common/http';
import { AuthService } from '@app/core/services/auth.service';
import { ValidatorService } from '@app/core/services/validator.service';
import { PaymentService } from '@app/core/services/payment.service';
import { NotificationService } from '@app/core/services/notification.service';
import { SocketModule } from '@app/core/socket/socket.module';
import { PreloaderService } from '@app/core/services/preloader.service';
import { SidebarService } from '@app/core/services/sidebar.service';
import { PopupUpdateService } from '@app/core/services/popup-update.service';
import { AppUpdateModule } from '@app/client/modals/app-update/app-update.module';
import { VersionCheckService } from '@app/core/services/version-check.service';
import { IntercomService } from './services/intercom.service';
import { TrackingModule } from '@app/shared/tracking/tracking.module';
import { FeatureFlaggingService } from '@app/shared/services/feature-flagging/feature-flagging.service';
import { ScriptLoaderService } from '@app/core/services/scripts/script-loader.service';
import { ProfitWellService } from '@app/core/services/profit-well.service';
import { StoreModule } from './store.module';
import { PartnerStackService } from './services/partnerstack.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    SocketModule,
    StoreModule,
    AppUpdateModule,
    TrackingModule.forRoot(),
  ],
  providers: [
    ToastService,
    AuthService,
    IntercomService,
    PaymentService,
    FullStoryService,
    ValidatorService,
    NotificationService,
    PreloaderService,
    SidebarService,
    PopupUpdateService,
    VersionCheckService,
    ScriptLoaderService,
    FeatureFlaggingService,
    ProfitWellService,
    PartnerStackService,
  ],
})
export class CoreModule {
  /* make sure CoreModule is imported only by one NgModule the AppModule */
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import only in AppModule');
    }
  }
}
