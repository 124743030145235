import { Component, Input } from '@angular/core';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { Plan } from '@app/shared/data/plan/plan.models';

@Component({
  selector: 'st-plan-price',
  template: `
    <span class="price price-discounted" *ngIf="canBeApplied()">
      <span class="price-value"><span>$</span>{{ plan?.price | stDiscountedPrice:coupon | number:'1.0-2' }}</span>
      <span class="price-anual">{{!plan?.annual ? ' / MO' : ' / YR'}}</span>
    </span>

    <span class="price" *ngIf="!canBeApplied()">
      <span class="price-value"><span>$</span>{{ plan?.price | number:'1.0-2' }}</span>
      <span class="price-anual">{{!plan?.annual ? ' / MO' : ' / YR'}}</span>
    </span>
  `
})
export class PlanPriceComponent {
  @Input()
  plan: Plan;

  @Input()
  coupon: Coupon;

  canBeApplied() {
    // coupon object is not set
    if (!(this.coupon && this.coupon.code && this.coupon.valid)) {
      return false;
    }

    if (Boolean(this.coupon.plans)) {
      return this.coupon.plans.includes(this.plan.remote_id);
    }

    return true;
  }
}
