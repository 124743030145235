import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";

@Injectable()
export class ProductToolsActions {


  static readonly TYPES = {
    FIND          : '[PRODUCT_TOOLS]FIND',
    FIND_SUCCEEDED: '[PRODUCT_TOOLS]FIND_SUCCEEDED',
    FIND_FAILED   : '[PRODUCT_TOOLS]FIND_FAILED',

    CHECK_RANK          : '[PRODUCT_TOOLS]CHECK_RANK',
    CHECK_RANK_SUCCEEDED: '[PRODUCT_TOOLS]CHECK_RANK_SUCCEEDED',
    CHECK_RANK_FAILED   : '[PRODUCT_TOOLS]CHECK_RANK_FAILED',
  };

  find(filters): StAction {
    return {
      type: ProductToolsActions.TYPES.FIND,
      data: filters
    }
  }

  findSucceeded(data): StAction {
    return {
      type: ProductToolsActions.TYPES.FIND_SUCCEEDED,
      data: data
    }
  }

  findFailed(error: any): StAction {
    return {
      type: ProductToolsActions.TYPES.FIND_FAILED,
      data: error
    }
  }

  checkRank(filters): StAction {
    return {
      type: ProductToolsActions.TYPES.CHECK_RANK,
      data: filters
    }
  }

  checkRankSucceeded(data): StAction {
    return {
      type: ProductToolsActions.TYPES.CHECK_RANK_SUCCEEDED,
      data: data
    }
  }

  checkRankFailed(error: any): StAction {
    return {
      type: ProductToolsActions.TYPES.CHECK_RANK_FAILED,
      data: error
    }
  }
}
