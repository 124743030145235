import { Injectable } from "@angular/core";
import { User } from "../data/user/user.models";
import * as Sentry from "@sentry/angular";

@Injectable()
export class SentryTrackingService {
  public identifyUser(user: User) {
    Sentry.setUser({
      id: ((user && user.id) || 0).toString(),
      ip_address: "{{auto}}",
    });
    this.setCredentialId(user.active_credential);
  }

  public setCredentialId(credentialId?: number) {
    Sentry.setTag("credentialId", credentialId || "none");
  }
}
