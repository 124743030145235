<st-modal [showInstructions]="false" [enableMinimize]="true" [onCloseInterceptor]="onClose.bind(this)">
    <div modal-header>
        <st-breadcrumb [value]="translateBreadcrumbs"></st-breadcrumb>
    </div>
    <div modal-body class="body">
        <div class="horizontal-layout xs-vertical-layout">
          <div class="tab">
            <st-browse-products
              [productSelectLimit]="asinLimit"
              (productsSelected)="selectedProducts = $event"
            ></st-browse-products>
        </div>
        </div>
    </div>
    <div modal-footer>
        <div class="buttons horizontal-layout">
            <div class="button-group">
                <div class="button-with-error-msg">
                    <button class="button interface-component abort" (click)="abort()" i18n>Abort</button>
<!--                    <button *ngIf="!(productsAreSet$ | async)" [disabled]="!keywordSearchText.trim().length" class="button interface-component" (click)="findProducts()" i18n>Search</button>-->
                    <st-button *ngIf="selectedProducts.length > 0" class="confirm-button" buttonClass="blue-button" (click)="confirm()" i18n>Confirm Selection</st-button>
                </div>
            </div>
        </div>
    </div>

    <div class="loading" *ngIf="loading$ | async" modal-overlay>
        <div class="content">
            <div i18n>
                Loading...
            </div>
        </div>
    </div>

    <div class="loading" *ngIf="searchInProgress$ | async" modal-overlay>
        <div class="content lines-3">
            <div>
                <span i18n>Loading...</span><br>
                <span i18n>Searching for suggested products</span><br>
                <span i18n>This may take a few moments. Please wait...</span>
            </div>
        </div>
        <div class="buttons">
            <st-button (click)="abort()" icon="cancel" i18n>Abort</st-button>
        </div>
    </div>
</st-modal>
