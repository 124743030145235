
import {filter, tap,  take ,  switchMap ,  startWith ,  takeUntil, share } from 'rxjs/operators';
import {
  AfterContentChecked,
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ContentChildren,
  ElementRef,
  EventEmitter,
  forwardRef,
  Input,
  NgZone, OnDestroy,
  OnInit, Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { LabelTemplateDirective } from '@app/shared/components/select/directives/label-template.directive';
import {
  OPTION_PARENT_COMPONENT, OptionComponent,
  OptionSelectionChangeEvent,
} from '@app/shared/components/select/option.component';
import { ValueAccessorBase } from '@app/shared/layout/forms/lib/base-value-accessor';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { defer ,  merge ,  Subject } from 'rxjs';
import { SelectionModel } from '@app/shared/components/select/selection-model';
import { DropdownDirective } from '@app/shared/layout/dropdown/dropdown.directive';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector       : 'st-select',
  templateUrl    : './select.component.html',
  styleUrls      : ['./select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers      : [
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi      : true,
    },
    { provide: OPTION_PARENT_COMPONENT, useExisting: SelectComponent },
  ],
})
export class SelectComponent extends ValueAccessorBase<any> implements OnInit, OnDestroy, AfterViewInit, AfterContentInit, AfterContentChecked {
  private _multiple: boolean = false;
  private _placeholder: string;
  private _selectionModel: SelectionModel<OptionComponent>;
  private _destroy: Subject<void> = new Subject<void>();

  @Input() public height = 320;
  @Input() public allSelectedPlaceholder: string = this.i18n('All Selected');
  @Input() public search = false;

  @Output() public searchTextChanged = new EventEmitter();

  // Template for button
  @ContentChild(LabelTemplateDirective, { read: TemplateRef, static: true })
  labelTemplate: TemplateRef<LabelTemplateDirective>;

  @ContentChildren(OptionComponent, { descendants: true })
  stOptions: QueryList<OptionComponent>;

  @ViewChild(DropdownDirective, { static: true })
  dropdown: DropdownDirective;

  @ViewChild('options', { read: ElementRef, static: true })
  optionsElement: ElementRef;

  @Input()
  innerHtmlLabel = false;

  @Input()
  loading = false;

  @Input()
  showCheckUncheckButtons = false;

  @Input()
  theme: string;

  /** Combined stream of all of the child options' change events. */
  optionOnSelect = defer(() => {
    if (this.stOptions) {
      return merge(...this.stOptions.map(option => option.onSelect));
    }

    return this._ngZone.onStable
      .asObservable()
      .pipe(take(1), switchMap(() => this.optionOnSelect));
  });

  /** Placeholder to be shown if no value has been selected. */
  @Input()
  set placeholder(value: string) {
    this._placeholder = value;
  }

  get placeholder() {
    return this._placeholder || this.i18n('Select');
  }

  @Input()
  set multiple(value: boolean) {
    this._multiple = value != null && `${value}` !== 'false'; // force set boolean value
  }

  get multiple() {
    return this._multiple;
  }

  @Input()
  disabled: boolean = false;

  setDisabledState(value: boolean) {
    this.disabled = value != null && `${value}` !== 'false'; // force set boolean value
    this.cd.markForCheck();
  }

  get selectedRaw() {
    if (this._selectionModel.isEmpty()) {
      return [];
    }
    return this._selectionModel.selected;
  }

  get selected() {
    if (this._selectionModel.isEmpty()) {
      return null;
    }

    if (this._multiple) {
      if (this._selectionModel.length === this.stOptions.length) {
        return this.allSelectedPlaceholder;
      }

      const values = this._selectionModel.selected
        .slice(0, 4)
        .reduce((acc, selected, index) =>
            [...acc, index === 3 ? '...' : selected.value],
          []);

      return values.slice(0, 4).join(', ');
    }

    return this._selectionModel.selected[0].value;
  }

  get selectedInner() {
    if (this._selectionModel.isEmpty()) {
      return false;
    }

    return this._selectionModel.selected[0].elementRef.nativeElement.innerText;
  }

  get selectedInputText() {
    if (this._selectionModel.isEmpty()) {
      return '';
    }

    return this._selectionModel.selected[0].elementRef.nativeElement.innerText;
  }

  get anchorRight(): boolean {
    return this.elRef.nativeElement.getBoundingClientRect().left + this.optionsElement.nativeElement.offsetWidth > window.innerWidth;
  }

  public setTheme(val: string) {
    this.theme = val;
    this.propagateTheme();
    this.changeDetectorRef.markForCheck();
  }

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private _ngZone: NgZone,
              private elRef: ElementRef,
              private i18n: I18n) {
    super(changeDetectorRef);
  }

  ngAfterContentChecked(): void {
    this.searchTextChanged.subscribe(_ => {
      this.dropdown.dropdownOpen.open();
      if (!this._selectionModel.isEmpty()) {
        this.deselectAll();
        this.searchTextChanged.next('');
      }
    });
  }

  ngAfterContentInit(): void {
    this.stOptions.changes.pipe(
      startWith(null),
      takeUntil(this._destroy),
    ).subscribe(() => {
      this.propagateTheme();
      this._initOptions();
      this._initializeSelection();
    });
  }

  private _initializeSelection(): void {
    Promise.resolve().then(() => {
      this._setSelectionByValue(this.value, true);
    });
  }

  /**
   * Sets the selected option based on a value. If no option can be
   * found with the designated value, the select trigger is cleared.
   */
  private _setSelectionByValue(value: any | any[], internal = false): void {
    if (this.multiple && value) {
      if (!Array.isArray(value)) {
      }

      value.forEach((currentValue: any) => this._selectValue(currentValue, internal));
    } else {
      this._clearSelection();

      this._selectValue(value, internal);

      // TODO: Shift focus to the active item.
    }

    this.markForCheck();
  }

  private _selectValue(currentValue, internal = false): OptionComponent | undefined {
    // console.log("currentValue", currentValue);

    if (this.stOptions === undefined) {
      return null;
    }

    const selectedOption = this.stOptions.find((option) => {
      return option.value !== null && option.value === currentValue;
    });

    if (selectedOption) {
      selectedOption._selectViaInteraction(internal, null);
    }

    return selectedOption;
  }

  selectAll() {
    this._clearSelection();
    const selectedOption = this.stOptions.filter((option) => {
      return option.value !== null;
    });

    selectedOption.forEach((so) => so._selectViaInteraction());

    return selectedOption;
  }

  deselectAll() {
    this._clearSelection();
    this._setValue();
    this.markForCheck();
  }

  /**
   * Subscribe to optionOnSelect event to handle selected event!
   */
  private _initOptions() {
    this._selectionModel = new SelectionModel<OptionComponent>(this.multiple, undefined);

    this.optionOnSelect.pipe(
      takeUntil(merge(this._destroy, this.stOptions.changes)),
      share(),
    ).subscribe((event: OptionSelectionChangeEvent) => {
      if (!event.internal) {
        this.onSelect(event.target);
      } else {
        this.onInternalSelect(event.target);
      }
    });
  }

  private propagateTheme() {
    this.stOptions.toArray().forEach((oc: OptionComponent) => {
      if (!oc.theme) {
        oc.theme = this.theme;
      }
    });
  }


  ngOnDestroy() {
    this._destroy.next();
    this._destroy.complete();
  }

  ngOnInit() {
    this._selectionModel = new SelectionModel<OptionComponent>(this.multiple, undefined);
    this.innerValueChange.pipe(
      tap(this._clearSelection.bind(this)),
      filter(v => v !== null && v !== undefined),
      takeUntil(this._destroy),)
      .subscribe((v) => {
        return this._setSelectionByValue(v);
      });
  }

  ngAfterViewInit() {
  }

  onInternalSelect(option: OptionComponent) {
    if (this.multiple) {
      // toggle selected!
      if (option.isSelected) {
        this._selectionModel.select(option);
      } else {
        this._selectionModel.deselect(option);
      }

    } else {
      // set selected option
      this._selectionModel.select(option);
    }

    // update value
    this._setValue();
    this.markForCheck();
  }

  onSelect(option: OptionComponent) {
    if (this.multiple) {
      // toggle selected!
      const wasSelected = this._selectionModel.toggle(option);
      wasSelected
        ? option.deselect()
        : option.select();

    } else {
      // clear selection
      this._clearSelection();
      // set selected option
      this._selectionModel.select(option);
    }

    // update value
    this._setValue();
    this.markForCheck();
  }

  _setValue() {
    const values = this._selectionModel.selected.map((option) => {
      return option.value;
    });
    if (this.multiple) {
      this.value = values;
    } else {
      this.value = values[0];
    }
  }

  private markForCheck() {
    if (!(<any>this.changeDetectorRef).destroyed) {
      this.changeDetectorRef.markForCheck();
    }
  }

  private _clearSelection() {
    const selected = this._selectionModel.selected;
    this._selectionModel.clear();

    selected.forEach((option: OptionComponent) => {
      option.deselect();
    });

    this._selectionModel.clear();
  }
}
