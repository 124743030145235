import { Injectable } from '@angular/core';
import { SortByEnum } from '@app/client/v2/product-manager/product-manager-product-list/product-manager-product-list.component';
import { Action } from 'redux';
import { StAction } from '../st-action';
import { IReviewRequestGraphResponse, IReviewRequestOverride, IReviewRequestProductsResponse, IReviewRequestSettings } from './review-request.models';

@Injectable()
export class RequestReviewActions {
  static readonly TYPES = {
    LOAD_SETTINGS: '[REQUEST_REVIEW]LOAD_SETTINGS',
    SETTINGS_LOADED: '[REQUEST_REVIEW]SETTINGS_LOADED',
    SETTINGS_LOADED_ERROR: '[REQUEST_REVIEW]SETTINGS_LOADED_ERROR',
    SETTINGS_UPDATED: '[REQUEST_REVIEW]SETTINGS_UPDATED',
    RESET_SETTINGS_UPDATED: '[REQUEST_REVIEW]RESET_SETTINGS_UPDATED',
    UPDATE_SETTINGS: '[REQUEST_REVIEW]UPDATE_SETTINGS',
    UPDATE_SETTINGS_SUCCESS: '[REQUEST_REVIEW]UPDATE_SETTINGS_SUCCESS',
    UPDATE_SETTINGS_ERROR: '[REQUEST_REVIEW]UPDATE_SETTINGS_ERROR',
    GET_PRODUCTS: '[REQUEST_REVIEW]GET_PRODUCTS',
    GET_PRODUCTS_SUCCESS: '[REQUEST_REVIEW]GET_PRODUCTS_SUCCESS',
    GET_PRODUCTS_ERROR: '[REQUEST_REVIEW]GET_PRODUCTS_ERROR',

    // Graphs
    GET_SENT_REQUESTS_GRAPH: '[REQUEST_REVIEW]GET_SENT_REQUESTS_GRAPH',
    GET_SENT_REQUESTS_GRAPH_SUCCESS: '[REQUEST_REVIEW]GET_SENT_REQUESTS_GRAPH_SUCCESS',
    GET_SENT_REQUESTS_GRAPH_ERROR: '[REQUEST_REVIEW]GET_SENT_REQUESTS_GRAPH_ERROR',
    GET_REVIEW_GROWTH_GRAPH: '[REQUEST_REVIEW]GET_REVIEW_GROWTH_GRAPH',
    GET_REVIEW_GROWTH_GRAPH_SUCCESS: '[REQUEST_REVIEW]GET_REVIEW_GROWTH_GRAPH_SUCCESS',
    GET_REVIEW_GROWTH_GRAPH_ERROR: '[REQUEST_REVIEW]GET_REVIEW_GROWTH_GRAPH_ERROR',
  }

  loadSettings(): Action {
    return {
      type: RequestReviewActions.TYPES.LOAD_SETTINGS,
    };
  }

  settingsLoaded(data: IReviewRequestSettings): StAction {
    return {
      type: RequestReviewActions.TYPES.SETTINGS_LOADED,
      data: data
    };
  }

  settingsUpdated(): Action {
    return {
      type: RequestReviewActions.TYPES.SETTINGS_UPDATED,
    }
  }

  resetSettingsUpdated(): Action {
    return {
      type: RequestReviewActions.TYPES.RESET_SETTINGS_UPDATED,
    }
  }

  settingsLoadError(error: any): StAction {
    return {
      type: RequestReviewActions.TYPES.SETTINGS_LOADED_ERROR,
      data: error,
    }
  }

  updateSettings(data: { settings: IReviewRequestSettings, overrides: { [key: string]: IReviewRequestOverride } }): StAction {
    const {
      settings,
      overrides,
    } = data;

    return {
      type: RequestReviewActions.TYPES.UPDATE_SETTINGS,
      data: {
        settings,
        overrides,
      }
    };
  }

  updateSettingsSuccess(data: IReviewRequestSettings): StAction {
    return {
      type: RequestReviewActions.TYPES.UPDATE_SETTINGS_SUCCESS,
      data,
    };
  }

  updateSettingsError(error: any): StAction {
    return {
      type: RequestReviewActions.TYPES.UPDATE_SETTINGS_ERROR,
      data: error,
    };
  }

  getProducts(page: number = 1, perPage: number = 20, sort: SortByEnum, term?: string): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_PRODUCTS,
      data: {
        page,
        perPage,
        sort,
        term,
      }
    };
  }

  reviewRequestProductsLoaded(data: IReviewRequestProductsResponse): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_PRODUCTS_SUCCESS,
      data,
    }
  }

  reviewRequestProductsError(error: any): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_PRODUCTS_ERROR,
      data: error,
    };
  }

  getSentRequestGraph(time: 1|7|14|30|12 = 7, granularity: 'day'|'month' = 'day'): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_SENT_REQUESTS_GRAPH,
      data: {
        time,
        granularity
      }
    }
  }

  getSentRequestGraphSuccess(graphData: IReviewRequestGraphResponse[]): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_SENT_REQUESTS_GRAPH_SUCCESS,
      data: {
        graphData,
      }
    }
  }

  getSentRequestGraphError(error: any): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_SENT_REQUESTS_GRAPH_ERROR,
      data: error,
    };
  }

  getReviewGrowthGraph(time: 1|7|14|30 = 7): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_REVIEW_GROWTH_GRAPH,
      data: {
        time,
      }
    }
  }

  getReviewGrowthGraphSuccess(graphData: IReviewRequestGraphResponse[]): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_REVIEW_GROWTH_GRAPH_SUCCESS,
      data: {
        graphData,
      }
    }
  }

  getReviewGrowthGraphError(error: any): StAction {
    return {
      type: RequestReviewActions.TYPES.GET_REVIEW_GROWTH_GRAPH_ERROR,
      data: error,
    };
  }
}
