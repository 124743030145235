import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ScriptLoaderService } from './scripts/script-loader.service';
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { throwError } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

interface GrowSumoData {
  name?: string;
  email?: string;
  customer_key?: string;
  partner_key?: string;
}

interface GrowSumoModule {

  data?: GrowSumoData;
  _initialize(key: string);
}

@Injectable()
export class PartnerStackService {

  constructor(private scriptLoaderService: ScriptLoaderService) {
  }

  public growsumo?: GrowSumoModule = null;

  public partnerData$: Observable<GrowSumoData | null> = throwError('PartnerStackService not initialized');

  public init() {
    if (environment.PARTNER_STACK_KEY) {
      const dataSubject = new BehaviorSubject<GrowSumoData | null>(null);
      this.scriptLoaderService.load('partner-stack-script').then(() => {
        this.growsumo = window['growsumo'] as GrowSumoModule;
        this.growsumo._initialize(environment.PARTNER_STACK_KEY);

        setTimeout(() => {
          dataSubject.next(this.growsumo.data);
        }, 1000);
      }).catch(() => {
        dataSubject.error('Failed to load growsumo script');
        dataSubject.complete();
      });
      this.partnerData$ = dataSubject.asObservable();
    }
  }

  public async getData() {
    // get the latest data from growsumo
    if (this.growsumo) {
      this.growsumo._initialize(environment.PARTNER_STACK_KEY);
      return this.growsumo.data;
    }
  }
}
