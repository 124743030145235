<div class="product-list__table">
  <div class="product-list__table-header">
    <div class="product-list__table-header-column product-list__table-header-column--name" (click)="sortBy(sortByEnum.TITLE)" i18n>Product name <div class="sort-icon"></div></div>
    <div class="product-list__table-header-column product-list__table-header-column--asin" i18n>ASIN</div>
    <div class="product-list__table-header-column product-list__table-header-column--sales-rank product-list__column--center" (click)="sortBy(sortByEnum.RANK)" i18n>Sales Rank <div class="sort-icon"></div></div>
    <div class="product-list__table-header-column product-list__table-header-column--keywords product-list__column--center" i18n>Keywords </div>
    <div class="product-list__table-header-column product-list__table-header-column--price" (click)="sortBy(sortByEnum.PRICE)" i18n>Price <div class="sort-icon"></div></div>
  </div>

  <div class="loader" *ngIf="loadingProducts">
    <st-loader [lines]="5"></st-loader>
  </div>

  <div class="product-list__table-products" *ngIf="!loadingProducts" [style.maxHeight.px]="maxTableHeight">
    <div class="product-list__table-product product-list__table-product-none"
      [style.zIndex]="products.length - i"
      [class.product-list__table-product--checked]="!selectedProductAsin"
      *ngIf="showNoneOption">
      <div class="product-list__table-product-column product-list__column--center product-list__table-product-column--select" (click)="selectNone()">
        <st-radio-button-v2 [checked]="!selectedProductAsin"></st-radio-button-v2>
      </div>
      <span i18n>None <span class="none-text">{{noneText}}</span></span>
    </div>
    <div class="product-list__table-product"
      [style.zIndex]="products.length - i"
      [class.product-list__table-product--checked]="product.checked"
      *ngFor="let product of products; let i = index">
      <div class="product-list__table-product-column product-list__column--center product-list__table-product-column--select" (click)="toggleProductDetails(product, $event)">
        <st-radio-button-v2 [checked]="product.checked"></st-radio-button-v2>
      </div>
      <div class="product-list__table-product-column product-list__table-product-column--name" (click)="toggleProductDetails(product, $event)">
        <div class="product-list__missing-product-label" *ngIf="product.missing_at">
          <st-icon
            icon="warning-yellow"
            i18n-stTooltip stTooltip="This product was not found during the syncing with Amazon Seller Central."
            position="right"></st-icon>
        </div>
        <img [src]="product.image_url" [alt]="product.title" class="product-image" onerror="this.src='/assets/img/placeholder.svg'" (click)="toggleProductDetails(product, $event)">
        <span class="product-title">{{product.short_title}}</span>
        <a (click)="$event.stopPropagation();" class="product-amazon-link" [href]="getAmazonLink(product)" target="_blank">
          <st-icon icon="amazon"></st-icon>
        </a>
      </div>
      <div class="product-list__table-product-column product-list__table-product-column--asin" (click)="toggleProductDetails(product, $event)">{{product.asin}}</div>
      <div class="product-list__table-product-column product-list__table-product-column--sales-rank" (click)="toggleProductDetails(product, $event)">
        <div class="sales-rank">
          <div class="sales-rank__value">{{(product.sales_rank | number: '1.0-0') || '-'}}</div>
          <div class="sales-rank__title" i18n>Current</div>
        </div>
        <div class="sales-rank sales-rank--change" [class.sales-rank--change-up]="product.sales_rank_change > 0" [class.sales-rank--change-down]="product.sales_rank_change < 0">
          <div class="sales-rank__value">{{(product.sales_rank_change | number: '1.0-0') || '-'}}</div>
          <div class="sales-rank__title" i18n>Change</div>
        </div>
      </div>
      <div class="product-list__table-product-column product-list__column--center product-list__table-product-column--keywords" (click)="toggleProductDetails(product, $event)">{{product.keywords_count}}</div>
      <div class="product-list__table-product-column product-list__table-product-column--price" (click)="toggleProductDetails(product, $event)">
        <ng-container *ngIf="product.min_price?.amount === product.max_price?.amount">
          {{product.min_price?.amount | currency: product.min_price?.currency_code}}
        </ng-container>
        <ng-container *ngIf="product.min_price?.amount !== product.max_price?.amount">
          {{product.min_price?.amount | currency: product.min_price?.currency_code}} - {{product.max_price?.amount | currency: product.max_price?.currency_code}}
        </ng-container>
      </div>
      <div class="product-list__product-details" *ngIf="product.detailsOpened && showVariations" (click)="preventDefault($event)">
        <div class="product-list__product-details-section" *ngIf="productVariations?.length > 0 && !loadingProductVariations">
          <div class="product-list__product-details-section-header" i18n>Product Variations</div>
          <div class="product-list__table">
            <div class="product-list__table-header">
              <div (click)="toggleAllVariations()" class="product-list__table-header-column product-list__table-header-column--select product-list__column--center">
                <st-checkbox-v2  *ngIf="!singleVariationSelection" color="red" [checked]="allVariationsChecked"></st-checkbox-v2>
              </div>
              <div class="product-list__table-header-column product-list__table-header-column--name" i18n>Product Name</div>
              <div class="product-list__table-header-column product-list__table-header-column--asin" i18n>ASIN</div>
            </div>

            <div class="product-list__table-products">
              <div class="product-list__table-product"
                [style.zIndex]="productVariations?.length - i"
                (click)="variationChanged(variation)"
                *ngFor="let variation of productVariations; let i = index">
                <div class="product-list__table-product-column product-list__column--center product-list__table-product-column--select">
                  <st-checkbox-v2 *ngIf="!singleVariationSelection" color="red" [checked]="variation.checked"></st-checkbox-v2>
                  <st-radio-button-v2 *ngIf="singleVariationSelection" color="red" [checked]="variation.checked"></st-radio-button-v2>
                </div>
                <div class="product-list__table-product-column product-list__table-product-column--name">
                  <img [src]="product.image_url" [alt]="variation.title" class="product-image" onerror="this.src='/assets/img/placeholder.svg'">
                  <span class="product-title">{{variation.title}}</span>
                </div>

                <div class="product-list__table-product-column product-list__table-product-column--asin">{{variation.asin}}</div>
              </div>
            </div>
          </div>

          <div class="product-list__product-details-footer">
            <div class="product-list__product-details-footer-column"></div>
            <div class="product-list__product-details-footer-column">
              <st-pagination
                [currentPage]="variationCurrentPage"
                [pages]="variationPagesArray"
                (goTofirstPage)="variationGoToFirstPage(product)"
                (goToLastPage)="variationGoToLastPage(product)"
                (goToNextPage)="variationNextPage(product)"
                (goToPreviousPage)="variationPreviousPage(product)"
                (changePage)="variationChangePage($event, product)"></st-pagination>
            </div>
            <div class="product-list__product-details-footer-column product-list__product-details-footer-column--pagination-info">
              <st-pagination-info
                [text]="currentDisplayedVariationProducts"
                [disabled]="loadingProductVariations"
                [options]="perPageOptions"
                (onPageChange)="variationPageChange($event, product)"
                [preSelectedOption]="preSelectedOption"></st-pagination-info>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
