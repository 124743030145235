import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RadioComponent } from '@app/shared/layout/forms/radio/radio.component';
import { CommonModule } from '@angular/common';
import { StFormsModule } from '@app/shared/layout/forms/forms.module';
import { NoticesListComponent } from '@app/shared/layout/notices-list/notices-list.component';
import { DropdownModule } from '@app/shared/layout/dropdown/dropdown.module';
import { ToastsModule } from '@app/shared/layout/toasts/toasts.module';
import { SvgIconModule } from '@app/shared/layout/icon/svg-icon.module';
import { NoticeActions } from '@app/shared/data/notice/notice.actions';
import { HScrollComponent } from '@app/shared/layout/hscroll/hscroll.component';
import { OffClickDirective } from '@app/shared/directives/off-click.directive';
import { AutofocusDirective } from '@app/shared/directives/autofocus.directive';
import { BreadcrumbModule } from '@app/shared/layout/breadcrumb/breadcrumb.module';
import { ButtonsModule } from '@app/shared/layout/forms/button/buttons.module';
import { CollapsiblesDirective } from '@app/shared/layout/collapsible/collapsibles.directive';
import { CollapsibleComponent } from '@app/shared/layout/collapsible/collapsible.component';
import { ProgressLoaderComponent } from '@app/shared/layout/progress-loader/progress-loader.component';
import { NoticesService } from '@app/shared/layout/notices-list/notices.service';
import { LoaderModule } from '@app/shared/layout/loader/loader.module';
import { SidemenuOffClickDirective } from '../directives/sidemenu-off-click.directive';

const COMPONENTS = [
  NoticesListComponent,
  RadioComponent,
  HScrollComponent,
  CollapsibleComponent,
  ProgressLoaderComponent,
];
const DIRECTIVES = [
  OffClickDirective,
  SidemenuOffClickDirective,
  AutofocusDirective,
  CollapsiblesDirective,
];

@NgModule({
  imports     : [
    CommonModule,
    RouterModule,
    SvgIconModule,
    BreadcrumbModule,
    ButtonsModule,
    SvgIconModule,
    DropdownModule,
    LoaderModule,
  ],
  declarations: [
    ...COMPONENTS, ...DIRECTIVES,
  ],
  exports     : [
    StFormsModule,
    DropdownModule,
    ToastsModule,
    SvgIconModule,
    BreadcrumbModule,
    ButtonsModule,
    LoaderModule,
    ...COMPONENTS, ...DIRECTIVES,
  ],
  providers   : [
    NoticeActions,
    NoticesService,
  ],
})
export class LayoutModule {
}
