import { Directive, EventEmitter, HostListener, Output } from '@angular/core';
import { topMenuItemClassName } from '../layout/sidebar-new/client-sidebar/client-sidebar.component';

@Directive({
  selector: '[stSidemenuOffClick]'
})
export class SidemenuOffClickDirective {
  private itemClassName = topMenuItemClassName;
  @Output() clickedOutsideMenuItem = new EventEmitter<void>();

  constructor() { }

  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    const clickedOutside = this.hasSomeParentTheClass(target, this.itemClassName);
    if (!clickedOutside) {
      this.clickedOutsideMenuItem.emit();
    }
  }

  private hasSomeParentTheClass(element, classname) {
    if (element.className && this.isFunction(element.className.split) && element.className.split(' ').indexOf(classname) >= 0) return true;
    return element.parentNode && this.hasSomeParentTheClass(element.parentNode, classname);
  }

  private isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
}
