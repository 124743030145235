import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { LS } from "@app/shared/services/local-storage.service";

const tasksFromStorage = () => {
  try {
    const tasks = LS.forActiveCredential().getTasks();
    if (tasks !== null) {
      let returnTasks = {};

      for (let k in tasks.data) {
        if (tasks.data.hasOwnProperty(k) && (tasks.data[k].saved || tasks.data[k].step >= 15)) {
          returnTasks[k] = tasks.data[k]
        }
      }

      tasks.data = returnTasks;

      return tasks;
    }
  } catch (error) {
    console.log("UserReducer: LocalStorage error", error);
  }
  return { data: {}, wip: {} };
};

const INITIAL_STATE: AppState = tasksFromStorage();

export function taskReducers(reducers: ((state: AppState, action: StAction) => AppState)[]) {
  return (state: AppState = INITIAL_STATE, action: StAction) => {
    return reducers.reduce((state, reducer) => reducer(state, action), state);
  };
}
