
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/shared/data/base.api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Credential } from '@app/shared/data/credential/credential.models';
import { User } from '@app/shared/data/user/user.models';

@Injectable()
export class ProductToolsApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public find(params = {}): Observable<any> {
    return this.http.get(this.constructFullUrl('api/admin/tools/product-info'), { params: params }).pipe(
      map((data: any) => {
        return {
          credential: new Credential(data.credential),
          product: data.product,
          user: new User(data.user),
        };
    }));
  }

  public fireRankCheck(params = {}): Observable<any> {
    return this.http.get(this.constructFullUrl('api/admin/tools/rank-check'), { params: params })
  }
}
