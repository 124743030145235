import { Component, Input } from "@angular/core";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-number-with-star-item',
  templateUrl: './number-with-star.item.component.html',
  styleUrls: ['./number-with-star.item.component.scss']
})
export class NumberWithStarItemTableItemComponent {
  @Input() item: V2TableDataRecord;
}
