import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Credential } from '@app/shared/data/credential/credential.models';
import { Invitation } from '@app/shared/data/invitation/invitation.models';

@Injectable()
export class InvitationActions {

  static readonly TYPES = {
    FETCH_INVITATION        : '[INVITATION]GET_INVITATION',
    FETCH_INVITATION_SUCCESS: '[INVITATION]FETCH_INVITATION_SUCCESS',
    FETCH_INVITATION_FAILED : '[INVITATION]FETCH_INVITATION_FAILED',

    ACCEPT_INVITATION        : '[INVITATION]ACCEPT_INVITATION',
    ACCEPT_INVITATION_SUCCESS: '[INVITATION]ACCEPT_INVITATION_SUCCESS',
    ACCEPT_INVITATION_FAILED : '[INVITATION]ACCEPT_INVITATION_FAILED',
  };

  static fetchInvitation(invitation: Invitation): StAction {
    return {
      type: InvitationActions.TYPES.FETCH_INVITATION,
      data: invitation,
    };
  }

  static fetchInvitationSucceed(invitation: Invitation): StAction {
    return {
      type: InvitationActions.TYPES.FETCH_INVITATION_SUCCESS,
      data: invitation,
    };
  }

  static fetchInvitationFailed(error: any): StAction {
    return {
      type: InvitationActions.TYPES.FETCH_INVITATION_FAILED,
      data: error,
    };
  }

  static acceptInvitation(invitation: Invitation): StAction {
    return {
      type: InvitationActions.TYPES.ACCEPT_INVITATION,
      data: invitation,
    };
  }


  static acceptInvitationSucceed(invitation: Invitation): StAction {
    return {
      type: InvitationActions.TYPES.ACCEPT_INVITATION_SUCCESS,
      data: invitation,
    };
  }

  static acceptInvitationFailed(error: any): StAction {
    return {
      type: InvitationActions.TYPES.ACCEPT_INVITATION_FAILED,
      data: error,
    };
  }
}
