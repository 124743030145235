import { AppState } from '@app/shared/data/app-state.model';
import { Collection } from '@app/shared/data/base.models';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { StAction } from '@app/shared/data/st-action';
import { LS } from '@app/shared/services/local-storage.service';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { AlertGroup, AlertIssue, BEAlert, ProductAlerts } from '@app/shared/data/alert/alert.models';
import { AlertActions } from '@app/shared/data/alert/alert.actions';

const emptyAlerts: Collection<ProductAlerts> = new Collection<ProductAlerts>();
const emptyAlertGroup: AlertGroup = new AlertGroup();
emptyAlertGroup.be_alerts = BEAlert.collect<BEAlert>([]);

const INITIAL_STATE: AppState = {
  alerts: emptyAlerts,
  loading: false,
  modal_saving: false,
  acknowledging: false,
  errors: null,
  modal_alert: emptyAlertGroup,
  _filters: {},
  _be_pagination: {
    page : 1,
    sort : [],
    limit: LS.forActiveCredential().getPerPage('alerts') || 10
  }
};

export function alertReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type === PaginationActions.getPagActionTypes(ProductAlerts).LOAD_PAGE) {
    LS.forActiveCredential().setPerPage(action.data.pagination.perPage, 'alerts');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading: true,
    };
  }

  if (action.type === AlertActions.TYPES.SET_ALERTS) {
    return {
      ...state,
      alerts: action.data,
      loading: false,
      acknowledging: false,
    };
  }

  if (action.type === AlertActions.TYPES.RESET_ALERTS) {
    return {
      ...state,
      alerts: emptyAlerts,
      loading: true,
    };
  }

  if (action.type === AlertActions.TYPES.UPDATE_ALERTS) {
    return {
      ...state,
      alerts: state['alerts'].map((product: ProductAlerts) => {
        const updatedProduct = action.data.find(p => p.asin === product.asin);
        return updatedProduct ? new ProductAlerts(
          {
            ...product,
            ...updatedProduct
          }) : product;
      }),
      loading: false,
      acknowledging: false,
    };
  }

  if (action.type === AlertActions.TYPES.SAVE
    || action.type === AlertActions.TYPES.REMOVE
    || action.type === AlertActions.TYPES.LOAD_PRODUCTS_DATA
    || action.type === AlertActions.TYPES.LOAD_MODAL_DATA
    || action.type === AlertActions.TYPES.TEMPLATE_SAVE
    || action.type === AlertActions.TYPES.REMOVE_FOR_ASIN
    || action.type === AlertActions.TYPES.REMOVE_ALL) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === AlertActions.TYPES.TEMPLATE_SAVE_SUCCESS
    || action.type === AlertActions.TYPES.TEMPLATE_SAVE_FAILED) {
    return {
      ...state,
      loading: false,
    };
  }

  if (action.type === AlertActions.TYPES.SET_MODAL_DATA) {
    return {
      ...state,
      modal_alert: action.data.subscription,
      loading: false,
      errors: null,
    };
  }

  if (action.type === AlertActions.TYPES.CLEAR_MODAL_DATA) {
    return {
      ...state,
      modal_alert: emptyAlertGroup,
      loading: false,
      errors: null,
    };
  }

  if (action.type === AlertActions.TYPES.SET_MODAL_DATA_FAILED) {
    let err = null;

    if (action.data.error.status === 404) {
      err = 'Error when loading subscription. Please try to refresh page and try again.';
    }

    return {
      ...state,
      loading: false,
      errors: err,
    };
  }

  if (action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUE
    || action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUES_FOR_PRODUCT
    || action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUES) {
    return {
      ...state,
      acknowledging: true,
    };
  }

  if (action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUE_SUCCESS) {
    return {
      ...state,
      acknowledging: false,
      alerts: state['alerts'].map((p: ProductAlerts) =>
        p.asin === action.data.issue.asin
          ? new ProductAlerts({ ...p, _issues: p['_issues'].filter((issue: AlertIssue) => issue.id !== action.data.issue.id) })
          : p,
      )
    };
  }

  if (action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUE_FAILED
    || action.type === AlertActions.TYPES.ACKNOWLEDGE_ISSUES_FAILED) {
    return {
      ...state,
      acknowledging: false,
    };
  }

  return paginationReducer(ProductAlerts)(state, action);
}
