import { Injectable, Pipe, PipeTransform } from '@angular/core';
import {
  SortByKeywordEnum
} from '@app/client/v2/product-manager/product-manager-keyword-manager/product-manager-keyword-manager.component';
import { IProductManagerKeyword } from '../data/product-manager/product-manager.models';

@Pipe({
  name: 'stKeywordSortPipe'
})
@Injectable()
export class KeywordSortPipe implements PipeTransform {
  transform(keywords: IProductManagerKeyword[], currentSortBy: SortByKeywordEnum, currentSortDirection: 'ASC' | 'DESC'): IProductManagerKeyword[] {
    if (!keywords || keywords.length === 0) {
      return [];
    }

    return (keywords || []).sort((a: IProductManagerKeyword, b: IProductManagerKeyword) => {
      // Sort by number
      if ([
        SortByKeywordEnum.ORGANIC_RANK,
        SortByKeywordEnum.RANKED_RESULTS,
        SortByKeywordEnum.SFR,
        SortByKeywordEnum.VOLUME,
        SortByKeywordEnum.SALES,
        SortByKeywordEnum.SALES_RATE,
        SortByKeywordEnum.CLICK_DOMINANCE,
        SortByKeywordEnum.SALES_DOMINANCE,
        SortByKeywordEnum.MIN_RANK,
        SortByKeywordEnum.SEARCH_VOLUME,
        SortByKeywordEnum.NO_OF_COMPETITORS,
        SortByKeywordEnum.RANK_DENSITY,
        SortByKeywordEnum.KW_FOUND_REPORT_CARD,
        SortByKeywordEnum.KW_FOUND_BRAND_ANALYTICS,
        SortByKeywordEnum.SPONSORED_DENSTITY,
        SortByKeywordEnum.REPORT_CARD_DENSTITY,
        SortByKeywordEnum.IS_SPONSORED,
      ].includes(currentSortBy)) {
        const firstValue = Number(a[currentSortBy]) || -1;
        const secondValue = Number(b[currentSortBy]) || -1;
        // keywords with undefined
        // should be at the end of the array (have the highers weight when sorting)
        if (typeof a[currentSortBy] === 'undefined' || a[currentSortBy] === null) {
          return Number.MAX_SAFE_INTEGER;
        }

        return currentSortDirection === 'ASC' ? firstValue - secondValue : secondValue - firstValue;
      } else if ([SortByKeywordEnum.PRIORITY].includes(currentSortBy)) {
        const priorityMapper = {
          'VL': 0,
          'L': 1,
          'M': 2,
          'H': 3,
        };

        let newPriority1 = priorityMapper[a.priority];
        let newPriority2 = priorityMapper[b.priority];

        if (currentSortDirection === 'ASC') {
          newPriority1 *= -1;
          newPriority2 *= -1;
        }

        return newPriority1 - newPriority2;
      } else if ([SortByKeywordEnum.KEYWORD].includes(currentSortBy)) {
        return currentSortDirection === 'ASC' ?
          a.keyword.localeCompare(b.keyword) :
          b.keyword.localeCompare(a.keyword);
      } else if ([SortByKeywordEnum.NICHE_TITLE].includes(currentSortBy)) {
        return currentSortDirection === 'ASC' ?
          a['metrics'][currentSortBy]?.localeCompare(b['metrics'][currentSortBy]) :
          b['metrics'][currentSortBy]?.localeCompare(a['metrics'][currentSortBy]);
      } else if ([
        // oep
        SortByKeywordEnum.CLICK_SHARE,
        SortByKeywordEnum.CLICK_SHARE_T360,
        SortByKeywordEnum.SEARCH_CONVERSION_RATE,
        SortByKeywordEnum.SEARCH_VOLUME_CONVERSION_RATE_T360,
        SortByKeywordEnum.SEARCH_VOLUME_GROWTH_T360_YOY,
        SortByKeywordEnum.SEARCH_VOLUME_QOQ,
        SortByKeywordEnum.SEARCH_VOLUME_T360,
        SortByKeywordEnum.SEARCH_VOLUME_T90,
        SortByKeywordEnum.SEARCH_VOLUME_YOY,].includes(currentSortBy)) {
        a = a['metrics'];
        b = b['metrics'];
        const firstValue = Number(a[currentSortBy]) || -1;
        const secondValue = Number(b[currentSortBy]) || -1;
        // keywords with undefined
        // should be at the end of the array (have the highers weight when sorting)
        if (typeof a[currentSortBy] === 'undefined' || a[currentSortBy] === null) {
          return Number.MAX_SAFE_INTEGER;
        }

        return currentSortDirection === 'ASC' ? firstValue - secondValue : secondValue - firstValue;
      }
    });
  }
}
