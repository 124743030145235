import { Component, Input } from "@angular/core";

@Component({
  selector : 'st-dot-loader',
  templateUrl: './dot-loader.component.html',
  styleUrls: ['./dot-loader.component.scss']
})
export class DotLoaderComponent {
  @Input()
  size: string = "12px";
}
