import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { Coupon } from "@app/shared/data/coupon/coupon.models";

@Injectable()
export class CouponActions {
  static readonly TYPES = {
    LOAD_COUPONS          : '[COUPON]LOAD_COUPONS',
    LOAD_COUPONS_SUCCEEDED: '[COUPON]LOAD_COUPONS_SUCCEEDED',
    LOAD_COUPONS_FAILED   : '[COUPON]LOAD_COUPONS_FAILED',

    CREATE          : '[COUPON]CREATE',
    CREATE_SUCCEEDED: '[COUPON]CREATE_SUCCEEDED',
    CREATE_FAILED   : '[COUPON]CREATE_FAILED',

    REMOVE          : '[COUPON]REMOVE',
    REMOVE_SUCCEEDED: '[COUPON]REMOVE_SUCCEEDED',
    REMOVE_FAILED   : '[COUPON]REMOVE_FAILED',
  };

  loadCoupons(data = {}): StAction {
    return {
      type: CouponActions.TYPES.LOAD_COUPONS,
      data: data
    }
  }

  loadCouponsForFeature(feature: string): StAction {
    return this.loadCoupons({
      feature: feature
    });
  }

  loadCouponsSucceeded(coupons: Collection<Coupon>): StAction {
    return {
      type: CouponActions.TYPES.LOAD_COUPONS_SUCCEEDED,
      data: coupons
    }
  }

  loadCouponsFailed(error: any): StAction {
    return {
      type: CouponActions.TYPES.LOAD_COUPONS_FAILED,
      data: error
    }
  }

  create(code, type): StAction {
    return {
      type: CouponActions.TYPES.CREATE,
      data: {
        code: code,
        type: type,
      }
    }
  }

  createSucceeded(coupon: Coupon): StAction {
    return {
      type: CouponActions.TYPES.CREATE_SUCCEEDED,
      data: coupon
    }
  }

  createFailed(error): StAction {
    return {
      type: CouponActions.TYPES.CREATE_FAILED,
      data: error
    }
  }

  remove(croupon: Coupon): StAction {
    return {
      type: CouponActions.TYPES.REMOVE,
      data: croupon
    }
  }

  removeSucceeded(croupon: Coupon): StAction {
    return {
      type: CouponActions.TYPES.REMOVE_SUCCEEDED,
      data: croupon
    }
  }

  removeFailed(error): StAction {
    return {
      type: CouponActions.TYPES.REMOVE_FAILED,
      data: error
    }
  }
}
