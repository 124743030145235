import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { LastLaunchScheduleActions } from '@app/shared/data/last-launch-schedule/last-launch-schedule.actions';
import {
  LastLaunchSchedule,
  LastLaunchScheduleDate,
  LastLaunchScheduleKeyword, LastLaunchScheduleProduct
} from '@app/shared/data/last-launch-schedule/last-launch-schedule.models';

const INITIAL_STATE: AppState = {
  schedule        : new LastLaunchSchedule(),
  products        : LastLaunchScheduleProduct.collect<LastLaunchScheduleProduct>([]),
  available_dates : LastLaunchScheduleDate.collect<LastLaunchScheduleDate>([]),
  loading         : false,
  loading_dates   : false,
  loading_products: false,
  saving          : false,
  error           : null,
  product_error   : null,
};

export function lastLaunchScheduleReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === LastLaunchScheduleActions.TYPES.OBTAIN_AVAILABLE_DATES) {
    return {
      ...state,
      loading_dates: true,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.OBTAIN_AVAILABLE_DATES_SUCCEEDED) {
    return {
      ...state,
      available_dates: action.data.dates,
      loading_dates  : false,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PROMOTION) {
    return {
      ...state,
      error : null,
      saving: true,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PROMOTION_SUCCEEDED) {
    return {
      ...state,
      schedule: action.data.schedule,
      error   : null,
      saving  : false,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PROMOTION_FAILED) {
    return {
      ...state,
      error : action.data.error,
      saving: false,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.LOAD_PROMOTION_SUCCEEDED) {
    return {
      ...state,
      schedule: action.data.schedule,
      loading : false
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SEARCHING_FOR_PRODUCT) {
    return {
      ...state,
      loading_products: true
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SEARCHING_FOR_PRODUCT_SUCCEEDED) {
    return {
      ...state,
      products        : action.data.products,
      loading_products: false,
      product_error   : null,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SEARCHING_FOR_PRODUCT_FAILED) {
    return {
      loading_products: false,
      product_error   : action.data.error,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PRODUCT) {
    return {
      ...state,
      saving: true,
      error : null,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PRODUCT_SUCCEEDED) {
    return {
      ...state,
      schedule: action.data.schedule,
      saving  : false,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SET_PRODUCT) {
    return {
      ...state,
      schedule: state['schedule'].clone(LastLaunchSchedule, {
        product: action.data.product,
        set_moq: action.data.schedule.set_moq
      }),
      saving  : false,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PRODUCT_FAILED) {
    return {
      ...state,
      saving: false,
      error : action.data.error,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_UNITS) {
    return {
      ...state,
      saving: true,
      error : null,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_UNITS_SUCCEEDED) {
    return {
      ...state,
      schedule: action.data.schedule,
      saving  : false,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_UNITS_FAILED) {
    return {
      ...state,
      saving: false,
      error : action.data.error,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_COUPONS) {
    return {
      ...state,
      saving: true,
      error : null,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_COUPONS_SUCCEEDED) {
    return {
      ...state,
      schedule: action.data.schedule,
      saving  : false,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.SAVE_COUPONS_FAILED) {
    return {
      ...state,
      saving: false,
      error : action.data.error,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.PAY) {
    return {
      ...state,
      saving: true,
      error : null,
    };
  }

  if (action.type === LastLaunchScheduleActions.SOCKET_TYPES.STATUS_HAS_CHANGED) {
    return {
      ...state,
      error: null,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.PAY_SUCCEEDED) {
    return {
      ...state,
      schedule: action.data.schedule,
      saving  : false,
      error   : null,
    };
  }

  if (action.type === LastLaunchScheduleActions.TYPES.PAYMENT_FAILED_BECAUSE_SLOT_HAS_EXPIRED) {
    return {
      ...state,
      schedule: action.data.schedule,
      saving  : false,
      error   : action.data.error,
    }
  }

  if (action.type == LastLaunchScheduleActions.TYPES.PAY_FAILED) {
    let message = action.data.message;

    if (action.data.error.error && action.data.error.error.message) {
      message = action.data.error.error.message['units.max'] || message;
    }

    return {
      ...state,
      error : message,
      saving: false,
    };
  }

  return state;
}
