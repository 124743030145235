import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { WalletTransaction, EmailLog, TransactionStats } from '@app/shared/data/wallet-transactions/wallet-transactions.models';
import { WalletTransactionsActions } from '@app/shared/data/wallet-transactions/wallet-transactions.actions';
import { LS } from '@app/shared/services/local-storage.service';

export type TransactionFilters = {
  type: string,
  source: string,
  query: string,
  status: string,
};

interface WalletTransactionsState extends AppState {
  data: Collection<WalletTransaction>;
  logs: Collection<EmailLog>;
  loading: boolean;
  loading_logs: boolean;
  stats: TransactionStats;
  creating: boolean;
  exporting: boolean;
  sending_gift_card: boolean;
  error?: any;

  _filters: TransactionFilters;
  _be_pagination: {};
}

const INITIAL_STATE: WalletTransactionsState = {
  data    : new Collection([]),
  logs: new Collection([]),
  loading : false,
  loading_logs: false,
  stats : new TransactionStats(),
  creating: false,
  exporting: false,
  sending_gift_card: false,
  error: null,

  _filters: loadFilters(),
  _be_pagination: {
    page: 1,
    sort: [],
    limit: 20
  }
};

function loadFilters(): TransactionFilters {
  return <TransactionFilters>LS.forActiveUser().getTransactionFilters()
    || {
      type: '',
      source: '',
      query: '',
      status: '',
    }
}

export function walletTransactionsReducer(state: WalletTransactionsState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === PaginationActions.getPagActionTypes(WalletTransaction).LOAD_PAGE) {
    return {
      ...state,
      _be_pagination: {
        page: action.data.pagination.page,
        sort: action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading: true
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.TOP_UP) {
    return {
      ...state,
      error: null,
      creating: true,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.FILTER) {
    return {
      ...state,
      _filters: action.data,
      _be_pagination: {
        ...state['_be_pagination'],
        page: 1,
      },
      loading: true,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.TOP_UP_SUCCEEDED) {
    return {
      ...state,
      error: null,
      creating: false,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.TOP_UP_FAILED) {
    return {
      ...state,
      error: action.data.error.message,
      creating: false,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.RESEND) {
    return {
      ...state,
      data: state.data.map((transaction: WalletTransaction) => {
        if (transaction.id === action.data.transaction.id) {
          return transaction.clone(WalletTransaction, {
            _resending: true,
          });
        }

        return transaction;
      })
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.RESEND_SUCCEEDED || action.type === WalletTransactionsActions.TYPES.RESEND_FAILED) {
    return {
      ...state,
      data: state.data.map((transaction: WalletTransaction) => {
        if (transaction.id === action.data.transaction.id) {
          return transaction.clone(WalletTransaction, {
            _resending: false,
          });
        }

        return transaction;
      })
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.EXPORT) {
    return {
      ...state,
      exporting: true,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.EXPORT_FINISHED) {
    return {
      ...state,
      exporting: false,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.SEARCH) {
    return {
      ...state,
      _be_pagination: {
        ...state['_be_pagination'],
        page: 1,
      },
      loading: true,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.SEND_GIFT_CARD) {
    return {
      ...state,
      sending_gift_card: true,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.SEND_GIFT_CARD_SUCCEEDED || action.type === WalletTransactionsActions.TYPES.SEND_GIFT_CARD_FAILED) {
    return {
      ...state,
      sending_gift_card: false,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.MARK_AS_AVAILABLE) {
    return {
      ...state,
      data: state.data.map((transaction: WalletTransaction) => {
        if (transaction.id === action.data.transactionId) {
          return transaction.clone(WalletTransaction, {
            marking_as_available: true
          });
        }
        return transaction;
      })
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.MARK_AS_AVAILABLE_SUCCEEDED) {
    return {
      ...state,
      data: state.data.map((transaction: WalletTransaction) => {
        if (transaction.id === action.data.transactionId) {
          return transaction.clone(WalletTransaction, {
            is_available: true,
            marking_as_available: false
          });
        }
        return transaction;
      })
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.MARK_AS_AVAILABLE_FAILED) {
    return {
      ...state,
      data: state.data.map((transaction: WalletTransaction) => {
        if (transaction.id === action.data.transactionId) {
          return transaction.clone(WalletTransaction, {
            marking_as_available: false
          });
        }
        return transaction;
      })
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.GET_STATS) {
    return {
      ...state,
      stats: {
        ...state['stats'],
        loading: true,
      }
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.GET_STATS_SUCCEEDED) {
    return {
      ...state,
      stats: {
        ...action.data,
        loading: false,
      }
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.GET_STATS_FAILED) {
    return {
      ...state,
      stats: {
        ...state['stats'],
        loading: false,
      }
    };
  }


  if (action.type === WalletTransactionsActions.TYPES.LOAD_EMAIL_LOGS) {
    return {
      ...state,
      logs: new Collection(),
      loading_logs: true,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.LOAD_EMAIL_LOGS_SUCCEEDED) {
    return {
      ...state,
      logs: action.data['logs'],
      loading_logs: false,
    };
  }

  if (action.type === WalletTransactionsActions.TYPES.LOAD_EMAIL_LOGS_FAILED) {
    return {
      ...state,
      loading_logs: false,
    };
  }

  return paginationReducer(WalletTransaction)(state, action);
}
