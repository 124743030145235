<st-modal #modal="modal" [showInstructions]="false" size="medium" [showOnlyBody]="true">
  <div modal-body class="content">
    <div class="modal-header">
      <h2 i18n>Save Filter as a View</h2>
    <span class="close-icon" (click)="cancel()">
      <st-icon icon="cross-v2"></st-icon>
    </span>
  </div>

    <div class="filter-name-wrapper">
      <div class="label" i18n>View Name</div>
      <input type="text" class="custom-input" placeholder="E.G. FAVORITE CUSTOMERS" i18n-placeholder [(ngModel)]="viewName" />
    </div>

    <div class="filters-header" i18n>Filters applied:</div>
    <div class="filters-wrapper">
      <div class="filter" *ngFor="let filter of filters">
        <div class="filter-column filter-column--title">
          <div class="filter-column-title" i18n>Column:</div>
          <div class="filter-column-value">{{filter.title}}</div>
        </div>
        <div class="filter-column filter-column--behaviour">
          <div class="filter-column-value filter-column-value--behaviour">
              {{operatorTranslator.translate(filter.operator)}}
          </div>
        </div>
        <div class="filter-column filter-column--type">
          <div class="filter-column-title" i18n>Value:</div>
          <div class="filter-column-value">
            {{filter.valueAsAString || filter.value}}
          </div>
        </div>
      </div>
    </div>

    <div class="footer">
      <span class="footer-error">{{error}}</span>
      <st-button-v2 (clicked)="cancel()" color="v2_red_empty" i18n-label label="CANCEL"></st-button-v2>
      <st-button-v2 (clicked)="save()" color="v2_red" [disabled]="saving || !viewName.length || !filters.length" i18n-label label="{{saving ? 'SAVING...' : 'SAVE CURRENT VIEW'}}"></st-button-v2>
    </div>
  </div>
</st-modal>
