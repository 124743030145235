import { AsinCompareAPI } from '@app/shared/data/asin-compare/asin-compare.api';
import { AsinCompareActions } from '@app/shared/data/asin-compare/asin-compare.actions';
import { NgReduxRouter, NgReduxRouterModule } from '@angular-redux/router';
import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { AmazonSnapshotActions } from '@app/shared/data/admin/amazon-snapshot/amazon-snapshot.actions';
import { AmazonSnapshotApi } from '@app/shared/data/admin/amazon-snapshot/amazon-snapshot.api';
import { AmazonSnapshotEpics } from '@app/shared/data/admin/amazon-snapshot/amazon-snapshot.epics';
import { AppState } from '@app/shared/data/app-state.model';
import { mainReducer } from '@app/shared/data/reducers';
import { provideReduxForms } from '@angular-redux/form';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { NgModule } from '@angular/core';
import { UserEpics } from '@app/shared/data/user/user.epics';
import { NotificationsEpics } from '@app/shared/data/notification/notifications.epics';
import { NotificationsActions } from '@app/shared/data/notification/notifications.actions';
import { UserAPI } from '@app/shared/data/user/user.api';
import { environment } from '@env/environment';
import { LayoutActions } from '@app/shared/layout/layout.actions';
import { NotificationAPI } from '@app/shared/data/notification/notification.api';
import { NoticeApi } from '@app/shared/data/notice/notice.api';
import { NoticeEpics } from '@app/shared/data/notice/notice.epics';
import { UnreadNotificationsActions } from '@app/shared/data/notification/notifications-unread.actions';
import { AffiliateAPI } from '@app/shared/data/affiliate/affiliate.api';
import { AffiliateEpics } from '@app/shared/data/affiliate/affiliate.epics';
import { AffiliateActions } from '@app/shared/data/affiliate/affiliate.actions';
import { CommissionActions } from '@app/shared/data/commission/commission.actions';
import { CommissionEpics } from '@app/shared/data/commission/commission.epics';
import { CommissionAPI } from '@app/shared/data/commission/commission.api';
import { CouponActions } from '@app/shared/data/coupon/coupon.actions';
import { CouponEpics } from '@app/shared/data/coupon/coupon.epics';
import { CouponAPI } from '@app/shared/data/coupon/coupon.api';
import { SocketEpics } from '@app/core/socket/store/socket.epics';
import { CredentialAPI } from '@app/shared/data/credential/credential.api';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { SubscriptionAPI } from '@app/shared/data/subscription/subscription.api';
import { SubscriptionEpics } from '@app/shared/data/subscription/subscription.epics';
import { InvoiceActions } from '@app/shared/data/invoice/invoice.actions';
import { InvoiceEpics } from '@app/shared/data/invoice/invoice.epics';
import { InvoiceAPI, WalletInvoiceAPI } from '@app/shared/data/invoice/invoice.api';
import { CreditCardAPI } from '@app/shared/data/credit-card/credit-card.api';
import { PlanActions } from '@app/shared/data/plan/plan.actions';
import { PlanEpics } from '@app/shared/data/plan/plan.epics';
import { PlanAPI } from '@app/shared/data/plan/plan.api';
import { KeywordGeneratedAPI } from '@app/shared/data/keyword-generated/keyword-generated.api';
import { KeywordGeneratedEpics } from '@app/shared/data/keyword-generated/keyword-generated.epics';
import { KeywordGeneratedActions } from '@app/shared/data/keyword-generated/keyword-generated.actions';
import { PromotionEpics } from '@app/shared/data/promotion/promotion.epics';
import { PromotionActions } from '@app/shared/data/promotion/promotion.actions';
import { PromotionApi } from '@app/shared/data/promotion/promotion.api';
import { InvitationEpics } from '@app/shared/data/invitation/invitation.epics';
import { InvitationApi } from '@app/shared/data/invitation/invitation.api';
import { InvitationActions } from '@app/shared/data/invitation/invitations.actions';
import { LocalStorageService, LS } from '@app/shared/services/local-storage.service';
import { SubAccountActions } from '@app/shared/data/sub-account/sub-account.actions';
import { SubAccountEpics } from '@app/shared/data/sub-account/sub-account.epics';
import { SubAccountAPI } from '@app/shared/data/sub-account/sub-account.api';
import { DecisionEpics } from '@app/shared/data/decision/decision.epics';
import { DecisionActions } from '@app/shared/data/decision/decision.actions';
import { DecisionAPI } from '@app/shared/data/decision/decision.api';
import { UserAdminActions } from '@app/shared/data/admin/user/user-admin.actions';
import { UserAdminAPI } from '@app/shared/data/admin/user/user-admin.api';
import { UserAdminEpics } from '@app/shared/data/admin/user/user-admin.epics';
import { SystemNotificationAdminActions } from '@app/shared/data/admin/system-notification/system-notification.actions';
import { SystemNotificationAdminEpics } from '@app/shared/data/admin/system-notification/system-notification.epics';
import { SystemNotificationAdminAPI } from '@app/shared/data/admin/system-notification/system-notification.api';
import { PayoutAdminActions } from '@app/shared/data/admin/payout-affiliate/payout-admin.actions';
import { PayoutAdminEpics } from '@app/shared/data/admin/payout-affiliate/payout-admin.epics';
import { PayoutAdminAPI } from '@app/shared/data/admin/payout-affiliate/payout-admin.api';
import { PayoutCommisionAdminEpics } from '@app/shared/data/admin/payout-commision/payout-commision.epics';
import { PayoutCommisionAdminAPI } from '@app/shared/data/admin/payout-commision/payout-commision.api';
import { PayoutCommisionAdminActions } from '@app/shared/data/admin/payout-commision/payout-commision.actions';
import { PayoutByPlanAdminActions } from '@app/shared/data/admin/payout-by-plan/payout-by-plan.actions';
import { PayoutByPlanAdminEpics } from '@app/shared/data/admin/payout-by-plan/payout-by-plan.epics';
import { PayoutByPlanAdminAPI } from '@app/shared/data/admin/payout-by-plan/payout-by-plan.api';
import { PayoutAllCommisionAdminAPI } from '@app/shared/data/admin/payout-all-commisions/payout-commision.api';
import { PayoutAllCommisionAdminActions } from '@app/shared/data/admin/payout-all-commisions/payout-commision.actions';
import { PayoutAllCommisionAdminEpics } from '@app/shared/data/admin/payout-all-commisions/payout-commision.epics';
import { AffiliateYourCommisionActions } from '@app/shared/data/affiliate/your-commision/your-commision.actions';
import { AffiliateYourCommisionEpics } from '@app/shared/data/affiliate/your-commision/your-commision.epics';
import { AffiliateYourCommisionAPI } from '@app/shared/data/affiliate/your-commision/your-commision.api';
import { AffiliateSummaryCommisionEpics } from '@app/shared/data/affiliate/summary-commision/summary-commision.epics';
import { AffiliateSummaryCommisionAPI } from '@app/shared/data/affiliate/summary-commision/summary-commision.api';
import { AffiliateSummaryCommisionActions } from '@app/shared/data/affiliate/summary-commision/summary-commision.actions';
import { User } from '@app/shared/data/user/user.models';
import { AdminCouponEpics } from '@app/shared/data/admin/coupon/coupon.epics';
import { AdminCouponAPI } from '@app/shared/data/admin/coupon/coupon.api';
import { AdminCouponActions } from '@app/shared/data/admin/coupon/coupon.actions';
import { SystemStatsActions } from '@app/shared/data/admin/system-stats/system-stats.actions';
import { SystemStatsEpics } from '@app/shared/data/admin/system-stats/system-stats.epics';
import { SystemStatsAPI } from '@app/shared/data/admin/system-stats/system-stats.api';
import { AdminPlanEpics } from '@app/shared/data/admin/plan/plan.epics';
import { AdminPlanAPI } from '@app/shared/data/admin/plan/plan.api';
import { AdminPlanActions } from '@app/shared/data/admin/plan/plan.actions';
import { LastLaunchActions } from '@app/shared/data/admin/last-launch/last-launch.actions';
import { LastLaunchEpics } from '@app/shared/data/admin/last-launch/last-launch.epics';
import { LastLaunchAPI } from '@app/shared/data/admin/last-launch/last-launch.api';
import { ReverseAsinEpics } from '@app/shared/data/reverse-asin/reverse-asin.epics';
import { ReverseAsinAPI } from '@app/shared/data/reverse-asin/reverse-asin.api';
import { ReverseAsinActions } from '@app/shared/data/reverse-asin/reverse-asin.actions';
import { UsageEpics } from '@app/shared/data/usage/usage.epics';
import { UsageActions } from '@app/shared/data/usage/usage.actions';
import { UsageApi } from '@app/shared/data/usage/usage.api';
import { ProductToolsActions } from '@app/shared/data/admin/product-tools/product-tools.actions';
import { ProductToolsApi } from '@app/shared/data/admin/product-tools/product-tools.api';
import { ProductToolsEpics } from '@app/shared/data/admin/product-tools/product-tools.epics';
import { LastLaunchPromotionAPI } from '@app/shared/data/last-launch-promotion/last-launch-promotion.api';
import { LastLaunchPromotionActions } from '@app/shared/data/last-launch-promotion/last-launch-promotion.actions';
import { LastLaunchPromotionEpics } from '@app/shared/data/last-launch-promotion/last-launch-promotion.epics';
import { LastLaunchScheduleActions } from '@app/shared/data/last-launch-schedule/last-launch-schedule.actions';
import { LastLaunchScheduleAPI } from '@app/shared/data/last-launch-schedule/last-launch-schedule.api';
import { LastLaunchScheduleEpics } from '@app/shared/data/last-launch-schedule/last-launch-schedule.epics';
import { LsTaskActions } from '@app/shared/data/tasks/ls-task/ls-task.actions';
import { LsTaskEpics } from '@app/shared/data/tasks/ls-task/ls-task.epics';
import { LsTaskApi } from '@app/shared/data/tasks/ls-task/ls-task.api';
import { R2aTaskActions } from '@app/shared/data/tasks/r2a-task/r2a-task.actions';
import { R2aTaskEpics } from '@app/shared/data/tasks/r2a-task/r2a-task.epics';
import { R2aTaskApi } from '@app/shared/data/tasks/r2a-task/r2a-task.api';
import { AdminSurveyActions } from '@app/shared/data/admin/survey/survey.actions';
import { AdminSurveyEpics } from '@app/shared/data/admin/survey/survey.epics';
import { AdminSurveyAPI } from '@app/shared/data/admin/survey/survey.api';
import { SurveyActions } from '@app/shared/data/survey/survey.actions';
import { SurveyAPI } from '@app/shared/data/survey/survey.api';
import { SurveyEpics } from '@app/shared/data/survey/survey.epics';
import { ProductChildAPI } from '@app/shared/data/product-child/product-child.api';
import { ProductChildActions } from '@app/shared/data/product-child/product-child.actions';
import { VersionActions } from '@app/shared/data/admin/version/version.actions';
import { VersionEpics } from '@app/shared/data/admin/version/version.epics';
import { VersionApi } from '@app/shared/data/admin/version/version.api';
import { SessionLogsActions } from '@app/shared/data/session-logs/session-logs.actions';
import { SessionLogsApi } from '@app/shared/data/session-logs/session-logs.api';
import { SessionLogsEpics } from '@app/shared/data/session-logs/session-logs.epics';
import {
  BaKeywordRankingDataCollectionApi,
  BrandAnalyticsSyncDataCollectionApi,
  FeedbacksDataCollectionApi,
  FinancialEventsSyncDataCollectionApi,
  KeywordRankingDataCollectionApi,
  LastSearchSyncDataCollectionApi,
  NewKeywordRankingDataCollectionApi,
  OrderItemsSyncDataCollectionApi,
  OrderReturnsSyncDataCollectionApi,
  OrdersSyncDataCollectionApi,
  ProductStockSyncDataCollectionApi,
  ProductSyncDataCollectionApi,
  ReviewCountStatsDataCollectionApi,
  SalesJungleScoutDataCollectionApi,
  SalesViralLaunchDataCollectionApi,
  SellerSpriteKeywordHistoryDataCollectionApi,
  KWDHPopulateDataCollectionApi,
  SocilitationActionsDataCollectionApi,
  SalesJungleScoutByCategoryDataCollectionApi,
} from '@app/shared/data/admin/data-collection/data-collection.api';
import { ApiTokenActions } from '@app/shared/data/api-token/api-token.actions';
import { ApiTokenEpics } from '@app/shared/data/api-token/api-token.epics';
import { ApiTokenApi } from '@app/shared/data/api-token/api-token.api';
import { PrizmActions, PrizmKeywordActions, PrizmProductActions } from '@app/shared/data/prizm/prizm.actions';
import { PrizmEpics } from '@app/shared/data/prizm/prizm.epics';
import { ApiPromotionActions } from '@app/shared/data/api-promotion/api-promotion.actions';
import { ApiPromotionEpics } from '@app/shared/data/api-promotion/api-promotion.epics';
import { ApiPromotionApi } from '@app/shared/data/api-promotion/api-promotion.api';
import { SystemSettingsActions } from '@app/shared/data/admin/system-settings/system-settings.actions';
import { SystemSettingsEpics } from '@app/shared/data/admin/system-settings/system-settings.epics';
import { SystemSettingsApi } from '@app/shared/data/admin/system-settings/system-settings.api';
import { PrizmApi } from '@app/shared/data/prizm/prizm.api';
import { AlertEpics } from '@app/shared/data/alert/alert.epics';
import { AlertActions } from '@app/shared/data/alert/alert.actions';
import { AlertApi } from '@app/shared/data/alert/alert.api';
import { WalletTransactionsApi } from '@app/shared/data/wallet-transactions/wallet-transactions.api';
import { WalletTransactionsEpics } from '@app/shared/data/wallet-transactions/wallet-transactions.epics';
import { WalletTransactionsActions } from '@app/shared/data/wallet-transactions/wallet-transactions.actions';
import { UserAlertEpics } from '@app/shared/data/alert/user/user-alert.epics';
import { UserAlertActions } from '@app/shared/data/alert/user/user-alert.actions';
import { WalletTopUpSourcesEpics } from '@app/shared/data/wallet-transactions/wallet-top-up-sources.epics';
import { WalletTopUpSourcesActions } from '@app/shared/data/wallet-transactions/wallet-top-up-sources.actions';
import { WalletTopUpSourcesApi } from '@app/shared/data/wallet-transactions/wallet-top-up-sources.api';
import { GiftCardsApi } from '@app/shared/data/wallet-transactions/gift-cards.api';
import { WalletActions } from '@app/shared/data/wallet/wallet.actions';
import { WalletEpics } from '@app/shared/data/wallet/wallet.epics';
import { WalletApi } from '@app/shared/data/wallet/wallet.api';
import { ExchangeRatesActions } from '@app/shared/data/exchange-rates/exchange-rates.actions';
import { ExchangeRatesApi } from '@app/shared/data/exchange-rates/exchange-rates.api';
import { ExchangeRatesEpics } from '@app/shared/data/exchange-rates/exchange-rates.epics';
import { BlacklistActions } from '@app/shared/data/wallet-blacklist/blacklist.actions';
import { BlacklistEpics } from '@app/shared/data/wallet-blacklist/blacklist.epics';
import { BlacklistApi } from '@app/shared/data/wallet-blacklist/blacklist.api';
import { AsinCompareEpics } from '@app/shared/data/asin-compare/asin-compare.epics';
import { ReviewRequestEpics } from '@app/shared/data/review-request/review-request.epics';
import { RequestReviewActions } from '@app/shared/data/review-request/review-request.actions';
import { ReviewRequestAPI } from '@app/shared/data/review-request/review-request.api';
import { CustomerListActions } from '@app/shared/data/customer-list/customer-list.actions';
import { CustomerListEpics } from '@app/shared/data/customer-list/customer-list.epics';
import { CustomerListAPI } from '@app/shared/data/customer-list/customer-list.api';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import { ProductManagerEpics } from '@app/shared/data/product-manager/product-manager.epics';
import { ProductManagerAPI } from '@app/shared/data/product-manager/product-manager.api';
import { CampaignManagerActions } from '@app/shared/data/campaign-manager/campaign-manager.actions';
import { CampaignManagerEpics } from '@app/shared/data/campaign-manager/campaign-manager.epics';
import { CampaignManagerAPI } from '@app/shared/data/campaign-manager/campaign-manager.api';
import { BrowseProductsActions } from '@app/shared/data/browse-products/browse-products.actions';
import { BrowseProductsEpics } from '@app/shared/data/browse-products/browse-products.epics';
import { BrowseProductsApi } from '@app/shared/data/browse-products/browse-products.api';
import { R2AEpicsV2 } from '@app/shared/data/reverse-asin-v2/reverse-asin.epics';
import { WalletAdminAPI } from '@app/shared/data/admin/wallet/wallet.api';

const ACTIONS = [
  PlanActions,
  UsersActions,
  UsageActions,
  LayoutActions,
  CouponActions,
  InvoiceActions,
  AffiliateActions,
  AffiliateYourCommisionActions,
  AffiliateSummaryCommisionActions,
  PromotionActions,
  CommissionActions,
  InvitationActions,
  SubscriptionActions,
  NotificationsActions,
  KeywordGeneratedActions,
  ReverseAsinActions,
  UnreadNotificationsActions,
  LsTaskActions,
  R2aTaskActions,
  SubAccountActions,
  SurveyActions,
  DecisionActions,
  LastLaunchPromotionActions,
  LastLaunchScheduleActions,
  ProductChildActions,
  SessionLogsActions,
  WalletActions,
  WalletTransactionsActions,
  WalletTopUpSourcesActions,
  BlacklistActions,
  ExchangeRatesActions,
  AlertActions,
  UserAlertActions,
  PrizmActions,
  PrizmProductActions,
  PrizmKeywordActions,
  ApiTokenActions,
  ApiPromotionActions,
  AsinCompareActions,
  RequestReviewActions,
  CustomerListActions,
  ProductManagerActions,
  CampaignManagerActions,
  BrowseProductsActions,

  // Admin
  SystemStatsActions,
  UserAdminActions,
  PayoutAdminActions,
  PayoutCommisionAdminActions,
  PayoutByPlanAdminActions,
  PayoutAllCommisionAdminActions,
  SystemNotificationAdminActions,
  AdminCouponActions,
  AdminPlanActions,
  LastLaunchActions,
  ProductToolsActions,
  AdminSurveyActions,
  VersionActions,
  SystemSettingsActions,
  AmazonSnapshotActions,
];

const EPICS = [
  PlanEpics,
  UserEpics,
  UsageEpics,
  CouponEpics,
  NoticeEpics,
  InvoiceEpics,
  PromotionEpics,
  AffiliateEpics,
  AffiliateYourCommisionEpics,
  AffiliateSummaryCommisionEpics,
  CommissionEpics,
  InvitationEpics,
  SubscriptionEpics,
  NotificationsEpics,
  KeywordGeneratedEpics,
  ReverseAsinEpics,
  R2AEpicsV2,
  SubAccountEpics,
  SurveyEpics,
  DecisionEpics,
  LastLaunchPromotionEpics,
  LastLaunchScheduleEpics,
  LsTaskEpics,
  R2aTaskEpics,
  SessionLogsEpics,
  WalletEpics,
  WalletTransactionsEpics,
  WalletTopUpSourcesEpics,
  BlacklistEpics,
  ExchangeRatesEpics,
  AlertEpics,
  UserAlertEpics,
  PrizmEpics,
  ApiTokenEpics,
  ApiPromotionEpics,
  AsinCompareEpics,
  ReviewRequestEpics,
  CustomerListEpics,
  ProductManagerEpics,
  CampaignManagerEpics,
  BrowseProductsEpics,

  // Admin
  SystemStatsEpics,
  UserAdminEpics,
  PayoutAdminEpics,
  PayoutCommisionAdminEpics,
  PayoutByPlanAdminEpics,
  PayoutAllCommisionAdminEpics,
  SystemNotificationAdminEpics,
  AdminCouponEpics,
  AdminPlanEpics,
  AdminSurveyEpics,
  LastLaunchEpics,
  ProductToolsEpics,
  VersionEpics,
  SystemSettingsEpics,
  AmazonSnapshotEpics,
];

const APIS = [
  UserAPI,
  PlanAPI,
  UsageApi,
  CouponAPI,
  NoticeApi,
  InvoiceAPI,
  WalletInvoiceAPI,
  PromotionApi,
  AffiliateAPI,
  AffiliateYourCommisionAPI,
  AffiliateSummaryCommisionAPI,
  CommissionAPI,
  CreditCardAPI,
  InvitationApi,
  CredentialAPI,
  NotificationAPI,
  SubscriptionAPI,
  KeywordGeneratedAPI,
  ReverseAsinAPI,
  SubAccountAPI,
  SurveyAPI,
  DecisionAPI,
  LastLaunchPromotionAPI,
  LastLaunchScheduleAPI,
  LsTaskApi,
  R2aTaskApi,
  ProductChildAPI,
  AlertApi,
  ApiTokenApi,
  ApiPromotionApi,
  PrizmApi,
  AsinCompareAPI,
  ReviewRequestAPI,
  CustomerListAPI,
  ProductManagerAPI,
  CampaignManagerAPI,
  BrowseProductsApi,

  // Admin
  SystemStatsAPI,
  UserAdminAPI,
  PayoutAdminAPI,
  PayoutCommisionAdminAPI,
  PayoutByPlanAdminAPI,
  PayoutAllCommisionAdminAPI,
  SystemNotificationAdminAPI,
  AdminCouponAPI,
  AdminPlanAPI,
  LastLaunchAPI,
  ProductToolsApi,
  AdminSurveyAPI,
  VersionApi,
  SessionLogsApi,
  SystemSettingsApi,
  OrdersSyncDataCollectionApi,
  OrderItemsSyncDataCollectionApi,
  OrderReturnsSyncDataCollectionApi,
  FinancialEventsSyncDataCollectionApi,
  WalletApi,
  WalletTransactionsApi,
  BlacklistApi,
  ExchangeRatesApi,
  GiftCardsApi,
  WalletTopUpSourcesApi,
  BrandAnalyticsSyncDataCollectionApi,
  LastSearchSyncDataCollectionApi,
  ProductSyncDataCollectionApi,
  ProductStockSyncDataCollectionApi,
  KeywordRankingDataCollectionApi,
  NewKeywordRankingDataCollectionApi,
  BaKeywordRankingDataCollectionApi,
  AmazonSnapshotApi,
  SalesViralLaunchDataCollectionApi,
  SalesJungleScoutDataCollectionApi,
  SalesJungleScoutByCategoryDataCollectionApi,
  FeedbacksDataCollectionApi,
  ReviewCountStatsDataCollectionApi,
  SocilitationActionsDataCollectionApi,
  SellerSpriteKeywordHistoryDataCollectionApi,
  KWDHPopulateDataCollectionApi,
  WalletAdminAPI,
];


@NgModule({
  imports: [
    NgReduxModule,
    NgReduxRouterModule.forRoot(),
  ],
  providers: [
    ...ACTIONS,
    ...EPICS,
    ...APIS,
  ],
})
export class StoreModule {
  constructor(public store: NgRedux<AppState>,
              ngReduxRouter: NgReduxRouter,
              userEpics: UserEpics,
              notificationsEpics: NotificationsEpics,
              noticeEpics: NoticeEpics,
              affiliateEpics: AffiliateEpics,
              affilaiteYourCommisionEpics: AffiliateYourCommisionEpics,
              affilaiteSummaryCommisionEpics: AffiliateSummaryCommisionEpics,
              commissionEpics: CommissionEpics,
              couponEpics: CouponEpics,
              subscriptionEpics: SubscriptionEpics,
              invoiceEpics: InvoiceEpics,
              planEpics: PlanEpics,
              socketEpics: SocketEpics,
              promotionEpics: PromotionEpics,
              invitationEpics: InvitationEpics,
              keywordGeneratedEpics: KeywordGeneratedEpics,
              reverseAsinEpics: ReverseAsinEpics,
              r2AEpicsV2: R2AEpicsV2,
              subAccountEpics: SubAccountEpics,
              surveyEpics: SurveyEpics,
              decisionEpics: DecisionEpics,
              usageEpics: UsageEpics,
              lastLaunchPromotionEpics: LastLaunchPromotionEpics,
              lastLaunchScheduleEpics: LastLaunchScheduleEpics,
              lsTaskEpics: LsTaskEpics,
              r2aTaskEpics: R2aTaskEpics,
              sessionLogsEpics: SessionLogsEpics,
              walletEpics: WalletEpics,
              exchangeRatesEpics: ExchangeRatesEpics,
              walletTransactionsEpics: WalletTransactionsEpics,
              walletTopUpSourcesEpics: WalletTopUpSourcesEpics,
              blacklistEpics: BlacklistEpics,
              productAlertEpics: AlertEpics,
              userAlertEpics: UserAlertEpics,
              prizmEpics: PrizmEpics,
              apiTokenEpics: ApiTokenEpics,
              apiPromotionEpics: ApiPromotionEpics,
              asinCompareEpics: AsinCompareEpics,
              browseProductsEpics: BrowseProductsEpics,
              // Admin
              systemStatsEpics: SystemStatsEpics,
              userAdminEpics: UserAdminEpics,
              payoutAdminEpics: PayoutAdminEpics,
              payoutCommisionEpics: PayoutCommisionAdminEpics,
              payoutByPlanEpics: PayoutByPlanAdminEpics,
              payoutAllCommisionEpics: PayoutAllCommisionAdminEpics,
              systemNotificationAdminEpics: SystemNotificationAdminEpics,
              adminCouponEpics: AdminCouponEpics,
              adminPlanEpics: AdminPlanEpics,
              lastLaunchEpics: LastLaunchEpics,
              productToolsEpics: ProductToolsEpics,
              adminSurveyEpics: AdminSurveyEpics,
              adminVersionEpics: VersionEpics,
              systemSettingsEpics: SystemSettingsEpics,
              amazonSnapshotEpics: AmazonSnapshotEpics,
              reviewRequestEpics: ReviewRequestEpics,
              customerListEpics: CustomerListEpics,
              productManagerEpics: ProductManagerEpics,
              campaignManagerEpics: CampaignManagerEpics,
              // Actions
              usersActions: UsersActions) {

    // Tell Redux about our reducers and epics. If the Redux DevTools
    // chrome extension is available in the browser, tell Redux about
    // it too.
    store.configureStore(
      mainReducer,
      {},
      [
        ...userEpics.createEpic(),
        ...planEpics.createEpic(),
        ...socketEpics.createEpic(),
        ...noticeEpics.createEpic(),
        ...couponEpics.createEpic(),
        ...invoiceEpics.createEpic(),
        ...promotionEpics.createEpic(),
        ...affiliateEpics.createEpic(),
        ...affilaiteYourCommisionEpics.createEpic(),
        ...affilaiteSummaryCommisionEpics.createEpic(),
        ...commissionEpics.createEpic(),
        ...invitationEpics.createEpic(),
        ...subscriptionEpics.createEpic(),
        ...notificationsEpics.createEpic(),
        ...keywordGeneratedEpics.createEpic(),
        ...reverseAsinEpics.createEpic(),
        ...r2AEpicsV2.createEpic(),
        ...decisionEpics.createEpic(),
        ...subAccountEpics.createEpic(),
        ...surveyEpics.createEpic(),
        ...usageEpics.createEpic(),
        ...lastLaunchPromotionEpics.createEpic(),
        ...lastLaunchScheduleEpics.createEpic(),
        ...lsTaskEpics.createEpic(),
        ...r2aTaskEpics.createEpic(),
        ...sessionLogsEpics.createEpic(),
        ...walletEpics.createEpic(),
        ...walletTransactionsEpics.createEpic(),
        ...walletTopUpSourcesEpics.createEpic(),
        ...blacklistEpics.createEpic(),
        ...exchangeRatesEpics.createEpic(),
        ...productAlertEpics.createEpic(),
        ...userAlertEpics.createEpic(),
        ...prizmEpics.createEpic(),
        ...apiTokenEpics.createEpic(),
        ...apiPromotionEpics.createEpic(),
        ...asinCompareEpics.createEpic(),
        ...reviewRequestEpics.createEpic(),
        ...customerListEpics.createEpic(),
        ...productManagerEpics.createEpic(),
        ...campaignManagerEpics.createEpic(),
        ...browseProductsEpics.createEpic(),

        // Admin
        ...systemStatsEpics.createEpic(),
        ...userAdminEpics.createEpic(),
        ...payoutAdminEpics.createEpic(),
        ...payoutCommisionEpics.createEpic(),
        ...payoutByPlanEpics.createEpic(),
        ...payoutAllCommisionEpics.createEpic(),
        ...systemNotificationAdminEpics.createEpic(),
        ...adminCouponEpics.createEpic(),
        ...adminPlanEpics.createEpic(),
        ...lastLaunchEpics.createEpic(),
        ...productToolsEpics.createEpic(),
        ...adminSurveyEpics.createEpic(),
        ...adminVersionEpics.createEpic(),
        ...systemSettingsEpics.createEpic(),
        ...amazonSnapshotEpics.createEpic(),
      ],
      environment['reduxEnhancer'] && Boolean((window as any).__REDUX_DEVTOOLS_EXTENSION__)
        ? [(window as any).__REDUX_DEVTOOLS_EXTENSION__()]
        : []
    );

    // Enable syncing of Angular router state with our Redux store.
    if (ngReduxRouter) {
      ngReduxRouter.initialize();
    }

    // Enable syncing of Angular form state with our Redux store.
    provideReduxForms(store);

    store.select(['user', 'data']).subscribe((data) => {
      try {
        localStorage.setItem(
          environment.LOCALSTORAGE_KEY + '-user',
          JSON.stringify(data)
        );
      } catch (error) {
        console.log('StoreModule: LocalStorage error', error);
      }
    });

    store.select(['tasks']).subscribe((data) => {
      try {
        LocalStorageService.forActiveCredential().setTasks(data);
      } catch (error) {
        console.log('StoreModule: LocalStorage error', error);
      }
    });


    LS.subscribe(LS.USER_KEY, (newUser, oldUser) => {
      newUser = new User(JSON.parse(newUser));
      oldUser = new User(JSON.parse(oldUser));

      if (!oldUser || !newUser || newUser.active_credential !== oldUser.active_credential) {
        location.reload();
      } else {
        //noinspection TypeScriptValidateTypes
        this.store.dispatch(usersActions.override(newUser));
      }
    });
  }
}
