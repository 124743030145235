import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { SessionLogsActions } from "@app/shared/data/session-logs/session-logs.actions";
import { SessionLogsApi } from "@app/shared/data/session-logs/session-logs.api";

@Injectable()
export class SessionLogsEpics {

  constructor(
    private store: NgRedux<AppState>,
    private sessionLogsApi: SessionLogsApi,
    private action: SessionLogsActions
  ) { }

  public createEpic() {
    return [
      this.fetchLogs,
    ];
  }

  fetchLogs = store => next => {
    return (action: StAction) => {
      let n = next(action);

      if ([
        SessionLogsActions.TYPES.LOAD_SESSION_LOGS,
        SessionLogsActions.TYPES.SESSION_LOGS_CHANGE_CURRENT_PAGE,
        SessionLogsActions.TYPES.SESSION_LOGS_CHANGE_PER_PAGE
      ].includes(action.type)) {
        let state = store.getState()['session_logs'];

        const limit = (action.data && action.data.perPage) ? action.data.perPage : state.pagination.per_page;
        const page = (action.data && action.data.page) ? action.data.page : state.pagination.current_page;

        this.sessionLogsApi.all(action.data, { page, limit, sort: '-created_at' })
          .subscribe(
            (sessionLogs) => {
              this.store.dispatch(this.action.fetchSessionLogsSucceeded(sessionLogs));
            },
            (response) => {
              this.store.dispatch(this.action.fetchSessionLogsFailed(response));
            }
          );
      }

      return n
    };
  };
}
