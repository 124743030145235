import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { InvitationApi } from '@app/shared/data/invitation/invitation.api';
import { InvitationActions } from '@app/shared/data/invitation/invitations.actions';
import { Invitation } from '@app/shared/data/invitation/invitation.models';
import { AuthService } from '@app/core/services/auth.service';

@Injectable()
export class InvitationEpics {

  constructor(private store: NgRedux<AppState>,
              private invitationApi: InvitationApi,
              private authService: AuthService) {
  }

  public createEpic() {
    return [
      this.fetchInvitation,
      this.acceptInvitation,
      this.acceptInvitationSucceeded,
    ];
  }

  fetchInvitation = store => next => {
    return (action: StAction) => {
      if (action.type === InvitationActions.TYPES.FETCH_INVITATION) {
        this.invitationApi.get(action.data)
          .subscribe(
            (invitation: Invitation) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(InvitationActions.fetchInvitationSucceed(invitation));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(InvitationActions.fetchInvitationFailed(response));
            },
            () => {
            },
          );
      }

      return next(action);
    };
  };

  acceptInvitation = store => next => {
    return (action: StAction) => {
      if (action.type === InvitationActions.TYPES.ACCEPT_INVITATION) {
        this.invitationApi.accept(action.data)
          .subscribe(
            (invitation: Invitation) => {
              this.store.dispatch(InvitationActions.acceptInvitationSucceed(invitation));
            },
            (response) => {
              this.store.dispatch(InvitationActions.acceptInvitationFailed(response));
            },
            () => {
            },
          );
      }

      return next(action);
    };
  };

  acceptInvitationSucceeded = store => next => {
    return (action: StAction) => {
      if (action.type === InvitationActions.TYPES.ACCEPT_INVITATION_SUCCESS) {
        this.authService.switchActiveCredential(action.data.credential);
      }

      return next(action);
    };
  };
}
