
import {throwError as observableThrowError, from as observableFrom,  Observable } from 'rxjs';

import {mergeMap, catchError} from 'rxjs/operators';
import { HttpInterceptor } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HttpRequest } from "@angular/common/http";
import { HttpHandler } from "@angular/common/http";
import { HttpEvent } from "@angular/common/http";
import { DialogService } from "@app/shared/components/dialog/dialog.service";
import {I18n} from '@ngx-translate/i18n-polyfill';

@Injectable()
export class GeneralInterceptor implements HttpInterceptor {
  constructor(
    private dialogService: DialogService,
    private i18n: I18n) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(response => {
      let error = response.error;

      if ('error' in error) error = error.error;

      if (response.status >= 500 && response.status < 600) {
        const promise = this.dialogService.info(
          this.i18n('Oops, something went wrong.'),
          this.i18n('Try to refresh this page or feel free to contact us if the problem persists.'));

        return observableFrom(promise).pipe(
          mergeMap((confirmed) => {
            return observableThrowError(response);
          }))
      }
      return observableThrowError(response);
    }));
  }
}
