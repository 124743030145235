
import {skip} from 'rxjs/operators';
import { Inject, NgModule } from '@angular/core';

import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { environment } from '@env/environment';
import { StoreActions } from '@app/core/socket/store/socket.actions';

import { SocketConfig } from '@app/core/socket/socket.config';
import { SocketService } from '@app/core/socket/socket.service';
import { SocketIoService } from '@app/core/socket/socket-io.service';
import { AuthService } from '@app/core/services/auth.service';
import { SocketEpics } from '@app/core/socket/store/socket.epics';

const SOCKET_CONFIG: SocketConfig = {
  host               : environment.ECHO_HOST,
  credentials_channel: 'SellerTools.Credential',
  credential_owner_channel: 'SellerTools.CredentialOwner',
  admin_channel      : 'SellerTools.Admin',
  public_channel     : 'SellerTools.Public',
  user_channel       : 'SellerTools.User',
  auth               : {
    headers: {},
  },
  debug              : true,
};

@NgModule({
  imports  : [],
  providers: [
    SocketEpics,
    {
      provide : 'SocketConfig',
      useValue: SOCKET_CONFIG,
    },
    {
      provide : 'SocketService',
      useClass: SocketIoService,
    },
    {
      provide    : 'UserService',
      useExisting: AuthService,
    },
  ],
})
export class SocketModule {

  constructor(@Inject('SocketService') private _socket: SocketService,
              private store: NgRedux<AppState>,) {
    this._socket.connected$.pipe(
      skip(1))
      .subscribe((isConnected: boolean) => {
        if (isConnected) {
          this.store.dispatch(StoreActions.onConnect());
        } else {
          this.store.dispatch(StoreActions.onDisconnect());
        }
      });

  }
}
