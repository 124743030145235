import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';
import {
  IProductManagerProduct,
  IProductManagerState,
  IProductManagerVariation
} from '@app/shared/data/product-manager/product-manager.models';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Subject } from 'rxjs';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import { ProductManagerService } from '@app/client/v2/product-manager/product-manager.service';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  IFieldOrder,
  SortByEnum,
  SortOrderEnum
} from '@app/client/v2/product-manager/product-manager-product-list/product-manager-product-list.component';
import { ToastService } from '@app/core/services/toast.service';

@Component({
  templateUrl: './browse-products-modal.component.html',
  styleUrls: ['./browse-products-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BrowseProductsModalComponent implements OnInit, OnDestroy {
  @ViewChild('modal', { static: true }) modal: ModalComponent;

  products: IProductManagerProduct[];
  productVariations: IProductManagerVariation[];
  loadingProducts = false;
  loadingProductVariations = false;

  // temporary
  from = 1;
  to = 10;
  total = 50;
  lastPage = 5;

  perPage = 10;
  perPageOptions: IDropdownOption[] = [
    {
      id: 10,
      label: '10'
    },
    {
      id: 20,
      label: '20'
    },
    {
      id: 50,
      label: '50'
    },
  ];
  preSelectedOption: IDropdownOption = {
    id: this.perPage,
    label: this.perPage.toString()
  };

  variationPreSelectedOption = this.productManagerService.preSelectedOption;
  variationPerPage = 20;
  variationCurrentPage = 1;
  variationTotal: number;
  variationPagesArray: number[];
  variationFrom: number;
  variationTo: number;
  variationLastPage: number;

  currentPage = 1;
  numberOfPages = Math.ceil(this.total / this.perPage);
  pagesArray = Array(this.numberOfPages).fill(0).map((_x, i) => i + 1);

  defaultSortBy: IFieldOrder[] = [{
    field: SortByEnum.STATUS,
    order: SortOrderEnum.DESC
  }, {
    field: SortByEnum.TITLE,
    order: SortOrderEnum.ASC
  }];
  currentSortBy: IFieldOrder[] = this.defaultSortBy;

  searchQuery$ = new Subject<string>();
  searchProductString: string;

  preselectedProduct = null;
  selectedProductChildren = null;
  selectedProduct: {product: IProductManagerProduct, children: any[]};
  singleVariationSelection = false;
  singleProductSelection = false;
  showVariations = true;
  showNoneOption = false;
  noneText: string;

  initDone: boolean;

  title: string = this.i18n('Browse Products');
  subtitle: string = this.i18n('Choose the product you would like to do the campaign for.  All variations will be automatically selected. In order to change that, open up product details.');

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private i18n: I18n,
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
    private productManagerService: ProductManagerService,
    private cd: ChangeDetectorRef,
    private toastService: ToastService,
  ) {
  }

  ngOnInit() {
    this.ngRedux.dispatch(this.productManagerActions.resetState());
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy));
    this.productManagerService.reset();

    this.ngRedux.select('product_manager')
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((productManagerState: IProductManagerState) => {
        this.loadingProducts = productManagerState.loadingProducts;
        this.loadingProductVariations = productManagerState.loadingProductVariations;
        this.productVariations = productManagerState.productVariationsState.products;
        this.products = productManagerState.productsState.products;
        this.from = productManagerState.productsState.from;
        this.to = productManagerState.productsState.to;
        this.perPage = productManagerState.productsState.per_page || 20;
        this.currentPage = productManagerState.productsState.current_page || 1;
        this.total = productManagerState.productsState.total;
        this.lastPage = productManagerState.productsState.last_page;
        this.variationPerPage = productManagerState.productVariationsState.per_page || 20;
        this.variationCurrentPage = productManagerState.productVariationsState.current_page || 1;
        this.variationTotal = productManagerState.productVariationsState.total;
        this.variationFrom = productManagerState.productVariationsState.from;
        this.variationTo = productManagerState.productVariationsState.to;
        this.variationLastPage = productManagerState.productVariationsState.last_page;

        if (productManagerState.productsState.products) {
          const selectedProduct = productManagerState.productsState.products.find((product: IProductManagerProduct) => product.checked);

          if (selectedProduct) {
            this.selectedProduct = { product: selectedProduct, children: [] };
          } else {
            if (this.preselectedProduct && this.products?.length) {
              this.ngRedux.dispatch(this.productManagerActions.toggleProduct(this.preselectedProduct.product?.asin || this.preselectedProduct.product));
              this.ngRedux.dispatch(this.productManagerActions.getProductVariations(this.preselectedProduct.product?.asin || this.preselectedProduct.product));
              if (this.preselectedProduct.children?.length) {
                this.selectedProductChildren = this.preselectedProduct.children.map(v => v.asin || v);
              }
              this.preselectedProduct = null;
            } else {
              this.selectedProduct = null;
            }
          }
        }

        if (productManagerState.productVariationsState.products && this.selectedProduct) {
          const childProducts = productManagerState.productVariationsState.products.filter(
            (variation: IProductManagerVariation) => variation.checked
          );

          if (childProducts && childProducts.length) {
            this.selectedProduct.children = childProducts.map(
              (variation: IProductManagerVariation) => this.singleVariationSelection ? variation : variation.asin
            );
          }
        }

        const numberOfPages = Math.ceil(this.total / this.perPage);

        if (!isNaN(numberOfPages)) {
          this.pagesArray = Array(numberOfPages).fill(0).map((_x, i) => i + 1);
        }

        const numberOfVariationPages = Math.ceil(this.variationTotal / this.variationPerPage);

        if (!isNaN(numberOfVariationPages)) {
          this.variationPagesArray = Array(numberOfVariationPages).fill(0).map((_x, i) => i + 1);
        }

        if (this.canDetectChanges) {
          this.cd.detectChanges();
        }
      });

    this.searchQuery$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe((value: string) => {
        this.currentPage = 1;
        this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, false, value));
      });
  }

  sortBy(field: SortByEnum) {
    const existing = this.currentSortBy.find((filedOrder: IFieldOrder) => filedOrder.field === field);

    // if field is already on the order list, toggle the sorting
    if (existing !== undefined) {
      existing.order = existing.order === SortOrderEnum.DESC ? SortOrderEnum.ASC : SortOrderEnum.DESC;
    } else {
      this.currentSortBy = [{
        field: SortByEnum.STATUS,
        order: SortOrderEnum.DESC
      }, {
        field,
        order: SortOrderEnum.ASC,
      }];
    }

    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy));
  }

  searchProducts() {
    this.searchQuery$.next(this.searchProductString);
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.initDone = false;
      this.currentPage--;
      this.fetchProducts();
    }
  }

  nextPage() {
    if (this.currentPage < this.pagesArray.length - 1) {
      this.initDone = false;
      this.currentPage++;
      this.fetchProducts();
    }
  }

  goToFirstPage() {
    this.currentPage = 1;
    this.initDone = false;
    this.fetchProducts();
  }

  goToLastPage() {
    this.currentPage = this.lastPage;
    this.initDone = false;
    this.fetchProducts();
  }

  selectPerPage(event: IDropdownOption) {
    if (this.perPage === event.id) {
      return;
    }
    this.initDone = false;
    this.currentPage = 1;
    this.perPage = event.id as number;
    this.preSelectedOption = {
      id: event.id,
      label: event.id.toString()
    };
    this.fetchProducts();
  }

  close() {
    this.modal.close();
  }

  confirm() {
    if (!this.showNoneOption && !this.selectedProduct) {
      this.toastService.error(this.i18n('Cannot save'), this.i18n('Please select a product'));
      return;
    }

    if (this.showVariations && !this.selectedProduct.children.length) {
      this.toastService.error(this.i18n('Cannot save'), this.i18n('Please select at least one variation'));
      return;
    }
    
    this.modal.close(this.selectedProduct);
  }

  get currentDisplayedProducts(): string {
    return this.i18n(`Showing ${this.from} - ${this.to} of ${this.total}`);
  }

  fetchProducts() {
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy));
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }
}

