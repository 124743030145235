import { Injectable } from "@angular/core";
import { Epic } from "redux-observable";
import { AppState } from "@app/shared/data/app-state.model";
import { Action } from "redux";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { PlanActions } from "@app/shared/data/plan/plan.actions";
import { PlanAPI } from "@app/shared/data/plan/plan.api";
import { Plan } from "@app/shared/data/plan/plan.models";

@Injectable()
export class PlanEpics {
  constructor(private planAPI: PlanAPI,
              private ngRedux: NgRedux<AppState>) {}

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.load,
    ];
  }

  load = store => next => {
    return (action) => {
      if (action.type == PlanActions.TYPES.LOAD) {
        this.planAPI.load(action.data)
          .subscribe(
            (plans: Collection<Plan>) => {
              this.ngRedux.dispatch(PlanActions.loadSucceeded(plans));
            },
            (response) => {
              this.ngRedux.dispatch(PlanActions.loadFailed(response));
            }
          )
      }

      return next(action);
    }
  }
}
