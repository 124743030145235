
import {throwError as observableThrowError, of as observableOf,  Observable ,  forkJoin } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpParameterCodec, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { handleError } from '@app/helpers';
import { BaseAPI } from '../base.api';
import { catchError } from 'rxjs/operators';
import {
  IAddedCompetitorProduct,
  IProductManagerDraft,
  IProductManagerDraftListingResponse,
  IProductManagerGraphDataResponse,
  IProductManagerKeywordGraphResponse,
  IProductManagerKeywordsResponse,
  IProductManagerProduct,
  IProductManagerProductsResponse,
  IProductManagerUpdateKeywordsPayload,
  IProductManagerVariation,
  IProductManagerVariationsResponse,
  ProductActivityStatusEnum,
} from './product-manager.models';
import { PastedAsin } from '@app/client/v2/product-manager/product-list-add-products-manually-modal/product-list-add-products-manually-modal.component';

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}


@Injectable()
export class ProductManagerAPI extends BaseAPI {
  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  getProducts(
    page: number, limit: number = 20, sort: string, foreign: boolean,
    term?: string, tags?: string[], status?: ProductActivityStatusEnum,
    asins?: string[]
  ): Observable<IProductManagerProductsResponse> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()})
      .set('page', page.toString())
      .set('limit', limit.toString())
      .set('foreign', foreign.toString());

    if (term) {
      params = params.set('term', term);
    }

    if (sort) {
      params = params.set('sort', sort);
    }

    if (tags && tags.length > 0) {
      tags.forEach((tag: string) => {
        params = params.append('tags[]', tag);
      });
    }
    if (asins && asins.length > 0) {
      asins.forEach((asin: string) => {
        params = params.append('asins[]', asin);
      });
    }

    // Status can be either 0, 1 or 2. Need to add additional condition to check for 0
    if (status || status === 0) {
      params = params.set('status', status.toString());
    }

    return this.http.get<IProductManagerProductsResponse>(this.constructFullUrl('api/v2/products'), { params });
  }

  getProduct(asin: string): Observable<IProductManagerProduct> {
    return this.http.get<IProductManagerProduct>(this.constructFullUrl(`api/v2/products/${asin}`));
  }

  addForeignProducts(asins: PastedAsin[]) {
    const asinIds = asins.map((asin: PastedAsin) => asin.value);

    return this.http.post(this.constructFullUrl(`api/v2/products`), { asins: asinIds });
  }

  getVariations(asin: string, limit: number, page: number): Observable<IProductManagerVariationsResponse> {
    const params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()})
      .set('page', page.toString())
      .set('limit', limit.toString());

    return this.http.get<IProductManagerVariationsResponse>(this.constructFullUrl(`api/v2/products/${asin}/variations`), { params });
  }

  getGraphData(asin: string, types: any, startDate?: string, endDate?: string): Observable<IProductManagerGraphDataResponse> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()})
      .append('types[]', types);

    if (startDate) {
      params = params.set('start', startDate);
    }

    if (endDate) {
      params = params.set('end', endDate);
    }

    return this.http.get<IProductManagerGraphDataResponse>(this.constructFullUrl(`api/v2/products/${asin}/data-points`), { params });
  }

  addProductToCompetitorList(productAsin: string, asins: string[]): Observable<{data: IAddedCompetitorProduct}[]> {
    let requests = [];

    asins.forEach((asin: string) => {
      requests = [
        ...requests,
        this.http.post<{ data: IAddedCompetitorProduct }>(
          this.constructFullUrl(`api/v2/products/${productAsin}/competition/products`), {asin}
        )
          .pipe(
            catchError((err: HttpErrorResponse) => {
              if (err.status === 409)  {
                return observableOf({data: {error: err.error.error}});
              }

              return observableThrowError(err);
            }),
          )
      ];
    });

    return forkJoin<{data: IAddedCompetitorProduct}>(requests);
  }

  removeProductFromCompetitorList(productAsin: string, asins: string[]): Observable<any> {
    return this.http.delete<any>(this.constructFullUrl(`api/v2/products/${productAsin}/competition/products/${asins[0]}`));
  }

  getCompetitorsProducts(asin: string): Observable<{data: IProductManagerVariation[]}> {
    return this.http.get<{data: IProductManagerVariation[]}>(this.constructFullUrl(`api/v2/products/${asin}/competition/products`));
  }

  getProductDraftAndListings(asin: string): Observable<IProductManagerDraftListingResponse> {
    return this.http.get<IProductManagerDraftListingResponse>(this.constructFullUrl(`api/v2/products/${asin}/listings`));
  }

  saveDraft(asin: string, draft: IProductManagerDraft): Observable<IProductManagerDraft> {
    return this.http.put<IProductManagerDraft>(this.constructFullUrl(`api/v2/products/${asin}/listings/draft`), {
      ...draft
    }).pipe(catchError(handleError));
  }

  publishDraft(asin: string, draft: IProductManagerDraft): Observable<IProductManagerDraft> {
    return this.http.post<IProductManagerDraft>(this.constructFullUrl(`api/v2/products/${asin}/listings/publish`), {
      ...draft
    });
  }

  getKeywords(asin: string): Observable<IProductManagerKeywordsResponse> {
    return this.http.get<IProductManagerKeywordsResponse>(this.constructFullUrl(`api/v2/products/${asin}/keywords`));
  }

  updateKeywords(asin: string, keywords: IProductManagerUpdateKeywordsPayload[]): Observable<any> {
    const maxKeywordsPerRequest = 500;
    const numberOfRequests = Math.ceil(keywords.length / maxKeywordsPerRequest);
    const requests = [];

    let updateKeywords: IProductManagerUpdateKeywordsPayload[];

    for (var i = 0; i < numberOfRequests; i++) {
      updateKeywords = keywords.slice(i * maxKeywordsPerRequest, (i + 1) * maxKeywordsPerRequest);
      requests.push(this.http.put<any>(this.constructFullUrl(`api/v2/products/${asin}/keywords/priority`), { list: keywords }));
    }

    return forkJoin(requests).pipe(catchError(handleError));
  }

  addKeywords(asin: string, keywords: string[], minimumPriorityLimit?: string): Observable<any> {
    const maxKeywordsPerRequest = 500;
    const numberOfRequests = Math.ceil(keywords.length / maxKeywordsPerRequest);
    const requests = [];

    let postObject: { keywords?: string[], minimum_priority_limit?: string };

    if (minimumPriorityLimit) {
      postObject = {
        minimum_priority_limit: minimumPriorityLimit,
      };
    }

    for (var i = 0; i < numberOfRequests; i++) {
      postObject = {
        ...postObject,
        keywords: keywords.slice(i * maxKeywordsPerRequest, (i + 1) * maxKeywordsPerRequest),
      };

      requests.push(this.http.post<any>(this.constructFullUrl(`api/v2/products/${asin}/keywords`), postObject));
    }

    return forkJoin(requests).pipe(catchError(handleError));
  }

  archiveProducts(asins: string[]): Observable<any> {
    return this.http.post<any>(this.constructFullUrl(`api/v2/products/archive`), { asins });
  }

  resetProducts(asin: string, reason: string): Observable<any> {
    return this.http.put<any>(this.constructFullUrl(`api/v2/products/${asin}/reset`), { reset_reason: reason });
  }

  analyzeKeywords(asins: string[] = [], addToAsin: string): Observable<any> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()})
      .set('add-to-asin', addToAsin);

    asins.slice(0, 10)
      .forEach((asin: string) => {
        params = params.append('asins[]', asin);
      });

    return this.http.get(this.constructFullUrl(`api/v2/competition-research/keyword-search`), { params });
  }

  copyKeywords(asin: string, asins: string[]): Observable<any> {
    return this.http.post(this.constructFullUrl(`api/v2/products/${asin}/keywords/copy`), { asins });
  }

  deleteKeywords(asin: string, keywords: string[]): Observable<any> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()});
    keywords.forEach((keyword: string) => {
      params = params.append('keywords[]', keyword);
    });

    return this.http.delete(this.constructFullUrl(`api/v2/products/${asin}/keywords`), {params});
  }

  recalculatePriority(asin: string, keywords: string[]): Observable<any> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()});
    keywords.forEach((keyword: string) => {
      params = params.append('keywords[]', keyword);
    });

    return this.http.put(this.constructFullUrl(`api/v2/products/${asin}/keywords/recalculate-priority`), { params });
  }

  refreshIndex(asin: string, keywords: string[]) {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()});
    keywords.forEach((keyword: string) => {
      params = params.append('keywords[]', keyword);
    });

    return this.http.put(this.constructFullUrl(`api/v2/products/${asin}/index-check`), { params });
  }

  getKeywordGraphData(asin: string, keyword: string): Observable<IProductManagerKeywordGraphResponse> {
    return this.http.get<IProductManagerKeywordGraphResponse>(this.constructFullUrl(`api/v2/products/${asin}/keywords/history/${keyword}`));
  }

  setStatus(asin: string, status: string): Observable<any> {
    return this.http.put<any>(this.constructFullUrl(`api/v2/products/${asin}`), {
      action: 'status_change',
      status,
    });
  }

  setTags(asin: string, tags: string[]): Observable<any> {
    return this.http.put<any>(this.constructFullUrl(`api/v2/products/${asin}`), {
      action: 'add_tags',
      tags,
    });
  }

  removeTags(asin: string, tags: string[]): Observable<any> {
    return this.http.put<any>(this.constructFullUrl(`api/v2/products/${asin}`), {
      action: 'remove_tags',
      tags,
    });
  }

  correctKeyword(asin: string, keyword: string): Observable<any> {
    return this.http.post(this.constructFullUrl(`api/v2/products/${asin}/keywords/correct`), { keyword });
  }

  syncProducts(): Observable<any> {
    return this.http.put(this.constructFullUrl(`api/v2/products/sync`), {});
  }

  getBrandAnalytics(keyword: string): Observable<any> {
    return this.http.get(this.constructFullUrl(`api/data/api/v1/keywords/${keyword}/brand-analytics`));
  }

  loadAvailableTagFilters(): Observable<any[]> {
    return this.http.get<any[]>(this.constructFullUrl('api/v2/products/tags'));
  }

  deleteProduct(productAsin): Observable<any[]> {
    return this.http.delete<any[]>(this.constructFullUrl(`api/v2/products/${productAsin}`));
  }
}
