import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { Collection } from "@app/shared/data/base.models";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { SystemStats } from "@app/shared/data/admin/system-stats/system-stats.models";


const INITIAL_STATE: AppState = {
  data             : new Collection<SystemStats>([]),
  loading          : false,
  error            : null,
  _be_pagination   : {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage('admin-system-stats') || 50
  }
};

export function systemStatsReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == PaginationActions.getPagActionTypes(SystemStats).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-system-stats');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  return paginationReducer(SystemStats)(state, action);
}
