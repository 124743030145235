
import {takeUntil} from 'rxjs/operators';
import { AfterViewInit, EventEmitter, Input, KeyValueDiffer, OnDestroy, OnInit, Output, AfterViewChecked, Directive } from '@angular/core';
import { LegendOptions, Options } from 'highcharts';
import { ChartEvent } from '@app/shared/components/charts/models/chart.events';
import { ChartComponentInterface } from '@app/shared/components/charts/models/highchart-options.model';
import { HighchartsService } from '@app/shared/components/charts/highcharts.service';
import { BehaviorSubject ,  Subject } from 'rxjs';
import {HostListener} from '@angular/core';
@Directive()
export abstract class BaseChartComponent implements ChartComponentInterface,
  OnInit, AfterViewInit, OnDestroy, AfterViewChecked {

  @Output() click = new EventEmitter<Event>();
  @Output() create = new EventEmitter<any>();

  @Output() addSeries = new EventEmitter<ChartEvent>();
  @Output() afterPrint = new EventEmitter<ChartEvent>();
  @Output() beforePrint = new EventEmitter<ChartEvent>();
  @Output() drilldown = new EventEmitter<ChartEvent>();
  @Output() drillup = new EventEmitter<ChartEvent>();
  @Output() load = new EventEmitter<ChartEvent>();
  @Output() redraw = new EventEmitter<ChartEvent>();
  @Output() selection = new EventEmitter<ChartEvent>();
  @Input() loading$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  protected _highchartsService: HighchartsService;
  currentWidth: number;
  currentHeight: number;
  differ: KeyValueDiffer<any, any>;

  private onDestroy$ = new Subject();

  ngAfterViewChecked(): void {
    if (this.currentWidth !== this._highchartsService.elementRef.nativeElement.offsetWidth) {
      this._highchartsService.reflow();
      this.currentWidth = this._highchartsService.elementRef.nativeElement.offsetWidth;
    }
    if (this.currentHeight !== this._highchartsService.elementRef.nativeElement.offsetHeight) {
      this._highchartsService.reflow();
      this.currentHeight = this._highchartsService.elementRef.nativeElement.offsetHeight;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let x = document.getElementById("offset-reference");
    if (x != null) {
      let width = x.offsetWidth-57;
      this._highchartsService.setSize(width,400);
      this._highchartsService.reflow();
      this.currentWidth = width;
    }
  }
  @Input()
  set type(type: string) {
    this._highchartsService.setType(type);
  }

  @Input()
  set title(title: string) {
    this._highchartsService.setTitle(title);
  }

  @Input()
  set options(opts: Options) {
    this._highchartsService.setOptions(opts);
  }

  @Input()
  set legend(legend: LegendOptions) {
    this._highchartsService.setOptions({legend});
  }

  ngOnInit(): void {
    this._highchartsService._relatedComponent = this._getRelatedComponent();
    this._highchartsService.listenChartEvents();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewInit() {
    this._highchartsService.createChart();
    this._highchartsService.reflow();
    this.create.emit(this._highchartsService.chart);

    this.loading$.pipe(takeUntil(this.onDestroy$)).subscribe(isLoading => {
      if (isLoading) {
        this._highchartsService.startLoading();
      } else {
        this._highchartsService.stopLoading();
      }
    });
  }

  protected abstract _getRelatedComponent(): ChartComponentInterface;

  public getHighChart() {
    return this._highchartsService.chart;
  }
}
