import { NgRedux } from '@angular-redux/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { AppState } from '@app/shared/data/app-state.model';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import {
  IProductManagerKeyword,
  IProductManagerKeywordSFRProduct,
  IProductManagerProduct,
  IProductManagerState,
  IProductManagerUpdateKeywordsPayload
} from '@app/shared/data/product-manager/product-manager.models';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { KeywordPipe } from '@app/shared/pipes/keyword-manager-pipe';
import { SearchPipeV2 } from '@app/shared/pipes/search-v2.pipe';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { IFilter, OperatorEnum, OperatorTranslator } from '../../common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { ProductManagerAddKeywordModalComponent } from '../product-manager-add-keyword-modal/product-manager-add-keyword-modal.component';
import { ProductManagerService } from '../product-manager.service';
import { productManagerKeywordManagerFilters } from './product-manager-keyword-manager.filters';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { AuthService } from '@app/core/services/auth.service';
import { Credential } from '@app/shared/data/credential/credential.models';
import { SortByEnum, V2TableColumn, V2TableColumnType, V2TableDataItem, V2TableDataItemType } from '../../table/table.models';

export enum SortByKeywordEnum {
  ORGANIC_RANK = 'rank',
  RANKED_RESULTS = 'number_of_competitors',
  SFR = 'sfr_rank',
  VOLUME = 'impressions',
  SALES = 'sales',
  SALES_RATE = 'sales_rate',
  CLICK_DOMINANCE = 'click_dominance',
  SALES_DOMINANCE = 'search_dominance',
  PRIORITY = 'priority',
  INDEXED = 'indexed',
  KEYWORD = 'keyword',
  MIN_RANK = 'min_rank',
  SEARCH_VOLUME = 'search_volume',
  NO_OF_COMPETITORS = 'results_count',
  SPONSORED_DENSTITY = 'density_score_sponsored',
  REPORT_CARD_DENSTITY = 'density_score_report_card',
  RANK_DENSITY = 'density_score',
  KW_FOUND_BRAND_ANALYTICS = 'in_brand_analytics',
  KW_FOUND_REPORT_CARD = 'in_report_card',
  IS_SPONSORED = 'is_sponsored',

  // oep data
  CLICK_SHARE = 'click_share',
  CLICK_SHARE_T360 = 'click_share_t360',
  NICHE_TITLE = 'niche_title',
  SEARCH_CONVERSION_RATE = 'search_conversion_rate',
  SEARCH_VOLUME_CONVERSION_RATE_T360 = 'search_volume_conversion_rate_t360',
  SEARCH_VOLUME_GROWTH_T360_YOY = 'search_volume_growth_t360_yoy',
  SEARCH_VOLUME_QOQ = 'search_volume_qoq',
  SEARCH_VOLUME_T360 = 'search_volume_t360',
  SEARCH_VOLUME_T90 = 'search_volume_t90',
  SEARCH_VOLUME_YOY = 'search_volume_yoy',
}

@Component({
  templateUrl: './product-manager-keyword-manager.component.html',
  styleUrls: ['./product-manager-keyword-manager.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProductManagerKeywordManagerComponent implements OnInit {
  keywords: IProductManagerKeyword[];

  currentPage = 1;
  perPage = this.productManagerService.perPage;
  perPageOptions = this.productManagerService.perPageOptions;
  preSelectedOption = this.productManagerService.preSelectedOption;
  initDone: boolean;

  currentKeyword: any;

  sortByEnum = SortByKeywordEnum;
  currentSortBy: SortByKeywordEnum;
  currentSortDirection: 'ASC' | 'DESC';

  loadingKeywords: boolean;
  updatingKeywords: boolean;

  productAsin: string;

  progressWrapperActive = false;
  activeProduct: IProductManagerProduct;

  progress = 0;
  progressInterval: any;
  query: string;

  availableFilters = productManagerKeywordManagerFilters;
  appliedFilters: IFilter[] = [];

  keywordGraph: {
    rank: number[][],
    impressions: number[][],
  };

  loadingGraphData: boolean;
  timerAnalyzingCompetitors: number;

  selectedFilters: IFilter[] = [];

  activePriorityFilter: 'VL'|'L'|'M'|'H';

  sfrData: IProductManagerKeywordSFRProduct[] = [];

  upgradePlanBannerVisible: boolean;
  upgradePlanBannerTitle: string;
  upgradePlanBannerMessage: string;

  private destroy$: Subject<void> = new Subject();
  activeCredential: Credential;

  tableColumns: V2TableColumn[];
  tableData: V2TableDataItem[];

  constructor(
    private productManagerService: ProductManagerService,
    private productManagerActions: ProductManagerActions,
    private route: ActivatedRoute,
    private ngRedux: NgRedux<AppState>,
    private i18n: I18n,
    private cd: ChangeDetectorRef,
    private modalService: ModalService,
    private keywordPipe: KeywordPipe,
    private searchV2Pipe: SearchPipeV2,
    private rudderTracking: RudderTrackingService,
    private authService: AuthService,
    public operatorTranslator: OperatorTranslator,
  ) {
  }

  ngOnInit() {
    this.productAsin = this.route.snapshot.params.asin;

    this.setupTableColumns();

    this.productManagerService.setActiveStep(3);
    this.ngRedux.dispatch(this.productManagerActions.getKeywords(this.productAsin));
    this.productManagerService.setNextButtonEnabled(true);

    this.ngRedux.select('product_manager')
      .pipe(
        filter((productManagerState: IProductManagerState) => !productManagerState.activeProduct),
        take(1),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        // Fetch data for a product only if product was not set on 1st step
        this.ngRedux.dispatch(this.productManagerActions.getSingleProduct(this.productAsin));
      });

    this.ngRedux.select('product_manager')
      .pipe(takeUntil(this.destroy$))
      .subscribe((productManagerState: IProductManagerState) => {
        this.loadingKeywords = productManagerState.loadingKeywords;
        this.keywords = productManagerState.keywords.phrases;
        this.activeProduct = productManagerState.activeProduct;
        this.updatingKeywords = productManagerState.updatingKeywordsPriority;
        this.progressWrapperActive = productManagerState.loadingKeywordsWS;
        this.progress = productManagerState.keywords.progress;
        this.keywordGraph = {
          rank: productManagerState.keywordGraph.rank,
          impressions: productManagerState.keywordGraph.impressions,
        };
        this.loadingGraphData = productManagerState.loadingKeywordsGraph;
        this.upgradePlanBannerVisible = productManagerState.upgradePlanBannerVisible;
        this.upgradePlanBannerTitle = productManagerState.upgradePlanBannerTitle;
        this.upgradePlanBannerMessage = productManagerState.upgradePlanBannerMessage;

        if (productManagerState.keywords.sfrGraphData) {
          this.sfrData = productManagerState.keywords.sfrGraphData;
        }

        if (this.progress === 0) {
          this.timerAnalyzingCompetitors = performance.now();
        } else if (this.progress === 100 && this.timerAnalyzingCompetitors !== 0) {
          this.rudderTracking.trackEvent('Keyword Manager - Analyzing Competitors', {
            time: Math.trunc(performance.now() - this.timerAnalyzingCompetitors)
          });
        }

        this.tableData = this.keywords && this.keywords.map((k: IProductManagerKeyword) => {
          return {
            ...k,
            graphOpened: k.graphOpened || false,
            loadingGraphData: this.loadingGraphData,
            columns: [
              {
                type: V2TableDataItemType.KEYWORD_LABEL,
                value: {
                  value: k.value,
                  value_corrected: k.value_corrected,
                  asin: this.activeProduct ? this.activeProduct.asin : null,
                },
                isFixed: true,
              },
              {
                type: V2TableDataItemType.PRIORITY,
                value: {
                  priority: k.priority,
                  loading: this.updatingKeywords,
                },
                isFixed: true,
              },
              {
                type: V2TableDataItemType.RANK,
                value: {
                  rank: k.rank,
                  rank_change: k.rank_change,
                },
                onClick: () => {
                  this.toggleKeywordDetails({event: event as MouseEvent, item: k})
                },
              },
              {
                type: V2TableDataItemType.DEFAULT,
                value: k.impressions,
                onClick: () => {
                  this.toggleKeywordDetails({event: event as MouseEvent, item: k})
                }
              },
              {
                type: V2TableDataItemType.SFR,
                value: {
                  sfr_rank: k.sfr_rank,
                  sfrGraphLoading: k.sfrGraphLoading,
                  sfrGraphOpened: k.sfrGraphOpened,
                  products: this.sfrData,
                }
              },
              {
                type: V2TableDataItemType.DEFAULT,
                value: k.sales_rate,
                isPercentage: true,
                onClick: () => {
                  this.toggleKeywordDetails({event: event as MouseEvent, item: k})
                }
              },
              {
                type: V2TableDataItemType.DEFAULT,
                value: k.sales,
                onClick: () => {
                  this.toggleKeywordDetails({event: event as MouseEvent, item: k})
                }
              },
              {
                type: V2TableDataItemType.DEFAULT,
                value: k.number_of_competitors,
                onClick: () => {
                  this.toggleKeywordDetails({event: event as MouseEvent, item: k})
                }
              },
              {
                type: V2TableDataItemType.DEFAULT,
                value: k.click_dominance,
                isPercentage: true,
                onClick: () => {
                  this.toggleKeywordDetails({event: event as MouseEvent, item: k})
                }
              },
              {
                type: V2TableDataItemType.DEFAULT,
                value: k.search_dominance,
                isPercentage: true,
                onClick: () => {
                  this.toggleKeywordDetails({event: event as MouseEvent, item: k})
                }
              },
              {
                type: V2TableDataItemType.INDEXED,
                value: {
                  indexed: k.indexed,
                  indexed_date: k.indexed_date,
                },
              },
              {
                type: V2TableDataItemType.GRAPH_DATA,
                value: {
                  keywordGraph: this.keywordGraph,
                }
              }
            ]
          }
        })

        if (this.canDetectChanges) {
          this.cd.markForCheck();
        }
      });

    this.authService.activeCredentials$().pipe(takeUntil(this.destroy$))
      .subscribe((credential: Credential) => {
        this.activeCredential = credential;
      });
  }

  setupTableColumns() {
    this.tableColumns = [
      {
        type: V2TableColumnType.STRING,
        title: 'Keyword',
        isFixed: true,
        hideSort: true,
        tooltip: 'Keyword is displayed in exact search term format. Please pay attention to slight misspellings and pluralization\'s since they visually seem like the best keyword but could have a major impact on exact search volume.',
      },
      {
        type: V2TableColumnType.PRIORITY,
        title: 'Priority',
        hideTitle: true,
        isFixed: true,
        sortBy: SortByEnum.PRIORITY
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Organic Rank',
        tooltip: 'Organic Rank is the organic search position for the exact search term. Positions are updated Daily for High, Medium & Low priority keywords, Very Low rated keywords do not display organic search position due to their low priority nature. If there is a search term you feel should be organically tracked, please change the Very Low Priority to Low, Medium or High Priority and the organic rank position will be updated on next organic keyword rank check.',
        sortBy: SortByEnum.ORGANIC_RANK
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Search Volume',
        tooltip: 'Searched Volume is displayed in monthly exact searches and is updated daily.',
        sortBy: SortByEnum.VOLUME
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Search Frequency Rank',
        // tooltip: 'Purchase Rate is the conversation rate for a given search term. The higher the percentage the more often a customer search turns into a sale. If the Purchase Rate is low then you can assume that this search term is used more often for browsing or educating purposes. If the Purchase Rate is a high percentage then you can assume that the customer knows exactly what they intend to purchase and are looking for the best product for the given search term. Example, a customer searches for \'Argan Oil\', many different types of products are shown for various purpose such as for the hair, the skin or even as a face wash. The customer did not locate what they were looking for thus the Purchase Rate is under 2%. The customer changes their search term to \'Argan Oil For Hair\' and finds exactly what they were looking for and the Purchase Rate would be much higher.',
        sortBy: SortByEnum.SFR
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Purchase Rate',
        tooltip: `Purchase Rate is the conversation rate for a given search term. The higher the percentage the more often a customer search turns into a sale. If the Purchase Rate is low then you can assume that this search term is used more often for browsing or educating purposes. If the Purchase Rate is a high percentage then you can assume that the customer knows exactly what they intend to purchase and are looking for the best product for the given search term. Example, a customer searches for 'Argan Oil', many different types of products are shown for various purpose such as for the hair, the skin or even as a face wash. The customer did not locate what they were looking for thus the Purchase Rate is under 2%. The customer changes their search term to 'Argan Oil For Hair' and finds exactly what they were looking for and the Purchase Rate would be much higher.`,
        sortBy: SortByEnum.SALES_RATE
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Unit Sales',
        tooltip: 'Unit Sales are merely a calculations based upon Search Volume times Purchase Rate. Sorting by Units Sales may help you pinpoint highly valuable search terms to potentially increase their Priority.',
        sortBy: SortByEnum.SALES
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Total No. of Competitors',
        tooltip: 'Total Number of Competitors are competing products whose products are optimized and are shown for the selected Search Term. Pay attention to search terms which only show products located in proper categories. This will help you determine the best category for your product based upon the relevance of the search term to the category itself.',
        sortBy: SortByEnum.RANKED_RESULTS
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Top 3 Click Dominance',
        tooltip: 'Top 3 Click Dominance is a calculation based upon the Top 3 Competitors for the given search terms and how many Search Term Clicks they receive. The higher the Click Dominance the more of a monopoly of traffic the Top 3 Competitors are receiving and a customer the customer is choosing one of these Top 3 Competitors over all other listings showing up for the Search Term. Conversly, a low Click Dominance shows us that a customer is skipping over the Top 3 Competitors and choosing other products in the Search Results. Hint. This could be a new product opportunity, look to understand why the customer is choosing a different product, this could be a poorly rated, priced or optimized product for the Search Keyword.',
        sortBy: SortByEnum.CLICK_DOMINANCE
      },
      {
        type: V2TableColumnType.STRING,
        title: 'Top 3 Sales Dominance',
        tooltip: 'Top 3 Sales Shares is a calculation based upon the Top 3 Competitors for the given search term and how many Sales (conversions) they receive. The higher the Sales Dominance the more of a monopoly of sales (conversations) the Top 3 Competitors are receiving. Hint. A very large 80%+ Sales share is an indication that the customer is finding the exact product relative to the search term and may be difficult to rank.',
        sortBy: SortByEnum.SALES_DOMINANCE
      },
      {
        type: V2TableColumnType.REFRESH,
        title: 'Indexed',
        loading: this.isIndexCheckInProgress
      }
    ]
  }

  onPriorityChange(event: { priority: 'VL'|'L'|'M'|'H', item: IProductManagerKeyword}) {
    if (this.updatingKeywords || this.progressWrapperActive) {
      return;
    }

    const keywords: IProductManagerUpdateKeywordsPayload[] = [
      {
        keyword: event.item.value,
        priority: event.priority,
      },
    ];

    this.rudderTracking.trackEvent('Keyword Manager - Priority updated', {
      keyword: event.item.value,
      priority: event.priority
    });

    this.ngRedux.dispatch(this.productManagerActions.updateKeyword(this.productAsin, keywords));
  }

  closeProgressWrapper() {
    this.progressWrapperActive = false;
  }

  goToFirstPage() {
    this.currentPage = 1;
  }

  goToLastPage() {
    this.currentPage = this.lastPage;
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.currentPage--;
    }
  }

  nextPage() {
    if (this.currentPage < this.pagesArray.length) {
      this.currentPage++;
    }
  }

  changePage(page: number) {
    this.currentPage = page;
  }

  openKeywordsModal() {
    this.modalService.open(ProductManagerAddKeywordModalComponent, {
      asin: this.productAsin
    });
  }

  getBrandAnalytics(item: V2TableDataItem) {
    if (item.graphOpened || item.loadingGraphData) {
      return;
    }

    this.ngRedux.dispatch(this.productManagerActions.getKeywordBrandAnalytics(item.value));
  }

  sortBy(sort: any) {
    this.currentPage = 1;
    this.currentSortBy = sort;
    this.currentSortDirection = this.currentSortDirection === 'ASC' ? 'DESC' : 'ASC';
  }

  filtersApplied(filters: IFilter[]) {
    this.currentPage = 1;
    this.appliedFilters = filters;
    this.rudderTracking.trackEvent('Keyword Manager - Advanced filters applied', {
      count: filters.length
    });
  }

  removeFilter(filter: IFilter) {
    let filters = this.appliedFilters.filter((internalFilter: IFilter) => internalFilter.title !== filter.title || internalFilter.value !== filter.value);
    this.filtersApplied(filters);
  }

  pageChange(event: IDropdownOption) {
    this.currentPage = 1;
    this.perPage = event.id as number;
    this.rudderTracking.trackEvent('Keyword Manager - Changed items per page', {
      count: this.perPage
    });
  }

  keywordIndex() {
    if (!this.isIndexCheckInProgress) {
      this.rudderTracking.trackEvent('Keyword Manager - Index refreshed');
      this.ngRedux.dispatch(this.productManagerActions.refreshIndex(this.productAsin, this.checkedKeywords.map((kw: IProductManagerKeyword) => kw.value)));
    }
  }

  toggleKeywordDetails(event: { event: MouseEvent, item: IProductManagerKeyword }) {
    this.preventDefault(event.event);

    this.ngRedux.dispatch(this.productManagerActions.getKeywordGraphData(this.activeProduct.asin, event.item.value));
  }

  preventDefault(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  correctKeyword(event: MouseEvent, keyword: IProductManagerKeyword) {
    this.preventDefault(event);

    if (this.updatingKeywords || keyword.correctingKeyword) {
      return;
    }

    this.ngRedux.dispatch(this.productManagerActions.correctKeyword(this.activeProduct.asin, keyword.value, keyword.value_corrected));
  }

  filterByPriority(priority: 'VL'|'L'|'M'|'H') {
    if (this.progressWrapperActive) {
      return;
    }

    const statusMapper = {
      'VL': this.i18n('Very Low'),
      'L': this.i18n('Low'),
      'M': this.i18n('Medium'),
      'H': this.i18n('High'),
    };

    if (this.activePriorityFilter === priority) {
      this.activePriorityFilter = null;
      this.selectedFilters = [];
    } else {
      this.activePriorityFilter = priority;
      this.selectedFilters = [
        {
          title: 'Priority',
          field: 'priority',
          operator: OperatorEnum.CONTAINS,
          value: [priority],
          valueAsAString: statusMapper[priority],
        }
      ];
    }

    // Remove any current priority filters
    const newFilters = [
      ...this.appliedFilters.filter((filter: IFilter) => filter.field !== 'priority'),
      ...this.selectedFilters,
    ];

    this.filtersApplied(newFilters);
  }

  getAmazonLink(item: IProductManagerKeyword): string {
    if (!this.activeCredential) {
      return '';
    }

    return `https://www.${this.activeCredential.amazon_domain}/s/?field-keywords=${item.value}`;
  }

  recalculate() {
    this.ngRedux.dispatch(this.productManagerActions.recalculatePriority(this.productAsin, this.getKeywordsValues()))
    this.rudderTracking.trackEvent('Keyword Manager - Recalculate Keywords');
  }

  private getKeywordsValues(): string[] {
    return this.checkedKeywords.map((keyword: IProductManagerKeyword) => keyword.value);
  }

  get currentDisplayedProducts(): string {
    return this.i18n(`Showing ${this.from} - ${this.to} of ${this.total}`);
  }

  get progressTitle(): string {
    if (this.progress < 33) {
      return this.i18n('Analyzing Competitors’ Keywords');
    } else if (this.progress >= 33 && this.progress < 66) {
      return this.i18n('Ranking Keywords');
    } else if (this.progress >= 66 && this.progress < 100) {
      return this.i18n('Getting Keyword Details');
    } else {
      return this.i18n('New keywords found');
    }
  }

  get isIndexCheckInProgress() {
    return !this.activeProduct || (this.activeProduct.index_check_at !== null && this.activeProduct.index_check_finished_at == null);
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }

  get areAllKeywordsChecked(): boolean {
    return this.filteredKeywords && this.filteredKeywords.length > 0 && this.filteredKeywords.every((keyword: IProductManagerKeyword) => keyword.checked);
  }

  get checkedKeywords(): IProductManagerKeyword[] {
    return this.filteredKeywords.filter((keyword: IProductManagerKeyword) => keyword.checked);
  }

  get numberOfPages(): number {
    return Math.ceil(this.total / this.perPage) || 0;
  }

  get pagesArray(): number[] {
    return Array(this.numberOfPages).fill(0).map((_x, i) => i + 1);
  }

  get lastPage(): number {
    return this.numberOfPages || 0;
  }

  get from(): number {
    return this.total === 0 ?
      this.total :
      this.currentPage === 1
        ? this.currentPage
        : ((this.currentPage - 1) * this.perPage + 1);
  }

  get to(): number {
    return this.total < this.perPage ?
      this.total :
      (this.currentPage * this.perPage > this.total) ?
        this.total :
        this.currentPage * this.perPage;
  }

  get total(): number {
    return this.filteredKeywords.length || 0;
  }

  get filteredKeywords(): IProductManagerKeyword[] {
    return this.searchV2Pipe.transform(
      this.keywordPipe.transform(this.keywords, this.appliedFilters),
      this.query,
    );
  }

  get isFilterActive(): boolean {
    return Boolean(this.activePriorityFilter);
  }
}
