import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { WalletActions } from './wallet.actions';
import { WalletApi } from './wallet.api';
import { Wallet } from './wallet.models';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class WalletEpics {
  constructor(
    private i18n: I18n,
    private walletApi: WalletApi,
    protected toastService: ToastService,
    private walletActions: WalletActions) {
  }

  public createEpic() {
    return [
      this.load,
    ];
  }

  load = (store: NgRedux<AppState>) => (next: (action: StAction) => AppState) => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === WalletActions.TYPES.LOAD) {
        this.walletApi.get()
          .subscribe(
            (wallet: Wallet) => {
              store.dispatch(this.walletActions.loadSucceeded(wallet));
            },
            (response) => {
              store.dispatch(this.walletActions.loadFailed(response.error));
            },
          );
      }

      return n;
    };
  }
}
