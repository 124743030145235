
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { BaseAPI } from "@app/shared/data/base.api";
import { Subscription } from "@app/shared/data/subscription/subscription.models";
import { Plan } from "@app/shared/data/plan/plan.models";
import { Coupon } from "@app/shared/data/coupon/coupon.models";

@Injectable()
export class SubscriptionAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(): Observable<Collection<Subscription>> {
    return this.http.get(this.constructFullUrl('api/billing/subscriptions')).pipe(
      map(data => Subscription.collect<Subscription>(Object.values(data).map(d => SubscriptionAPI.transformFullSubscription(d)))));
  }

  public create(plan: Plan, coupon: Coupon = null): Observable<any> {
    return this.http.post(this.constructFullUrl('api/billing/subscriptions'), {
      plan_id: plan.id,
      coupon : coupon ? coupon.code : null
    }).pipe(
      map(data => Subscription.collect<Subscription>(Object.values(data).map(d => SubscriptionAPI.transformFullSubscription(d)))));
  }

  public cancel(subscription: Subscription): Observable<any> {
    return this.http.delete(this.constructFullUrl('api/billing/subscriptions/' + subscription.id)).pipe(
      map(data => new Subscription(SubscriptionAPI.transformFullSubscription(data))));
  }

  private static transformFullSubscription(s) {
    if (s.couponObj) {
      s.couponObj = new Coupon(s.couponObj);
    }

    return s;
  }
}
