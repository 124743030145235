
import {takeUntil} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { SubscriptionAPI } from '@app/shared/data/subscription/subscription.api';
import { Subscription } from '@app/shared/data/subscription/subscription.models';
import { Collection } from '@app/shared/data/base.models';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { getTranslationFromResponse } from '@app/helpers';
import { Subject } from 'rxjs';
@Injectable()
export class SubscriptionEpics {
  private loader$: Subject<any> = new Subject<any>();

  constructor(private subscriptionActions: SubscriptionActions,
    private dialogService: DialogService,
    private subscriptionAPI: SubscriptionAPI,
    private ngRedux: NgRedux<AppState>,
    private i18n: I18n) {
  }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.load,
      this.create,
      this.cancel,
    ];
  }

  load = store => next => {
    return (action) => {
      if (action.type === SubscriptionActions.TYPES.LOAD || action.type === 'billable_status_changed') {
        this.loader$.next();

        this.subscriptionAPI.all().pipe(
          takeUntil(this.loader$))
          .subscribe(
            (subscriptions: Collection<Subscription>) => {
              this.ngRedux.dispatch(this.subscriptionActions.loadSucceeded(subscriptions));
            },
            (response) => {
              this.ngRedux.dispatch(this.subscriptionActions.loadFailed(response));
            },
          );
      }
      return next(action);
    };
  };

  create = store => next => {
    return (action) => {
      if (action.type === SubscriptionActions.TYPES.CREATE) {
        this.subscriptionAPI.create(action.data.plan, action.data.coupon)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.subscriptionActions.createSucceeded(response));
            },
            (response) => {
              // this.dialogService.info('Sorry.', response.error.error.message);
              this.ngRedux.dispatch(this.subscriptionActions.createFailed(response.error.error));
            },
            () => {

            },
          );
      }
      return next(action);
    };
  };

  cancel = store => next => {
    return (action) => {
      if (action.type === SubscriptionActions.TYPES.CANCEL) {
        this.subscriptionAPI.cancel(action.data['subscription'])
          .subscribe(
            (subscription: Subscription) => {
              this.ngRedux.dispatch(this.subscriptionActions.cancelSucceeded(subscription));
            },
            (response) => {
              this.dialogService.info(
                this.i18n('Sorry.'),
                getTranslationFromResponse([
                  {
                    code: 'stripe.subscription_does_not_exists',
                    translation: this.i18n('Subscription does not exists or has already been canceled.')
                  }
                ],
                  response,
                  this.i18n('Something went wrong while cancelling subscription')
                )
              );

              this.ngRedux.dispatch(this.subscriptionActions.cancelFailed(
                this.i18n('Subscription does not exists or has already been canceled.')
              ));
            },
          );
      }
      return next(action);
    };
  };
}
