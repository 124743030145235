import { Component } from "@angular/core";

@Component({
  selector: 'st-error',
  template: `<st-icon icon="error_outline"></st-icon><ng-content></ng-content>`,
  styles  : [
    `:host[hidden] {display: none}`,
    `:host { display: flex; align-items: center; justify-content: center;padding-right: 1.2em;color: #FD603D;}`,
    `st-icon { margin-right:0.4em; }`,
  ]
})
export class ErrorComponent {
}
