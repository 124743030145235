import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { Notice } from "@app/shared/data/notice/notice.models";
import { Collection } from "@app/shared/data/base.models";


@Injectable()
export class NoticeActions {

  static readonly _events = {
    notice_created: 'system_notice_created',
    notice_updated: 'system_notice_updated',
    notice_deleted: 'system_notice_deleted',
  };

  static readonly TYPES = {
    SET_NOTICES_HEIGHT: '[NOTICE]SET_NOTICES_HEIGHT',

    LOAD_SYSTEM_NOTICES        : '[SYSTEM_NOTICE]LOAD_NOTICES',
    LOAD_SYSTEM_NOTICES_SUCCESS: '[SYSTEM_NOTICE]LOAD_NOTICES_SUCCESS',
    LOAD_SYSTEM_NOTICES_FAILED : '[SYSTEM_NOTICE]LOAD_NOTICES_FAILED',
    NEW_SYSTEM_NOTICE_CREATED  : '[SYSTEM_NOTICE]NEW_NOTICE_CREATED',
    SYSTEM_NOTICE_UPDATED      : '[SYSTEM_NOTICE]NOTICE_UPDATED',
    SYSTEM_NOTICE_DELETED      : '[SYSTEM_NOTICE]NOTICE_DELETED',

    ADD_USER_NOTICE   : '[USER_NOTICE]ADD_USER_NOTICE',
    REMOVE_USER_NOTICE: '[USER_NOTICE]REMOVE_USER_NOTICE',
  };

  /**
   * @param height
   * @returns {{type: string, data: any}}
   */
  static setNoticesHeight(height): StAction {
    return {
      type: NoticeActions.TYPES.SET_NOTICES_HEIGHT,
      data: height
    };
  }

  static newNoticeCreated(notice: Notice): StAction {
    return {
      type: NoticeActions.TYPES.NEW_SYSTEM_NOTICE_CREATED,
      data: notice
    };
  }

  static loadSystemNotices(params: { active?: boolean } = {}): StAction {
    return {
      type: NoticeActions.TYPES.LOAD_SYSTEM_NOTICES,
      data: params
    };
  }

  static loadNotificationsSucceeded(notices: Collection<Notice>): StAction {
    return {
      type: NoticeActions.TYPES.LOAD_SYSTEM_NOTICES_SUCCESS,
      data: notices
    };
  }

  static loadNotificationsFailed(error): StAction {
    return {
      type: NoticeActions.TYPES.LOAD_SYSTEM_NOTICES_FAILED,
      data: error
    };
  }

  static noticeUpdated(notice: Notice): StAction {
    return {
      type: NoticeActions.TYPES.SYSTEM_NOTICE_UPDATED,
      data: notice
    }
  }

  static noticeDeleted(notice: Notice): StAction {
    return {
      type: NoticeActions.TYPES.SYSTEM_NOTICE_DELETED,
      data: notice
    }
  }

  static addUserNotice(notice: Notice): StAction {
    return {
      type: NoticeActions.TYPES.ADD_USER_NOTICE,
      data: notice
    }
  }

  static removeUserNotice(notice: Notice): StAction {
    return {
      type: NoticeActions.TYPES.REMOVE_USER_NOTICE,
      data: notice
    }
  }
}
