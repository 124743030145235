
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { PayoutCommisionAdmin } from "@app/shared/data/admin/payout-commision/payout-commision.model";

@Injectable()
export class PayoutCommisionAdminAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<PayoutCommisionAdmin>> {
    return this.http.get(this.constructFullUrl('api/admin/affiliations/payouts/commision'), { params: params }).pipe(
      map(data => PayoutCommisionAdmin.collect<PayoutCommisionAdmin>(data, PayoutCommisionAdmin.BACKEND_PAGINATION)));
  }

  public commision(params = {}): Observable<Collection<PayoutCommisionAdmin>> {
    return this.http.get(this.constructFullUrl('api/admin/affiliations/payouts/commision'), { params: params }).pipe(
      map(data => {
        return PayoutCommisionAdmin.collect<PayoutCommisionAdmin>(data, PayoutCommisionAdmin.BACKEND_PAGINATION);
      }));
  }
}
