import { PipeTransform, Injectable, Pipe } from '@angular/core';
import { ValidatorService } from '@app/core/services/validator.service';

@Pipe({
  name: 'stStringLength',
  pure: true
})
@Injectable()
export class StringLengthPipe implements PipeTransform {
  transform(value: string|number, method: StringCountMethod): number {
    if (method === 'chars') {
      return this.countCharacters(value);
    } else {
      return this.countBytes(value);
    }
  }

  countCharacters(value: string|number): number {
    let length = 0;
    if (value && typeof value === 'string') {
      length = ValidatorService.charsCount(value);
    }

    return length;
  }

  countBytes(value: string|number): number {
    let length = 0;
    if (value && typeof value === 'string') {
      length = ValidatorService.byteLength(value);
    }

    return length;
  }
}

export type StringCountMethod = 'chars'|'bytes';
