import { Component, EventEmitter, Input, Output } from "@angular/core";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-track-keyword-item',
  templateUrl: './track-keyword.item.component.html',
  styleUrls: ['./track-keyword.item.component.scss']
})
export class TrackKeywordItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() trackKeyword: EventEmitter<{
    keyword: string,
    disabled: boolean,
    tracked: boolean
  }> = new EventEmitter();
}
