
import {
    Component, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';

@Component({
    selector: 'st-simple-pager',
    templateUrl: './st-simple-pager.component.html',
    styleUrls: ['./st-pager.component.scss']
})
export class StSimplePagerComponent implements OnChanges {
    @Input()
    currentPage = 1;

    @Input()
    hasMore = false;

    @Input()
    loading = false;

    @Output()
    currentPageChange: Subject<number> = new Subject<number>();

    hasPrevious = false;

    pages = [];

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentPage || changes.hasMore) {
            this.hasPrevious = this.currentPage > 1;

            this.pages = [];

            const first = Math.max(this.currentPage - 3, 1);

            for (let i = first; i <= this.currentPage; i++) {
                this.pages.push(i);
            }

            if (this.hasMore) {
                this.pages.push(this.currentPage + 1);
            }
        }
    }
}
