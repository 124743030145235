
import {throwError as observableThrowError, timer as observableTimer, merge as observableMerge,  Observable } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ToastService } from '@app/core/services/toast.service';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { AsinCompareActions } from './asin-compare.actions';
import { Injectable } from '@angular/core';
import { AsinCompareAPI } from '@app/shared/data/asin-compare/asin-compare.api';
import { filter, flatMap, switchMapTo, takeUntil, take, map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { HttpResponse } from '@angular/common/http';
import { ProductManagerAPI } from '@app/shared/data/product-manager/product-manager.api';
import { IProductManagerVariationsResponse } from '@app/shared/data/product-manager/product-manager.models';

enum ErrorCodesEnum {
  ERROR_CODE_PRODUCT_NOT_FOUND = 'error.not_found',
  ERROR_CODE_SEARCH_TIMEOUT = 'error.search_timeout',
}

@Injectable()
export class AsinCompareEpics {

  constructor(private asinCompareActions: AsinCompareActions,
              private asinCompareApi: AsinCompareAPI,
              private productManagerApi: ProductManagerAPI,
              private ngRedux: NgRedux<AppState>,
              private toast: ToastService,
              private i18n: I18n,
              ) {
  }

  public createEpic() {
    return [
      this.compare,
      this.compareSocket,
      this.compareFailed,
      this.compareRefresh,
    ];
  }

  compare = store => next => action => {
    const nextResults = next(action);
    if (action.type === AsinCompareActions.TYPES.COMPARE) {

      observableMerge( // Retry in intervals for results
        observableTimer(0, environment.R2A_REQUEST_INTERVAL),
        observableTimer(environment.R2A_TIMEOUT).pipe( // Fail after specified timeout
          switchMapTo(observableThrowError({error: {error: {error_code: ErrorCodesEnum.ERROR_CODE_SEARCH_TIMEOUT}}}))
        )
        ).pipe(
          takeUntil(this.ngRedux.select(['asin_compare', 'loading']).pipe(filter(x => !x))),
          flatMap(() => this.asinCompareApi.requestCompare(action.data.asin, action.data.groupId))
        ).subscribe(
          (resp: HttpResponse<any>) => {
            if (resp.status !== 202) {
              this.ngRedux.dispatch(this.asinCompareActions.compareSucceeded(resp.body));
            }
          },
          (error) => {
            this.ngRedux.dispatch(this.asinCompareActions.compareFailed(error.error.error));
          },
          () => {
          },
        );

      this.productManagerApi.getVariations(action.data.asin, 100, 1)
          .pipe(
            take(1),
            map((x: IProductManagerVariationsResponse) => this.asinCompareActions.compareChildren(x.data))
          )
          .subscribe(this.ngRedux.dispatch.bind(this.ngRedux));
    }

    return nextResults;
  }

  compareSocket = (store: NgRedux<AppState>) => next => action => {
    if (action.type === AsinCompareActions.EVENTS.asin_compare_finished
      && action.data.group_id === store.getState()['asin_compare']['groupId'] + store.getState()['asin_compare']['asin']) {

      if (action.data.error !== null && Object.values(action.data.error).length > 0) {
        this.ngRedux.dispatch(this.asinCompareActions.compareFailed(action.data.error));
      } else {
        this.ngRedux.dispatch(this.asinCompareActions.compareSucceeded(action.data.results));
      }
    }
    return next(action);
  }

  compareFailed = (store: NgRedux<AppState>) => next => action => {
    if (action.type === AsinCompareActions.TYPES.COMPARE_FAILED) {
      switch (action.data.error.error_code) {
        case ErrorCodesEnum.ERROR_CODE_PRODUCT_NOT_FOUND:
          action.data.errorMessage = this.i18n('Product for comparison could not be found. This usually happens if product is no longer active.');
          break;
        case ErrorCodesEnum.ERROR_CODE_SEARCH_TIMEOUT:
          action.data.errorMessage = this.i18n('Compare timed out. Please try again later.');
          break;
        default:
          action.data.errorMessage = this.i18n('Comparison failed due to application data processing error.');
          break;
      }
    }
    return next(action);
  }

  compareRefresh = store => next => action => {
    if (action.type === AsinCompareActions.TYPES.COMPARE_REFRESH) {
      // Initiate compare refresh in background
      this.ngRedux.dispatch(this.asinCompareActions.compare(
        store.getState()['asin_compare']['asin'],
        store.getState()['asin_compare']['groupId'],
        action.data.backgroundLoading)
      );
    }

    return next(action);
  };

}
