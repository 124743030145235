import { Component, ViewChild } from '@angular/core';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';

@Component({
  selector: 'st-logo-loader-modal',
  templateUrl: './logo-loader-modal.component.html',
  styleUrls: ['./logo-loader-modal.component.scss']
})
export class LogoLoaderModalComponent {

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;
  
  loadingText: string;

  constructor() { }

  close() {
    this.modal.close();
  }
}
