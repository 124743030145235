import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalModule } from '@app/shared/components/modals/modal.module';
import { TagListComponent } from '@app/shared/components/tag-list/tag-list.component';
import { StFormsModule } from '@app/shared/layout/forms/forms.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { LayoutModule } from '@app/shared/layout/layout.module';
import { SelectModule } from '@app/shared/components/select/select.module';
import { DialogModule } from '@app/shared/components/dialog/dialog.module';
import { TabsModule } from '@app/shared/components/tabs/tabs.module';
import { ContextMenuComponent } from '@app/shared/components/contextmenu/contextmenu.component';
import { MediumEditorModule } from '@app/shared/components/editor/medium-editor.module';
import { ImgChartComponent } from '@app/shared/components/img-chart/img-chart.component';
import { PopoverModule } from '@app/shared/components/popover/popover.module';
import { MarketplaceSelectComponent } from '@app/shared/components/marketplace-select/marketplace-select.component';
import { ProgressBarModule } from '@app/shared/components/progress-bar/progress-bar.module';
import { RangeSliderModule } from '@app/shared/components/range-slider/range-slider.module';
import { RatingComponent } from './rating/rating.component';
import { PulseComponent } from './pulse/pulse.component';
import { TreeSelectComponent } from './tree-select/tree-select.component';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { ProductSelectComponent } from '@app/shared/components/product-select/product-select.component';
import { InstructionsComponent } from '@app/shared/components/instructions/instructions.component';
import { EditInputComponent } from './edit-input/edit-input.component';
import { ErrorV2Component } from './error-v2/error-v2.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { RouterLinkActiveDirective } from '../directives/router-link-active.directive';
import { LayoutV2Module } from '../layout/v2/layout-v2.module';

const COMPONENTS = [
  TagListComponent,
  ContextMenuComponent,
  ImgChartComponent,
  MarketplaceSelectComponent,
  RatingComponent,
  ErrorV2Component,
  PulseComponent,
  TreeSelectComponent,
  ProductSelectComponent,
  InstructionsComponent,
  EditInputComponent,
  QrCodeComponent,
];
const DIRECTIVES = [
  RouterLinkActiveDirective,
];

@NgModule({
  imports     : [
    CommonModule,
    LayoutModule,
    ModalModule,
    MediumEditorModule,
    DialogModule,
    TabsModule,
    PopoverModule,
    StFormsModule,
    PipesModule,
    SelectModule,
    ProgressBarModule,
    CheckboxModule,
    LayoutV2Module
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports     : [
    ModalModule,
    SelectModule,
    DialogModule,
    MediumEditorModule,
    TabsModule,
    PopoverModule,
    RangeSliderModule,
    ProgressBarModule,
    ...COMPONENTS,
    ...DIRECTIVES
  ]
})
export class ComponentsModule {
}
