import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection, PaginationCollection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { LS } from '@app/shared/services/local-storage.service';
import { BlacklistEntry, BlacklistInvitationCollection, BlacklistInvitation, BlacklistSettings } from './blacklist.models';
import { BlacklistActions } from './blacklist.actions';
import { dotVal } from '@app/functions';

export interface BlacklistFilters {
  field: string;
  source: string;
  list: string;
  query: string;
}

interface WalletBlacklistState extends AppState {
  data: PaginationCollection<BlacklistEntry>;
  invitations: BlacklistInvitationCollection;
  settings: BlacklistSettings,
  forms: {
    share: {
      fields: {
        email: string,
      }
      submitting: boolean;
      last_invite_failed: boolean;
    },
  };

  loading: boolean;
  loading_invitations: boolean;
  loading_settings: boolean;
  adding: boolean;
  saving: boolean;
  error?: any;

  _filters: BlacklistFilters;
  _be_pagination: {};
}

const INITIAL_STATE: WalletBlacklistState = {
  data: new PaginationCollection([]),
  invitations: {
    sent: new Collection(),
    received: new Collection()
  },
  settings: new BlacklistSettings(),
  forms: {
    share: {
      fields: {
        email: '',
      },
      submitting: false,
      last_invite_failed: false,
    },
  },

  loading: false,
  loading_invitations: false,
  loading_settings: false,
  adding: false,
  saving: false,
  error: null,

  _filters: loadFilters(),
  _be_pagination: {
    page: 1,
    sort: [],
    limit: 50
  }
};

function loadFilters(): BlacklistFilters {
  return <BlacklistFilters>LS.forActiveUser().getBlacklistFilters() || {
    field: '',
    source: '',
    list: '',
    query: '',
  };
}

function removeInvitation(id: number, collection: BlacklistInvitationCollection): BlacklistInvitationCollection {
  const filter = (invitation: BlacklistInvitation) => invitation.id !== id;

  collection.received = collection.received.filter(filter);
  collection.sent = collection.sent.filter(filter);

  return collection;
}

function updateInvitationsMeta(id: number, collection: BlacklistInvitationCollection, meta: any): BlacklistInvitationCollection {
  const map = (invitation: BlacklistInvitation) => {
    if (invitation.id === id) {
      return invitation.clone(BlacklistInvitation, {
        _meta: {
          ...invitation._meta,
          ...meta,
        }
      });
    }

    return invitation;
  };

  collection.received = collection.received.map(map);
  collection.sent = collection.sent.map(map);

  return collection;
}

export function walletBlacklistReducer(state: WalletBlacklistState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === PaginationActions.getPagActionTypes(BlacklistEntry).LOAD_PAGE) {
    return {
      ...state,
      _be_pagination: {
        page: action.data.pagination.page,
        sort: action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading: true
    };
  }

  if (action.type === BlacklistActions.TYPES.FILTER) {
    return {
      ...state,
      _filters: action.data,
      _be_pagination: {
        ...state['_be_pagination'],
        page: 1,
      },
      loading: true,
    };
  }

  if (action.type === BlacklistActions.TYPES.ADD_ENTRY) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['field'] && entry.value === action.data['value']) {
          return entry.clone(BlacklistEntry, {
            _meta: {
              ...entry._meta,
              updating: true,
            }
          });
        }

        return entry;
      }),
      adding: true,
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.ADD_ENTRY_SUCCEEDED) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['entry'].field && entry.value === action.data['entry'].value) {
          return action.data['entry'];
        }

        return entry;
      }),
      adding: false,
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.ADD_ENTRY_FAILED) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['field'] && entry.value === action.data['value']) {
          return entry.clone(BlacklistEntry, {
            _meta: {
              ...entry._meta,
              updating: false,
            }
          });
        }

        return entry;
      }),
      adding: false,
      error: action.data.error,
    };
  }

  if (action.type === BlacklistActions.TYPES.ADD_ENTRIES) {
    return {
      ...state,
      adding: true,
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.ADD_ENTRIES_SUCCEEDED) {
    return {
      ...state,
      adding: false,
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.ADD_ENTRIES_FAILED) {
    return {
      ...state,
      adding: false,
      error: action.data.error,
    };
  }

  if (action.type === BlacklistActions.TYPES.REPLACE_ENTRY) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['oldEntry']['field'] && entry.value === action.data['oldEntry']['value']) {
          return entry.clone(BlacklistEntry, {
            _meta: {
              ...entry._meta,
              updating: true,
            }
          });
        }

        return entry;
      }),
      adding: true,
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.REPLACE_ENTRY_SUCCEEDED) {
    return {
      ...state,
      adding: false,
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.REPLACE_ENTRY_FAILED) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['oldEntry']['field'] && entry.value === action.data['oldEntry']['value']) {
          return entry.clone(BlacklistEntry, {
            _meta: {
              ...entry._meta,
              updating: false,
            }
          });
        }

        return entry;
      }),
      adding: false,
      error: action.data.error,
    };
  }

  if (action.type === BlacklistActions.TYPES.REMOVE_ENTRY) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['entry'].field && entry.value === action.data['entry'].value) {
          return entry.clone(BlacklistEntry, {
            _meta: {
              ...entry._meta,
              updating: true,
            }
          });
        }

        return entry;
      }),
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.REMOVE_ENTRY_SUCCEEDED) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['entry'].field && entry.value === action.data['entry'].value) {
          return action.data['entry'];
        }

        return entry;
      }),
      error: null,
    };
  }


  if (action.type === BlacklistActions.TYPES.REMOVE_ENTRY_FAILED) {
    return {
      ...state,
      data: state.data.map((entry: BlacklistEntry) => {
        if (entry.field === action.data['entry'].field && entry.value === action.data['entry'].value) {
          return entry.clone(BlacklistEntry, {
            _meta: {
              ...entry._meta,
              updating: false,
            }
          });
        }

        return entry;
      }),
      error: null,
    };
  }

  if (action.type === BlacklistActions.TYPES.LOAD_INVITATIONS) {
    return {
      ...state,
      invitations: {
        sent: new Collection(),
        received: new Collection()
      },
      loading_invitations: true,
    };
  }

  if (action.type === BlacklistActions.TYPES.LOAD_INVITATIONS_SUCCEEDED) {
    return {
      ...state,
      invitations: action.data.invitations,
      loading_invitations: false,
    };
  }

  if (action.type === BlacklistActions.TYPES.LOAD_INVITATIONS_FAILED) {
    return {
      ...state,
      loading_invitations: false,
    };
  }

  if (action.type === BlacklistActions.TYPES.SHARE) {
    return {
      ...state,
      forms: {
        ...state['forms'],
        share: {
          ...dotVal(state, 'forms.share'),
          submitting: true,
          last_invite_failed: false,
        },
      },
    };
  }

  if (action.type === BlacklistActions.TYPES.SHARE_SUCCEEDED) {
    return {
      ...state,
      invitations: {
        ...state['invitations'],
        sent: state['invitations']['sent'].clone().updateOrPush(action.data.invitation, 'invitee.id'),
      },
      forms: {
        ...state['forms'],
        share: {
          ...dotVal(state, 'forms.share'),
          fields: {
            ...dotVal(state, 'forms.share.fields'),
            email: '',
          },
          submitting: false,
          last_invite_failed: false,
        },
      },
    };
  }

  if (action.type === BlacklistActions.TYPES.SHARE_FAILED) {
    return {
      ...state,
      forms: {
        ...state['forms'],
        share: {
          ...dotVal(state, 'forms.share'),
          submitting: false,
          last_invite_failed: true,
        },
      },
    };
  }

  if (action.type === BlacklistActions.TYPES.ACCPEPT_INVITATION) {
    return {
      ...state,
      invitations: updateInvitationsMeta(action.data['id'], state.invitations, { updating: true, }),
    };
  }

  if (action.type === BlacklistActions.TYPES.ACCPEPT_INVITATION_SUCCEEDED) {
    return {
      ...state,
      invitations: {
        ...state['invitations'],
        received: state['invitations']['received'].clone().updateOrPush(action.data.invitation, 'invitee.id'),
      },
    };
  }

  if (action.type === BlacklistActions.TYPES.ACCPEPT_INVITATION_FAILED) {
    return {
      ...state,
      invitations: updateInvitationsMeta(action.data['id'], state.invitations, { updating: false, }),
    };
  }

  if (action.type === BlacklistActions.TYPES.REMOVE_INVITATION) {
    return {
      ...state,
      invitations: updateInvitationsMeta(action.data['id'], state.invitations, { updating: true, }),
    };
  }

  if (action.type === BlacklistActions.TYPES.REMOVE_INVITATION_SUCCEEDED) {
    return {
      ...state,
      invitations: removeInvitation(action.data['id'], state['invitations']),
    };
  }

  if (action.type === BlacklistActions.TYPES.REMOVE_INVITATION_FAILED) {
    return {
      ...state,
      invitations: updateInvitationsMeta(action.data['id'], state.invitations, { updating: false, }),
    };
  }

  if (action.type === BlacklistActions.TYPES.LOAD_SETTINGS) {
    return {
      ...state,
      loading_settings: true,
    };
  }

  if (action.type === BlacklistActions.TYPES.LOAD_SETTINGS_SUCCEEDED) {
    return {
      ...state,
      settings: action.data.settings,
      loading_settings: false,
    };
  }

  if (action.type === BlacklistActions.TYPES.LOAD_SETTINGS_FAILED) {
    return {
      ...state,
      loading_settings: false,
    };
  }

  if (action.type === BlacklistActions.TYPES.SAVE_SETTINGS) {
    return {
      ...state,
      saving: true,
    };
  }

  if (action.type === BlacklistActions.TYPES.SAVE_SETTINGS_SUCCEEDED) {
    return {
      ...state,
      settings: action.data.settings,
      saving: false,
    };
  }

  if (action.type === BlacklistActions.TYPES.SAVE_SETTINGS_FAILED) {
    return {
      ...state,
      saving: false,
    };
  }

  return paginationReducer(BlacklistEntry)(state, action);
}
