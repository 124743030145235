import {
  AfterViewInit, ContentChildren, Directive, ElementRef, EventEmitter, HostListener, Output,
  QueryList
} from '@angular/core';
import { DropdownNotClosableZoneDirective } from "@app/shared/layout/dropdown/dropdown-not-closable-zone.directive";
import { isPhoneViewport } from "@app/helpers";
import { DropdownOpenDirective } from "@app/shared/layout/dropdown/dropdown-open.directive";

@Directive({
  selector: '[stDropdown]'
})
export class DropdownDirective implements AfterViewInit {

  @Output()
  onOpen = new EventEmitter();

  @Output()
  onClose = new EventEmitter();

  @ContentChildren(DropdownNotClosableZoneDirective, {descendants: true})
  private _notClosableZones: QueryList<DropdownNotClosableZoneDirective>;

  private _parents: DropdownDirective[] = [];

  get parent(): DropdownDirective {
    if(this._parents.length <= 0) return null;
    return this._parents[0];
  }

  set parent(val: DropdownDirective){
    if(val === null) this._parents.shift();
    else this._parents.unshift(val);
  }

  public dropdownOpen:DropdownOpenDirective = null;

  @ContentChildren(DropdownDirective)
  private _childrenDropdowns: QueryList<DropdownDirective> = new QueryList<DropdownDirective>();

  private nestedDropdowns: DropdownDirective[] = [];

  public addNestedDropdown(dropdown: DropdownDirective) {
    dropdown.parent = this;
    this.nestedDropdowns.push(dropdown);
  }

  public removeNestedDropdown(dropdown: DropdownDirective) {
    dropdown.parent = null;
    this.nestedDropdowns = this.nestedDropdowns.filter(d => d !== dropdown);
  }

  get childrenDropdowns(): DropdownDirective[] {
    return this.nestedDropdowns.concat(this._childrenDropdowns.toArray());
  }

  constructor(public elementRef: ElementRef) {}

  private handleMobileOpen() {
    if(isPhoneViewport() && this.parent && this.parent != this) {
      const oldTransf= this.parent.elementRef.nativeElement.querySelector('.transformer');
      const transf = this.elementRef.nativeElement.querySelector('ol');

      const options = this.elementRef.nativeElement.querySelector(":scope > .options");
      options.style.top = -this.elementRef.nativeElement.offsetTop + 'px';

      oldTransf.classList.add('left-behind');
      oldTransf.style.width = transf.offsetWidth + 'px';
      oldTransf.style.height = transf.offsetHeight + 'px';
    }
  }

  open() {
    const element: HTMLElement = this.elementRef.nativeElement;
    element.classList.add("opened");
    this.handleMobileOpen();
    this.onOpen.emit(undefined);
  }

  private handleMobileClose() {
    if(isPhoneViewport() && this.parent && this.parent != this) {
      const oldTransf= this.parent.elementRef.nativeElement.querySelector('.transformer');

      const options = this.elementRef.nativeElement.querySelector(":scope > .options");
      options.style.top = null;

      oldTransf.classList.remove('left-behind');
      oldTransf.style.width = oldTransf.style.height = null;
    }
  }

  @HostListener('window:resize', [])
  close() {
    const element: HTMLElement = this.elementRef.nativeElement;
    element.classList.remove("opened");
    this.handleMobileClose();
    this.onClose.emit(undefined);
  }

  isOpened() {
    const element: HTMLElement = this.elementRef.nativeElement;
    return element.classList.contains("opened");
  }

  isInClosableZone(element: HTMLElement) {
    for(let cs of this._notClosableZones.toArray())
      if(cs.contains(element)) return false;

    for(let dd of this.childrenDropdowns)
      if(dd != this && !dd.isInClosableZone(element))
        return false;

    return !this.dropdownOpen.elementRef.nativeElement.contains(element);
  }

  ngAfterViewInit(): void {
    for(let d of this.childrenDropdowns)
      d.parent = this;

    // Move <li>s to transformer
    const ol = this.elementRef.nativeElement.querySelector('ol');
    if(ol) {
      const lis = ol.querySelectorAll(':scope > li');
      const t = document.createElement('div');
      t.classList.add('transformer');
      ol.appendChild(t);
      lis.forEach(li => t.appendChild(li));
    }
  }
}
