import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { ToastService } from '@app/core/services/toast.service';
import { WalletTopUpSourcesActions } from './wallet-top-up-sources.actions';
import { IListSourcesResponse, WalletTopUpSourcesApi } from './wallet-top-up-sources.api';
import { ITopUpSource } from './wallet-top-up-sources.models';
import { Collection } from '../base.models';

@Injectable()
export class WalletTopUpSourcesEpics {

  constructor(
    private walletTopUpSourcesApi: WalletTopUpSourcesApi,
    protected toastService: ToastService,
    private walletTopUpSourcesActions: WalletTopUpSourcesActions) {
  }

  public createEpic() {
    return [
      this.loadSources,
      this.linkACH,
      this.removeACH,
    ];
  }

  loadSources = store => next => {
    return (action: StAction) => {
      if (action.type === WalletTopUpSourcesActions.TYPES.LOAD_SOURCES) {
        this.walletTopUpSourcesApi.get()
          .subscribe(
            (response: IListSourcesResponse) => {
              store.dispatch(this.walletTopUpSourcesActions.loadSourcesSucceeded(response.data));
            },
            (response) => {
              store.dispatch(this.walletTopUpSourcesActions.loadSourcesFailed(response.error));
            },
          );
      }

      return next(action);
    };
  }

  linkACH = store => next => {
    return (action: StAction) => {
      if (action.type === WalletTopUpSourcesActions.TYPES.LINK_ACH) {
        this.walletTopUpSourcesApi.linkACH(action.data.setupIntentId, action.data.paymentMethodId)
          .subscribe(
            (response: IListSourcesResponse) => {
              store.dispatch(this.walletTopUpSourcesActions.linkACHSucceeded(response.data));
            },
            (response) => {
              store.dispatch(this.walletTopUpSourcesActions.linkACHFailed(response.error));
            },
          );
      }

      return next(action);
    };
  }

  removeACH = store => next => {
    return (action: StAction) => {
      if (action.type === WalletTopUpSourcesActions.TYPES.REMOVE_ACH) {
        this.walletTopUpSourcesApi.removeACH()
          .subscribe(
            (response: IListSourcesResponse) => {
              store.dispatch(this.walletTopUpSourcesActions.removeACHSucceeded(response.data));
            },
            (response) => {
              store.dispatch(this.walletTopUpSourcesActions.removeACHFailed(response.error));
            },
          );
      }

      return next(action);
    };
  }
}
