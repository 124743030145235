import { NgRedux } from '@angular-redux/store';
import { Component, Input, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import { IProductManagerKeyword, IProductManagerState, IProductManagerUpdateKeywordsPayload } from '@app/shared/data/product-manager/product-manager.models';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { ProductManagerKeywordExportService } from '@app/shared/services/export-keywords.service';
import { ActiveModal, ModalService } from '@app/shared/components/modals/modal.service';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { LogoLoaderModalComponent } from '@app/shared/layout/logo-loader/logo-loader-modal/logo-loader-modal.component';
import { takeUntil } from 'rxjs/operators';
import {
  ProductManagerCopyKeywordModalComponent
} from '@app/client/v2/product-manager/product-manager-copy-keyword-modal/product-manager-copy-keyword-modal.component';
import { Subject } from 'rxjs';

export enum BulkActionEnum {
  DELETE = 'DELETE',
  CSV = 'CSV',
  COPY = 'COPY',
  RECALCULATE = 'RECALCULATE',
  PRIORITY = 'PRIORITY'
}

export enum PriorityEnum {
  VL = 'VL',
  L = 'L',
  M = 'M',
  H = 'H'
}

@Component({
  selector: 'st-product-manager-bulk-actions',
  templateUrl: './product-manager-bulk-actions.component.html',
  styleUrls: ['./product-manager-bulk-actions.component.scss']
})
export class ProductManagerBulkActionsComponent implements OnInit, OnDestroy {
  @Input() currentAsin: string;
  @Input() selectedKeywords: IProductManagerKeyword[];
  @Input() isDisabled = false;

  options: IDropdownOption[] = [
    {
      id: null,
      label: this.i18n('BULK ACTIONS'),
      dontDisplayInDropdown: true,
    },
    {
      id: BulkActionEnum.DELETE,
      label: this.i18n('DELETE'),
      onClick: this.archive.bind(this)
    },
    {
      id: BulkActionEnum.CSV,
      label: this.i18n('EXPORT AS .CSV'),
      onClick: this.export.bind(this)
    },
    {
      id: BulkActionEnum.COPY,
      label: this.i18n('COPY KEYWORDS TO'),
      onClick: this.copyKeywords.bind(this)
    },
    {
      id: BulkActionEnum.PRIORITY,
      label: this.i18n('CHANGE PRIORITY'),
      onClick: this.togglePriority.bind(this),
      canOpen: true
    },
  ];

  priorityOptions = [
    {
      id: PriorityEnum.H,
      label: this.i18n('HIGH'),
    },
    {
      id: PriorityEnum.M,
      label: this.i18n('MEDIUM'),
    },
    {
      id: PriorityEnum.L,
      label: this.i18n('LOW'),
    },
    {
      id: PriorityEnum.VL,
      label: this.i18n('VERY LOW'),
    }
  ]

  selectedOption = this.options[0];
  selectedPriorityOption = null;
  priorityEnum = PriorityEnum;
  priorityOptionsOpened: boolean = false;

  public opened = false;
  private destroy$: Subject<void> = new Subject();
  private loaderModal: ActiveModal;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
    private rudderTracking: RudderTrackingService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private i18n: I18n
  ) { }

  ngOnInit() {
    this.ngRedux.select('product_manager')
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((productManagerState: IProductManagerState) => {
        if (!productManagerState.removingKeyword) {
          this.loaderModal?.instance?.close();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedKeywords) {
      if(this.selectedKeywords.length === 1) {
        this.selectedPriorityOption = this.priorityOptions.find(p => p.id === this.selectedKeywords[0].priority);
      } else {
        this.selectedPriorityOption = null;
      }
    }
  }

  toggleDropdown() {
    this.opened = !this.isDisabled && !this.opened;
    this.priorityOptionsOpened = false;
  }

  export() {
    ProductManagerKeywordExportService.exportToCsv(`${this.currentAsin}-keywords.csv`, this.selectedKeywords);
    this.toggleDropdown();
    this.rudderTracking.trackEvent('Keyword Manager - Bulk Export');
  }

  archive() {
    this.dialogService.confirm(
      this.i18n('Are you sure you sure you want to delete selected keywords?'),
      this.i18n(`You are about to permanenteley remove ${this.selectedKeywords.length} keywords from your list`),
      this.i18n('YES, DELETE'), this.i18n('CANCEL'), true
    )
      .then(
        (ok) => {
          if (ok) {
            this.ngRedux.dispatch(this.productManagerActions.deleteKeywords(this.currentAsin, this.getKeywordsValues()));
            this.toggleDropdown();
            this.loaderModal = this.modalService.open(LogoLoaderModalComponent);
            this.loaderModal.instance.loadingText = this.i18n('Deleting in progress...');
            this.rudderTracking.trackEvent('Keyword Manager - Bulk Archive');
          }
        }
      );
  }

  copyKeywords() {
    this.modalService.open(ProductManagerCopyKeywordModalComponent, {
      asin: this.currentAsin,
    });
    this.toggleDropdown();
    this.rudderTracking.trackEvent('Keyword Manager - Copy Keywords');
  }

  optionSelected(item: IDropdownOption) {
    if (item.onClick) {
      item.onClick();
    }
  }

  togglePriority() {
    this.priorityOptionsOpened = !this.priorityOptionsOpened;
  }

  changePriority(event: PriorityEnum) {
    const keywords: IProductManagerUpdateKeywordsPayload[] = [];
    this.selectedKeywords.forEach((item) => {
      keywords.push({
        keyword: item.value,
        priority: event,
      })
    });

    this.rudderTracking.trackEvent('Keyword Manager - Bulk Priority Updated', {
      priority: event
    });

    this.ngRedux.dispatch(this.productManagerActions.updateKeyword(this.currentAsin, keywords));
    this.toggleDropdown();
  }

  private getKeywordsValues(): string[] {
    return this.selectedKeywords.map((keyword: IProductManagerKeyword) => keyword.value);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
