import { StAction } from '@app/shared/data/st-action';
import { InvoiceActions, LoadDirectionEnum } from '@app/shared/data/invoice/invoice.actions';
import { Invoice, InvoicesCollection, InvoicesTypeEnum } from './invoice.models';

export interface InvoicesState {
  invoices: Invoice[];
  previousPage: null | string;
  nextPage: null | string;
  type: InvoicesTypeEnum,
  downloading: boolean;
  loading: boolean;
  error: any;
}

const INITIAL_STATE: InvoicesState = {
  invoices: [],
  previousPage: null,
  nextPage: null,
  type: InvoicesTypeEnum.SUBSCRIPTIONS,
  loading: false,
  downloading: false,
  error: null,
};

export function invoiceReducer(state: InvoicesState = INITIAL_STATE, action: StAction): InvoicesState {
  if (action.type === InvoiceActions.TYPES.SET_TYPE) {
    return {
      ...state,
      type: action.data.type
    };
  }

  if (action.type === InvoiceActions.TYPES.LOAD) {
    return {
      ...state,
      loading: true,
      downloading: false,
      error: null
    };
  }

  if (action.type === InvoiceActions.TYPES.LOAD_SUCCEEDED) {
    const invoices: InvoicesCollection = action.data.invoices;
    const direction: null|LoadDirectionEnum = action.data.direction;

    const first = invoices.data[0];
    const last = invoices.data[invoices.data.length - 1];

    let previousPage = first ? first.id : null;
    let nextPage = last ? last.id : null;

    if (direction == null || (direction === LoadDirectionEnum.PREVIOUS && !invoices.has_more)) {
      previousPage = null;
    }

    if (direction !== LoadDirectionEnum.PREVIOUS && !invoices.has_more) {
      nextPage = null;
    }

    return {
      ...state,
      invoices: invoices.data,
      previousPage,
      nextPage,
      loading: false,
      error: null
    };
  }

  if (action.type === InvoiceActions.TYPES.LOAD_FAILED) {
    return {
      ...state,
      loading: false,
      error: action.data
    };
  }

  if (action.type === InvoiceActions.TYPES.DOWNLOAD) {
    return {
      ...state,
      invoices: [
        ...state.invoices.map((invoice: Invoice) => {
          if (invoice.id === action.data.invoice.id) {
            return {
              ...invoice,
              downloading: true
            };
          }
          return invoice;
        })
      ],
      downloading: true,
    };
  }

  if (action.type === InvoiceActions.TYPES.DOWNLOAD_SUCCEEDED) {
    return {
      ...state,
      invoices: [
        ...state.invoices.map((invoice: Invoice) => {
          return {
            ...invoice,
            downloading: false
          };
        })
      ],
      downloading: true,
    };
  }

  return state;
}
