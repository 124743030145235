import { NgRedux } from "@angular-redux/store";
import { Component, OnInit, ViewChild } from "@angular/core";
import { ModalComponent } from "@app/shared/components/modals/modal/modal.component";
import { AppState } from "@app/shared/data/app-state.model";
import { ProductManagerActions } from "@app/shared/data/product-manager/product-manager.actions";
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';

@Component({
  templateUrl: './product-manager-add-keyword-modal.component.html',
  styleUrls: ['./product-manager-add-keyword-modal.component.scss'],
})
export class ProductManagerAddKeywordModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;
  tags: string[] = [];
  data?: any;

  asin: string;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
    private rudderTracking: RudderTrackingService
  ) { }

  ngOnInit() {
    this.asin = this.data.asin;
  }

  cancel() {
    this.modal.close();
  }

  save() {
    this.rudderTracking.trackEvent('Keyword Manager - Added manually', {
      count: this.tags.length
    });
    this.ngRedux.dispatch(this.productManagerActions.addKeywords(this.asin, this.tags));
    this.modal.close();
  }

  onTagsChange(tags: string[]) {
    this.tags = tags;
  }

  get confirmButtonDisabled(): boolean {
    return this.tags && this.tags.length === 0;
  }
}
