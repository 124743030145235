import {Injectable} from '@angular/core';
import {Collection} from '@app/shared/data/base.models';
import {NotificationAction, NotificationsActions,} from '@app/shared/data/notification/notifications.actions';
import {NgRedux} from '@angular-redux/store';
import {AppState} from '@app/shared/data/app-state.model';
import {NotificationAPI} from '@app/shared/data/notification/notification.api';
import {Notification} from '@app/shared/data/notification/notification.models';
import {UnreadNotificationsActions} from '@app/shared/data/notification/notifications-unread.actions';
import {NotificationService} from '@app/core/services/notification.service';

@Injectable()
export class NotificationsEpics {

  constructor(private store: NgRedux<AppState>,
              private notificationApi: NotificationAPI,
              private notificationsActions: NotificationsActions,
              private notificationService: NotificationService) {
  }

  public createEpic() {
    return [
      this.newNotification,
      this.fetchUnreadNotifications,
      this.readNotification,
      this.readAllNotifications,
      this.loadPage,
      this.setPage,
      this.filterNotificationsModal,
    ];
  }

  newNotification = store => next => {
    return (action: NotificationAction) => {
      if (action.type === UnreadNotificationsActions._events.socket_notification_added) {
        const notification = new Notification(action.data);
        notification.markAsNew();

        // dispatch action if set
        if (notification.eventAction) {
            this.store.dispatch({type: notification.eventAction, data: notification});
        }

        // no need to dispatch SOCKET_NOTIFICATION_ADDED
        this.store.dispatch(UnreadNotificationsActions.addNotification(
          this.notificationService.transform(notification)
        ));
      }

      return next(action);
    };
  }

  fetchUnreadNotifications = store => next => {
    return (action: NotificationAction) => {
      if (action.type === UnreadNotificationsActions.TYPES.FETCH_NOTIFICATIONS) {
        if (store.getState()['unread_notifications']['loading']) {
          return;
        }

        this.notificationApi.all(action.data)
            .subscribe(
              (notifications: Collection<Notification>) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(UnreadNotificationsActions.fetchNotificationsSucceeded(
                  notifications.map(notification => this.notificationService.transform(notification)))
                );
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(UnreadNotificationsActions.fetchingNotificationsFailed(response));
              },
              () => {

              },
            );
      }

      return next(action);
    };
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === NotificationsActions.getPagActionTypes(Notification).LOAD_PAGE) {
        const params = {
          ...action.data.pagination,
          limit: action.data.pagination.perPage,
          sort : action.data.pagination.sort.join(','),
        };

        this.notificationApi.all(params)
            .subscribe(
              (notifications: Collection<Notification>) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.notificationsActions.setPage(notifications));
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.notificationsActions.loadPageFailed(response));
              },
              () => {

              },
            );
      }
      return next(action);
    };
  }

  setPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === NotificationsActions.getPagActionTypes(Notification).SET_PAGE) {
        action.data.data = action.data.data.map(notification => this.notificationService.transform(notification));
        this.store.dispatch(NotificationsActions.setPage(action.data));
      }
      return next(action);
    };
  }

  readNotification = store => next => {
    return (action) => {
      if (action.type === UnreadNotificationsActions.TYPES.READ_NOTIFICATION) {
        this.notificationApi.delete(action.data)
            .subscribe(() => {
              this.store.dispatch(UnreadNotificationsActions.fetchNotifications({
                limit : 5,
                filter: 'unread'
              }));
            });
      }

      return next(action);
    };
  }

  readAllNotifications = store => next => {
    return (action) => {
      if (action.type == UnreadNotificationsActions.TYPES.READ_ALL_NOTIFICATIONS) {
        this.notificationApi.deleteAll()
            .subscribe(() => {
              this.store.dispatch(UnreadNotificationsActions.readAllNotificationsSucceeded());
              const params = {
                limit: 30
              };

              if (['read', 'unread'].includes(action.data)) {
                params['filter'] = action.data;
              }

              this.notificationApi.all(params)
                  .subscribe(
                    (notifications: Collection<Notification>) => {
                      this.store.dispatch(this.notificationsActions.setPage(notifications));
                    },
                    (response) => {
                      this.store.dispatch(this.notificationsActions.loadPageFailed(response));
                    }
                  );
            });
      }

      return next(action);
    };
  };

  filterNotificationsModal = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === NotificationsActions.TYPES.NOTIFICATION_MODAL_FILTER) {
        const params = {
          limit: 30
        };

        if (['read', 'unread'].includes(action.data)) {
          params['filter'] = action.data;
        }

        this.notificationApi.all(params)
            .subscribe(
              (notifications: Collection<Notification>) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.notificationsActions.setPage(notifications));
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.notificationsActions.loadPageFailed(response));
              },
              () => {

              },
            );
      }
      return next(action);
    };
  };
}
