import { NgModule } from '@angular/core';
import { SharedModule } from "@app/shared/shared.module";
import { PlanAPI } from "@app/shared/data/plan/plan.api";
import { BillingPlanChangeComponent, PlanBadgeComponent } from "@app/client/billing/partials/plan-change.component";
import { UpdatePlanComponent } from "@app/client/modals/update-plan/update-plan.component";
import { PpcsPlanChangeComponent } from "@app/client/billing/partials/ppcs-plan-change.component";
import { LayoutV2Module } from '@app/shared/layout/v2/layout-v2.module';
import { PlanPriceModule } from '@app/client/billing/partials/plan-price.module';

@NgModule({
  imports        : [
    SharedModule,
    LayoutV2Module,
    PlanPriceModule,
  ],
  exports        : [
    PlanBadgeComponent,
    BillingPlanChangeComponent,
    PpcsPlanChangeComponent,
  ],
  declarations   : [
    UpdatePlanComponent,
    BillingPlanChangeComponent,
    PpcsPlanChangeComponent,
    PlanBadgeComponent,
  ],
  providers      : [
    PlanAPI,
  ],
})
export class UpdatePlanModule {
}
