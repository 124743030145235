
import {throwError as observableThrowError, of as observableOf,  Observable } from 'rxjs';

import {catchError, mergeMap, map} from 'rxjs/operators';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { AuthService } from '@app/core/services/auth.service';
import { HttpEvent } from '@angular/common/http';

import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private router: Router) {}

  /**
   * Interceptor adds Authorization headers to all
   * requests and refresh token if required.
   *
   * @param request
   * @param next
   * @returns {any}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.params.has('_tokenInterceptor')) {
      return next.handle(
        this.withToken(
          this.withoutTokenInterceptorParam(request)
        )
      );
    }

    const forceRefreshToken = (event) => {
      if (event instanceof HttpResponse) {
        if (event.headers.has('st-refresh-token')) {
          return this
            .authService
            .refreshToken().pipe(
            map((t) => {
              return event;
            }));
        }
      }
      return observableOf(event);
    };

    return next.handle(this.withToken(request)).pipe(
      mergeMap(forceRefreshToken),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          if (this.authService.hasToken()) {
            return this
              .authService
              .refreshToken().pipe(
              mergeMap((t) => {
                return next.handle(this.withToken(request)).pipe(
                  mergeMap(forceRefreshToken));
              }));
          } else {
            this.router.navigateByUrl('/auth/logout');
          }
        }

        if (error instanceof HttpErrorResponse && error.headers.has('st-refresh-token') && this.authService.hasToken()) {
          return this
            .authService
            .refreshToken().pipe(
            map(() => {
              throw error;
            }));
        }

        return observableThrowError(error);
      }),);
  }

  /**
   * Add Authorization header to passed request.
   *
   * @param req
   * @returns {HttpRequest<any>} - new instance
   */
  withToken(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      setHeaders: {
        Authorization: `Bearer ${this.authService.getAccessToken()}`
      }
    });
  }

  /**
   * Remove _tokenInterceptor param from request
   * @param req
   * @return {HttpRequest<any>}
   */
  withoutTokenInterceptorParam(req: HttpRequest<any>): HttpRequest<any> {
    return req.clone({
      params: req.params.delete('_tokenInterceptor')
    });
  }
}
