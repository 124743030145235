import { ChangeDetectorRef, Component, forwardRef, HostListener, Input } from '@angular/core';
import { ValueAccessorBase } from "@app/shared/layout/forms/lib/base-value-accessor";
import { NG_VALUE_ACCESSOR } from "@angular/forms";

let _checkboxIdCounter = 0;

@Component({
  selector   : 'st-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls  : ['./checkbox.component.scss'],
  providers  : [
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi      : true,
    },
  ],
})
export class CheckboxComponent extends ValueAccessorBase<boolean> {
  @HostListener('click', ['$event'])
  onClick(e) {
    this.toggle();
    e.preventDefault();
  }

  @Input() disabled: boolean = false;
  @Input() readonly: boolean = false;

  id = `st-checkbox-${_checkboxIdCounter++}`;

  constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  public toggle() {
    if (!this.disabled && !this.readonly) {
      this.value = !this.value;
    }
  }
}
