import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LastLaunchPromotion } from "@app/shared/data/last-launch-promotion/last-launch-promotion.models";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { LastLaunchPromotionActions } from "@app/shared/data/last-launch-promotion/last-launch-promotion.actions";
import { LastLaunchSchedule } from "@app/shared/data/last-launch-schedule/last-launch-schedule.models";
import {Filter} from "@app/shared/components/filter-builder/filter/filter";
//
export type LastLaunchPromotionFilters = {
  filters: Filter[];
};

const INITIAL_STATE: AppState = {
  data                  : new Collection<LastLaunchPromotion>([]),
  full_promotion        : new LastLaunchSchedule(),
  loading               : false,
  loading_full_promotion: false,
  _be_pagination        : {
    page : 1,
    sort : [],
    limit: LS.forActiveCredential().getPerPage('llpromo') || 50
  }
};

export function lastLaunchPromotionReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == PaginationActions.getPagActionTypes(LastLaunchPromotion).LOAD_PAGE) {
    LS.forActiveCredential().setPerPage(action.data.pagination.perPage, 'llpromo');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == LastLaunchPromotionActions.TYPES.FILTER) {
    return {
      ...state,
      _filters: action.data,
      loading : true
    }
  }

  if (action.type == LastLaunchPromotionActions.TYPES.CANCEL_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].clone().map(
        (promo: LastLaunchPromotion) => {
          if (promo.id == action.data.promotion.id) {
            return promo.clone(LastLaunchPromotion, {
              cancelled_at: new Date()
            });
          }
          return promo;
        }
      )
    }
  }

  if (action.type == LastLaunchPromotionActions.TYPES.LOAD_PROMOTION_REPORT) {
    return {
      ...state,
      full_promotion        : new LastLaunchSchedule(),
      loading_full_promotion: true,
    }
  }

  if (action.type == LastLaunchPromotionActions.TYPES.LOAD_PROMOTION_REPORT_SUCCEEDED) {
    return {
      ...state,
      full_promotion        : action.data.promotion,
      loading_full_promotion: false,
    }
  }

  if (action.type == LastLaunchPromotionActions.TYPES.LOAD_PROMOTION_REPORT_FAILED) {
    return {
      ...state,
      loading_full_promotion: false,
    }
  }

  return paginationReducer(LastLaunchPromotion)(state, action);
}
