import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { LS } from "@app/shared/services/local-storage.service";
import { SystemNotificationAdmin } from "@app/shared/data/admin/system-notification/system-notification.models";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { SystemNotificationAdminActions } from "@app/shared/data/admin/system-notification/system-notification.actions";

const INITIAL_STATE: AppState = {
  data          : new Collection<SystemNotificationAdmin>([]),
  loading       : false,
  saving        : false,
  removing      : false,
  error         : null,
  _filters      : {},
  _be_pagination: {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage('admin-system-notifications') || 50
  }
};

export function systemNotificationReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == PaginationActions.getPagActionTypes(SystemNotificationAdmin).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-system-notifications');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == SystemNotificationAdminActions.TYPES.SAVE) {
    return {
      ...state,
      saving: true,
      error : null,
    };
  }

  if (action.type == SystemNotificationAdminActions.TYPES.SAVE_SUCCEEDED) {
    return {
      ...state,
      saving: false,
      error : null,
    };
  }

  if (action.type == SystemNotificationAdminActions.TYPES.SAVE_FAILED) {
    console.log("Saving System Notification Failed With Error", action.data);

    return {
      ...state,
      saving: false,
      error : action.data.error,
    };
  }

  if (action.type == SystemNotificationAdminActions.TYPES.REMOVE) {
    return {
      ...state,
      removing: true,
      loading : true,
      error   : null,
    };
  }

  if (action.type == SystemNotificationAdminActions.TYPES.REMOVE_SUCCEEDED) {
    return {
      ...state,
      removing: false,
      loading : false,
      error   : null,
    };
  }

  if (action.type == SystemNotificationAdminActions.TYPES.REMOVE_FAILED) {
    console.log("Removal Of System Notification Failed With Error", action.data);

    return {
      ...state,
      removing: false,
      loading : false,
      error   : action.data,
    };
  }

  return paginationReducer(SystemNotificationAdmin)(state, action);
}
