import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { SystemSettings } from '@app/shared/data/admin/system-settings/system-settings.models';

@Injectable()
export class SystemSettingsActions {

  static readonly EVENTS = {};

  static readonly TYPES = {
    GET          : '[SYSTEM_SETTINGS]GET',
    GET_SUCCEEDED: '[SYSTEM_SETTINGS]GET_SUCCEEDED',
    GET_FAILED   : '[SYSTEM_SETTINGS]GET_FAILED',

    SAVE          : '[SYSTEM_SETTINGS]SAVE',
    SAVE_SUCCEEDED: '[SYSTEM_SETTINGS]SAVE_SUCCEEDED',
    SAVE_FAILED   : '[SYSTEM_SETTINGS]SAVE_FAILED',
  };
  
  static save(settings: SystemSettings): StAction {
    return {
      type: SystemSettingsActions.TYPES.SAVE,
      data: settings
    }
  }

  static saveSuccedded(settings: SystemSettings): StAction {
    return {
      type: SystemSettingsActions.TYPES.SAVE_SUCCEEDED,
      data: settings
    }
  }

  static saveFailed(error): StAction {
    return {
      type: SystemSettingsActions.TYPES.SAVE_FAILED,
      data: error
    }
  }

  static getSettings(): StAction {
    return {
      type: SystemSettingsActions.TYPES.GET,
      data: null
    };
  }

  static getSettingsSucceeded(settings: SystemSettings): StAction {
    return {
      type: SystemSettingsActions.TYPES.GET_SUCCEEDED,
      data: settings
    }
  }

  static getSettingsFailed(error): StAction {
    return {
      type: SystemSettingsActions.TYPES.GET_FAILED,
      data: error
    }
  }
}
