
import { UserService } from '@app/core/socket/socket.service';
import { isObject } from '@app/helpers';

export interface SocketMiddleware {
  /**
   // event name,
   // channel name,
   // event data
   */
  next(packet: any);
}

export class TokenRefreshMiddleware implements SocketMiddleware {
  userService: UserService;

  constructor(userService: UserService) {
    this.userService = userService;
  }

  next(packet) {
    if (packet.data[2] === 401 && this.userService.getUserId()) {
      this.userService.refreshToken().subscribe();
    }
  }
}

export class WildcardEmiterMiddleware implements SocketMiddleware {
  next(packet) {
    if (packet.data && isObject(packet.data[2])) {
      packet.data[2].__event = packet.data[0];
      packet.data[0] = '*';
    }

    return packet;
  }
}
