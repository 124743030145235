
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { ExchangeRate } from './exchange-rates.models';
import { Collection } from '../base.models';

@Injectable()
export class ExchangeRatesApi extends BaseAPI {

  constructor(protected http: HttpClient) {
    super();
  }

  public get(): Observable<Collection<ExchangeRate>> {
    return this.http.get(this.constructFullUrl(`api/wallet/exchange-rates`)).pipe(
      map(d => ExchangeRate.collect(d)));
  }
}
