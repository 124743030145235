import { Injectable, Injector } from '@angular/core';
import { Observable, Subject } from "rxjs";

@Injectable()
export class SidebarService {
  public locked$ = new Subject<boolean>();
  public extended$: Observable<boolean>;

  public lock() {
    this.locked$.next(true);
  }

  public unlock() {
    this.locked$.next(false);
  }
}
