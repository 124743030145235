import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { Plan } from '@app/shared/data/plan/plan.models';
import { PlanActions } from '@app/shared/data/plan/plan.actions';

const INITIAL_STATE: AppState = {
  data    : new Collection<Plan>([]),
  _loading: false,
  _error  : null,
};

export function planReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === PlanActions.TYPES.LOAD) {
    return {
      ...state,
      _loading: true,
      _error  : null
    };
  }

  if (action.type === PlanActions.TYPES.LOAD_SUCCEEDED) {
    return {
      ...state,
      data    : action.data,
      _loading: false,
      _error  : null
    };
  }

  if (action.type === PlanActions.TYPES.LOAD_FAILED) {
    return {
      ...state,
      _loading: false,
      _error  : action.data
    };
  }

  return state;
}
