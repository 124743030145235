import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RangeSliderComponent } from "@app/shared/components/range-slider/range-slider.component";

let COMPONENTS = [
  RangeSliderComponent
];
let DIRECTIVES = [
];


@NgModule({
  imports     : [
    CommonModule,
    FormsModule,
  ],
  declarations: [
    ...COMPONENTS, ...DIRECTIVES,
  ],
  exports     : [
    ...COMPONENTS, ...DIRECTIVES
  ]
})
export class RangeSliderModule {
}
