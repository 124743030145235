import { AfterContentChecked, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[stAutofocus]'
})
export class AutofocusDirective implements AfterContentChecked {
  constructor(private el: ElementRef) {
  }

  ngAfterContentChecked() {
    if (this.el.nativeElement.offsetParent) {
      this.el.nativeElement.focus();
    }
  }
}
