
import {share,  filter, flatMap, map, takeUntil ,  take } from 'rxjs/operators';
import { NgRedux, select } from '@angular-redux/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ChooseAnotherCardComponent } from '@app/client/billing/modals/choose-another-card.component';
import { EditBillingInformationComponent } from '@app/client/billing/modals/edit-billing-information.component';
import { AuthService } from '@app/core/services/auth.service';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { AppState } from '@app/shared/data/app-state.model';
import { Collection } from '@app/shared/data/base.models';
import { InvoiceActions, LoadDirectionEnum } from '@app/shared/data/invoice/invoice.actions';
import { Invoice, InvoicesTypeEnum } from '@app/shared/data/invoice/invoice.models';
import { PlanActions } from '@app/shared/data/plan/plan.actions';
import { Plan } from '@app/shared/data/plan/plan.models';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { Subscription as StSubscription, Subscription } from '@app/shared/data/subscription/subscription.models';
import { UserAPI } from '@app/shared/data/user/user.api';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { environment } from '@env/environment';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { BehaviorSubject ,  Observable ,  Subject } from 'rxjs';
import { UpgradeSubscriptionModalComponent } from './modals/upgrade-subscription-modal/upgrade-subscription-modal.component';
import { InvoicesState } from '@app/shared/data/invoice/invoice.reducers';

@Component({
  selector       : 'st-billing',
  templateUrl    : './billing.component.html',
  styleUrls      : ['./billing.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class BillingComponent implements OnInit, OnDestroy {
  public selectedInvoicesType = InvoicesTypeEnum.SUBSCRIPTIONS;
  public invoicesTypeEnum = InvoicesTypeEnum;

  showAllUsageSummary = false;

  selected: Plan;

  private destroy$ = new Subject<void>();

  @select(['user', 'data', 'billing_information'])
  readonly billingInformation$: Observable<any>;

  public invoices: Invoice[] = [];
  public hasNextInvoicesPage = false;
  public hasPreviousInvoicesPage = false;
  public loadingInvoices = false;

  @select(['plans', '_loading'])
  public loadingPlans$;

  public keywordPlans: Plan[];
  public ppcsPlans: Plan[] = [];

  @select(['subscriptions', '_loading'])
  public loadingSubscriptions$;

  @select(['subscriptions', '_deleting'])
  public cancelingSubscription$;

  @select(['user', '_is_subscribing_to_plan'])
  public subscribingToPlan$;

  public activeSubscription = null;
  public nextSubscription = null;

  public activePPCSSubscription = null;
  public nextPPCSSubscription = null;

  public usageSummary = null;
  allUsageSummary;

  public translateBreadcrumbs: string[] = [':user', this.i18n('Billing')];

  public newPricingUrl = environment.NEW_PRICING_URL;

  private onSubscriptionUpdate: Subject<boolean> = new Subject<boolean>();

  constructor(private invoiceActions: InvoiceActions,
              private subscriptionActions: SubscriptionActions,
              private userAPI: UserAPI,
              private ref: ChangeDetectorRef,
              private modalService: ModalService,
              public authService: AuthService,
              private ngRedux: NgRedux<AppState>,
              private dialogService: DialogService,
              private trackingService: RudderTrackingService,
              private i18n: I18n) { }

  ngOnInit() {
    //noinspection TypeScriptValidateTypes
    this.ngRedux.dispatch(this.subscriptionActions.load());
    //noinspection TypeScriptValidateTypes
    this.ngRedux.dispatch(PlanActions.load({ fees: true }));

    this.ngRedux.select('plans')
      .pipe(takeUntil(this.destroy$))
      .subscribe((plansData) => {
        this.keywordPlans = (plansData as any).data.filter((plan: Plan) => plan.feature === 'Keywords');
        this.ppcsPlans = (plansData as any).data.filter(p => p.feature === 'PPCS');

        this.ref.detectChanges();
      });

    this.ngRedux.select('invoices').pipe(
      takeUntil(this.destroy$))
      .subscribe((invoicesState: InvoicesState) => {
        this.invoices = invoicesState.invoices;
        this.hasNextInvoicesPage = invoicesState.nextPage != null;
        this.hasPreviousInvoicesPage = invoicesState.previousPage != null;
        this.selectedInvoicesType = invoicesState.type;
        this.loadingInvoices = invoicesState.loading;

        this.ref.markForCheck();
      });

    this.ngRedux.select(['subscriptions', '_newSubscription'])
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe(
        (newSubscription: Subscription) => {
          if (newSubscription) {
            this.onSubscriptionUpdate.next(true);
            this.dialogService.info(
              this.i18n(`You've successfully upgraded your account to the {{planName}} plan`, { planName: newSubscription.plan.name }),
              null, null, true, true
            );
          }
        }
      );

    this.ngRedux.select(['subscriptions', 'data']).pipe(
      takeUntil(this.destroy$))
      .subscribe(
        (subscriptions: Collection<StSubscription>) => {
          if (subscriptions.isNotEmpty()) {
            const keywordSubscriptions = subscriptions.filter(sub => sub.name === 'Keywords');
            const ppcsSubscriptions = subscriptions.filter(sub => sub.name === 'PPCS');

            this.activeSubscription = keywordSubscriptions.filter(s => s.active).first(false);

            if (keywordSubscriptions.count() === 2) {
              this.nextSubscription = keywordSubscriptions.get(1);
            } else {
              this.nextSubscription = keywordSubscriptions.first(false);
            }

            this.activePPCSSubscription = ppcsSubscriptions.filter(s => s.active).first(false);

            if (ppcsSubscriptions.count() === 2) {
              this.nextPPCSSubscription = ppcsSubscriptions.get(1);
            } else {
              this.nextPPCSSubscription = ppcsSubscriptions.first(false);
            }
          }

          this.ref.markForCheck();
        }
      );

    this.ngRedux.select(['subscriptions', 'data'])
      .pipe(
        filter((_data, i) => i !== 1),
        flatMap(() => {
          this.usageSummary = null;
          this.ngRedux.dispatch(this.invoiceActions.load());

          return this.userAPI.limits();
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(
        (usageSummary) => {
          this.allUsageSummary = usageSummary['user-summary'];
          this.usageSummary = usageSummary['user-summary'].filter(col => col.visible);
          this.ref.markForCheck();
        }
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    // If going from an unpaid customer to a paid customer, the free trial subscription will be cached;
    // after updating to the real subscription, the paid customer will be redirected to a
    // confirmation page (because he became a paid customer) => the cache needs to be cleared
    this.ngRedux.dispatch(this.subscriptionActions.emptyCache());
  }

  public downloadInvoice(invoice: Invoice) {
    //noinspection TypeScriptValidateTypes
    this.ngRedux.dispatch(this.invoiceActions.download(invoice));
    this.trackingService.trackEvent('Download Invoice - Button Clicked', {category: 'Billing Page'});
  }

  public openEditBillingInformationModal() {
    this.modalService.open(EditBillingInformationComponent);
    this.trackingService.trackEvent('Edit Billing - Button Clicked', {category: 'Billing Page'});
  }

  public openChooseAnotherCardModal() {
    this.modalService.open(ChooseAnotherCardComponent);
    this.trackingService.trackEvent('Choose Another Card - Button Clicked', {category: 'Billing Page'});
  }

  public save(plan?: Plan) {
    const modalComponent = this.modalService.open(UpgradeSubscriptionModalComponent, {
      plan: plan ? plan : this.selected,
      coupon: this.nextSubscription && this.nextSubscription.couponObj,
    });

    this.onSubscriptionUpdate
        .pipe(take(1))
        .subscribe(() => modalComponent.close());
  }

  public previousInvoicesPage() {
    this.ngRedux.dispatch(this.invoiceActions.load(LoadDirectionEnum.PREVIOUS));
  }

  public nextInvoicesPage() {
    this.ngRedux.dispatch(this.invoiceActions.load(LoadDirectionEnum.NEXT));
  }

  onSelect(plan: Plan) {
    this.selected = plan;
  }

  /**
   * This function looks through the usageSummary of the user for the usage user already used.
   * @param feature Feature
   * @returns Object
   */
  getFeatureUsageSummary(feature: string): { limit_reached: number, limit: number, percentage_reached: number } | null {
    if (!this.usageSummary) {
      return;
    }

    let summary;

    if (feature.includes('Seller Central Accounts')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'limit_credentials');
    } else if (feature.includes('Subaccounts')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'number_of_subusers');
    } else if (feature.includes('Active Keywords')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'prioritized_keywords_per_user');
    } else if (feature.includes('Passive Keywords')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'trivialized_keywords_per_user');
    } else if (feature.includes('Reverse ASIN / month')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'limit_reverse_asins');
    } else if (feature.includes('Keyword Autocomplete / month')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'limit_last_searches');
    } else if (feature.includes('Alerts')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'limit_alerts');
    } else if (feature.includes('Products')) {
      summary = this.allUsageSummary.find((summary) => summary.name === 'limit_products');
    }

    if (!summary) {
      return;
    }

    let limit = this.getFeatureLimit(feature);

    if (isNaN(Number(limit))) {
      limit = this.i18n('Unlimited');
    }

    return {
      ...summary,
      limit,
      percentage_reached: limit === 'Unlimited' ? 0 : summary.limit_reached > limit ? 100 : ((summary.limit_reached / limit) * 100),
    };
  }

  get featuresList(): string[] {
    if (!this.selected || !this.selected.features) {
      return [];
    }

    return this.selected.features.filter((feature: string) => feature !== 'Unlimited Products');
  }

  getFeatureLimit(feature: string): any {
    const number = feature.split(' ')[0].replace(',', '');

    if (!isNaN(Number(number))) {
      return Number(number);
    }

    return number;
  }

  public selectInvoicesType(type: InvoicesTypeEnum){
    if (type === this.selectedInvoicesType) {
      return;
    }

    this.ngRedux.dispatch(this.invoiceActions.setInvoicesType(type));
  }
}
