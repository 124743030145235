<div class="question-header">
  <div class="question-count">{{count}}</div>
  <div class="question-title" [ngClass]="{'required': question.required}">{{question.value}}</div>
</div>
<div class="question-description" *ngIf="question.description">{{question.description}}</div>

<ng-container [ngSwitch]="question.type">
  <input class="question-input" *ngSwitchCase="'short_text'" [class.required]="question.required" type="text" i18n-placeholder placeholder="Enter your answer here ..." (keyup)="textChange($event)">
  <textarea *ngSwitchCase="'long_text'" class="question-textearea" i18n-placeholder placeholder="Enter your answer here ..." cols="30" rows="10" (keyup)="textChange($event)"></textarea>
  <ng-container *ngSwitchCase="'multi_select'">
    <p i18n>Choose as many as you like</p>
    <st-checkbox-v2 *ngFor="let option of question.options" [id]="option.id" [label]="option.value" (changed)="optionSelected($event)"></st-checkbox-v2>
  </ng-container>
  <st-dropdown-v2 *ngSwitchCase="'select'" [options]="selectOptions" (selected)="dropdownSelected($event)"></st-dropdown-v2>
</ng-container>
