
import {takeUntil} from 'rxjs/operators';
import {
  AfterViewInit, ChangeDetectorRef, Component, ContentChild, ContentChildren, Input, OnChanges, OnDestroy,
  Output, QueryList, SimpleChange, SimpleChanges
} from '@angular/core';
import { ColmComponent } from '@app/shared/components/datatable/components/colm.component';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { LengthAwarePaginationCollection, PaginationCollection, } from '@app/shared/data/base.models';
import { Subject, Subscription } from 'rxjs';
import { NoContentDirective } from '@app/shared/components/datatable/directives/no-content.directive';
import {
  AccordionDirective, AccordionObject,
  AccordionTemplateDirective
} from '@app/shared/components/datatable/directives/accordion-content.directive';
import { environment } from '@env/environment';

@Component({
  selector: 'st-datatable',
  templateUrl: './datatable.component.html',
  styleUrls: ['./datatable.component.scss']
})
export class DatatableComponent implements OnChanges, AfterViewInit, OnDestroy {

  @ContentChild(NoContentDirective, { static: true })
  nocontent: NoContentDirective = null;

  @ContentChild(AccordionTemplateDirective, { static: true })
  accordionTemplate: AccordionTemplateDirective = null;

  @ContentChild(AccordionDirective, { static: true })
  accordion: AccordionDirective = null;

  /**
   * Get column templates
   */
  @ContentChildren(ColmComponent)
  colms: QueryList<ColmComponent>;

  /**
   * DataTable input
   * @type {Iterable<any>|any[]}
   */
  @Input('data')
  data: any[] = [];

  sort: any = null;

  /**
   * Redux pagination actions
   * @type {PaginationActions}
   */
  @Input('pagination')
  paginationActions: PaginationActions = null;

  /**
   * Indicates if data is loading
   * @type {boolean}
   */
  @Input()
  loading = false;

  @Input()
  theme = '';

  private _defaultSort: string = null;

  /**
   * If preceded by "-" descending order is used
   * asc: impressionP
   * desc: -impressionP
   */
  @Input()
  set defaultSort(val: string) {
    const tmp = this._defaultSort;
    this._defaultSort = val;

    if (tmp !== val) {
      this.reloadPage();
    }
  }

  get defaultSort(): string {
    return this._defaultSort;
  }


  @Input()
  isHidden: (any) => boolean = null;

  @Input()
  appendClasses: (any) => string[] = null;

  @Input()
  context: any = null;

  @Input()
  disableWhenLoading = true;

  @Input()
  autoLoad = true;

  @Input()
  showLineLoader = true;

  /**
   * Per page
   * @type {number}
   * @private
   */
  private _perPage = environment.PER_PAGE;

  @Input()
  set perPage(val: number) {
    const tmp = this._perPage;
    this._perPage = val;

    if (tmp !== val) {
      this.reloadPage();
    }
  }

  get perPage(): number {
    return this._perPage;
  }

  @Output()
  perPageChange: Subject<number> = new Subject<number>();

  /**
   * Current page
   * @type {number}
   * @private
   */
  private _page = 1;

  @Input()
  set page(val: number) {
    const tmp = this._page;
    this._page = val;

    if (tmp !== val) {
      this.reloadPage();
    }
  }

  get page(): number {
    return this._page;
  }

  @Output()
  pageChange: Subject<number> = new Subject<number>();

  paginator = 'none';
  _ready = false;

  private subscriptions: Subscription[] = [];

  private onDestroy$ = new Subject();

  private static sortFieldCompare(a: ColmComponent, b: ColmComponent) {
    if (a.sortFieldOrder === b.sortFieldOrder) {
      return 0;
    }
    return a.sortFieldOrder > b.sortFieldOrder ? 1 : -1;
  }

  @Input()
  compareTest: (a: any, b: any) => boolean = (oldItem, item) => oldItem === item

  constructor(private store: NgRedux<AppState>,
    private cd: ChangeDetectorRef) { }

  // When exiting unsubscribe
  ngOnDestroy(): void {
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   * Detect input changes
   * @param {SimpleChanges} changes
   */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.paginationActions) {
      this.handleActionsChange(changes.paginationActions);
    }
    if (changes.data) {
      this.handleDataChange(changes.data);

      // Update active accordion items if item references changed
      if (this.data != null && !!this.accordion && this.accordion.data.length > 0) {
        for (const x of this.data) {
          // Loop trough accordion items and update item if found
          this.accordion.data.forEach((ao: AccordionObject, index) => {
            if (this.compareTest(x, ao.openedItem)) {
              this.accordion.data[index] = new AccordionObject(x, ao.context$);
            }
          });
        }
      }
    }
  }

  /**
   * After view init
   */
  ngAfterViewInit(): void {
    this._ready = true;
    this.cd.detectChanges();
    if (this.autoLoad) {
      this.reloadPage();
    }

    this.colms.changes.pipe(takeUntil(this.onDestroy$)).subscribe(this.subscribeToChanges.bind(this));
    this.subscribeToChanges();
  }

  private subscribeToChanges() {
    // Unsubscribe
    this.subscriptions.forEach((s: Subscription) => s.unsubscribe());
    // Subscribe
    this.subscriptions = this.colms.map((c: ColmComponent) => c.sortChange.subscribe((field) => {
      this.colms.forEach((c: ColmComponent) => {
        if (c.sortField !== field && !c.forceSort) {
          c.sortDir = 0;
        }
      });
      this.reloadPage();
    }));
  }

  private handleDataChange(change: SimpleChange) {
    // Preserve old data when loading
    if (this.loading && change.currentValue.length <= 0 && change.previousValue !== undefined) {
      this.data = change.previousValue;
    }

    if (this.data instanceof LengthAwarePaginationCollection) {
      this.paginator = 'default';
    } else if (this.data instanceof PaginationCollection) {
      this.paginator = 'simple';
    }

    if (this.data instanceof PaginationCollection && !this.loading) {
      // If not loading then fix inconsistent data
      if (this.data.hasPages()) {
        if (this.data.pageIsOutOfScope()) {
          this._page = 1;
          this.pageChange.next(this.page);
        } else if (this.data.getCurrentPage() && this.page !== this.data.getCurrentPage()) {
          this._page = this.data.getCurrentPage();
          this.pageChange.next(this.page);
        }

        if (this.perPage !== this.data.getPerPage()) {
          this._perPage = this.data.getPerPage();
          this.perPageChange.next(this.perPage);
        }
        this.cd.markForCheck();
      }
    }
  }

  showCounters() {
    return this.data instanceof PaginationCollection && this.data.hasPages();
  }

  showTotal() {
    return this.data instanceof LengthAwarePaginationCollection && this.data.hasPages();
  }

  /**
   * Handle change of pagination actions change.
   * Dispatch init and request first page.
   * @param {SimpleChange} change
   */
  private handleActionsChange(change: SimpleChange) {
    if (this.paginationActions) {
      setTimeout(() => {
        this.store.dispatch(this.paginationActions.initData(this.context));
      }, 0);
    }
  }

  perPageChanged() {
    // When per page changed set page to first page
    this._page = 1;
    this.pageChange.next(this.page);
    this.perPageChange.next(this.perPage);
  }

  protected pageChanged() {
    this.pageChange.next(this.page);
  }

  public reload() {
    if (this.paginationActions) {
      this.store.dispatch(this.paginationActions.initData(this.context));
    }
    this.reloadPage();
  }

  protected reloadPage() {
    if (this.colms !== undefined) {
      const sort: string[] = this.colms
        .filter((c: ColmComponent) => c.sortField !== null && c.sortDir !== 0)
        .sort(DatatableComponent.sortFieldCompare)
        .map((c: ColmComponent) => {
          return c.sortDir > 0 ? c.sortField : '-' + c.sortField;
        });

      if (this.defaultSort) {
        sort.push(this.defaultSort);
      }

      if (this._ready && this.paginationActions) {
        setTimeout(() => {
          this.store.dispatch(this.paginationActions.loadPage(this.page, this.perPage, sort, this.context));
        }, 0);
      } else if (Array.isArray(this.data)) {
        this.sort = sort;
      }
    }
  }

  isDisabled(item) {
    if (!this.isHidden) {
      return false;
    }
    return this.isHidden(item);
  }

  getClassList(item) {
    if (!this.appendClasses) {
      return [];
    }
    return this.appendClasses(item);
  }
}
