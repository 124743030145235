import { BaseModel } from "@app/shared/data/base.models";

export class Commission extends BaseModel {
  static className = 'Commission';

  number: string;
  remote_id: string;
  coupon: string;
  lead_email: string;
  percentage: number;
  amount: number;
  plan_name: string;
  status: string;
  scheduled_for: string;
  paid_at?: Date;
  cancelled_at?: Date;
  created_at: Date;

  public pending(): boolean {
    return this.status === 'pending';
  }

  public paid(): boolean {
    return this.status === 'paid';
  }

  public cancelled(): boolean {
    return this.status === 'cancelled' && this.amount > 0;
  }
}
