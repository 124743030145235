import { ActivatedRoute } from "@angular/router";

export class RoutingService {

  public static extractPath(route: ActivatedRoute) {
    const path = this
      .flattenRoute(route)
      .map((routePart: ActivatedRoute) => {
        return routePart.snapshot.routeConfig?.path || '';
      })
      .filter(Boolean)
      .join('/');

    return '/' + path;
  }

  private static flattenRoute(route: ActivatedRoute):ActivatedRoute[] {
    var routes = [route];
    while (route.firstChild) {
      route = route.firstChild;
      routes.push(route);
    }
    return routes;
  }
}
