import {BaseModel} from '@app/shared/data/base.models';

export class ApiToken extends BaseModel {
  value: string;
  name: string;
  credential_id: number;
  credential_name?: string;
  marketplace_name?: string;
  is_revoked?: boolean;
}
