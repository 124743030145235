import {
  Component, Input, OnChanges, Output, SimpleChanges
} from '@angular/core';
import { Subject } from "rxjs";

@Component({
  selector: 'st-pager',
  templateUrl: './st-pager.component.html',
  styleUrls: ['./st-pager.component.scss']
})
export class StPagerComponent implements OnChanges{
  @Input()
  currentPage:number = 1;

  @Input()
  lastPage:number = 1;

  @Output()
  currentPageChange: Subject<number> = new Subject<number>();

  pages: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.currentPage || changes.lastPage) {
      this.pages = [];
      for(let n:number = 1; n <= this.lastPage; n++) {
        const add = Math.max(4-this.currentPage, 0)
          + Math.max((this.currentPage + 3)-this.lastPage, 0);

        if(Math.abs(n - this.currentPage) < (3 + add) || n == 1 || n == this.lastPage)
          this.pages.push(n);
      }

      if(this.pages[1] > 2)
        this.pages[1] = null;

      if(this.pages[this.pages.length - 2] < this.lastPage-1)
        this.pages[this.pages.length - 2] = null;

    }
  }
}
