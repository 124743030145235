import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from "@app/shared/layout/breadcrumb/breadcrumb.component";
import { RouterModule } from '@angular/router';

let COMPONENTS = [
  BreadcrumbComponent
];
let DIRECTIVES = [
];

@NgModule({
  imports     : [
    CommonModule,
    RouterModule
  ],
  declarations: [
    ...COMPONENTS, ...DIRECTIVES,
  ],
  exports     : [
    ...COMPONENTS, ...DIRECTIVES,
  ]
})
export class BreadcrumbModule {
}
