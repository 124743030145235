import { Injectable } from '@angular/core';
import { User } from '../data/user/user.models';
import smartLook from 'smartlook-client';
import { timezones } from '@app/shared/data/timezones';
import { environment } from '@env/environment';
import { FeatureFlaggingEnum, FeatureFlaggingService, ISetting } from '@app/shared/services/feature-flagging/feature-flagging.service';
import { filter, take } from 'rxjs/operators';

@Injectable()
export class SmartLookTrackingService {
  initiated = false;

  constructor(private featureFlaggingService: FeatureFlaggingService) {
  }

  public init() {
    if (!Boolean(environment.SMARTLOOK_KEY)) {
      return;
    }

    this.featureFlaggingService.getFeatureFlag(FeatureFlaggingEnum.SMARTLOOK_TRACKING)
      .pipe(
        filter((setting: ISetting) => setting && Boolean(setting.settingKey)),
        take(1)
      )
      .subscribe((setting: ISetting) => {
        if (setting.settingValue === false) {
          return;
        }

        this.initiated = true;
        smartLook.init(environment.SMARTLOOK_KEY);
      });
  }

  public identify(user: User) {
    if (!(user && user.id) || !this.initiated) {
      return;
    }

    const timezone = timezones.find(t => t.id === user.timezone);
    smartLook.identify(user.id.toString(), {
      email: user.email,
      created_at: new Date(user.registration_finished_at).toISOString(),
      name: `${user.first_name} ${user.last_name}`,
      language_override: user.locale,
      phone: user.phone_number,
      timezoneOffset: timezone ? timezone.value : null,
      timezoneLabel: timezone ? timezone.label : null,
      plan: user.plan_name,
      subAccount: user.isSubUser(),
      source: user.source,
      referrer: user.referrer,
      utm_source: user.utm_source,
      utm_medium: user.utm_medium,
      utm_campaign: user.utm_campaign,
      utm_term: user.utm_term,
      utm_content: user.utm_content,
    });
  }

  public shutdown() {
    smartLook.disable();
  }

  public trackEvent(eventName: string, eventData: { [key: string]: any } = {}) {
    if (!this.initiated) {
      return;
    }

    smartLook.track(eventName, eventData);
  }
}
