import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { Collection } from '@app/shared/data/base.models';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';
import { ApiTokenApi } from '@app/shared/data/api-token/api-token.api';
import { ApiTokenActions } from '@app/shared/data/api-token/api-token.actions';

@Injectable()
export class ApiTokenEpics {
  constructor(private apiTokenActions: ApiTokenActions,
              private apiTokensApi: ApiTokenApi,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.load,
      this.create,
      this.revoke,
    ];
  }

  load = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === ApiTokenActions.TYPES.LOAD) {
        this.apiTokensApi.all(action.data.credential_id, action.data.params)
          .subscribe(
            (tokens: Collection<ApiToken>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.loadSucceeded(tokens));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.loadFailed(response));
            },
            () => {

            }
          );
      }

      if (action.type === ApiTokenActions.TYPES.LOAD_ALL) {
        this.apiTokensApi.allTokens()
          .subscribe(
            (tokens: Collection<ApiToken>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.loadAllSucceeded(tokens));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.loadFailed(response));
            }
          );
      }

      if (action.type === ApiTokenActions.TYPES.GET_SINGLE) {
        this.apiTokensApi.getSingleToken(action.data.credentialId, action.data.obfuscatedTokenValue)
          .subscribe((token: ApiToken) => {
            this.ngRedux.dispatch(ApiTokenActions.getSingleSuccess(token));
          },
          (response) => {
            this.ngRedux.dispatch(ApiTokenActions.getSingleFailure(response));
          });
      }

      return next(action);
    };
  }

  create = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === ApiTokenActions.TYPES.CREATE) {
        this.apiTokensApi.create(action.data.credentialId, action.data.testing)
          .subscribe(
            (token: ApiToken) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.createSucceeded(token));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.createFailed(response));
            }
          );
      }

      return next(action);
    }
  }

  revoke = (store: NgRedux<AppState>)  => next => {
    return (action) => {
      if (action.type === ApiTokenActions.TYPES.REVOKE) {
        var token = action.data;
        this.apiTokensApi.revoke(token.credential_id, token)
          .subscribe(
            (token: ApiToken) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.revokeSucceeded(token));
              this.ngRedux.dispatch(ApiTokenActions.create(token.credential_id, token.name === 'Test'));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(ApiTokenActions.revokeFailed(response));
            }
          );
      }

      return next(action);
    }
  }
}
