
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { Wallet } from './wallet.models';

@Injectable()
export class WalletApi extends BaseAPI {

  constructor(protected http: HttpClient) {
    super();
  }

  public get(): Observable<Wallet> {
    return this.http.get(this.constructFullUrl(`api/wallet`)).pipe(
      map(d => new Wallet(d)));
  }
}
