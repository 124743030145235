import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import { PayoutCommisionAdmin } from "@app/shared/data/admin/payout-commision/payout-commision.model";

@Injectable()
export class PayoutCommisionAdminActions extends PaginationActions {
  protected model = PayoutCommisionAdmin;

  static readonly TYPES = {
    FILTER   : '[PAYOUTCOMMISIONADMIN]FILTER',
    COMMISION: '[PAYOUTCOMMISIONADMIN]COMMISION',
  };

  filter(filters): StAction {
    return {
      type: PayoutCommisionAdminActions.TYPES.FILTER,
      data: filters
    }
  }

  commision(commision: PayoutCommisionAdmin): StAction {
    return {
      type: PayoutCommisionAdminActions.TYPES.COMMISION,
      data: {
        commision: commision,
      }
    }
  }
}
