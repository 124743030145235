import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { ReverseAsinActions } from '@app/shared/data/reverse-asin/reverse-asin.actions';
import { catchError } from 'rxjs/operators';
import { handleError } from '@app/helpers';

@Injectable()
export class ReverseAsinAPI extends BaseAPI {

  baseApiUrl = 'api/reverse-asin';
  baseProductsApiUrl = 'api/v2/products';

  constructor(protected http: HttpClient) {
    super();
  }

  public requestKeywords(
    asins: string[],
    compare_asin: string,
    group_id: string,
    marketplace: { id: number },
    depth: number,
    resolveChildren: boolean,
  ): Observable<any> {
    return this.http.post(this.constructFullUrl(this.baseApiUrl + '/keywords'), {
      asins: asins,
      compare_asin: compare_asin,
      group_id: group_id,
      amazon_marketplace_id: marketplace.id,
      depth: depth,
      resolve_children: resolveChildren,
    });
  }

  public loadHistory(): any {
    return this.http.get(this.constructFullUrl(this.baseApiUrl), {params: {history: 'true'}});
  }

  public findProductsForKeyword(keyword: string) {
    const params = {
      keyword: keyword,
      flow   : ReverseAsinActions.FIND_PRODUCTS_FOR_KEYWORDS_TASK_ID
    };
    const url = this.constructFullUrl(this.baseApiUrl + '/products-for-keyword');
    return this.http.post(url, params);
  }

  public trackKeywords(asin: string, keywords: string[], priority?: string) {
    let object: any = {
      keywords,
    };

    const maxKeywordsPerRequest = 500;
    const numberOfRequests = Math.ceil(keywords.length / maxKeywordsPerRequest);
    const requests = [];

    for (var i = 0; i < numberOfRequests; i++) {
      object = {
        ...object,
        keywords: keywords.slice(i * maxKeywordsPerRequest, (i + 1) * maxKeywordsPerRequest),
      };

      if (priority) {
        object = {
          ...object,
          minimum_priority_limit: priority
        };
      }

      requests.push(this.http.post(this.constructFullUrl(`${this.baseProductsApiUrl}/${asin}/keywords`), object));
    }

    return forkJoin(requests).pipe(catchError(handleError));
  }

  public removeKeywordsTracking(asin: string, keywords: string[]) {
    return this.http.delete(this.constructFullUrl(`${this.baseProductsApiUrl}/${asin}/keywords`), {
      body: {
        keywords
      }
    });
  }
}
