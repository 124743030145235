import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { ApiPromotion } from '@app/shared/data/api-promotion/api-promotion.models';
import { ApiPromotionActions } from '@app/shared/data/api-promotion/api-promotion.actions';
import { Product } from '@app/shared/data/product/product.models';

const INITIAL_STATE: AppState = {
  data    : new Collection<ApiPromotion>([]),
  loading : false,
  error   : null,
  modal   : {
    data   : new ApiPromotion,
    loading: false,
    saving: false,
    error  : null,
    products: new Collection<Product>([]),
  }
};

export function apiPromotionReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type === ApiPromotionActions.TYPES.FETCH_SUCCEEDED) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        data   : action.data,
        saving: false,
        error  : null,
        loading: false,
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.FETCH_FAILED) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        data   : null,
        saving: false,
        error  : action.data,
        loading: false
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.FETCH || action.type === ApiPromotionActions.TYPES.FETCH_STATS) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        data   : new ApiPromotion,
        saving: false,
        error  : null,
        loading: true,
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SAVE) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        data   : new ApiPromotion,
        saving: true,
        error  : null,
        loading: false,
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SAVE_SUCCEEDED) {
    return {
      ...state,
      data: state['data']
        .filter(apiPromotion => apiPromotion.id !== action.data.id)
        .push(action.data)
        .sort((a: ApiPromotion, b: ApiPromotion) => {
          return b.id - a.id;
        }),
      modal: {
        ...state['modal'],
        data   : action.data.clone(ApiPromotion, {
          _saved: true,
        }),
        error  : null,
        saving: false,
        loading: false,
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SAVE_FAILED) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        data   : null,
        error  : action.data,
        saving: false,
        loading: false
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SAVE_COUPONS) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        // data   : new ApiPromotion,
        saving: true,
        error  : null,
        loading: false,
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SAVE_COUPONS_SUCCEEDED) {
    return {
      ...state,
      data: state['data']
        .filter(apiPromotion => apiPromotion.id !== action.data.id)
        .push(action.data)
        .sort((a: ApiPromotion, b: ApiPromotion) => {
          return b.id - a.id;
        }),
      modal: {
        ...state['modal'],
        data   : new ApiPromotion({
          ...state['modal']['data'],
          _saved: true,
          _saved_coupons: true,
        }),
        error  : null,
        saving: false,
        loading: false,
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SAVE_COUPONS_FAILED) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        data   : new ApiPromotion({
          ...state['modal']['data'],
          _saved: false,
          _saved_coupons: false,
        }),
        error  : action.data,
        saving: false,
        loading: false
      }
    };
  }


  if (action.type === ApiPromotionActions.TYPES.SEARCHING_FOR_PRODUCT) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        products: new Collection<Product>([]),
        error  : null,
        loading: true,
      }
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SEARCHING_FOR_PRODUCT_SUCCEEDED) {
    return {
      ...state,
      modal: {
        ...state['modal'],
        products: action.data.products,
        error  : null,
        loading: false,
      },
    };
  }

  if (action.type === ApiPromotionActions.TYPES.SEARCHING_FOR_PRODUCT_FAILED) {
    let err = null;

    if (action.data.error.status === 422) {
      err = 'Product not found. Check that this product exists.';
    }

    return {
      ...state,
      modal: {
        ...state['modal'],
        error  : err,
        loading: false,
      },
    };
  }


  return paginationReducer(ApiPromotion)(state, action);
}
