
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Collection } from "@app/shared/data/base.models";
import { BaseAPI } from "@app/shared/data/base.api";
import { AdminCoupon } from "@app/shared/data/admin/coupon/coupon.models";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";

@Injectable()
export class AdminCouponAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<AdminCoupon>> {
    return this.http.get(this.constructFullUrl('api/admin/coupons'), { params: params }).pipe(
      map(data => AdminCoupon.collect<AdminCoupon>(data, AdminCoupon.BACKEND_PAGINATION)));
  }

  public create(code: AdminCoupon): Observable<AdminCoupon> {
    return this.http.post(this.constructFullUrl('api/admin/coupons'), code).pipe(
      map(data => new AdminCoupon(data)));
  }

  public get(coupon: AdminCoupon) {
    return this.http.get(this.constructFullUrl(`api/admin/coupons/${coupon.code}`)).pipe(
      catchError(e => {
        return observableThrowError(e.error);
      }),
      map(data => new AdminCoupon(data)),);
  }

  public remove(coupon: AdminCoupon): Observable<any> {
    return this.http.delete(this.constructFullUrl((`api/admin/coupons/${coupon.code}`)));
  }
}
