import './moduleaugmentation';

import { enableProdMode, LOCALE_ID, MissingTranslationStrategy, TRANSLATIONS, TRANSLATIONS_FORMAT } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";

import { AppModule } from '@app/app.module';
import { environment } from '@env/environment';

import { detectLocale } from '@app/helpers';
import { Localization } from '@app/shared/data/localization/localization.models';

if (environment.SENTRY_DSN) {
  Sentry.init({
    dsn: environment.SENTRY_DSN,
    environment: environment.SENTRY_ENVIRONMENT,
    integrations: [
      new BrowserTracing({
        tracingOrigins: environment.SENTRY_SAMPLE_ORIGINS,
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.SENTRY_SAMPLE_RATE,
  });
}

if (environment.production) {
  enableProdMode();
}

if (environment.DEPLOYED_AT) {
  // tslint:disable-next-line:no-console
  console.info('Last deploy: ', new Date(environment.DEPLOYED_AT * 1000));
}

// use the require method provided by webpack
declare const require;
const detectedLocale = detectLocale();
let translationsFile = '';
if (detectedLocale && Localization.isTranslated(detectedLocale)) {
  translationsFile = require(`raw-loader!./locale/messages.${detectedLocale}.xlf`);
}

// build 5 -> https://v5.angular.io/guide/deployment
// i18n 5 -> https://v5.angular.io/guide/i18n
const bootstrap = () => platformBrowserDynamic([
  {
    provide: LOCALE_ID,
    useValue: detectedLocale
  },
  {
    provide: TRANSLATIONS, // Needed twice
    useValue: translationsFile
  },
  {
    provide: TRANSLATIONS_FORMAT,
    useValue: 'xlf'
  },
]).bootstrapModule(AppModule, {
  missingTranslation: MissingTranslationStrategy.Warning,
  providers: [
    {
      provide: TRANSLATIONS, // Needed twice
      useValue: translationsFile
    },
  ]
}).catch(err => console.error(err));

bootstrap();



