import { Component, EventEmitter, Input, Output } from "@angular/core";
import { V2TableColumn } from "../../table.models";

@Component({
  selector: 'st-table-column-string',
  templateUrl: './table-columns.string.component.html',
  styleUrls: ['./table-columns.string.component.scss']
})
export class TableColumnStringComponent {
  @Input() column: V2TableColumn;
  @Output() onSortBy: EventEmitter<any> = new EventEmitter();

  sortBy() {
    this.onSortBy.emit(this.column.sortBy);
  }
}
