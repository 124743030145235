import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Notification } from '@app/shared/data/notification/notification.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import {Collection} from '@app/shared/data/base.models';


export interface NotificationAction extends StAction {
}

@Injectable()
export class NotificationsActions extends PaginationActions {
  protected model = Notification;

  static readonly TYPES = {
    NOTIFICATION_MODAL_SHOW          : '[NOTIFICATIONS]NOTIFICATION_MODAL_SHOW',
    NOTIFICATION_MODAL_FILTER        : '[NOTIFICATIONS]NOTIFICATION_MODAL_FILTER',
    NOTIFICATION_MODAL_CLOSE         : '[NOTIFICATIONS]NOTIFICATION_MODAL_CLOSE',
    NOTIFICATION_SET_PAGE            : '[NOTIFICATIONS]NOTIFICATION_SET_PAGE',
  };

  static showAllNotificationsModal(): NotificationAction {
    return {
      type: NotificationsActions.TYPES.NOTIFICATION_MODAL_SHOW,
      data: null
    };
  }

  static filterNotificationsModal(data): NotificationAction {
    return {
      type: NotificationsActions.TYPES.NOTIFICATION_MODAL_FILTER,
      data: data
    };
  }

  static allNotificationsModalClosed(): NotificationAction {
    return {
      type: NotificationsActions.TYPES.NOTIFICATION_MODAL_CLOSE,
      data: null
    };
  }

  static setPage(notifications: Collection<object>): NotificationAction {
    return {
      type: NotificationsActions.TYPES.NOTIFICATION_SET_PAGE,
      data: notifications
    };
  }
}
