<st-dialog>
    <div dialog-header class="header">
        <div class="question">{{ title }}</div>
    </div>
    <div dialog-content>
        <div [innerHTML]="message"></div>
    </div>
    <div dialog-footer>
        <st-button *ngFor="let button of buttons" (click)="click(button)">{{ button.name }}</st-button>
    </div>
</st-dialog>
