
import {from as observableFrom, throwError as observableThrowError, empty as observableEmpty,  Observable } from 'rxjs';

import {mergeMap, catchError} from 'rxjs/operators';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';

import { ModalService } from '@app/shared/components/modals/modal.service';
import { UpdatePlanComponent } from '@app/client/modals/update-plan/update-plan.component';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private injector: Injector,
    private modalService: ModalService,
    private i18n: I18n,
  ) { }

  /**
   * Catch authorization errors (subscription, no active credential, permission denied)
   * and perform required action.
   *
   * @param request
   * @param next
   * @returns {Observable<R|T>|Observable<R>}
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(catchError(response => {
      let error = response.error;
      if ('error' in error) {
        error = error.error;
      }

      if (response.status === 403) {
        switch (error.error_code) {

          case 'error.not_subscribed':
            this.router.navigate(['/billing']);
            return observableEmpty();

          case 'error.no_active_credentials':
            this.router.navigate(['/credentials']);
            return observableEmpty();

          case 'error.limits_reached':
            const type = error['details']['type'];
            return this.openUpgradePlanModal(request, next, response, type);

          default:
        }
      }

      return observableThrowError(response);
    }));
  }

  private openUpgradePlanModal(request: HttpRequest<any>, next: HttpHandler, response: any, type: string): Observable<any> {
    try {
      const comp = this.modalService.open(UpdatePlanComponent);

      if (type === 'keywords_limit_reached') {
        comp.instance.title$.next(this.i18n('You have reached the number limit of tracked keywords'));
        comp.instance.message$.next(this.i18n('Please upgrade your subscription plan or remove keywords to be able to add the new ones'));
      } else if (type === 'competitor_research_limit_reached') {
        comp.instance.title$.next(this.i18n('Competition products limit reached'));
        comp.instance.message$.next(this.i18n('Maximum number of competitors reached. Upgrade your account to increase it.')); 
      }

      return observableFrom(comp.result).pipe(
        mergeMap((confirmed) => {
          if (confirmed) {
            // Repeat initial request
            return next.handle(request);
          }
          return observableThrowError(response);
        }));
    } catch (e) {
      return observableThrowError(response);
    }
  }
}
