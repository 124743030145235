<button [disabled]="disabled" (mouseover)="hovered()" (mouseout)="unhovered()" [type]="type"
  class="button"
  [style.justifyContent]="justify"
  [class.button--primary]="color === 'primary'"
  [class.button--light-gray]="color === 'light-gray'"
  [class.button--light-blue]="color === 'light-blue'"
  [class.button--empty]="color === 'empty'"
  [class.button--transparent]="color === 'transparent'"
  [class.button--gray]="color === 'gray'"
  [class.button--v2_red]="color === 'v2_red'"
  [class.button--v2_purple]="color === 'v2_purple'"
  [class.button--v2_red_empty]="color === 'v2_red_empty'"
  [class.button--v2_blue_empty]="color === 'v2_blue_empty'"
  [class.button--v2_blue]="color === 'v2_blue'"
  [class.button--v2_teal]="color === 'v2_teal'"
  [class.button--v2_light_blue]="color === 'v2_light_blue'"
  [class.button--black]="color === 'black'"
  [class.button--small]="size === 'small'"
  [class.button--dynamic]="size === 'dynamic'"
  [class.button--dynamic-large]="size === 'dynamic-large'"
  [class.button--normal]="size === 'normal'"
  [class.button--large]="size === 'large'"
  [class.button--x-large]="size === 'x-large'"
  [class.all-round]="edges === 'all-round'"
  [class.one-corner]="edges === 'one-corner'"
  [class.button--icon-reversed]="iconPosition === 'after'"
  [class.button--full-width]="fullWidth" (click)="handleClick()" #button>
  <st-icon icon="spinner" class="icon-responsive" *ngIf="loading"></st-icon>
  <svg-icon class="button__icon" *ngIf="icon" [src]="iconPath"></svg-icon>
  <div class="button__label">{{label}}</div>
</button>
