import { Component, OnInit } from '@angular/core';
import { PaymentService } from '@app/core/services/payment.service';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { takeUntil } from 'rxjs/operators';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { Subject } from 'rxjs';
import { IUserState } from '@app/shared/data/user/user.reducers';

@Component({
  selector: 'st-choose-another-card',
  templateUrl: './choose-another-card.component.html',
  styleUrls: ['./choose-another-card.component.scss']
})
export class ChooseAnotherCardComponent implements OnInit {
  public errorMessage = '';
  public saving = false;

  public translateBreadcrumbs: string[] = [this.i18n('Update Payment Method'), 'SellerTools'];

  public secret = null;
  public cardholderName = '';

  private destroy$ = new Subject<void>();

  constructor(
    private userActions: UsersActions,
    private ngRedux: NgRedux<AppState>,
    private activeDialog: ActiveModal,
    private paymentService: PaymentService,
    private i18n: I18n,
  ) {
  }

  ngOnInit() {
    this.paymentService.getFormSecret()
      .subscribe((secret) => {
        this.secret = secret;

        this.paymentService.initCardField('#card-element');
      });
    this.ngRedux.dispatch(this.userActions.updateCreditCardParameterMissing());

    this.ngRedux.select(['user'])
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((user: IUserState) => {
        this.errorMessage = user._error;
        this.saving = user._is_adding_credit_card;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save() {

    if (this.cardholderName === '') {
      this.ngRedux.dispatch(this.userActions.updateCreditCardParameterMissing(this.i18n('Cardholder Name is required parameter')));
      return;
    }

    this.paymentService.addCreditCard(this.secret, this.cardholderName).then(
      () => {
        this.activeDialog.close('SAVED');
      }
    );
  }

  public cancel() {
    this.activeDialog.close();
  }
}
