import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { AdminSurvey } from "@app/shared/data/admin/survey/survey.models";
import { AdminSurveyActions } from "@app/shared/data/admin/survey/survey.actions";

export const SURVEYS_STORAGE_KEY = 'admin-surveys';

const INITIAL_STATE: AppState = {
  data   : new Collection<AdminSurvey>([]),
  loading: false,
  error  : null,
  selected   : {
    data    : new AdminSurvey(),
    error   : null,
    _loading: false
  },
  results   : {
    data    : new AdminSurvey(),
    error   : null,
    _loading: false
  },
  // selected: false,
  _filters      : {},
  _be_pagination: {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage(SURVEYS_STORAGE_KEY) || 50
  }
};

export function adminSurveyReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == PaginationActions.getPagActionTypes(AdminSurvey).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, SURVEYS_STORAGE_KEY);

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == AdminSurveyActions.TYPES.GET) {
    return {
      ...state,
      selected: {
        data    : null,
        error   : null,
        _loading: true
      }
    }
  }

  if (action.type == AdminSurveyActions.TYPES.GET_SUCCEEDED) {
    return {
      ...state,
      selected: {
        data    : action.data,
        error   : null,
        _loading: false
      }
    }
  }

  if (action.type == AdminSurveyActions.TYPES.GET_FAILED) {
    return {
      ...state,
      selected: {
        error   : action.data,
        _loading: false
      }
    }
  }

  if (action.type == AdminSurveyActions.TYPES.GET_RESULTS) {
    return {
      ...state,
      results: {
        data    : null,
        error   : null,
        _loading: true
      }
    }
  }

  if (action.type == AdminSurveyActions.TYPES.GET_RESULTS_SUCCEEDED) {
    return {
      ...state,
      results: {
        data    : action.data,
        error   : null,
        _loading: false
      }
    }
  }

  if (action.type == AdminSurveyActions.TYPES.GET_RESULTS_FAILED) {
    return {
      ...state,
      results: {
        error   : action.data,
        _loading: false
      }
    }
  }

  if (action.type == AdminSurveyActions.TYPES.SAVE) {
    return {
      ...state,
      selected: {
        error   : null,
        _loading: true
      }
    }
  }

  if (action.type == AdminSurveyActions.TYPES.SAVE_FAILED) {
    return {
      ...state,
      selected: {
        error   : action.data,
        _loading: false
      }
    }
  }

  // if (action.type == AdminSurveyActions.TYPES.SET_SELECTED) {
  //   return {
  //     ...state,
  //     selected: action.data.id || null
  //   }
  // }

  if (action.type == AdminSurveyActions.TYPES.SAVE_SUCCEEDED) {
    return {
      ...state,
      data: state['data']
        .filter(plan => plan.id != action.data.id)
        .push(action.data)
        .sort((a: AdminSurvey, b: AdminSurvey) => {
          return b.id - a.id;
        }),
      selected: {
        data    : action.data,
        error   : null,
        _loading: false
      }
    }
  }

  return paginationReducer(AdminSurvey)(state, action);
}
