import { Injectable } from '@angular/core';
import { ITopUpSource } from '@app/shared/data/wallet-transactions/wallet-top-up-sources.models';
import { StAction } from '@app/shared/data/st-action';

@Injectable()
export class WalletTopUpSourcesActions {
  static readonly TYPES = {
    LOAD_SOURCES: '[WALLET_TOP_UP_SOURCES]LOAD',
    LOAD_SOURCES_SUCCEEDED: '[WALLET_TOP_UP_SOURCES]LOAD_SUCCEEDED',
    LOAD_SOURCES_FAILED: '[WALLET_TOP_UP_SOURCES]LOAD_FAILED',
    START_ACH_MANDATE_FLOW: '[WALLET_TOP_UP_SOURCES]START_ACH_MANDATE_FLOW',
    FINISH_ACH_MANDATE_FLOW: '[WALLET_TOP_UP_SOURCES]FINISH_ACH_MANDATE_FLOW',
    LINK_ACH: '[WALLET_TOP_UP_SOURCES]LINK_ACH',
    LINK_ACH_SUCCEEDED: '[WALLET_TOP_UP_SOURCES]LINK_ACH_SUCCEEDED',
    LINK_ACH_FAILED: '[WALLET_TOP_UP_SOURCES]LINK_ACH_FAILED',
    REMOVE_ACH: '[WALLET_TOP_UP_SOURCES]REMOVE_ACH',
    REMOVE_ACH_SUCCEEDED: '[WALLET_TOP_UP_SOURCES]REMOVE_ACH_SUCCEEDED',
    REMOVE_ACH_FAILED: '[WALLET_TOP_UP_SOURCES]REMOVE_ACH_FAILED',
  };

  loadSources(): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.LOAD_SOURCES,
      data: {}
    };
  }

  loadSourcesSucceeded(sources: ITopUpSource[]): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.LOAD_SOURCES_SUCCEEDED,
      data: {
        sources,
      }
    };
  }

  loadSourcesFailed(error: any): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.LOAD_SOURCES_FAILED,
      data: {
        error,
      }
    }
  }

  startACHMandateFlow(): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.START_ACH_MANDATE_FLOW,
      data: null
    };
  }

  finishACHMandateFlow(): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.FINISH_ACH_MANDATE_FLOW,
      data: null
    };
  }

  linkACH(setupIntentId: string, paymentMethodId: string): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.LINK_ACH,
      data: {
        setupIntentId,
        paymentMethodId,
      }
    };
  }

  linkACHSucceeded(sources: ITopUpSource[]): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.LINK_ACH_SUCCEEDED,
      data: {
        sources,
      }
    };
  }

  linkACHFailed(error: any): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.LINK_ACH_FAILED,
      data: {
        error,
      }
    };
  }

  removeACH(): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.REMOVE_ACH,
      data: {
      }
    };
  }

  removeACHSucceeded(sources: ITopUpSource[]): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.REMOVE_ACH_SUCCEEDED,
      data: {
        sources,
      }
    };
  }

  removeACHFailed(error: any): StAction {
    return {
      type: WalletTopUpSourcesActions.TYPES.REMOVE_ACH_FAILED,
      data: {
        error,
      }
    };
  }
}
