import { BaseModel, Collection, FePaginationCollection } from '@app/shared/data/base.models';
import { Filter } from '@app/shared/components/filter-builder/filter/filter';
import { Alert } from '@app/shared/components/alert-builder/alert/alert';
import { AlertsService } from '@app/client/alerts/alerts.service';

export class AlertsModel extends BaseModel {
  static className = 'AlertsModel';

  _alerts?: FePaginationCollection<BEAlert>;
  _issues?: FePaginationCollection<AlertIssue>;

  paginatedAlerts?: FePaginationCollection<BEAlert>;
  paginatedIssues?: FePaginationCollection<AlertIssue>;

  alerts_page_opened = 1;
  alerts_per_page = 10;
  issues_page_opened = 1;
  issues_per_page = 10;

  constructor(data = {}) {
    super({
      _alerts: new FePaginationCollection<BEAlert>(),
      _issues: new FePaginationCollection<AlertIssue>(),
      paginatedAlerts: new FePaginationCollection<BEAlert>(),
      paginatedIssues: new FePaginationCollection<AlertIssue>(),
      ...data,
    });

    if ('alerts' in data) {
      this.alerts = data['alerts'];
    }

    if ('issues' in data) {
      this.issues = data['issues'];
    }
  }

  set alerts(alerts: any) {
    this._alerts = new FePaginationCollection<BEAlert>(alerts);
    this.refreshAlertsPagination();
  }

  set issues(issues: any) {
    this._issues = new FePaginationCollection<AlertIssue>(issues);
    this._issues.sort((a: AlertIssue, b: AlertIssue) => b.detected_on.valueOf() - a.detected_on.valueOf());
    this.refreshIssuesPagination();
  }

  get alerts() {
    return this._alerts;
  }

  get issues() {
    return this._issues;
  }

  refreshAlertsPagination() {
    this.paginatedAlerts = this._alerts.createPage({
      page: this.alerts_page_opened,
      perPage: this.alerts_per_page,
      sort: [],
    });
  }

  refreshIssuesPagination() {
    this.paginatedIssues = this._issues.createPage({
      page: this.issues_page_opened,
      perPage: this.issues_per_page,
      sort: [],
    });
  }

  transformToAlertGroup() {
    return new AlertGroup({
      be_alerts: BEAlert.collect<BEAlert>(this.alerts),
    });
  }
}

export class ProductAlerts extends AlertsModel {
  static className = 'ProductAlerts';

  asin: string;
  title: string;
  image_url: string;
  pre_opened: boolean;
  opened: boolean;
  data_loaded: boolean;

  alerts_count: number;
  alert_ids: number[];
  issues_count: number;
  issue_ids: number[];
}

export class UserAlerts extends AlertsModel {
  static className = 'UserAlerts';
}

export class BEAlert extends BaseModel {
  static className = 'BEAlert';

  id: string;
  credential_id: number;
  products: any[];
  app_channel: boolean;
  email_channel: boolean;
  email_channel_subusers: any;
  sms_channel: boolean;
  webhook_channel: boolean;
  webhook_channel_destinations: any;
  type: string;
  condition?: string;
  condition_val?: string;
  type_val?: string;
  issues?: FePaginationCollection<AlertIssue>;
  template: boolean;

  firstAsin(): string {
    return this.products.length > 0 ? this.products[0].asin : null;
  }

  transformToFrontendAlerts(): Alert[] {

    const alerts: Alert[] = [];

    const filter = new Filter();
    filter.field = this.type;
    filter.operator = this.condition;
    filter.value = AlertsService.transformConditionValue(this.condition, this.condition_val, true);

    const data = {
      asin: this.firstAsin(),
      filter: filter,
      channel_id: 1,
      type_val: this.type_val,
      selected_users: [],
      selected_destinations: [],
      template_id: this.template ? this.id : null,
      be_alert_id: this.id,
    };

    if (this.app_channel) {
      alerts.push(new Alert(this.appendRandomId(data)));
    }

    if (this.email_channel) {
      data.channel_id = 2;
      data.selected_users = this.email_channel_subusers;
      alerts.push(new Alert(this.appendRandomId(data)));
    }

    if (this.webhook_channel) {
      data.channel_id = 5;
      data.selected_users = [];
      data.selected_destinations = this.webhook_channel_destinations.map(destination => {
        destination['validated'] = true;
        return destination;
      });
      alerts.push(new Alert(this.appendRandomId(data)));
    }

    return alerts;
  }

  appendRandomId(data: Object) {
    return {...data, internal_id: Alert.getRandomId()};
  }
}

export class AlertGroup extends BaseModel {
  static className = 'AlertGroup';

  credential_id: number;
  asins: string[];
  asins_deselected: string[];
  all_products_selected: boolean;
  be_alerts: Collection<BEAlert>;
  fe_alerts: Alert[];

  constructor(data = {}) {
    super({
      all_products_selected: false,
      be_alerts: new Collection(),
      fe_alerts: [],
      ...data,
    });
  }

  isProductAsinSet() {
    return this.asins && this.asins.length !== 0;
  }

  getFirstProductAsin() {
    return this.isProductAsinSet() ? this.asins[0] : null;
  }

  generateFEAlerts() {
    this.fe_alerts = [];
    this.be_alerts.each((beAlert: BEAlert) => {
      this.fe_alerts = this.fe_alerts.concat(beAlert.transformToFrontendAlerts());
    });

    return this.fe_alerts;
  }
}

export class AlertConflict extends BaseModel {
  static className = 'AlertConflict';

  type: string;
  old_conditions: string[];
  new_conditions: string[];
}

export class AlertIssue extends BaseModel {
  static className = 'AlertIssue';

  id: number;
  asin: string;
  message: string;
  _detected_on: Date;
  deleted = false;

  // @ts-ignore
  set detected_on(date: Date) {
    this._detected_on = new Date(date);
  }

  // @ts-ignore
  get detected_on(): Date {
    return this._detected_on;
  }

  // @ts-ignore
  get detected_on_string(): string {
    return this.detected_on.toLocaleString();
  }
}

export class AlertTemplate extends BaseModel {
  static className = 'AlertTemplate';

  id: number;
  credential_id?: number;
  name: string;
  be_alerts: Collection<BEAlert>;
  fe_alerts: Alert[];
  source: string;

  set alerts(alerts: Collection<BEAlert>) {
    this.be_alerts = BEAlert.collect<BEAlert>(alerts);
  }

  get alerts(): Collection<BEAlert> {
    return this.be_alerts;
  }
}
