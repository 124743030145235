import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { ApiPromotion, ProductChild } from '@app/shared/data/api-promotion/api-promotion.models';
import { Collection } from '@app/shared/data/base.models';

@Injectable()
export class ApiPromotionActions extends PaginationActions {

  protected model = ApiPromotion;

  static readonly TYPES = {
    SAVE          : '[API_PROMOTION]SAVE',
    SAVE_SUCCEEDED: '[API_PROMOTION]SAVE_SUCCEEDED',
    SAVE_FAILED   : '[API_PROMOTION]SAVE_FAILED',

    SAVE_COUPONS          : '[API_PROMOTION]SAVE_COUPONS',
    SAVE_COUPONS_SUCCEEDED: '[API_PROMOTION]SAVE_COUPONS_SUCCEEDED',
    SAVE_COUPONS_FAILED   : '[API_PROMOTION]SAVE_COUPONS_FAILED',

    FETCH          : '[API_PROMOTION]FETCH',
    FETCH_STATS    : '[API_PROMOTION]FETCH_STATS',
    FETCH_SUCCEEDED: '[API_PROMOTION]FETCH_SUCCEEDED',
    FETCH_FAILED   : '[API_PROMOTION]FETCH_FAILED',

    DELETE          : '[API_PROMOTION]DELETE',
    DELETE_SUCCEEDED: '[API_PROMOTION]DELETE_SUCCEEDED',
    DELETE_FAILED   : '[API_PROMOTION]DELETE_FAILED',

    SEARCHING_FOR_PRODUCT          : '[API_PROMOTION]SEARCHING_FOR_PRODUCT',
    SEARCHING_FOR_PRODUCT_SUCCEEDED: '[API_PROMOTION]SEARCHING_FOR_PRODUCT_SUCCEEDED',
    SEARCHING_FOR_PRODUCT_FAILED   : '[API_PROMOTION]SEARCHING_FOR_PRODUCT_FAILED',
  };

  static fetch(apiPromotion: ApiPromotion) {
    return {
      type: ApiPromotionActions.TYPES.FETCH,
      data: apiPromotion
    };
  }

  static fetchSucceeded(apiPromotion: ApiPromotion): StAction {
    return {
      type: ApiPromotionActions.TYPES.FETCH_SUCCEEDED,
      data: apiPromotion
    };
  }

  static fetchFailed(error): StAction {
    return {
      type: ApiPromotionActions.TYPES.FETCH_FAILED,
      data: error
    };
  }

  static fetchStats(apiPromotion: ApiPromotion, timezone: String) {
    return {
      type: ApiPromotionActions.TYPES.FETCH_STATS,
      data: {apiPromotion: apiPromotion,
             timezone: timezone
            }
    };
  }

  static save(apiPromotion: ApiPromotion): StAction {
    return {
      type: ApiPromotionActions.TYPES.SAVE,
      data: apiPromotion
    };
  }

  static saveSucceeded(apiPromotion: ApiPromotion): StAction {
    return {
      type: ApiPromotionActions.TYPES.SAVE_SUCCEEDED,
      data: apiPromotion
    };
  }

  static saveFailed(error): StAction {
    return {
      type: ApiPromotionActions.TYPES.SAVE_FAILED,
      data: error
    };
  }

  static saveCoupons(apiPromotion: ApiPromotion): StAction {
    return {
      type: ApiPromotionActions.TYPES.SAVE_COUPONS,
      data: apiPromotion
    };
  }

  static saveCouponsSucceeded(apiPromotion: ApiPromotion): StAction {
    return {
      type: ApiPromotionActions.TYPES.SAVE_COUPONS_SUCCEEDED,
      data: apiPromotion
    };
  }

  static saveCouponsFailed(error): StAction {
    return {
      type: ApiPromotionActions.TYPES.SAVE_COUPONS_FAILED,
      data: error
    };
  }

  static delete(apiPromotion: ApiPromotion): StAction {
    return {
      type: ApiPromotionActions.TYPES.DELETE,
      data: apiPromotion
    };
  }

  static deleteSucceeded(apiPromotion: ApiPromotion): StAction {
    return {
      type: ApiPromotionActions.TYPES.DELETE_SUCCEEDED,
      data: apiPromotion
    };
  }

  static deleteFailed(error): StAction {
    return {
      type: ApiPromotionActions.TYPES.DELETE_FAILED,
      data: error
    };
  }

  searchForProduct(query: string): StAction {
    return {
      type: ApiPromotionActions.TYPES.SEARCHING_FOR_PRODUCT,
      data: {
        query: query
      }
    };
  }

  searchForProductSucceeded(products: Collection<ProductChild>): StAction {
    return {
      type: ApiPromotionActions.TYPES.SEARCHING_FOR_PRODUCT_SUCCEEDED,
      data: {
        products: products
      }
    };
  }

  searchForProductFailed(error): StAction {
    return {
      type: ApiPromotionActions.TYPES.SEARCHING_FOR_PRODUCT_FAILED,
      data: {
        error: error,
      }
    };
  }
}
