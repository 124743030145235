<st-datatable [theme]="'box-table'" [loading]="isLoading" [data]="apiKeys">
  <colm class="flex-grow-1 c-name" i18n-title title="Credentials">
    <td *cl="let item;">
      <b> {{ item.credential_name }} </b>
    </td>
  </colm>
  <colm class="flex-grow-1 c-name" i18n-title title="Marketplace">
    <td *cl="let item;">
      <b> {{ item.marketplace_name }} </b>
    </td>
  </colm>
  <colm class="flex-grow-1 c-name" i18n-title title="Created at">
    <td *cl="let item;">
      <b> {{ item.created_at | date }} </b>
    </td>
  </colm>
  <colm class="flex-grow-4 c-key" [class.c-key--blurred]="liveKeys" i18n-title title="Key">
    <td *cl="let item;">{{ item.value }}</td>
  </colm>
  <colm class="flex-grow-1 c-status" i18n-title title="Status">
    <td *cl="let item;">
      <span class="badge badge-padding badge-upper badge-{{item.is_revoked ?'red':'green'}}">
        <ng-container *ngTemplateOutlet="item.is_revoked ? translateRevoked : translateActive"></ng-container>
      </span>
      <span *ngIf="item.name.toLowerCase() != 'test'" class="badge badge-padding badge-upper badge-blue">
        {{ item.name }} </span>
      <span *ngIf="item.name.toLowerCase() == 'test'" class="badge badge-padding badge-upper badge-red" i18n>TEST</span>
    </td>
  </colm>
  <colm class="flex-grow-1 c-actions" i18n-title title="Copy" *ngIf="liveKeys">
    <td *cl="let item;">
      <st-icon class="pointer" (click)="openWarningModal(item)" i18n-stTooltip stTooltip="Reveal & Copy" icon='copy'>
      </st-icon>
    </td>
  </colm>
  <colm class="flex-grow-1 c-actions" i18n-title title="Rotate" *ngIf="liveKeys">
    <td *cl="let item;">
      <st-icon *ngIf="!item.is_revoked" class="pointer" (click)="onRotate(item)" i18n-stTooltip
        stTooltip="Revoke and regenerate" icon='rotate_right'></st-icon>
    </td>
  </colm>
  <ng-template no-content i18n *ngIf="liveKeys">
    No API Keys generated just yet!
  </ng-template>
  <ng-template no-content i18n *ngIf="!liveKeys">
    No expired API keys!
  </ng-template>
</st-datatable>

<ng-template #translateRevoked i18n>
  Revoked
</ng-template>
<ng-template #translateActive i18n>
  Active
</ng-template>
