import { FilterTypeEnum, IAvailableFilter, OperatorEnum } from "../../common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component";

export const productManagerKeywordManagerFilters: IAvailableFilter[] = [
  {
    field: 'rank',
    title: 'Organic Rank',
    operators:
      {
        [OperatorEnum.GREATER_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.LESS_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
      }
  },
  {
    field: 'impressions',
    title: 'Search Volume',
    operators:
      {
        [OperatorEnum.GREATER_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.LESS_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
      }
  },
  {
    field: 'sfr_rank',
    title: 'Search Frequency Rank',
    operators:
      {
        [OperatorEnum.GREATER_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.LESS_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
      }
  },
  {
    field: 'sales_rate',
    title: 'Purchase Rate',
    operators:
      {
        [OperatorEnum.GREATER_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.LESS_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
      }
  },
  {
    field: 'number_of_competitors',
    title: 'Number of Competitors',
    operators:
      {
        [OperatorEnum.GREATER_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.LESS_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
      }
  },
  {
    field: 'click_dominance',
    title: 'Top 3 Click Dominance',
    operators:
      {
        [OperatorEnum.GREATER_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.LESS_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
      }
  },
  {
    field: 'search_dominance',
    title: 'Top 3 Sales Dominance',
    operators:
      {
        [OperatorEnum.GREATER_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
        [OperatorEnum.LESS_EQUALS]: {
          type: FilterTypeEnum.POSITIVE_NUMBER,
        },
      }
  },
  {
    field: 'indexed',
    title: 'Indexed',
    operators:
      {
        [OperatorEnum.EQUALS]: {
          type: FilterTypeEnum.DROPDOWN,
          options: [
            { id: 0, label: 'True' },
            { id: 1, label: 'False' },
          ]
        },
      }
  },
  {
    field: 'priority',
    title: 'Priority',
    operators:
      {
        [OperatorEnum.CONTAINS]: {
          type: FilterTypeEnum.MULTISELECT,
          options: [
            { id: 'H', label: 'High' },
            { id: 'M', label: 'Medium' },
            { id: 'L', label: 'Low' },
            { id: 'VL', label: 'Very Low' },
          ]
        },
      }
  },
  {
    field: 'set_data',
    title: 'Fields ',
    operators:
      {
        [OperatorEnum.CONTAINS]: {
          type: FilterTypeEnum.MULTISELECT,
          options: [
            { id: 'rank', label: 'Organic Rank' },
            { id: 'impressions', label: 'Search Volume' },
            { id: 'sfr_rank', label: 'Search Frequency Rank' },
            { id: 'sales_rate', label: 'Purchase Rate' },
            { id: 'sales', label: 'Unit Sales' },
            { id: 'number_of_competitors', label: 'Total No of Competitors' },
            { id: 'click_dominance', label: 'Top 3 Click Dominance' },
            { id: 'search_dominance', label: 'Top 3 Sales Dominance' },
          ]
        },
      }
  },
];
