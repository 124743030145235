import { AlignValue, LegendOptions, OptionsLayoutValue, VerticalAlignValue } from "highcharts";

export class LegendModel implements LegendOptions {
  enabled: boolean = true;
  floating: boolean;
  layout: OptionsLayoutValue;
  backgroundColor: string;
  align: AlignValue;
  verticalAlign: VerticalAlignValue;
  x: number;
  y: number;
  useHTML: boolean;
  labelFormatter?(): string;

  constructor(options: any = {}) {
    Object.assign(this, options);
  }

  public setEnabled(enabled: boolean) {
    this.enabled = enabled;
  }

  public setUseHtml(useHTML: boolean) {
    this.useHTML = useHTML;
  }
}
