
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { SystemStats } from "@app/shared/data/admin/system-stats/system-stats.models";

@Injectable()
export class SystemStatsAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<SystemStats>> {
    return this.http.get(this.constructFullUrl('api/admin/system-stats'), { params: params }).pipe(
      map(data => SystemStats.collect<SystemStats>(data, SystemStats.BACKEND_PAGINATION)));
  }
}
