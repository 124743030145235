
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Collection } from '@app/shared/data/base.models';
import { BaseAPI } from '@app/shared/data/base.api';
import { Alert, AlertDestination } from '@app/shared/components/alert-builder/alert/alert';
import { AlertConflict, AlertGroup, AlertTemplate, BEAlert, ProductAlerts, UserAlerts } from '@app/shared/data/alert/alert.models';
import { handleError } from '@app/helpers';

@Injectable()
export class AlertApi extends BaseAPI {
  constructor(protected http: HttpClient, ) {
    super();
  }

  public getProducts(params = {}): Observable<Collection<ProductAlerts>> {
    return this.http.get(this.constructFullUrl('api/alerts'), { params: params }).pipe(
      map(data => ProductAlerts.collect<ProductAlerts>(data, ProductAlerts.BACKEND_PAGINATION)));
  }

  public getProductsData(params = {}): Observable<Collection<ProductAlerts>> {
    return this.http.post(this.constructFullUrl('api/alerts/product/data'), params).pipe(
               map(data => ProductAlerts.collect<ProductAlerts>(data)));
  }

  public getProductAlertGroup(asin): Observable<AlertGroup> {
    return this.http.get(this.constructFullUrl('api/alerts/product/' + asin + '/group'), {}).pipe(
               map(data => {
                 const group: AlertGroup = new AlertGroup(data);
                 group.be_alerts = BEAlert.collect<BEAlert>(group.be_alerts);
                 return group;
               }));
  }

  public getUserAlerts(params = {}): Observable<UserAlerts> {
    return this.http.get(this.constructFullUrl('api/alerts/user'), { params: params }).pipe(
      map(data => new UserAlerts(data)));
  }

  public save(group: AlertGroup, source: string): Observable<any> {
    return this.http.put(this.constructFullUrl('api/alerts/' + source), group);
  }

  public updateForKeywords(
    deleteOnly: boolean,
    asin: string,
    type: string,
    keywords: string[],
    condition?: string,
    condition_value?: string,
    channel_id?: number,
    selected_users?: number[],
    selected_destinations?: AlertDestination[]
  ): Observable<any> {
    return this.http.put(
      this.constructFullUrl('api/alerts/' + asin + '/update-for-keywords'),
      {
        deleteOnly: deleteOnly,
        type: type,
        keywords: keywords,
        condition: condition,
        condition_value: condition_value,
        channel_id: channel_id,
        selected_users: selected_users,
        selected_destinations: selected_destinations,
      }
    );
  }

  public delete(alert: BEAlert, source: string, asin: string = null): Observable<BEAlert> {
    return this.http.delete(this.constructFullUrl('api/alerts/' + alert.id), {params: {source: source, asin: asin}}).pipe(
               map(data => new BEAlert(data)));
  }

  public bulkDelete(parameters: { [param: string]: string | string[]; }): Observable<boolean> {
    return this.http.delete(this.constructFullUrl('api/alerts'), {params: parameters}).pipe(
               map(data => !!data));
  }

  public validateURL(url: string, type: string): Observable<boolean> {
    return this.http.post(this.constructFullUrl('api/alerts/validate-url'), { url: url, type: type }).pipe(
      map(data => !!data));
  }

  public sendTestAlert(
    source: string,
    type: string,
    type_value: string,
    condition?: string,
    condition_value?: string,
    channel_id?: number,
    selected_users?: number[],
    selected_destinations?: AlertDestination[],
    asin?: string,
  ): Observable<boolean> {
    return this.http.post(
      this.constructFullUrl('api/alerts/send-test-alert'),
        {
          source: source,
          type: type,
          type_value: type_value,
          condition: condition,
          condition_value: condition_value,
          channel_id: channel_id,
          selected_users: selected_users,
          selected_destinations: selected_destinations,
          asin: asin,
        }
      ).pipe(catchError(handleError),
      map(data => !!data),);
  }

  public obtainConflicts(
    credential_id: number,
    asins: string[],
    alerts: Alert[]
  ): Observable<Collection<AlertConflict>> {
    return this.http.post(
      this.constructFullUrl('api/alerts/obtain-conflicts'),
      {
        credential_id: credential_id,
        asins: asins,
        fe_alerts: alerts
      }
    ).pipe(map(data => AlertConflict.collect<AlertConflict>(data)));
  }

  public acknowledgeIssues(parameters: { [param: string]: string | string[]; }): Observable<boolean> {
    return this.http.post(this.constructFullUrl('api/alerts/acknowledge-issues'), parameters).pipe(
      map(data => !!data));
  }

  public getDefaultTemplates(source: string): Observable<Collection<AlertTemplate>> {
    return this.http.get(this.constructFullUrl('api/alerts/templates/default'), {params: {source: source}}).pipe(
       map(data => AlertTemplate.collect(data)));
  }

  public getUsersTemplates(source: string): Observable<Collection<AlertTemplate>> {
    return this.http.get(this.constructFullUrl('api/alerts/templates/custom'), {params: {source: source}}).pipe(
       map(data => AlertTemplate.collect(data)));
  }

  public saveTemplate(template: AlertTemplate): Observable<any> {
    return this.http.post(this.constructFullUrl('api/alerts/template'), template);
  }

  public deleteTemplate(template: AlertTemplate): Observable<any> {
    return this.http.delete(this.constructFullUrl('api/alerts/template/' + template.id), {});
  }
}
