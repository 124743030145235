import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Affiliate } from "@app/shared/data/affiliate/affiliate.models";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";

const INITIAL_STATE: AppState = {
  data   : new Collection<Affiliate>([]),
  loading: false
};

export function affiliateReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  return paginationReducer(Affiliate)(state, action);
}
