import { Injectable } from "@angular/core";
import { Collection } from "@app/shared/data/base.models";
import { StAction } from "@app/shared/data/st-action";
import { Subscription } from "@app/shared/data/subscription/subscription.models";
import { Plan } from "@app/shared/data/plan/plan.models";
import { Coupon } from "@app/shared/data/coupon/coupon.models";

@Injectable()
export class SubscriptionActions {
  static readonly TYPES = {
    LOAD            : '[SUBSCRIPTION]LOAD',
    LOAD_SUCCEEDED  : '[SUBSCRIPTION]LOAD_SUCCEEDED',
    LOAD_FAILED     : '[SUBSCRIPTION]LOAD_FAILED',
    CREATE          : '[SUBSCRIPTION]CREATE',
    CREATE_SUCCEEDED: '[SUBSCRIPTION]CREATE_SUCCEEDED',
    CREATE_FAILED   : '[SUBSCRIPTION]CREATE_FAILED',
    CANCEL          : '[SUBSCRIPTION]CANCEL',
    CANCEL_SUCCEEDED: '[SUBSCRIPTION]CANCEL_SUCCEEDED',
    CANCEL_FAILED   : '[SUBSCRIPTION]CANCEL_FAILED',
    EMPTY_CACHE     : '[SUBSCRIPTION]EMPTY_CACHE'
  };

  load(): StAction {
    return {
      type: SubscriptionActions.TYPES.LOAD,
      data: null,
    }
  }

  loadSucceeded(creditCards: Collection<Subscription>): StAction {
    return {
      type: SubscriptionActions.TYPES.LOAD_SUCCEEDED,
      data: creditCards,
    }
  }

  loadFailed(error: any): StAction {
    return {
      type: SubscriptionActions.TYPES.LOAD_FAILED,
      data: error,
    }
  }

  create(plan: Plan, coupon: Coupon = null): StAction {
    return {
      type: SubscriptionActions.TYPES.CREATE,
      data: {
        plan  : plan,
        coupon: coupon
      }
    }
  }

  createSucceeded(data): StAction {
    return {
      type: SubscriptionActions.TYPES.CREATE_SUCCEEDED,
      data: data
    }
  }

  createFailed(data): StAction {
    return {
      type: SubscriptionActions.TYPES.CREATE_FAILED,
      data: data
    }
  }

  cancel(subscription: Subscription): StAction {
    return {
      type: SubscriptionActions.TYPES.CANCEL,
      data: {
        subscription: subscription
      },
    }
  }

  cancelSucceeded(subscription: Subscription): StAction {
    return {
      type: SubscriptionActions.TYPES.CANCEL_SUCCEEDED,
      data: {
        subscription: subscription
      },
    }
  }

  cancelFailed(error): StAction {
    return {
      type: SubscriptionActions.TYPES.CANCEL_FAILED,
      data: error,
    }
  }

  emptyCache(): StAction {
    return {
      type: SubscriptionActions.TYPES.EMPTY_CACHE,
      data: null,
    }
  }
}
