import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPI } from '../base.api';
import cloneDeep from 'clone-deep';

import { HttpParameterCodec } from '@angular/common/http';
import { IFilter } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { ICampaignManagerResponse, ICampaignState, ICheckCampaignSlugResponse, ILoadAvailableFiltersResponse, ISavedFiltersListResponse, ISaveFilterResponse, RewardTypeEnum } from './campaign-manager.models';

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}

export class FiltersEncoder {
  encode(filters: IFilter[]): string {
    return btoa(
      JSON.stringify(
        filters.map(val => [val.field, val.operator, val.value])
      )
    );
  }
}

@Injectable()
export class CampaignManagerAPI extends BaseAPI {
  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  getCampaignList(page: number, limit: number, sort: string, filters: IFilter[]): Observable<ICampaignManagerResponse> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder() })
      .set('page', page.toString())
      .set('limit', limit.toString());

    if (sort !== 'none') {
      params = params.set('sort', sort);
    }

    if (filters.length > 0) {
      const filtersEncoder = new FiltersEncoder();
      params = params.set('query', filtersEncoder.encode(filters));
    }

    return this.http.get<ICampaignManagerResponse>(this.constructFullUrl('api/v1/campaigns'), { params });
  }

  uploadFile(file: any): Observable<any> {
    return this.http.post<any>(this.constructFullUrl('api/v1/campaigns/upload_file'), file);
  }

  saveFilters(name: string, filters: IFilter[]): Observable<ISaveFilterResponse> {
    return this.http.post<ISaveFilterResponse>(this.constructFullUrl('api/campaign-manager/views'), {
      name,
      filters
    });
  }

  getSavedFilters(): Observable<ISavedFiltersListResponse> {
    return this.http.get<ISavedFiltersListResponse>(this.constructFullUrl('api/campaign-manager/views'));
  }

  deleteSavedFilter(id: number): Observable<any> {
    return this.http.request<any>('delete', this.constructFullUrl('api/campaign-manager/views/'+id.toString()));
  }

  loadAvailableFilters(): Observable<ILoadAvailableFiltersResponse> {
    return this.http.get<ILoadAvailableFiltersResponse>(this.constructFullUrl('api/v1/campaigns/available-filters'));
  }

  addCampaign(campaign: ICampaignState): Observable<{data: ICampaignState}> {
    return this.http.post<{data: ICampaignState}>(this.constructFullUrl('api/v1/campaigns'), this.sanitizeCampaign(campaign));
  }

  updateCampaign(campaign: ICampaignState): Observable<{data: ICampaignState}> {
    return this.http.put<{data: ICampaignState}>(this.constructFullUrl(`api/v1/campaigns/${campaign.id}`), this.sanitizeCampaign(campaign));
  }

  deleteCampaign(campaignId: string): Observable<any> {
    return this.http.delete<any>(this.constructFullUrl(`api/v1/campaigns/${campaignId}`));
  }

  getCampaign(campaignId: string): Observable<any> {
    return this.http.get<any>(this.constructFullUrl(`api/v1/campaigns/${campaignId}`));
  }

  checkCampaignSlug(slug: string, campaignId?: string): Observable<ICheckCampaignSlugResponse> {
    return this.http.post<ICheckCampaignSlugResponse>(this.constructFullUrl(`api/v1/campaigns/check_slug`), { slug, campaign_id: campaignId });
  }

  sanitizeCampaign(data: ICampaignState): ICampaignState {
    const sanitized:ICampaignState = cloneDeep(data);

    // remove full_name and image in campaign's product
    if (sanitized.product) {
      delete(sanitized.product.full_name);
      delete(sanitized.product.image);
    }

    // remove full_name and image from mcf_gift product's variations
    if (sanitized.reward 
      && sanitized.reward.mcf_gift_reward_settings 
      && sanitized.reward.mcf_gift_reward_settings.product 
      && sanitized.reward.mcf_gift_reward_settings.product.variations) {
      for (const i in sanitized.reward.mcf_gift_reward_settings.product.variations) {
        delete(sanitized.reward.mcf_gift_reward_settings.product.variations[i].full_name);
        delete(sanitized.reward.mcf_gift_reward_settings.product.variations[i].image);
      }
    } 

    return sanitized;
  }
}
