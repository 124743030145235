import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { PayoutCommisionAdmin } from "@app/shared/data/admin/payout-commision/payout-commision.model";
import { PayoutAdminActions } from "@app/shared/data/admin/payout-affiliate/payout-admin.actions";

const INITIAL_STATE: AppState = {
  data   : new Collection<PayoutCommisionAdmin>([]),
  loading: false,
};

export function payoutCommisonsReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == PaginationActions.getPagActionTypes(PayoutCommisionAdmin).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-payouts-commisions');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == PayoutAdminActions.TYPES.PAY_SUCCEEDED) {
    return {
      ...state,
      data : state['data'].clone().map((pa: PayoutCommisionAdmin) => {
        if (pa.scheduled_for != action.data.scheduledFor)
          return pa;

        return pa.clone(PayoutCommisionAdmin, {
          commision: pa.commision - action.data.amount
        });
      }),
      error: null,
    };
  }

  return paginationReducer(PayoutCommisionAdmin)(state, action);
}
