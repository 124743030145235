import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { ProductToolsActions } from "@app/shared/data/admin/product-tools/product-tools.actions";

const INITIAL_STATE: AppState = {
  data: {
    product: null, credential: null, owner: null
  },
  loading: false,
  error  : null,
};

export function productToolsReducers(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == ProductToolsActions.TYPES.FIND) {
    return {
      ...state,
      loading: true
    };
  }

  if (action.type == ProductToolsActions.TYPES.FIND_SUCCEEDED) {
    return {
      ...state,
      data: action.data,
      loading: false
    };
  }

  if (action.type == ProductToolsActions.TYPES.FIND_FAILED) {
    return {
      ...state,
      error  : action.data,
      loading: false
    };
  }

  return state;
}
