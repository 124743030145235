import { Injectable } from "@angular/core";
import { ModalService, ActiveModal } from "@app/shared/components/modals/modal.service";
import { ConfirmComponent } from "@app/shared/components/dialog/confirm/confirm.component";
import { PromptComponent } from "@app/shared/components/dialog/prompt/prompt.component";
import { InfoComponent } from "@app/shared/components/dialog/info/info.component";
import { Observable } from "rxjs";
import { ProgressComponent } from "@app/shared/components/dialog/progress/progress.component";
import {I18n} from '@ngx-translate/i18n-polyfill';
import { MultiButtonDialogComponent, DialogButton } from "@app/shared/components/dialog/multi-button-dialog/multi-button-dialog.component";
@Injectable()
export class DialogService {
  constructor(
    private modalService: ModalService,
    private i18n: I18n) {
  }

  confirm(
    title: string = this.i18n('Confirmation message'),
    message: string = this.i18n('Please confirm.'),
    yesButtonText: string = this.i18n('Ok'),
    noButtonText: string = this.i18n('Cancel'),
    showIcon: boolean = false,
    warningText?: string,
    confirmText?: string,
  ): Promise<boolean> {
    const modalRef: ActiveModal = this.modalService.open(ConfirmComponent);

    const component: ConfirmComponent = modalRef.instance;
    component.setMessage(message);
    component.setYesButtonText(yesButtonText);
    component.setNoButtonText(noButtonText);
    component.setTitle(title);
    component.setIcon(showIcon);

    if (warningText) {
      component.setWarningText(warningText);
    }

    if (confirmText) {
      component.setConfirmText(confirmText);
    }

    return modalRef.result.then(null, (e) => false); // return false if modal is dismissed!
  }

  prompt(title: string,
    message: string = this.i18n('Enter value'),
    yesButtonText: string = this.i18n('Ok'),
    noButtonText: string = this.i18n('Cancel'),
    value: string = "", validator?: ((value: string) => boolean)): Promise<boolean | string> {
    const modalRef: ActiveModal = this.modalService.open(PromptComponent);

    const component: PromptComponent = modalRef.instance;
    component.setTitle(title);
    component.setMessage(message);
    component.setYesButtonText(yesButtonText);
    component.setNoButtonText(noButtonText);
    component.setValue(value);

    if (validator)
      component.setValidator(validator);

    return modalRef.result.then(null, (e) => ""); // return empty string if modal is dismissed!
  }

  multiButtonDialog(
    title: string,
    message: string,
    buttons: DialogButton[]): Promise<boolean | any> {
      const modalRef: ActiveModal = this.modalService.open(MultiButtonDialogComponent);
      const component: MultiButtonDialogComponent = modalRef.instance;
      component.setTitle(title);
      component.setMessage(message);
      component.addButtons(buttons);

      return modalRef.result.then(null, (e) => false); // return false if modal is dismissed!
  }

  info(title: string, message: string = "", buttonText: string = this.i18n('Ok'), centerText = false, successIcon = false): Promise<boolean | string> {
    const modalRef: ActiveModal = this.modalService.open(InfoComponent);

    const component: InfoComponent = modalRef.instance;
    component.setTitle(title);
    component.setMessage(message);
    component.setButtonText(buttonText);
    component.setCenterText(centerText);
    component.setSuccessIcon(successIcon);

    return modalRef.result.then(null, (e) => ""); // return empty string if modal is dismissed!
  }

  openProgress(title: string, message: string = "", progress$: Observable<number>, allowAbort = false): ActiveModal {
    let modalRef: ActiveModal = this.modalService.open(ProgressComponent);

    const component: ProgressComponent = modalRef.instance;
    component.setTitle(title);
    component.setMessage(message);
    component.setProgressObs(progress$);
    component.setEnableAbort(allowAbort);

    return modalRef;
  }

  openFakeProgress(title: string, message: string = "", allowAbort = false): ActiveModal {
    let modalRef: ActiveModal = this.modalService.open(ProgressComponent);

    const component: ProgressComponent = modalRef.instance;
    component.setTitle(title);
    component.setMessage(message);
    component.setMaxFakeProgress(98);
    component.setEnableAbort(allowAbort);

    return modalRef;
  }
}
