
import {merge as observableMerge,  Observable } from 'rxjs';

import {filter, takeUntil} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Epic } from 'redux-observable';
import { AppState } from '@app/shared/data/app-state.model';
import { Action } from 'redux';
import { StoreActions } from '@app/core/socket/store/socket.actions';
import { SocketService } from '@app/core/socket/socket.service';

import { VersionCheckService } from '@app/core/services/version-check.service';
import { environment } from '@env/environment';

@Injectable()
export class SocketEpics {
  constructor(@Inject('SocketService') private socketService: SocketService,
              private versionCheckService: VersionCheckService) {
  }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.socketConnected,
      this.socketDisconnected,
    ];
  }


  socketConnected = store => next => {
    return (action) => {
      if (action.type === StoreActions.onConnect().type) {
        // Listen to events in channels
        observableMerge(
          this.socketService.onUser('*'),
          this.socketService.onCredential('*'),
          this.socketService.onCredentialOwner('*'),
          this.socketService.onPublic('*'),
          this.socketService.onAdmin('*'),
        ).pipe(
          takeUntil(this.socketService.connected$.pipe(filter(x => !x))))
          .subscribe(
            (event: { name: string, data: any }) => {
              // dispatch an action for event
              store.dispatch({ type: event.name, data: event.data });
            },
          );

        if (environment.production) {
          this.versionCheckService.check();
        }
      }

      return next(action);
    };
  }

  socketDisconnected = store => next => {
    return (action) => {
      if (action.type === StoreActions.onDisconnect().type) {
        this.socketService.leaveAllChannels();
      }

      return next(action);
    };
  }
}
