import { ApiTokenActions } from '@app/shared/data/api-token/api-token.actions';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'st-api-keys',
  templateUrl: './api-keys.component.html',
  styleUrls: ['./api-keys.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApiKeysComponent implements OnInit, OnDestroy {

  liveTokens: ApiToken[];
  expiredTokens: ApiToken[];
  isLoading: boolean;
  initialLoadDone = false;
  regenerateLoading: boolean;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private ngRedux: NgRedux<AppState>,
    private changeDetection: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.loadData();

    this.ngRedux.select(['tokens', 'data'])
      .pipe(
        filter((data) => Boolean(data)),
        takeUntil(this.destroy$)
      ).subscribe((tokens: ApiToken[]) => {
        this.liveTokens = this.sortTokens(tokens.filter((token: ApiToken) => !token.is_revoked));
        this.expiredTokens = this.sortTokens(tokens.filter((token: ApiToken) => token.is_revoked));
        this.changeDetection.detectChanges();
      });

    this.ngRedux.select(['tokens', 'loading'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading: boolean) => {
        if (!this.initialLoadDone) {
          this.isLoading = loading;
        } else {
          this.regenerateLoading = loading;
        }

        if (!loading && !this.initialLoadDone) {
          this.initialLoadDone = true;
        }

        this.changeDetection.detectChanges();
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Load data for datatable
   */
  loadData() {
    this.ngRedux.dispatch(ApiTokenActions.loadAll());
  }

  sortTokens(tokens: ApiToken[]): ApiToken[] {
    return tokens.sort((a: ApiToken, b: ApiToken) => {
      return a.credential_id - b.credential_id;
    });
  }
}
