<div class="toggle-columns__dropdown-wrapper"
    [class.toggle-columns__dropdown-wrapper--opened]="opened"
    [class.toggle-columns__dropdown-wrapper--disabled]="disabled"
    stOffClick
    (clickOutside)="opened = false">
    <div class="toggle-columns__dropdown__title" (click)="toggleDropdown()">
        <img src="/assets/img/icons/settings_blue.svg">
    </div>
    <div class="toggle-columns__dropdown">
        <div class="toggle-columns__dropdown__options">
            <ng-container *ngFor="let column of columns; let i=index">
                <div class="toggle-columns__dropdown__option" [class.hide]="column.hide" (click)="toggleColumn(i)" *ngIf="column.canToggle !==false">
                    <st-icon [icon]="column.hide ? 'hide' : 'show'" [width]="16" [height]="13"></st-icon>
                    <span>{{column.title}}</span>
                </div>
            </ng-container>
        </div>
    </div>
</div>

