import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { PromotionApi } from '@app/shared/data/promotion/promotion.api';
import { PromotionActions } from '@app/shared/data/promotion/promotion.actions';
import { Promotion } from '@app/shared/data/promotion/promotion.models';
import { getTranslationFromResponse } from '@app/helpers';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class PromotionEpics {

  constructor(private store: NgRedux<AppState>,
              private promotionApi: PromotionApi,
              private actions: PromotionActions,
              private i18n: I18n) {
  }

  public createEpic() {
    return [
      this.fetchPromotion,
    ];
  }

  fetchPromotion = store => next => {
    return (action: StAction) => {
      if (action.type === PromotionActions.TYPES.FETCH_PROMOTION) {
        this.promotionApi.get(action.data.token)
          .subscribe(
            (promotion: Promotion) => {
              this.store.dispatch(this.actions.fetchPromotionSucceed(promotion));
            },
            (response) => {
              this.store.dispatch(this.actions.fetchPromotionFailed(
                getTranslationFromResponse([{
                  code: 'error.not_found',
                  translation: this.i18n('Promotion does not exist, or already expired')
                }], response.error)
              ));
            },
            () => {
            },
          );
      }

      return next(action);
    };
  };
}
