import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { CouponAPI } from "@app/shared/data/coupon/coupon.api";
import { CouponActions } from "@app/shared/data/coupon/coupon.actions";
import { Coupon } from "@app/shared/data/coupon/coupon.models";

@Injectable()
export class CouponEpics {
  constructor(private couponActions: CouponActions,
              private couponAPI: CouponAPI,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
      this.create,
      this.remove,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == this.couponActions.loadCoupons().type) {
        this.couponAPI.all(action.data)
          .subscribe(
            (coupons: Collection<Coupon>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.loadCouponsSucceeded(coupons));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.loadCouponsFailed(response));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };

  create = store => next => {
    return (action) => {
      if (action.type == this.couponActions.create(null, null).type) {
        this.couponAPI.create(action.data)
          .subscribe(
            (coupon: Coupon) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.createSucceeded(coupon));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.createFailed(response.error.error));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };

  remove = store => next => {
    return (action) => {
      if (action.type == this.couponActions.remove(null).type) {
        this.couponAPI.remove(action.data)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.removeSucceeded(action.data));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.removeFailed(response.error.error));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };
}
