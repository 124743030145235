<st-modal #modal="modal" size="small" [showOnlyBody]="true">
  <div modal-body class="content">
    <div class="modal-header">
      <span class="close-icon" (click)="cancelFilters()">
        <st-icon icon="cross-v2"></st-icon>
      </span>
      <h2 i18n>Advanced Filters</h2>
      <p i18n>Improve your search with setting up the advanced filters</p>
    </div>
    <ng-container *ngIf="!loading;else loader">
      <h3 *ngIf="advancedFiltersTitle">{{ advancedFiltersTitle }}</h3>
      <div class="advanced-filter__column">
        <div class="advanced-filter__container advanced-filter__columns" [ngStyle]="{ 'grid-template-columns': 'repeat(' + columns + ', 1fr)'}">
          <ng-template *ngTemplateOutlet="filtersLayout; context: {$implicit: ( filters | slice:0:additionalFiltersTitleIndex)}"></ng-template>
        </div>
        <h3 *ngIf="additionalFiltersTitle" class="border">{{ additionalFiltersTitle }}</h3>
        <div class="advanced-filter__container">
          <ng-template *ngTemplateOutlet="filtersLayout; context: {$implicit: ( filters | slice:additionalFiltersTitleIndex)}"></ng-template>
        </div>
      </div>
    </ng-container>
    <ng-template #filtersLayout let-currentFilters>
      <ng-container *ngFor="let filter of currentFilters; let i=index">
        <div *ngIf="filter.show !== false" class="advanced-filter" [class.column-3]="filter.type === filterTypeEnum.INPUT">
          <div class="advanced-filter__column">
              {{filter.label}}
          </div>
          <div class="advanced-filter__column">
              <div class="advanced-filter__content">
                <ng-container [ngSwitch]="filter.type">
                  <st-dropdown-v2 *ngSwitchCase="filterTypeEnum.DROPDOWN"
                    [preselectedOptionEmpty]="true"
                    [preselectedOption]="filter.value"
                    (selected)="filter.value = $event"
                    [options]="filterOptions[filter.id][filter.operator?.id]?.options || []"></st-dropdown-v2>
                  <st-dropdown-checkbox *ngSwitchCase="filterTypeEnum.MULTISELECT"
                    [displaySelectedOptionsCount]="!filter.search"
                    [displaySearch]="filter.search"
                    (checkboxesUpdated)="setMultiselectValue($event, filter)"
                    [options]="filter.multiselectOptions"></st-dropdown-checkbox>
                  <input type="text" *ngSwitchCase="filterTypeEnum.INPUT_SINGLE" [disabled]="!filter.operator?.id " [(ngModel)]="filter.value" class="advanced-filter__content-input" placeholder="ENTER VALUE" i18n-placeholder>
                  <div *ngSwitchCase="filterTypeEnum.POSITIVE_NUMBER" class="number">
                    <input type="number" min="0" [(ngModel)]="filter.value" class="advanced-filter__content-input" placeholder="MIN" i18n-placeholder>
                    <span>-</span>
                    <input type="number" min="0" [(ngModel)]="filter.valueAdditional" class="advanced-filter__content-input" placeholder="MAX" i18n-placeholder>
                  </div>
                  <input type="date" *ngSwitchCase="filterTypeEnum.DATE" [(ngModel)]="filter.value"  class="advanced-filter__content-input">
                  <st-dropdown-v2 *ngSwitchDefault
                    [preselectedOption]="filter.operator"
                    [preselectedOptionEmpty]="true"
                    (selected)="setFilterOperator($event, filter)"
                    [options]="filterOperators[filter.id]"></st-dropdown-v2>
                </ng-container>
              </div>
            </div>

            <div class="advanced-filter__column">
              <div class="advanced-filter__content">
                <ng-container [ngSwitch]="filter.type">
                  <input type="text" *ngSwitchCase="filterTypeEnum.INPUT" [disabled]="!filter.operator?.id " [(ngModel)]="filter.value" class="advanced-filter__content-input" placeholder="ENTER VALUE" i18n-placeholder>
                  <input type="date" *ngSwitchCase="filterTypeEnum.DATE" [(ngModel)]="filter.valueAdditional"  class="advanced-filter__content-input" placeholder="END DATE" i18n-placeholder>
                </ng-container>
              </div>
            </div>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #loader>
        <div class="advanced-filters__body" i18n>Loading ...</div>
    </ng-template>

    <div class="advanced-filters-footer">
      <div class="advanced-filters__clear" (click)="clearFilters()" i18n>Clear All Filters</div>
      <div class="advanced-filters-footer--buttons">
        <st-button-v2 color="v2_red_empty" (clicked)="cancelFilters()" i18n-label label="CANCEL"></st-button-v2>
        <st-button-v2 color="v2_red" (clicked)="applyFilters()" i18n-label label="APPLY"></st-button-v2>
      </div>
    </div>
  </div>
</st-modal>
