import { PipeTransform, Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'stOrderBy'
})
@Injectable()
export class OrderByPipe implements PipeTransform {
  transform(value: any[], desc = false, column: string = ''): any[] {
    if (value.length <= 1) {
      return value;
    }

    let tmp = [...value];

    tmp.sort((a, b) => {
      if (column !== '') {
        a = a[column];
        b = b[column];
      }

      if (a < b) {
        return -1;
      }
      if (a > b) {
        return 1;
      }
      return 0;
    });

    if (desc) {
      return tmp.reverse();
    }

    return tmp;
  }
}
