import { Injectable } from '@angular/core';
import { User } from '@app/shared/data/user/user.models';
import { FeatureFlaggingEnum, FeatureFlaggingService, ISetting } from '@app/shared/services/feature-flagging/feature-flagging.service';
import { filter, take } from 'rxjs/operators';
import { ScriptLoaderService } from '@app/core/services/scripts/script-loader.service';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class C6AttributionService {
  isLoaded = false;

  constructor(private featureFlaggingService: FeatureFlaggingService,
              private scriptLoaderService: ScriptLoaderService
  ) {
  }

  public load() {
    if (this.isLoaded) {
      return;
    }
    this.featureFlaggingService.getFeatureFlag(FeatureFlaggingEnum.C6_ATTRIBUTION_SCRIPT)
      .pipe(filter(Boolean), take(1))
      .subscribe((val: ISetting) => {
        if (! val.settingValue) {
          return;
        }
        this.scriptLoaderService.load('c6-attribution-script').then((x) => {
          this.isLoaded = true;
        });
      });
  }

  public trackRegister(user: User) {
    if (! this.isLoaded) {
      return;
    }

    if (Boolean(window['biSignup'])) {
      window['biSignup']({
        name: user.full_name,
        email: user.email,
        tool_name: environment.APPLICATION_NAME,
      });
    }
  }
}
