import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { IInvoiceAPI, InvoiceAPI, WalletInvoiceAPI } from '@app/shared/data/invoice/invoice.api';
import { InvoiceActions, LoadDirectionEnum } from '@app/shared/data/invoice/invoice.actions';
import { InvoicesCollection, InvoicesTypeEnum } from '@app/shared/data/invoice/invoice.models';
import { InvoicesState } from './invoice.reducers';

@Injectable()
export class InvoiceEpics {
  constructor(private invoiceActions: InvoiceActions,
    private invoiceAPI: InvoiceAPI,
    private walletInvoiceAPI: WalletInvoiceAPI,
    private ngRedux: NgRedux<AppState>) { }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.load,
      this.download,
      this.onTypeChange,
    ];
  }

  load = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == InvoiceActions.TYPES.LOAD) {
        const direction: LoadDirectionEnum = action.data.direction

        const state: InvoicesState = store.getState()['invoices'];

        let startingAafter = direction == LoadDirectionEnum.NEXT ? state.nextPage : null;
        let endingBefore = direction == LoadDirectionEnum.PREVIOUS ? state.previousPage : null;

        const api: IInvoiceAPI = state.type === InvoicesTypeEnum.SUBSCRIPTIONS
          ? this.invoiceAPI
          : this.walletInvoiceAPI;

        api.all(startingAafter, endingBefore)
          .subscribe(
            (invoices: InvoicesCollection) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.invoiceActions.loadSucceeded(invoices, direction));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.invoiceActions.loadFailed(response));
            }
          );
      }
      return next(action);
    };
  }

  download = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === InvoiceActions.TYPES.DOWNLOAD) {
        const state: InvoicesState = store.getState()['invoices'];

        const invoiceType = action.data.type ?? state.type;

        const api: IInvoiceAPI = invoiceType === InvoicesTypeEnum.SUBSCRIPTIONS
          ? this.invoiceAPI
          : this.walletInvoiceAPI;

        api.download(action.data.invoice.id)
          .subscribe(
            (blob) => {
              const link = document.createElement('a');
              link.href = window.URL.createObjectURL(blob);
              link.download = ('SellerTools_Invoice_' + (action.data.invoice.date || action.data.invoice.paid_at).substring(0, 10) + '.pdf').replace(' ', '_').replace(',', '_');
              link.click();
            },
            () => { },
            () => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.invoiceActions.downloadSucceeded());
            }
          );
      }
      return next(action);
    };
  }
  onTypeChange = () => next => {
    return (action) => {
      const result = next(action);
      if (action.type === InvoiceActions.TYPES.SET_TYPE) {
        this.ngRedux.dispatch(this.invoiceActions.load());
      }
      return result;
    };
  }
}
