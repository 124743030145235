import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { AdminCoupon } from "@app/shared/data/admin/coupon/coupon.models";
import { AdminCouponActions } from "@app/shared/data/admin/coupon/coupon.actions";
import { AdminCouponAPI } from "@app/shared/data/admin/coupon/coupon.api";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { UserAdminActions } from "@app/shared/data/admin/user/user-admin.actions";
import { ToastService } from "@app/core/services/toast.service";

@Injectable()
export class AdminCouponEpics {
  constructor(private couponActions: AdminCouponActions,
              private couponAPI: AdminCouponAPI,
              private toastService: ToastService,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
      this.create,
      this.remove,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PaginationActions.getPagActionTypes(AdminCoupon).LOAD_PAGE || action.type == AdminCouponActions.TYPES.SEARCH) {
        let state = store.getState()['admin']['coupons'];
        let params = {};

        if(action.type == AdminCouponActions.TYPES.SEARCH) {
          let searchTerm = action.data;
          params = { ...state['_be_pagination'], searchTerm };
        } else {
          params = { ...state['_be_pagination'] };
        }

        this.couponAPI.all(params)
          .subscribe(
            (coupons: Collection<AdminCoupon>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.setPage(coupons));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.loadPageFailed(response));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };

  create = store => next => {
    return (action) => {
      if (action.type == this.couponActions.create(null).type) {
        this.couponAPI.create(action.data)
          .subscribe(
            (coupon: AdminCoupon) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.createSucceeded(coupon));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.createFailed(response.error.error));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };

  remove = store => next => {
    return (action) => {
      if (action.type == this.couponActions.remove(null).type) {
        this.couponAPI.remove(action.data)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.removeSucceeded(action.data));
              this.toastService.success('Success', action.data.code + " has been successfully removed!");
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.couponActions.removeFailed(response.error.error));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };
}
