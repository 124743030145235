<div class="box" [class.box--loading]="regenerateLoading" *ngIf="!isLoading">
  <h2 class="page-title">Live API keys ({{liveTokens?.length}})</h2>
  <st-api-keys-table [apiKeys]="liveTokens" [liveKeys]="true" [isLoading]="isLoading">
  </st-api-keys-table>

  <h2 class="page-title">Revoked API keys ({{expiredTokens?.length}})</h2>
  <st-api-keys-table [apiKeys]="expiredTokens" [liveKeys]="false" [isLoading]="isLoading"></st-api-keys-table>
  <st-access-academy></st-access-academy>
</div>

<st-line-loader *ngIf="isLoading"></st-line-loader>
