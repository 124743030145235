import { environment as defaultenv } from './environment.default';

export const environment = {
  ...defaultenv,
  production: true,
  hmr: false,
  reduxEnhancer: false,

  // localStorage
  LOCALSTORAGE_KEY: 'ST',

  // API
  API_URL       : "https://api.seller.tools",
  DATA_API_URL  : 'https://data.seller.tools',
  GRAPHS_API_URL: "https://graphs.seller.tools",
  SAMURAI_SELLER_URL: 'https://ppcs.seller.tools',

  STRIPE_PUBLISHABLE_KEY: "pk_live_KGcSD2RVnn3jcf4eRX17f9LK",
  PLAID_PUBLIC_KEY: 'e9e23860608dc740ba7e196de98371',
  PLAID_ENVIRONMENT: 'production',
  PLAID_CLIENT_NAME: 'SellerTools',

  // WEBSCOCKET CONFIG
  ECHO_HOST: "https://app.seller.tools",

  // LEAD DYNO CONFIG
  LEAD_DYNO_SECRET: "101acc487a3f666b0b96b92104f98c250ee2fe32",
  LEAD_DYNO_DOMAIN: "https://app.seller.tools",

  PROFIT_WELL_ID: "06c2839f855e498ba28bcfe03f4abb4f",

  // PAYPAL
  PAYPAL_CLIENT_ID: 'AU6SriTBc_VeZg96E8ti5cpzvN5zxEQ_9TbDpYMv1OcrdaaQnf5trnW1uvLHn7iHIU4x1-mnMg2QZtzk',

  // FACEBOOK PIXEL
  FB_KEY: "127295327989403",

  // MWS
  MWS_DEVELOPER_NUMBER_NA: "3514-8991-1408",
  MWS_DEVELOPER_NUMBER_EU: "6250-6068-6961",

  BULK_BUFFER_SIZE: 20,

  POWER_TIME_LIMIT: 60,
  REINDEX_TIME_LIMIT: 60,

  LOG_ROCKET_APP_ID: "3dmf3s/sellertools-production",

  DEPLOYED_AT: 1722855144,

  SELLER_CENTRAL_APP_ID: 'amzn1.sellerapps.app.a7ff96e6-04b6-4e4b-bbc4-089e49fb8fe2',

  // Config cat configuration for feature flagging
  CONFIG_CAT_SDK_KEY: 'f7vYCO__d0yK-Bz-61Grsw/LRJTA_ObpkaUNSeQDtJXfw',
  CONFIG_CAT_LOGGING_LEVEL: 1, // Error only
  CONFIG_CAT_POLL_INTERVAL: 120,

  RUDDER_STACK_URL: 'https://journey.seller.tools',
  RUDDER_STACK_KEY: '1rpn10TpyQA52jBhWhseUxb1spK',
  RUDDER_STACK_CONFIG_URL: 'https://journey.seller.tools',

  CHAT_BASE_URL: 'https://pickreward.com',

  SENTRY_DSN: 'https://c57907ed86d644449c7b1ce8d0f03903@o1090550.ingest.sentry.io/6106654',
  SENTRY_ENVIRONMENT: 'production',

  SMARTLOOK_KEY: '830877d50ade45e6eb835fe73a2ed743a8400fd5',

  APPLICATION_NAME: 'SellerTools',

  // C6 Auth0
  AUTH0_DOMAIN: 'login.carbon6.io',
  AUTH0_CLIENT_ID: 'Hp3nLkgNG6w8lAlkrVuBVXup2O3rucTO',
  AUTH0_AUDIENCE: 'https://app.carbon6.io',

  // C6 Navbar
  C6_NAVBAR_URL: 'https://navbar.carbon6.io/c6-navbar.js',

  // Partner stack key
  PARTNER_STACK_KEY: 'pk_IK9ny1hflVpq0aAOjLrdgNQZMCSjH04r',
}
