import { Component, AfterViewInit, ElementRef, ViewChild } from '@angular/core';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector   : 'st-prompt',
  templateUrl: './prompt.component.html',
  styleUrls  : ['./prompt.component.scss']
})
export class PromptComponent implements AfterViewInit {
  yesButtonText: string = this.i18n('Ok');
  noButtonText = this.i18n('Cancel');
  title = this.i18n('Enter value');
  message = '';
  value = '';

  isValid = true;

  validator = (value: string) => true;

  constructor(private activeModal: ActiveModal,
              private i18n: I18n) {
  }

  @ViewChild('input', { static: true }) vc: ElementRef;

  ngAfterViewInit() {
    this.vc.nativeElement.focus();
  }


  cancel(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(this.value);
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public setYesButtonText(yesButtonText: string) {
    this.yesButtonText = yesButtonText;
  }

  public setNoButtonText(noButtonText: string) {
    this.noButtonText = noButtonText;
  }

  public setMessage(message: string) {
    this.message = message;
  }

  public setValue(value: string) {
    this.value = value;
  }

  public setValidator(validator: (string) => boolean) {
    this.validator = validator;
    this.validate();
  }

  public validate() {
    this.isValid = this.validator(this.value);
  }
}
