import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { AffiliateYourCommision } from "@app/shared/data/affiliate/your-commision/your-commision.model";

const INITIAL_STATE: AppState = {
  data   : new Collection<AffiliateYourCommision>([]),
  loading: false,
};

export function affiliateYourCommisonsReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
    if (action.type == PaginationActions.getPagActionTypes(AffiliateYourCommision).LOAD_PAGE) {
        LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'affiliate-your-commisions');
        return {
          ...state,
          _be_pagination: {
            page : action.data.pagination.page,
            sort : action.data.pagination.sort,
            limit: action.data.pagination.perPage
          },
          loading       : true
        };
    }

    return paginationReducer(AffiliateYourCommision)(state, action);
}
