import { Injectable } from '@angular/core';
import { CampaignDestinationEnum } from '@app/client/campaign-manager/campaign-manager-destination/campaign-manager-destination.component';
import { IFilter, ISavedFilter } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { Action } from 'redux';
import { IProductManagerProduct } from '../product-manager/product-manager.models';
import { StAction } from '../st-action';
import {
  FileTypeEnum,
  ICampaign,
  ICampaignManagerResponse,
  ICampaignState,
  ICheckCampaignSlugResponse,
  ILoadAvailableFiltersResponse,
  ISavedFiltersListResponse,
  ISaveFilterResponse,
  OrdersUrlEnum,
  ReviewUrlEnum,
  RewardTypeEnum
} from './campaign-manager.models';

@Injectable()
export class CampaignManagerActions {
  static readonly TYPES = {
    LOAD_CAMPAIGN_LIST: '[CAMPAIGN_MANAGER]LOAD_CAMPAIGN_LIST',
    CAMPAIGN_LIST_LOADED: '[CAMPAIGN_MANAGER]CAMPAIGN_LIST_LOADED',
    CAMPAIGN_LIST_LOADED_ERROR: '[CAMPAIGN_MANAGER]CAMPAIGN_LIST_LOADED_ERROR',
    TOGGLE_CAMPAIGN: '[CAMPAIGN_MANAGER]TOGGLE_CAMPAIGN',
    SET_FILTERS: '[CAMPAIGN_MANAGER]SET_FILTERS',
    SEARCH: '[CAMPAIGN_MANAGER]SEARCH',
    UPLOAD_FILE: '[CAMPAIGN_MANAGER]UPLOAD_FILE',
    UPLOAD_FILE_SUCCESS: '[CAMPAIGN_MANAGER]UPLOAD_FILE_SUCCESS',
    UPLOAD_FILE_ERROR: '[CAMPAIGN_MANAGER]UPLOAD_FILE_ERROR',
    UPLOAD_THEME_LOGO_FILE: '[CAMPAIGN_MANAGER]UPLOAD_THEME_LOGO_FILE',
    UPLOAD_THEME_LOGO_FILE_SUCCESS: '[CAMPAIGN_MANAGER]UPLOAD_THEME_LOGO_FILE_SUCCESS',
    UPLOAD_THEME_LOGO_FILE_ERROR: '[CAMPAIGN_MANAGER]UPLOAD_THEME_LOGO_FILE_ERROR',
    SET_THEME_LOGO_FILE: '[CAMPAIGN_MANAGER]SET_THEME_LOGO_FILE',
    RESET_THEME_LOGO_FILE: '[CAMPAIGN_MANAGER]RESET_THEME_LOGO_FILE',
    UPLOAD_EBOOK_FILE: '[CAMPAIGN_MANAGER]UPLOAD_EBOOK_FILE',
    UPLOAD_EBOOK_FILE_SUCCESS: '[CAMPAIGN_MANAGER]UPLOAD_EBOOK_FILE_SUCCESS',
    UPLOAD_EBOOK_FILE_ERROR: '[CAMPAIGN_MANAGER]UPLOAD_EBOOK_FILE_ERROR',
    SAVE_FILTERS: '[CAMPAIGN_MANAGER]SAVE_FILTERS',
    SAVE_FILTERS_SUCCESS: '[CAMPAIGN_MANAGER]SAVE_FILTERS_SUCCESS',
    SAVE_FILTERS_ERROR: '[CAMPAIGN_MANAGER]SAVE_FILTERS_ERROR',
    LOAD_SAVED_FILTERS: '[CAMPAIGN_MANAGER]LOAD_SAVED_FILTERS',
    LOAD_SAVED_FILTERS_SUCCESS: '[CAMPAIGN_MANAGER]LOAD_SAVED_FILTERS_SUCCESS',
    LOAD_SAVED_FILTERS_ERROR: '[CAMPAIGN_MANAGER]LOAD_SAVED_FILTERS_ERROR',
    LOAD_AVAILABLE_FILTERS: '[CAMPAIGN_MANAGER]LOAD_AVAILABLE_FILTERS',
    LOAD_AVAILABLE_FILTERS_SUCCESS: '[CAMPAIGN_MANAGER]LOAD_AVAILABLE_FILTERS_SUCCESS',
    LOAD_AVAILABLE_FILTERS_ERROR: '[CAMPAIGN_MANAGER]LOAD_AVAILABLE_FILTERS_ERROR',

    ADD_CAMPAIGN: '[CAMPAIGN_MANAGER]ADD_CAMPAIGN',
    ADD_CAMPAIGN_SUCCESS: '[CAMPAIGN_MANAGER]ADD_CAMPAIGN_SUCCESS',
    ADD_CAMPAIGN_ERROR: '[CAMPAIGN_MANAGER]ADD_CAMPAIGN_ERROR',

    UPDATE_CAMPAIGN: '[CAMPAIGN_MANAGER]UPDATE_CAMPAIGN',
    UPDATE_CAMPAIGN_SUCCESS: '[CAMPAIGN_MANAGER]UPDATE_CAMPAIGN_SUCCESS',
    UPDATE_CAMPAIGN_ERROR: '[CAMPAIGN_MANAGER]UPDATE_CAMPAIGN_ERROR',

    GET_CAMPAIGN: '[CAMPAIGN_MANAGER]GET_CAMPAIGN',
    GET_CAMPAIGN_SUCCESS: '[CAMPAIGN_MANAGER]GET_CAMPAIGN_SUCCESS',
    GET_CAMPAIGN_ERROR: '[CAMPAIGN_MANAGER]GET_CAMPAIGN_ERROR',

    DELETE_CAMPAIGN: '[CAMPAIGN_MANAGER]DELETE_CAMPAIGN',
    DELETE_CAMPAIGN_SUCCESS: '[CAMPAIGN_MANAGER]DELETE_CAMPAIGN_SUCCESS',
    DELETE_CAMPAIGN_ERROR: '[CAMPAIGN_MANAGER]DELETE_CAMPAIGN_ERROR',

    SET_CAMPAIGN_REWARD_TYPE: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_TYPE',
    SET_CAMPAIGN_DESTINATION: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_DESTINATION',
    SET_CAMPAIGN_TRUST_RATES: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_TRUST_RATES',
    SET_CAMPAIGN_URLS_ORDERS_URL: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_URLS_ORDERS_URL',
    SET_CAMPAIGN_URLS_REVIEWS_URL: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_URLS_REVIEWS_URL',
    SET_CAMPAIGN_THANK_YOU_PAGE_HEADLINE: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_THANK_YOU_PAGE_HEADLINE',
    SET_CAMPAIGN_THANK_YOU_PAGE_SUB_HEADLINE: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_THANK_YOU_PAGE_SUB_HEADLINE',
    SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME',
    CONFIRM_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME: '[CAMPAIGN_MANAGER]CONFIRM_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME',
    SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_PRODUCT_SHORT_NAME: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_PRODUCT_SHORT_NAME',
    SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_URL_DESTINATION: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_URL_DESTINATION',
    SET_CAMPAIGN_REWARD_SETUP_REWARD_SHIPPING: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_SETUP_REWARD_SHIPPING',
    SET_CAMPAIGN_REWARD_SETUP_VIDEO_REWARD_URL: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_SETUP_VIDEO_REWARD_URL',
    SET_CAMPAIGN_REWARD_SETUP_WARRANTY_DURATION: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_SETUP_WARRANTY_DURATION',
    SET_CAMPAIGN_REWARD_SETUP_WARRANTY_LIFETIME: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_SETUP_WARRANTY_LIFETIME',
    SET_CAMPAIGN_QR_CODE_COLOR: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_QR_CODE_COLOR',
    SET_CAMPAIGN_QR_CODE_DENSITY: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_QR_CODE_DENSITY',
    SET_CAMPAIGN_QR_CODE_LOGO_URL: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_QR_CODE_LOGO_URL',
    SET_CAMPAIGN_CHECK_SLUG: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_CHECK_SLUG',
    SET_CAMPAIGN_CHECK_SLUG_SUCCESS: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_CHECK_SLUG_SUCCESS',
    SET_CAMPAIGN_CHECK_SLUG_ERROR: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_CHECK_SLUG_ERROR',
    SET_CAMPAIGN_PRODUCT: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_PRODUCT',
    SET_CAMPAIGN_REWARD_PRODUCT: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REWARD_PRODUCT',
    SET_CAMPAIGN_THEME: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_THEME',
    SET_CAMPAIGN_CONTACT_EMAIL: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_CONTACT_EMAIL',
    SET_CAMPAIGN_REJECTION_HEADLINE: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REJECTION_HEADLINE',
    SET_CAMPAIGN_REJECTION_BODY: '[CAMPAIGN_MANAGER]SET_CAMPAIGN_REJECTION_BODY',
    RESET_STATE: '[CAMPAIGN_MANAGER]RESET_STATE',
  };

  resetState(): Action {
    return {
      type: CampaignManagerActions.TYPES.RESET_STATE,
    };
  }

  setCampaignRewardType(type: RewardTypeEnum): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_TYPE,
      data: type,
    };
  }

  setCampaignDestination(destination: CampaignDestinationEnum): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_DESTINATION,
      data: destination,
    };
  }

  setCampaignCheckSlug(slug: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_CHECK_SLUG,
      data: slug,
    };
  }

  setCampaignCheckSlugSuccess(response: ICheckCampaignSlugResponse): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_CHECK_SLUG_SUCCESS,
      data: response,
    };
  }

  setCampaignCheckSlugError(error: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_CHECK_SLUG_ERROR,
      data: error,
    };
  }

  setCampaignQrCodeColor(color: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_QR_CODE_COLOR,
      data: color,
    };
  }

  setCampaignQrCodeDensity(denstiy: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_QR_CODE_DENSITY,
      data: denstiy,
    };
  }

  setCampaignQrCodeLogoUrl(logoUrl: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_QR_CODE_LOGO_URL,
      data: logoUrl,
    };
  }

  setCampaignProduct(selectedProduct: { product: IProductManagerProduct, children: string[] }) {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_PRODUCT,
      data: selectedProduct,
    };
  }

  setCampaignRewardProduct(selectedProduct: {product: IProductManagerProduct, children: string[]}){
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_PRODUCT,
      data: selectedProduct,
    }
  }

  setCampaignTheme(theme: string) {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_THEME,
      data: theme,
    };
  }

  setCampaignContactEmail(email: string) {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_CONTACT_EMAIL,
      data: email,
    }
  }

  setCampaignRejectionHeadline(headline: string){
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REJECTION_HEADLINE,
      data: headline,
    }
  }

  setCampaignRejectionBody(body: string){
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REJECTION_BODY,
      data: body,
    }
  }

  setCampaignRewardCampaignProductShortName(name: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_PRODUCT_SHORT_NAME,
      data: name,
    };
  }
  setCampaignRewardCampaignName(name: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME,
      data: name,
    };
  }

  confirmCampaignRewardCampaignName(): Action {
    return {
      type: CampaignManagerActions.TYPES.CONFIRM_CAMPAIGN_REWARD_SETUP_CAMPAIGN_NAME,
    };
  }

  setCampaignRewardCampaignUrlDestination(url: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_CAMPAIGN_URL_DESTINATION,
      data: url,
    };
  }

  setCampaignRewardShipping(shipping: string, asFallback: boolean = false): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_REWARD_SHIPPING,
      data: shipping,
      meta: {asFallback},
    }
  }

  setCampaignTrustRates(event: {phoneNumberTrustRates: string[], customerTrustRates: string[]}): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_TRUST_RATES,
      data: {
        phoneNumberTrustRates: event.phoneNumberTrustRates,
        customerTrustRates: event.customerTrustRates,
      },
    };
  }

  setCampaignUrlsOrdersUrl(url: OrdersUrlEnum): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_URLS_ORDERS_URL,
      data: url,
    };
  }

  setCampaignUrlsReviewsUrl(url: ReviewUrlEnum): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_URLS_REVIEWS_URL,
      data: url,
    };
  }

  setCampaignThankYouPageHeadline(headline: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_THANK_YOU_PAGE_HEADLINE,
      data: headline,
    };
  }

  setCampaignThankYouPageSubHeadline(subHeadline: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_THANK_YOU_PAGE_SUB_HEADLINE,
      data: subHeadline,
    };
  }

  setCampaignVideoRewardUrl(url: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_VIDEO_REWARD_URL,
      data: url,
    };
  }

  setCampaignRewardWarrantyDuration(duration: number): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_WARRANTY_DURATION,
      data: duration,
    };
  }

  setCampaignRewardWarrantyLifetime(lifetime: boolean): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_CAMPAIGN_REWARD_SETUP_WARRANTY_LIFETIME,
      data: lifetime,
    };
  }

  addCampaign(campaign: ICampaignState, active = false): StAction {
    return {
      type: CampaignManagerActions.TYPES.ADD_CAMPAIGN,
      data: {
        campaign: {...campaign, active},
        redirectToMainPage: active,
      }
    };
  }

  addCampaignSuccess(campaign: ICampaignState): StAction {
    return {
      type: CampaignManagerActions.TYPES.ADD_CAMPAIGN_SUCCESS,
      data: campaign,
    };
  }

  addCampaignError(error: { code: string, message: string }): StAction {
    return {
      type: CampaignManagerActions.TYPES.ADD_CAMPAIGN_ERROR,
      data: error,
    };
  }

  updateCampaign(campaign: ICampaignState, active = false) {
    return {
      type: CampaignManagerActions.TYPES.UPDATE_CAMPAIGN,
      data: {
        campaign: {...campaign, active},
        redirectToMainPage: active,
      }
    };
  }

  updateCampaignSuccess(campaign: ICampaignState): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPDATE_CAMPAIGN_SUCCESS,
      data: campaign,
    };
  }

  updateCampaignError(error: { code: string, message: string }): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPDATE_CAMPAIGN_ERROR,
      data: error,
    };
  }

  deleteCampaign(campaignId: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.DELETE_CAMPAIGN,
      data: campaignId,
    };
  }

  deleteCampaignSuccess(): Action {
    return {
      type: CampaignManagerActions.TYPES.DELETE_CAMPAIGN_SUCCESS,
    };
  }

  deleteCampaignError(error: { code: string, message: string }): StAction {
    return {
      type: CampaignManagerActions.TYPES.DELETE_CAMPAIGN_ERROR,
      data: error,
    };
  }

  getCampaign(campaignId: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.GET_CAMPAIGN,
      data: campaignId,
    };
  }

  getCampaignSuccess(campaign: ICampaignState): StAction {
    return {
      type: CampaignManagerActions.TYPES.GET_CAMPAIGN_SUCCESS,
      data: campaign,
    };
  }

  getCampaignError(error: { code: string, message: string }): StAction {
    return {
      type: CampaignManagerActions.TYPES.GET_CAMPAIGN_ERROR,
      data: error,
    };
  }

  loadCampaignList(currentPage: number = null, perPage: number = null, sort: string = null): StAction {
    return {
      type: CampaignManagerActions.TYPES.LOAD_CAMPAIGN_LIST,
      data: {
        currentPage,
        perPage,
        sort
      }
    };
  }

  campaignListLoaded(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.CAMPAIGN_LIST_LOADED,
      data
    };
  }

  campaignListLoadedError(data: ICampaignManagerResponse): StAction {
    return {
      type: CampaignManagerActions.TYPES.CAMPAIGN_LIST_LOADED_ERROR,
      data
    };
  }

  toggleCampaign(data: ICampaign): StAction {
    return {
      type: CampaignManagerActions.TYPES.TOGGLE_CAMPAIGN,
      data
    }
  }

  setFilters(filters: IFilter[]): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_FILTERS,
      data: {
        filters
      }
    };
  }

  search(searchTerm: string): StAction {
    return {
      type: CampaignManagerActions.TYPES.SEARCH,
      data: {
        searchTerm
      }
    };
  }

  uploadFile(file: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_FILE,
      data: {file}
    };
  }

  uploadFileSuccess(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_FILE_SUCCESS,
      data: data.url
    };
  }

  uploadFileError(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_FILE_ERROR,
      data
    };
  }

  uploadThemeLogoFile(file: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_THEME_LOGO_FILE,
      data: {file}
    };
  }

  uploadThemeLogoFileSuccess(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_THEME_LOGO_FILE_SUCCESS,
      data: data.url
    };
  }

  uploadThemeLogoFileError(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_THEME_LOGO_FILE_ERROR,
      data
    };
  }

  setThemeLogoFile(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.SET_THEME_LOGO_FILE,
      data
    };
  }

  resetThemeLogoFile(): Action {
    return {
      type: CampaignManagerActions.TYPES.RESET_THEME_LOGO_FILE,
    };
  }

  uploadEbookFile(file: any, type: FileTypeEnum): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE,
      data: {
        file, 
        type
      }
    };
  }

  uploadEbookFileSuccess(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE_SUCCESS,
      data
    };
  }

  uploadEbookFileError(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE_ERROR,
      data
    };
  }

  saveFilters(filters: ISavedFilter): StAction {
    return {
      type: CampaignManagerActions.TYPES.SAVE_FILTERS,
      data: filters
    };
  }

  saveFiltersSuccess(data: ISaveFilterResponse): StAction {
    return {
      type: CampaignManagerActions.TYPES.SAVE_FILTERS_SUCCESS,
      data: data
    };
  }

  saveFiltersError(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.SAVE_FILTERS_ERROR,
      data
    };
  }

  loadSavedFilters(): StAction {
    return {
      type: CampaignManagerActions.TYPES.LOAD_SAVED_FILTERS,
      data: null
    };
  }

  loadSavedFiltersSuccess(data: ISavedFiltersListResponse): StAction {
    return {
      type: CampaignManagerActions.TYPES.LOAD_SAVED_FILTERS_SUCCESS,
      data
    };
  }

  loadSavedFiltersError(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.LOAD_SAVED_FILTERS_ERROR,
      data
    };
  }

  loadAvailableFilters(): StAction {
    return {
      type: CampaignManagerActions.TYPES.LOAD_AVAILABLE_FILTERS,
      data: null
    };
  }

  loadAvailableFiltersSuccess(data: ILoadAvailableFiltersResponse): StAction {
    return {
      type: CampaignManagerActions.TYPES.LOAD_AVAILABLE_FILTERS_SUCCESS,
      data
    };
  }

  loadAvailableFiltersError(data: any): StAction {
    return {
      type: CampaignManagerActions.TYPES.LOAD_AVAILABLE_FILTERS_ERROR,
      data
    };
  }
}
