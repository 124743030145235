<div class="pager-range">
    <ol class="pager">
        <li>
            <a href="javascript:void(0)" (click)="!loading && hasPrevious && currentPageChange.next(currentPage-1)" [class.disabled]="loading || !hasPrevious">Previous</a>
        </li>
        <li *ngFor="let page of pages" [class.ellipsis]="!page" [class.current]="currentPage == page" [class.disabled]="loading && currentPage != page">
            <a *ngIf="page;else hellip" href="javascript:void(0)"
                (click)="!loading && currentPage != page && currentPageChange.next(page)">{{page}}</a>
            <ng-template #hellip>&hellip;</ng-template>
        </li>
        <li>
            <a href="javascript:void(0)" (click)="!loading && hasMore && currentPageChange.next(currentPage+1)" [class.disabled]="!hasMore">Next</a>
        </li>
    </ol>
</div>