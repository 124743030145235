
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { PayoutAllCommisionAdmin } from "@app/shared/data/admin/payout-all-commisions/payout-commision.model";

@Injectable()
export class PayoutAllCommisionAdminAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<PayoutAllCommisionAdmin>> {
    return this.http.get(this.constructFullUrl('api/admin/affiliations/payouts/commision/all'), { params: params }).pipe(
      map(data => {
        return PayoutAllCommisionAdmin.collect<PayoutAllCommisionAdmin>(data, PayoutAllCommisionAdmin.BACKEND_PAGINATION);
      }));
  }
}
