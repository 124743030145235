<st-dialog #dialog="dialog">
    <div dialog-header class="dialog-header">
        <div>
            <st-breadcrumb
                [value]="translateBreadcrumbs"></st-breadcrumb>
        </div>
    </div>
    <div dialog-content>
        <form [formGroup]="editBillingInformationForm"
              (submit)="save()"
              novalidate
              autocomplete="off">
            <div class="field-container">
                <st-field-container [control]="editBillingInformationForm.controls.info">
                    <textarea type="text" class="field interface-component" formControlName="info" rows="5" [disabled]="saving$ | async"></textarea>
                </st-field-container>
            </div>
        </form>
    </div>
    <div dialog-footer class="button-with-error-msg">
        <div class="button-group">
            <st-button (click)="abort()" [disabled]="saving$ | async" i18n>Abort</st-button>
            <st-button (click)="save()" [loading]="saving$ | async" [disabled]="!editBillingInformationForm.valid" i18n>Save</st-button>
        </div>
    </div>
</st-dialog>
