import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IFilter, OperatorEnum } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppState } from '../app-state.model';
import { CustomerListActions } from './customer-list.actions';
import { CustomerListAPI } from './customer-list.api';
import {
  ICustomerListState,
  ISavedFiltersListResponse,
  ISaveFilterResponse,
  TypeListEnum,
  IAddCustomerResponse,
  IImportManyChatCustomersResponse,
  ILoadCustomerOrderItemsSuccessResponse
} from './customer-list.models';

@Injectable()
export class CustomerListEpics {
  constructor(
    private customerListActions: CustomerListActions,
    private customerListApi: CustomerListAPI,
    private ngRedux: NgRedux<AppState>,
    private i18n: I18n,
  ) { }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.getCustomerList,
      this.checkPhoneNumber,
      this.importCustomers,
      this.saveFilters,
      this.loadSavedFilters,
      this.deleteView,
      this.checkMissingPhoneNumberScores,
      this.bulkMoveCustomersToTheList,
      this.bulkExportCustomers,
      this.loadAvailableFilters,
      this.loadCustomerOrderItems,
    ];
  }

  getCustomerList = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const response = next(action);
      const state: ICustomerListState = store.getState()['customer_list'];

      if (action.type === CustomerListActions.TYPES.LOAD_CUSTOMER_LIST) {
        const filters = [];

        state.appliedFilters.forEach((filter: IFilter) => {
          // each value of multiselect filters should be passed on their own
          // so [country, ==, [sl,at]] becomes [country, ==, sl] and [country,==,at]
          if (Array.isArray(filter.value)) {
            filter.value.forEach(value => filters.push({ ...filter, value }));
          } else {
            filters.push(filter);
          }
        });

        if (state.list !== TypeListEnum.NONE) {
          filters.push({
            field: 'list',
            operator: OperatorEnum.EQUALS,
            value: state.list,
          });
        } else {
          filters.push({
            field: 'list',
            operator: OperatorEnum.NOT_EQUALS,
            value: 'deny',
          });
        }

        if (state.searchTerm !== '') {
          filters.push({
            field: '*',
            operator: OperatorEnum.CONTAINS,
            value: state.searchTerm,
          });
        }

        if (state.appliedView) {
          state.appliedView.filters.forEach((filter: IFilter) => {
            filters.push(filter);
          });
        }

        this.customerListApi.getCustomerList(state.currentPage, state.perPage, filters, state.credentialId, state.sort)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.customerListActions.customerListLoaded(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.customerListLoadedError(error));
            },
          );
      }

      if (action.type === CustomerListActions.TYPES.ADD_CUSTOMER) {
        this.customerListApi.addCustomer(action.data, state.credentialId)
          .subscribe(
            (response: IAddCustomerResponse) => {
              this.ngRedux.dispatch(this.customerListActions.loadCustomerList());
              this.ngRedux.dispatch(this.customerListActions.addCustomerSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.addCustomerError(error));
            }
          )
      }

      if (action.type === CustomerListActions.TYPES.UPDATE_CUSTOMER) {
        this.customerListApi.updateCustomer(action.data.customerId, action.data.payload)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.customerListActions.updateCustomerSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.updateCustomerError(error));
            }
          )
      }
      if (action.type === CustomerListActions.TYPES.DELETE_CUSTOMER) {
        this.customerListApi.deleteCustomer(action.data)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.customerListActions.deleteCustomerSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.deleteCustomerError(error));
            }
          )
      }

      if (action.type === CustomerListActions.TYPES.SET_LIST) {
        this.ngRedux.dispatch(this.customerListActions.loadCustomerList(1));
      }

      if (action.type === CustomerListActions.TYPES.UPDATE_CUSTOMER_SUCCESS) {
        this.ngRedux.dispatch(this.customerListActions.loadCustomerList());
      }

      if (action.type === CustomerListActions.TYPES.SET_FILTERS) {
        this.ngRedux.dispatch(this.customerListActions.loadCustomerList(1));
      }

      if (action.type === CustomerListActions.TYPES.SEARCH) {
        this.ngRedux.dispatch(this.customerListActions.loadCustomerList(1));
      }

      if (action.type === CustomerListActions.TYPES.APPLY_VIEW) {
        this.ngRedux.dispatch(this.customerListActions.loadCustomerList(1));
      }

      if (action.type === CustomerListActions.TYPES.SET_CREDENTIAL_ID) {
        this.ngRedux.dispatch(this.customerListActions.loadCustomerList(1));
      }

      if (action.type === CustomerListActions.EVENTS.CHECK_MISSING_PHONE_RATINGS_FINISHED) {
        this.ngRedux.dispatch(this.customerListActions.loadCustomerList());
      }

      return response;
    };
  }

  checkPhoneNumber = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.CHECK_PHONE_NUMBER) {
        this.customerListApi.checkPublicPhoneScore(action.data.phoneNumber)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.customerListActions.checkPhoneNumberSuccess(response));
            },
            (error) => {
              let message = this.i18n('Phone number could not be checked at the moment. Please try again later.');
              if (error.status && error.status === 402) {
                message = this.i18n('Your wallet balance is too low.')
              }

              this.ngRedux.dispatch(this.customerListActions.checkPhoneNumberError(message));
            },
          );
      }
      return next(action);
    };
  }

  importCustomers = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.IMPORT_CUSTOMERS) {
        this.customerListApi.importCustomers(action.data.source, action.data.customers, action.data.apiKey, action.data.properties)
          .subscribe(
            (response: IImportManyChatCustomersResponse) => {
              this.ngRedux.dispatch(this.customerListActions.importCustomersSuccess(response.data.id));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.importCustomersError(error));
            },
          );
      }
      return next(action);
    };
  }

  saveFilters = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.SAVE_FILTERS) {
        this.customerListApi.saveFilters(action.data.name, action.data.filters)
          .subscribe(
            (response: ISaveFilterResponse) => {
              this.ngRedux.dispatch(this.customerListActions.saveFiltersSuccess(response));
              this.ngRedux.dispatch(this.customerListActions.applyView(response.data));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.saveFiltersError(error));
            },
          );
      }
      return next(action);
    };
  }

  loadSavedFilters = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.LOAD_SAVED_FILTERS) {
        this.customerListApi.getSavedFilters()
          .subscribe(
            (response: ISavedFiltersListResponse) => {
              this.ngRedux.dispatch(this.customerListActions.loadSavedFiltersSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.loadSavedFiltersError(error));
            },
          );
      }
      return next(action);
    };
  }

  deleteView = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.DELETE_VIEW) {
        this.customerListApi.deleteSavedFilter(action.data.id)
          .subscribe(
            () => {
              this.ngRedux.dispatch(this.customerListActions.deleteViewSuccess());
              this.ngRedux.dispatch(this.customerListActions.loadSavedFilters());
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.deleteViewError(error));
            },
          );
      }
      return next(action);
    };
  }

  checkMissingPhoneNumberScores = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.CHECK_MISSING_PHONE_NUMBER_SCORES) {
        this.customerListApi.checkMissingPhoneScores()
          .subscribe(
            () => {
              this.ngRedux.dispatch(this.customerListActions.checkMissingPhoneNumberScoresSuccess());
            },
            (response) => {
              this.ngRedux.dispatch(this.customerListActions.checkMissingPhoneNumberScoresError(response.error));
            },
          );
      }
      return next(action);
    };
  }

  bulkMoveCustomersToTheList = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.MOVE_CUSTOMERS) {
        this.customerListApi.moveCustomer(action.data.customersID, action.data.listType)
          .subscribe(
            () => {
              this.ngRedux.dispatch(this.customerListActions.moveCustomersSuccess(action.data));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.moveCustomersError(error));
            }
          )
      }

      return next(action);
    }
  };

  bulkExportCustomers = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.EXPORT_CUSTOMERS) {
        this.customerListApi.exportCustomers(action.data)
          .subscribe(
            (res) => {
              this.ngRedux.dispatch(this.customerListActions.exportCustomersSuccess(res));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.exportCustomersError(error));
            }
          )
      }

      return next(action);
    }
  };

  loadAvailableFilters = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.LOAD_AVAILABLE_FILTERS) {
        this.customerListApi.loadAvailableFilters()
          .subscribe(
            (res) => {
              this.ngRedux.dispatch(this.customerListActions.loadAvailableFiltersSuccess(res));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.loadAvailableFiltersError(error));
            }
          )
      }

      return next(action);
    };
  }

  loadCustomerOrderItems = () => next => {
    return (action) => {
      if (action.type === CustomerListActions.TYPES.LOAD_CUSTOMER_ORDER_ITEMS) {
        this.customerListApi.loadCustomerOrderItems(action.data.customerId)
          .subscribe(
            (res: ILoadCustomerOrderItemsSuccessResponse) => {
              this.ngRedux.dispatch(this.customerListActions.loadCustomerOrderItemsSuccess(action.data.customerId, res.data));
            },
            (error) => {
              this.ngRedux.dispatch(this.customerListActions.loadCustomerOrderItemsError(action.data.customerId));
            }
          );
      }

      return next(action);
    };
  }
}
