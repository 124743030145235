<st-modal #modal="modal" [showInstructions]="false" [showOnlyBody]="true" size="small" height="auto">
  <div modal-body class="content">
    <h2 class="modal-title" i18n>Upgrade Subscription Plan</h2>

    <ng-container *ngIf="plan">
      <div class="selected-plan-title" i18n>Selected Plan</div>

      <div class="selected-plan">
        <div class="selected-plan__title">{{plan.name}}</div>
        <div class="selected-plan__price">
          <st-plan-price [plan]="plan"></st-plan-price>
        </div>
      </div>

      <div class="total-cost-wrapper">
        <div class="total-cost-wrapper__title" i18n>Total Cost Calculation</div>
        <div class="total-cost-wrapper__table">
          <div class="total-cost-wrapper__header">
            <div class="total-cost-wrapper__header-cell" i18n>Description</div>
            <div class="total-cost-wrapper__header-cell" i18n>Amount</div>
          </div>

          <div class="total-cost-wrapper__body">
            <div class="total-cost-wrapper__body-row">
              <div class="total-cost-wrapper__body-row-title">{{plan.name}}</div>
              <div class="total-cost-wrapper__body-row-value">
                <div class="total-cost-wrapper__body-row-value-multiplier" *ngIf="plan.annual">12 x ${{(plan.display_price / 12) | number:'1.2-2'}} =</div>
                <div class="total-cost-wrapper__body-row-value-final">${{plan.display_price | number:'1.2-2'}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="coupon">
        <div class="field-container" [class.error]="errorMessage" [class.success]="coupon && coupon.valid">
          <div class="coupon-wrapper" *ngIf="canRedeemCouponCode">
            <input type="text" class="coupon-input" debounceKeyUp [debounceTime]="600"
              [(ngModel)]="couponCode"
              [disabled]="(plan && plan.remote_id === 'demo') || validatingCouponCode" i18n-placeholder placeholder="PASTE YOUR COUPON CODE HERE" name="value" autocomplete="off" />

            <st-button-v2 color="v2_blue_empty" [disabled]="(plan && plan.remote_id === 'demo') || validatingCouponCode" (clicked)="verifyCode()" label="APPLY CODE" i18n-label></st-button-v2>
          </div>
    
          <div class="plan-change__coupon-loader" *ngIf="validatingCouponCode">
            <st-icon icon="spinner"></st-icon>
          </div>

          <div *ngIf="couponErrorMessage">
            <st-error>{{ couponErrorMessage }}</st-error>
          </div>

          <div *ngIf="!canRedeemCouponCode && coupon && !coupon.valid">
            <st-error i18n>Not available for this plan</st-error>
          </div>
        </div>
      </div>

      <div class="total-cost-wrapper__table">
        <div class="total-cost-wrapper__body">
          <div class="total-cost-wrapper__body-row total-cost-wrapper__body-row--coupon" [class.total-cost-wrapper__body-row--coupon-valid]="coupon && coupon.description && coupon.valid && canRedeemCouponCode">
            <div class="total-cost-wrapper__body-row-title" i18n>
              Coupon <ng-container *ngIf="coupon && coupon.description && coupon.valid && canRedeemCouponCode">(-{{coupon.percent_off}}%)</ng-container>
            </div>
            <div class="total-cost-wrapper__body-row-value">
              <div class="total-cost-wrapper__body-row-value-multiplier">
                <ng-container *ngIf="!coupon || !coupon.valid">-$0.00</ng-container>
                <ng-container *ngIf="coupon && coupon.valid && canRedeemCouponCode">-${{getCouponAmountOff | number:'1.2-2'}}</ng-container>
              </div>
            </div>
          </div>

          <div class="total-cost-wrapper__body-row total-cost-wrapper__body-row--total">
            <div class="total-cost-wrapper__body-row-title" i18n>Total</div>
            <div class="total-cost-wrapper__body-row-value">
              <div class="total-cost-wrapper__body-row-value-final">
                <ng-container *ngIf="!coupon || !coupon.valid">${{plan.display_price | number:'1.2-2'}}</ng-container>
                <ng-container *ngIf="coupon && coupon.valid && canRedeemCouponCode">${{getTotalPrice | number:'1.2-2'}}</ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="total-amount-bill-wrapper">
        <div class="total-amount-bill">
          <div class="total-amount-bill__header">
            <st-icon icon="red-error"></st-icon>
            <span i18n>Total amount of</span>
          </div>
          <div class="total-amount-bill__amount">
            <ng-container *ngIf="!coupon || !coupon.valid">${{plan.display_price | number:'1.2-2'}}</ng-container>
            <ng-container *ngIf="coupon && coupon.valid && canRedeemCouponCode">${{getTotalPrice | number:'1.2-2'}}</ng-container>
          </div>
          <div class="total-amount-bill__text" i18n>
            will be charged to your credit card for the {{plan.annual ? 'yearly' : 'monthly'}} subscription.
          </div>
        </div>
      </div>

      <div class="buttons">
        <st-button-v2 color="v2_red_empty" [disabled]="(plan && plan.remote_id === 'demo') || validatingCouponCode" (clicked)="cancel()" label="CANCEL" i18n-label></st-button-v2>
        <st-button-v2 color="v2_red" [disabled]="(plan && plan.remote_id === 'demo') || validatingCouponCode || updatingPlan" (clicked)="save()" [label]="updatingPlan ? 'UPGRADING PLAN...' : 'CONFIRM & UPGRADE'" i18n-label></st-button-v2>        
      </div>
    </ng-container>
  </div>
</st-modal>
