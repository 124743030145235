
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { AffiliateYourCommision } from "@app/shared/data/affiliate/your-commision/your-commision.model";

@Injectable()
export class AffiliateYourCommisionAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<AffiliateYourCommision>> { 
    return this.http.get(this.constructFullUrl('api/partnership/commissions/your'), { params: params }).pipe(
      map(data => {
        return AffiliateYourCommision.collect<AffiliateYourCommision>(data, AffiliateYourCommision.BACKEND_PAGINATION);
      }));
  }
}
