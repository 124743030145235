import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { PrizmProductFilters, PrizmKeywordFilters } from '@app/shared/data/prizm/prizm.models';

@Injectable()
export class PrizmApi extends BaseAPI {
  baseApiUrl = (marketplaceId = 1) => 'api/prizm/' + marketplaceId;

  constructor(protected http: HttpClient) {
    super();
  }

  /**
   * Create api request to get results for product prizm search.
   *
   * @param {PrizmProductFilters} filters
   * @returns {Observable<any>}
   * @memberof PrizmApi
   */
  public requestProductPrizmSearch(marketplaceId: number, filters: PrizmProductFilters, pagination?: {[_: string]: any}): Observable<any> {
    const tempFilters = {...filters};
    const categories = filters.categories;
    delete tempFilters.categories;
    const paramsData = {
      ...pagination,
      ...tempFilters,
      sort: pagination['sort'].join(',')
    };

    let params = new HttpParams({fromObject: paramsData as { [_: string]: any }});
    if (!!categories) {
      params = categories.reduce((p: HttpParams, value: any) => p.append('categories[]', value), params);
    }

    return this.http.get(this.constructFullUrl(this.baseApiUrl(marketplaceId) + '/products'), {
      params: params
    });
  }

  /**
   * Example search products
   */
  public requestExampleProductSearch(marketplaceId: number): Observable<any> {
    return this.http.get(this.constructFullUrl(this.baseApiUrl(marketplaceId) + '/example/products'));
  }

  /**
   * Create api request to get categories for prizm filtering.
   *
   * @returns {*}
   * @memberof PrizmApi
   */
  public requestCategories(marketplaceId: number): any {
    return this.http.get(this.constructFullUrl(this.baseApiUrl(marketplaceId) + '/categories'));
  }

  /**
   * Create api request to get keywords for prizm.
   *
   * @returns {Observable<any>}
   * @memberof PrizmApi
   */
  public requestKeywords(marketplaceId: number, filters: PrizmKeywordFilters): Observable<any> {
    return this.http.get(this.constructFullUrl(this.baseApiUrl(marketplaceId) + '/keywords'), {
      params: filters as {[key: string]: any}
    });
  }

  /**
   * Create api request to get prizm history for specified asin.
   */
  public requestHistory(marketplaceId: number, asin: string): Observable<any> {
    return this.http.get(this.constructFullUrl(this.baseApiUrl(marketplaceId) + '/products/' + asin + '/history'));
  }
}
