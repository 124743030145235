
import {interval as observableInterval,  BehaviorSubject, Subject, Subscription , Observable} from 'rxjs';

import {map, takeUntil} from 'rxjs/operators';
import { Component, Input, ChangeDetectionStrategy, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector       : 'st-progress-bar',
  templateUrl    : './progress-bar.component.html',
  styleUrls      : ['./progress-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressBarComponent implements OnDestroy, OnChanges {

  @Input()
  percentageObservable: BehaviorSubject<any> = new BehaviorSubject(0);

  @Input()
  label: string = "";

  @Input()
  rightLabel: string = null;

  @Input()
  style: string = "red";

  @Input()
  value: number = null;

  @Input()
  max: number = null;

  @Input()
  loading: boolean = false;

  @Input()
  speed: number = 1;

  @Input()
  maxFakeProgress: number = 0;
  fakeLoading: boolean = false;

  @Input()
  resetWhenMaxFakeChanges = true;

  @Input() newLayout = false;

  displayedPercentage = new BehaviorSubject(0);

  fakeIntervalSub: Subscription = null;

  private offset = 0;

  @Input()
  set percentage(val) {
    this.setPercentage(val);
  }

  get percentage() {
    return this.displayedPercentage.getValue();
  }

  ngOnInit() {
    this.percentageObservable.pipe(takeUntil(this.onDestroy$)).subscribe(newPercentage => {

      if (newPercentage > 0){
        if (this.maxFakeProgress > 0){
          this.fakeLoading = false;
          this.setPercentage(newPercentage / (this.max / this.maxFakeProgress) + this.maxFakeProgress);
        }else{
          this.setPercentage(newPercentage);
        }
      }
    }, error => {
      console.log(error);
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.maxFakeProgress) {
      if (this.fakeIntervalSub !== null) {
        this.fakeIntervalSub.unsubscribe();
      }
      if (this.maxFakeProgress > 0) {
        this.fakeLoading = true;
        if (!this.resetWhenMaxFakeChanges) {
          this.offset = this.percentage;
        } else {
          this.offset = 0;
        }
        this.fakeIntervalSub = observableInterval(1000).pipe(map(x => x * 1000),takeUntil(this.onDestroy$),).subscribe(this.fake.bind(this));
      } else {
        this.offset = 0;
        this.fakeLoading = false;
      }
    }
  }

  private onDestroy$ = new Subject();
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  private setPercentage(val) {
    setTimeout(() => {
      this.displayedPercentage.next(Math.round(val));
    }, 100); // Delay init / change
  }

  public fake(times = 0) {
    if (this.fakeLoading) {
      const newVal = this.offset + (Math.atan(times / (2e3 / this.speed)) / (Math.PI / 2) * (this.maxFakeProgress - this.offset));
      if(newVal != this.displayedPercentage.getValue())
        this.setPercentage(newVal);
    }
  }

}
