import {
    ComponentFactoryResolver, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, TemplateRef,
    ViewContainerRef
} from '@angular/core';
import { PopoverService, PopoverServiceFactory } from "@app/shared/components/popover/services/popover.service";
import {
  TooltipWrapperComponent,
} from "@app/shared/components/popover/tooltip/tooltip-wrapper.component";

@Directive({
  selector : '[stTooltip]',
  providers: [
    PopoverServiceFactory,
  ]
})
export class TooltipDirective implements OnInit, OnDestroy {
  private _popoverService: PopoverService<TooltipWrapperComponent>;

  constructor(viewContainerRef: ViewContainerRef,
              componentFactoryResolver: ComponentFactoryResolver,
              popoverServiceFactory: PopoverServiceFactory<TooltipWrapperComponent>) {
    this._popoverService = popoverServiceFactory.createService(viewContainerRef);
  }

  ngOnDestroy() {
    this._popoverService.destroy();
  }

  @Input('stTooltip')
  content: string | TemplateRef<any>;

  @Input()
  placement: string | ElementRef = "body";

  @Input()
  position: string = "top";

  @Input()
  tooltipContext: any;

  @Input()
  disableTooltip = false;

  ngOnInit() {
    // console.log("ngOnInit -> TooltipDirective");
  }

  @HostListener('mouseenter', ['$event'])
  @HostListener('focusin', ['$event'])
  onMouseEnter($event) {
    this.open();
  }

  @HostListener('mouseleave', ['$event'])
  @HostListener('focusout', ['$event'])
  onMouseLeave($event: Event) {
    this.close();
  }

  open() {
    if (!this.disableTooltip) {
      this._popoverService.attach(TooltipWrapperComponent);
      this._popoverService.to(this.placement);
      this._popoverService.position(this.position);
      this._popoverService.withContext(this.tooltipContext);
      this._popoverService.open(this.content);
    }
  }

  close() {
    this._popoverService.close();
  }
}
