import { isObject } from "@app/helpers";

export function mergeDeep(target, source) {
  if (isObject(target) && isObject(source)) {
    Object.keys(source).forEach(key => {
      if (isObject(source[key])) {
        if (!(key in target)){
          Object.assign(target, { [key]: source[key] });
        }
        else {
          target[key] = mergeDeep(target[key], source[key]);
        }
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    });
  }
  return target;
}
