<div class="priority-wrapper" [class.priority-wrapper--disabled]="item.value.loading">
  <div class="priority-line"></div>
  <div class="priority-steps">
    <div (click)="priorityChange('VL')" class="priority-step"></div>
    <div (click)="priorityChange('L')" class="priority-step"></div>
    <div (click)="priorityChange('M')" class="priority-step"></div>
    <div (click)="priorityChange('H')" class="priority-step"></div>
  </div>
  <div [class]="'priority-handle priority-handle--' + item.value.priority"></div>
</div>
