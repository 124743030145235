import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { InvitationActions } from '@app/shared/data/invitation/invitations.actions';

const INITIAL_STATE: AppState = {
  data   : null,
  errors : [],
  loading: false,
};

export function invitationReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == InvitationActions.TYPES.FETCH_INVITATION)
    return {
      ...state,
      loading: true,
    };

  // set active Invitation
  if (action.type == InvitationActions.TYPES.FETCH_INVITATION_SUCCESS)
    return {
      ...state,
      data   : action.data,
      loading: false,
    };

  if (action.type == InvitationActions.TYPES.FETCH_INVITATION_FAILED)
    return {
      ...state,
      errors : action.data.message,
      loading: false,
    };

  if (action.type == InvitationActions.TYPES.ACCEPT_INVITATION)
    return {
      ...state,
      errors : [],
      loading_invitation: true,
    };

  // set active Invitation
  if (action.type == InvitationActions.TYPES.ACCEPT_INVITATION_SUCCESS)
    return {
      ...state,
      errors : [],
      loading: false,
      data   : action.data,
      loading_invitation: false,
    };

  if (action.type == InvitationActions.TYPES.ACCEPT_INVITATION_FAILED)
    return {
      ...state,
      errors : action.data.message,
      loading: false,
      loading_invitation: false,
    };

  return state;
}
