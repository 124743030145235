import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { LayoutModule } from '../layout.module';
import { ButtonComponent } from './forms/button/button.component';
import { CheckboxToggleComponent } from './forms/checkbox-toggle/checkbox-toggle.component';
import { CheckboxComponent } from './forms/checkbox/checkbox.component';
import { DropdownCheckboxComponent } from './forms/dropdown-checkbox/dropdown-checkbox.component';
import { DropdownRadioComponent } from './forms/dropdown-radio/dropdown-radio.component';
import { DropdownComponent } from './forms/dropdown/dropdown.component';
import { RadioButtonComponent } from './forms/radio-button/radio-button.component';
import { TextareaTagsComponent } from './forms/textarea-tags/textarea-tags.component';
import { HeaderComponent } from './header/header.component';
import { DropdownV2Component } from './forms/dropdown-v2/dropdown-v2.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { TableComponent } from '@app/client/v2/table/table.component';
import { ProductManagerBulkActionsComponent } from '@app/client/v2/product-manager/product-manager-bulk-actions/product-manager-bulk-actions.component';
import { PopoverModule } from '@app/shared/components/popover/popover.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { ChartsModule } from '@app/shared/components/charts/charts.module';
import { MyForOfModule } from '@app/shared/components/datatable/directives/my-for.module';
import { TableColumnStringComponent } from '@app/client/v2/table/columns/string/table-columns.string.component';
import { TableColumnPriorityComponent } from '@app/client/v2/table/columns/priority/table-columns.priority.component';
import { TableColumnRefreshComponent } from '@app/client/v2/table/columns/refresh/table-columns.refresh.component';
import { DefaultItemTableItemComponent } from '@app/client/v2/table/item/default/default.item.component';
import { KeywordLabeltItemTableItemComponent } from '@app/client/v2/table/item/keyword-label/keyword-label.item.component';
import { PriorityItemTableItemComponent } from '@app/client/v2/table/item/priority/priority.item.component';
import { RanktItemTableItemComponent } from '@app/client/v2/table/item/rank/rank.item.component';
import { IndexedItemTableItemComponent } from '@app/client/v2/table/item/indexed/indexed.item.component';
import { SfrItemTableItemComponent } from '@app/client/v2/table/item/sfr/sfr.item.component';
import { ProductGraphItemTableItemComponent } from '@app/client/v2/table/item/product-graph/product-graph.item.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { TableColumnTrackKeywordComponent } from '@app/client/v2/table/columns/track-keyword/table-columns.track-keyword.component';
import { KeywordR2AItemTableItemComponent } from '@app/client/v2/table/item/keyword-r2a/keyword-r2a.item.component';
import { TrackKeywordItemTableItemComponent } from '@app/client/v2/table/item/track-keyword/track-keyword.item.component';
import { CheckedItemTableItemComponent } from '@app/client/v2/table/item/checked/checked.item.component';
import { NumberWithStarItemTableItemComponent } from '@app/client/v2/table/item/number-with-star/number-with-star.item.component';
import { AsinsItemTableItemComponent } from '@app/client/v2/table/item/asins/asins.item.component';
import { DateItemTableItemComponent } from '@app/client/v2/table/item/date/date.item.component';
import { ButtonItemTableItemComponent } from '@app/client/v2/table/item/button/button.item.component';
import { DotLoaderModule } from '../dot-loader/dot-loader.module';
import { ToggleColumnsComponent } from '@app/client/v2/table/toggle-columns/toggle-columns.component';
import { MarketplaceItemTableItemComponent } from '@app/client/v2/table/item/marketplace/marketplace.item.component';
import { StatusItemTableItemComponent } from '@app/client/v2/table/item/status/status.item.component';
import { ActionItemTableItemComponent } from '@app/client/v2/table/item/action/action.item.component';
import { ProductsListItemComponent } from '@app/client/v2/table/item/products-list/products-list.item.component';

const componentList = [
  CheckboxToggleComponent,
  CheckboxComponent,
  DropdownCheckboxComponent,
  ButtonComponent,
  RadioButtonComponent,
  DropdownRadioComponent,
  DropdownComponent,
  HeaderComponent,
  TextareaTagsComponent,
  DropdownV2Component,
  TableComponent,
  TableColumnStringComponent,
  TableColumnTrackKeywordComponent,
  TableColumnPriorityComponent,
  TableColumnRefreshComponent,
  ToggleColumnsComponent,
  DefaultItemTableItemComponent,
  CheckedItemTableItemComponent,
  NumberWithStarItemTableItemComponent,
  KeywordLabeltItemTableItemComponent,
  KeywordR2AItemTableItemComponent,
  TrackKeywordItemTableItemComponent,
  PriorityItemTableItemComponent,
  RanktItemTableItemComponent,
  IndexedItemTableItemComponent,
  SfrItemTableItemComponent,
  ProductGraphItemTableItemComponent,
  ProductsListItemComponent,
  ProductManagerBulkActionsComponent,
  AsinsItemTableItemComponent,
  DateItemTableItemComponent,
  ButtonItemTableItemComponent,
  MarketplaceItemTableItemComponent,
  StatusItemTableItemComponent,
  ActionItemTableItemComponent
];

@NgModule({
  imports: [
    FormsModule,
    CommonModule,
    LayoutModule,
    AngularSvgIconModule.forRoot(),
    PopoverModule,
    PipesModule,
    ChartsModule,
    MyForOfModule,
    NgxPopperjsModule,
    DotLoaderModule,
  ],
  exports : [
    ...componentList,
  ],
  declarations: [
    ...componentList,
  ],
})
export class LayoutV2Module { }
