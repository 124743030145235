import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'st-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent {

  @Input() tags;
  _loading: boolean = false;
  @Input() set loading(value: boolean) {
    this._loading = value;
    if (!this._loading) {
      this.addingTag = false;
    }
  }

  @Output() onRemove: EventEmitter<string> = new EventEmitter();
  @Output() onAdd: EventEmitter<string> = new EventEmitter();

  @ViewChild('input') input: ElementRef;

  addingTag: boolean = false;
  tagString: string;

  add() {
    if (this._loading) {
      return;
    }
    this.addingTag = true;
    this.tagString = '';
    setTimeout(() => this.input.nativeElement.focus(),0);
  }

  remove(tag) {
    if (this._loading) {
      return;
    }
    this.onRemove.emit(tag.value);
  }

  finishAdding() {
    this.onAdd.emit(this.tagString);
  }

  inputKeyUp(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      this.finishAdding();
    }
  }
}
