
import {first} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpBackend, HttpClient } from '@angular/common/http';
import { NoticesService } from '@app/shared/layout/notices-list/notices.service';

/**
 * @see https://blog.nodeswat.com/automagic-reload-for-clients-after-deploy-with-angular-4-8440c9fdd96c
 */
@Injectable()
export class VersionCheckService {
  // this will be replaced by actual hash post-build.js
  private currentHash = '{{CURRENT_BUILD_HASH}}';

  private http: HttpClient;

  constructor(handler: HttpBackend,
              private noticeService: NoticesService) {
    this.http = new HttpClient(handler);
  }

  /**
   * Will do the call and check if the hash has changed or not
   */
  public check(): void {
    // timestamp these requests to invalidate caches
    this.http.get('/en/version.json', { params: { t: Date.now().toString() } }).pipe(
      first())
      .subscribe(
        (response: any) => {
          const hash = response.hash;
          const hashChanged = this.hasHashChanged(hash);

          // If new version, do something
          if (hashChanged) {
            this.noticeService.setVersionMismatchBanner();
          }
          // store the new hash so we wouldn't trigger versionChange again
          // only necessary in case you did not force refresh
          this.currentHash = hash;
        },
        (err) => {
          console.error(err, 'Could not get version');
        }
      );
  }

  /**
   * Checks if hash has changed.
   * This file has the JS hash, if it is a different one than in the version.json
   * we are dealing with version change
   *
   * @param newHash
   *
   * @returns {boolean}
   */
  private hasHashChanged(newHash) {
    return this.currentHash !== newHash;
  }
}
