<div id="alerts" class="overlay modal-container" [class.modal-container--large]="size === 'large'" (click)="close()">
    <div class="alert modal" (click)="$event.stopPropagation()">
        <div class="top">
            <ng-content select="[dialog-header]"></ng-content>
        </div>
        <div class="body">
            <ng-content select="[dialog-content]"></ng-content>
        </div>
        <div class="buttons">
            <ng-content select="[dialog-footer]"></ng-content>
        </div>
    </div>
</div>
