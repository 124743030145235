import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { Survey, SurveyQuestion } from "@app/shared/data/survey/survey.models";

@Injectable()
export class SurveyActions {

  static readonly TYPES = {

    GET          : '[SURVEY]GET',
    GET_SUCCEEDED: '[SURVEY]GET_SUCCEEDED',
    GET_FAILED   : '[SURVEY]GET_FAILED',

    SUBMIT: '[SURVEY]SUBMIT',
    SUBMIT_SUCCEEDED: '[SURVEY]SUBMIT_SUCCEEDED',
    SUBMIT_FAILED: '[SURVEY]SUBMIT_FAILED',
  };

  static getSurvey(surveyId: number): StAction {
    return {
      type: SurveyActions.TYPES.GET,
      data: surveyId
    };
  }

  static getSurveySucceeded(survey: Survey): StAction {
    return {
      type: SurveyActions.TYPES.GET_SUCCEEDED,
      data: survey
    }
  }

  static getSurveyFailed(error): StAction {
    return {
      type: SurveyActions.TYPES.GET_FAILED,
      data: error
    }
  }

  static submit(survey: { id: number, questions: SurveyQuestion[] }) {
    return {
      type: SurveyActions.TYPES.SUBMIT,
      data: survey
    }
  }

  static submitSucceeded(survey: Survey) {
    return {
      type: SurveyActions.TYPES.SUBMIT_SUCCEEDED,
      data: survey
    }
  }

  static submitFailed(error) {
    return {
      type: SurveyActions.TYPES.SUBMIT_FAILED,
      data: error
    }
  }
}
