
import { throwError as observableThrowError, BehaviorSubject, Observable, Subject } from 'rxjs';

import { takeUntil } from 'rxjs/operators';
import { LS } from '@app/shared/services/local-storage.service';
import { Localization } from '@app/shared/data/localization/localization.models';

export const calculateWidth = (el: HTMLElement) => {
  // Calculate width
  const style = window.getComputedStyle(el, null);
  const margin = parseFloat(style.marginLeft) + parseFloat(style.marginRight);
  return el.offsetWidth + margin;
};

export const isPhoneViewport = () => {
  return window.innerWidth <= 760;
};

export const isTabletViewport = () => {
  return !isPhoneViewport() && window.innerWidth <= 760;
};

export const parseError = (error) => {
  const msg = error['message'];

  if (typeof msg === 'string') {
    return msg;
  }

  let m = [];
  for (const k in msg) {
    if (msg.hasOwnProperty(k)) {
      m = m.concat(error['message'][k]);
    }
  }

  return m.join(',');
};

// todo: not consistant errors from backend!
export const handleError = (response) => {
  let data = response.error;
  if ('error' in data) {
    data = data.error;
  }
  let errors = [];

  if (typeof data.message === 'object') {
    for (const k in data.message) {
      if (data.message.hasOwnProperty(k)) {
        if (typeof data.message[k] === 'object') {
          errors.push(data.message[k][0]);
        } else {
          errors.push(data.message[k]);
        }
      }
    }
  } else {
    if (data.message === '') {
      data.message = response.statusText;
    }
    errors = [data.message];
  }

  return observableThrowError({ message: errors });
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function getSellerCentralRegisterDeveloperURL(domain: string, devMWSAccountId: string) {
  return `https://sellercentral.${domain}/gp/mws/registration/register.html`
  + `?signInPageDisplayed=1&devAuth=1&devMWSAccountId=${devMWSAccountId}&developerName=Seller.Tools`;
}

export function zeroPad(num: number): string {
  if (num.toString().length === 1) {
    return '0' + num;
  }
  return num.toString();
}

export function formatDate(date: Date): string {
  return date.getFullYear() + '-' + zeroPad(date.getMonth() + 1) + '-' + zeroPad(date.getDate());
}

export function isObject(o) {
  return typeof o === 'object' && o !== null;
}

export function hasProp(o, name) {
  return o === undefined ? false : o.hasOwnProperty(name);
}

export function isLowerCase(str) {
  return str === str.toLowerCase();
}

export function getURLLocale() {
  const parts = window.location.pathname.split('/');
  if (parts.length > 0) {
    return parts[1];
  }
  return null;
}

export function detectLocale() {
  const user = LS.getUser();
  const URLLocale = Localization.validateOrGetDefault(getURLLocale());
  if (user && Localization.isSupported(user.locale)) {
    const LSLocale = user.locale;
    if (LSLocale !== URLLocale) {
      window.location.replace(window.location.pathname.replace(getLanguageBaseURL(URLLocale), getLanguageBaseURL(LSLocale)));
    }
    return LSLocale;
  } else {
    return URLLocale;
  }
}

export function getTranslationFromResponse(options: {code: string, translation: string}[], response: any, defaultTranslation ?: string) {
  let error = response.error;
  if ('error' in error) {
    error = error.error;
  }

  const translation = options.filter(option => option.code === error.error_code);

  if (translation.length > 0) {
    return translation.shift().translation;
  } else if (defaultTranslation) {
    return defaultTranslation;
  } else {
    return error.message;
  }
}

export function getLanguageBaseURL(locale: string) {
  return `/${Localization.validateOrGetDefault(locale)}/`;
}

export const getAmazonUrl = (keyword: string, marketplaceDomain: string = 'amazon.com') => {
  return 'https://www.' + marketplaceDomain +  '/s/?field-keywords=' + encodeURI(keyword);
};

export function isURL(url) {
  try {
    url = new URL(url);
  } catch (_) {
    return false;
  }

  return url.protocol === "http:" || url.protocol === "https:";
}

export const pick = (...props) => o => props.reduce((a, e) => ({ ...a, [e]: o[e] }), {});
