<div class="campaign-manager-browse-products-wrapper campaign-manager__full-width">
  <st-button-v2 (clicked)="browseProducts()" color="v2_blue_empty" label="BROWSE PRODUCTS" data-qa="cm_campaign_setup_browse_products" i18n-label></st-button-v2>
</div>

<div class="campaign-manager-selected-product-wrapper" *ngIf="selectedProduct">
  <div *ngIf="selectedProduct?.product?.asin" class="campaign-manager-selected-product-details">
    <div class="campaign-manager-selected-product-details--column">
      <img [src]="selectedProduct.product.image_url || selectedProduct.product.image" [alt]="selectedProduct.product.short_title" class="campaign-manager-product-image" onerror="this.src='/assets/img/placeholder.svg'">
      <span class="campaign-manager-product-title">{{selectedProduct.product.short_title || selectedProduct.product.short_name}}</span>
    </div>
    <div class="campaign-manager-selected-product-details--column">
      <span>{{selectedProduct.product.asin}}</span>
      <st-icon class="campaign-manager-product-remove" (click)="removeSelectedProduct()" icon="cross-red" data-qa="cm_remove_advanced_filters"></st-icon>
    </div>
  </div>
  <div *ngIf="!selectedProduct?.product?.asin" class="campaign-manager-selected-product-details">
    <div class="campaign-manager-selected-product-details--column">
      <img [src]="selectedProduct.children[0].image" [alt]="selectedProduct.children[0].full_name" class="campaign-manager-product-image" onerror="this.src='/assets/img/placeholder.svg'">
      <span class="campaign-manager-product-title">{{selectedProduct.children[0].full_name}}</span>
    </div>
    <div class="campaign-manager-selected-product-details--column">
      <span>{{selectedProduct?.variations?.asin}}</span>
      <st-icon class="campaign-manager-product-remove" (click)="removeSelectedProduct()" icon="cross-red" data-qa="cm_remove_advanced_filters"></st-icon>
    </div>
  </div>
  <div *ngIf="selectedProduct?.children?.length && !singleVariationSelection" class="campaign-manager-selected-product-variations" (click)="browseProducts()" data-qa="cm_campaign_setup_browse_products_selected_variations">
    Selected Variations ({{selectedProduct.children && selectedProduct.children.length}})
  </div>
</div>
