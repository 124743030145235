import { AppState } from '@app/shared/data/app-state.model';
import { NotificationAction } from '@app/shared/data/notification/notifications.actions';
import { Collection } from '@app/shared/data/base.models';
import { UnreadNotificationsActions } from '@app/shared/data/notification/notifications-unread.actions';

interface UnreadNotificationState extends AppState {
  data: Collection<Notification>;
  loading: boolean;
  has_new: boolean;
  total: number;
}

const INITIAL_STATE: UnreadNotificationState = {
  data   : new Collection(),
  loading: false,
  has_new: false,
  total: 0,
};

export function unreadNotificationsReducer(
  state: UnreadNotificationState = INITIAL_STATE, action: NotificationAction
): UnreadNotificationState {
  if (action.type === UnreadNotificationsActions.TYPES.FETCH_NOTIFICATIONS) {
    return {
      ...state,
      loading: true
    };
  }

  if (action.type === UnreadNotificationsActions.TYPES.FETCH_NOTIFICATIONS_FAILED) {

    return {
      ...state,
      data   : INITIAL_STATE.data,
      loading: false
    };
  }

  if (action.type === UnreadNotificationsActions.TYPES.FETCH_NOTIFICATIONS_SUCCESS) {
    return {
      ...state,
      data   : action.data,
      total: action.data.getMeta('total'),
      loading: false
    };
  }

  if (action.type === UnreadNotificationsActions.TYPES.ADD_NOTIFICATION) {
    return {
      ...state,
      data   : state.data.prepend(action.data),
      loading: false,
      has_new: true,
      total: state.total + 1,
    };
  }

  if (action.type === UnreadNotificationsActions.TYPES.READ_NOTIFICATION) {
    return {
      ...state,
      data   : state.data.filter(notification => notification !== action.data),
      loading: false
    };
  }

  if (action.type == UnreadNotificationsActions.TYPES.READ_ALL_NOTIFICATIONS_SUCCEEDED) {
    return {
      ...state,
      data: new Collection(),
      total: 0
    };
  }

  if (action.type == UnreadNotificationsActions.TYPES.OPEN_NOTIFICATION_DROPDOWN) {
    return {
      ...state,
      has_new: false,
    };
  }

  return state;
}
