import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { Commission } from "@app/shared/data/commission/commission.models";

const INITIAL_STATE: AppState = {
  data   : new Collection<Commission>([]),
  loading: false
};

export function commissionReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  return paginationReducer(Commission)(state, action);
}
