<div class="pagination-info" [class.pagination-info--disabled]="disabled">
  <div class="pagination-info__text">
    {{text}}
  </div>
  <div class="pagination-info__quantity">
    <st-dropdown
      dropdownPosition="up"
      [disabled]="disabled"
      color="blue"
      [preSelectedOption]="preSelectedOption"
      (selected)="pageChange($event)"
      [options]="options"></st-dropdown>
  </div>
</div>
