import { BaseModel } from '@app/shared/data/base.models';

export class RewardType {
  static readonly AMAZON_GIFT_CARD = 'amazon_gift_card';
  static readonly AMAZON_GIFT_CARD_LINK = 'amazon_gift_card_link';
  static readonly VISA_GIFT_CARD = 'visa_gift_card';
  static readonly MASTERCARD_GIFT_CARD = 'mastercard_gift_card';
  static readonly PICKREWARD = 'pickreward';
  static readonly PAYPAL = 'paypal';
  static readonly VENMO = 'venmo';
}

export class WalletTransaction extends BaseModel {
  static className = 'WalletTransaction';

  // Made without user/team interference (eg. cron)
  static readonly SOURCE_AUTO = 'auto';
  // Made through public/data API
  static readonly SOURCE_API = 'api';
  // Made through ST app
  static readonly SOURCE_UI = 'ui';
  // Made by SellerTools team either through administration or directly in DB.
  static readonly SOURCE_ST_TEAM = 'st';

  static readonly TYPE_USER_TOP_UP = 'user_top_up';
  static readonly TYPE_CHARGE_REBATE_LAST_LAUNCH = 'charge_rebate_last_launch';
  static readonly TYPE_CHARGE_GIFT_CARD_PURCHASE = 'charge_gift_card_purchase';
  static readonly TYPE_REFUND_REBATE_LAST_LAUNCH = 'refund_rebate_last_launch';
  static readonly TYPE_REFUND_UNUSED_REBATE = 'refund_unused_rebate';
  static readonly TYPE_CHARGE_TRANSFER_TO_CREDIT_CARD = 'charge_transfer_to_cc';
  static readonly TYPE_CHARGE_PHONE_NUMBER_SCORE = 'charge_phone_number_score';

  id: number;
  hash: string;
  source: string;
  type: string;
  status: string;
  stripe_invoice_id?: string;
  stripe_payment_method?: string;
  stripe_client_secret?: string;
  stripe_payment_intent?: string;
  stripe_account?: string;
  receiver_identifier?: string;
  amount: number;
  additional?: object;
  is_available: boolean;
  is_confirmed: boolean;
  has_failed: boolean;
  created_at: Date;
  purchase?: any;

  public isUnusedRebateRefund() {
    return this.type === WalletTransaction.TYPE_REFUND_UNUSED_REBATE;
  }

  public isGiftCardPurchase() {
    return this.type === WalletTransaction.TYPE_CHARGE_GIFT_CARD_PURCHASE;
  }

  public hasGiftCard() {
    return this.purchase && !!this.purchase.value;
  }

  public isPurchasedValueDifferentThanWalletCharge() {
    return this.purchase && (Math.abs(this.purchase.amount + this.amount) !== 0 || this.hasFees());
  }

  public hasExchangeRate() {
    return this.additional !== null && 'exchange_rate' in this.additional;
  }

  public hasFees() {
    return this.fees().length > 0;
  }

  public fees() {
    if (this.additional === null || !('fees' in this.additional)) {
      return [];
    }

    return this.additional['fees'];
  }

  public hasOrderId() {
    return this.purchase !== null &&
      'ref_order_id' in this.purchase &&
      null !== this.purchase['ref_order_id'];
  }

  public getMarketplace() {
    if (this.purchase) {
      const marketplace = this.purchase['marketplace'].replace('-', '.');

      if (this.purchase['type'] === RewardType.AMAZON_GIFT_CARD || this.purchase['type'] === RewardType.AMAZON_GIFT_CARD_LINK) {
        return 'Amazon.' + marketplace;
      }

      if (marketplace === 'com') {
        return 'United States (USD)';
      }

      if (marketplace === 'ca') {
        return 'Canada (CAD)';
      }

      if (marketplace === 'co.uk') {
        return 'United Kingdom (GBP)';
      }

      if (marketplace === 'es') {
        return 'Spain (EUR)';
      }

      if (marketplace === 'fr') {
        return 'France (EUR)';
      }

      if (marketplace === 'it') {
        return 'Italy (EUR)';
      }

      if (marketplace === 'de') {
        return 'Germany (EUR)';
      }

      return marketplace;
    }

    return '';
  }
}

export class EmailLog extends BaseModel {
  static readonly TYPE_SEND = 'send';
  static readonly TYPE_DEFERRAL = 'deferral';
  static readonly TYPE_HARD_BOUNCE = 'hard_bounce';
  static readonly TYPE_SOFT_BOUNCE = 'soft_bounce';
  static readonly TYPE_OPEN = 'open';
  static readonly TYPE_CLICK = 'click';
  static readonly TYPE_SPAM = 'spam';
  static readonly TYPE_UNSUB = 'unsub';
  static readonly TYPE_REJECT = 'reject';

  reward_id: number;
  type: string;
  email_address: string;
  url?: string;
  ip?: string;
  created_at: Date;
}

export class TransactionStats extends BaseModel {
  loading: false;
  '24_hours': 0;
  '7_days': 0;
  '30_days': 0;
}
