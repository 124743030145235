import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { SessionLog } from "@app/shared/data/session-logs/session-logs.models";
import { Collection } from "@app/shared/data/base.models";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Action } from "redux";

@Injectable()
export class SessionLogsActions {
  protected model = SessionLog;

  static readonly TYPES = {
    LOAD_SESSION_LOGS        : '[PROMOTION]LOAD_SESSION_LOGS',
    SESSION_LOGS_CHANGE_CURRENT_PAGE : '[PROMOTION]SESSION_LOGS_CHANGE_CURRENT_PAGE',
    SESSION_LOGS_CHANGE_PER_PAGE : '[PROMOTION]SESSION_LOGS_CHANGE_PER_PAGE',
    LOAD_SESSION_LOGS_SUCCESS: '[PROMOTION]LOAD_SESSION_LOGS_SUCCESS',
    LOAD_SESSION_LOGS_FAILED : '[PROMOTION]LOAD_SESSION_LOGS_FAILED',
  };

  public fetchPromotions(): Action {
    return {
      type: SessionLogsActions.TYPES.LOAD_SESSION_LOGS,
    }
  }

  updateCurrentPage(event: { page: number }): StAction {
    return {
      type: SessionLogsActions.TYPES.SESSION_LOGS_CHANGE_CURRENT_PAGE,
      data: event,
    }
  }

  updatePerPage(event: { perPage: number, page?: number }): StAction {
    return {
      type: SessionLogsActions.TYPES.SESSION_LOGS_CHANGE_PER_PAGE,
      data: event,
    }
  }

  public fetchSessionLogsSucceeded(response): StAction {
    return {
      type: SessionLogsActions.TYPES.LOAD_SESSION_LOGS_SUCCESS,
      data: response
    }
  }

  public fetchSessionLogsFailed(error: any) {
    return {
      type: SessionLogsActions.TYPES.LOAD_SESSION_LOGS_FAILED,
      data: error
    }
  }

}
