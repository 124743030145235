import { environment } from '@env/environment';

export interface ScriptData {
  name: string;
  src: string;
  reload: boolean;
  unload_scripts: string[];
  unload_variables: string[];
}

export const ScriptStore: ScriptData[] = [
  {
    name: 'manychat',
    src: '//widget.manychat.com/461068944273409.js',
    reload: false,
    unload_scripts: [],
    unload_variables: [],
  },
  {
    name: 'paypal',
    // &commit=false is needed so we can add fees after payer is selected
    src: `https://www.paypal.com/sdk/js?client-id=${environment.PAYPAL_CLIENT_ID}&commit=false`,
    reload: false,
    unload_scripts: [],
    unload_variables: [],
  },
  {
    name: 'c6-navbar',
    src: environment.C6_NAVBAR_URL,
    reload: false,
    unload_scripts: [],
    unload_variables: [],
  },
  {
    name: 'c6-attribution-script',
    src: environment.C6_ATTRIBUTION_SCRIPT_URL,
    reload: false,
    unload_scripts: [],
    unload_variables: [],
  },
  {
    name: 'partner-stack-script',
    src: environment.PARTNER_STACK_SCRIPT_URL,
    reload: false,
    unload_scripts: [],
    unload_variables: [],
  },
];
