
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { SystemNotificationAdmin } from "@app/shared/data/admin/system-notification/system-notification.models";

@Injectable()
export class SystemNotificationAdminAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<SystemNotificationAdmin>> {
    return this.http.get(this.constructFullUrl('api/admin/notices'), { params: params }).pipe(
      map(data => SystemNotificationAdmin.collect<SystemNotificationAdmin>(data, SystemNotificationAdmin.BACKEND_PAGINATION)));
  }

  public save(notification: SystemNotificationAdmin): Observable<SystemNotificationAdmin> {
    let r = notification.id
      ? this.http.put(this.constructFullUrl('api/admin/notices/' + notification.id), notification.getData())
      : this.http.post(this.constructFullUrl('api/admin/notices'), notification.getData());

    return r.pipe(map(d => new SystemNotificationAdmin(d)));
  }

  public remove(id): Observable<any> {
    return this.http.delete(this.constructFullUrl('api/admin/notices/' + id));
  }
}
