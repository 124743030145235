import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { AdminPlanAPI } from "@app/shared/data/admin/plan/plan.api";
import { AdminPlan } from "@app/shared/data/admin/plan/plan.models";
import { AdminPlanActions } from "@app/shared/data/admin/plan/plan.actions";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { SystemNotificationAdmin } from "@app/shared/data/admin/system-notification/system-notification.models";

@Injectable()
export class AdminPlanEpics {
  constructor(private adminPlanApi: AdminPlanAPI,
              private adminPlanActions: AdminPlanActions,
              private ngRedux: NgRedux<AppState>) {}

  public createEpic() {
    return [
      this.loadPage,
      this.getPlan,
      this.savePlan,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PaginationActions.getPagActionTypes(AdminPlan).LOAD_PAGE) {
        let state = store.getState()['admin']['plans'];
        let params = { ...state['_be_pagination'], ...state['_filters'] };

        this.adminPlanApi.load(params)
          .subscribe(
            (plans: Collection<AdminPlan>) => {
              this.ngRedux.dispatch(this.adminPlanActions.setPage(plans));
            },
            (response) => {
              this.ngRedux.dispatch(this.adminPlanActions.loadPageFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  getPlan = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == AdminPlanActions.TYPES.GET) {
        this.adminPlanApi.get(action.data)
          .subscribe(
            (plan: AdminPlan) => {
              this.ngRedux.dispatch(AdminPlanActions.getPlanSucceeded(plan));
            },
            (response) => {
              this.ngRedux.dispatch(AdminPlanActions.getPlanFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  savePlan = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == AdminPlanActions.TYPES.SAVE) {
        this.adminPlanApi.save(action.data.plan, action.data.limits, action.data.permissionsChanges)
          .subscribe(
            (plan: AdminPlan) => {
              this.ngRedux.dispatch(AdminPlanActions.saveSuccedded(plan));
            },
            (response) => {
              this.ngRedux.dispatch(AdminPlanActions.saveFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    }
  };
}
