import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { Collection } from '@app/shared/data/base.models';
import { LastLaunchScheduleActions } from '@app/shared/data/last-launch-schedule/last-launch-schedule.actions';
import { LastLaunchScheduleAPI } from '@app/shared/data/last-launch-schedule/last-launch-schedule.api';
import {
  LastLaunchSchedule,
  LastLaunchScheduleCoupon,
  LastLaunchScheduleDate,
  LastLaunchScheduleKeyword,
  LastLaunchScheduleProduct
} from '@app/shared/data/last-launch-schedule/last-launch-schedule.models';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class LastLaunchScheduleEpics {
  constructor(private lastLaunchScheduleActions: LastLaunchScheduleActions,
    private store: NgRedux<AppState>,
    private lastLaunchScheduleAPI: LastLaunchScheduleAPI,
    private i18n: I18n) {
  }

  public createEpic() {
    return [
      this.obtainAvailableDates,
      this.savePromotion,
      this.loadPromotion,
      this.productSearch,
      this.saveProduct,
      this.saveUnits,
      this.saveCoupons,
      this.pay,
    ];
  }

  obtainAvailableDates = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchScheduleActions.TYPES.OBTAIN_AVAILABLE_DATES) {
        this.lastLaunchScheduleAPI.availableDates({ schedule: action.data.scheduleId })
          .subscribe(
            (dates: Collection<LastLaunchScheduleDate>) => {
              this.store.dispatch(this.lastLaunchScheduleActions.obtainAvailableDatesSucceeded(dates));
            },
            (response) => {
              // TODO: Error
              console.error(response);
            },
          );
      }

      return next(action);
    };
  }

  savePromotion = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PROMOTION) {
        this.lastLaunchScheduleAPI.save(action.data.schedule, action.data.coupon)
          .subscribe(
            (schedule: LastLaunchSchedule) => {
              this.store.dispatch(this.lastLaunchScheduleActions.savePromotionSucceeded(schedule));
            },
            (response) => {
              this.store.dispatch(this.lastLaunchScheduleActions.savePromotionFailed(response.error.error.message || response.error.error));
            }
          );
      }
      return next(action);
    };
  }

  loadPromotion = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === LastLaunchScheduleActions.TYPES.LOAD_PROMOTION || (action.type === LastLaunchScheduleActions.SOCKET_TYPES.STATUS_HAS_CHANGED && store.getState()['last_launch_schedule']['schedule']['id'] === action.data.promoId)) {
        const promoId = action.data.promoId;

        if (promoId === 'new') {
          this.store.dispatch(this.lastLaunchScheduleActions.loadPromotionSucceeded(new LastLaunchSchedule({
            type: action.data.type,
            duration: 1,
          })));
        } else {
          this.lastLaunchScheduleAPI.getPromotion(promoId)
            .subscribe(
              (promotion: LastLaunchSchedule) => {
                this.store.dispatch(this.lastLaunchScheduleActions.loadPromotionSucceeded(promotion));
              },
              (response) => {
                this.store.dispatch(this.lastLaunchScheduleActions.loadPromotionFailed(response));
              }
            );
        }
      }
      return n;
    };
  }

  productSearch = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === LastLaunchScheduleActions.TYPES.SEARCHING_FOR_PRODUCT) {
        this.lastLaunchScheduleAPI.searchProducts(action.data.promotion_id, action.data.query).subscribe(
          (products: Collection<LastLaunchScheduleProduct>) => {
            this.store.dispatch(this.lastLaunchScheduleActions.searchForProductSucceeded(products));
          },
          (error) => {
            if (error.status === 422) {
              // tslint:disable-next-line:max-line-length
              error = this.i18n('For best performance choose the ASIN that organically comes up for the particular keyword you are launching on and NOT parent ASIN.');
            } else {
              error = null;
            }
            this.store.dispatch(this.lastLaunchScheduleActions.searchForProductFailed(error));
          });
      }
      return n;
    };
  }

  saveProduct = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchScheduleActions.TYPES.SAVE_PRODUCT) {
        this.lastLaunchScheduleAPI.saveProduct(action.data.schedule, action.data.product).subscribe(
          (schedule: LastLaunchSchedule) => {
            this.store.dispatch(this.lastLaunchScheduleActions.saveProductSucceeded(schedule));
          },
          (error) => {
            this.store.dispatch(this.lastLaunchScheduleActions.savePromotionFailed(error));
          });
      }
      return next(action);
    };
  }

  saveUnits = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchScheduleActions.TYPES.SAVE_UNITS) {
        this.lastLaunchScheduleAPI.saveUnits(action.data.schedule.id, action.data.keywords).subscribe(
          (schedule: LastLaunchSchedule) => {
            this.store.dispatch(this.lastLaunchScheduleActions.saveUnitsSucceeded(schedule));
          },
          (error) => {
            this.store.dispatch(this.lastLaunchScheduleActions.saveUnitsFailed(error));
          });
      }
      return next(action);
    };
  }

  saveCoupons = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchScheduleActions.TYPES.SAVE_COUPONS) {
        this.lastLaunchScheduleAPI.saveCoupons(action.data.schedule.id, action.data.coupons).subscribe(
          (schedule: LastLaunchSchedule) => {
            this.store.dispatch(this.lastLaunchScheduleActions.saveCouponsSucceeded(schedule));
          },
          (error) => {
            this.store.dispatch(this.lastLaunchScheduleActions.saveCouponsFailed(error));
          });
      }
      return next(action);
    };
  }

  pay = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchScheduleActions.TYPES.PAY) {
        this.lastLaunchScheduleAPI.pay(action.data.schedule.id, action.data.useWallet, action.data.toPayAmount).subscribe(
          (schedule: LastLaunchSchedule) => {
            if (schedule.is_paid) {
              this.store.dispatch(this.lastLaunchScheduleActions.paySucceeded(schedule));
            }
          },
          (error) => {
            if (error.status === 409 && error.error.id !== undefined) {
              const schedule = LastLaunchScheduleAPI.transformFullPromotion(error.error);

              this.store.dispatch(this.lastLaunchScheduleActions.obtainAvailableDates(schedule.id));
              this.store.dispatch(this.lastLaunchScheduleActions.paymentFailedBecauseSlotHasExpired(schedule));
            } else if ('code' in error) { // stripe error
              this.store.dispatch(this.lastLaunchScheduleActions.payFailed(error));
            } else {
              this.store.dispatch(this.lastLaunchScheduleActions.payFailed(error.error));
            }
          });
      }
      return next(action);
    };
  }
}
