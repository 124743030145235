<div tabindex="0" (keydown)="keyDown($event)" (blur)="focusLost($event)" class="range-container" [class.expand]="showLabels$ | async" [class.disabled]="disabled" #rangeContainer>
    <div class="selected-range-container">
        <div [style.left]="(fromLeftOffset$ | async) + 'px'" [style.right]="(toRightOffset$ | async)+'px'" class="range">
            &nbsp;&nbsp;
            <span class="l">
                <!--
                <span class="lab">
                    {{fromVal$ | async}}
                </span>
                -->
                <span class="lab">
                    <input size="2" (blur)="focusLost($event)" (keyup)="inputKeyUp($event, 0)" #inputLeft>
                </span>
            </span>
            <span class="r">
                <!--<span class="lab">{{toVal$ | async}}</span>-->
                <span class="lab">
                    <input size="2" (blur)="focusLost($event)" (keyup)="inputKeyUp($event, 1)" #inputRight>
                </span>
            </span>
        </div>
    </div>
    <div class="input-placeholder" ></div>
    <div class="content-placeholder"><ng-content></ng-content></div>
    <div class="graph-container" [hidden]="!(showGraphContainer$ | async)">
        <canvas class="graph" [class.animate]="showGraph$ | async" #canvas>
        </canvas>
    </div>
</div>
