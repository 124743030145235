
import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { Promotion } from '@app/shared/data/promotion/promotion.models';
import { handleError } from '@app/helpers';

@Injectable()
export class PromotionApi extends BaseAPI {

  constructor(protected http: HttpClient) {
    super();
  }

  public get(token: string, params = {}): Observable<Promotion> {
    return this
      .http.get(this.constructFullUrl(`api/promotions/${token}`), {
        params
      })
      .pipe(map((response: { data: Promotion }) => response.data)).pipe(
      // catchError(handleError)
      );
  }
}
