
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { Collection } from "@app/shared/data/base.models";
import { BaseAPI } from "@app/shared/data/base.api";
import { Coupon } from "@app/shared/data/coupon/coupon.models";
import { CustomURLEncoder } from '@app/shared/data/product-manager/product-manager.api';

@Injectable()
export class CouponAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<Coupon>> {
    return this.http.get(this.constructFullUrl('api/billing/coupons'), { params: params }).pipe(
      map(data => Coupon.collect<Coupon>(data)));
  }

  public create(data: any): Observable<Coupon> {
    return this.http.post(this.constructFullUrl('api/billing/coupons'), data).pipe(
      map(data => new Coupon(data)));
  }

  public get(coupon: Coupon) {
    const params: HttpParams = new HttpParams({encoder: new CustomURLEncoder()})
      .set('feature', coupon.feature ? coupon.feature : null)
      .set('plan', coupon.plan || null);

    return this.http.get(this.constructFullUrl(`api/billing/coupons/${encodeURIComponent(coupon.code)}`), {params}).pipe(
               catchError(e => observableThrowError(e.error)),
               map(data => new Coupon(data)),);
  }

  public remove(coupon: Coupon): Observable<any> {
    return this.http.delete(this.constructFullUrl((`api/billing/coupons/${coupon.code}`)));
  }
}
