import { BaseModel } from '@app/shared/data/base.models';
import { Credential } from '@app/shared/data/credential/credential.models';

export class User extends BaseModel {
  id: number;
  business_name: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  email: string;
  timezone?: number;
  locale: string;
  paypal_email: string;
  default_commission_ratio: number;
  default_coupon_percent_off: number;
  registration_finished_at?: Date;
  source?: string;
  referrer?: string;
  utm_source?: string;
  utm_medium?: string;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  is_verified: boolean;
  is_impersonated?: boolean;
  is_active: boolean;
  last_payment_failed_at?: boolean;
  last_unread_app_update?: any;
  plan_name?: string;
  stripe_id?: string;
  trial_ends_in_days?: number;
  trial_ends_in_time?: number;
  active_features: any;
  trialCouponCode: string;
  permissions: any;
  coaching_call_viewed: boolean;
  active_credential?: number;
  billing_information?: string[];
  wallet_balance?: number;
  is_risky: boolean;
  intercom_hash: string;
  token: string;
  allCredentials: Credential[];
  states: UserState[];
  created_at: Date;
  is_wallet_verified: boolean;

  /**
   * Additional options for user
   * for example:
   * {
   *    logrocket_enabled: true <- this will enable tracking with logRocket
   * }
   */
  extras?: any;

  /**
   * Get User's full name
   * @return {string}
   */
  public getFullName(): string {
    return this.first_name + ' ' + this.last_name;
  }

  get full_name() {
    return this.getFullName();
  }

  /**
   * @param permission
   * @param ignoreAdmin -> if ignore admin flag is true, it will not check if user is admin
   * @returns {boolean}
   */
  public hasPermission(permission: string, ignoreAdmin: boolean = false): boolean {
    return !!this?.permissions && (
      this.permissions.indexOf(permission) !== -1
      || (!ignoreAdmin && this.permissions.indexOf('admin.super') !== -1)
    );
  }

  /**
   * @returns {boolean}
   */
  public isAdmin(): boolean {
    return this.hasPermission('admin.super');
  }

  /**
   * @returns {boolean}
   */
  public isActive(): boolean {
    return this.is_active;
  }

  /**
   * @returns {boolean}
   */
  public hasSubscription(): boolean {
    return this.plan_name !== null;
  }

  /**
   * Return new User instance with given token
   */
  public withToken(token): User {
    return this.clone(User, { token: token }) as User;
  }

  /**
   * Add Credential to User.
   * @param credential
   */
  public pushCredential(credential: Credential) {
    return this.clone(User, { allCredentials: this['allCredentials'].concat(credential) });
  }

  /**
   * Update User's Credential
   * @param credential
   */
  public updateCredential(credential: Credential) {
    return this.clone(User, {
      allCredentials: this['allCredentials'].map((c) => {
        if (c.id === credential.id) {
          return credential;
        }
        return c;
      })
    });
  }

  /**
   * Remove Credential from User
   * @param credential
   */
  public removeCredential(credential: Credential) {
    return this.clone(User, { allCredentials: this['allCredentials'].filter(c => c.id !== credential.id) });
  }

  /**
   * Update User's Credential Limits
   * @param credential
   */
  public updateCredentialLimits(credential: Credential, limits: any) {
    return this.clone(User, {
      allCredentials: this['allCredentials'].map((c) => {
        if (c.id === credential.id) {
          c.limits = limits;
        }
        return c;
      })
    });
  }

  /**
   * @param val
   */
  public setIsImpersonating(val = true) {
    return this.clone(User, { is_impersonated: val });
  }

  /**
   * @param val
   */
  public setIsVerified(val = true) {
    return this.clone(User, { is_verified: val });
  }

  /**
   * @return {any}
   */
  public isImpersonating(): boolean {
    return this.is_impersonated;
  }

  public isBasicInfoComplete(): boolean {
    return !!this.first_name && !!this.last_name && !!this.business_name;
  }

  /**
   * @return {boolean}
   */
  public isSubUser(): boolean {
    return this['allCredentials'].length > 0 && this['allCredentials'].filter(c => c.owner.id === this.id).length === 0;
  }

  /**
   * Set finished registration attr.
   * @return {any}
   */
  public finishRegistration(): User {
    if (this.registration_finished_at != null) {
      return this;
    }
    return this.clone(User, { registration_finished_at: (new Date()).toISOString().substring(0, 19).replace('T', ' ') });
  }

  /**
   * @return {string}
   */
  public getInitials() {
    if (!this.first_name.length || !this.last_name.length) {
      return '??';
    }

    return this.first_name.substring(0, 1).toUpperCase() + this.last_name.substring(0, 1).toUpperCase();
  }

  public canAccessKeywords() {
    return this.active_features && this.active_features.includes('keywords') && this.active_credential;
  }

  public getOwner() {
    if (!!this.active_credential) {
      const owner = this['allCredentials'].find(credential => credential.id === this.active_credential);

      if (!owner) {
        return this;
      }

      return this.clone(User, owner.owner);
    }

    return this;
  }

  public getIdentificationProperties() {
    let owner = this.getOwner();
    return {
      'first_name': this.first_name,
      'last_name': this.last_name,
      'user_email': this.email,
      'stripe_id': owner.stripe_id,
    };
  }


  public getProfitWellSettings() {
    let owner = this.getOwner();
    return {
      'user_email': owner.email,
      'user_id': owner.stripe_id,
    };
  }

  public setWalletBalance(balance) {
    let ownerId = this.id;

    if (!!this.active_credential) {
      ownerId = this['allCredentials'].find(credential => credential.id === this.active_credential).owner.id;
    }

    return this.clone(User, {
      wallet_balance: balance,
      allCredentials: this['allCredentials'].map((c) => {
        if (c.owner.id === ownerId) {
          return {
            ...c,
            owner: {
              ...c.owner,
              wallet_balance: balance,
            }
          };
        }
        return c;
      })
    });
  }

  /**
   * @param val
   */
  public setCoachingOfferState(val = true) {
    return this.clone(User, {coaching_call_viewed: val});
  }
}

export enum UserBackedStateEnum {
  USER_AUTH_FLOW_STEP = 'user_auth_flow_step'
}

export enum RegistrationStepsEnum {
  CHOOSE_SUBSCRIPTION = 'choose_subscription'
}

export interface UserState {
  state: UserBackedStateEnum;
  value: string;
}
