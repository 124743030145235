<div class="tags-container" [class.disabled]="_loading">
  <div class="tag" *ngFor="let tag of tags">
    {{tag?.value}}
    <st-icon class="remove-tag" icon="cross-v2" width="10" height="10" (click)="remove(tag)"></st-icon>
  </div>
  <div *ngIf="!addingTag; else addTag" class="add-tag" (click)="add()" i18n>
    + ADD TAG
  </div>
  <ng-template #addTag>
    <input #input placeholder="TYPE TAG NAME" i18n-placeholder [(ngModel)]="tagString" (blur)="finishAdding()" (keyup)="inputKeyUp($event)">
  </ng-template>
</div>
