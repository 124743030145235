import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject } from "rxjs";
import { OPTION_PARENT_COMPONENT } from "@app/shared/components/select/option.component";
import { ValueAccessorBase } from '@app/shared/layout/forms/lib/base-value-accessor';

@Component({
  selector   : 'st-marketplace-select',
  templateUrl: './marketplace-select.component.html',
  styleUrls  : ['./marketplace-select.component.scss'],
  providers      : [
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MarketplaceSelectComponent),
      multi      : true,
    },
    { provide: OPTION_PARENT_COMPONENT, useExisting: MarketplaceSelectComponent },
  ],
})
export class MarketplaceSelectComponent extends ValueAccessorBase<any> implements OnInit {
  @Input() forceErrorsDisplay: boolean = false;
  @Input() forceErrorsDisplay$: BehaviorSubject<boolean> = new BehaviorSubject(false);

  @Input()
  marketplaces = null;

  @Input()
  disabled: boolean = false;

  ngOnInit(): void {
    if(this.marketplaces == null)
      this.marketplaces = [
        {
          id: 1,
          name : "Amazon US",
          code : "US"
        },
        {
          id: 4,
          name : "Amazon UK",
          code : "UK"
        },
      ];
  }
}
