import { amazonSnapshotReducers } from '@app/shared/data/admin/amazon-snapshot/amazon-snapshot.reducers';
import { Action, combineReducers, Reducer } from 'redux';
import { defaultFormReducer } from '@angular-redux/form';
import { routerReducer } from '@angular-redux/router';
import { userReducer } from '@app/shared/data/user/user.reducers';
import { AppState } from '@app/shared/data/app-state.model';
import { notificationsReducer } from '@app/shared/data/notification/notifications.reducers';
import { layoutReducer } from '@app/shared/layout/layout.reducer';
import { noticeReducer } from '@app/shared/data/notice/notice.reducers';
import { affiliateReducer } from '@app/shared/data/affiliate/affiliate.reducers';
import { commissionReducer } from '@app/shared/data/commission/commission.reducers';
import { couponReducer } from '@app/shared/data/coupon/coupon.reducers';
import { unreadNotificationsReducer } from '@app/shared/data/notification/notifications-unread.reducers';
import { subscriptionReducer } from '@app/shared/data/subscription/subscription.reducers';
import { invoiceReducer } from '@app/shared/data/invoice/invoice.reducers';
import { planReducer } from '@app/shared/data/plan/plan.reducers';
import { keywordGeneratedReducer } from '@app/shared/data/keyword-generated/keyword-generated.reducers';
import { promotionReducer } from '@app/shared/data/promotion/promotion.reducers';
import { invitationReducer } from '@app/shared/data/invitation/invitation.reducers';
import { taskReducers } from '@app/shared/data/tasks/task.reducers';
import { subAccountReducer } from '@app/shared/data/sub-account/sub-account.reducers';
import { decisionReducer } from '@app/shared/data/decision/decision.reducers';
import { userAdminReducer } from '@app/shared/data/admin/user/user-admin.reducers';
import { systemNotificationReducer } from '@app/shared/data/admin/system-notification/system-notification.reducers';
import { affiliateYourCommisonsReducer } from '@app/shared/data/affiliate/your-commision/your-commisions.reducers';
import { affiliateSummaryCommisonsReducer } from '@app/shared/data/affiliate/summary-commision/summary-commisions.reducers';
import { adminCouponReducer } from '@app/shared/data/admin/coupon/coupon.reducers';
import { systemStatsReducer } from '@app/shared/data/admin/system-stats/system-stats.reducers';
import { affiliatePayoutReducer } from '@app/shared/data/admin/payout-affiliate/payout-admin.reducers';
import { payoutAllCommisonsReducer } from '@app/shared/data/admin/payout-all-commisions/payout-commisions.reducers';
import { payoutByPlanReducer } from '@app/shared/data/admin/payout-by-plan/payout-by-plan.reducers';
import { payoutCommisonsReducer } from '@app/shared/data/admin/payout-commision/payout-commision.reducers';
import { adminPlanReducer } from '@app/shared/data/admin/plan/plan.reducers';
import { lastLaunchReducer } from '@app/shared/data/admin/last-launch/last-launch.reducers';
import { reverseAsinReducer } from '@app/shared/data/reverse-asin/reverse-asin.reducers';
import { usagesReducer } from '@app/shared/data/usage/usage.reducers';
import { productToolsReducers } from '@app/shared/data/admin/product-tools/product-tools.reducers';
import { lsTaskReducers } from '@app/shared/data/tasks/ls-task/ls-task.reducers';
import { lastLaunchPromotionReducer } from '@app/shared/data/last-launch-promotion/last-launch-promotion.reducers';
import { lastLaunchScheduleReducer } from '@app/shared/data/last-launch-schedule/last-launch-schedule.reducers';
import { adminSurveyReducer } from '@app/shared/data/admin/survey/survey.reducers';
import { surveyReducer } from '@app/shared/data/survey/survey.reducer';
import { r2aTaskReducers } from '@app/shared/data/tasks/r2a-task/r2a-task.reducers';
import { versionReducer } from '@app/shared/data/admin/version/version.reducers';
import { sessionLogsReducer } from '@app/shared/data/session-logs/session-logs.reducers';
import { prizmReducers } from '@app/shared/data/prizm/prizm.reducers';
import { apiTokenReducer } from '@app/shared/data/api-token/api-token.reducers';
import { apiPromotionReducer } from '@app/shared/data/api-promotion/api-promotion.reducers';
import { systemSettingsReducer } from '@app/shared/data/admin/system-settings/system-settings.reducers';
import { alertReducer } from '@app/shared/data/alert/alert.reducers';
import { walletTransactionsReducer } from '@app/shared/data/wallet-transactions/wallet-transactions.reducers';
import { walletTopUpSourcesReducer } from '@app/shared/data/wallet-transactions/wallet-top-up-sources.reducers';
import { userAlertReducer } from '@app/shared/data/alert/user/user-alert.reducers';
import { walletReducer } from '@app/shared/data/wallet/wallet.reducers';
import { exchangeRatesReducer } from '@app/shared/data/exchange-rates/exchange-rates.reducers';
import { walletBlacklistReducer } from './wallet-blacklist/blacklist.reducers';
import { asinCompareReducer } from '@app/shared/data/asin-compare/asin-compare.reducers';
import { reviewRequestReducer } from './review-request/review-request.reducer';
import { customerListReducer } from './customer-list/customer-list.reducer';
import { productManagerReducer } from './product-manager/product-manager.reducer';
import { campaignManagerReducer } from './campaign-manager/campaign-manager.reducer';
import { browseProductsReducer } from '@app/shared/data/browse-products/browse-products.reducers';
import { r2aReducerV2 } from './reverse-asin-v2/reverse-asin.reducers';

export const composeReducers = <State>(...reducers: Reducer<State>[]): Reducer<State> => (s: State, action: Action) =>
  reducers.reduce((st, reducer) => {
    return reducer(st, action);
  }, s);


// Define the global store shape by combining our application's
// reducers together into a given structure.
export const mainReducer = composeReducers(
  defaultFormReducer(),
  combineReducers<AppState>({
    user                      : userReducer,
    layout                    : layoutReducer,
    tokens                    : apiTokenReducer,
    api_promotions            : apiPromotionReducer,
    notifications             : notificationsReducer,
    unread_notifications      : unreadNotificationsReducer,
    notices                   : noticeReducer,
    router                    : routerReducer,
    affiliate                 : affiliateReducer,
    affiliate_your_commision  : affiliateYourCommisonsReducer,
    affiliate_summary_commison: affiliateSummaryCommisonsReducer,
    commission                : commissionReducer,
    coupon                    : couponReducer,
    subscriptions             : subscriptionReducer,
    invoices                  : invoiceReducer,
    plans                     : planReducer,
    usages                    : usagesReducer,
    keyword_generator         : keywordGeneratedReducer,
    reverse_asin              : reverseAsinReducer,
    r2a_v2                    : r2aReducerV2,
    promotions                : promotionReducer,
    session_logs              : sessionLogsReducer,
    wallet                    : walletReducer,
    wallet_transactions       : walletTransactionsReducer,
    wallet_top_up_sources     : walletTopUpSourcesReducer,
    wallet_blacklist          : walletBlacklistReducer,
    exchange_rates            : exchangeRatesReducer,
    invitation                : invitationReducer,
    sub_accounts              : subAccountReducer,
    survey                    : surveyReducer,
    tasks                     : taskReducers([
      lsTaskReducers,
      r2aTaskReducers,
    ]),
    decisions                 : decisionReducer,
    last_launch_promotions    : lastLaunchPromotionReducer,
    last_launch_schedule      : lastLaunchScheduleReducer,
    alerts                    : alertReducer,
    user_alerts               : userAlertReducer,
    prizm                     : prizmReducers,
    asin_compare              : asinCompareReducer,
    admin                     : composeReducers(
      combineReducers<AppState>({
        system_stats          : systemStatsReducer,
        user                  : userAdminReducer,
        plans                 : adminPlanReducer,
        system_notifications  : systemNotificationReducer,
        coupons               : adminCouponReducer,
        affiliate_payouts     : affiliatePayoutReducer,
        payouts_commisions    : payoutCommisonsReducer,
        payouts_by_plan       : payoutByPlanReducer,
        payouts_all_commisions: payoutAllCommisonsReducer,
        last_launch           : lastLaunchReducer,
        product_tools         : productToolsReducers,
        surveys               : adminSurveyReducer,
        versions              : versionReducer,
        system_settings       : systemSettingsReducer,
        amazon_snapshot       : amazonSnapshotReducers,
      })
    ),
    review_request: reviewRequestReducer,
    customer_list: customerListReducer,
    campaign_manager: campaignManagerReducer,
    product_manager: productManagerReducer,
    browse_products: browseProductsReducer,
  }));
