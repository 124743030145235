import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Commission } from "@app/shared/data/commission/commission.models";

@Injectable()
export class CommissionActions extends PaginationActions {
  protected model = Commission;

  static readonly TYPES = {};
}
