import { Injectable } from '@angular/core';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import {
  BlacklistEntry,
  BlacklistInvitationCollection,
  BlacklistInvitation,
  BlacklistSettings
} from '@app/shared/data/wallet-blacklist/blacklist.models';
import { StAction } from '@app/shared/data/st-action';

@Injectable()
export class BlacklistActions extends PaginationActions {
  static readonly TYPES = {
    FILTER: '[WALLET_BLACKLIST]FILTER',

    LOAD_SETTINGS: '[WALLET_BLACKLIST]LOAD_SETTINGS',
    LOAD_SETTINGS_SUCCEEDED: '[WALLET_BLACKLIST]LOAD_SETTINGS_SUCCEEDED',
    LOAD_SETTINGS_FAILED: '[WALLET_BLACKLIST]LOAD_SETTINGS_FAILED',

    SAVE_SETTINGS: '[WALLET_BLACKLIST]SAVE_SETTINGS',
    SAVE_SETTINGS_SUCCEEDED: '[WALLET_BLACKLIST]SAVE_SETTINGS_SUCCEEDED',
    SAVE_SETTINGS_FAILED: '[WALLET_BLACKLIST]SAVE_SETTINGS_FAILED',

    ADD_ENTRY: '[WALLET_BLACKLIST]ADD_ENTRY',
    ADD_ENTRY_SUCCEEDED: '[WALLET_BLACKLIST]ADD_ENTRY_SUCCEEDED',
    ADD_ENTRY_FAILED: '[WALLET_BLACKLIST]ADD_ENTRY_FAILED',

    ADD_ENTRIES: '[WALLET_BLACKLIST]ADD_ENTRIES',
    ADD_ENTRIES_SUCCEEDED: '[WALLET_BLACKLIST]ADD_ENTRIES_SUCCEEDED',
    ADD_ENTRIES_FAILED: '[WALLET_BLACKLIST]ADD_ENTRIES_FAILED',

    REPLACE_ENTRY: '[WALLET_BLACKLIST]REPLACE_ENTRY',
    REPLACE_ENTRY_SUCCEEDED: '[WALLET_BLACKLIST]REPLACE_ENTRY_SUCCEEDED',
    REPLACE_ENTRY_FAILED: '[WALLET_BLACKLIST]REPLACE_ENTRY_FAILED',

    REMOVE_ENTRY: '[WALLET_BLACKLIST]REMOVE_ENTRY',
    REMOVE_ENTRY_SUCCEEDED: '[WALLET_BLACKLIST]REMOVE_ENTRY_SUCCEEDED',
    REMOVE_ENTRY_FAILED: '[WALLET_BLACKLIST]REMOVE_ENTRY_FAILED',

    LOAD_INVITATIONS: '[WALLET_BLACKLIST]LOAD_INVITATIONS',
    LOAD_INVITATIONS_SUCCEEDED: '[WALLET_BLACKLIST]LOAD_INVITATIONS_SUCCEEDED',
    LOAD_INVITATIONS_FAILED: '[WALLET_BLACKLIST]LOAD_INVITATIONS_FAILED',

    SHARE: '[WALLET_BLACKLIST]SHARE',
    SHARE_SUCCEEDED: '[WALLET_BLACKLIST]SHARE_SUCCEEDED',
    SHARE_FAILED: '[WALLET_BLACKLIST]SHARE_FAILED',

    ACCPEPT_INVITATION: '[WALLET_BLACKLIST]ACCPEPT_INVITATION',
    ACCPEPT_INVITATION_SUCCEEDED: '[WALLET_BLACKLIST]ACCPEPT_INVITATION_SUCCEEDED',
    ACCPEPT_INVITATION_FAILED: '[WALLET_BLACKLIST]ACCPEPT_INVITATION_FAILED',

    REMOVE_INVITATION: '[WALLET_BLACKLIST]REMOVE_INVITATION',
    REMOVE_INVITATION_SUCCEEDED: '[WALLET_BLACKLIST]REMOVE_INVITATION_SUCCEEDED',
    REMOVE_INVITATION_FAILED: '[WALLET_BLACKLIST]REMOVE_INVITATION_FAILED',
  };

  protected model = BlacklistEntry;

  filter(filters: any): StAction {
    return {
      type: BlacklistActions.TYPES.FILTER,
      data: {
        ...filters,
      }
    };
  }

  addEntry(field: string, value: string, refreshState: boolean = true): StAction {
    return {
      type: BlacklistActions.TYPES.ADD_ENTRY,
      data: {
        field,
        value,
        refreshState,
      }
    };
  }

  addEntrySucceeded(entry: BlacklistEntry, refreshState: boolean = true): StAction {
    return {
      type: BlacklistActions.TYPES.ADD_ENTRY_SUCCEEDED,
      data: {
        entry,
        refreshState,
      }
    };
  }

  addEntryFailed(error: any): StAction {
    return {
      type: BlacklistActions.TYPES.ADD_ENTRY_FAILED,
      data: {
        error,
      }
    };
  }

  addEntries(entries: { field: string, value: string }[], note: string = ''): StAction {
    return {
      type: BlacklistActions.TYPES.ADD_ENTRIES,
      data: {
        entries,
        note
      }
    };
  }

  addEntriesSucceeded(): StAction {
    return {
      type: BlacklistActions.TYPES.ADD_ENTRIES_SUCCEEDED,
      data: {
      }
    };
  }

  addEntriesFailed(error: any): StAction {
    return {
      type: BlacklistActions.TYPES.ADD_ENTRIES_FAILED,
      data: {
        error,
      }
    };
  }

  replaceEntry(oldEntry: BlacklistEntry, value: string, note: string): StAction {
    return {
      type: BlacklistActions.TYPES.REPLACE_ENTRY,
      data: {
        oldEntry,
        value,
        note,
      }
    };
  }

  replaceEntrySucceeded(oldEntry: BlacklistEntry): StAction {
    return {
      type: BlacklistActions.TYPES.REPLACE_ENTRY_SUCCEEDED,
      data: {
        oldEntry,
      }
    };
  }

  replaceEntryFailed(oldEntry: BlacklistEntry, error: any): StAction {
    return {
      type: BlacklistActions.TYPES.REPLACE_ENTRY_FAILED,
      data: {
        oldEntry,
        error,
      }
    };
  }

  removeEntry(entry: BlacklistEntry): StAction {
    return {
      type: BlacklistActions.TYPES.REMOVE_ENTRY,
      data: {
        entry,
      }
    };
  }

  removeEntrySucceeded(entry: BlacklistEntry): StAction {
    return {
      type: BlacklistActions.TYPES.REMOVE_ENTRY_SUCCEEDED,
      data: {
        entry,
      }
    };
  }

  removeEntryFailed(entry: BlacklistEntry, error: any): StAction {
    return {
      type: BlacklistActions.TYPES.REMOVE_ENTRY_FAILED,
      data: {
        entry,
        error,
      }
    };
  }

  loadInvitations(): StAction {
    return {
      type: BlacklistActions.TYPES.LOAD_INVITATIONS,
      data: {}
    };
  }

  loadInvitationsSucceeded(invitations: BlacklistInvitationCollection): StAction {
    return {
      type: BlacklistActions.TYPES.LOAD_INVITATIONS_SUCCEEDED,
      data: {
        invitations,
      }
    };
  }

  loadInvitationsFailed(error: any): StAction {
    return {
      type: BlacklistActions.TYPES.LOAD_INVITATIONS_FAILED,
      data: {
        error,
      }
    };
  }

  share(email: string): StAction {
    return {
      type: BlacklistActions.TYPES.SHARE,
      data: {
        email,
      }
    };
  }

  shareSucceeded(invitation: BlacklistInvitation): StAction {
    return {
      type: BlacklistActions.TYPES.SHARE_SUCCEEDED,
      data: {
        invitation,
      }
    };
  }

  shareFailed(error: any): StAction {
    return {
      type: BlacklistActions.TYPES.SHARE_FAILED,
      data: {
        error,
      }
    };
  }

  acceptInvitation(id: number): StAction {
    return {
      type: BlacklistActions.TYPES.ACCPEPT_INVITATION,
      data: {
        id,
      }
    };
  }

  acceptInvitationSucceeded(invitation: BlacklistInvitation): StAction {
    return {
      type: BlacklistActions.TYPES.ACCPEPT_INVITATION_SUCCEEDED,
      data: {
        invitation,
      }
    };
  }

  acceptInvitationFailed(id: number, error: any): StAction {
    return {
      type: BlacklistActions.TYPES.ACCPEPT_INVITATION_FAILED,
      data: {
        id,
        error,
      }
    };
  }

  removeInvitation(id: number): StAction {
    return {
      type: BlacklistActions.TYPES.REMOVE_INVITATION,
      data: {
        id,
      }
    };
  }

  removeInvitationSucceeded(id: number): StAction {
    return {
      type: BlacklistActions.TYPES.REMOVE_INVITATION_SUCCEEDED,
      data: {
        id,
      }
    };
  }

  removeInvitationFailed(id: number, error: any): StAction {
    return {
      type: BlacklistActions.TYPES.REMOVE_INVITATION_FAILED,
      data: {
        id,
        error,
      }
    };
  }

  loadSettings(): StAction {
    return {
      type: BlacklistActions.TYPES.LOAD_SETTINGS,
      data: {
      }
    };
  }

  loadSettingsSucceeded(settings: BlacklistSettings): StAction {
    return {
      type: BlacklistActions.TYPES.LOAD_SETTINGS_SUCCEEDED,
      data: {
        settings,
      }
    };
  }

  loadSettingsFailed(error: any): StAction {
    return {
      type: BlacklistActions.TYPES.LOAD_SETTINGS_FAILED,
      data: {
        error,
      }
    };
  }

  saveSettings(settings: BlacklistSettings): StAction {
    return {
      type: BlacklistActions.TYPES.SAVE_SETTINGS,
      data: {
        settings,
      }
    };
  }

  saveSettingsSucceeded(settings: BlacklistSettings): StAction {
    return {
      type: BlacklistActions.TYPES.SAVE_SETTINGS_SUCCEEDED,
      data: {
        settings,
      }
    };
  }

  saveSettingsFailed(error: any): StAction {
    return {
      type: BlacklistActions.TYPES.SAVE_SETTINGS_FAILED,
      data: {
        error,
      }
    };
  }
}
