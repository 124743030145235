
import {mergeMap, map, filter} from 'rxjs/operators';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { User } from '@app/shared/data/user/user.models';
import { Subscription } from 'rxjs';
import { isObject, hasProp } from '@app/helpers';

@Component({
  selector   : 'st-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls  : ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
  @Input()
  set value(value) {
    if (value.length > 0) {
      this.breadcrumb = this.transformData(value);
    }
  }

  @Input()
  className: string = null;

  public activeAsin = '';
  public breadcrumb: string[] = null;
  public activeUserName = '';

  private productSubscription: Subscription;
  private userSubscription: Subscription;
  private valueSubscription: Subscription;

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private ngRedux: NgRedux<AppState>) { }

  hasProp(o, name) {
    return o === undefined ? false : o.hasOwnProperty(name);
  }

  /**
   * Since data can be be object or array we need to transformer them in one data structure for nicer templating.
   * Data can be object because we want to add hyperlinks to breadcrumbs. To make this working properly we need
   * change all :asin parameters into actual asin.
   * Example: [/product,:asin,'keyword-manager'] => [/product, KJDS456, 'keyword-manager']
   */
  transformData(data) {
    data = data.map((x: any) => {
      if (isObject(x) && hasProp(x, 'hyperLinkValue') && Array.isArray(x.hyperLinkValue)) {
        x.hyperLinkValue = x.hyperLinkValue.map((val: any) => val === ':asin' ? this.activeAsin : val);
      }

      return typeof x === 'string' ? {value: x} : x;
    });
    return data;
  }

  ngOnInit(): void {
    let route_ = null;
    this.valueSubscription = this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        route_ = route;
        while (route_.firstChild) {
          route_ = route_.firstChild;
        }
        return route_;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data),)
      .subscribe((data: { breadcrumbs: any }) => {
        if (data.breadcrumbs === undefined) {
          this.breadcrumb = null;
        } else if (Array.isArray(data.breadcrumbs)) {
            this.breadcrumb = this.transformData(data.breadcrumbs);
        }
      });


    this.productSubscription = this.ngRedux.select(['active_product', 'data', 'asin']).subscribe((asin: string) => {
      this.activeAsin = asin;
    });

    this.userSubscription = this.ngRedux.select(['user', 'data']).subscribe((user: User) => {
      this.activeUserName = user.getFullName();
    });
  }

  ngOnDestroy(): void {
    if (this.productSubscription) {
      this.productSubscription.unsubscribe();
    }

    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }

    if (this.valueSubscription) {
      this.valueSubscription.unsubscribe();
    }
  }

}
