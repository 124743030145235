import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IProductManagerVariation } from "@app/shared/data/product-manager/product-manager.models";
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';

@Injectable()
export class ProductManagerService {
  activeStep = 1;
  doneSteps: number[] = [];
  activeStepSubject$: BehaviorSubject<number> = new BehaviorSubject(1);
  doneStepsSubject$: BehaviorSubject<number[]> = new BehaviorSubject([]);
  nextButtonEnabled: boolean;
  nextButtonEnabledSubject$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  selectedCompetitorProducts: IProductManagerVariation[];
  selectedCompetitorProductsSubject$: BehaviorSubject<IProductManagerVariation[]> = new BehaviorSubject([]);
  perPage = 20;
  perPageOptions: IDropdownOption[] = [
    {
      id: 5,
      label: '5'
    },
    {
      id: 10,
      label: '10'
    },
    {
      id: 20,
      label: '20'
    },
    {
      id: 50,
      label: '50'
    },
  ];
  preSelectedOption: IDropdownOption = {
    id: this.perPage,
    label: this.perPage.toString()
  };

  constructor() { }

  getActiveStep(): Observable<number> {
    return this.activeStepSubject$.asObservable();
  }

  getDoneSteps(): Observable<number[]> {
    return this.doneStepsSubject$.asObservable();
  }

  getNextButtonEnabled(): Observable<boolean> {
    return this.nextButtonEnabledSubject$.asObservable();
  }

  getSelectedCompetitorProducts(): Observable<IProductManagerVariation[]> {
    return this.selectedCompetitorProductsSubject$.asObservable();
  }

  nextStep() {
    this.doneSteps.push(this.activeStep);
    this.doneStepsSubject$.next([...this.doneSteps, this.activeStep]);
    this.activeStep += 1;
    this.activeStepSubject$.next(this.activeStep);
  }

  setActiveSteps(steps: number[]) {
    steps.forEach((step: number) => {
      this.doneSteps.push(step);
    });
    
    this.doneStepsSubject$.next(steps);
  }

  setCompetitorsProducts(products: IProductManagerVariation[]) {
    this.selectedCompetitorProductsSubject$.next(products);
  }

  setNextButtonEnabled(value: boolean) {
    this.nextButtonEnabled = value;
    this.nextButtonEnabledSubject$.next(this.nextButtonEnabled);
  }

  setActiveStep(id: number) {
    this.activeStep = id;
    this.activeStepSubject$.next(this.activeStep);
  }

  reset() {
    this.activeStep = 1;
    this.doneSteps = [];
    this.activeStepSubject$.next(this.activeStep);
    this.doneStepsSubject$.next(this.doneSteps);
    this.setCompetitorsProducts([]);
  }
}
