import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import {
  LastLaunchAdminProduct,
  LastLaunchAdminPromotion, LastLaunchAdminScheduledEmail,
  LastLaunchAdminSlot
} from "@app/shared/data/admin/last-launch/last-launch.models";
import { Collection } from "@app/shared/data/base.models";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";

@Injectable()
export class LastLaunchActions extends PaginationActions {
  protected model = LastLaunchAdminPromotion;

  static readonly TYPES = {
    FILTER: '[LAST_LAUNCH_ADMIN]FILTER',

    SAVE          : '[LAST_LAUNCH_ADMIN]SAVE',
    SAVE_SUCCEEDED: '[LAST_LAUNCH_ADMIN]SAVE_SUCCEEDED',
    SAVE_FAILED   : '[LAST_LAUNCH_ADMIN]SAVE_FAILED',

    REMOVE          : '[LAST_LAUNCH_ADMIN]REMOVE',
    REMOVE_SUCCEEDED: '[LAST_LAUNCH_ADMIN]REMOVE_SUCCEEDED',
    REMOVE_FAILED   : '[LAST_LAUNCH_ADMIN]REMOVE_FAILED',

    SET_FULL_PROMOTION          : '[LAST_LAUNCH_ADMIN]SET_FULL_PROMOTION',
    SET_FULL_PROMOTION_SUCCEEDED: '[LAST_LAUNCH_ADMIN]SET_FULL_PROMOTION_SUCCEEDED',
    SET_FULL_PROMOTION_FAILED   : '[LAST_LAUNCH_ADMIN]SET_FULL_PROMOTION_FAILED',

    LOAD_CALENDAR          : '[LAST_LAUNCH_ADMIN]LOAD_CALENDAR',
    LOAD_CALENDAR_SUCCEEDED: '[LAST_LAUNCH_ADMIN]LOAD_CALENDAR_SUCCEEDED',
    LOAD_CALENDAR_FAILED   : '[LAST_LAUNCH_ADMIN]LOAD_CALENDAR_FAILED',

    LOAD_SCHEDULED_EMAILS          : '[LAST_LAUNCH_ADMIN]LOAD_SCHEDULED_EMAILS',
    LOAD_SCHEDULED_EMAILS_SUCCEEDED: '[LAST_LAUNCH_ADMIN]LOAD_SCHEDULED_EMAILS_SUCCEEDED',
    LOAD_SCHEDULED_EMAILS_FAILED   : '[LAST_LAUNCH_ADMIN]LOAD_SCHEDULED_EMAILS_FAILED',

    LOAD_SCHEDULED_EMAIL          : '[LAST_LAUNCH_ADMIN]LOAD_SCHEDULED_EMAIL',
    LOAD_SCHEDULED_EMAIL_SUCCEEDED: '[LAST_LAUNCH_ADMIN]LOAD_SCHEDULED_EMAIL_SUCCEEDED',
    LOAD_SCHEDULED_EMAIL_FAILED   : '[LAST_LAUNCH_ADMIN]LOAD_SCHEDULED_EMAIL_FAILED',

    SAVE_SCHEDULED_EMAIL          : '[LAST_LAUNCH_ADMIN]SAVE_SCHEDULED_EMAIL',
    SAVE_SCHEDULED_EMAIL_SUCCEEDED: '[LAST_LAUNCH_ADMIN]SAVE_SCHEDULED_EMAIL_SUCCEEDED',
    SAVE_SCHEDULED_EMAIL_FAILED   : '[LAST_LAUNCH_ADMIN]SAVE_SCHEDULED_EMAIL_FAILED',

    SAVE_CALENDAR_SLOT          : '[LAST_LAUNCH_ADMIN]SAVE_CALENDAR_SLOT',
    SAVE_CALENDAR_SLOT_SUCCEEDED: '[LAST_LAUNCH_ADMIN]SAVE_CALENDAR_SLOT_SUCCEEDED',
    SAVE_CALENDAR_SLOT_FAILED   : '[LAST_LAUNCH_ADMIN]SAVE_CALENDAR_SLOT_FAILED',

    LOAD_USERS          : '[LAST_LAUNCH_ADMIN]LOAD_USERS',
    LOAD_USERS_SUCCEEDED: '[LAST_LAUNCH_ADMIN]LOAD_USERS_SUCCEEDED',
    LOAD_USERS_FAILED   : '[LAST_LAUNCH_ADMIN]LOAD_USERS_FAILED',

    SEARCH_PRODUCT          : '[LAST_LAUNCH_ADMIN]SEARCH_PRODUCT',
    SEARCH_PRODUCT_SUCCEEDED: '[LAST_LAUNCH_ADMIN]SEARCH_PRODUCT_SUCCEEDED',
    SEARCH_PRODUCT_FAILED   : '[LAST_LAUNCH_ADMIN]SEARCH_PRODUCT_FAILED',

    ADD_NOTE          : '[LAST_LAUNCH_ADMIN]ADD_NOTE',
    ADD_NOTE_SUCCEEDED: '[LAST_LAUNCH_ADMIN]ADD_NOTE_SUCCEEDED',
    ADD_NOTE_FAILED   : '[LAST_LAUNCH_ADMIN]ADD_NOTE_FAILED',
  };

  filter(filters): StAction {
    return {
      type: LastLaunchActions.TYPES.FILTER,
      data: filters,
    }
  }

  save(data): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE,
      data: data
    }
  }

  saveSucceeded(promotion: LastLaunchAdminPromotion): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_SUCCEEDED,
      data: {
        promotion: promotion,
      }
    }
  }

  saveFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_FAILED,
      data: {
        error: error,
      }
    }
  }

  remove(promotion: LastLaunchAdminPromotion): StAction {
    return {
      type: LastLaunchActions.TYPES.REMOVE,
      data: {
        promotion: promotion,
      }
    }
  }

  removeSucceeded(promotion: LastLaunchAdminPromotion): StAction {
    return {
      type: LastLaunchActions.TYPES.REMOVE_SUCCEEDED,
      data: {
        promotion: promotion,
      }
    }
  }

  removeFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.REMOVE_FAILED,
      data: {
        error: error,
      }
    }
  }

  setFullPromotion(id): StAction {
    return {
      type: LastLaunchActions.TYPES.SET_FULL_PROMOTION,
      data: {
        id: id,
      }
    }
  }

  setFullPromotionSucceeded(promotion: LastLaunchAdminPromotion): StAction {
    return {
      type: LastLaunchActions.TYPES.SET_FULL_PROMOTION_SUCCEEDED,
      data: {
        promotion: promotion,
      }
    }
  }

  setFullPromotionFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.SET_FULL_PROMOTION_FAILED,
      data: {
        error: error,
      }
    }
  }

  loadCalendar(from: string, to: string): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_CALENDAR,
      data: {
        from: from,
        to  : to,
      }
    }
  }

  loadCalendarSucceeded(slots: Collection<LastLaunchAdminSlot>): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_CALENDAR_SUCCEEDED,
      data: {
        slots: slots,
      }
    }
  }

  loadCalendarFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_CALENDAR_FAILED,
      data: {
        error: error,
      }
    }
  }

  loadScheduledEmails(from: string = null, to: string = null): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS,
      data: {
        type: 'upcoming',
        from: from,
        to  : to,
      }
    }
  }

  loadPastScheduledEmails(from: string = null, to: string = null): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS,
      data: {
        type: 'past',
        from: from,
        to  : to,
      }
    }
  }

  loadScheduledEmailsSucceeded(scheduledEmails: Collection<LastLaunchAdminScheduledEmail>): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS_SUCCEEDED,
      data: {
        scheduledEmails: scheduledEmails,
      }
    }
  }

  loadScheduledEmailsFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS_FAILED,
      data: {
        error: error,
      }
    }
  }

  loadScheduledEmail(id): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAIL,
      data: {
        id: id,
      }
    }
  }

  loadScheduledEmailSucceeded(scheduledEmail: LastLaunchAdminScheduledEmail): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAIL_SUCCEEDED,
      data: {
        scheduledEmail: scheduledEmail,
      }
    }
  }

  loadScheduledEmailFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAIL_FAILED,
      data: {
        error: error,
      }
    }
  }

  saveScheduledEmail(scheduledEmail: LastLaunchAdminScheduledEmail): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_SCHEDULED_EMAIL,
      data: {
        scheduledEmail: scheduledEmail,
      }
    }
  }

  saveScheduledEmailSucceeded(scheduledEmail: LastLaunchAdminScheduledEmail): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_SCHEDULED_EMAIL_SUCCEEDED,
      data: {
        scheduledEmail: scheduledEmail,
      }
    }
  }

  saveScheduledEmailFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_SCHEDULED_EMAIL_FAILED,
      data: {
        error: error,
      }
    }
  }

  saveCalendarSlot(date: string, available: number): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_CALENDAR_SLOT,
      data: {
        date     : date,
        available: available,
      }
    }
  }

  saveCalendarSlotSucceeded(slot: LastLaunchAdminSlot): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_CALENDAR_SLOT_SUCCEEDED,
      data: {
        slot: slot,
      }
    }
  }

  saveCalendarSlotFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.SAVE_CALENDAR_SLOT_FAILED,
      data: {
        error: error,
      }
    }
  }

  loadUsers(email: string): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_USERS,
      data: {
        email: email,
      }
    }
  }

  loadUsersSucceeded(users: Collection<UserAdmin>): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_USERS_SUCCEEDED,
      data: {
        users: users,
      }
    }
  }

  loadUsersFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.LOAD_USERS_FAILED,
      data: {
        error: error,
      }
    }
  }

  searchProduct(promotionId: string, term: string): StAction {
    return {
      type: LastLaunchActions.TYPES.SEARCH_PRODUCT,
      data: {
        promotionId: promotionId,
        term       : term,
      }
    }
  }

  searchProductSucceeded(product: LastLaunchAdminProduct): StAction {
    return {
      type: LastLaunchActions.TYPES.SEARCH_PRODUCT_SUCCEEDED,
      data: {
        product: product,
      }
    }
  }

  searchProductFailed(error: any): StAction {
    return {
      type: LastLaunchActions.TYPES.SEARCH_PRODUCT_FAILED,
      data: {
        error: error,
      }
    }
  }

  addNote(promotionId, note): StAction {
    return {
      type: LastLaunchActions.TYPES.ADD_NOTE,
      data: {
        promotionId: promotionId,
        note       : note,
      }
    }
  }

  addNoteSucceeded(promotionId, note): StAction {
    return {
      type: LastLaunchActions.TYPES.ADD_NOTE_SUCCEEDED,
      data: {
        promotionId: promotionId,
        note       : note,
      }
    }
  }

  addNoteFailed(error): StAction {
    return {
      type: LastLaunchActions.TYPES.ADD_NOTE_FAILED,
      data: {
        promotionId: error,
      }
    }
  }
}
