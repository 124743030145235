import { Injectable, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux, select } from '@angular-redux/store';
import { NoticeActions } from '@app/shared/data/notice/notice.actions';
import { AuthService } from '@app/core/services/auth.service';
import { Notice } from '@app/shared/data/notice/notice.models';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { Credential } from '@app/shared/data/credential/credential.models';
import { User } from '@app/shared/data/user/user.models';
import { Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Injectable()
export class NoticesService implements OnInit, OnDestroy {
  constructor(private ngRedux: NgRedux<AppState>,
              private router: Router,
              private authService: AuthService) {
  }

  private usersSubscription: Subscription = null;
  private credentialSubscription: Subscription = null;
  private sysNoticesSubscription: Subscription = null;
  private onDestroy$ = new Subject();

  @select(['user', 'data', 'is_verified'])
  isVerified$;

  @select(['user', 'data', 'is_risky'])
  isRisky$;

  ngOnInit(): void {
  }

  loadSystemNotices() {
    this.ngRedux.dispatch(NoticeActions.loadSystemNotices({active: true}));

    // listen for changes and update layout if notification is added!
    if (this.sysNoticesSubscription) {
      this.sysNoticesSubscription.unsubscribe();
    }
  }

  loadUserBanners() {
    this.setVerificationBanner();
    this.setInvalidCredentialsBanner();
    this.setPaymentFailedBanner();
    this.setAccountMarkedAsRiskyBanner();
  }

  setPaymentFailedBanner() {
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
    }

    this.usersSubscription = this.authService.getUser$().subscribe(
      (user: User) => {
        if (user.last_payment_failed_at) {
          const notice = new Notice({
            id: -2,
            type: 'warn',
            message: `Warning! Limited access. Your subscription will be halted. Please update your credit card.`,
            icon: 'priority_high',
            url: '/billing',
            actionText: 'Edit Payment Method'
          });

          //noinspection TypeScriptValidateTypes
          this.ngRedux.dispatch(NoticeActions.addUserNotice(notice));
        } else {
          const n: Notice = <Notice>{id: -2};
          //noinspection TypeScriptValidateTypes
          this.ngRedux.dispatch(NoticeActions.removeUserNotice(n));
        }
      }
    );
  }

  setInvalidCredentialsBanner() {
    if (this.credentialSubscription) {
      this.credentialSubscription.unsubscribe();
    }

    this.credentialSubscription = this.authService.activeCredentials$().subscribe(
      (activeCredentials: Credential) => {
        if (activeCredentials && activeCredentials.id) {
          if (activeCredentials.is_spa_connected) {
            const n: Notice = <Notice>{id: -1};
            //noinspection TypeScriptValidateTypes
            this.ngRedux.dispatch(NoticeActions.removeUserNotice(n));
          } else {
            const notice = new Notice({
              id: -1,
              type: 'warn',
              message: `Oops! Looks like your SellingPartner access was revoked. Please authorize access on the credentials page.`,
              icon: 'priority_high',
              onClick: () => this.router.navigate(['/', 'credentials', {outlets: {modal: [activeCredentials.id]}}]),
              actionText: 'Edit Credentials'
            });

            //noinspection TypeScriptValidateTypes
            this.ngRedux.dispatch(NoticeActions.addUserNotice(notice));
          }
        }
      }
    );
  }

  setVersionMismatchBanner() {
    const notice = new Notice({
      id: -3,
      type: 'warn',
      message: `A new version is available. For optimal performance, please reload the page.`,
      icon: 'priority_high',
      onClick: () => location.reload(),
      actionText: 'Get new version'
    });

    //noinspection TypeScriptValidateTypes
    this.ngRedux.dispatch(NoticeActions.addUserNotice(notice));
  }

  setVerificationBanner() {
    this.isVerified$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isVerified) => {
        const notice = new Notice({
          id: 0,
          type: 'warn',
          message: `Please verify your e-mail address!`,
          icon: 'priority_high',
          onClick: () => this.ngRedux.dispatch(UsersActions.resendVerificationEmail(this.authService.getUser())),
          actionText: 'Resend verification email'
        });

        if (isVerified) {
          this.ngRedux.dispatch(NoticeActions.removeUserNotice(notice));
        } else {
          this.ngRedux.dispatch(NoticeActions.addUserNotice(notice));
        }
      });
  }

  setAccountMarkedAsRiskyBanner() {
    this.isRisky$
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((isRisky) => {
        const notice = new Notice({
          id: -4,
          type: 'warn',
          message: `Warning! We've detected suspicious activity on your account. Access to Wallet and Gift Cards was blocked. Please contact our support at hello@seller.tools for more information.`,
          icon: 'priority_high',
        });

        if (isRisky) {
          this.ngRedux.dispatch(NoticeActions.addUserNotice(notice));
        } else {
          this.ngRedux.dispatch(NoticeActions.removeUserNotice(notice));
        }
      });
  }

  updateLayout() {
    const el = document.getElementById('notices');

    if (!el) {
      return;
    }
    if (this.ngRedux.getState()['notices']['height'] !== el.offsetHeight) {
      this.ngRedux.dispatch(NoticeActions.setNoticesHeight(el.offsetHeight));
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
