import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { Collection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { LastLaunchPromotionActions } from '@app/shared/data/last-launch-promotion/last-launch-promotion.actions';
import { LastLaunchPromotionAPI } from '@app/shared/data/last-launch-promotion/last-launch-promotion.api';
import { LastLaunchPromotion } from '@app/shared/data/last-launch-promotion/last-launch-promotion.models';
import { LastLaunchScheduleAPI } from '@app/shared/data/last-launch-schedule/last-launch-schedule.api';
import { LastLaunchSchedule } from '@app/shared/data/last-launch-schedule/last-launch-schedule.models';
import { LastLaunchScheduleActions } from '@app/shared/data/last-launch-schedule/last-launch-schedule.actions';

@Injectable()
export class LastLaunchPromotionEpics {
  constructor(private lastLaunchPromotionActions: LastLaunchPromotionActions,
              private store: NgRedux<AppState>,
              private lastLaunchScheduleAPI: LastLaunchScheduleAPI,
              private lastLaunchPromotionAPI: LastLaunchPromotionAPI) {
  }

  public createEpic() {
    return [
      this.loadPage,
      this.loadReport,
      this.cancel,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);

      const actions = [
        PaginationActions.getPagActionTypes(LastLaunchPromotion).LOAD_PAGE,
        LastLaunchPromotionActions.TYPES.FILTER,
        LastLaunchScheduleActions.TYPES.SAVE_PROMOTION_SUCCEEDED,
        LastLaunchScheduleActions.TYPES.SAVE_COUPONS_SUCCEEDED,
        LastLaunchScheduleActions.TYPES.SAVE_UNITS_SUCCEEDED,
        LastLaunchScheduleActions.TYPES.PAY_SUCCEEDED,
      ];

      if (actions.indexOf(action.type) >= 0) {
        const state = store.getState()['last_launch_promotions'];
        const filters = {
          status: '*',
          type: '*',
        };

        let params = {
          ...state['_be_pagination'],
          ...filters,
          sort: state['_be_pagination']['sort'].join(',')
        };

        if (state.hasOwnProperty('_filters')) {
          params = {
            ...params,
            status: state._filters.state.value === 'all' ? '*' : state._filters.state.value.toLowerCase(),
            type: state._filters.type.value === 'all' ? '*' : state._filters.type.value.toLowerCase()
          };
        }

        this.lastLaunchPromotionAPI.all(params)
          .subscribe(
            (promotions: Collection<LastLaunchPromotion>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchPromotionActions.setPage(promotions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchPromotionActions.loadPageFailed(response));
            },
            () => {
            }
          );
      }
      return n;
    };
  }

  loadReport = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchPromotionActions.TYPES.LOAD_PROMOTION_REPORT) {
        this.lastLaunchScheduleAPI.getPromotion(action.data.id)
          .subscribe(
            (schedule: LastLaunchSchedule) => {
              this.store.dispatch(this.lastLaunchPromotionActions.loadPromotionReportSucceeded(schedule));
            },
            (response) => {
              this.store.dispatch(this.lastLaunchPromotionActions.loadPromotionReportFailed(response));
            }
          );
      }
      return next(action);
    };
  }

  cancel = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === LastLaunchPromotionActions.TYPES.CANCEL) {
        this.lastLaunchPromotionAPI.cancel(action.data.promotion.id)
          .subscribe(
            () => {
              this.store.dispatch(this.lastLaunchPromotionActions.cancelSucceeded(action.data.promotion));
            },
            (response) => {
              this.store.dispatch(this.lastLaunchPromotionActions.cancelFailed(response));
            }
          );
      }
      return next(action);
    };
  }
}
