<st-modal #modal="modal" [showInstructions]="false" [scrollable]="false" [size]="'small'">
  <div modal-header>
    <h2>Copy key</h2>
  </div>
  <div modal-body>
    <div class="loading" *ngIf="loadingToken">
      <div class="loading__text">Loading key...</div>
      <st-line-loader></st-line-loader>
    </div>
    <div class="api-key" *ngIf="!loadingToken">{{apiToken.value}}</div>
    <p class="warning">This API key provides secure access to your account and Wallet funds. It belongs to you and should be shared only with trusted partners. By using this API key and sharing it, you assume all responsibility for action taken in your SellerTools account and other connected accounts.</p>
  </div>

  <div class="footer" modal-footer>
    <button [disabled]="loadingToken" class="button interface-component blue-bg" type="button" (click)="onCopy()">I acknowledge, copy API KEY</button>
    <button class="button interface-component red-bg" type="button" (click)="modal.close()">Cancel</button>
  </div>
</st-modal>
