import { Component, Input, OnInit, } from '@angular/core';
import { HighchartsService, Type } from '@app/shared/components/charts/highcharts.service';
import { BaseChartComponent } from "@app/shared/components/charts/base-chart.component";
import { RangeSelectorOptions } from "highcharts/highstock";

@Component({
  selector : 'st-stock-chart',
  template : `&nbsp;`,
  providers: [
    {
      provide : Type,
      useValue: 'StockChart',
    },
    HighchartsService
  ],
  styleUrls: ['./stock-chart.component.scss']
})
export class StockChartComponent extends BaseChartComponent implements OnInit {

  @Input()
  set rangeSelector(rangeSelector: RangeSelectorOptions) {
    this._highchartsService.options?.setRangeSelectorOptions(rangeSelector);
  }

  @Input() navigatorEnabled = true;

  constructor(protected _highchartsService: HighchartsService) {
    super();
  }

  ngOnInit() {
    this._highchartsService.options?.setRangeSelectorOptions({
      inputEditDateFormat: '%m-%d-%Y',
      inputDateParser: (s:string) => {
        const dt: number[] = s.split('-').map(x => parseInt(x));
        return Date.UTC(dt[2], dt[0]-1, dt[1]);
      },
      buttonTheme: {
        padding: 3
      },
      selected: 1 // set 3 month range by default
    });
    this._highchartsService.options?.setNavigatorOptions({
      yAxis: {
        reversed: true
      },
      enabled: this.navigatorEnabled
    });

    this._highchartsService.options?.setTurboThreshold(5000);
  }

  protected _getRelatedComponent() {
    return this;
  }
}
