
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { AffiliateSummaryCommision } from "@app/shared/data/affiliate/summary-commision/summary-commision.model";

@Injectable()
export class AffiliateSummaryCommisionAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<AffiliateSummaryCommision>> { 
    return this.http.get(this.constructFullUrl('api/partnership/commissions/summary'), { params: params }).pipe(
      map(data => {
        return AffiliateSummaryCommision.collect<AffiliateSummaryCommision>(data, AffiliateSummaryCommision.BACKEND_PAGINATION);
      }));
  }
}
