<st-modal #modal="modal" [showInstructions]="false" [showOnlyBody]="true" size="medium" height="auto">
  <div modal-body>
    <span class="close-icon" (click)="abort(modal)">
      <st-icon icon="cross-v2"></st-icon>
    </span>

    <ng-container *ngIf="surveyLoading">
      <div class="loading-circles"></div>
    </ng-container>

    <ng-container *ngIf="survey && !surveyLoading && step === 1">
      <p class="survey--description">{{survey.description}}</p>

      <st-survey-question
        *ngFor="let question of survey.questions; let i = index"
        [question]="question"
        [count]="i+1"
        (onCheckboxChange)="onCheckboxChange($event)"
        (onTextChange)="onTextChange($event)"
        (onDrodpownChange)="onDrodpownChange($event)"></st-survey-question>

        <div class="button-group" *ngIf="!surveyLoading">
          <st-button-v2 size="large" color="v2_red_empty" (clicked)="abort(modal)" i18n-label label="CANCEL"></st-button-v2>
          <st-button-v2 size="large" color="v2_red" [disabled]="!canContinue || surveySubmitting" [loading]="surveySubmitting" (click)="submitSurvey()" i18n-label label="CONTINUE"></st-button-v2>
        </div>
    </ng-container>

    <ng-container *ngIf="step === 2">
      <div class="section top">
        <div class="support">
          <span i18n>Thanks for sharing your feedback and feel free to reach us here anytime:</span>
          <a class="link" href="https://seller.tools/contact">https://seller.tools/contact</a>
        </div> 
      </div>
      <span class="separator"></span>
      <div class="section">
        <div class="agreement mb-4">
          <h2>
            <st-icon icon="alert-red" class="icon icon-m"></st-icon>
            <span i18n>Still want to cancel?</span>
          </h2>

          <p i18n>By completing this step your account will no longer be on a paid plan, <br> but you will still have the access to our Free plan!</p>
        </div>
        <div class="button-group button-group--center">
          <st-button-v2 size="large" color="v2_red_empty" (clicked)="abort(modal)" i18n-label label="QUIT"></st-button-v2>
          <st-button-v2 size="large" color="v2_red" (click)="cancelSubscription(modal)" i18n-label label="CANCEL SUBSCRIPTION"></st-button-v2>
        </div>
      </div>
      <span class="separator"></span>
      <div class="section bottom">
        <div class="delete-account" (click)="deleteAccount()" i18n>I want to delete my account</div>
      </div>
    </ng-container>

    <ng-container *ngIf="step === 3">
      <div class="section top">
        <div class="agreement">
          <h2 class="delete-account-header">
            <span i18n>Delete Account</span>
          </h2>
          <h3 i18n>We are sorry to see you go.</h3> 
          <p i18n>Deleting your account will remove all your information from our database. <br> This action is permanent. There will be no way to restore your account.</p>
        </div>
      </div>
      <span class="separator"></span>
      <div class="section bottom">
        <div class="agreement">
          <h2>
            <st-icon icon="alert-red" class="icon-m"></st-icon>
            <span i18n>Still want to delete your account?</span>
          </h2>

          <p i18n>Please contact us at <a class="link" href="mailto:hello@seller.tools">hello@seller.tools</a><br> and we will delete your account for you</p>
        </div>
      </div>
    </ng-container>

  </div>
</st-modal>
