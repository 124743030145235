<st-modal #modal="modal" size="small" [showOnlyBody]="true">
    <div modal-body class="content">
      <div class="modal-header">
          <h2 i18n>{{loadingText || 'Loading...'}}</h2>
      </div>
  
      <div class="modal-body">
        <div class="loader"><div class="logo-image"></div><div class="logo-loader"></div></div>
      </div>
    </div>
</st-modal>
  