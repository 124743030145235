import { ExportService } from '@app/shared/services/export.service';
import { IProductManagerKeyword } from '../data/product-manager/product-manager.models';

export class ProductManagerKeywordExportService extends ExportService {
  static transformKeywords(kws: IProductManagerKeyword[]) {
    const values = [];
    const keys = [
      'Keyword',
      'Organic Rank',
      'Search Volume',
      'Search Frequency Rank',
      'Purchase Rate',
      'Unit Sales',
      'Total No. of Competitors',
      'Top 3 Click Dominance',
      'Top 3 Sales Dominance',
      'Indexed',
      'Priority',
      'Density Score',
    ];

    for (const kw of kws) {
      values.push([
        kw.value || '',
        kw.rank || '',
        kw.impressions || '',
        kw.sfr_rank || '',
        kw.sales_rate || '',
        kw.sales || '',
        kw.number_of_competitors || '',
        kw.click_dominance || '',
        kw.search_dominance || '',
        kw.indexed || '',
        kw.priority || 'VL',
        kw.density_score || '',
      ]);
    }

    return [keys, ...values];
  }

  static exportToCsv(filename, keywords: IProductManagerKeyword[]) {
    ExportService.exportToCsv(filename, this.transformKeywords(keywords));
  }
}
