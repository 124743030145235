export class GTagService {
  public static hasGTAG(): boolean {
    return window.hasOwnProperty('gtag');
  }

  private static GTAG(): any {
    return window['gtag'];
  }

  private static dataLayer(): any | undefined {
    return window['dataLayer'];
  }

  static pushDataToGTM(event: string, data: any) {
    GTagService.hasGTAG() && GTagService.dataLayer().push({'event': event, ...data});
  }

  static trackConversion(token: string): void {
    GTagService.hasGTAG() && GTagService.GTAG()('event', 'conversion', {'send_to': token, transaction_id: ''});
  }
}
