import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/services/auth.service';
import { Notification } from '@app/shared/data/notification/notification.models';
import { Credential } from '@app/shared/data/credential/credential.models';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Injectable()
export class NotificationService {
  constructor(private router: Router,
              private authService: AuthService,
              private i18n: I18n) {}

  static defaultTransform(data) {
    return {
      'id': data['id'],
      'credential_id': data['credential_id'],
      'source': data['source'],
      '_source': data['source'] !== '__user' ? data['source'] : null,
      'created_at': data['created_at'],
      'read_at': data['read_at'],
      'title': 'Unknown notification',
      'message': data['name'],
      'message_sort': ''
    };
  }

  navigate(notification: Notification) {
    const credential = new Credential({ id: notification.credential_id });
    if (!this._inActiveCredentials(credential)) {
      this.authService.switchActiveCredential(credential).then(() => {
        // redirect after switching credential is fulfilled!
        const url = this.router.serializeUrl(this.router.parseUrl(notification.url));
        window.location.assign(url);
      });
      return;
    }

    return this.router.navigateByUrl(notification.url);
  }

  private _inActiveCredentials(credential: Credential) {
    return credential.id && this.authService.getUser().active_credential === credential.id;
  }

  transform(notificationObject: object) {
    if (!this['notify' + notificationObject['name']]) {
      return NotificationService.defaultTransform(notificationObject);
    }
    return this['notify' + notificationObject['name']](notificationObject);
  }

  notifyIndexRecheckFinishedNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Index recheck finished'),
      'message': this.i18n(
        'Index recheck for product "{{val}}" finished.\n Please check updated indexes in keyword manager',
        {val: data['product']['short_name']}
      ),
      'message_short': this.i18n('for') + ' "' + data['product']['short_name'] + '"',
      'url': '/products/' + data['product']['asin'] + '/keywords-manager'
    };
  }

  notifyManyChatImportContactsFinished(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Customers imported'),
      'message': this.i18n(
        'Your ManyChat import has finished. Total of {{total}} customers were added, {{with_phone}} with phone number, {{with_email}} with email address and {{with_phone_and_email}} with both.',
        data['stats']
      ),
      'url': '/customers-list'
    };
  }

  notifyRelatedProductsRefreshedNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Related products Refreshed'),
      'message': this.i18n(
        'Related products for product "{{val}}" refreshed.\n',
        {val: data['product']['short_name']}
      ),
      'message_short': this.i18n('for') + ' "' + data['product']['short_name'] + '"',
      'url': '/products/' + data['product']['asin'] + '/competitor-builder'
    };
  }

  notifyNewSuggestionsNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('New keyword suggestions available'),
      'message': this.i18n(
        'New keyword suggestions for "{{val}}" product were generated',
        {val: data['product']['short_name']}
      ),
      'message_short': this.i18n('for') + ' "' + data['product']['short_name'] + '"',
      'url': '/products/' + data['product']['asin']
        + '/keywords-manager/(modal:add-keywords/manual/'
        + data['action_id'] + ')?src=' + data['src']
    };
  }

  notifyProductResetFinishedNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Product reset finished'),
      'message': this.i18n(
        'Product "{{val}}" was successfully reset',
        {val: data['product']['short_name']}
      ),
      'message_short': this.i18n('for') + ' "' + data['product']['short_name'] + '"',
      'url': '/products'
    };
  }

  notifyProductsSyncedNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Products synced'),
      'message': this.i18n(
        'We have successfully synced {{val}} products',
        {val: data['num_synced']}
      ),
      'message_short': this.i18n(
        '{{val}} products synced',
        {val: data['num_synced']}
      ),
      'url': '/products'
    };
  }


  notifyVolumeCheckFinishedNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Volume check finished'),
      'message': this.i18n(
        'Volume check finished for product "{{val}}"',
        {val: data['product']['short_name']}
      ),
      'message_short': this.i18n(
        'Volume check finished for product "{{val}}"',
        {val: data['product']['short_name']}
      ),
      'url': '/products/' + data['product']['asin'] + '/keywords-manager'
    };
  }

  notifyEmailConfirmationRequest(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Email confirmation'),
      'message': this.i18n('Please confirm your email in 24 hours after registration or you will be unable to login after that time'),
      'message_short': this.i18n('Please confirm your email')
    };
  }

  notifyUserVerified(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Account verification'),
      'message': this.i18n('Account has been successfully verified'),
      'message_short': this.i18n('Account has been successfully verified')
    };
  }

  notifyCommissionPaidNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Payout completed'),
      'message': this.i18n('One payout from affiliate program is completed'),
      'message_short': this.i18n('Payout for affiliate program is completed'),
      'url': '/admin/affiliation'
    };
  }

  notifyHistoricalReportGeneratedNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n('Historical Export Finished'),
      'message': this.i18n(
        'Historical export for {{val}} has finished and was sent to your email.',
        {val: data['product']['short_name']}
      ),
      'message_short': this.i18n(
        'for "{{val}}" and was sent to your email.',
        {val: data['product']['short_name']}
      ),
    };
  }

  notifyAlertNotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': data['alertTitle'],
      'message': data['alertMessage'],
      'message_short': data['alertMessage'],
    };
  }

  notifyKeywordsCopiedFromR2ANotification(data) {
    return {
      ...NotificationService.defaultTransform(data),
      'title': this.i18n(
      'Keywords copied from R2A'
      ),
      'message': this.i18n(
        'We have successfully copied {{count}} keywords to product {{asin}}',
        {count: data['count'], asin: data['asin']}
      ),
      'message_short': this.i18n(
        '{{count}} keywords copied to {{asin}}',
        {count: data['count'], asin: data['asin']}
      ),
      'url': '/products/' + data['asin'] + '/keywords-manager'
    };
  }
}
