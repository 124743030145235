<div class="track-button top-menu-item"
  [class.track-button--disabled]="column.data?.disabled"
  (click)="toggleOpened()"
  [stTooltip]="column.data?.disabled && 'Select products in order to track keyword'"
  i18n-tooltip
  stSidemenuOffClick
  (clickedOutsideMenuItem)="opened = false">
  <div class="track-button__sign">+</div>
</div>
<div class="track-button-wrapper" *ngIf="opened">
  <div class="track-button__button" (click)="!column.data?.disabled && trackKeywords.emit({ trackAll: true })" i18n>Track all keywords</div>
  <div class="track-button__button" (click)="!column.data?.disabled && trackKeywords.emit({ trackAll: false })" i18n>Track keywords on this page</div>
</div>
