import { AxisTypeValue, XAxisOptions } from "highcharts";
import { ChartEvent, yAxisEvents } from "@app/shared/components/charts/models/chart.events";
import { AxisDirective } from '@app/shared/components/charts/directives/axis.directive';

export class AxisModel implements XAxisOptions {
  events: {};
  categories: string[];
  type: AxisTypeValue;
  reversed: boolean;
  visible: boolean;
  title: any;
  allowDecimals: boolean;
  opposite: boolean = false;
  max: number | null;
  min: number | null;
  crosshair: boolean;
  labels: any;

  constructor(options: any = {}) {
    Object.assign(this, options);
  }

  public listenForEvents(component: AxisDirective) {
    this.events = {}; // reset events!

    yAxisEvents.forEach((eventType) => {
      this.events[eventType] = (event: Event) => {
        component[eventType].emit(new ChartEvent(event, this));
      }
    });
  }
}
