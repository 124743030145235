import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Plan, PlanFee, PlanPermission } from '@app/shared/data/plan/plan.models';
import { Collection } from '@app/shared/data/base.models';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { environment } from '@env/environment';
import { GTagService } from '@app/core/services/gtag.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Subscription } from '@app/shared/data/subscription/subscription.models';
import { select } from '@angular-redux/store';

enum PlanTypeEnum {
  MONTHLY = 'monthly',
  ANNUAL = 'annual'
}

enum DirectionEnum {
  BACK = 'BACK',
  FORWARD = 'FORWARD',
}

interface Breakpoint {
  breakpoint: number;
  elements: number;
}

@Component({
  selector: 'st-billing-plan-change',
  templateUrl: './plan-change.component.html',
  styleUrls: ['./plan-change.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BillingPlanChangeComponent implements OnInit, OnDestroy, OnChanges {
  _destroy: Subject<void> = new Subject<void>();
  _coupon: Coupon = new Coupon();
  _activeSubscription = null;
  _nextSubscription = null;
  _selected = null;

  couponErrorMessage = '';

  errorMessage = false;
  selectedPlanType = PlanTypeEnum.MONTHLY;
  planTypeEnum = PlanTypeEnum;
  fullPricingUrl = environment.FULL_PRICING_URL;
  fullBillingUrl = environment.CANCELLATION_POLICY_URL;

  validatingCouponCode = false;
  planWidth: number;
  plansGap = 10;
  numberOfPlanElements: number;
  plansBreakpoints: Breakpoint[] = [
    {
      breakpoint: 2100,
      elements: 5,
    },
    {
      breakpoint: 1800,
      elements: 4
    },
    {
      breakpoint: 1024,
      elements: 3
    },
    {
      breakpoint: 768,
      elements: 2
    },
    {
      breakpoint: 0,
      elements: 1,
    }
  ];
  activeBreakpoint: Breakpoint;
  windowWidth: number;
  directions = DirectionEnum;
  currentlySelectedIndex: number;
  numberOfCarouselPages: number;
  currentCarouselPage: number;
  currentCarouseOffset = 0;
  currentCarouseOffsetIndex = 0;

  @ViewChild('wrapper', { static: true }) wrapper: ElementRef;
  @ViewChild('plansInnerWrapper', { static: true }) plansInnerWrapper: ElementRef;

  @select(['user', 'trialCouponCode'])
  trialCouponCode$;

  permissions: PlanPermission[];

  @Input()
  columns = false;

  @Input()
  set currentPlans(val: Collection<Plan>) {
    if (val) {
      this.initialPlans = val;
      this.initialPlans = this.initialPlans?.map(plan => ({
        ...plan,
        canRedeemCouponCode: this.canRedeemCouponCode(plan),
        monthly_price: plan.annual ? plan.price / 12 : plan.price,
        walletPayoutFee: this.getPlanServiceFee(plan),
        permissions: this.permissions || plan.permissions
      }));
      this.filterPlans(false);
    }
  }

  initialPlans: Collection<Plan>;
  plans: Collection<Plan>;

  @Input()
  loading = true;

  @Input()
  subscribingToPlan = false;

  @Input()
  set coupon(val) {
    if (val) {
      this._coupon = val.clone(Coupon);
    }
  }

  get coupon() {
    return this._coupon;
  }

  @Input()
  set activeSubscription(val: Subscription) {
    if (val) {
      this._activeSubscription = val;
      this.selected = val;
      const planType = this._activeSubscription.plan.annual ? PlanTypeEnum.ANNUAL : PlanTypeEnum.MONTHLY;
      this.selectPlanType(planType, true);
      this.navigateToActivePlan();
    }
  }

  get activeSubscription() {
    return this._activeSubscription;
  }

  @Input()
  set nextSubscription(val) {
    if (val) {
      this._nextSubscription = val;
      this.permissions = this._nextSubscription.plan.permissions;
      this.onSelect(this._nextSubscription.plan);
    }
  }

  @Output() closeModal: EventEmitter<void> = new EventEmitter();
  @Output() planSelected: EventEmitter<Plan> = new EventEmitter();

  get nextSubscription() {
    return this._nextSubscription;
  }

  set selected(val) {
    this._selected = val;
  }

  get selected() {
    return this._selected;
  }

  annualPlansEnabled: boolean = false;
  initialAnimationDone = false;
  freePlanId = 0;
  private destroy$ = new Subject<void>();

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.calculatePlanWidth();
  }

  constructor(
    private router: Router,
    private trackingService: RudderTrackingService,
    private renderer: Renderer2,
    private ref: ChangeDetectorRef,
    private i18n: I18n,
  ) { }

  private getPlanServiceFee(plan: Plan): number {
    const fee = plan.fees?.find((f: PlanFee) => f.slug === 'paypal_service');

    if (!fee) {
      return;
    }

    return Math.floor(fee.amount / 100);
  }

  private setNumberOfPlanElements() {
    this.windowWidth = window.innerWidth;
    this.activeBreakpoint = this.plansBreakpoints.find((breakpoint) => this.windowWidth > breakpoint.breakpoint);
    this.numberOfPlanElements = this.activeBreakpoint.elements;
  }

  private calculatePlanWidth(): void {
    this.setNumberOfPlanElements();
    this.planWidth = (this.plansInnerWrapper.nativeElement.offsetWidth - this.plansGap * this.numberOfPlanElements) / this.numberOfPlanElements;
    this.currentCarouseOffsetIndex = Math.floor(Math.abs(this.currentCarouseOffset) / this.planWidth);
    this.renderer.setStyle(this.wrapper.nativeElement, 'width', `${this.plans?.length * (this.planWidth + this.plansGap)}px`);
  }

  ngOnInit() {
    this.calculatePlanWidth();
  }

  navigateToActivePlan() {
    if (!this._activeSubscription || !this.plans) {
      return;
    }

    // findIndex is 0 based, 1 is added
    const activePlanIndex = this.plans?.toArray().findIndex((plan) => plan.id === this._activeSubscription.plan.id) + 1;

    /**
     * We added free plan on frontend only, that's why we need to subtract 1
     * to get the proper offset.
     */
    const numberOfElements = this.selectedPlanType === PlanTypeEnum.ANNUAL ?
      this.numberOfPlanElements - 1 : this.numberOfPlanElements;

    if ((activePlanIndex / numberOfElements) >= 1) {
      this.currentCarouseOffset = ((this.planWidth + this.plansGap) * ( Math.floor( activePlanIndex / numberOfElements ) ) ) * -1;
      this.currentCarouseOffsetIndex = Math.floor(Math.abs(this.currentCarouseOffset) / this.planWidth);
      this.renderer.setStyle(this.wrapper.nativeElement, 'transform', `translateX(${this.currentCarouseOffset}px)`);
    }

    this.ref.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeSubscription && changes.activeSubscription.currentValue && changes.activeSubscription.previousValue) {
      // First subscribe
      if (changes.activeSubscription.previousValue.plan.price === 0 && changes.activeSubscription.currentValue.plan.price > 0) {
        this.trackingService.trackEvent('PaidSubscription', {
          plan: changes.activeSubscription.currentValue.plan.name,
          plan_id: changes.activeSubscription.currentValue.plan_id,
          value: changes.activeSubscription.currentValue.plan.price,
          order_id: changes.activeSubscription.currentValue.id,
          currency: 'USD',
        });

        GTagService.trackConversion('AW-782139888/fuHlCN_6vv4BEPCD-vQC');
        this.router.navigate(['/success'], {queryParams: {type: 'success.subscription'}});
      }

      // Upgrade subscription
      if (changes.activeSubscription.currentValue.plan.price > changes.activeSubscription.previousValue.plan.price && changes.activeSubscription.previousValue.plan.price > 0) {
        this.trackingService.trackEvent('UpgradeSubscription', {
          previousPlan: changes.activeSubscription.previousValue.plan.name,
          newplan: changes.activeSubscription.currentValue.plan.name,
          plan_id: changes.activeSubscription.currentValue.plan_id,
        });
      }

      // Cancel subscription
      if (changes.activeSubscription.previousValue.plan.price > 0 && changes.activeSubscription.currentValue.plan.price > 0 && changes.activeSubscription.currentValue.canceled) {
        this.trackingService.trackEvent('CancelSubscription', {
          plan: changes.activeSubscription.currentValue.plan.name,
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigatePlans(direction: DirectionEnum) {
    if (direction === DirectionEnum.FORWARD) {
      if (this.currentCarouseOffsetIndex + this.numberOfPlanElements < this.plans?.length) {
        this.currentCarouseOffset -= this.planWidth;
        this.currentCarouseOffsetIndex++;
      }
    } else if (direction === DirectionEnum.BACK) {
      if (this.currentCarouseOffsetIndex > 0) {
        this.currentCarouseOffset += this.planWidth;
        this.currentCarouseOffsetIndex--;
      }
    }

    this.renderer.setStyle(this.wrapper.nativeElement, 'transform', `translateX(${this.currentCarouseOffset}px)`);
    this.ref.markForCheck();
  }

  selectPlanType(event: PlanTypeEnum, navigateToPlan: boolean = false) {
    if(this.plans?.length === 1) {
      return;
    }

    if (this.selectedPlanType !== event) {
      this.selectedPlanType = event;
      this.filterPlans(navigateToPlan);
    }
  }

  filterPlans(navigateToPlan?: boolean) {
    if (this._selected && (this._selected.annual && this.selectedPlanType !== PlanTypeEnum.ANNUAL || !this._selected.annual && this.selectedPlanType === PlanTypeEnum.ANNUAL)) {
      this._selected = this.initialPlans?.filter((plan: Plan) => {
        return this._selected.name === plan.name && this._selected.annual !== plan.annual;
      }).first();

      if (this._selected) {
        this.onSelect(this._selected);
      }
    }
    if (this.selectedPlanType === PlanTypeEnum.ANNUAL) {
      this.initialPlans.unshift(new Plan({
        id: this.freePlanId,
        remote_id: null,
        name: this.i18n('Free'),
        price: 0,
        annual: true,
        display_price: 0,
        feature: '',
        features: [],
        permissions: [],
        end_after_days: 0,
        monthly_price: 0,
        walletPayoutFee: 0,
        fees: [],
      }));
    }
    this.plans = this.initialPlans?.filter((plan: Plan) => this.selectedPlanType === PlanTypeEnum.ANNUAL ? plan.annual : !plan.annual);
    this.numberOfCarouselPages = Math.ceil(this.plans?.length / 3);
    this.currentCarouselPage = 1;
    this.currentCarouseOffset = 0;

    this.calculatePlanWidth();

    if (navigateToPlan) {
      this.navigateToActivePlan();
    }
    this.ref.markForCheck();
  }

  public onSelect(plan: Plan) {
    // Free plan selected, ignore
    if (plan.id === this.freePlanId) {
      return;
    }

    this._selected = {
      ...plan,
      canRedeemCouponCode: this.canRedeemCouponCode(plan),
      monthly_price: plan.annual ? plan.price / 12 : plan.price,
      permissions: this.permissions || plan.permissions,
    };

    if (this.plans && this.plans?.length && this._selected && this._selected.id) {
      this.currentlySelectedIndex = this.plans?.map((p: Plan) => p.id).toArray().indexOf(this._selected.id);
    }

    this.planSelected.emit(this._selected);
  }

  public closeModalEvent() {
    this.closeModal.emit();
  }

  private canRedeemCouponCode(plan: Plan) {
    if (plan && plan.permissions) {
      return plan.permissions.filter(function (permission) {
        return permission.slug === 'plan-coupon.redeem';
      }).length === 1;
    }
    return false;
  }

  isCouponCodeApplied(plan) {
    const subscription = this.activeSubscription;
    const code = this.activeSubscription?.couponObj;

    return this.canRedeemCouponCode(plan) && subscription && code && (Boolean(code) && code.plans.includes(plan.remote_id));
  }

  isFreePlan(plan: Plan): boolean {
    return plan && plan.id === this.freePlanId;
  }

  get selectedIndex(): number {
    if (!this.plans || !this.selected) {
      return 0;
    }

    return this.plans?.toArray().map((plan: Plan) => plan.id).indexOf(this.selected.id);
  }
}

@Component({
  selector: 'st-plan-badge',
  template: `<span *ngIf="display">{{ next.is_past_due ? 'PAST DUE' : 'PENDING' }}</span>`,
})
export class PlanBadgeComponent {
  @Input()
  selected;

  @Input()
  next;

  @Input()
  active;

  get display() {
    if (!this.active && this.next && this.next.plan.id === this.selected.id) {
      return true;
    }

    return this.selected && this.next && this.active &&
      this.selected.id === this.next.plan.id &&
      this.next.plan.id !== this.active.plan.id;
  }
}
