import { Component, Input } from "@angular/core";
import { ActiveModal } from "@app/shared/components/modals/modal.service";

@Component({
  selector   : 'st-dialog',
  exportAs   : 'dialog',
  templateUrl: './dialog.component.html',
  styleUrls  : ['./dialog.component.scss']
})
export class DialogComponent {
  constructor(private activeModal: ActiveModal) {}

  @Input()
  disableClose: boolean = false;

  @Input() size = 'small';

  close(): void {
    if(!this.disableClose)
      this.activeModal.close(false);
  }
}
