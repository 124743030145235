import { AmazonSnapshotActions } from '@app/shared/data/admin/amazon-snapshot/amazon-snapshot.actions';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';

const INITIAL_STATE: AppState = {
  data: [],
  loading: false,
  error  : null,
};

export function amazonSnapshotReducers(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == AmazonSnapshotActions.TYPES.SHOW) {
    return {
      ...state,
      loading: true
    };
  }

  if (action.type == AmazonSnapshotActions.TYPES.SHOW_SUCCEEDED) {
    return {
      ...state,
      data: action.data,
      loading: false
    };
  }

  if (action.type == AmazonSnapshotActions.TYPES.SHOW_FAILED) {
    return {
      ...state,
      error  : action.data,
      loading: false
    };
  }

  return state;
}
