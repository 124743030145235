
import {take, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { KeywordGeneratedActions } from '@app/shared/data/keyword-generated/keyword-generated.actions';
import { R2aTaskApi } from '@app/shared/data/tasks/r2a-task/r2a-task.api';
import { R2aTaskActions } from '@app/shared/data/tasks/r2a-task/r2a-task.actions';
import { ReverseAsinActions } from '@app/shared/data/reverse-asin/reverse-asin.actions';
import { R2aTask } from '@app/shared/data/tasks/r2a-task/r2a-task.models';
import { AsinCompareActions } from '@app/shared/data/asin-compare/asin-compare.actions';
import { R2AActionsV2 } from '../../reverse-asin-v2/reverse-asin.actions';

@Injectable()
export class R2aTaskEpics {
  constructor(
    private store: NgRedux<AppState>,
    private r2aTaskApi: R2aTaskApi,
    private r2aTaskActions: R2aTaskActions,
    private reverseAsinActions: ReverseAsinActions,
    private asinCompareActions: AsinCompareActions,
    private r2AActionsV2: R2AActionsV2,
  ) { }

  public createEpic() {
    return [
      this.listLoad,
      this.load,
      this.open,
    ];
  }

  listLoad = store => next => action => {

    if (action.type === R2aTaskActions.TYPES.LIST
      || action.type === KeywordGeneratedActions.TYPES.LOAD_RECENT) {
      this.r2aTaskApi.index().subscribe((x: any) => {
          this.store.dispatch(this.r2aTaskActions.listSearchesLoaded(x));
        },
        () => {

        });
    }

    return next(action);
  };

  load = store => next => action => {
    if (action.type === R2aTaskActions.TYPES.LOAD) {
      this.r2aTaskApi.find(action.data.id).subscribe((x: any) => {
          this.store.dispatch(this.r2aTaskActions.loadSearchLoaded(x.data));
        },
        () => {
          // TODO: Error handling
        });
    }

    return next(action);
  };

  open = store => next => action => {
    // Check if finished and mark open if on last search page
    if (action.type === ReverseAsinActions._events.keywords_received
      && store.getState()['router'].includes('r2a')
      && [
        store.getState().reverse_asin?.group_id,
        store.getState().r2a_v2.activeSearch?.groupId
      ].includes(action.data?.group_id)) {
      const group_id = action.data.group_id;
      this.store.select(['tasks', 'data', group_id]).pipe(
        filter((x: any) => !!x && 'id' in x),
        take(1),
      ).subscribe((task: R2aTask) => {
        this.store.dispatch(this.r2aTaskActions.openSearch(task.id, task.asins, task.compare_asin, task.marketplace, false));
      });
    }

    if (action.type === R2aTaskActions.TYPES.OPEN) {
      this.r2aTaskApi.open(action.data.id).pipe(take(1)).subscribe((ls: any) => {
        if (action.data.load) {
          this.store.dispatch(
            this.reverseAsinActions.requestKeywords(
              action.data.asins, action.data.compare_asin, action.data.id, action.data.marketplace, ls.depth, ls.resolveChildren
            )
          );

          this.store.dispatch(this.r2AActionsV2.requestKeywords(
            action.data.asins, action.data.compare_asin, action.data.id, action.data.marketplace, ls.depth, ls.resolveChildren
          ));
        } else if (action.data.compare_asin) {
          this.store.dispatch(this.asinCompareActions.compare(action.data.compare_asin, action.data.id));
        }

        if (ls.opened_at !== null) {
          this.store.dispatch(this.r2aTaskActions.removeSearch(action.data.id));
        }
      });
    }

    return next(action);
  };
}
