import { Component, Input } from '@angular/core';
import { Options } from 'redux-observable';
import { V2TableDataRecord } from '../../table.models';

@Component({
  selector: 'st-table-products-list-item',
  template: `
    <div class="products-list">
      <div class="products-list__product" *ngFor="let product of item.value" [style.backgroundImage]="'url(' + product.image_url + ')'"
           [popper]="product.title"
           popperTrigger="hover"
           popperPlacement="bottom"
           popperAppendTo="body"
      ></div>
    </div>
  `,
  styles: [`
    .products-list {
      display: flex
    }

    .products-list__product {
      height: 50px;
      width: 50px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-color: white;
      border-radius: 0 0 2px 2px;
    }
  `]
})
export class ProductsListItemComponent {
  @Input() item: V2TableDataRecord;
}
