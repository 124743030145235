import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Invoice, InvoicesCollection, InvoicesTypeEnum } from '@app/shared/data/invoice/invoice.models';
import { LastLaunchScheduleInvoice } from '@app/shared/data/last-launch-schedule/last-launch-schedule.models';

export enum LoadDirectionEnum {
  PREVIOUS = 'previous',
  NEXT = 'next'
}
@Injectable()
export class InvoiceActions {
  static readonly TYPES = {
    SET_TYPE: '[INVOICE]SET_TYPE',
    LOAD: '[INVOICE]LOAD',
    LOAD_SUCCEEDED: '[INVOICE]LOAD_SUCCEEDED',
    LOAD_FAILED: '[INVOICE]LOAD_FAILED',
    DOWNLOAD: '[INVOICE]DOWNLOAD',
    DOWNLOAD_SUCCEEDED: '[INVOICE]DOWNLOAD_SUCCEEDED',
  };

  setInvoicesType(type: InvoicesTypeEnum): StAction {
    return {
      type: InvoiceActions.TYPES.SET_TYPE,
      data: {
        type,
      },
    };
  }

  load(direction: null|LoadDirectionEnum = null): StAction {
    return {
      type: InvoiceActions.TYPES.LOAD,
      data: {
        direction: direction,
      },
    };
  }

  loadSucceeded(invoices: InvoicesCollection, direction: null|LoadDirectionEnum = null): StAction {
    return {
      type: InvoiceActions.TYPES.LOAD_SUCCEEDED,
      data: {
        invoices,
        direction
      },
    };
  }

  loadFailed(error: any): StAction {
    return {
      type: InvoiceActions.TYPES.LOAD_FAILED,
      data: error,
    };
  }

  download(invoice: Invoice|LastLaunchScheduleInvoice, type: null|InvoicesTypeEnum = null): StAction {
    return {
      type: InvoiceActions.TYPES.DOWNLOAD,
      data: {
        invoice,
        type
      },
    };
  }

  downloadSucceeded(): StAction {
    return {
      type: InvoiceActions.TYPES.DOWNLOAD_SUCCEEDED,
      data: null,
    };
  }
}
