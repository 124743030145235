<div id="notices">
    <ng-container #allNotices *ngFor="let notice of systemNotices$ | async;" [ngTemplateOutlet]="noticeTemplate"
                  [ngTemplateOutletContext]="{notice:notice}"></ng-container>
    <ng-container #allNotices *ngFor="let notice of userNotices$ | async;" [ngTemplateOutlet]="noticeTemplate"
                  [ngTemplateOutletContext]="{notice:notice}"></ng-container>
</div>

<ng-template #noticeTemplate let-notice="notice">
    <button *ngIf="notice.type != 'trial'" class="notice" ngClass="{{notice.type ? notice.type : 'error'}}"
            (click)="onClick(notice.onClick)">
        <span class="icon"><st-icon [icon]="notice.icon"></st-icon></span>
        <span class="text">{{ notice.message }} </span>
        <a *ngIf="notice.url" href="{{notice.url}}">
            <ng-container *ngIf="notice.actionText; else translateClickForMore">{{notice.actionText}}</ng-container>
        </a>
        <a href="javascript:void(0)" *ngIf="notice.actionText && !notice.url">{{notice.actionText}}</a>
    </button>
</ng-template>

<ng-template #translateClickForMore i18n>
    Click for more ...
</ng-template>
