
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Collection } from '@app/shared/data/base.models';
import { BaseAPI } from '@app/shared/data/base.api';
import { ApiPromotion, ProductChild } from '@app/shared/data/api-promotion/api-promotion.models';
import { CustomHttpParamEncoder } from '@app/shared/UrlEncoders/get-param-encoder';

@Injectable()
export class ApiPromotionApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<ApiPromotion>> {
    return this.http.get(this.constructFullUrl('api/api-promotions'), { params: params }).pipe(
      map(data => ApiPromotion.collect<ApiPromotion>(data, ApiPromotion.BACKEND_PAGINATION)));
  }

  public get(apiPromotion: ApiPromotion, params: {} = {}): Observable<ApiPromotion> {
    return this.http.get(this.constructFullUrl('api/api-promotions/' + apiPromotion.id), {params: (new HttpParams({
      encoder: new CustomHttpParamEncoder(),
      fromObject : params
      }))}).pipe(
      map(data => new ApiPromotion(data)));
  }

  public save(apiPromotion: ApiPromotion): Observable<ApiPromotion> {
    return this.http.post(this.constructFullUrl('api/api-promotions'), apiPromotion).pipe(
      map(data => new ApiPromotion(data)));
  }

  public saveCoupons(apiPromotion: ApiPromotion): Observable<ApiPromotion> {
    return this.http.post(this.constructFullUrl('api/api-promotions/' + apiPromotion.id + '/coupons'), apiPromotion).pipe(
      map(data => new ApiPromotion(data)),
      catchError((response) => {
        let data = response.error;
        if ('error' in data) {
          data = data.error;
        }

        return observableThrowError({ message: data.message });
      }),);
  }

  public update(apiPromotion: ApiPromotion): Observable<ApiPromotion> {
    return this.http.put(this.constructFullUrl('api/api-promotions/' + apiPromotion.id), apiPromotion).pipe(
      map(data => new ApiPromotion(data)));
  }

  public delete(apiPromotion: ApiPromotion): Observable<ApiPromotion> {
    return this.http.delete(this.constructFullUrl('api/api-promotions/' + apiPromotion.id)).pipe(
      map(data => new ApiPromotion(data)));
  }

  public productPromotions(asin: string): Observable<Collection<ApiPromotion>> {
    return this.http.get(this.constructFullUrl('api/api-promotions/product/' + asin), {}).pipe(
      map(data => ApiPromotion.collect<ApiPromotion>(data)));
  }

  public products(params = {}): Observable<Collection<ProductChild>> {
    return this.http.get(this.constructFullUrl('api/api-promotions/products'), { params: params }).pipe(
               map(data => ProductChild.collect<ProductChild>(data, ProductChild.FRONTEND_PAGINATION)));
  }
}
