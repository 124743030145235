import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { ReverseAsin } from '@app/shared/data/reverse-asin/reverse-asin.models';
import { R2AActionsV2 } from './reverse-asin.actions';
import { AsinCompareActions } from '../asin-compare/asin-compare.actions';
import {
  CompareAsinData,
  CompareAsinKeywordData,
  ErrorCodesEnum,
  ErrorPayload,
  R2AProduct,
  ReverseAsinKeyword,
  ReverseAsinStats
} from './reverse-asin.models';
import {
  filterOperator,
  IFilter,
  ISavedFilter
} from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { pick } from '@app/helpers';
import { IProductManagerProduct } from '@app/shared/data/product-manager/product-manager.models';

export interface R2AV2InitialState extends AppState {
  data: ReverseAsinKeyword[];
  filteredData: ReverseAsinKeyword[];
  total: number;
  loading: boolean;
  error: ErrorPayload;
  loadingCompareAsin: boolean;
  compareAsinResults: CompareAsinData;
  history: any[];
  loadingHistory: boolean;
  progress: number;
  step: number;
  searchDone: boolean;
  stats: ReverseAsinStats;
  activeSearch: {
    asins: string[],
    group_id: string,
    products?: R2AProduct[];
    compareAsinProduct?: R2AProduct|IProductManagerProduct;
  };
  appliedFilters: IFilter[];
  appliedView: ISavedFilter;
  savedFilters: ISavedFilter[];
  missingAsins: string[];
}

const savedFiltersLSKey = 'R2A-saved-filters';
const savedFilters = JSON.parse(localStorage.getItem(savedFiltersLSKey)) || [];

const INITIAL_STATE: R2AV2InitialState = {
  data: null,
  filteredData: null,
  total: 0,
  loading: false,
  error: null,
  step: 0,
  history: null,
  loadingHistory: false,
  progress: 20,
  searchDone: false,
  stats: null,
  activeSearch: null,
  compareAsinResults: null,
  loadingCompareAsin: false,
  appliedFilters: null,
  appliedView: null,
  savedFilters,
  missingAsins: null,
};

export function r2aReducerV2(state: R2AV2InitialState = INITIAL_STATE, action: StAction): R2AV2InitialState {
  if (action.type === R2AActionsV2.TYPES.CLEAR_ALL) {
    return {
      ...INITIAL_STATE,
      history: state.history,
    };
  }

  if (action.type === R2AActionsV2.TYPES.APPLY_VIEW) {
    const filters = action.data?.name === state.appliedView?.name ?
      null :
      action.data?.filters || null;

    const appliedView = action.data?.name === state.appliedView?.name ?
      null :
      action.data;

    return {
      ...state,
      appliedFilters: filters,
      filteredData: createFiltered(state.data, filters),
      appliedView,
    };
  }

  if (action.type === R2AActionsV2.TYPES.DELETE_VIEW) {
    const filters = state.savedFilters.filter((f) => f.name !== action.data.name);

    localStorage.setItem(savedFiltersLSKey, JSON.stringify(filters));

    return {
      ...state,
      deletingView: true,
      savedFilters: filters
    };
  }

  if (action.type === R2AActionsV2.TYPES.SAVE_FILTERS) {
    const newFilters = [
      ...state.savedFilters,
      action.data
    ];

    localStorage.setItem(savedFiltersLSKey, JSON.stringify(newFilters));

    return {
      ...state,
      savedFilters: newFilters,
    };
  }

  if (action.type === R2AActionsV2.TYPES.LOAD_HISTORY) {
    return {
      ...state,
      loadingHistory: true,
    };
  }

  if (action.type === R2AActionsV2.TYPES.SET_ACTIVE_ASIN) {
    return {
      ...state,
      error: null,
      activeSearch: {
        ...state.activeSearch,
        asins: action.data.asins,
        group_id: action.data.group_id
      },
    };
  }

  if (action.type === R2AActionsV2.TYPES.SET_ACTIVE_PRODUCT) {
    return {
      ...state,
      error: null,
      activeSearch: {
        ...state.activeSearch,
        products: action.data.products,
      },
    };
  }

  if (action.type === R2AActionsV2.TYPES.SET_COMPARE_ASIN_PRODUCT) {
    const current = state.activeSearch?.compareAsinProduct;

    if (Boolean(current) && current.asin === action.data?.asin && current.image_url) {
      return state;
    }

    return {
      ...state,
      error: null,
      activeSearch: {
        ...state.activeSearch,
        compareAsinProduct: action.data,
      }
    };
  }

  if (action.type === R2AActionsV2.TYPES.LOAD_HISTORY_SUCCESS) {
    return {
      ...state,
      history: action.data,
      loadingHistory: false,
    };
  }

  if (action.type === R2AActionsV2.TYPES.SET_SEARCH_DONE) {
    return {
      ...state,
      searchDone: action.data,
    };
  }

  if (action.type === R2AActionsV2.TYPES.REQUEST_KEYWORDS) {
    return {
      ...INITIAL_STATE,
      loading: true,
      activeSearch: {
        ...state.activeSearch,
        asins: action.data.asins,
        group_id: action.data.group_id
      },
    };
  }

  if (action.type === R2AActionsV2.TYPES.UPDATE_PROGRESS) {
    return {
      ...state,
      progress: action.data.progress,
      step: action.data.step,
    };
  }

  if (action.type === R2AActionsV2.TYPES.REQUEST_KEYWORDS_ERROR) {

    const activeSearch = state.activeSearch;
    // if there was a data validation error, lets set an error to the product
    if (Boolean(action.data.validation_error)) {
      activeSearch.products = state.activeSearch?.products.map((p, idx) => {
        if ('asins.' + idx in action.data.validation_error) {
          p['error'] = action.data.validation_error['asins.' + idx];
        }

        return p;
      });
    }

    return {
      ...INITIAL_STATE,
      activeSearch: activeSearch,
      error: action.data,
    };
  }

  if (action.type === R2AActionsV2.TYPES.REQUEST_KEYWORDS_SUCCESS) {
    const {
      stats,
      keywords
    } = action.data;

    const data = keywords.map((keyword: ReverseAsin) => {
      const model: ReverseAsinKeyword = pick(
        'keyword', 'categories', 'metrics'
      )(keyword);

      model.search_volume = Boolean(keyword.impressionP) ? Math.round(Number(keyword.impressionP)) : null;
      model.in_brand_analytics = Boolean(keyword.sfr);
      model.in_report_card = Boolean(keyword.report_card);
      model.is_sponsored = Boolean(keyword.in_sponsored) && keyword.in_sponsored.length > 0;
      model.avg_rank = Number(keyword.avg_rank) || null;
      model.min_rank = Number(keyword.min_rank) || null;
      model.min_rank_asin = Boolean(keyword.min_rank_asin) ? String(keyword.min_rank_asin) : null;
      model.sfr_rank = Boolean(keyword.sfr) ? Number(keyword.sfr) : null;
      model.sponsored_asins = keyword.in_sponsored || [];
      model.density_score = Number(keyword.density) || 0;
      model.results_count = Boolean(keyword.resultsCount) ? Number(keyword.resultsCount) : null;
      model.amazons_choice_asins = keyword.amazons_choice || [];
      model.density_score_amazons_choice = model.amazons_choice_asins.length;
      model.density_score_sponsored = model.sponsored_asins.length;
      model.density_score_report_card = Boolean(keyword.rc_density) ? Number(keyword.rc_density) : 0;

      return model;
    });

    return {
      ...state,
      searchDone: true,
      error: null,
      data, // keywords transformed
      filteredData: createFiltered(data, state.appliedFilters),
      stats,
      loading: false,
      total: keywords.length,
      missingAsins: getMissingAsins(action.data.info, state.activeSearch.asins),
    };
  }

  if (action.type === R2AActionsV2._events.keywords_received) {
    return {
      ...state,
      missingAsins: getMissingAsins(action.data.info, state.activeSearch.asins),
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE) {
    return {
      ...state,
      loadingCompareAsin: typeof (action.data.backgroundLoading) !== 'undefined' ? action.data.backgroundLoading : true,
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_SUCCEEDED) {
    const {
      compare,
      stats,
      keywords,
    } = action.data.results;
    const data = attachMyProductToKeywords(state, keywords);

    return {
      ...state,
      loadingCompareAsin: false,
      compareAsinResults: {...state.compareAsinResults, compare, stats},
      data,
      filteredData: createFiltered(data, state.appliedFilters),
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_FAILED) {
    return {
      ...state,
      loadingCompareAsin: false,
      compareAsinResults: {
        ...state.compareAsinResults,
        error: {
          error_code: action.data.error.error_code,
          message: action.data.errorMessage,
        }
      },
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_CHILDREN) {
    const children = action.data.children;
    return {
      ...state,
      activeSearch: {
        ...state.activeSearch,
        compareAsinProduct: children[0] || state.activeSearch?.compareAsinProduct,
      },
      compareAsinResults: {
        ...state.compareAsinResults,
        children
      }
    };
  }

  if (action.type === R2AActionsV2.TYPES.APPLY_FILTERS) {
    return {
      ...state,
      filteredData: createFiltered(state.data, action.data.filters),
      appliedFilters: action.data.filters
    };
  }

  if (action.type === R2AActionsV2.TYPES.REMOVE_KEYWORD_MY_ASIN_DATA) {
    const data = (state.data?.map((k) => {
      if (action.data.includes(k.keyword)) {
        return {
          ...k,
          my_asin: {
            ...k?.my_asin,
            __status_adding_to_tracked_list: false,
            is_tracked: false,
          }
        };
      }

      return k;
    }) || []) as ReverseAsinKeyword[];

    return {
      ...state,
      data,
      compareAsinResults: {
        ...state.compareAsinResults,
        compare: {
          ...state.compareAsinResults.compare,
          ...getTrackedKeywordsStats(data)
        }
      },
      filteredData: createFiltered(data, state.appliedFilters),
    };
  }

  if (action.type === R2AActionsV2.TYPES.TRACK_KEYWORDS) {
    const data = (state.data?.map((k) => {
      if (action.data.keywords.includes(k.keyword)) {
        return {
          ...k,
          my_asin: {
            ...k?.my_asin,
            __status_adding_to_tracked_list: true
          }
        };
      }
      return k;
    }) || []) as ReverseAsinKeyword[];

    return {
      ...state,
      data,
      filteredData: createFiltered(data, state.appliedFilters),
    };
  }

  if (action.type === R2AActionsV2.TYPES.TRACK_KEYWORDS_DONE) {
    const data = (state.data?.map((k) => {
      if (action.data.keywords.includes(k.keyword)) {
        return {
          ...k,
          my_asin: {
            ...k?.my_asin,
            __status_adding_to_tracked_list: false,
            is_tracked: true,
          }
        };
      }
      return k;
    }) || []) as ReverseAsinKeyword[];

    return {
      ...state,
      data,
      compareAsinResults: {
        ...state.compareAsinResults,
        compare: {
          ...state.compareAsinResults.compare,
          ...getTrackedKeywordsStats(data)
        }
      },
      filteredData: createFiltered(data, state.appliedFilters),
    };
  }

  return state;
}

function attachMyProductToKeywords(state: R2AV2InitialState, myAsinKeywords: CompareAsinKeywordData): any {
  return state.data?.map((ra: ReverseAsinKeyword) => {
    const myAsin = myAsinKeywords[ra.keyword];

    if (Boolean(myAsin)) {
      const tags = myAsin.t || [];
      return {
        ...ra,
        my_asin: {
          is_tracked: tags?.includes('T'),
          is_sponsored: tags?.includes('S'),
          in_report_card: tags?.includes('A'),
          is_ranked: tags?.includes('R'),
          is_organically_ranked: tags?.includes('O'),
          organic_rank: Boolean(myAsin.r) ? Number(myAsin.r) : null,
          is_ranked_successfully: tags?.includes('RS'),
          has_opportunity: tags?.includes('OP'),
          is_missing_advertising: tags?.includes('MS')
        }
      };
    }
    return {
      ...ra
    };
  }) || [];
}

function getMissingAsins(products, activeSearchProducts = []): string[] {
  const foundAsins: { [asin: string]: any } = [];
  products.forEach(info => {
    [info.asin, ...info.children].filter(asin => activeSearchProducts.includes(asin)).forEach(asin => {
      if (!(asin in foundAsins)) {
        foundAsins[asin] = { ...info, searchedAsin: asin };
      }
    });
  });

  return activeSearchProducts.filter(asin => !(asin in foundAsins));
}

function getTrackedKeywordsStats(keywords: ReverseAsinKeyword[]) {
  let stats = {
    tracked_organic: 0,
    not_tracked_organic: 0,
    tracked_not_organic: 0,
    not_tracked_not_organic: 0,
  };
  keywords.forEach(k => {
    if (k.my_asin?.is_organically_ranked) {
      stats.tracked_organic += k.my_asin.is_tracked ? 1 : 0;
      stats.not_tracked_organic += !k.my_asin.is_tracked ? 1 : 0;
    } else {
      stats.tracked_not_organic += k.my_asin.is_tracked ? 1 : 0;
      stats.not_tracked_not_organic += !k.my_asin.is_tracked ? 1 : 0;
    }
  })
  return stats;
}

export function createFiltered(keywords: ReverseAsinKeyword[], filters: IFilter[]): ReverseAsinKeyword[] {
  if (!filters?.length) {
    return keywords;
  }

  const filterFn = (kw: ReverseAsinKeyword) => {
    return !filters.some((filter: IFilter) => { // Returns false if none of the conditions match
      let value: any = false;

      switch (filter.field) {
        case 'sfr':
          value = kw.sfr_rank;
          break;
        case 'search_volume':
          value = kw.search_volume;
          break;
        case 'density':
          value = kw.density_score;
          break;
        case 'sponsored_density':
          value = kw.density_score_sponsored;
          break;
        case 'organic_ranking':
          value = kw.avg_rank || 0;
          break;
        case 'min_rank':
          value = kw.min_rank;
          break;
        case 'result_count':
          value = kw.results_count;
          break;
        case 'report_card':
          value = kw.in_report_card ? 0 : 1;
          break;
        case 'keyword':
          value = kw.keyword.toLowerCase();
          break;
        case 'amazons_choice':
          value = kw.density_score_amazons_choice ? 0 : 1;
          break;
        case 'word_count':
          value = kw.keyword.trim().split(/\s+/).length;
          break;
        case 'my_asin_rank':
          value = kw.my_asin?.organic_rank;
          break;
        case 'tracking_keyword':
          value = kw.my_asin?.is_tracked ? 0 : 1;
          break;
        case 'ranked_keyword':
          value = kw.my_asin?.is_ranked ? 0 : 1;
          break;
        case 'keyword_in_amazon_report':
          value = kw.my_asin?.in_report_card ? 0 : 1;
          break;
        case 'sponsored_keyword':
          value = kw.my_asin?.is_sponsored ? 0 : 1;
          break;
        case 'asin_vs_target':
          value = kw.my_asin?.is_ranked_successfully ? 0 : 1;
          break;
        case 'keyword_opportunities':
          value = kw.my_asin?.has_opportunity ? 0 : 1;
          break;
        case 'within_top_tracking':
          value = kw.my_asin?.is_organically_ranked && kw.my_asin?.is_tracked ? 0 : 1;
          break;
        case 'within_top_not_tracking':
          value = kw.my_asin?.is_organically_ranked && !kw.my_asin?.is_tracked ? 0 : 1;
          break;
        case 'outside_top_tracking':
          value = !kw.my_asin?.is_organically_ranked && kw.my_asin?.is_tracked ? 0 : 1;
          break;
        case 'outside_top_not_tracking':
          value = !kw.my_asin?.is_organically_ranked && !kw.my_asin?.is_tracked ? 0 : 1;
          break;
        case 'missing_advertising':
          value = kw.my_asin?.is_missing_advertising ? 0 : 1;
          break;
        case 'not_successful':
          value = !kw.my_asin?.is_ranked_successfully ? 0 : 1;
          break;
        default:
          return true;
      }

      return !filterOperator(value, filter);
    });
  };

  return keywords.filter(filterFn);
}
