import { SubAccount } from "@app/shared/data/sub-account/sub-account.models";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import {Collection} from "@app/shared/data/base.models";
import {Credential} from "@app/shared/data/credential/credential.models";

@Injectable()
export class SubAccountActions extends PaginationActions {
  protected model = SubAccount;

  static readonly TYPES = {
    LOAD_SUB_ACCOUNTS          : '[SUB_ACCOUNT]LOAD_SUB_ACCOUNTS',
    LOAD_SUB_ACCOUNTS_SUCCEEDED: '[SUB_ACCOUNT]LOAD_SUB_ACCOUNTS_SUCCEEDED',
    LOAD_SUB_ACCOUNTS_FAILED   : '[SUB_ACCOUNT]LOAD_SUB_ACCOUNTS_FAILED',
    SEND_INVITATION            : '[SUB_ACCOUNT]SEND_INVITATION',
    SEND_INVITATION_SUCCEEDED  : '[SUB_ACCOUNT]SEND_INVITATION_SUCCEEDED',
    SEND_INVITATION_FAILED     : '[SUB_ACCOUNT]SEND_INVITATION_FAILED',
    UPDATE_INVITATION          : '[SUB_ACCOUNT]UPDATE_INVITATION',
    UPDATE_INVITATION_SUCCEEDED: '[SUB_ACCOUNT]UPDATE_INVITATION_SUCCEEDED',
    UPDATE_INVITATION_FAILED   : '[SUB_ACCOUNT]UPDATE_INVITATION_FAILED',
    REMOVE_INVITATION          : '[SUB_ACCOUNT]REMOVE_INVITATION',
    REMOVE_INVITATION_SUCCEEDED: '[SUB_ACCOUNT]REMOVE_INVITATION_SUCCEEDED',
    REMOVE_INVITATION_FAILED   : '[SUB_ACCOUNT]REMOVE_INVITATION_FAILED',
    SET_SUB_ACCOUNTS           : '[SUB_ACCOUNT]SET_SUB_ACCOUNTS',
  };

  loadSubAccounts(activeCredential: Credential): StAction {
    return {
      type: SubAccountActions.TYPES.LOAD_SUB_ACCOUNTS,
      data: {
        credential: activeCredential
      }
    }
  }

  setSubAccounts(subAccounts: Collection<SubAccount>): StAction {
    return {
      type: SubAccountActions.TYPES.SET_SUB_ACCOUNTS,
      data: subAccounts
    }
  }

  sendInvitation(credentialId, email): StAction {
    return {
      type: SubAccountActions.TYPES.SEND_INVITATION,
      data: {
        credentialId: credentialId,
        email       : email
      }
    }
  }

  sendInvitationSucceeded(data): StAction {
    return {
      type: SubAccountActions.TYPES.SEND_INVITATION_SUCCEEDED,
      data: data
    }
  }

  sendInvitationFailed(error): StAction {
    return {
      type: SubAccountActions.TYPES.SEND_INVITATION_FAILED,
      data: error
    }
  }

  updateInvitation(credentialId, subAccount: SubAccount, changes): StAction {
    return {
      type: SubAccountActions.TYPES.UPDATE_INVITATION,
      data: {
        credentialId: credentialId,
        subAccount  : subAccount,
        changes     : changes,
      }
    }
  }

  updateInvitationSucceeded(subAccount: SubAccount): StAction {
    return {
      type: SubAccountActions.TYPES.UPDATE_INVITATION_SUCCEEDED,
      data: {
        subAccount: subAccount,
      }
    }
  }

  updateInvitationFailed(error): StAction {
    return {
      type: SubAccountActions.TYPES.UPDATE_INVITATION_FAILED,
      data: error
    }
  }

  removeInvitation(credentialId, subAccount: SubAccount): StAction {
    return {
      type: SubAccountActions.TYPES.REMOVE_INVITATION,
      data: {
        credentialId: credentialId,
        subAccount  : subAccount,
      }
    }
  }

  removeInvitationSucceeded(subAccount: SubAccount): StAction {
    return {
      type: SubAccountActions.TYPES.REMOVE_INVITATION_SUCCEEDED,
      data: {
        subAccount: subAccount,
      }
    }
  }

  removeInvitationFailed(error): StAction {
    return {
      type: SubAccountActions.TYPES.REMOVE_INVITATION_FAILED,
      data: error
    }
  }
}
