
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseAPI } from "@app/shared/data/base.api";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { AdminPlan } from "@app/shared/data/admin/plan/plan.models";

@Injectable()
export class AdminPlanAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public load(params): Observable<Collection<AdminPlan>> {
    return this.http.get(this.constructFullUrl('api/admin/plans'), { params: params }).pipe(
      map(data => AdminPlan.collect<AdminPlan>(data)));
  }

  public get(plan: AdminPlan): Observable<AdminPlan> {
    return this.http.get(this.constructFullUrl(`api/admin/plans/${plan.id}`)).pipe(
      map(data => new AdminPlan(data)));
  }

  public save(plan: AdminPlan, limits, permissionsChanges): Observable<AdminPlan> {
    let path = this.constructFullUrl(`api/admin/plans`);
    let request = null;
    if (plan.id) {
      request = this.http.put(`${path}/${plan.id}`, {
        ...plan,
        limits: limits,
        permissionsChanges: permissionsChanges
      });
    } else {
      request = this.http.post(`${path}`, plan);
    }
    return request.pipe(map(data => new AdminPlan(data)));
  }
}
