import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { IDropdownOption } from "../models/IDropdownOptions";

@Component({
  selector: 'st-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit, OnDestroy, OnChanges {
  @Input() options: IDropdownOption[];
  @Input() alternateBg = false;
  @Input() transparentBg = false;
  @Input() color: 'blue' = null;
  @Input() preSelectedOption?: IDropdownOption;
  @Input() dropdownPosition: 'down'|'up' = 'down';
  @Input() preselectedOptionEmpty: boolean = false;
  @Input() size: 'small'|'normal' = 'normal';
  @Input() disabled: boolean = false;

  @Output() selected: EventEmitter<IDropdownOption> = new EventEmitter();

  selectedOption: IDropdownOption;

  opened = false;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.preSelectedOption) {
      this.selectedOption = this.preSelectedOption;
    } else if (!this.preselectedOptionEmpty && this.options.length > 0) {
      this.selectOption(this.options[0]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.preSelectedOption && JSON.stringify(changes.preSelectedOption.previousValue) !== JSON.stringify(changes.preSelectedOption.currentValue)) {
      this.selectedOption = changes.preSelectedOption.currentValue;
      this.cd.detectChanges();
    }

    if (changes.options && !changes.options.firstChange && JSON.stringify(changes.options.previousValue) !== JSON.stringify(changes.options.currentValue)) {
      this.selectOption(this.preselectedOptionEmpty || this.options.length <= 0 ? null:this.options[0]);
      this.cd.detectChanges();
    }
  }

  ngOnDestroy() { }

  toggleDropdown() {
    if (this.disabled) {
      return;
    }

    this.opened = !this.opened;
  }

  selectOption(option: IDropdownOption) {
    if (this.disabled) {
      return;
    }

    this.selectedOption = option;
    this.selected.emit(this.selectedOption);
    this.opened = false;
  }

  get filteredOptions(): IDropdownOption[] {
    return this.options.filter(o => !o.dontDisplayInDropdown);
  }
}
