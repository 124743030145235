import { Injectable } from "@angular/core";
import { SubAccountActions } from "@app/shared/data/sub-account/sub-account.actions";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { SubAccountAPI } from "@app/shared/data/sub-account/sub-account.api";
import { SubAccount } from "@app/shared/data/sub-account/sub-account.models";
import { Collection } from "@app/shared/data/base.models";

@Injectable()
export class SubAccountEpics {
  constructor(private subAccountActions: SubAccountActions,
              private store: NgRedux<AppState>,
              private subAccountAPI: SubAccountAPI) {
  }

  public createEpic() {
    return [
      this.loadPage,
      this.loadSubAccounts,
      this.sendInvitation,
      this.updateInvitation,
      this.removeInvitation,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == this.subAccountActions.loadPage(0).type) {
        this.subAccountAPI.all(action.data.context.credential)
          .subscribe(
            (subAccounts: Collection<SubAccount>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.setPage(subAccounts));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.loadPageFailed(response));
            },
            () => {

            },
          );
      }
      return next(action);
    };
  };

  loadSubAccounts = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == SubAccountActions.TYPES.LOAD_SUB_ACCOUNTS) {
        this.subAccountAPI.all(action.data.credential.id)
          .subscribe(
            (subAccounts: Collection<SubAccount>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.setSubAccounts(subAccounts));
            },
            (response) => {
              console.log("subAccounts error",response);
            },
            () => {},
          );
      }
      return next(action);
    };
  };

  sendInvitation = store => next => {
    return (action) => {
      if (action.type == this.subAccountActions.sendInvitation(null, null).type) {
        this.subAccountAPI.sendInvitation(action.data.credentialId, action.data.email)
          .subscribe(
            (subAccount: SubAccount) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.sendInvitationSucceeded(subAccount));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.sendInvitationFailed(response));
            }
          )
      }
      return next(action);
    }
  };

  updateInvitation = store => next => {
    return (action) => {
      if (action.type == this.subAccountActions.updateInvitation(null, null, null).type) {
        this.subAccountAPI.update(action.data.credentialId, action.data.subAccount.id, action.data.changes)
          .subscribe(
            (subAccount: SubAccount) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.updateInvitationSucceeded(subAccount));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.updateInvitationFailed(response));
            }
          )
      }
      return next(action);
    }
  };

  removeInvitation = store => next => {
    return (action) => {
      if (action.type == this.subAccountActions.removeInvitation(null, null).type) {
        this.subAccountAPI.remove(action.data.credentialId, action.data.subAccount.id)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.removeInvitationSucceeded(action.data.subAccount));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.subAccountActions.removeInvitationFailed(response));
            }
          )
      }
      return next(action);
    }
  };
}
