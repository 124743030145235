import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { BlacklistApi } from './blacklist.api';
import { BlacklistActions } from './blacklist.actions';
import { BlacklistEntry, BlacklistInvitationCollection, BlacklistInvitation, BlacklistSettings } from './blacklist.models';
import { dotVal } from '@app/functions';
import { ToastService } from '@app/core/services/toast.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class BlacklistEpics {

  constructor(private store: NgRedux<AppState>,
    private blacklistApi: BlacklistApi,
    private blacklistActions: BlacklistActions,
    private i18n: I18n,
    protected toastService: ToastService
  ) {
  }

  public createEpic() {
    return [
      this.load,
      this.addEntry,
      this.addEntries,
      this.replaceEntry,
      this.removeEntry,
      this.loadInvitations,
      this.share,
      this.accpetInvitation,
      this.removeInvitation,
      this.loadSettings,
      this.saveSettings,
    ];
  }

  load = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (
        action.type === PaginationActions.getPagActionTypes(BlacklistEntry).LOAD_PAGE ||
        action.type === BlacklistActions.TYPES.FILTER ||
        action.type === BlacklistActions.TYPES.REMOVE_INVITATION_SUCCEEDED ||
        action.type === BlacklistActions.TYPES.ACCPEPT_INVITATION_SUCCEEDED ||
        action.type === BlacklistActions.TYPES.REPLACE_ENTRY_SUCCEEDED ||
        // if new entry is added and paginator does not have any more pages
        (
          action.type === BlacklistActions.TYPES.ADD_ENTRY_SUCCEEDED &&
          action.data['refreshState'] &&
          !dotVal(store.getState(), 'wallet_blacklist.data').hasMore()
        ) ||
        // if new entries are added and paginator does not have any more pages
        (
          action.type === BlacklistActions.TYPES.ADD_ENTRIES_SUCCEEDED &&
          !dotVal(store.getState(), 'wallet_blacklist.data').hasMore()
        )
      ) {
        const state = store.getState()['wallet_blacklist'];

        this.blacklistApi.all({ ...state['_be_pagination'], ...state['_filters'] })
          .subscribe(
            (entries: Collection<BlacklistEntry>) => {
              this.store.dispatch(this.blacklistActions.setPage(entries));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.loadPageFailed(response.error));
            },
          );
      }

      return n;
    };
  }

  addEntry = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.ADD_ENTRY) {
        this.blacklistApi.addEntry(action.data['field'], action.data['value'])
          .subscribe(
            (entry: BlacklistEntry) => {
              this.toastService.success(
                this.i18n('Entry added'),
                this.i18n('New entry was added to Blacklist.')
              );

              this.store.dispatch(this.blacklistActions.addEntrySucceeded(entry, action.data['refreshState']));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.addEntryFailed(response.error));
            },
          );
      }

      return n;
    };
  }

  addEntries = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.ADD_ENTRIES) {
        this.blacklistApi.addEntries(action.data['entries'], action.data['note'])
          .subscribe(
            (entry: BlacklistEntry) => {
              this.toastService.success(
                this.i18n('Entries added'),
                this.i18n('New entries were added to Blacklist.')
              );

              this.store.dispatch(this.blacklistActions.addEntriesSucceeded());
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.addEntriesFailed(response.error));
            },
          );
      }

      return n;
    };
  }

  replaceEntry = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.REPLACE_ENTRY) {
        this.blacklistApi.replaceEntry(action.data['oldEntry'], action.data['value'], action.data['note'])
          .subscribe(
            (entry: BlacklistEntry) => {
              this.toastService.success(
                this.i18n('Entry update'),
                this.i18n('Blacklist entry was updated.')
              );

              this.store.dispatch(this.blacklistActions.replaceEntrySucceeded(action.data['oldEntry']));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.replaceEntryFailed(action.data['oldEntry'], response.error));
            },
          );
      }

      return n;
    };
  }

  removeEntry = (store) => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.REMOVE_ENTRY) {
        this.blacklistApi.removeEntry(action.data['entry']['field'], action.data['entry']['value'])
          .subscribe(
            (entry: BlacklistEntry) => {
              this.toastService.success(
                this.i18n('Entry deleted'),
                this.i18n('Blacklist entry was deleted.')
              );

              this.store.dispatch(this.blacklistActions.removeEntrySucceeded(entry));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.removeEntryFailed(action.data['entry'], response.error));
            },
          );
      }

      return n;
    };
  }

  loadInvitations = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.LOAD_INVITATIONS) {
        this.blacklistApi.invitations()
          .subscribe(
            (collection: BlacklistInvitationCollection) => {
              this.store.dispatch(this.blacklistActions.loadInvitationsSucceeded(collection));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.loadInvitationsFailed(response.error));
            },
          );
      }

      return n;
    };
  }

  share = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.SHARE) {
        this.blacklistApi.share(action.data['email'])
          .subscribe(
            (invitation: BlacklistInvitation) => {
              this.store.dispatch(this.blacklistActions.shareSucceeded(invitation));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.shareFailed(response.error));
            },
          );
      }

      return n;
    };
  }

  accpetInvitation = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.ACCPEPT_INVITATION) {
        this.blacklistApi.acceptInvitation(action.data['id'])
          .subscribe(
            (invitation: BlacklistInvitation) => {
              this.store.dispatch(this.blacklistActions.acceptInvitationSucceeded(invitation));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.acceptInvitationFailed(action.data['id'], response.error));
            },
          );
      }

      return n;
    };
  }

  removeInvitation = store => next => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === BlacklistActions.TYPES.REMOVE_INVITATION) {
        this.blacklistApi.removeInvitation(action.data['id'])
          .subscribe(
            () => {
              this.store.dispatch(this.blacklistActions.removeInvitationSucceeded(action.data['id']));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.removeInvitationFailed(action.data['id'], response.error));
            },
          );
      }

      return n;
    };
  }

  loadSettings = store => next => {
    return (action: StAction) => {
      if (action.type === BlacklistActions.TYPES.LOAD_SETTINGS) {
        this.blacklistApi.loadSettings()
          .subscribe(
            (settings: BlacklistSettings) => {
              this.store.dispatch(this.blacklistActions.loadSettingsSucceeded(settings));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.loadSettingsFailed(response.error));
            },
          );
      }

      return next(action);
    };
  }

  saveSettings = store => next => {
    return (action: StAction) => {
      if (action.type === BlacklistActions.TYPES.SAVE_SETTINGS) {
        this.blacklistApi.saveSettings(action.data.settings)
          .subscribe(
            (settings: BlacklistSettings) => {
              this.toastService.success(
                this.i18n('Settings updated'),
                this.i18n('Your blacklist settings were successfully updated.')
              );
              this.store.dispatch(this.blacklistActions.saveSettingsSucceeded(settings));
            },
            (response) => {
              this.store.dispatch(this.blacklistActions.saveSettingsFailed(response.error));
            },
          );
      }

      return next(action);
    };
  }
}
