<st-modal #modal="modal" [showInstructions]="false" [showOnlyBody]="true" [size]="'medium'">
  <div modal-body>
    <div class="box upgrade">
      <div class="box-header">
        <h2 *ngIf="title$ | async as title">
          <st-icon icon="red-info"></st-icon>
          <span>{{ title }}</span>
        </h2>
        <p *ngIf="message$ | async as message">{{ message }}</p>
      </div>

      <st-billing-plan-change
        class="no-right-border"
        (closeModal)="closeModal()"
        [currentPlans]="plans"
        [loading]="loadingPlans"
        [activeSubscription]="activeSubscription"
        [nextSubscription]="nextSubscription"
        [subscribingToPlan]="subscribingToPlan$ | async"
        [coupon]="nextSubscription?.couponObj">
      </st-billing-plan-change>
    </div>
  </div>
</st-modal>
