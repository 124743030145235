import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import {NgRedux, select} from "@angular-redux/store";
import { LayoutActions } from "@app/shared/layout/layout.actions";
import {ModalService} from "@app/shared/components/modals/modal.service";
import {AppUpdateComponent} from "@app/client/modals/app-update/app-update.component";

@Injectable()
export class PopupUpdateService {

  public lastUnreadAppUpdate: any;

  constructor(private ngRedux: NgRedux<AppState>, private layoutActions: LayoutActions, private modalService: ModalService) {
    this.ngRedux.select(['user', 'data', 'last_unread_app_update']).subscribe((lastUnreadAppUpdate: any) => {
      this.lastUnreadAppUpdate = lastUnreadAppUpdate;
    });
  }

  public show(): void {
    if(typeof this.lastUnreadAppUpdate !== "boolean") {
      this.modalService.open(AppUpdateComponent);
    }
  }
}
