import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { PayoutAllCommisionAdminActions } from "@app/shared/data/admin/payout-all-commisions/payout-commision.actions";
import { PayoutAllCommisionAdminAPI } from "@app/shared/data/admin/payout-all-commisions/payout-commision.api";
import { PayoutAllCommisionAdmin } from "@app/shared/data/admin/payout-all-commisions/payout-commision.model";

@Injectable()
export class PayoutAllCommisionAdminEpics {
  constructor(private commisionActions: PayoutAllCommisionAdminActions,
              private commisionAPI: PayoutAllCommisionAdminAPI,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PayoutAllCommisionAdminActions.getPagActionTypes(PayoutAllCommisionAdmin).LOAD_PAGE || action.type == PayoutAllCommisionAdminActions.TYPES.FILTER) {
        const params = {
          ...action.data.pagination,
          limit: action.data.pagination.perPage,
          sort : action.data.pagination.sort.join(',')
        };
        this.commisionAPI.all(params)
          .subscribe(
            (commisions: Collection<PayoutAllCommisionAdmin>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.commisionActions.setPage(commisions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.commisionActions.loadPageFailed(response));
            },
            () => {}
          );
      }
      return next(action);
    }
  };
}
