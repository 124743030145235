import { Component, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import { Observable } from 'rxjs';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector   : 'st-progress-dialog',
  templateUrl: './progress.component.html',
  styleUrls  : ['./progress.component.scss']
})
export class ProgressComponent {
  buttonText = this.i18n('Abort');
  title = this.i18n('Loading');
  message = this.i18n('Please wait...');
  value = '';
  progress$: Observable<number>;
  enableAbort = false;
  maxFakeProgress = 0;

  constructor(private activeModal: ActiveModal,
              private cd: ChangeDetectorRef,
              private i18n: I18n) {}

  close(): void {
    this.activeModal.close(false);
  }

  public setTitle(title: string) {
    this.title = title;
    this.cd.detectChanges();
  }

  public setAbortButtonText(buttonText: string) {
    this.buttonText = buttonText;
    this.cd.detectChanges();
  }

  public setEnableAbort(value: boolean) {
    this.enableAbort = value;
    this.cd.detectChanges();
  }

  public setMessage(message: string) {
    this.message = message;
    this.cd.detectChanges();
  }

  public setProgressObs(progress$: Observable<number>) {
    this.progress$ = progress$;
  }

  public setMaxFakeProgress(maxFakeProgress: number) {
    this.maxFakeProgress = maxFakeProgress;
  }
}
