
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { BaseModel, Collection } from '@app/shared/data/base.models';
import { BaseAPI } from '@app/shared/data/base.api';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';

@Injectable()
export class ApiTokenApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public allTokens(): Observable<Collection<ApiToken>> {
    return this.http.get(this.constructFullUrl('api/credentials/tokens'), { }).pipe(
      map(data => ApiToken.collect(<ApiToken>data, BaseModel.NO_PAGINATION)));
  }

  public all(credentialId, params = {}): Observable<Collection<ApiToken>> {
    return this.http.get(this.constructFullUrl('api/credentials/' + credentialId + '/tokens'), { params: params }).pipe(
      map(data => ApiToken.collect(<ApiToken>data, BaseModel.NO_PAGINATION)));
  }

  public getSingleToken(credentialId: number, obfuscatedTokenValue: string): Observable<ApiToken> {
    return this.http.get<ApiToken>(this.constructFullUrl(`api/credentials/${credentialId}/tokens/${obfuscatedTokenValue}`))
  }

  public revoke(credentialId, token: ApiToken, params = {}): Observable<ApiToken> {
    return this.http.delete(this.constructFullUrl('api/credentials/' + credentialId + '/tokens/' + token.value)).pipe(
      map(data => new ApiToken(data)));
  }

  public create(credentialId, testing: boolean, params = {}): Observable<ApiToken> {
    params['testing'] = testing;
    return this.http.put(this.constructFullUrl('api/credentials/' + credentialId + '/tokens'), params).pipe(
      map(data => new ApiToken(data)));
  }
}
