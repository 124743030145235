import { IMarketplace } from '../marketplaces';
import { ReverseAsin } from '../reverse-asin/reverse-asin.models';
import { arrow } from '@popperjs/core';

export interface IR2AHistorySearchItem {
  asins: string[];
  compare_asin?: string;
  depth: number;
  finished_at: string;
  id: string;
  info: any[];
  marketplace: IMarketplace;
  opened_at?: string;
  resolveChildren: boolean;
  started_ago: number;
  started_at: string;
  type: 'r2a';
}

export interface IRequestKeywordsResponse {
  progress?: number;
  step?: number;
  info?: {
    asin: string;
    children: string[];
    img: string;
    title: string;
  }[];
  keywords?: ReverseAsin[];
  stats?: ReverseAsinStats;
}

export interface AsinCompareStats {
  missing_advertising: number;
  not_rank_successful: number;
  not_tracked_not_organic: number;
  not_tracked_organic: number;
  total_opp: number;
  tracked_not_organic: number;
  tracked_organic: number;
}

export interface ReverseAsinStats {
  ba: number;
  organic: number;
  rc: number;
  sponsored: number;
  total: number;
}

export interface CompareAsinData {
  compare: AsinCompareStats;
  stats: ReverseAsinStats;
  children: any[];
  error: ErrorPayload;
}

export interface CompareAsinKeywordData {
  [key: string]: {
    r: boolean | number;
    t: string[];
  };
}

export interface ReverseAsinKeyword {
  model: {};
  sfr_rank: number | null;
  is_sponsored: boolean;
  keyword: string;
  results_count: number | null;
  search_volume: number | null;
  categories: string[];
  in_brand_analytics: boolean;
  in_report_card: boolean;
  amazons_choice_asins: string[];
  density_score: number;
  density_score_sponsored: number;
  density_score_amazons_choice: number;
  density_score_report_card: number;
  sponsored_asins: string[];
  density_asins: string[];
  found_in_amz_report_card: boolean;
  avg_rank: number | null;
  min_rank: number | null;
  min_rank_asin: string | null;
  myAsinRankSuccess: boolean | null;
  my_asin?: {
    is_tracked: boolean;
    is_sponsored: boolean;
    in_report_card: boolean;
    organic_rank: number;
    is_ranked: boolean;
    is_organically_ranked: boolean;
    is_ranked_successfully: boolean;
    is_missing_advertising: boolean;
    has_opportunity: boolean;
    __status_adding_to_tracked_list: boolean;
  };
  metrics?: {
    click_share: number;
    click_share_t360: number;
    niche_title: string;
    search_conversion_rate: number;
    search_volume_conversion_rate_t360: number;
    search_volume_growth_t360_yoy: number;
    search_volume_qoq: number;
    search_volume_t360: number;
    search_volume_t90: number;
    search_volume_yoy: number;
    top_clicked_products: R2AProduct[];
    updated_at: string;
  };
}

export interface R2AProduct {
  asin: string;
  title: string;
  image_url: string;
}

export enum ErrorCodesEnum {
  ERROR_CODE_RESOLVE_ASINS_FAILED = 'error.resolve_asins',
  ERROR_CODE_INVALID_INPUT = 'invalid_input',
  ERROR_CODE_TIMEOUT = 'error.search_timeout',
  ERROR_CODE_FORBIDDEN = 'forbidden',
  ERROR_CODE_LIMITS_REACHED = 'error.limits_reached',
}

export interface ErrorPayload {
  message: string;
  error_code: ErrorCodesEnum;
}

export interface InvalidInputPayload  extends ErrorPayload {
  validation_error: object;
}
