import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { PayoutAdmin } from "@app/shared/data/admin/payout-affiliate/payout-admin.models";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { PayoutAdminActions } from "@app/shared/data/admin/payout-affiliate/payout-admin.actions";


export type PayoutAdminFilters = {
  searchTerm: string,
  payment_date: string,
  commision: string,
};

const INITIAL_STATE: AppState = {
  data          : new Collection<PayoutAdmin>([]),
  loading       : false,
  _filters      : loadFilters(),
  _be_pagination: {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage('admin-payouts') || 50
  }
};

function loadFilters() {
  return LS.forActiveUser().getAdminPayoutsFilters()
    || {
      searchTerm  : "",
      payment_date: '*',
      commision   : '*'
    }
}

export function affiliatePayoutReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == PaginationActions.getPagActionTypes(PayoutAdmin).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-payouts');

    return {
      ...state,
      data          : state['data']['data'],
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      error         : null,
      loading       : true
    };
  }

  if (action.type == PayoutAdminActions.TYPES.FILTER) {
    LS.forActiveUser().setAdminPayoutsFilters(action.data);
    return {
      ...state,
      _filters: action.data,
      loading : true
    }
  }

  if (action.type == PayoutAdminActions.TYPES.PAY) {
    return {
      ...state,
      data   : state['data'].clone().map((pa: PayoutAdmin) => {
        if (pa.commision_id != action.data.commisionId)
          return pa;

        return pa.clone(PayoutAdmin, {
          is_paying: true
        });
      }),
      error  : null,
      loading: false
    };
  }

  if (action.type == PayoutAdminActions.TYPES.PAY_SUCCEEDED) {
    return {
      ...state,
      data : state['data'].clone().map((pa: PayoutAdmin) => {
        if (pa.commision_id != action.data.commissionId)
          return pa;

        return pa.clone(PayoutAdmin, {
          is_paying    : false,
          buttonEnabled: false
        });
      }),
      error: null,
    };
  }

  if (action.type == PayoutAdminActions.TYPES.PAY_FAILED) {
    console.log("Paying commision Failed With Error", action.data.error);

    return {
      ...state,
      data : state['data'].clone().map((pa: PayoutAdmin) => {
        if (pa.commision_id != action.data.commissionId)
          return pa;

        return pa.clone(PayoutAdmin, {
          is_paying: false
        });
      }),
      error: action.data.error,
    };
  }

  return paginationReducer(PayoutAdmin)(state, action);
}
