import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { Plan } from '@app/shared/data/plan/plan.models';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { CouponAPI } from '@app/shared/data/coupon/coupon.api';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { CreditCardAPI } from '@app/shared/data/credit-card/credit-card.api';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { User } from '@app/shared/data/user/user.models';

export interface IConfirmCardSetupResponse {
  setupIntent?: ISetupIntent;
  error?: IError;
}

export enum StatusEnum {
  ERROR = 'error',
  CANCELED = 'CANCELED',
  REQUIRES_ACTION = 'requires_action',
  SUCCEEDED = 'succeeded',
};
export interface ICollectAndConfirmBankAccountResponse {
  status: StatusEnum;
  setupIntent?: ISetupIntent;
  error?: IError;
}
export interface ISetupIntentResponse {
  setupIntent?: ISetupIntent;
  error?: IError;
}
export interface IConfirmCardPaymentResponse {
  paymentIntent?: IPaymentIntent;
  error?: IError;
}
export interface ISetupIntent {
  id: string;
  payment_method: string;
  status: string;
}

export interface IPaymentIntent {
  id: string;
}

export interface IError {
  type: string;
  code: string;
  decline_code: string;
  message: string;
  param: string;
}

@Injectable()
export class PaymentService {
  private stripeServices = {};
  private cardElement: any = null;

  constructor(private usersActions: UsersActions,
    private subscriptionActions: SubscriptionActions,
    private ngRedux: NgRedux<AppState>,
    private creditCardAPI: CreditCardAPI,
    private couponAPI: CouponAPI) {
  }

  private init(account: null | string) {
    let options = {};

    if (account !== null) {
      options['stripeAccount'] = account;
    }

    return (<any>window).Stripe(environment.STRIPE_PUBLISHABLE_KEY, options);
  }

  public getStripeService(account: null | string = null) {
    if (!Boolean(this.stripeServices[account])) {
      this.stripeServices[account] = this.init(account);
    }

    return this.stripeServices[account];
  }

  public generateCardToken(secret: string, data: any): Promise<IConfirmCardSetupResponse> {
    return this.getStripeService().confirmCardSetup(
      secret,
      {
        payment_method: {
          card: this.cardElement,
          billing_details: {
            ...data,
          }
        }
      }
    );
  }

  public getFormSecret(): Observable<string> {
    return this.creditCardAPI.create();
  }

  /**
   * @return {boolean}
   */
  private isAddingCreditCard(): boolean {
    return this.ngRedux.getState()['user']['_is_adding_credit_card'];
  }

  public addCreditCard(secret: string, cardholder: string): Promise<any> {
    if (!this.isAddingCreditCard()) {
      this.ngRedux.dispatch(this.usersActions.addCreditCardStarted());

      this.generateCardToken(secret, { name: cardholder }).then(
        (response: IConfirmCardSetupResponse) => {
          if (response.error) {
            this.ngRedux.dispatch(this.usersActions.addCreditCardFailed(response.error.message));
          } else {
            this.ngRedux.dispatch(this.usersActions.addCreditCard(response.setupIntent.payment_method));
          }
        }
      );
    }

    return new Promise((resolve, reject) => {
      this.ngRedux.select(['user', '_is_adding_credit_card'])
        .pipe(
          filter(loading => !loading),
          take(1)
        )
        .subscribe(() => {
          const error = this.ngRedux.getState()['user']['_error'];

          if (error) {
            reject(error);
          } else {
            resolve(true);
          }
        });
    });
  }

  /**
   * @return {boolean}
   */
  private isSubscribingToPlan(): boolean {
    return this.ngRedux.getState()['user']['_is_subscribing_to_plan'];
  }

  /**
   * Subscribe User To Selected Plan
   *
   * @param plan
   * @param coupon
   * @return {Promise<T>}
   */
  public subscribeToPlan(plan: Plan, coupon: Coupon = null): Promise<any> {
    if (!this.isSubscribingToPlan()) {
      //noinspection TypeScriptValidateTypes
      this.ngRedux.dispatch(this.subscriptionActions.create(plan, coupon));
    }

    return new Promise((resolve, reject) => {
      this.ngRedux.select(['user', '_is_subscribing_to_plan'])
        .pipe(
          filter(loading => !loading),
          take(1)
        )
        .subscribe(() => {
          const error = this.ngRedux.getState()['user']['_error'];
          if (error) {
            reject(error);
          } else {
            resolve(true);
          }
        });
    });
  }

  public validateCoupon(coupon: Coupon) {
    return new Promise((resolve, reject) => {
      this.couponAPI.get(coupon).subscribe((c: any) => {
        if (c.valid) {
          return resolve(c);
        }
        reject({
          message: 'Coupon code exists but is not valid anymore!'
        });
      }, e => {
        reject(e.error);
      });
    });
  }

  public confirmCardPayment(secret: string, paymentMethod: string, stripeAccount: null | string = null): Promise<IConfirmCardPaymentResponse> {
    return this
      .getStripeService(stripeAccount)
      .confirmCardPayment(secret, {
        payment_method: paymentMethod,
      });
  }

  public collectAndConfirmBankAccount(user: User, secret: string, stripeAccount: null | string = null): Promise<ICollectAndConfirmBankAccountResponse> {
    return new Promise((resolve) => {
      this
        .getStripeService(stripeAccount)
        .collectBankAccountForSetup(
          {
            clientSecret: secret,
            params: {
              payment_method_type: 'us_bank_account',
              payment_method_data: {
                billing_details: {
                  name: user.first_name + ' ' + user.last_name,
                  email: user.email,
                },
              },
            },
          }
        ).then((collectResp: ISetupIntentResponse) => {
          if (collectResp.error) {
            resolve({
              status: StatusEnum.ERROR,
              error: collectResp.error,
            });
          } else if (collectResp.setupIntent.status === 'requires_payment_method') {
            resolve({
              status: StatusEnum.CANCELED,
            });
          } else {
            this
              .getStripeService(stripeAccount)
              .confirmUsBankAccountSetup(secret)
              .then((confirmResp: ISetupIntentResponse) => {
                if (confirmResp.error) {
                  resolve({
                    status: StatusEnum.ERROR,
                    error: confirmResp.error,
                  });
                } else if (confirmResp.setupIntent.status === 'requires_action') {
                  resolve({
                    status: StatusEnum.REQUIRES_ACTION,
                    setupIntent: confirmResp.setupIntent,
                  });
                } else {
                  resolve({
                    status: StatusEnum.SUCCEEDED,
                    setupIntent: confirmResp.setupIntent,
                  });
                }
              })
          }
        })
    });
  }

  public initCardField(el: string) {
    const elements = this.getStripeService().elements();

    this.cardElement = elements.create('card', {
      hidePostalCode: true,
      style: {
        base: {
          iconColor: '#5CB4C9',
          color: '#173042',
          fontFamily: 'Lato, sans-serif',
          fontSize: '1rem',
          fontWeight: '500',
          padding: '10',
          height: '40px',
          '::placeholder': {
            color: '#C4C4C4',
          },
        },
        invalid: {
          iconColor: '#F26563',
          color: '#F26563',
        }
      }
    });

    this.cardElement.mount(el);
  }
}
