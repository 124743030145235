import { StAction } from '../st-action';
import { RequestReviewActions } from './review-request.actions';
import { IReviewRequestState, IReviewRequestGraphResponse, IReviewRequestSettings, IReviewRequestProductsResponse } from './review-request.models';

const INITIAL_STATE: IReviewRequestState = {
  overallSettings: null,
  loadingSettings: false,
  productsState: null,
  loadingProducts: false,
  sentRequestsGraph: null,
  reviewGrowthGraph: null,
  loadingSentRequestsGraph: false,
  loadingReviewGrowthGraph: false,
  settingsUpdated: false,
}

export function reviewRequestReducer(state: IReviewRequestState = INITIAL_STATE, action: StAction): IReviewRequestState {
  switch(action.type) {
    case RequestReviewActions.TYPES.LOAD_SETTINGS:
      return {
        ...state,
        loadingSettings: true,
      };

    case RequestReviewActions.TYPES.SETTINGS_LOADED:
      return {
        ...state,
        overallSettings: action.data,
        loadingSettings: false,
      };

    case RequestReviewActions.TYPES.SETTINGS_LOADED_ERROR:
      return {
        ...state,
        loadingSettings: false
      };

    case RequestReviewActions.TYPES.UPDATE_SETTINGS:
      return {
        ...state,
        loadingSettings: true,
      };

    case RequestReviewActions.TYPES.UPDATE_SETTINGS_SUCCESS:
      return {
        ...state,
        loadingSettings: false,
        overallSettings: action.data
      };

    case RequestReviewActions.TYPES.UPDATE_SETTINGS_ERROR:
      return {
        ...state,
        loadingSettings: false
      };

    case RequestReviewActions.TYPES.GET_PRODUCTS:
      return {
        ...state,
        loadingProducts: true,
      };

    case RequestReviewActions.TYPES.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsState: handleGetProductsSuccess(action.data),
        loadingProducts: false,
      };

    case RequestReviewActions.TYPES.GET_PRODUCTS_ERROR:
      return {
        ...state,
        loadingProducts: false,
      };

    case RequestReviewActions.TYPES.GET_SENT_REQUESTS_GRAPH:
      return {
        ...state,
        loadingSentRequestsGraph: true,
      };

    case RequestReviewActions.TYPES.GET_REVIEW_GROWTH_GRAPH:
      return {
        ...state,
        loadingReviewGrowthGraph: true,
      };

    case RequestReviewActions.TYPES.GET_SENT_REQUESTS_GRAPH_SUCCESS:
      return {
        ...state,
        sentRequestsGraph: mapGraphData(action.data.graphData),
        loadingSentRequestsGraph: false,
      };

    case RequestReviewActions.TYPES.GET_REVIEW_GROWTH_GRAPH_SUCCESS:
      return {
        ...state,
        reviewGrowthGraph: mapGraphData(action.data.graphData),
        loadingReviewGrowthGraph: false,
      };

    case RequestReviewActions.TYPES.GET_SENT_REQUESTS_GRAPH_ERROR:
      return {
        ...state,
        loadingSentRequestsGraph: false,
      };
      
    case RequestReviewActions.TYPES.GET_REVIEW_GROWTH_GRAPH_ERROR:
      return {
        ...state,
        loadingReviewGrowthGraph: false,
      };

    case RequestReviewActions.TYPES.SETTINGS_UPDATED:
      return {
        ...state,
        settingsUpdated: true,
      };

    case RequestReviewActions.TYPES.RESET_SETTINGS_UPDATED:
      return {
        ...state,
        settingsUpdated: false,
      };

    default:
      return state;
  }
}

function mapGraphData(data: IReviewRequestGraphResponse[]): number[][] {
  return data.map((data: IReviewRequestGraphResponse) => {
    return [new Date(data.date).getTime(), data.value];
  });
}

function handleGetProductsSuccess(data: IReviewRequestProductsResponse): IReviewRequestProductsResponse {
  return {
    ...data,
    from: data.from || 0,
    to: data.to || 0,
  }
}
