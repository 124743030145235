import { NgRedux } from "@angular-redux/store";
import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ModalComponent } from "@app/shared/components/modals/modal/modal.component";
import { AppState } from "@app/shared/data/app-state.model";
import { ProductManagerActions } from "@app/shared/data/product-manager/product-manager.actions";
import { IProductManagerState } from "@app/shared/data/product-manager/product-manager.models";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { isAsin } from '@app/shared/validators/is-asin';

let window: any;

export interface PastedAsin {
  value: string;
  valid: boolean;
}

@Component({
  templateUrl: './product-list-add-products-manually-modal.component.html',
  styleUrls: ['product-list-add-products-manually-modal.component.scss'],
})
export class ProductManagerAddProductsManuallyComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;
  pastedAsins: PastedAsin[] = [{
    value: null,
    valid: false,
  }];

  addingProducts: boolean;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private productManagerActions: ProductManagerActions,
    private store: NgRedux<AppState>,
  ) { }

  ngOnInit() {
    this.store.select('product_manager')
      .pipe(takeUntil(this.destroy$))
      .subscribe((productManagerState: IProductManagerState) => {
        if (productManagerState.productsState.closeAddProductsModal) {
          this.close();
        }
      });
  }

  onPaste(event: ClipboardEvent, index: number) {
    let clipboardData = event.clipboardData || window.clipboardData;
    let pastedText = clipboardData.getData('text');
    
    this.pastedAsins = this.pastedAsins.map((asin: PastedAsin, internalIndex: number) => {
      if (index === internalIndex) {
        return {
          value: pastedText,
          valid: isAsin(pastedText),
        };
      }

      return asin;
    });
  }

  onAsinChange(event, index: number) {
    const value = event.target.value;
    if (this.pastedAsins[index]) {
      this.pastedAsins[index] = {
        value,
        valid: isAsin(value),
      };
    }
  }

  addNewAsin() {
    if (this.addingProducts) {
      return;
    }

    this.pastedAsins = [
      ...this.pastedAsins,
      { value: null, valid: false }
    ];
  }

  close() {
    this.modal.close();
  }

  addAsins() {
    const areAllAsinsValid = this.areAllAsinsValid;

    if (!areAllAsinsValid) {
      return;
    }

    // Only send unique values, remove duplicates
    this.store.dispatch(this.productManagerActions.addForeignProducts(this.pastedAsins));
    this.addingProducts = true;
  }

  ngOnDestroy() {
    this.store.dispatch(this.productManagerActions.addForeignProductsSuccess());
    this.destroy$.next();
    this.destroy$.complete();
  }

  isAsinNotValid(asin: string): boolean {
    return !isAsin(asin);
  }

  get areAllAsinsValid(): boolean {
    return this.pastedAsins.every((asin: PastedAsin) => asin.valid);
  }
}
