import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { ModalService } from "@app/shared/components/modals/modal.service";
import { AdvancedFiltersModalComponent, IAvailableFilter, IFilter } from "./advanced-filters-modal/advanced-filters-modal.component";
@Component({
  selector: 'st-advanced-filters',
  templateUrl: 'advanced-filters.component.html',
  styleUrls: ['advanced-filters.component.scss']
})
export class AdvancedFiltersComponent {
  _selectedFilters: IFilter[] = [];
  @Input() set selectedFilters(filters: IFilter[]) {
    if(!filters) {
      return
    }
    if(this.modalInstance)
    {
      this.modalInstance.setSelectedFilters(filters);
    }
    this._selectedFilters = filters;
  }

  _availableFilters: IAvailableFilter[];
  @Input() set availableFilters(value: IAvailableFilter[]) {
    if(!value) {
      return
    }
    if(this.modalInstance)
    {
      this.modalInstance.setAvailableFilters(value);
      this.modalInstance.additionalFiltersTitleIndex = this._availableFilters.length;
    }
    this._availableFilters = value;
  }

  _additionalFilters: IAvailableFilter[];
  @Input() set additionalFilters(value: IAvailableFilter[]) {
    if(!value) {
      return
    }
    if(this.modalInstance)
    {
      this.modalInstance.setAdditionalFilters(value);
    }
    this._additionalFilters = value;
  }

  @Input() advancedFiltersTitle: string = '';
  @Input() additionalFiltersTitle: string = '';

  _loading: boolean = false;
  @Input() set loading(value: boolean) {
    if(this.modalInstance)
    {
      this.modalInstance.loading = value || false;
    }
    this._loading = value;
  }

  @Input() isDisabled: boolean = false;
  @Input() columns: number = 1;

  filtersOpened: boolean;
  modalInstance: any;

  @Output() filtersApplied: EventEmitter<IFilter[]> = new EventEmitter();
  @Output() opened: EventEmitter<void> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef, private modalService: ModalService,) { }

  applyFilters(filters: IFilter[]) {
    if(!filters) {
      return;
    }
    this.filtersApplied.emit(filters);
  }

  openFilters() {
    if (this.isDisabled) {
      return;
    }

    this.modalInstance = this.modalService.open(AdvancedFiltersModalComponent).instance;
    this.modalInstance.setAvailableFilters(this._availableFilters);
    this.modalInstance.setAdditionalFilters(this._additionalFilters);
    this.modalInstance.setSelectedFilters(this._selectedFilters)
    this.modalInstance.advancedFiltersTitle = this.advancedFiltersTitle;
    this.modalInstance.additionalFiltersTitle = this.additionalFiltersTitle;
    this.modalInstance.additionalFiltersTitleIndex = this._availableFilters.length;
  
    this.modalInstance.loading = this._loading;
    this.modalInstance.columns = this.columns;
    this.modalInstance.filtersApplied.subscribe(filters => this.applyFilters(filters))

    if (this.filtersOpened) {
      this.opened.emit();
    }
  }

  closeFilters() {
    this.modalInstance?.close();
  }

  get selectedFiltersLength(): number {
    return this._selectedFilters.length;
  }
}
