import { Injectable } from '@angular/core';
import { ScriptData, ScriptStore } from './script-store.service';

declare var document: any;

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {

  private scripts: any = {};

  constructor() {
    ScriptStore.forEach((script: ScriptData) => {
      this.scripts[script.name] = { ...script, loaded: false };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  loadScript(name: string) {
    return new Promise((resolve, reject) => {
      const scriptData = this.scripts[name];
      if (scriptData.loaded) {
        if (scriptData.reload) {
          this.removeScript(name, scriptData.unload_scripts, scriptData.unload_variables);
          this.addScript(name, resolve);
        } else {
          resolve({script: name, loaded: true, status: 'Already Loaded'});
        }
      } else {
        this.addScript(name, resolve);
      }
    });
  }

  addScript(name: string, resolve: (value?: {} | PromiseLike<{}>) => void) {
    if (document.getElementById('script-' + name)) {
      resolve({script: name, loaded: true, status: 'Already Loaded'});
      return;
    }

    const script = document.createElement('script');
    script.id = 'script-' + name;
    script.type = 'text/javascript';
    script.src = this.scripts[name].src;
    if (script.readyState) {  // IE
      script.onreadystatechange = () => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          this.scripts[name].loaded = true;
          resolve({script: name, loaded: true, status: 'Loaded'});
        }
      };
    } else {  // Others
      script.onload = () => {
        this.scripts[name].loaded = true;
        resolve({script: name, loaded: true, status: 'Loaded'});
      };
    }
    script.onerror = (error: any) => resolve({script: name, loaded: false, status: 'Loaded'});
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  removeScript(name: string, unload_scripts: string[], unload_variables: string[]) {
    this.scripts[name].loaded = false;
    const script = document.getElementById('script-' + name);
    if (!script) {
      return;
    }

    script.remove();
    unload_scripts.forEach((id: string) => {
      document.getElementById(id).remove();
    });
    unload_variables.forEach((variable: string) => {
      window[variable] = undefined;
    });
  }
}
