import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { SystemNotificationAdminActions } from "@app/shared/data/admin/system-notification/system-notification.actions";
import { SystemNotificationAdminAPI } from "@app/shared/data/admin/system-notification/system-notification.api";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { SystemNotificationAdmin } from "@app/shared/data/admin/system-notification/system-notification.models";
import { Collection } from "@app/shared/data/base.models";

@Injectable()
export class SystemNotificationAdminEpics {
  constructor(private systemNotificationAdminActions: SystemNotificationAdminActions,
              private store: NgRedux<AppState>,
              private systemNotificationAdminAPI: SystemNotificationAdminAPI) {}

  public createEpic() {
    return [
      this.loadPage,
      this.save,
      this.remove,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      let n = next(action);
      if (action.type == PaginationActions.getPagActionTypes(SystemNotificationAdmin).LOAD_PAGE || action.type == SystemNotificationAdminActions.TYPES.FILTER) {
        let state = store.getState()['admin']['system_notifications'];
        let params = { ...state['_be_pagination'], ...state['_filters'] };

        this.systemNotificationAdminAPI.all(params)
          .subscribe(
            (notifications: Collection<SystemNotificationAdmin>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.setPage(notifications));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.loadPageFailed(response));
            },
            () => {}
          );
      }

      return n;
    }
  };

  save = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == SystemNotificationAdminActions.TYPES.SAVE) {
        this.systemNotificationAdminAPI.save(action.data.notification)
          .subscribe(
            (notification: SystemNotificationAdmin) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.filter(this.store.getState()['admin']['system_notifications']['_filters']));

              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.saveSucceeded(notification));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.saveFailed(response.error));
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  remove = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == SystemNotificationAdminActions.TYPES.REMOVE) {
        this.systemNotificationAdminAPI.remove(action.data.notification.id)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.filter(this.store.getState()['admin']['system_notifications']['_filters']));

              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.removeSucceeded());
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemNotificationAdminActions.removeFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    }
  };
}
