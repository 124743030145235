import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { AdminCoupon } from "@app/shared/data/admin/coupon/coupon.models";
import { AdminCouponActions } from "@app/shared/data/admin/coupon/coupon.actions";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";

const INITIAL_STATE: AppState = {
  data    : new Collection<AdminCoupon>([]),
  filter  : "",
  loading : false,
  _creating: false,
  _removing: false,
  _error  : null,
  _be_pagination   : {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage('admin-coupons') || 50
  }
};

export function adminCouponReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == PaginationActions.getPagActionTypes(AdminCoupon).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-coupons');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == AdminCouponActions.TYPES.LOAD_COUPONS_SUCCEEDED) {
    return {
      ...state,
      data   : action.data,
      loading: false,
      _error : null,
    }
  }

  if (action.type == AdminCouponActions.TYPES.LOAD_COUPONS_FAILED) {
    return {
      ...state,
      loading: false,
      _error : action.data,
    }
  }

  if (action.type == AdminCouponActions.TYPES.CREATE) {
    return {
      ...state,
      _creating: true,
      _error  : null
    }
  }

  if (action.type == AdminCouponActions.TYPES.CREATE_SUCCEEDED) {
    return {
      ...state,
      data    : state['data'].push(action.data),
      _creating: false,
      _error  : ''
    }
  }

  if (action.type == AdminCouponActions.TYPES.CREATE_FAILED) {
    return {
      ...state,
      _creating: false,
      _error  : action.data
    }
  }

  if (action.type == AdminCouponActions.TYPES.REMOVE) {
    return {
      ...state,
      _removing: true,
      _error  : null
    }
  }

  if (action.type == AdminCouponActions.TYPES.REMOVE_SUCCEEDED) {
    return {
      ...state,
      data    : state['data'].filter(c => c.code != action.data.code),
      _removing: false,
      _error  : null
    }
  }

  if (action.type == AdminCouponActions.TYPES.REMOVE_FAILED) {
    return {
      ...state,
      _removing: false,
      _error  : action.data
    }
  }

  if (action.type == AdminCouponActions.TYPES.SEARCH) {
    return {
      ...state,
      searchTerm  :   action.data
    }
  }

  return paginationReducer(AdminCoupon)(state, action);
}
