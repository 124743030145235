
import {take} from 'rxjs/operators';
import { Directive, EventEmitter, Host, Input, OnInit, Output } from '@angular/core';
import { AxisDirective } from '@app/shared/components/charts/directives/axis.directive';
import { HighchartsService } from '@app/shared/components/charts/highcharts.service';
import { SeriesModel } from '@app/shared/components/charts/models/series.model';
import { ChartEvent } from "@app/shared/components/charts/models/chart.events";

@Directive({
  selector: 'line',
})
export class LineDirective implements OnInit {
  @Output() click = new EventEmitter<ChartEvent>();
  @Output() afterAnimate = new EventEmitter<ChartEvent>();
  @Output() checkboxClick = new EventEmitter<ChartEvent>();
  @Output() hide = new EventEmitter<ChartEvent>();
  @Output() legendItemClick = new EventEmitter<ChartEvent>();
  @Output() mouseOut = new EventEmitter<ChartEvent>();
  @Output() mouseOver = new EventEmitter<ChartEvent>();
  @Output() show = new EventEmitter<ChartEvent>();

  @Input('data')
  set data(data: any[]) {
    this._options.setData(data);
  };

  @Input()
  set name(name: string) {
    this._options.setName(name);
  }

  @Input()
  set options(options: any) {
    this._setOption(options);
  }

  @Input()
  set color(color: string) {
    this._options.setColor(color);
  }

  @Input()
  set step(position: 'left' | 'center' | 'right') {
    this._options.setStep(position);
  }

  private _options = new SeriesModel();

  constructor(@Host() private axis: AxisDirective, private _highchartsService: HighchartsService) { }

  private _setOption(opt) {
    this._options = Object.assign(this._options, opt);
  }

  ngOnInit(): void {
    this.axis.ID.pipe(
      take(1))
      .subscribe((id: number) => {
        this._options.setYAxis(id);
        this._highchartsService.addSeries(this.getOptions());
      });
  }

  getOptions(): SeriesModel {
    this._options.listenForEvents(this);
    return this._options;
  }

}
