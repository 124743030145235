import { Injectable } from "@angular/core";

@Injectable()
export class LsTaskActions {
  static readonly TYPES = {
    LIST           : '[LS_TASK]LIST',
    LIST_LOADED    : '[LS_TASK]LIST_LOADED',
    LOAD           : '[LS_TASK]LOAD',
    LOADED         : '[LS_TASK]LOADED',
    OPEN           : '[LS_TASK]OPEN',
    REMOVE         : '[LS_TASK]REMOVE'
  };

  listSearches() {
    return {
      type: LsTaskActions.TYPES.LIST,
    }
  }

  listSearchesLoaded(data) {
    return {
      type: LsTaskActions.TYPES.LIST_LOADED,
      data: {list: data}
    }
  }

  loadSearch(id) {
    return {
      type: LsTaskActions.TYPES.LOAD,
      data: {id}
    }
  }

  loadSearchLoaded(data) {
    return {
      type: LsTaskActions.TYPES.LOADED,
      data: {task: data}
    }
  }

  openSearch(id, keyword, marketplace, load = true) {
    return {
      type: LsTaskActions.TYPES.OPEN,
      data: {
        id,
        keyword,
        marketplace,
        load
      }
    }
  }

  removeSearch(id) {
    return {
      type: LsTaskActions.TYPES.REMOVE,
      data: {id}
    }
  }
}
