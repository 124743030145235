import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { LengthAwarePaginationCollection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';

const INITIAL_STATE = {
  data: new LengthAwarePaginationCollection<any>([]),
  _data: new LengthAwarePaginationCollection<any>([]),
  _loadPageError: false,
  loading: false
};

export function paginationReducer(model: { className: string }, modelTransformer: (data: any) => any = null) {
  return (state: AppState = INITIAL_STATE, action: StAction) => {
    if (action.type === PaginationActions.getPagActionTypes(model).SET_DATA) {
      return {
        ...state,
        _data: modelTransformer ? action.data.map(modelTransformer) : action.data
      };
    }

    if (action.type === PaginationActions.getPagActionTypes(model).APPEND_DATA) {
      return {
        ...state,
        _data: (state as any)._data.merge(modelTransformer ? modelTransformer(action.data) : action.data)
      };
    }

    if (action.type === PaginationActions.getPagActionTypes(model).INIT_DATA) {
      return {
        ...state,
        _loadPageError: false,
        _data: null
      };
    }

    if (action.type === PaginationActions.getPagActionTypes(model).LOAD_PAGE) {
      return {
        ...state,
        data: new LengthAwarePaginationCollection<any>([]),
        _pagination: {
          ...state['_pagination'],
          ...action.data.pagination
        },
        _loadPageError: false,
        loading: true
      };
    }

    if (action.type === PaginationActions.getPagActionTypes(model).SET_PAGE) {
      return {
        ...state,
        data: action.data,
        loading: false
      };
    }

    if (action.type === PaginationActions.getPagActionTypes(model).LOAD_PAGE_FAILED) {
      return {
        ...state,
        data: new LengthAwarePaginationCollection<any>([]),
        loading: false,
        _loadPageError: action.data.error
      };
    }
    return state;
  };
}
