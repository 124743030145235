import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Wallet } from './wallet.models';
import { WalletActions } from './wallet.actions';

export interface WalletState extends AppState {
  data: Wallet;
  loading: boolean;
  saving: boolean;
  error: any;
  verified: boolean;
}

const INITIAL_STATE: WalletState = {
  data: new Wallet(),
  loading: true,
  saving: false,
  error: null,
  verified: true,
};

export function walletReducer(state: WalletState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === WalletActions.TYPES.LOAD) {
    return {
      ...state,
      data: new Wallet(),
      loading: true,
      error: null,
      verified: true,
    };
  }

  if (action.type === WalletActions.TYPES.LOAD_SUCCEEDED) {
    return {
      ...state,
      data: action.data.wallet,
      loading: false,
      error: null,
      verified: true
    };
  }

  if (action.type === WalletActions.TYPES.LOAD_FAILED) {
    return {
      ...state,
      data: new Wallet(),
      loading: false,
      error: action.data.error,
      verified: action.data.error.error && action.data.error.error.error_code !== 'forbidden',
    };
  }

  return state;
}
