import { environment } from '@env/environment';
import * as rudderanalytics from 'rudder-sdk-js';
import { User } from '../data/user/user.models';
import { LS } from '@app/shared/services/local-storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RudderTrackingService {
  rudderAnalyticsReady = false;

  public init() {
    if (!environment.production || !environment.RUDDER_STACK_KEY || !environment.RUDDER_STACK_URL) {
      return;
    }

    let opts = null;

    // if RUDDER_STACK_CONFIG_URL is not set, the default `api.rudderlabs.com` URL will be used
    if (environment.RUDDER_STACK_CONFIG_URL) {
      opts = {configUrl: environment.RUDDER_STACK_CONFIG_URL};
    }

    rudderanalytics.load(environment.RUDDER_STACK_KEY, environment.RUDDER_STACK_URL, opts);
    rudderanalytics.ready(() => {
      this.rudderAnalyticsReady = true;
    });
  }

  public identify(user: User) {
    const {
      email,
      id,
      stripe_id
    } = user;

    rudderanalytics.identify(
      id.toString(),
      {
        email,
        stripe_id,
      },
      this.context,
    );
  }

  public alias(user: User) {
    rudderanalytics.alias(user.id.toString());
  }

  public pageView(pageName: string = null, properties: object = {}) {
    if (this.canTrackEvent) {
      rudderanalytics.page(null, pageName, properties, this.context);
    }
  }

  public trackEvent(eventName: string, eventData: { [key: string]: any } = {}) {
    if (this.canTrackEvent) {
      rudderanalytics.track(eventName, eventData, this.context);
    }
  }

  private get context() {
    let context: any = {
      userAgent: navigator.userAgent
    };

    const user = LS.getUser();

    if (user && LS.hasUserValidUtmTrackingTags()) {
      context = {
        ...context,
        campaign: {
          name: user.utm_campaign,
          mediums: user.utm_medium,
          term: user.utm_term,
          content: user.utm_content,
          source: user.utm_source
        },
      };
    }

    return context;
  }

  private get canTrackEvent(): boolean {
    return this.rudderAnalyticsReady;
  }
}
