import { NgRedux } from "@angular-redux/store";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { ProductManagerActions } from "@app/shared/data/product-manager/product-manager.actions";
import { ICheckboxChanged } from "@app/shared/layout/v2/forms/checkbox/checkbox.component";
import { Subject } from "rxjs";
import {
  SortByEnum,
  V2TableColumn,
  V2TableColumnTopHeader,
  V2TableColumnType,
  V2TableDataItem,
  V2TableDataItemType,
  V2TableDataRecord,
} from "./table.models";

@Component({
  selector: 'st-table-v2',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, OnDestroy {
  areAllItemsChecked: boolean;
  sortByEnum = SortByEnum;
  currentSortBy: SortByEnum;
  columnTypes = V2TableColumnType;
  itemTypes = V2TableDataItemType;

  loadingItemContent: boolean;

  hasFixedColumns: boolean;

  @Input() tableId?;
  @Input() columns: V2TableColumn[];
  @Input() columnsTopHeader: V2TableColumnTopHeader[];
  @Input() data: V2TableDataItem[];
  @Input() loadingItems: boolean;
  @Input() columnOpenedTopOffset = 231;
  @Input() isFilterActive: boolean;
  @Input() activePriorityFilter: string;
  @Input() currentSortDirection: string;
  @Input() showResizableIcon = false;
  @Input() showActionsColumn = true;

  @Output() onGetBrandAnalytics: EventEmitter<any> = new EventEmitter();
  @Output() onToggleKeywordDetails: EventEmitter<any> = new EventEmitter();
  @Output() onPriorityChange: EventEmitter<any> = new EventEmitter();
  @Output() onIndexRefresh: EventEmitter<any> = new EventEmitter();
  @Output() onFilterByPriority: EventEmitter<any> = new EventEmitter();
  @Output() onSortBy: EventEmitter<any> = new EventEmitter();
  @Output() onButtonClicked: EventEmitter<any> = new EventEmitter();
  @Output() onStatusChange: EventEmitter<any> = new EventEmitter();
  @Output() onAction: EventEmitter<any> = new EventEmitter();
  @Output() onSingleKeywordTrack: EventEmitter<{
    keyword: string,
    disabled: boolean,
    tracked: boolean
  }> = new EventEmitter();
  @Output() onAllKeywordsTrack: EventEmitter<{ trackAll: boolean }> = new EventEmitter();

  columnsWidened: boolean;

  private $destroy: Subject<void> = new Subject();

  constructor(
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
  ) { }

  ngOnInit() {
    this.checkForResizableColumns();
    this.hasFixedColumns = this.columns?.some(c => c.isFixed);
    this.setBorders();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.columnsTopHeader) {
      this.setBorders();
    }
  }

  /**
   * This function checks if user already expanded the columns
   * so he always has the latest state
   */
  checkForResizableColumns() {
    const expanded = localStorage.getItem(`tableColumnsExpanded-${this.tableId}`) === 'true';

    if (expanded) {
      this.columnsWidened = true;
    }
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }

  setBorders() {
    setTimeout(() => {
      let myAsinColumn = document.querySelector('.table-top-header__column--MY_ASIN');
      if (myAsinColumn) {
        const container = document.getElementsByClassName('table-scroll').item(0);
        myAsinColumn['style'].setProperty('--height', container.clientHeight.toString() + 'px');
      }
    },0);
  }

  resizeFirstColumn() {
    this.columnsWidened = !this.columnsWidened;

    localStorage.setItem(`tableColumnsExpanded-${this.tableId}`, `${this.columnsWidened}`);
  }

  onRefresh() {
    this.onIndexRefresh.emit();
  }

  filterByPriority(priority: string) {
    this.onFilterByPriority.emit(priority);
  }

  toggleAll(event: ICheckboxChanged) {
    this.areAllItemsChecked = !this.areAllItemsChecked;

    this.ngRedux.dispatch(this.productManagerActions.toggleAllProducts(event.checked));
  }

  toggleItem(event: ICheckboxChanged, item: any) {
    this.ngRedux.dispatch(this.productManagerActions.toggleSingleTableItem(item.value, event.checked));
  }

  getColumnClassName(column: V2TableColumn): string | null {
    let columnBaseName = 'table-header__column';

    if (column.isFixed) {
      columnBaseName = `${columnBaseName}--fixed ${columnBaseName}`;
    }

    if (typeof column.title !== 'string') {
      return columnBaseName;
    }

    const appendClass = column.id ? column.id : column.title;

    return `${columnBaseName} ${columnBaseName}--${appendClass.toLowerCase().split(/[\s]+/).join('-')}`;
  }

  getFixedColumnsTopOffset(item: any, index: number, column?: V2TableDataRecord): string {
    if (column && !column.isFixed) {
      return '';
    }

    let offset = 0;
    if (!item.graphOpened) {
      return null;
    } else if (item.loadingItemContent) {
      offset = 50 * (index + 1);
    } else {
      offset = this.columnOpenedTopOffset;
    }

    return `translateY(-${Math.floor(offset)}px)`;
  }

  preventDefault(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  getTableItemColumnClassName(column: V2TableDataRecord): string {
    if (column.type === this.itemTypes.GRAPH_DATA) {
      return 'table-list__product-graph';
    }

    return `table__column table__column--${column.id ? column.id : column.type}`;
  }

  sortBy(sortBy: SortByEnum) {
    this.currentSortBy = sortBy;
    this.onSortBy.emit(sortBy);
  }

  trackKeywords(event: { trackAll: boolean }) {
    this.onAllKeywordsTrack.emit(event);
  }

  trackSingleKeyword(event: {
    keyword: string,
    disabled: boolean,
    tracked: boolean
  }) {
    const { keyword, disabled, tracked } = event;

    this.onSingleKeywordTrack.emit({
      keyword,
      disabled,
      tracked,
    });
  }

  get checkedItems() {
    return this.data.filter(i => i.checked);
  }

  get gridTemplateColumns(): string {
    return `repeat(${this.columns?.length - (this.columns?.filter(c => c.isFixed || c.hide)?.length || 0)}, 1fr)`;
  }

  get gridTemplateColumnsTopHeader(): string {
    return this.columnsTopHeader.filter(c => !c.isFixed && c.numberOfColumns).map(c => `${c.numberOfColumns}fr`).join(' ');
  }

  get scrollMinWidth(): string {
    let columns = 0;
    this.columnsTopHeader?.filter(c => !c.isFixed).forEach(c => columns += c.numberOfColumns);
    return `${columns * 150}px`;
  }

  getColumnTopHeaderClassName(column: V2TableColumnTopHeader): string {
    return `table-top-header__column table-top-header__column--${column.id}`;
  }
}
