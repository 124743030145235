import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { ApiKeysRoutingModule } from '@app/client/api-keys/api-keys.routing';
import { DatatableModule } from '@app/shared/components/datatable/datatable.module';
import { ApiKeysComponent } from '@app/client/api-keys/api-keys.component';
import { AccessAcademyModule } from '@app/shared/layout/access-academy/access-academy.module';
import { ApiKeysTableComponent } from './api-keys-table/api-keys-table.component';
import { LineLoaderModule } from '@app/shared/layout/line-loader/line-loader.module';
import { ApiKeysWarningModalComponent } from './api-keys-warning-modal/api-keys-warning-modal.component';
import { ApiKeysRotateWarningModalComponent } from './api-keys-rotate-warning-modal/api-keys-rotate-warning-modal.component';
@NgModule({
  imports: [
    SharedModule,
    ApiKeysRoutingModule,
    DatatableModule,
    AccessAcademyModule,
    LineLoaderModule,
  ],
  exports: [],
  declarations: [
    ApiKeysComponent,
    ApiKeysTableComponent,
    ApiKeysWarningModalComponent,
    ApiKeysRotateWarningModalComponent,
  ],
  providers: [],
})
export class ApiKeysModule {
}
