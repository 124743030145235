import { MediumContentEditor } from "./medium-editor.component";
import { NgModule } from '@angular/core';

@NgModule({
  imports: [
  ],
  declarations: [
    MediumContentEditor
  ],
  exports: [
    MediumContentEditor
  ]
})
export class MediumEditorModule {}
