import { BaseModel } from "@app/shared/data/base.models";

export class PayoutAdmin extends BaseModel {
  static className = 'PayoutAdmin';
  user_id: number;
  commision_id: number;
  first_name: string;
  last_name: string;
  email: string;
  paypal_email: string;
  scheduled_for: string;
  commisions: number;
  is_paying: boolean;
}
