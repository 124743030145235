import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPI } from '../base.api';
import {
  ICheckPhoneNumberResponse,
  ICustomerListResponse,
  IAddCustomerResponse,
  ICustomer,
  ISaveFilterResponse,
  ISavedFiltersListResponse,
  ILoadAvailableFiltersResponse,
  IImportManyChatCustomersResponse,
  ILoadCustomerOrderItemsSuccessResponse,
  ImportSourceEnum
} from './customer-list.models';

import { HttpParameterCodec } from '@angular/common/http';
import { IFilter } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { map } from 'rxjs/operators';

export class CustomURLEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }
  encodeValue(key: string): string {
    return encodeURIComponent(key);
  }
  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }
  decodeValue(key: string) {
    return decodeURIComponent(key);
  }
}

export class FiltersEncoder {
  encode(filters: IFilter[]): string {
    return btoa(
      JSON.stringify(
        filters.map(val => [val.field, val.operator, val.value])
      )
    );
  }
}

@Injectable()
export class CustomerListAPI extends BaseAPI {
  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  getCustomerList(page: number, limit: number, filters: IFilter[], credentialId: null|number, sort: string): Observable<ICustomerListResponse> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder() })
      .set('page', page.toString())
      .set('limit', limit.toString());

    if (sort !== 'none') {
      params = params.set('sort', sort);
    }

    if (filters.length > 0) {
      let filtersEncoder = new FiltersEncoder();
      params = params.set('query', filtersEncoder.encode(filters));
    }

    if (credentialId !== null) {
      params = params.set('credential_id', credentialId.toString());
    }

    return this.http.get<ICustomerListResponse>(this.constructFullUrl('api/customer-list/customers'), { params });
  }

  checkPublicPhoneScore(phoneNumber: string): Observable<ICheckPhoneNumberResponse> {
    let params: HttpParams = new HttpParams({encoder: new CustomURLEncoder() })
      .set('phone_number', phoneNumber);

    return this.http.get<ICheckPhoneNumberResponse>(this.constructFullUrl('api/data/api/v1/customer-list/phone-number-score'), { params });
  }

  checkMissingPhoneScores(): Observable<any> {
    return this.http.post(this.constructFullUrl('api/customer-list/phone-number-scores/check-missing'), {});
  }

  addCustomer(customer: ICustomer, credentialId: null|number): Observable<IAddCustomerResponse> {
    let payload = {...customer};

    if (credentialId !== null) {
      payload['credential_id'] = credentialId;
    }

    return this.http.post<IAddCustomerResponse>(this.constructFullUrl('api/customer-list/customers'),payload);
  }

  updateCustomer(customerId: string, payload: ICustomer): Observable<IAddCustomerResponse> {
    return this.http.put<IAddCustomerResponse>(this.constructFullUrl('api/customer-list/customers/' + customerId), payload);
  }

  deleteCustomer(payload: any): Observable<any> {
    return this.http.request<any>('delete', this.constructFullUrl('api/customer-list/customers') , { body: {customers: payload} });
  }

  importCustomers(source: ImportSourceEnum, ids: string[], apikey: string, properties: string[]): Observable<IImportManyChatCustomersResponse> {
    let payload;
    if (ids && apikey) {
      payload = {ids, apikey};
    } else {
      payload = {properties}
    }
    return this.http.post<IImportManyChatCustomersResponse>(this.constructFullUrl('api/customer-list/import-contacts/' + source), {
      ...payload 
    });
  }

  saveFilters(name: string, filters: IFilter[]): Observable<ISaveFilterResponse> {
    return this.http.post<ISaveFilterResponse>(this.constructFullUrl('api/customer-list/views'), {
      name,
      filters
    });
  }

  getSavedFilters(): Observable<ISavedFiltersListResponse> {
    return this.http.get<ISavedFiltersListResponse>(this.constructFullUrl('api/customer-list/views'));
  }

  deleteSavedFilter(id: number): Observable<any> {
    return this.http.request<any>('delete', this.constructFullUrl('api/customer-list/views/'+id.toString()));
  }

  moveCustomer(customers: string[], list: string): Observable<any> {
    return this.http.post<any>(this.constructFullUrl('api/customer-list/bulk-list'),{customers, list});
  }

  exportCustomers(customers: string[]):Observable<any> {
    return this.http.post<any>(this.constructFullUrl('api/customer-list/customers/export'), {customers}, {responseType: 'blob' as 'json'})
      .pipe(
        map((file: ArrayBuffer) => {
          return file;
        })
      );
  }

  loadAvailableFilters(): Observable<ILoadAvailableFiltersResponse> {
    return this.http.get<ILoadAvailableFiltersResponse>(this.constructFullUrl('api/customer-list/available-filters'));
  }

  loadCustomerOrderItems(customerId: string): Observable<ILoadCustomerOrderItemsSuccessResponse> {
    return this.http.get<ILoadCustomerOrderItemsSuccessResponse>(this.constructFullUrl(`api/customer-list/customers/${customerId}/orders`));
  }
}
