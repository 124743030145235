import { createSelector } from 'reselect';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { ReverseAsinActions } from '@app/shared/data/reverse-asin/reverse-asin.actions';
import { AsinCompareActions } from '@app/shared/data/asin-compare/asin-compare.actions';
import { ReverseAsinQuickAaddStates } from '@app/shared/data/reverse-asin/reverse-asin.models';

const INITIAL_STATE: AppState = {
  loading: false,
  backgroundLoading: false,
  asin: null,
  groupId: null,
  results: null,
  children: [],
  errorMessage: null,
};

export const ASIN_COMPARE_READY = 'ready';
export const ASIN_COMPARE_SUCCEEDED = 'succeeded';
export const ASIN_COMPARE_FAILED = 'failed';

export function asinCompareReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === AsinCompareActions.TYPES.COMPARE) {
    let newState: AppState = {
      ...state,
      loading: true,
      backgroundLoading: action.data.backgroundLoading || false,
      asin: action.data.asin,
      groupId: action.data.groupId,
      errorMessage: null,
    };

    // Do not reset results to empty if background loading
    if (!action.data.backgroundLoading) {
      newState = {
        ...newState,
        results: null,
        children: [],
      };
    }
    return newState;
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_FAILED) {
    console.log(JSON.stringify(action.data));
    return {
      ...state,
      ...INITIAL_STATE,
      errorMessage: action.data.errorMessage,
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_SUCCEEDED) {
    return {
      ...state,
      loading: false,
      backgroundLoading: false,
      results: action.data.results,
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_CANCEL
    || action.type === ReverseAsinActions.TYPES.KEYWORDS_REQUESTED) {
    return {
      ...state,
      ...INITIAL_STATE
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_CHILDREN) {
    return {
      ...state,
      children: action.data.children
    }
  }

  return state;
}

export const selectActive = (selector) => createSelector([
  state => selector(state['reverse_asin']['group_id'])(state),
], (data) => data);

export const selectKeywords = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['results']
], (groupId, results) => {
  if (groupId === r2aGroupId && results !== null && 'keywords' in results) {
    return results['keywords'];
  }
  return null;
});

export const selectStats = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['results']
], (groupId, results) => {
  if (groupId === r2aGroupId && results !== null && 'stats' in results) {
    return results['stats'];
  }
  return null;
});

export const selectCompareStats = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['results']
], (groupId, results) => {
  if (groupId === r2aGroupId && results !== null && 'compare' in results) {
    return results['compare'];
  }
  return null;
});

export const selectAsin = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['asin']
], (groupId, asin) => {
  if (groupId === r2aGroupId && asin !== null) {
    return asin;
  }
  return null;
});

export const selectProduct = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['children']
], (groupId, children) => {
  if (groupId === r2aGroupId && children !== null && children.length > 0) {
    return children[0];
  }
  return null;
});

export const selectResults = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['results']
], (groupId, results) => {
  if (groupId === r2aGroupId) {
    return results;
  }
  return null;
});


export const selectLoading = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['loading'],
  state => state['asin_compare']['backgroundLoading']
], (groupId, loading, bgLoading) => {
  if (groupId === r2aGroupId) {
    return loading && !bgLoading;
  }
  return false;
});

export const backgroundLoading = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['backgroundLoading']
], (groupId, loading) => {
  if (groupId === r2aGroupId) {
    return loading;
  }
  return false;
});


export const selectChildren = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['asin_compare']['children']
], (groupId, children) => {
  if (groupId === r2aGroupId) {
    return children;
  }
  return [];
});


export const selectErrorMessage = createSelector([
  state => state['asin_compare']['errorMessage']
], (state) => {
    return state;
});

export const selectAsinRankMissingCount = (r2aGroupId: string) => createSelector([
  state => state['asin_compare']['groupId'],
  state => state['reverse_asin']['complete_data']
], (groupId, keywords) => {
  if (groupId === r2aGroupId && keywords !== null) {
      return keywords.filter(item => item.quickAddState === ReverseAsinQuickAaddStates.ADDED && item.myAsinRank === false).length;
  }
  return 0;
});
