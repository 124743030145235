import { BaseModel } from '@app/shared/data/base.models';

/**
 * Prizm product result model.
 *
 * @export
 * @class PrizmProduct
 */
export class PrizmProduct extends BaseModel {
  static className = 'PrizmProduct';
  asin: string;
  title: string;
  price: number;
  prime_eligible: boolean;
  image_url?: string;
  bsr: number;
  review_count: number;
  review_rating: number;
  offer_count: number;
  dimensions: {width: number, length: number, height: number};
  monthly_sales_units: number;
  monthly_revenue: number;
  fulfillment: string;
  sales_trend: any[];
  amazons_choice: boolean;
  best_seller: boolean;
}

export class PrizmKeyword {
  static className = 'PrizmKeyword';
  keyword: string;
  searches: number;
  click_rate: number;
  monthly_revenue_avg: number;
  monthly_sales_units_avg: number;
  price_new_avg: number;
  price_amazon_avg: number;
  purchase_rate: number;
  review_count_avg: number;
  review_rating_avg: number;
  bsr_avg: number;
}

/**
 * Prizm product search filters.
 *
 * @export
 * @interface PrizmProductFilters
 */
export interface PrizmProductFilters {
  categories?: string[];

  bsrMin?: number;
  bsrMax?: number;

  reviewRatingMin?: number;
  reviewRatingMax?: number;

  reviewCountMin?: number;
  reviewCountMax?: number;

  monthlySalesUnitsMin?: number;
  monthlySalesUnitsMax?: number;

  monthlyRevenueMin?: number;
  monthlyRevenueMax?: number;

  priceAmazonMin?: number;
  priceAmazonMax?: number;

  priceNewMin?: number;
  priceNewMax?: number;

  productType?: string;

  fulfillment?: string;

  outOfStockMin?: number;
  outOfStockMax?: number;

  fbaFeeMin?: number ;
  fbaFeeMax?: number ;

  offerCountMin?: number;
  offerCountMax?: number;

  numberItemsMin?: number;
  numberItemsMax?: number;

  numberPagesMin?: number;
  numberPagesMax?: number;

  packageLengthMin?: number;
  packageLengthMax?: number;

  packageWidthMin?: number;
  packageWidthMax?: number;

  packageHeightMin?: number;
  packageHeightMax?: number;

  packageWeightMin?: number;
  packageWeightMax?: number;

  reviewChangeMin?: number;
  reviewChangeMax?: number;

  salesToReviewsMin?: number;
  salesToReviewsMax?: number;

  revenueGrowthMin?: number;
  revenueGrowthMax?: number;

  salesGrowthMin?: number;
  salesGrowthMax?: number;

  grossProfitMin?: number;
  grossProfitMax?: number;

  primeEligible?: boolean;
  subscribeSave?: boolean;

  asin?: string;
  keyword?: string;
}


export interface PrizmKeywordFilters {
  keyword?: string;

  reviewRatingAvgMin?: number;
  reviewRatingAvgMax?: number;

  reviewCountAvgMin?: number;
  reviewCountAvgMax?: number;

  monthlySalesUnitsAvgMin?: number;
  monthlySalesUnitsAvgMax?: number;

  monthlyRevenueAvgMin?: number;
  monthlyRevenueAvgMax?: number;

  priceAmazonAvgMin?: number;
  priceAmazonAvgMax?: number;

  priceNewAvgMin?: number;
  priceNewAvgMax?: number;

  searchesMin?: number;
  searchesMax?: number;

  clickRateMin?: number;
  clickRateMax?: number;

  cartRateMin?: number;
  cartRateMax?: number;

  purchaseRateMin?: number;
  purchaseRateMax?: number;

  bsrAvgMin?: number;
  bsrAvgMax?: number;
}

export class Category extends BaseModel {
  static className = 'Category';
  id: number;
  name: string;
  children: Category[];
}
