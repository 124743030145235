<div class="table" *ngIf="!loading">
  <div class="row" *ngFor="let plan of plans">
    <div class="name">{{ plan.name }}</div>
    <div>${{ plan.price }} / mo</div>
    <div>
      <!-- if currently subscribed to current -->
      <ng-container *ngIf="activeSubscription && activeSubscription.plan_id === plan.remote_id">
        <ng-container *ngIf="activeSubscription.ends_at">
          <span> <span i18n>Until</span> {{ activeSubscription.ends_at }}</span>
          <st-button-v2 color="v2_red" [disabled]="subscribingToPlan || cancelingSubscription"
            [loading]="(subscribingToPlan || cancelingSubscription) && buttonLoadingPlanId === plan.remote_id"
            (clicked)="save(plan)" buttonClass="orange-button" label="Resume" i18n-label>
          </st-button-v2>
        </ng-container>

        <st-button-v2 color="v2_red"
          *ngIf="activeSubscription.id === nextSubscription.id && !activeSubscription.ends_at"
          [disabled]="subscribingToPlan || cancelingSubscription"
          [loading]="(subscribingToPlan || cancelingSubscription) && buttonLoadingPlanId === plan.remote_id"
          (clicked)="cancel(activeSubscription)" buttonClass="red-button" label="Cancel" i18n-label>
        </st-button-v2>
      </ng-container>

      <!-- if switching to current -->
      <ng-container
        *ngIf="activeSubscription && activeSubscription.id !== nextSubscription.id && nextSubscription.plan_id === plan.remote_id">
        <span i18n>Pending</span>
        <st-button-v2 color="v2_red" [disabled]="subscribingToPlan || cancelingSubscription"
          [loading]="(subscribingToPlan || cancelingSubscription) && buttonLoadingPlanId === plan.remote_id"
          (clicked)="cancel(nextSubscription)" buttonClass="red-button" label="Cancel" i18n-label>
        </st-button-v2>
      </ng-container>

      <st-button-v2 color="v2_red"
        *ngIf="!activeSubscription || activeSubscription.plan_id != plan.remote_id && nextSubscription.plan_id != plan.remote_id"
        [disabled]="subscribingToPlan || cancelingSubscription"
        [loading]="(subscribingToPlan || cancelingSubscription) && buttonLoadingPlanId === plan.remote_id"
        (clicked)="save(plan)" label="Subscribe" i18n-label>
      </st-button-v2>
    </div>
  </div>
</div>

<div class="table" *ngIf="loading">
  <div>
    <st-loader></st-loader>
  </div>
  <div>
    <st-loader></st-loader>
  </div>
  <div>
    <st-loader></st-loader>
  </div>
  <div>
    <st-loader></st-loader>
  </div>
</div>
