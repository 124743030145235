import { Component, Input, ChangeDetectionStrategy } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Component({
  selector       : '[st-progress-loader]',
  template       : '<ng-content></ng-content><div class="loader" [style.width.%]="percentage | async" [class.animate]="finishing | async"></div>',
  styleUrls      : ['./progress-loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProgressLoaderComponent {
  loading = false;
  percentage = new BehaviorSubject(0);
  finishing = new BehaviorSubject(false);

  get progress() {
    return this.loading;
  }

  @Input('st-progress-loader')
  set progress(val) {
    this.loading = val;

    this.calculate();

    if (!this.loading && this.percentage.getValue() > 0) {
      this.finish();
    }
  }

  calculate(times = 0) {
    if (this.loading) {
      this.percentage.next(Math.atan(times / 2e3) / (Math.PI / 2) * 100);

      setTimeout(() => {
        this.calculate(times + 1);
      });
    }
  }

  finish() {
    this.finishing.next(true);
    this.percentage.next(100);

    setTimeout(() => {
      this.finishing.next(false);
    }, 200);

    setTimeout(() => {
      this.percentage.next(0);
    }, 800);
  }
}
