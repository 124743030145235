import { Component } from "@angular/core";

@Component({
  selector: 'st-white-box',
  templateUrl: './white-box.component.html',
  styleUrls: ['./white-box.component.scss']
})
export class WhiteBoxComponent {

}
