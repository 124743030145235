import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldContainerComponent } from '@app/shared/layout/forms/field-container/field-container.component';
import { PhoneInputComponent } from '@app/shared/layout/forms/phone-input/phone-input.component';
import { DropdownModule } from '@app/shared/layout/dropdown/dropdown.module';
import { CommonModule } from '@angular/common';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { SvgIconModule } from '@app/shared/layout/icon/svg-icon.module';
import { CreditCardInputComponent } from '@app/shared/layout/forms/credit-card-input/credit-card-input.component';
import { NgReduxFormModule } from '@angular-redux/form';
import { InputBoxComponent } from '@app/shared/layout/forms/input-box/input-box.component';
import { ClipboardModule } from 'ngx-clipboard';
import { MediumEditorModule } from '@app/shared/components/editor/medium-editor.module';
import { ErrorComponent } from '@app/shared/layout/forms/error/error.component';
import { PopoverModule } from '@app/shared/components/popover/popover.module';
import { DeModelDirective } from '@app/shared/directives/de-model.directive';
import { ForceLowercaseDirective } from '@app/shared/directives/force-lowercase.directive';
import { AutoresizeDirective } from '@app/shared/directives/autoresize.directive';
import { SuccessComponent } from '@app/shared/layout/forms/success/success.component';
import { LimitLinesDirective } from '@app/shared/directives/limit-lines.directive';
import { TranslateDirective } from '@app/shared/directives/translate.directive';
import { TrimDirective } from '@app/shared/directives/trim.directive';
import { NumericDirective } from '@app/shared/directives/numeric.directive';
import { PhoneFormatterDirective } from '@app/shared/directives/phone-formatter.directive';

@NgModule({
  imports     : [
    CommonModule,
    FormsModule,
    NgReduxFormModule,
    ReactiveFormsModule,
    DropdownModule,
    PipesModule,
    SvgIconModule,
    ClipboardModule,
    MediumEditorModule,
    PopoverModule,
  ],
  declarations: [
    FieldContainerComponent,
    PhoneInputComponent,
    CreditCardInputComponent,
    InputBoxComponent,
    ErrorComponent,
    SuccessComponent,
    DeModelDirective,
    ForceLowercaseDirective,
    AutoresizeDirective,
    LimitLinesDirective,
    TranslateDirective,
    TrimDirective,
    NumericDirective,
    PhoneFormatterDirective,
  ],
  exports     : [
    FormsModule,
    NgReduxFormModule,
    ReactiveFormsModule,
    FieldContainerComponent,
    PhoneInputComponent,
    CreditCardInputComponent,
    InputBoxComponent,
    ErrorComponent,
    SuccessComponent,
    DeModelDirective,
    ForceLowercaseDirective,
    AutoresizeDirective,
    LimitLinesDirective,
    TranslateDirective,
    TrimDirective,
    NumericDirective,
    PhoneFormatterDirective,
  ],
})
export class StFormsModule {
}
