import { PipeTransform, Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'stPositiveNumber'
})
@Injectable()
export class PositiveNumberPipe implements PipeTransform {
  transform(value: number, roundUp: boolean = false): number {
    if (value < 0) {
      return 0;
    }

    if (roundUp) {
      return Math.ceil(value * 100) / 100;
    }

    return Math.round(value * 100) / 100;
  }
}
