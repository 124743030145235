import { dotVal } from '@app/functions';

export interface IErrorResponse {
  error: {
    message: string;
    error_code: string
  }
}
export class BaseModel {
  static readonly NO_PAGINATION = 0;
  static readonly BACKEND_PAGINATION = 1;
  static readonly FRONTEND_PAGINATION = 2;
  static readonly SIMPLE_PAGINATION = 3;

  _actions: any = {};
  _meta: any = {};

  ds: number = null;

  public constructor(data = {}) {
    Object.assign(this, data);
  }

  /**
   * Create Collection from data.
   *
   * @param data
   * @param {boolean} pagination
   * @returns {Collection<T>}
   */
  public static collect<T extends BaseModel>(data, pagination: number = this.NO_PAGINATION) {
    let modelData = data;
    if ('data' in modelData) {
      modelData = modelData.data;
      delete data['data'];
    } else {
      data = {};
    }

    modelData = modelData.map(d => new this(d));

    if (pagination == BaseModel.BACKEND_PAGINATION) {
      return new LengthAwarePaginationCollection<T>(modelData, data);
    }

    if (pagination == BaseModel.FRONTEND_PAGINATION) {
      return new FePaginationCollection<T>(modelData, data);
    }

    if (pagination == BaseModel.SIMPLE_PAGINATION) {
      return new PaginationCollection<T>(modelData, data);
    }

    return new Collection<T>(modelData, data);
  }

  /**
   * Create new instance with updated attributes.
   *
   * @param model
   * @param attr
   * @return {BaseModel}
   */
  public clone(model, attr = {}) {
    return new model(Object.assign({}, this, attr));
  }

  /**
   * @param keys
   * @return {Object}
   */
  public getData(keys = []) {
    let data = Object.assign({}, this);
    for (var property in data) {
      if (data.hasOwnProperty(property)) {
        if (property[0] == '_' || (keys.length && !keys.includes(property)))
          delete data[property];
      }
    }

    return data;
  }

  public meta(field) {
    return this._meta[field];
  }
}

export class Collection<T> {
  protected data = [];
  protected _meta: {};

  [Symbol.iterator] = function* () {
    for (let data of this.data)
      yield data;
  };

  constructor(data: T[] = [], meta = {}) {
    this.data = data;
    this._meta = meta;
  }

  /**
   * Return array of the collection
   * @returns {T[]}
   */
  public toArray(): T[] {
    return this.data;
  }

  /**
   * @param key
   */
  public get(key): T {
    return this.data[key];
  }

  /**
   * @param key
   * @return {boolean}
   */
  public has(key): boolean {
    return key in this.data;
  }

  /**
   * @returns {number}
   */
  public count(): number {
    return this.data.length;
  }

  /**
   * Get accessor for length
   * @returns {number}
   */
  get length() {
    return this.count();
  }

  /**
   * @returns {boolean}
   */
  public isEmpty(): boolean {
    return this.count() == 0;
  }

  /**
   * @return {boolean}
   */
  public isNotEmpty(): boolean {
    return !this.isEmpty();
  }

  public getMeta(key) {
    return this._meta[key];
  }

  public setMeta(key, value) {
    this._meta[key] = value;
    return this;
  }

  /**
   * * @param def
   * @returns {any}
   */
  public first(def = null): T {
    if (this.isEmpty())
      return def;

    return this.get(0);
  }

  /**
   * @param def
   * @return {null}
   */
  public last(def = null): T {
    if (this.isEmpty())
      return def;

    return this.data.slice(-1).pop();
  }

  /**
   * @param callback
   * @param def
   * @return {T}
   */
  public find(callback, def = null): T {
    return this.filter(callback).first(def);
  }

  /**
   * @param callback
   * @returns {Collection<T>}
   */
  public filter(callback): Collection<T> {
    const tmp = this.clone();
    tmp.data = tmp.data.filter(callback);
    return tmp;
  }

  public push(item: T): Collection<T> {
    this.data = [...this.data, item];
    return this;
  }

  public unshift(item: T): Collection<T> {
    if (this.data.some(d => d.id === (item as any).id)) {
      return this;
    }

    this.data = [item, ...this.data];
    return this;
  }

  public updateOrPush(item: T, field = 'id', updateTotal = false): Collection<T> {
    let found = false;

    const itemValue = dotVal(item, field);

    this.data = this.data.map((i) => {
      if (dotVal(i, field) == itemValue) {
        found = true;
        return item;
      }
      return i;
    });

    if (!found) {
      this.push(item);
    }

    if (updateTotal && !found) {
      this.setMeta('total', this.getMeta('total') + 1)
    }

    return this;
  }

  public updateOrPushMany(items: T[], field = 'id', updateTotal = false, mergeFn: (old: T, nw: T) => T = (old, nw) => nw): Collection<T> {

    //Update the ones that already exist
    this.data = this.data.map((item: T) => {
      while (true) {
        let index = items.map(e => e[field]).indexOf(item[field]);
        if (index < 0) break;
        item = mergeFn(item, items.splice(index, 1)[0]);
      }
      return item;
    });

    if (updateTotal && items.length > 0) {
      this.setMeta('total', this.getMeta('total') + items.length)
    }

    // Merge duplicates inside push many list
    items = items.reduce((acc: T[], item: T) => {
      while (true) {
        let index = acc.map(e => e[field]).indexOf(item[field]);
        if (index < 0) break;
        item = mergeFn(item, acc.splice(index, 1)[0]);
      }

      return [...acc, item];
    }, []);

    //Add remaining as new ones
    return this.merge(items);
  }

  public reduce(fn: (prevVal: T, currentVal: T, index: number, array: T[]) => T, initial: any) {
    const tmp = this.clone();
    tmp.data = tmp.data.reduce(fn, initial);
    return tmp;
  }

  public prepend(item: T) {
    const tmp = this.clone();
    tmp.data = [item, ...this.data];
    return tmp;
  }

  public merge(data: T[]) {
    const tmp = this.clone();
    tmp.data = tmp.data.concat(data);
    return tmp;
  }

  public unique(): Collection<T> {
    const tmp = this.clone();
    tmp.data = tmp.data.filter((v, i, a) => a.indexOf(v) === i);
    return tmp;
  }

  /**
   * @param callback
   * @return {Collection<T>}
   */
  public map(callback): Collection<any> {
    const tmp = this.clone();
    tmp.data = tmp.data.map(callback);
    return tmp;
  }

  /**
   * @param callback
   * @returns {any[]}
   */
  public pluck(callback) {
    let data = [];

    this.each((entry) => {
      data.push(callback(entry));
    });


    return data;
  }

  /**
   * @param callback
   * @return {Collection<T>}
   */
  public sort(callback) {
    const tmp = this.clone();
    tmp.data = tmp.data.sort(callback);
    return tmp;
  }

  /**
   * @param callback
   */
  public each(callback) {
    this.map(callback);
  }

  /**
   * @param callback
   * @returns {number}
   */
  public sum(callback) {
    let sum = 0;

    this.each((entry) => {
      sum += Number(callback(entry));
    });

    return sum;
  }

  /**
   * @param callback
   * @return {boolean}
   */
  public exists(callback): boolean {
    return this.filter(callback).isNotEmpty();
  }

  /**
   * Clone the collection
   * @returns {Collection<T>}
   */
  public clone(): Collection<T> {
    return clone(this);
  }

  /**
   * Key By Field data.
   *
   * @param key
   * @return {Collection<T>}
   */
  public keyBy(key): Collection<T> {
    let data = [];
    for (let i in this.data) {
      let value = this.data[i];
      data[value[key]] = value;
    }

    const tmp = this.clone();
    tmp.data = data;
    return tmp;
  }
}

export function clone<T>(instance: T): T {
  const copy = new (instance.constructor as { new(): T })();
  Object.assign(copy, instance);
  return copy;
}

export interface Pagination {
  getCurrentPage(): number;
  getPerPage(): number;
  hasMore(): boolean;
  hasPages(): boolean;
  pageIsOutOfScope(): boolean;
  getCurrentRange(): { from: number, to: number };
}

export interface LengthAwarePagination extends Pagination {
  getTotal(): number;
  getLastPage(): number;
}

export class PaginationCollection<T> extends Collection<T> implements Pagination {
  public getPerPage(): number {
    return this._meta['per_page'];
  }

  public getCurrentPage(): number {
    return this._meta['current_page'];
  }

  public hasMore(): boolean {
    return this._meta.hasOwnProperty('next_page_url') && this._meta['next_page_url'] !== null;
  }

  public hasPages(): boolean {
    return true;
  }

  public pageIsOutOfScope(): boolean {
    return this.getCurrentPage() > 1 && this._meta['from'] === null;
  }

  getCurrentRange(): { from: number, to: number } {
    return {
      from: this._meta['from'],
      to: this._meta['to'],
    };
  }
}

export class LengthAwarePaginationCollection<T> extends PaginationCollection<T> implements LengthAwarePagination {
  public hasMore(): boolean {
    return this.getCurrentPage() < this.getLastPage();
  }

  public hasPages(): boolean {
    return this.getLastPage() > 0;
  }

  public pageIsOutOfScope(): boolean {
    return this.getCurrentPage() > this.getLastPage();
  }

  public getTotal(): number {
    return this._meta['total'];
  }

  public getLastPage(): number {
    return this._meta['last_page'];
  }
}

export class FePaginationCollection<T> extends LengthAwarePaginationCollection<T> {

  public comparator = (field: string, aObj: T, bObj: T, desc: boolean) => {
    const a = aObj[field];
    const b = bObj[field];
    if (typeof aObj[field + 'Compare'] === 'function') {
      return aObj[field + 'Compare'](a, b, desc);
    }

    if (a === b) {
      return 0;
    }

    const v = a === null ? 1 : b === null ? -1 : a < b ? 1 : -1;
    return desc ? v : v * -1;
  };

  public createPage(pagination: { page: number, perPage: number, sort: string[] } = { page: 1, perPage: 50, sort: [] }) {
    // Per page
    const _page: number = pagination.page - 1;
    const from: number = pagination.perPage * _page;
    const to: number = from + pagination.perPage;

    const compareFn = (sortFields: string[]) => (a: T, b: T) => {
      if (!sortFields || sortFields.length <= 0) {
        return 0;
      }

      if (a['_sortItem']) {
        a = a['_sortItem'];
      }

      if (b['_sortItem']) {
        b = b['_sortItem'];
      }

      const desc: boolean = sortFields[0].startsWith('-');
      const sf = desc ? sortFields[0].slice(1) : sortFields[0];

      const c = this.comparator(sf, a, b, desc);

      if (c === 0) {
        return compareFn(sortFields.slice(1))(a, b);
      }

      return c;
    };


    let data: any[] = this.data;
    data.sort(compareFn(pagination.sort));
    data = this.data.slice(from, to);

    const meta = {
      ...this._meta,
      current_page: pagination.page,
      last_page: Math.ceil(this.count() / pagination.perPage),
      per_page: pagination.perPage,
      total: this.count(),
      from: 0,
      to: 0,
    };

    if (meta.total > 0) {
      meta.from = (meta.current_page - 1) * meta.per_page + 1;
      meta.to = meta.from + meta.per_page - 1;

      if (meta.total < meta.to) {
        meta.to = meta.total;
      }
    }

    return new FePaginationCollection<T>(data, meta);
  }
}

export interface DownloadBlob {
  filename: string|null,
  blob: Blob
};
