<div class="dropdown-wrapper"
  [class.dropdown-wrapper--up]="dropdownPosition === 'up'"
  [class.dropdown-wrapper--down]="dropdownPosition === 'down'"
  [class.dropdown-wrapper--alternate]="alternateBg"
  [class.dropdown-wrapper--opened]="opened"
  [class.dropdown-wrapper--blue]="color === 'blue'"
  [class.dropdown-wrapper--transparent]="transparentBg"
  [class.dropdown-wrapper--size-small]="size === 'small'"
  [class.dropdown-wrapper--size-normal]="size === 'normal'"
  [class.dropdown-wrapper--disabled]="disabled"
  stOffClick
  (clickOutside)="opened = false">
  <div class="dropdown__title" (click)="toggleDropdown()">
    <div class="dropdown__title-text">{{selectedOption?.label}}</div>
    <div class="dropdown__title-icon"></div>
  </div>
  <div class="dropdown"
    [class.dropdown--up]="dropdownPosition === 'up'"
    [class.dropdown--down]="dropdownPosition === 'down'">
    <div class="dropdown__options">
      <div class="dropdown__option" (click)="selectOption(option)" *ngFor="let option of filteredOptions">{{option.label}}</div>
    </div>
  </div>
</div>
