import { Injectable } from "@angular/core";
import { AdminPlan } from '@app/shared/data/admin/plan/plan.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { StAction } from "@app/shared/data/st-action";

interface SnapshotAction extends StAction {
  marketplace_id?: number
}
@Injectable()
export class AmazonSnapshotActions  {

  static readonly TYPES = {
    SHOW          : '[AMAZON_SNAPSHOT]SHOW',
    SHOW_SUCCEEDED: '[AMAZON_SNAPSHOT]SHOW_SUCCEEDED',
    SHOW_FAILED   : '[AMAZON_SNAPSHOT]SHOW_FAILED',
  };

  show(marketplaceId:number, filters: {keyword:string, date:string}): SnapshotAction {
    return {
      type: AmazonSnapshotActions.TYPES.SHOW,
      marketplace_id: marketplaceId,
      data: filters
    }
  }

  showSucceeded(data): StAction {
    return {
      type: AmazonSnapshotActions.TYPES.SHOW_SUCCEEDED,
      data: data
    }
  }

  showFailed(error: any): StAction {
    return {
      type: AmazonSnapshotActions.TYPES.SHOW_FAILED,
      data: error
    }
  }
}
