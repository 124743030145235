import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { Collection } from "@app/shared/data/base.models";
import { ProductToolsActions } from "@app/shared/data/admin/product-tools/product-tools.actions";
import { ProductToolsApi } from "@app/shared/data/admin/product-tools/product-tools.api";
import { AdminPlan } from "@app/shared/data/admin/plan/plan.models";
import { ToastService } from "@app/core/services/toast.service";

@Injectable()
export class ProductToolsEpics {
  constructor(private productToolsActions: ProductToolsActions,
              private store: NgRedux<AppState>,
              private toastService: ToastService,
              private productToolsAPI: ProductToolsApi) {}

  public createEpic() {
    return [
      this.find,
      this.checkRank,
    ];
  }

  find = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == ProductToolsActions.TYPES.FIND) {
        this.productToolsAPI.find(action.data)
          .subscribe(
            (data: any) => {
              this.store.dispatch(this.productToolsActions.findSucceeded(data));
            },
            (response) => {
              this.store.dispatch(this.productToolsActions.findFailed(response));
              this.toastService.error('Derp!', "Product not found!");
            },
            () => {}
          );
      }

      return next(action);
    }
  };

  checkRank = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == ProductToolsActions.TYPES.CHECK_RANK) {
        this.productToolsAPI.fireRankCheck(action.data)
          .subscribe(
            (data: any) => {
              this.toastService.success('Success', data.message);
            },
            (response) => {
              this.toastService.error('Sorry', response.error.message);
            },
            () => {}
          );
      }

      return next(action);
    }
  };
}
