
import {takeUntil} from 'rxjs/operators';
import {
    AfterViewChecked,
    Directive, EmbeddedViewRef, Input, OnChanges, OnDestroy, OnInit, Renderer2,
    ViewContainerRef
} from "@angular/core";
import { ColmComponent } from "@app/shared/components/datatable/components/colm.component";
import {Subject} from "rxjs";

@Directive({
  selector: '[colmBuild]'
})
export class ColmBuilderDirective implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
  private view: EmbeddedViewRef<any>;

  constructor(private vcr: ViewContainerRef, private renderer:Renderer2) { }

  @Input('colmBuild')
  colm: ColmComponent;

  @Input('context')
  context: any;

  @Input('head')
  head: boolean = false;

  ngOnInit() {
    this.updateTemplate();
    this.colm.titleChange.pipe(takeUntil(this.onDestroy$)).subscribe(this.updateDataLabel.bind(this));
    this.colm.classListChange.pipe(takeUntil(this.onDestroy$)).subscribe(this.updateClassList.bind(this));
    if(this.head)
      this.colm.headTemplateChange.pipe(takeUntil(this.onDestroy$)).subscribe(this.updateTemplate.bind(this));
  }

  private onDestroy$ = new Subject();

  ngOnChanges() {
    this.updateTemplate();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  ngAfterViewChecked(): void {
    this.updateClassList();
    this.updateDataLabel();
  }


  private updateTemplate() {
    const template = this.head ? this.colm.getHeadTemplate() : this.colm.getCellTemplate();
    if(template) {
      const context = {...this.context};
      if(this.colm.title)
        context.title = this.colm.title;

      if (!this.view) {
        this.view = this.vcr.createEmbeddedView(template, context);
      } else {
        this.view.context.$implicit = context.$implicit;
      }
    }
  }

  private updateClassList() {
    if(!this.view) return null;
    this.colm.classList.forEach(c => {
      this.view.rootNodes.filter(node => node.tagName).forEach(node =>
        this.renderer.addClass(node, c)
      );
    });
  }

  private updateDataLabel() {
    if(!this.view) return null;
    if(this.colm.title) {
      this.view.rootNodes.filter(node => node.tagName).forEach(node =>
        this.renderer.setAttribute(node, 'data-label', this.colm.title)
      );
    }
  }

}
