import { Injectable } from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { browsingErrors, isSearchingProducts, results } from '@app/shared/data/browse-products/browse-products.reducers';
import { Observable ,  Subject ,  BehaviorSubject } from 'rxjs';
import { ISearchedProduct } from '@app/shared/data/browse-products/browse-products.models';
import { AppState } from '@app/shared/data/app-state.model';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { BrowseProductsActions } from '@app/shared/data/browse-products/browse-products.actions';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { map ,  combineLatest } from 'rxjs/operators';

export enum SortByEnum {
  TITLE = 'title',
  RANK = 'sales_rank',
  PRICE = 'price',
  REVIEWS = 'reviews_count'
}

export enum SortDirectionEnum {
  DESC = '-',
  ASC = '',
}

export interface SortOpts {
  field: SortByEnum,
  direction: SortDirectionEnum,
}

@Injectable()
export class BrowseProductsService {
  DEFAULT_ERROR_CODE = 1900;

  @select(isSearchingProducts)
  searching$: Observable<boolean>;

  @select(browsingErrors)
  error$: Observable<string>;

  @select(results)
  products$: Observable<ISearchedProduct[]>;

  sortedProducts$: Observable<ISearchedProduct[]>;

  sortOpts$: Subject<SortOpts> = new BehaviorSubject({
    field: SortByEnum.RANK,
    direction: SortDirectionEnum.ASC,
  });

  errorMapper = {
    1900: this.i18n('Search for products failed'),
    1906: this.i18n('Search for products failed'),
    1901: this.i18n('No products match your search criteria'),
    1903: this.i18n('No product data for selected Marketplace'),
  };

  currentSearchTerm = '';

  constructor(
    private i18n: I18n,
    private ngRedux: NgRedux<AppState>,
    private rudderTracking: RudderTrackingService,
    private browseProductsActions: BrowseProductsActions,
  ) {
    // sort results on every change of sort options or search results
    this.sortedProducts$ = this.products$.pipe(
      combineLatest(this.sortOpts$, sortProductsFn),
    );
  }

  clearSearch() {
    this.ngRedux.dispatch(this.browseProductsActions.clearSearch());
  }

  performAsinSearch(asins: string[]) {
    if (asins.length === 0) {
      this.clearSearch();
      return;
    }
    this.ngRedux.dispatch(this.browseProductsActions.browseByAsins(asins));
  }

  performSort(sortOpts: SortOpts) {
    this.sortOpts$.next(sortOpts);
  }

  performSearch(searchTerm: string) {
    if (this.currentSearchTerm === searchTerm) {
      return;
    }

    this.currentSearchTerm = searchTerm;
    if (this.currentSearchTerm.length === 0) {
      this.clearSearch();
      return;
    }

    this.ngRedux.dispatch(this.browseProductsActions.browseByKeyword(searchTerm));
    this.currentSearchTerm = null;
  }

  getError(): Observable<string> {
    return this.error$.pipe(
      map(errCode => {
        if (errCode === null) {
          return null;
        }

        return this.errorMapper[errCode] || this.errorMapper[this.DEFAULT_ERROR_CODE];
      }),
    );
  }
}

const sortProductsFn = (products: ISearchedProduct[], sortOpts: SortOpts) => {
  return products.sort((
    a: ISearchedProduct,
    b: ISearchedProduct,
  ) => {
    if ([SortByEnum.RANK, SortByEnum.REVIEWS].includes(sortOpts.field)) {
      const firstValue = a[sortOpts.field] || (sortOpts.direction === SortDirectionEnum.ASC ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER);
      const secondValue = b[sortOpts.field] || (sortOpts.direction === SortDirectionEnum.ASC ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER);
      // @ts-ignore
      return sortOpts.direction === SortDirectionEnum.ASC ? firstValue - secondValue : secondValue - firstValue;
    }

    if ([SortByEnum.PRICE].includes(sortOpts.field)) {
      const firstValue = a.price.amount || (sortOpts.direction === SortDirectionEnum.ASC ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER);
      const secondValue = b.price.amount || (sortOpts.direction === SortDirectionEnum.ASC ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER);
      return sortOpts.direction === SortDirectionEnum.ASC ? firstValue - secondValue : secondValue - firstValue;
    }

    if ([SortByEnum.TITLE].includes(sortOpts.field)) {
      return sortOpts.direction === SortDirectionEnum.ASC ? a.title.localeCompare(b.title) : b.title.localeCompare(a.title);
    }
  });
};
