
import {takeUntil, skip, distinctUntilChanged} from 'rxjs/operators';
import {
  ApplicationRef, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable,
  Injector,
  Renderer2,
  RendererFactory2
} from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { Subject } from "rxjs";

const modalOpenedBodyClass = 'modal-opened';

@Injectable()
export class ModalService {
  private renderer: Renderer2;

  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
    private rendererFactory: RendererFactory2,
  ) { }

  public open(component: any, data?: { [key: string]: any }): ActiveModal {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    const activeModal = new ActiveModal(this.injector.get(NgRedux), this.rendererFactory);

    const modalContentInjector = Injector.create({
      providers: [{
        provide : ActiveModal,
        useValue: activeModal
      }],
      parent   : this.injector
    });

    const componentRef = this.componentFactoryResolver
      .resolveComponentFactory(component)
      .create(modalContentInjector);

    activeModal.setAppRef(this.appRef);
    activeModal.setComponentRef(componentRef, data);

    this.appRef.attachView(componentRef.hostView);

    const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);

    this.renderer.addClass(document.body, modalOpenedBodyClass);

    return activeModal;
  }
}

@Injectable()
export class ActiveModal {
  private componentRef: ComponentRef<any> = null;
  private appRef: ApplicationRef = null;
  private renderer: Renderer2;

  private _resolve = null;
  private _reject = null;

  public result: Promise<any>;

  constructor(private store: NgRedux<AppState>, private rendererFactory: RendererFactory2) {
    this.renderer = this.rendererFactory.createRenderer(null, null);

    this.result = new Promise((resolve, reject) => {
      this._resolve = resolve;
      this._reject = reject;
    });
    this.result.then(null, () => {});
  }

  public setComponentRef(componentRef: ComponentRef<any>, data?: { [key: string]: any }) {
    this.componentRef = componentRef;
    this.instance = this.componentRef.instance;
    if (data) {
      this.instance.data = data;
    }

    // Listen for route change
    this.store.select(['router']).pipe(
      distinctUntilChanged(),
      skip(1),
      takeUntil(this.onDestroy$),)
      .subscribe(this.close.bind(this, null));
  }

  public setAppRef(appRef: ApplicationRef) {
    this.appRef = appRef;
  }

  public instance = null;

  public hasOwnUrl() {
    return this.appRef == null;
  }

  public close(result?: any) {
    if (this.appRef) {
      this.appRef.detachView(this.componentRef.hostView);
      this.componentRef.destroy();
      this.onDestroy$.next();
    }

    this.renderer.removeClass(document.body, modalOpenedBodyClass);

    this._resolve(result);
  }

  private onDestroy$ = new Subject();
}
