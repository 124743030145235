<st-modal #modal="modal" [showInstructions]="false" [scrollable]="false" [size]="'small'">
  <div modal-header>
    <h2>Rotate Key</h2>
  </div>
  <div modal-body>
    <div class="loading" *ngIf="loadingToken">
      <div class="loading__text">Loading key...</div>
      <st-line-loader></st-line-loader>
    </div>
    <div class="api-key" *ngIf="!loadingToken">{{apiToken.value}}</div>
    <p class="warning">Your action will revoke this API key and generate a new one.</p>
  </div>

  <div class="footer" modal-footer>
    <button [disabled]="loadingToken" class="button interface-component blue-bg" type="button" (click)="onRotate()">I acknowledge, regenerate the key</button>
    <button class="button interface-component red-bg" type="button" (click)="modal.close()">Cancel</button>
  </div>
</st-modal>
