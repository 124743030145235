
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { PayoutAdmin } from "@app/shared/data/admin/payout-affiliate/payout-admin.models";

@Injectable()
export class PayoutAdminAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public payouts(params = {}): Observable<Collection<PayoutAdmin>> {
    return this.http.get(this.constructFullUrl('api/admin/affiliations/payouts'), { params: params }).pipe(
      map(data => {
        return PayoutAdmin.collect<PayoutAdmin>(data, PayoutAdmin.BACKEND_PAGINATION);
      }));
  }

  public pay(commisionId: number): Observable<any> {
    return this.http.put(this.constructFullUrl('api/admin/affiliations/payouts/pay/' + commisionId), {});
  }
}
