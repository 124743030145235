<div class="loader" *ngIf="item.loadingGraphData">
  <st-loader [lines]="5"></st-loader>
</div>

<st-stock-chart [rangeSelector]="{enabled: false}" [options]="chartOpts" *ngIf="!item.loadingGraphData">
  <legend [checkboxes]="true" [enabled]="true"></legend>
  <axis [min]="1" [reversed]="true">
    <line [data]="item.value?.keywordGraph?.rank" i18n-name name="Rank" [options]="lineOptions"></line>
    <line [data]="item.value?.keywordGraph?.impressions" i18n-name name="Impressions" [options]="lineOptions"></line>
  </axis>
</st-stock-chart>
