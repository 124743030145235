import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { Collection } from '@app/shared/data/base.models';
import { VersionApi } from '@app/shared/data/admin/version/version.api';
import { AppVersion, AppVersionUpdate } from '@app/shared/data/version/version.models';
import { VersionActions } from '@app/shared/data/admin/version/version.actions';
import { ToastService } from '@app/core/services/toast.service';
import { NoticesService } from '@app/shared/layout/notices-list/notices.service';

@Injectable()
export class VersionEpics {
  constructor(private versionActions: VersionActions,
              private store: NgRedux<AppState>,
              private versionAPI: VersionApi,
              private noticeService: NoticesService,
              private toastService: ToastService) {
  }

  public createEpic() {
    return [
      this.loadPage,
      this.setEditingVersion,
      this.save,
      this.remove,
      this.listenForNewVersionEvent,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === PaginationActions.getPagActionTypes(AppVersion).LOAD_PAGE || action.type === VersionActions.TYPES.FILTER) {
        const state = store.getState()['admin']['versions'];
        const params = {
          ...state['_be_pagination'],
          ...state['_filters'],
          sort: state['_be_pagination']['sort'].join(',')
        };

        this.versionAPI.all(params)
          .subscribe(
            (promotions: Collection<AppVersion>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.versionActions.setPage(promotions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.versionActions.loadPageFailed(response.error));
            },
            () => {
            }
          );
      }
      return n;
    };
  }

  setEditingVersion = store => next => {
    return (action) => {
      if (action.type === VersionActions.TYPES.EDITING_VERSION) {
        const r = next(action);
        if (action.data.id === 'new') {
          const version: AppVersion = new AppVersion();

          version.title = '';
          version.description = '';

          //noinspection TypeScriptValidateTypes
          this.store.dispatch(this.versionActions.setEditingVersionSucceeded(version));
        } else {
          this.versionAPI.get(action.data.id)
            .subscribe(
              (version: AppVersion) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.versionActions.setEditingVersionSucceeded(version));
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.versionActions.setEditingVersionFailed(response.error));
              }
            );
        }
        return r;
      }
      return next(action);
    };
  }

  save = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === VersionActions.TYPES.SAVE) {
        this.versionAPI.save(action.data)
          .subscribe(
            (version: AppVersion) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.versionActions.saveSucceeded(version));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.toastService.error('Saving failed', 'Please check if all fields are set.');
              this.store.dispatch(this.versionActions.saveFailed(response.error.error));
            },
            () => {
            }
          );
      }
      return n;
    };
  }

  remove = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === VersionActions.TYPES.REMOVE) {
        this.versionAPI.remove(action.data.version)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.versionActions.removeSucceeded(action.data.version));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.versionActions.removeFailed(response.error));
            },
            () => {
            }
          );
      }
      return n;
    };
  }

  listenForNewVersionEvent = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === VersionActions.EVENTS.NEW_VERSION_RELEASED) {
        this.noticeService.setVersionMismatchBanner();
      }
      return next(action);
    };
  }
}
