import { StAction } from '@app/shared/data/st-action';
import { Promotion } from '@app/shared/data/promotion/promotion.models';
import { PromotionActions } from '@app/shared/data/promotion/promotion.actions';

interface PromotionState {
  data: Promotion[];
  active: Promotion;
  loading: boolean;
}

const INITIAL_STATE: PromotionState = {
  data: [],
  active: null,
  loading: false,
};

export function promotionReducer(state: PromotionState = INITIAL_STATE, action: StAction): PromotionState {
  if (action.type === PromotionActions.TYPES.FETCH_PROMOTION) {
    return {
      ...state,
      loading: true
    };
  }

  // set active promotion
  if (action.type === PromotionActions.TYPES.FETCH_PROMOTION_SUCCESS) {
    return {
      ...state,
      active: action.data,
      loading: false
    };
  }

  if (action.type === PromotionActions.TYPES.FETCH_PROMOTION_FAILED) {
    return {
      ...state,
      loading: false
    };
  }

  return state;
}
