import { StAction } from "@app/shared/data/st-action";
import { Injectable } from "@angular/core";
import { Toast } from "@app/shared/layout/toasts/toast.model";

@Injectable()
export class LayoutActions {
  static readonly TYPES = {
    ADD_TOAST         : '[LAYOUT]ADD_TOAST',
    REMOVE_TOAST      : '[LAYOUT]REMOVE_TOAST',
    OPEN_LEFT_SIDENAV : '[LAYOUT]OPEN_LEFT_SIDENAV',
    CLOSE_LEFT_SIDENAV: '[LAYOUT]CLOSE_LEFT_SIDENAV',
  };

  openLeftSidenav(): StAction {
    return {
      type: LayoutActions.TYPES.OPEN_LEFT_SIDENAV,
      data: null
    }
  }

  closeLeftSidenav(): StAction {
    return {
      type: LayoutActions.TYPES.CLOSE_LEFT_SIDENAV,
      data: null
    }
  }

  addToast(toast: Toast): StAction {
    return {
      type: LayoutActions.TYPES.ADD_TOAST,
      data: toast
    }
  }

  removeToast(toast: Toast): StAction {
    return {
      type: LayoutActions.TYPES.REMOVE_TOAST,
      data: toast
    };
  }
}
