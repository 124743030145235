import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { FePaginationCollection } from '@app/shared/data/base.models';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { ReverseAsin, ReverseAsinQuickAaddStates } from '@app/shared/data/reverse-asin/reverse-asin.models';
import { ReverseAsinActions } from '@app/shared/data/reverse-asin/reverse-asin.actions';
import { AsinCompareActions } from '@app/shared/data/asin-compare/asin-compare.actions';
import { R2aProductSelectionComponent } from '@app/client/reverse-asin/product-selection/r2a-product-selection.component';
import { createSelector } from 'reselect';

const INITIAL_STATE: AppState = {
  data          : new FePaginationCollection<ReverseAsin>([]),
  _data         : new FePaginationCollection<ReverseAsin>([]),
  complete_data : new FePaginationCollection<ReverseAsin>([]),
  info          : null,
  search_info   : null,
  stats         : null,
  loading       : false,
  button_loading: true,
  // recent_loading  : true,
  group_id      : '',
  recent        : [],
  filters       : { filters: [], showUnfinished: true },
  filtered      : new FePaginationCollection<ReverseAsin>([]),
  error         : null,
  search_done    : false,
  history_error : null,
  history       : [],
  history_loading: false,
  product_search_in_progress: false,
  product_search_error: null,
  product_search_results: [],
  missing_asins: [],
  progress: 20,
  progress_step: 0,
};

export function reverseAsinReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type === ReverseAsinActions.TYPES.SET_ACTIVE_ASIN) {
    return {
      ...state,
      data          : new FePaginationCollection<ReverseAsin>([]),
      _data         : new FePaginationCollection<ReverseAsin>([]),
      active_product: action.data.asin,
      button_loading: action.data.button_loading,
      group_id      : action.data.group_id,
      error         : null
    };
  }

  if ((action.type === ReverseAsinActions._events.keywords_received
    || action.type === ReverseAsinActions.TYPES.FINISHED)
    && action.data.group_id === state['group_id']) {

    if (!!action.data.error && action.data.error.length > 0) {
      return {
        ...state,
        button_loading: false,
        _data         : ReverseAsin.collect([], ReverseAsin.FRONTEND_PAGINATION),
        error         : action.data.error[0],
        search_done    : true
      };
    }

    // TODO: Check searched asins
    const searchedAsins: string[] = state['search_info']['asins'];

    const foundAsins: {[asin: string]: any} = [];

    action.data.info.forEach(info => {
        [info.asin, ...info.children].filter(asin  => searchedAsins.includes(asin)).forEach(asin => {
          if (!(asin in foundAsins)) {
            foundAsins[asin] = {...info, searchedAsin: asin};
          }
        });
    });

    const missingAsins: string[] = searchedAsins.filter(asin => !(asin in foundAsins));
    const searchInfos: any[] = searchedAsins.reduce((acc, asin) => asin in foundAsins ? [...acc, foundAsins[asin]] : acc, []);

    return {
      ...state,
      button_loading: false,
      _data         : ReverseAsin.collect(Object.values(action.data.keywords), ReverseAsin.FRONTEND_PAGINATION),
      info          : searchInfos,
      stats         : action.data.stats,
      // recent: state['recent'].map(x => x.keyword == action.data.keyword ? x : {...x, finished_at: action.data.finished_at})
      search_done    : true,
      missing_asins: missingAsins,
    };
  }

  if (action.type === PaginationActions.getPagActionTypes(ReverseAsin).APPEND_DATA) {
    return {
      ...state,
      _data: (state as any)._data.clone().updateOrPushMany(action.data, 'keyword', false, (old, nw) => {
        for (const k in old) {
          if (!(k in nw) || nw[k] === false) {
            nw[k] = old[k];
          }
        }
        return nw;
      })
    };
  }

  if (action.type === PaginationActions.getPagActionTypes(ReverseAsin).LOAD_PAGE) {
    state = {
      ...state,
      search_done: false
    };
  }

  if (action.type === PaginationActions.getPagActionTypes(ReverseAsin).LOAD_PAGE_FAILED) {
    state = {
      ...state,
      button_loading: false
    };
  }

  if (action.type === ReverseAsinActions.TYPES.APPLY_FILTERS) {
    return {
      ...state,
      filters: { ...state['filters'], filters: [...action.data || []] },
      loading: true,
    };
  }

  if (action.type === ReverseAsinActions.TYPES.TOGGLE_UNFINISHED) {
    return {
      ...state,
      filters: { ...state['filters'], showUnfinished: action.data }
    };
  }

  if (action.type === ReverseAsinActions.TYPES.KEYWORDS_REQUESTED) {
    return {
      ...state,
      search_info: action.data,
      info: null,
      stats: null,
      search_done: false,
      missing_asins: [],
      progress: 20,
      progress_step: 0
    };
  }

  if (action.type === ReverseAsinActions.TYPES.SET_COMPLETE_DATA) {
    return {
      ...state,
      complete_data: action.data.data
    };
  }

  if (action.type === ReverseAsinActions.TYPES.SET_FILTERED) {
    return {
      ...state,
      filtered: action.data.data
    };
  }

  if (action.type === ReverseAsinActions.TYPES.RESET) {
    return {
      ...state,
      data    : new FePaginationCollection<ReverseAsin>([]),
      _data   : new FePaginationCollection<ReverseAsin>([]),
      filters : { filters: [], showUnfinished: true },
      filtered: new FePaginationCollection<ReverseAsin>([]),
      info: null,
      stats: null,
      search_info: null,
    };
  }

  if (action.type === ReverseAsinActions.TYPES.LOAD_HISTORY) {
    return {
      ...state,
      history_error: null,
      history_loading: true
    };
  }

  if (action.type === ReverseAsinActions.TYPES.LOAD_HISTORY_SUCCEEDED) {
    return {
      ...state,
      history: action.data,
      history_error: null,
      history_loading: false
    };
  }

  if (action.type === ReverseAsinActions.TYPES.LOAD_HISTORY_FAILED) {
    return {
      ...state,
      history: [],
      history_error: action.data,
      history_loading: false
    };
  }

  if (action.type === ReverseAsinActions.TYPES.FIND_PRODUCTS_FOR_KEYWORD) {
    return {
      ...state,
      product_search_in_progress: true,
      product_search_error: null,
      product_search_results: []
    };
  }

  if (action.type === ReverseAsinActions.TYPES.FIND_PRODUCTS_FOR_KEYWORD_FAILED) {
    return {
      ...state,
      product_search_in_progress: false,
      product_search_error: R2aProductSelectionComponent.E_CODE_GENERAL,
      product_search_results: []
    };
  }

  if (action.type === ReverseAsinActions.TYPES.FIND_PRODUCTS_FOR_KEYWORD_CLEAR) {
    return {
      ...state,
      product_search_in_progress: false,
      product_search_error: null,
      product_search_results: []
    };
  }

  if (action.type === ReverseAsinActions.TYPES.SET_PROGRESS) {
    return {
      ...state,
      progress: action.data.progress,
      progress_step: action.data.step
    };
  }

  if (action.type === AsinCompareActions.TYPES.COMPARE_SUCCEEDED) {
    const keywords = action.data.results.keywords;
    return {
      ...state,
      _data: state['_data'].map((x: ReverseAsin) => {
        if (x.keyword in keywords) {
          return new ReverseAsin({
            ...x.getData(),
            myAsinTags: keywords[x.keyword]['t'],
            myAsinRank: keywords[x.keyword]['r'],
            quickAddState: keywords[x.keyword]['t'].includes('T') ? ReverseAsinQuickAaddStates.ADDED : ReverseAsinQuickAaddStates.READY
          });
        }
        return x;
      }),
    };
  }

  return paginationReducer(ReverseAsin)(state, action);
}

export const selectTargetAsinCount = createSelector([
  state => state['reverse_asin']['info']
], (state) => {
    return !!state ? state.length : 0;
});
