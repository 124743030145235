import { Injectable } from "@angular/core";
import { IIntercom } from "@app/shared/data/intercom/intercom.interface";
import { timezones } from "@app/shared/data/timezones";
import { User } from "@app/shared/data/user/user.models";
import { environment } from "@env/environment";
import { Intercom } from "ng-intercom";
import { AuthService } from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class IntercomService {
  interval: any;
  intervalMiliseconds = 500;
  isIntercomInitiated = false;

  constructor(private intercom: Intercom, private authService: AuthService) {
    this.checkIntercomIsBooted();
  }

  public init() {
    const isAuthenticated = this.authService.isAuthenticated();
    const user: User = this.authService.getUser();

    if (!isAuthenticated || this.isIntercomInitiated || (user && !user.intercom_hash)) {
      return;
    }

    const intercomData = this.getIntercomData(user);

    this.intercom.boot(intercomData);
    this.isIntercomInitiated = true;
  }

  public update(user: User) {
    const intercomData = this.getIntercomData(user);
    this.intercom.update(intercomData);
  }

  public shutdown() {
    this.intercom.shutdown();
    this.isIntercomInitiated = false;
  }

  private getIntercomData(user: User): IIntercom {
    const timezone = timezones.find(t => t.id === user.timezone);

    return {
      app_id: environment.INTERCOM_APP_ID,
      user_id: user.id.toString(),
      user_hash: user.intercom_hash,
      email: user.email,
      created_at: new Date(user.registration_finished_at).getTime(),
      name: `${user.first_name} ${user.last_name}`,
      language_override: user.locale,
      phone: user.phone_number,
      timezoneOffset: timezone ? timezone.value : null,
      timezoneLabel: timezone ? timezone.label : null,
      plan: user.plan_name,
      subAccount: user.isSubUser(),
      source: user.source,
      referrer: user.referrer,
      utm_source: user.utm_source,
      utm_medium: user.utm_medium,
      utm_campaign: user.utm_campaign,
      utm_term: user.utm_term,
      utm_content: user.utm_content,
    };
  }

  /**
   * This function will try to repeat initate intercom if it hasn't happened on the first
   * page load. It will stop working when intercom will be initated.
   */
  private checkIntercomIsBooted() {
    clearInterval(this.interval);

    this.interval = setInterval(() => {
      if (!this.isIntercomInitiated) {
        this.init();
      } else {
        clearInterval(this.interval);
      }

    }, this.intervalMiliseconds);
  }

  public openIntercom() {
    this.intercom.show();
  }
}
