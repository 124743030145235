import { BaseModel } from "@app/shared/data/base.models";

export class Notification extends BaseModel {
  static className = 'Notification';

  id: string;
  title: string;
  message: string;
  message_short: string;
  url: string;
  source: string;
  icon: string;
  type: string;
  query_params?: any[];
  eventAction?: string;
  credential_id?: number;
  action_id?:string;
  read_at?: Date;
  _isNew: boolean;

  get _source() {
    return this.source == '__user'
      ? 'Account'
      : this.source
  }

  public markAsNew() {
    this._isNew = true;
  }

  get router_link() {

    if (this.query_params) {
      return [this.url, this.query_params];
    }

    if (this.url) {
      return [this.url];
    }

    return null;
  }
}
