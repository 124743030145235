import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import { PayoutAdmin } from "@app/shared/data/admin/payout-affiliate/payout-admin.models";
import { User } from "@app/shared/data/user/user.models";

@Injectable()
export class PayoutAdminActions extends PaginationActions {
  protected model = PayoutAdmin;

  static readonly TYPES = {
    FILTER       : '[PAYOUTADMIN]FILTER',
    PAYPAL       : '[PAYOUTADMIN]PAYPAL',
    PAY          : '[PAYOUTADMIN]PAY_COMMISSION',
    PAY_SUCCEEDED: '[PAYOUTADMIN]PAY_COMMISSION_SUCCEEDED',
    PAY_FAILED   : '[PAYOUTADMIN]PAY_COMMISSION_FAILED',
  };

  filter(filters): StAction {
    return {
      type: PayoutAdminActions.TYPES.FILTER,
      data: filters
    }
  }

  paypal(affiliate: PayoutAdmin): StAction {
    return {
      type: PayoutAdminActions.TYPES.PAYPAL,
      data: {
        affiliate: affiliate,
      }
    }
  }

  pay(commisionId: number): StAction {
    return {
      type: PayoutAdminActions.TYPES.PAY,
      data: {
        commisionId: commisionId
      }
    }
  }

  payFailed(commisionId: number, error: any): StAction {
    return {
      type: PayoutAdminActions.TYPES.PAY_FAILED,
      data: {
        commisionId: commisionId,
        error      : error
      }
    }
  }
}
