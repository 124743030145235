import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BreadcrumbComponent } from "@app/shared/layout/breadcrumb/breadcrumb.component";
import { ButtonComponent } from "@app/shared/layout/forms/button/button.component";
import { SvgIconModule } from "@app/shared/layout/icon/svg-icon.module";

let COMPONENTS = [
  ButtonComponent
];
let DIRECTIVES = [
];

@NgModule({
  imports     : [
    CommonModule,
    SvgIconModule,
  ],
  declarations: [
    ...COMPONENTS, ...DIRECTIVES,
  ],
  exports     : [
    ...COMPONENTS, ...DIRECTIVES,
  ]
})
export class ButtonsModule {
}
