import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewRef } from '@angular/core';
import { Subject } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ISearchedProduct } from '@app/shared/data/browse-products/browse-products.models';
import { BrowseProductsService } from '@app/client/v2/amazon/browse-products.service';

@Component({
  selector: 'st-browse-products',
  template: `
    <div class="competitors-browse-products__wrapper">
      <div class="competitors-browse-products__header" *ngIf="!isAsinSearchModeActive">
        <div class="competitors-browse-products__header-column">
          <div class="product-list-search-wrapper">
            <input class="product-list-search-wrapper__input"
                   #searchInput type="text"
                   [disabled]="browseProductsService.searching$|async"
                   (keyup.enter)="searchProducts(searchInput.value)"
                   placeholder="ENTER KEYWORD OR A PHRASE" i18n-placeholder>
            <st-icon class="product-list-search-wrapper__icon" icon="search_blue"></st-icon>
          </div>
          <st-button-v2 color="v2_blue_empty" label="LOOK UP PRODUCTS" i18n-label
                        [loading]="browseProductsService.searching$|async"
                        [disabled]="browseProductsService.searching$|async"
                        (clicked)="searchProducts(searchInput.value)"
          ></st-button-v2>
        </div>
      </div>

      <st-browse-products-table
        [products]="browseProductsService.sortedProducts$|async"
        [isSearching]="browseProductsService.searching$|async"
        [error]="browseProductsService.getError()|async"
        [productSelectLimit]="productSelectLimit"
        [preselectedProductsAsins]="preselectedProductsAsins"
        (productsSelected)="productsSelected.next($event)"
        (sortChange)="browseProductsService.performSort($event)"
      ></st-browse-products-table>
    </div>
  `,
  styleUrls: ['./browse-products.component.scss']
})
export class BrowseProductsComponent implements OnDestroy, OnInit {

  @Output() productsSelected: EventEmitter<ISearchedProduct[]> = new EventEmitter();

  @Input() productSelectLimit: number|null = null;

  @Input() asinsToSearch: string[];

  @Input() isAsinSearchModeActive: boolean;

  @Input() preselectedProductsAsins: string[];

  private destroy$: Subject<void> = new Subject();

  constructor(
    private i18n: I18n,
    private cd: ChangeDetectorRef,
    public browseProductsService: BrowseProductsService,
  ) {
  }

  ngOnDestroy() {
    this.browseProductsService.clearSearch();
    this.destroy$.next();
    this.destroy$.complete();
  }

  searchProducts(searchTerm: string) {
    this.productsSelected.next([]);
    this.browseProductsService.performSearch(searchTerm);
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }

  ngOnInit(): void {
    if (this.isAsinSearchModeActive) {
      this.browseProductsService.performAsinSearch(this.asinsToSearch);
    }
  }
}
