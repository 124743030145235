import { StAction } from '@app/shared/data/st-action';
import { AppState } from '@app/shared/data/app-state.model';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { LS } from '@app/shared/services/local-storage.service';
import { Collection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { AppVersion } from '@app/shared/data/version/version.models';
import { VersionActions } from '@app/shared/data/admin/version/version.actions';

export interface VersionAdminFilters {
  searchTerm: string;
}

const emptyVersion = new AppVersion();
emptyVersion.title = '';
emptyVersion.description = '';

const INITIAL_STATE: AppState = {
  data: new Collection<AppVersion>([]),
  loading: false,
  saving: false,
  error: null,
  editing_version: emptyVersion,
  loading_version: false,
  _filters: loadFilters(),
  _be_pagination: {
    page: 1,
    sort: [],
    limit: LS.forActiveUser().getPerPage('admin-last-launch') || 50,
  },
};

function loadFilters() {
  return {
    searchTerm: '',
  };
}

export function versionReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === PaginationActions.getPagActionTypes(AppVersion).LOAD_PAGE) {
    LS.forActiveCredential().setPerPage(action.data.pagination.perPage, 'admin-last-launch');

    return {
      ...state,
      _be_pagination: {
        page: action.data.pagination.page,
        sort: action.data.pagination.sort,
        limit: action.data.pagination.perPage,
      },
      loading: true,
    };
  }

  if (action.type === VersionActions.TYPES.EDITING_VERSION) {
    return {
      ...state,
      loading_version: true,
      error: null,
    };
  }

  if (action.type === VersionActions.TYPES.EDITING_VERSION_SUCCEEDED) {
    return {
      ...state,
      editing_version: action.data.version,
      loading_version: false,
      error: null,
    };
  }

  if (action.type === VersionActions.TYPES.EDITING_VERSION_FAILED) {
    console.log('Setting Version failed with error: ', action.data);

    return {
      ...state,
      loading_version: false,
      error: action.data,
    };
  }

  if (action.type === VersionActions.TYPES.SAVE) {
    return {
      ...state,
      saving: true,
      error: null,
    };
  }

  if (action.type === VersionActions.TYPES.SAVE_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].clone().updateOrPush(action.data.version),
      editing_version: action.data.version,
      saving: false,
      error: null,
    };
  }

  if (action.type === VersionActions.TYPES.SAVE_FAILED) {
    console.log('Saving Version failed with error: ', action.data);

    return {
      ...state,
      saving: false,
      error: action.data,
    };
  }

  if (action.type === VersionActions.TYPES.REMOVE_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].clone().filter(p => p.id !== action.data.version.id),
      error: null,
    };
  }

  if (action.type === VersionActions.TYPES.REMOVE_FAILED) {
    console.log('Removing Version failed with error: ', action.data);

    return {
      ...state,
      error: action.data,
    };
  }

  return paginationReducer(AppVersion)(state, action);
}
