import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';
import { ApiTokenActions } from '@app/shared/data/api-token/api-token.actions';

const INITIAL_STATE: AppState = {
  data    : new Collection<ApiToken>([]),
  clearTokens: new Collection<ApiToken>([]),
  currentToken: null,
  loadingCurrentToken: false,
  loading : false,
  creating: false,
  _error  : null,
};

export function apiTokenReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === ApiTokenActions.TYPES.LOAD || action.type === ApiTokenActions.TYPES.LOAD_ALL) {
    return {
      ...state,
      loading: true,
      creating: false,
      _error : null,
    };
  }

  if (action.type === ApiTokenActions.TYPES.LOAD_SUCCEEDED) {
    return {
      ...state,
      clearTokens: action.data,
      loading: false,
      _error : null,
    };
  }

  if (action.type === ApiTokenActions.TYPES.LOAD_ALL_SUCCEEDED) {
    return {
      ...state,
      data   : action.data,
      loading: false,
      _error : null,
    };
  }

  if (action.type === ApiTokenActions.TYPES.LOAD_FAILED
    || action.type === ApiTokenActions.TYPES.REVOKE_FAILED
    || action.type === ApiTokenActions.TYPES.CREATE_FAILED
  ) {
    return {
      ...state,
      loading: false,
      _error : action.data,
    };
  }

  if (action.type === ApiTokenActions.TYPES.REVOKE || action.type === ApiTokenActions.TYPES.CREATE) {
    return {
      ...state,
      loading: true,
      _error : null,
    };
  }

  if (action.type === ApiTokenActions.TYPES.REVOKE_SUCCEEDED) {
    const updatedToken = action.data;
    return {
      ...state,
      data   : state['data'].map((token: ApiToken) => {
        if (token.value === updatedToken.value) {
          return {
            ...token,
            ...updatedToken,
          };
        }

        return token;
      }),
      loading: false,
      _error : null,
    };
  }

  if (action.type === ApiTokenActions.TYPES.CREATE_SUCCEEDED) {
    return {
      ...state,
      data   : [
        ...state['data'],
        action.data,
      ],
      loading: false,
      _error : null,
    };
  }

  if (action.type === ApiTokenActions.TYPES.GET_SINGLE) {
    return {
      ...state,
      loadingCurrentToken: true,
    }
  }

  if (action.type === ApiTokenActions.TYPES.GET_SINGLE_SUCCESS) {
    return {
      ...state,
      currentToken: action.data,
      loadingCurrentToken: false,
    }
  }

  if (action.type === ApiTokenActions.TYPES.CLEAR_SINGLE_TOKEN) {
    return {
      ...state,
      currentToken: null,
      loadingCurrentToken: false,
    }
  }

  return state;
}
