import { filter, map, skip, takeUntil, zip } from 'rxjs/operators';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Plan } from '@app/shared/data/plan/plan.models';
import { BehaviorSubject, Subject } from 'rxjs';
import { Collection } from '@app/shared/data/base.models';
import { PlanActions } from '@app/shared/data/plan/plan.actions';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux, select } from '@angular-redux/store';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { Subscription as StSubscription } from '@app/shared/data/subscription/subscription.models';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'st-update-plan',
  templateUrl: './update-plan.component.html',
  styleUrls: ['./update-plan.component.scss']
})
export class UpdatePlanComponent implements OnInit, OnDestroy {
  @Input() type: string;

  @ViewChild(ModalComponent, {static: true}) modal: ModalComponent;

  public title$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public message$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  public activeSubscription: StSubscription;
  public nextSubscription: StSubscription;

  public loadingPlans: boolean;

  @select(['user', '_is_subscribing_to_plan'])
  public subscribingToPlan$: Observable<boolean>;

  public plans: Collection<Plan>;

  private onDestroy$ = new Subject();

  constructor(private ngRedux: NgRedux<AppState>,
              private activeModal: ActiveModal,
              private ref: ChangeDetectorRef,
              private subscriptionActions: SubscriptionActions
  ) {
  }

  ngOnInit() {
    const params = {upgrade: true};

    this.ngRedux.dispatch(PlanActions.load(params));
    this.ngRedux.dispatch(this.subscriptionActions.load());

    this.ngRedux.select(['subscriptions', 'data']).pipe(
      takeUntil(this.onDestroy$)
    )
      .subscribe(
        (subscriptions: Collection<StSubscription>) => {
          if (subscriptions.isNotEmpty()) {
            this.activeSubscription = this.nextSubscription = subscriptions.first(false);

            if (subscriptions.count() === 2) {
              this.nextSubscription = subscriptions.get(1);
            }
          }

          this.ref.markForCheck();
        }
      );

    this.ngRedux.select('plans')
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((plansData) => {
        this.plans = (plansData as any).data.filter((plan: Plan) => plan.feature === 'Keywords');
        this.loadingPlans = (plansData as any)._loading;
        this.ref.detectChanges();
      });

    this.subscribingToPlan$
      .pipe(
        filter(x => !x),
        skip(1),
        zip(
          this.ngRedux.select(['subscriptions', '_error']).pipe(
            filter(e => !!e))
        ),
        takeUntil(this.onDestroy$),
        map(data => data[1]),
        filter((err) => !err)
      )
      .subscribe(() => {
        this.activeModal.close(true);
      });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  closeModal() {
    this.modal.close();
  }
}
