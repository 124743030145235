import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: 'st-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  @Input() pages: number[];
  @Input() currentPage: number;
  @Input() numberOfShownPages = 5;

  @Output() goTofirstPage: EventEmitter<void> = new EventEmitter();
  @Output() goToLastPage: EventEmitter<void> = new EventEmitter();
  @Output() goToNextPage: EventEmitter<void> = new EventEmitter();
  @Output() goToPreviousPage: EventEmitter<void> = new EventEmitter();
  @Output() changePage: EventEmitter<number> = new EventEmitter();

  ngOnInit() { }

  firstPage() {
    this.goTofirstPage.emit();
  }

  lastPage() {
    this.goToLastPage.emit();
  }

  nextPage() {
    this.goToNextPage.emit();
  }

  previousPage() {
    this.goToPreviousPage.emit();
  }

  changeThePage(page: any) {
    if (isNaN(page)) {
      return;
    }

    this.changePage.emit(page);
  }

  isPageNumber(page: any): boolean {
    return isNaN(page);
  }

  get startSliceIndex(): number {
    if (
      ((this.currentPage + Math.floor(this.numberOfShownPages / 2)) < this.numberOfShownPages) ||
      (this.pages || []).length <= this.numberOfShownPages
    ) {
      // Start
      return 0;
    } else if ((this.currentPage + Math.floor(this.numberOfShownPages / 2)) < (this.pages || []).length) {
      // Middle
      return this.currentPage - Math.floor(this.numberOfShownPages / 2) - 1;
    } else {
      // End
      return this.pages.length - this.numberOfShownPages;
    }
  }

  get endSliceIndex(): number {
    return this.startSliceIndex + this.numberOfShownPages;
  }

  get showBeforeDots(): boolean {
    return this.startSliceIndex > 0;
  }

  get showAfterDots(): boolean {
    return (this.pages || []).length > this.numberOfShownPages && this.endSliceIndex < this.pages.length;
  }

  get pagesArray(): any[] {
    return (this.pages || []).slice(this.startSliceIndex, this.endSliceIndex);
  }

  get isLastDisabled(): boolean {
    return this.currentPage === (this.pages || []).length;
  }

  get isFirstDisabled(): boolean {
    return this.currentPage === 1;
  }
}
