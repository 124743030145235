import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { AlertApi } from '@app/shared/data/alert/alert.api';
import { BEAlert, UserAlerts } from '@app/shared/data/alert/alert.models';
import { UserAlertActions } from '@app/shared/data/alert/user/user-alert.actions';
import { AlertsService } from '@app/client/alerts/alerts.service';

@Injectable()
export class UserAlertEpics {

  constructor(private store: NgRedux<AppState>,
              private alertApi: AlertApi,
              private userAlertActions: UserAlertActions) {
  }

  public createEpic() {
    return [
      this.loadPage,
      this.deleteAlert,
      this.bulkDeleteAlerts,
      this.acknowledgeIssue,
      this.bulkAcknowledgeIssues,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const n = next(action);
      if (action.type === PaginationActions.getPagActionTypes(UserAlerts).LOAD_PAGE
        || action.type === UserAlertActions.TYPES.SAVE_SUCCESS
        || action.type === UserAlertActions.TYPES.REMOVE_SUCCESS
        || action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUES_SUCCESS
      ) {
        const state = store.getState()['alerts'];
        const filters = {
          searchTerm: state['_filters']['search'] || '',
        };
        const params = {
          ...state['_be_pagination'],
          ...filters,
          sort: state['_be_pagination']['sort'].join(','),
        };
        this.alertApi.getUserAlerts(params)
            .subscribe(
              (userAlerts: UserAlerts) => {
                this.store.dispatch(
                  this.userAlertActions.setAlerts(userAlerts),
                );
              },
              (response) => {
                this.store.dispatch(this.userAlertActions.loadPageFailed(response));
              },
            );
      }
      return n;
    };
  };

  deleteAlert = store => next => {
    return (action: StAction) => {
      if (action.type === UserAlertActions.TYPES.REMOVE) {
        if (store.getState()['alerts']['loading']) {
          return;
        }

        this.alertApi.delete(action.data.alert, action.data.source)
            .subscribe(
              (alert: BEAlert) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.userAlertActions.removeSuccess());
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.userAlertActions.removeFailed(response));
              },
              () => {
              },
            );
      }

      return next(action);
    };
  };

  bulkDeleteAlerts = store => next => {
    return (action: StAction) => {
      if (action.type === UserAlertActions.TYPES.REMOVE_ALL) {
        if (store.getState()['alerts']['loading']) {
          return;
        }

        this.alertApi.bulkDelete(action.data)
            .subscribe(
              () => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.userAlertActions.removeSuccess());
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.userAlertActions.removeFailed(response));
              },
              () => {
              },
            );
      }

      return next(action);
    };
  };

  acknowledgeIssue = store => next => {
    return (action: StAction) => {
      if (action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUE) {
        if (store.getState()['alerts']['acknowledging']) {
          return;
        }

        this.alertApi.acknowledgeIssues({id: action.data.issue.id, source: AlertsService.userSource}).subscribe(
          (deleted: boolean) => {
            this.store.dispatch(this.userAlertActions.acknowledgedIssueSucceeded(action.data.issue));
          },
          (response) => {
            this.store.dispatch(this.userAlertActions.acknowledgedIssueFailed(response.error.error.message));
          },
          () => {
          },
        );
      }

      return next(action);
    };
  };

  bulkAcknowledgeIssues = store => next => {
    return (action: StAction) => {
      if (action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUES) {
        if (store.getState()['alerts']['acknowledging']) {
          return;
        }

        this.alertApi.acknowledgeIssues(action.data).subscribe(
          () => {
            this.store.dispatch(this.userAlertActions.acknowledgedIssuesSucceeded());
          },
          (response) => {
            this.store.dispatch(this.userAlertActions.acknowledgedIssuesFailed(response.error.error.message));
          },
          () => {
          },
        );
      }

      return next(action);
    };
  };
}
