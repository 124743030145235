import { StAction } from "@app/shared/data/st-action";
import { AppState } from "@app/shared/data/app-state.model";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LS } from "@app/shared/services/local-storage.service";
import { Collection } from "@app/shared/data/base.models";
import {
  LastLaunchAdminPromotion,
  LastLaunchAdminKeyword,
  LastLaunchAdminSlot, LastLaunchAdminProduct, LastLaunchAdminCoupon, LastLaunchAdminScheduledEmail
} from "@app/shared/data/admin/last-launch/last-launch.models";
import { LastLaunchActions } from "@app/shared/data/admin/last-launch/last-launch.actions";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Credential } from "@app/shared/data/credential/credential.models";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";

let emptyLLP = new LastLaunchAdminPromotion();
emptyLLP.keywords = LastLaunchAdminKeyword.collect<LastLaunchAdminKeyword>([]);
emptyLLP.user = new UserAdmin();
emptyLLP.credential = new Credential();
emptyLLP.product = new LastLaunchAdminProduct();
emptyLLP.coupons = LastLaunchAdminCoupon.collect<LastLaunchAdminCoupon>([]);

export type LastLaunchAdminFilters = {
  searchTerm: string,
  past: string,
  status?: any,
};

const INITIAL_STATE: AppState = {
  data                    : new Collection<LastLaunchAdminPromotion>([]),
  slots                   : new Collection<LastLaunchAdminSlot>([]),
  scheduled_emails        : new Collection<LastLaunchAdminScheduledEmail>([]),
  scheduled_email         : new LastLaunchAdminScheduledEmail(),
  user                    : new UserAdmin(),
  product                 : new LastLaunchAdminProduct(),
  full_promotion          : emptyLLP,
  loading                 : false,
  loading_full_promotion  : false,
  loading_slots           : false,
  loading_user            : false,
  loading_scheduled_emails: false,
  loading_scheduled_email : false,
  searching_product       : false,
  saving                  : false,
  saving_slots            : false,
  saving_scheduled_email  : false,
  saving_note             : false,
  error                   : null,
  sending_email           : false,
  _filters                : loadFilters(),
  _be_pagination          : {
    page : 1,
    sort : [],
    limit: LS.forActiveUser().getPerPage('admin-last-launch') || 50
  }
};

function loadFilters() {
  return {
    searchTerm: "",
    status    : "upcoming",
  }
}

export function lastLaunchReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == PaginationActions.getPagActionTypes(LastLaunchAdminPromotion).LOAD_PAGE) {
    LS.forActiveCredential().setPerPage(action.data.pagination.perPage, 'admin-last-launch');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == LastLaunchActions.TYPES.SET_FULL_PROMOTION) {
    return {
      ...state,
      loading_full_promotion: true,
      error                 : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SET_FULL_PROMOTION_SUCCEEDED) {
    return {
      ...state,
      full_promotion        : action.data.promotion,
      loading_full_promotion: false,
      error                 : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SET_FULL_PROMOTION_FAILED) {
    console.log("Setting Full Promotion failed with error: ", action.data);

    return {
      ...state,
      loading_full_promotion: false,
      error                 : action.data,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE) {
    return {
      ...state,
      saving: true,
      error : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_SUCCEEDED) {
    return {
      ...state,
      data          : state['data'].clone().updateOrPush(action.data.promotion),
      full_promotion: action.data.promotion,
      saving        : false,
      error         : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_FAILED) {
    console.log("Saving Promotion failed with error: ", action.data);

    return {
      ...state,
      saving: false,
      error : action.data,
    }
  }

  if (action.type == LastLaunchActions.TYPES.REMOVE_SUCCEEDED) {
    return {
      ...state,
      data : state['data'].clone().filter(p => p.id != action.data.promotion.id),
      error: null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.REMOVE_FAILED) {
    console.log("Removing Promotion failed with error: ", action.data);

    return {
      ...state,
      error: action.data,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_CALENDAR) {
    return {
      ...state,
      loading_slots: true,
      error        : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_CALENDAR_SUCCEEDED) {
    return {
      ...state,
      slots        : action.data.slots,
      loading_slots: false,
      error        : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_CALENDAR_FAILED) {
    return {
      ...state,
      loading_slots: false,
      error        : action.data.error,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS) {
    return {
      ...state,
      loading_scheduled_emails: true,
      error                   : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS_SUCCEEDED) {
    return {
      ...state,
      scheduled_emails        : action.data.scheduledEmails,
      loading_scheduled_emails: false,
      error                   : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS_FAILED) {
    return {
      ...state,
      loading_scheduled_emails: false,
      error                   : action.data.error,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAIL) {
    return {
      ...state,
      loading_scheduled_email: true,
      error                  : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAIL_SUCCEEDED) {
    return {
      ...state,
      scheduled_email        : action.data.scheduledEmail,
      loading_scheduled_email: false,
      error                  : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAIL_FAILED) {
    return {
      ...state,
      loading_scheduled_email: false,
      error                  : action.data.error,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_SCHEDULED_EMAIL) {
    return {
      ...state,
      saving_scheduled_email: true,
      error                 : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_SCHEDULED_EMAIL_SUCCEEDED) {
    let scheduledEmailWithoutUnselectedPromotions = action.data.scheduledEmail.clone(LastLaunchAdminScheduledEmail);
    scheduledEmailWithoutUnselectedPromotions.promotions = scheduledEmailWithoutUnselectedPromotions.promotions.filter(p => p._is_selected);

    return {
      ...state,
      scheduled_emails      : state['scheduled_emails'].clone().updateOrPush(scheduledEmailWithoutUnselectedPromotions),
      scheduled_email       : action.data.scheduledEmail,
      saving_scheduled_email: false,
      error                 : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_SCHEDULED_EMAIL_FAILED) {
    return {
      ...state,
      saving_scheduled_email: false,
      error                 : action.data.error,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_CALENDAR_SLOT) {
    return {
      ...state,
      saving_slots: true,
      error       : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_CALENDAR_SLOT_SUCCEEDED) {
    return {
      ...state,
      slots       : state['slots'].clone().updateOrPush(action.data.slot),
      saving_slots: false,
      error       : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SAVE_CALENDAR_SLOT_FAILED) {
    return {
      ...state,
      saving_slots: false,
      error       : action.data.error,
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_USERS) {
    return {
      ...state,
      loading_user: true,
      error       : null,
      user        : new UserAdmin(),
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_USERS_SUCCEEDED) {
    return {
      ...state,
      loading_user: false,
      error       : null,
      user        : action.data.users.first(new UserAdmin()),
    }
  }

  if (action.type == LastLaunchActions.TYPES.LOAD_USERS_FAILED) {
    return {
      ...state,
      loading_user: false,
      error       : action.data.error,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SEARCH_PRODUCT) {
    return {
      ...state,
      searching_product: true,
      error            : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SEARCH_PRODUCT_SUCCEEDED) {
    return {
      ...state,
      product          : action.data.product,
      searching_product: false,
      error            : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.SEARCH_PRODUCT_FAILED) {
    return {
      ...state,
      product          : new LastLaunchAdminProduct(),
      searching_product: false,
      error            : action.data.error,
    }
  }

  if (action.type == LastLaunchActions.TYPES.ADD_NOTE) {
    return {
      ...state,
      saving_note: true,
      error      : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.ADD_NOTE_SUCCEEDED) {
    let s = { ...state };

    if (state['full_promotion'] && state['full_promotion']['id'] == action.data.promotionId) {
      let promo = state['full_promotion'].clone(LastLaunchAdminPromotion);

      promo.notes.push(action.data.note);

      s = { ...s, full_promotion: promo };
    }

    return {
      ...s,
      saving_note: false,
      error      : null,
    }
  }

  if (action.type == LastLaunchActions.TYPES.ADD_NOTE_FAILED) {
    return {
      ...state,
      saving_note: false,
      error      : action.data.error,
    }
  }

  return paginationReducer(LastLaunchAdminPromotion)(state, action);
}
