import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { ICheckboxChanged } from "../checkbox/checkbox.component";
import { IDropdownOption } from "../models/IDropdownOptions";

@Component({
  selector: 'st-dropdown-checkbox',
  templateUrl: './dropdown-checkbox.component.html',
  styleUrls: ['./dropdown-checkbox.component.scss']
})
export class DropdownCheckboxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() options: IDropdownOption[];
  @Input() title: string;
  @Input() description?: string;
  @Input() displaySelectedOptions = false;
  @Input() displaySearch: boolean = false;
  @Input() displaySelectedOptionsCount: boolean = false;

  @Output() checkboxesUpdated: EventEmitter<{ checked: boolean, option: IDropdownOption }> = new EventEmitter();

  @ViewChild('searchInput') searchInput: ElementRef;

  opened;
  searchString: string = '';

  constructor(private cd: ChangeDetectorRef,
              private i18n: I18n) { }

  ngOnInit() { }
  ngOnDestroy() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.options && JSON.stringify(changes.options.previousValue) !== JSON.stringify(changes.options.currentValue)) {
      this.options = changes.options.currentValue;
      this.searchOptions();
      this.cd.detectChanges();
    }
  }

  toggleDropdown(event) {
    this.opened = !this.opened;
    event?.stopPropagation();

    this.opened && setTimeout(() => this.searchInput?.nativeElement.focus(), 0);
  }

  checkboxChanged(event: ICheckboxChanged, option: IDropdownOption) {
    this.options = this.options.map((internalOption: IDropdownOption) => {
      if (internalOption.id === option.id) {
        return {
          ...internalOption,
          checked: event.checked
        };
      }

      return internalOption;
    });
    this.cd.detectChanges();
    this.checkboxesUpdated.emit({ checked: event.checked, option });
  }

  trackByFn(index, item: IDropdownOption) {
    let tmp = { ...item };
    if (!tmp.checked) {
      tmp.checked = false;
    }
    return JSON.stringify(tmp);
  }

  searchOptions() {
    if(!this.opened && this.searchString) {
      this.opened = true;
    }
    this.options = this.options.map(option => {
      const temp = {...option};
      temp.hide = !option.label.includes(this.searchString);
      return temp;
    });
    this.cd.detectChanges();
  }

  get selectedOptionsValues(): string {
    if (!this.options) {
      return;
    }

    return this.options.filter((option: IDropdownOption) => option.checked).map((option: IDropdownOption) => option.id).join(', ');
  }

  get selectedOptionsValuesCount(): string {
    if (!this.options) {
      return;
    }

    const count = this.options.filter((option: IDropdownOption) => option.checked).map((option: IDropdownOption) => option.id).length;
    return this.i18n(`${count} ${count > 1 ? 'ITEMS' : 'ITEM'} SELECTED`);
  }
}
