import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: "[stNotClosableZone]"
})
export class DropdownNotClosableZoneDirective {

  private _dropdownNotClosabledZone: boolean;

  @Input("stNotClosableZone")
  set dropdownNotClosabledZone(value: boolean) {
    this._dropdownNotClosabledZone = value != null && `${value}` !== 'false';
  };

  constructor(private elementRef: ElementRef) {
  }

  contains(element: HTMLElement) {
    if (this._dropdownNotClosabledZone === false)
      return false;

    const thisElement: HTMLElement = this.elementRef.nativeElement;
    return thisElement.contains(element);
  }
}
