import { BaseModel } from "@app/shared/data/base.models";

export class PayoutAllCommisionAdmin extends BaseModel {
  static className = 'PayoutAllCommisionAdmin';
  user_id: number;
  commision_id: number;
  full_name: string;
  email: string;
  coupon: string;
  lead: string;
  plan: string;
  redeemed: string;
  commision: number;
  scheduled_for: string;
  paid_at?: string;
  cancelled_at: string;
  status: string;
}
