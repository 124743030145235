import { Pipe, PipeTransform } from '@angular/core';
import { ColmComponent } from "../components/colm.component";

@Pipe({name: 'stSort'})
export class StSortPipe implements PipeTransform {
  private compareFn(sortFields: string[]) {
    return (a: any, b: any) => {
        if (!sortFields || sortFields.length <= 0) return 0;
        if (a['_sortItem']) a = a['_sortItem'];
        if (b['_sortItem']) b = b['_sortItem'];

        const desc: boolean = sortFields[0].startsWith('-');
        const sf = desc ? sortFields[0].slice(1) : sortFields[0];
        if (a[sf] === b[sf])
            return this.compareFn(sortFields.slice(1))(a, b);
        if (desc)
            return a[sf] < b[sf] ? 1 : -1;
        return a[sf] > b[sf] ? 1 : -1;
    };
  }
  transform(data: any[], sort: any): ColmComponent[] {
    if(!sort || !Array.isArray(data)) return data;
    let newData = [...data];
    newData.sort(this.compareFn(sort));
    return newData;
  }
}
