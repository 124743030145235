import { PipeTransform, Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'stSearchV2'
})
@Injectable()
export class SearchPipeV2 implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return null;
    if (!args) return value;

    args = args.toLowerCase();

    return value.filter(function (data) {
      return JSON.stringify(data).toLowerCase().includes(args);
    });
  }
}
