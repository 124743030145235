import { Component, Input } from "@angular/core";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-date-item',
  templateUrl: './date.item.component.html',
  styleUrls: ['./date.item.component.scss']
})
export class DateItemTableItemComponent {
  @Input() item: V2TableDataRecord;
}
