import { BaseModel } from '@app/shared/data/base.models';

export class KeywordGenerated extends BaseModel {
  static className = 'KeywordGenerated';
  keyword: string;
  updated?: string;
  depth?: number;
  resultsCount?: number | boolean;
  impressionExact?: number | boolean;
  impressionBroad?: number | boolean;
  impressionP?: number | boolean;
  categories?: string[];

  get opportunityScore(): number | boolean {
    if (this.impressionP !== false && this.resultsCount !== false) {
      const val = Number(this.impressionP) / Math.max(Number(this.resultsCount) || 1, 1);
      return Math.round(val);
    }
    return false;
  }

  get searchVolume(): string | number | boolean {
    if (this.impressionP !== false) {
      return this.impressionP < 200 ? '< 200' : Math.round(Number(this.impressionP)) || false;
    }
    return false;
  }
}
