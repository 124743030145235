import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { ExchangeRate } from './exchange-rates.models';
import { Collection } from '../base.models';

@Injectable()
export class ExchangeRatesActions {
  static readonly TYPES = {
    LOAD: '[EXCHANGE_RATES]LOAD',
    LOAD_SUCCEEDED: '[EXCHANGE_RATES]LOAD_SUCCEEDED',
    LOAD_FAILED: '[EXCHANGE_RATES]LOAD_FAILED',
  };

  load(): StAction {
    return {
      type: ExchangeRatesActions.TYPES.LOAD,
      data: {
      }
    };
  }

  loadSucceeded(rates: Collection<ExchangeRate>): StAction {
    return {
      type: ExchangeRatesActions.TYPES.LOAD_SUCCEEDED,
      data: {
        rates,
      }
    };
  }

  loadFailed(error: any): StAction {
    return {
      type: ExchangeRatesActions.TYPES.LOAD_FAILED,
      data: {
        error,
      }
    };
  }
}
