import { NgModule } from "@angular/core";
import { SharedModule } from "@app/shared/shared.module";
import { PlanPriceComponent } from "./plan-price.component";

@NgModule({
  imports: [
    SharedModule,
  ],
  declarations: [
    PlanPriceComponent,
  ],
  exports: [
    PlanPriceComponent
  ]
})
export class PlanPriceModule { }
