import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { CancellationSurveyComponent } from '@app/client/billing/cancellation-survey/cancellation-survey.component';
import { Subject } from 'rxjs';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { Subscription } from '@app/shared/data/subscription/subscription.models';
import { Plan } from '@app/shared/data/plan/plan.models';

@Component({
  selector       : 'st-billing-current-plan',
  templateUrl    : './current-plan.component.html',
  styleUrls      : ['./current-plan.component.scss'],
  encapsulation  : ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class BillingCurrentPlanComponent implements OnDestroy, OnChanges {
  @Input()
  activeSubscription: Subscription = null;

  @Input()
  nextSubscription: Subscription = null;

  @Input()
  subscribingToPlan = false;

  @Input()
  loading;

  @Input()
  canceling;

  @Input()
  currentPlans;

  @Output() onResubscribe: EventEmitter<Plan> = new EventEmitter();

  savingAmount = 0;

  private _destroy: Subject<void> = new Subject<void>();

  constructor(private ngRedux: NgRedux<AppState>,
              private modalService: ModalService,
              private trackingService: RudderTrackingService,
              private subscriptionActions: SubscriptionActions) {}

  public subscriptionHasChanged(): boolean {
    if (this.activeSubscription && this.activeSubscription.ends_at) {
      return this.nextSubscription.id === this.activeSubscription.id // Cancelled
        || this.activeSubscription.plan.id !== this.nextSubscription.plan.id; // Upgrade, Downgrade
    }
    return false;
  }

  public isFree(): boolean {
    return this.activeSubscription.plan.price <= 0;
  }

  public subscriptionCancelled(): boolean {
    return this.subscriptionHasChanged()
      && !this.activeSubscription.on_trial
      && this.nextSubscription.id === this.activeSubscription.id;
  }

  public subscriptionTrialing(): boolean {
    return this.activeSubscription.on_trial;
  }

  public cancelSubscription(): void {
    if (this.subscribingToPlan || this.canceling) {
      return;
    }
    this.modalService.open(CancellationSurveyComponent).result
      .then((cancelSubscription) => {
        if (!cancelSubscription) {
          return;
        }
        // user has canceled his subscription!
        this.trackingService.trackEvent('Cancel Subscription - Button Clicked', {category: 'Billing Page'});
        this.ngRedux.dispatch(this.subscriptionActions.cancel(this.activeSubscription));
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.activeSubscription || changes.currentPlans) {
      if (this.currentPlans && this.activeSubscription) {
        const plans = this.currentPlans;
        const activePlan = this.activeSubscription.plan;

        const equivalentPlan = plans
          .filter((p) => p.name === activePlan.name && activePlan.annual !== p.annual)
          .first();

        if (equivalentPlan) {
          this.savingAmount = activePlan.annual
            ? (equivalentPlan.price * 12 - activePlan.price)
            : (activePlan.price * 12 - equivalentPlan.price );
        } else {
          this.savingAmount = 0;
        }
      }
    }
  }

  resubscribe() {
    this.onResubscribe.emit(this.activeSubscription.plan);
  }

  ngOnDestroy(): void {
    this._destroy.next();
    this._destroy.complete();
  }

  get isCouponCodeApplied() {
    return Boolean(this.activeSubscription && this.activeSubscription.couponObj);
  }
}
