import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { SortByEnum } from '@app/client/v2/table/table.models';
import { Credential } from '@app/shared/data/credential/credential.models';

@Pipe({
  name: 'stCredentialSortPipe'
})
@Injectable()
export class CredentialSortPipe implements PipeTransform {
  transform(credentials: any, currentSortBy: SortByEnum, currentSortDirection: 'ASC' | 'DESC'): Credential[] {
    if (!credentials || credentials.length === 0) {
      return [];
    }

    return (credentials || []).sort((a: any, b: any) => {
      // Sort by name
      if (SortByEnum.NAME === currentSortBy) {
        return currentSortDirection === 'ASC' ? a.value?.name?.localeCompare(b.name) : b?.value.name?.localeCompare(a.name);
      } else {
        return -1;
      }
    });
  }
}
