import { AppState } from '@app/shared/data/app-state.model';
import { NotificationAction, NotificationsActions } from '@app/shared/data/notification/notifications.actions';
import { Collection } from '@app/shared/data/base.models';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { Notification } from '@app/shared/data/notification/notification.models';

interface NotificationState extends AppState {
  data: Collection<Notification>;
  loading: boolean;
  modal_opened: boolean;
}

const INITIAL_STATE: NotificationState = {
  data        : new Collection(),
  loading     : false,
  modal_opened: false,
};


export function notificationsReducer(state: NotificationState = INITIAL_STATE, action: NotificationAction): AppState {

  if (action.type === NotificationsActions.TYPES.NOTIFICATION_MODAL_CLOSE) {
    return {
      ...state,
      modal_opened: false,
      filter: ''
    };
  }

  if (action.type === NotificationsActions.TYPES.NOTIFICATION_MODAL_SHOW) {
    return {
      ...state,
      modal_opened: true,
      filter: ''
    };
  }

  if (action.type == NotificationsActions.TYPES.NOTIFICATION_MODAL_FILTER) {
    return {
      ...state,
      filter: action.data
    };
  }

  if (action.type === NotificationsActions.TYPES.NOTIFICATION_SET_PAGE) {
    return {
      ...state,
      data: action.data,
      loading: false
    };
  }

  return paginationReducer(Notification)(state, action);
}
