import { Component, AfterViewInit, ElementRef, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector   : 'st-info',
  templateUrl: './info.component.html',
  styleUrls  : ['./info.component.scss']
})
export class InfoComponent {
  buttonText = this.i18n('OK');
  title = this.i18n('Info');
  message = '';
  value = '';
  centerText: boolean;
  successIcon: boolean;

  constructor(private activeModal: ActiveModal,
              private cd: ChangeDetectorRef,
              private i18n: I18n) {}

  close(): void {
    this.activeModal.close(false);
  }

  public setTitle(title: string) {
    this.title = title;
    this.cd.detectChanges();
  }

  public setButtonText(buttonText: string) {
    if (buttonText) {
      this.buttonText = buttonText;
      this.cd.detectChanges();
    }
  }

  public setMessage(message: string) {
    this.message = message;
    this.cd.detectChanges();
  }

  public setCenterText(centerText: boolean) {
    this.centerText = centerText;
    this.cd.detectChanges();
  }

  public setSuccessIcon(successIcon: boolean) {
    this.successIcon = successIcon;
    this.cd.detectChanges();
  }
}
