<st-modal #modal="modal" [showInstructions]="false">
  <div modal-header class="modal-header">
    <div>
      <h2 i18n>Copy keywords</h2>
      <p i18n>Please choose the product you would like keywords to be copied to</p>
    </div>
  </div>
  <div modal-body class="content">
    <div class="product-list">
      <div class="product-list__header">
        <div class="product-list-search-wrapper">
          <input class="product-list-search-wrapper__input" type="text" placeholder="Search Products" [(ngModel)]="searchProductString" (ngModelChange)="searchProducts()">
          <st-icon class="product-list-search-wrapper__icon" icon="search_v2"></st-icon>
        </div>
      </div>
      <st-product-manager-product-list-table
        [products]="products"
        [productVariations]="productVariations"
        [loadingProducts]="loadingProducts"
        [loadingGraphData]="loadingGraphData"
        [loadingProductVariations]="loadingProductVariations"
        [perPageOptions]="perPageOptions"
        [preSelectedOption]="preSelectedOption"
        [variationPerPage]="variationPerPage"
        [variationCurrentPage]="variationCurrentPage"
        [variationTotal]="variationTotal"
        [variationPagesArray]="variationPagesArray"
        [variationFrom]="variationFrom"
        [variationTo]="variationTo"
        [variationLastPage]="variationLastPage"
        [showResetButton]="false"
        [currentDisplayedVariationProducts]="currentDisplayedVariationProducts"
        [bsrGraph]="bsrGraph"
        (onSortBy)="sortBy($event)"
        (onToggleGraph)="toggleGraph($event)"
        (onToggleProductDetails)="toggleProductDetails($event)"
        (onVariationGoToFirstPage)="variationGoToFirstPage()"
        (onVariationGoToLastPage)="variationGoToLastPage()"
        (onVariationNextPage)="variationNextPage()"
        (onVariationPreviousPage)="variationPreviousPage()"
        (onVariationChangePage)="variationChangePage($event)"
        (onVariationPageChange)="variationPageChange($event)"
        (onSelectProduct)="selectProduct($event)">
      </st-product-manager-product-list-table>

      <div class="product-list__footer">
        <div class="product-list__footer-column"></div>
        <div class="product-list__footer-column">
          <st-pagination
            [currentPage]="currentPage"
            [pages]="pagesArray"
            (goTofirstPage)="goToFirstPage()"
            (goToLastPage)="goToLastPage()"
            (goToNextPage)="nextPage()"
            (goToPreviousPage)="previousPage()"
            (changePage)="changePage($event)"></st-pagination>
        </div>
        <div class="product-list__footer-column product-list__footer-column--info">
          <st-pagination-info
              [text]="currentDisplayedProducts"
              [disabled]="loadingProducts"
              [options]="perPageOptions"
              (onPageChange)="pageChange($event)"
              [preSelectedOption]="preSelectedOption"></st-pagination-info>
        </div>
      </div>
    </div>
  </div>
  <div modal-footer class="footer">
    <st-button-v2 (clicked)="cancel()" color="empty" label="Cancel" i18n-label></st-button-v2>
    <st-button-v2 (clicked)="save()" [disabled]="confirmButtonDisabled" label="Confirm selection & copy keywords" i18n-label></st-button-v2>
  </div>
</st-modal>
