import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { PrizmProductFilters, PrizmProduct, PrizmKeyword, PrizmKeywordFilters, Category } from '@app/shared/data/prizm/prizm.models';

/**
 * Prizm actions.
 *
 * @export
 * @class PrizmActions
 * @extends {PaginationActions}
 */
@Injectable()
export class PrizmActions {
  /**
   * Prizm Action types.
   *
   * @static
   * @memberof PrizmActions
   */
  static readonly TYPES = {
    GET_CATEGORIES: '[PRIZM]GET_CATEGORIES',
    GET_CATEGORIES_SUCCEEDED: '[PRIZM]GET_CATEGORIES_SUCCEEDED',
    GET_CATEGORIES_FAILED: '[PRIZM]GET_CATEGORIES_FAILED',
  };



  /**
   * Request to load categories.
   *
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getCategories(marketplaceId = 1): StAction {
    return {
      type: PrizmActions.TYPES.GET_CATEGORIES,
      data: {marketplaceId}
    };
  }

  /**
   * Getting categories succeeded.
   *
   * @param {Category[]} categories
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getCategoriesSucceeded(categories: Category[]): StAction {
    return {
      type: PrizmActions.TYPES.GET_CATEGORIES_SUCCEEDED,
      data: { categories }
    };
  }

  /**
   * Categories failed.
   *
   * @param {*} error
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getCategoriesFailed(error: any): StAction {
    return {
      type: PrizmActions.TYPES.GET_CATEGORIES_FAILED,
      data: { error }
    };
  }
}

@Injectable()
export class PrizmProductActions extends PaginationActions {
  static readonly TYPES = {
    GET_RESULTS: '[PRIZM][PROD]GET_RESULTS',
    GET_RESULTS_SUCCEEDED: '[PRIZM][PROD]GET_RESULTS_SUCCEEDED',
    GET_RESULTS_FAILED: '[PRIZM][PROD]GET_RESULTS_FAILED',
    RESET_RESULTS: '[PRIZM][PROD]RESET_RESULTS',
    GET_HISTORY: '[PRIZM][PROD]GET_HISTORY',
    GET_HISTORY_SUCCEEDED: '[PRIZM][PROD]GET_HISTORY_SUCCEEDED',
    GET_HISTORY_FAILED: '[PRIZM][PROD]GET_HISTORY_FAILED',
    EXPORT_ALL: '[PRIZM][PROD]EXPORT_ALL',
  };

  /**
   * Set actions model.
   *
   * @protected
   * @type {{ className: string }}
   * @memberof PrizmActions
   */
  protected model: { className: string } = PrizmProduct;

  /**
   * Request results with product prizm search filters.
   *
   * @param {PrizmProductFilters} filters
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getResults(filters: PrizmProductFilters): StAction {
    return {
      type: PrizmProductActions.TYPES.GET_RESULTS,
      data: { filters }
    };
  }

  /**
   * Set results for prizm search.
   *
   * @param {PrizmProduct[]} results
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getResultsSucceeded(results: PrizmProduct[]): StAction {
    return {
      type: PrizmProductActions.TYPES.GET_RESULTS_SUCCEEDED,
      data: { results }
    };
  }

  /**
   * Report get results filed.
   *
   * @param {*} error
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getResultsFailed(error: any): StAction {
    return {
      type: PrizmProductActions.TYPES.GET_RESULTS_FAILED,
      data: { error }
    };
  }

  /**
   * Reset prizm products results.
   *
   * @returns StAction
   * @memberof PrizmProductActions
   */
  resetResults(): StAction {
    return {
      type: PrizmProductActions.TYPES.RESET_RESULTS,
      data: {}
    };
  }

  /**
   * Request history for prizm product.
   *
   * @param {string} asin
   * @returns {StAction}
   * @memberof PrizmProductActions
   */
  getHistory(asin: string): StAction {
    return {
      type: PrizmProductActions.TYPES.GET_HISTORY,
      data: {asin}
    };
  }

  /**
   * Set requested history results.
   *
   * @param {*} historyData
   * @returns {StAction}
   * @memberof PrizmProductActions
   */
  getHistorySucceeded(historyData: any): StAction {
    return {
      type: PrizmProductActions.TYPES.GET_HISTORY_SUCCEEDED,
      data: historyData
    };
  }

  /**
   * Handle history request failed.
   *
   * @param {*} error
   * @returns {StAction}
   * @memberof PrizmProductActions
   */
  getHistoryFailed(error: any): StAction {
    return {
      type: PrizmProductActions.TYPES.GET_HISTORY_FAILED,
      data: {error}
    };
  }

  exportAll(fileName: string): StAction {
    return {
      type: PrizmProductActions.TYPES.EXPORT_ALL,
      data: {fileName}
    }
  }
}


@Injectable()
export class PrizmKeywordActions extends PaginationActions {
  static readonly TYPES = {
    GET_RESULTS: '[PRIZM][KEYW]GET_RESULTS',
    GET_RESULTS_SUCCEEDED: '[PRIZM][KEYW]GET_RESULTS_SUCCEEDED',
    GET_RESULTS_FAILED: '[PRIZM][KEYW]GET_RESULTS_FAILED',
  };

  /**
   * Set actions model.
   *
   * @protected
   * @type {{ className: string }}
   * @memberof PrizmActions
   */
  protected model: { className: string } = PrizmKeyword;

  /**
   * Request results with product prizm search filters.
   *
   * @param {PrizmKeywordFilters} filters
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getResults(filters: PrizmKeywordFilters): StAction {
    return {
      type: PrizmKeywordActions.TYPES.GET_RESULTS,
      data: { filters }
    };
  }

  /**
   * Set results for prizm search.
   *
   * @param {PrizmKeyword[]} results
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getResultsSucceeded(results: PrizmKeyword[]): StAction {
    return {
      type: PrizmKeywordActions.TYPES.GET_RESULTS_SUCCEEDED,
      data: { results }
    };
  }

  /**
   * Report get results filed.
   *
   * @param {*} error
   * @returns {StAction}
   * @memberof PrizmActions
   */
  getResultsFailed(error: any): StAction {
    return {
      type: PrizmKeywordActions.TYPES.GET_RESULTS_FAILED,
      data: { error }
    };
  }
}
