import { Injectable } from '@angular/core';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { UserAPI } from '@app/shared/data/user/user.api';
import { User } from '@app/shared/data/user/user.models';
import { Credential } from '@app/shared/data/credential/credential.models';
import { CredentialAPI } from '@app/shared/data/credential/credential.api';
import { ToastService } from '@app/core/services/toast.service';
import { CreditCardAPI } from '@app/shared/data/credit-card/credit-card.api';
import { CreditCard } from '@app/shared/data/credit-card/credit-card.models';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AuthService } from '@app/core/services/auth.service';
import { IntercomService } from '@app/core/services/intercom.service';
import { mergeMap, withLatestFrom } from 'rxjs/operators';
import { generateRedirectUrl } from '@app/shared/data/marketplaces';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { SentryTrackingService } from '@app/shared/tracking/sentry.service';
import { ConnectSPApiOriginEnum } from '../review-request/review-request.models';
import { HttpParams } from '@angular/common/http';
import { environment } from '@env/environment';
import { getTranslationFromResponse } from '@app/helpers';
import { AuthService as Auth0AuthService } from '@auth0/auth0-angular';



@Injectable()
export class UserEpics {
  constructor(private usersActions: UsersActions,
    private userAPI: UserAPI,
    private creditCardAPI: CreditCardAPI,
    private credentialAPI: CredentialAPI,
    private toastService: ToastService,
    private ngRedux: NgRedux<AppState>,
    private i18n: I18n,
    private authService: AuthService,
    private intercomService: IntercomService,
    private rudderTrackingService: RudderTrackingService,
    private sentryTrackingService: SentryTrackingService,
    private auth0Service: Auth0AuthService,

  ) {
  }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.refreshUserTokenWithAuth0,
      this.getApiToken,
      this.attemptAuth,
      this.attemptRegistration,
      this.logout,
      this.switchActiveCredential,
      this.addCreditCard,
      this.updateProfile,
      this.addCredential,
      this.updateCredential,
      this.removeCredential,
      this.getCredentialLimits,
      this.resendVerificationEmail,
      this.userVerified,
      this.trialAccountTimeLeft,
      this.connectSPApi,
    ];
  }

  refreshUserToken = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.REFRESH_TOKEN) {
        this.userAPI.refreshToken()
          .subscribe(
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.authService.storeToken(response.token);
              this.ngRedux.dispatch(this.usersActions.refreshTokenSucceeded(response));
            },
            (error) => {
              //noinspection TypeScriptValidateTypes
              this.authService.clearToken();
              this.ngRedux.dispatch(this.usersActions.refreshTokenFailed(error));
            },
          );
      }
      return next(action);
    };
  }

  refreshUserTokenWithAuth0 = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.REFRESH_TOKEN) {
        this.auth0Service.getAccessTokenSilently().pipe(
          mergeMap((access_token) => this.userAPI.attemptAuth({auth0_access_token: access_token}))
        ).subscribe(
          (response) => {
            //noinspection TypeScriptValidateTypes
            this.authService.storeToken(response.token);
            this.ngRedux.dispatch(this.usersActions.refreshTokenSucceeded(response));
          },
          (error) => {
            //noinspection TypeScriptValidateTypes
            this.authService.clearToken();
            this.ngRedux.dispatch(this.usersActions.refreshTokenFailed(error));
          }
        );
      }
      return next(action);
    };
  }

  getApiToken = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.GET_API_TOKEN) {
        this.userAPI.getApiToken()
          .subscribe(
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.authService.storeToken(response.token);
              this.ngRedux.dispatch(this.usersActions.getApiTokenSucceeded(response));
            },
            (error) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.getApiTokenFailed(error));
            },
          );
      }
      return next(action);
    };
  }

  attemptAuth = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.ATTEMPT_AUTH) {
        this.userAPI.attemptAuth(action.data)
          .subscribe(
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.authService.storeToken(response.token);
              this.rudderTrackingService.alias(response);
              this.sentryTrackingService.identifyUser(response);
              this.ngRedux.dispatch(this.usersActions.attemptAuthSucceeded(response));
              this.ngRedux.dispatch(this.usersActions.trialAccountTimeLeft());
            },
            (response) => {

              if (response.error && response.error.error?.message) {
                response.error.error.message = getTranslationFromResponse([{
                  code: 'error.not_found',
                  translation: this.i18n('User does not exist')
                }], response);
              }

              this.ngRedux.dispatch(this.usersActions.attemptAuthFailed(
                response.error.error
              ));
            },
            () => {

            }
          );
      }
      return next(action);
    };
  }

  attemptRegistration = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.ATTEMPT_REGISTRATION) {
        this.userAPI.attemptRegistration(action.data)
          .subscribe(
            (response: User) => {
              this.authService.storeToken(response.token);
              this.rudderTrackingService.identify(response);
              this.ngRedux.dispatch(this.usersActions.attemptRegistrationSucceeded(response));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.attemptRegistrationFailed(response.error.error));
            },
            () => {

            }
          );
      }
      return next(action);
    };
  }

  logout = () => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.ATTEMPT_LOGOUT) {
        this.userAPI.attemptLogout()
          .pipe(withLatestFrom(this.authService.getUser$()))
          .subscribe(([response, user]: [User, User]) => {
            if (!user.is_impersonated) {
              //noinspection TypeScriptValidateTypes
              this.authService.clearToken();
              this.intercomService.shutdown();

              return this.ngRedux.dispatch(this.usersActions.attemptLogoutSucceeded(new User()));
            }

            this.authService.storeToken(response.token);
            this.ngRedux.dispatch(this.usersActions.attemptLogoutSucceeded(response));
          },
          () => {
            //noinspection TypeScriptValidateTypes
            this.ngRedux.dispatch(this.usersActions.attemptLogoutSucceeded(new User()));
          }
        );
      }
      return next(action);
    };
  }

  switchActiveCredential = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.SWITCH_ACTIVE_CREDENTIAL) {
        this.userAPI.switchCredentials(action.data.id)
          .subscribe(
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.authService.storeToken(response.token);
              this.ngRedux.dispatch(this.usersActions.switchCredentialSucceeded(response));
              this.sentryTrackingService.setCredentialId(action.data.id);
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.switchCredentialFailed(response.error.error));
            },
            () => {

            }
          );
      }
      return next(action);
    };
  }

  addCreditCard = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.ADD_CREDIT_CARD) {
        this.creditCardAPI.store(action.data.id)
          .subscribe(
            (card: CreditCard) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.addCreditCardSucceeded(card));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.addCreditCardFailed(response.error.error.message));
            },
          );
      }
      return next(action);
    };
  }

  updateProfile = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.UPDATE_PROFILE) {
        this.userAPI.updateProfile(action.data, this.authService.getUserId())
          .subscribe(
            (response) => {
              //noinspection TypeScriptValidateTypes
              if (action.data.action === 'password_update') {
                this.authService.storeToken(response.token);
              }

              this.ngRedux.dispatch(this.usersActions.updateProfileSucceeded(response, action.data.action));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.updateProfileFailed(response.error.error, action.data.action));
            }
          );
      }
      return next(action);
    };
  }

  addCredential = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.ADD_CREDENTIAL) {
        this.credentialAPI.create(action.data)
          .subscribe(
            (credential: Credential) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.addCredentialSucceeded(credential));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.addCredentialFailed(response.error.error));
            }
          );
      }
      return next(action);
    };
  }

  trialAccountTimeLeft = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.TRIAL_ACCOUNT_TIME_LEFT) {
        this.userAPI.trialAccountTimeLeft()
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.usersActions.trialAccountTimeLeftSucceeded(response));
            }
          );
      }
      return next(action);
    };
  }

  updateCredential = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.UPDATE_CREDENTIAL) {
        this.credentialAPI.update(action.data.credential)
          .subscribe(
            (credential: Credential) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.updateCredentialSucceeded(credential));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.updateCredentialFailed(response.error.error));
            }
          );
      }
      return next(action);
    };
  }

  removeCredential = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.REMOVE_CREDENTIAL) {
        this.credentialAPI.remove(action.data)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.removeCredentialSucceeded(action.data));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.removeCredentialFailed(response));
            }
          );
      }
      return next(action);
    };
  }

  getCredentialLimits = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.GET_CREDENTIAL_LIMITS) {
        this.credentialAPI.getCredentialLimits(action.data)
          .subscribe(
            (res) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.getCredentialLimitsSucceeded(action.data, res.data));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.getCredentialLimitsFailed(response));
            }
          );
      }
      return next(action);
    };
  }

  connectSPApi = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.CONNECT_SELLING_PARTNER_API) {
        const {
          credentialId,
          marketplaceId,
          origin,
        } = action.data;

        this.userAPI.spaInit(credentialId, origin)
          .subscribe(
            (response: { id: string }) => {

              if (origin === ConnectSPApiOriginEnum.AMZ_MARKETPLACE) {
                let params = new HttpParams();
                params = params.set('amazon_state', action.data.amazonState);
                params = params.set('state', response.id);
                if (!environment.production) {
                  params = params.set('version', 'beta');
                }

                window.location.href = action.data.amazonCallbackUri + '?' + params.toString();
              } else {
                window.location.href = generateRedirectUrl(marketplaceId, response.id);
              }

              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.connectSellingPartnerAPISuccess(response.id, action.data.merchantId));
            },
            () => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.usersActions.connectSellingPartnerAPIError());
            }
          );
      }
      return next(action);
    };
  }

  resendVerificationEmail = store => next => {
    return (action) => {
      if (action.type === UsersActions.TYPES.RESEND_VERIFICATION_EMAIL) {
        this.userAPI.resendVerificationEmail(action.data)
          .subscribe(
            (success) => {
              this.toastService.success(
                this.i18n('Verification'),
                this.i18n('Check your email'));
            },
            (error) => {
              if (error.code === 'already_verified') {
                this.toastService.warning(
                  this.i18n('Already verified'),
                  this.i18n('You have already verified your email address.'));

                this.ngRedux.dispatch(UsersActions.verificationSucceeded());
              } else {
                this.toastService.error(
                  this.i18n('Verification'),
                  this.i18n('Something went wrong while verifying your email address')
                );
              }
            }
          );
      }

      return next(action);
    };
  }

  userVerified = store => next => {
    return action => {
      if (action.type === UsersActions.EVENTS.USER_VERIFIED) {
        this.ngRedux.dispatch(UsersActions.verificationSucceeded());
      }
      return next(action);
    };
  }
}
