import { Component, EventEmitter, Input, Output } from "@angular/core";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-sfr-item',
  templateUrl: './sfr.item.component.html',
  styleUrls: ['./sfr.item.component.scss']
})
export class SfrItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() onGetBrandAnalytics: EventEmitter<any> = new EventEmitter();
  @Output() onToggleKeywordDetails: EventEmitter<any> = new EventEmitter();

  constructor(
    private i18n: I18n,
  ) { }

  getBrandAnalytics() {
    this.onGetBrandAnalytics.emit();
  }

  toggleKeywordDetails(event: MouseEvent) {
    this.onToggleKeywordDetails.emit(event);
  }

  get informationTooltip(): string {
    return this.i18n(`Click on the icon for more information`);
  }
}
