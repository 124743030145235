<div class="header">
  <div class="header-titles">
    <h2 class="header__title" i18n>Keywords List</h2>
    <h3 class="header__subtitle" i18n>The list below contains all the keywords from your competitors you’ve
      previously imported. You can add you own keywords as well.<br>*This is our suggestion for top priority
      keywords. You may sort the list the way you find it’s the best. </h3>
  </div>
</div>

<div class="upgrade-plan-banner" *ngIf="upgradePlanBannerVisible">
  <st-icon icon="warning-yellow"></st-icon>
  <span>
    <ng-container i18n>You have reached keyword limits for a plan you are currently on. If you wish to see more,
    </ng-container>
    <a class="upgrade-plan-banner__link" [routerLink]="['/billing']" i18n>upgrade your plan.</a>
  </span>
</div>

<div class="progress-wrapper" *ngIf="progressWrapperActive">
  <div class="progress-wrapper__icon-wrapper">
    <div class="progress-wrapper__icon progress-wrapper__icon--1"
      [class.progress-wrapper__icon--active]="progress <= 33"></div>
    <div class="progress-wrapper__icon progress-wrapper__icon--2"
      [class.progress-wrapper__icon--active]="progress > 33 && progress <= 66"></div>
    <div class="progress-wrapper__icon progress-wrapper__icon--3"
      [class.progress-wrapper__icon--active]="progress === 100"></div>
  </div>
  <div class="progress-wrapper__title">
    {{progressTitle}}
  </div>
  <div class="progress-wrapper__count">{{progress}}%</div>
  <div class="progress-wrapper__bar-wrapper">
    <div class="progress-wrapper__bar-wrapper-line" [style.minWidth.%]="progress"></div>
  </div>
  <div class="progress-wrapper__remove" *ngIf="progress === 100" (click)="closeProgressWrapper()"></div>
</div>

<div class="keywords-wrapper">
  <div class="keywords-title">
    <div class="product-list-search-wrapper">
      <input class="product-list-search-wrapper__input" type="text" placeholder="SEARCH THE LIST" i18n-placeholder
        [(ngModel)]="query">
      <st-icon class="product-list-search-wrapper__icon" icon="search_v2"></st-icon>
    </div>

    <st-advanced-filters [selectedFilters]="appliedFilters" [availableFilters]="availableFilters"
      [isDisabled]="progressWrapperActive" (filtersApplied)="filtersApplied($event)">
    </st-advanced-filters>

    <st-product-manager-bulk-actions
      [currentAsin]="productAsin"
      [selectedKeywords]="checkedKeywords"
      [isDisabled]="progressWrapperActive || updatingKeywords">
    </st-product-manager-bulk-actions>

    <st-button-v2 class="recalculate-priority" color="v2_blue_empty" i18n-label label="RECALCULATE PRIORITY" (clicked)="recalculate()"></st-button-v2>

    <st-button-v2 class="add-keywords-manually" [disabled]="!activeProduct || progressWrapperActive"
      (clicked)="openKeywordsModal()" color="v2_blue_empty" i18n-label label="+ ADD KEYWORDS MANUALLY"></st-button-v2>
  </div>

  <div class="selected-filters-wrapper" *ngIf="appliedFilters?.length > 0">
    <div class="selected-filters__column selected-filters__column--title" i18n>Filters Applied:</div>
    <div class="selected-filters__column">
      <div class="selected-filters">
        <div class="selected-filter selected-filter--badge" *ngFor="let filter of appliedFilters">
          <div class="selected-filter__value">{{filter.title}} {{operatorTranslator.translate(filter.operator)}} {{filter.valueAsAString || filter.value}}</div>
          <st-icon class="selected-filter__remove" (click)="removeFilter(filter)" icon="cross-v2" data-qa="cm_remove_advanced_filters"></st-icon>
        </div>
      </div>
    </div>
  </div>

  <st-table-v2
    [columns]="tableColumns"
    tableId="productManagerKeywords"
    [data]="tableData | stSearchV2: query | stKeywordPipe: appliedFilters | stKeywordSortPipe: currentSortBy:currentSortDirection | slice: (currentPage-1) * perPage : currentPage * perPage"
    [showResizableIcon]="true"
    [loadingItems]="loadingKeywords"
    (onGetBrandAnalytics)="getBrandAnalytics($event)"
    (onToggleKeywordDetails)="toggleKeywordDetails($event)"
    (onIndexRefresh)="keywordIndex()"
    [isFilterActive]="isFilterActive"
    [activePriorityFilter]="activePriorityFilter"
    (onFilterByPriority)="filterByPriority($event)"
    (onSortBy)="sortBy($event)"
    [currentSortDirection]="currentSortDirection"
    (onPriorityChange)="onPriorityChange($event)"></st-table-v2>

  <div class="product-list__footer">
    <div class="product-list__footer-column"></div>
    <div class="product-list__footer-column">
      <st-pagination [currentPage]="currentPage" [pages]="pagesArray" (goTofirstPage)="goToFirstPage()"
        (goToLastPage)="goToLastPage()" (goToNextPage)="nextPage()" (goToPreviousPage)="previousPage()"
        (changePage)="changePage($event)"></st-pagination>
    </div>
    <div class="product-list__footer-column product-list__footer-column--info">
      <st-pagination-info [text]="currentDisplayedProducts" [disabled]="loadingKeywords" [options]="perPageOptions"
        (onPageChange)="pageChange($event)" [preSelectedOption]="preSelectedOption"></st-pagination-info>
    </div>
  </div>
</div>
