
import {take, filter} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { LsTaskActions } from "@app/shared/data/tasks/ls-task/ls-task.actions";
import { LsTaskApi } from "@app/shared/data/tasks/ls-task/ls-task.api";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { KeywordGeneratedActions } from "@app/shared/data/keyword-generated/keyword-generated.actions";
import { LsTask } from "@app/shared/data/tasks/ls-task/ls-task.models";

@Injectable()
export class LsTaskEpics {
  constructor(
    private store: NgRedux<AppState>,
    private lsTaskApi: LsTaskApi,
    private lsTaskActions: LsTaskActions,
    private keywordGeneratedActions: KeywordGeneratedActions) {}

  public createEpic() {
    return [
      this.listLoad,
      this.load,
      this.open
    ];
  }

  listLoad = store => next => action => {

    if(action.type == LsTaskActions.TYPES.LIST
      || action.type == KeywordGeneratedActions.TYPES.LOAD_RECENT) {
      this.lsTaskApi.index().subscribe((x:any) => {
        this.store.dispatch(this.lsTaskActions.listSearchesLoaded(x));
      },
      () => {

      });
    }

    return next(action);
  };

  load = store => next => action => {
    if(action.type == LsTaskActions.TYPES.LOAD) {
      this.lsTaskApi.find(action.data.id).subscribe((x:any) => {
        this.store.dispatch(this.lsTaskActions.loadSearchLoaded(x.data));
      },
      () => {

      });
    }

    return next(action);
  };

  open = store => next => action => {

    // Check if finished and mark open if on last search page
    if(action.type == KeywordGeneratedActions._events.suggestions_finished
      && store.getState()['router'].endsWith('/last-search')
      && store.getState()['keyword_generator']['group_id'] == action.data.group_id) {
      const group_id = action.data.group_id;
      this.store.select(['tasks', 'data', group_id]).pipe(filter(x => !!x),take(1),).subscribe((task:LsTask) => {
        this.store.dispatch(this.lsTaskActions.openSearch(task.id, task.keyword, task.marketplace, false));
      });
    }

    if(action.type == LsTaskActions.TYPES.OPEN) {
      this.lsTaskApi.open(action.data.id).pipe(take(1)).subscribe((ls:any) => {
        if(action.data.load)
          this.store.dispatch(this.keywordGeneratedActions.requestSuggestions(action.data.keyword, action.data.id, action.data.marketplace));
        if(ls && ls.opened_at !== null)
          this.store.dispatch(this.lsTaskActions.removeSearch(action.data.id));
      });
    }

    return next(action);
  };
}
