import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { PayoutCommisionAdmin } from "@app/shared/data/admin/payout-commision/payout-commision.model";
import { PayoutCommisionAdminAPI } from "@app/shared/data/admin/payout-commision/payout-commision.api";
import { PayoutCommisionAdminActions } from "@app/shared/data/admin/payout-commision/payout-commision.actions";

@Injectable()
export class PayoutCommisionAdminEpics {
  constructor(private commisionActions: PayoutCommisionAdminActions,
              private commisionAPI: PayoutCommisionAdminAPI,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PayoutCommisionAdminActions.getPagActionTypes(PayoutCommisionAdmin).LOAD_PAGE || action.type == PayoutCommisionAdminActions.TYPES.FILTER) {
        const params = {
          ...action.data.pagination,
          limit: action.data.pagination.perPage,
          sort : action.data.pagination.sort.join(',')
        };
        this.commisionAPI.commision(params)
          .subscribe(
            (commisions: Collection<PayoutCommisionAdmin>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.commisionActions.setPage(commisions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.commisionActions.loadPageFailed(response));
            },
            () => {}
          );
      }
      return next(action);
    }
  };
}
