import { Component, Input } from '@angular/core';

@Component({
  selector   : 'st-button',
  templateUrl: './button.component.html',
  styleUrls  : ['./button.component.scss']
})
export class ButtonComponent {
  @Input('icon')
  icon = null;

  @Input('disabled')
  disabled = false;

  @Input('loading')
  loading = false;

  @Input('buttonClass')
  buttonClass: string;

  @Input('active')
  active: false;

  _attention = false;
  @Input('attention')
  set attention(val) {
    this._attention = val;
  }

  get attention() {
    return this._attention && !this.disabled;
  }

  @Input()
  type = 'button';

  noNoting($event) {
    if (this.disabled) {
      $event.stopPropagation();
    }
  }

}
