import { BaseModel } from '@app/shared/data/base.models';

export class CreditCard extends BaseModel {
  id: number;
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line2: string;
  address_state: string;
  address_zip: string;
  brand: string;
  country: string;
  expires: string;
  last_four: string;
}
