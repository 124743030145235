
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/shared/data/base.api';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Collection } from '@app/shared/data/base.models';
import { AppVersion } from '@app/shared/data/version/version.models';

@Injectable()
export class VersionApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<AppVersion>> {
    return this.http.get(this.constructFullUrl('api/admin/versions'), {params: params}).pipe(
               map(data => AppVersion.collect<AppVersion>(data, AppVersion.BACKEND_PAGINATION)));
  }

  public get(id): Observable<AppVersion> {
    return this.http.get(this.constructFullUrl('api/admin/versions/' + id)).pipe(
               map(version => new AppVersion(version)));
  }

  public save(data): Observable<AppVersion> {
    let obs: Observable<Object>;

    if (data.id) {
      obs = this.http.put(this.constructFullUrl('api/admin/versions/' + data.id), data);
    } else {
      obs = this.http.post(this.constructFullUrl('api/admin/versions'), data);
    }

    return obs.pipe(map(version => new AppVersion(version)));
  }

  public remove(version: AppVersion): Observable<Object> {
    return this.http.delete(this.constructFullUrl('api/admin/versions/' + version.id));
  }
}
