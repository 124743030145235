import { AfterContentInit, Input, Component, ElementRef, ViewChild } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Component({
  selector : 'st-instructions',
  template : `
    <div class="instructions" [ngClass]="{'opened':showMore || !toggable}">
      <div class="content" #content>
        <ng-content></ng-content>
      </div>
      <div class="action" *ngIf="toggable" (click)="showMore=!showMore">{{ showMore ? '↑ ' + showLessTranslation : '↓ ' + showMoreTranslation }}</div>
    </div>`,
  styleUrls: ['./instructions.component.scss'],
})
export class InstructionsComponent implements AfterContentInit {
  public showMore = false;

  @Input()
  public toggable = true;

  @ViewChild('content', { static: true })
  private contentWrapper: ElementRef;

  public showLessTranslation: string = this.i18n('show less');
  public showMoreTranslation: string = this.i18n('Learn More');

  constructor(private i18n: I18n) {}

  ngAfterContentInit(): void {
    const el = this.contentWrapper.nativeElement;

    el.style.minHeight = 'calc(' + el.firstElementChild.clientHeight + 'px + 0.95em)';
  }
}
