
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Collection } from '@app/shared/data/base.models';
import { BaseAPI } from '@app/shared/data/base.api';
import { ProductChild } from '@app/shared/data/product-child/product-child.models';

@Injectable()
export class ProductChildAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(parent_asin: string, params = {}): Observable<Collection<ProductChild>> {
    return this.http.get(this.constructFullUrl('api/v2/products/' + parent_asin + '/children'), { params: params }).pipe(
      map(data => ProductChild.collect<ProductChild>(data, ProductChild.BACKEND_PAGINATION)));
  }
}
