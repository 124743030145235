import { ElementRef, Inject, Injectable, OnInit } from '@angular/core';
import {
  ChartComponentInterface,
  HighchartOptions
} from "@app/shared/components/charts/models/highchart-options.model";
import { AxisDirective } from "@app/shared/components/charts/directives/axis.directive";
import { PlotOptions } from "highcharts";

export const Type: 'Chart' | 'StockChart' = 'StockChart';

@Injectable()
export class Highcharts {
}


@Injectable()
export class HighchartsService {
  options: HighchartOptions;
  _relatedComponent: ChartComponentInterface;
  chart: any;

  constructor(
    private _highcharts: Highcharts,
    @Inject(Type) private type: string,
    public elementRef: ElementRef) {
      this.__initOptions();
  }

  createChart() {
    this.elementRef.nativeElement.style.display = 'block';
    this.chart = this._highcharts[this.type](this.getOptions());
  }

  reflow() {
    if (!this.chart || !this.options) { return; }

    if (getComputedStyle(this.elementRef.nativeElement).transitionDuration) {
      let duration = parseFloat(getComputedStyle(this.elementRef.nativeElement).transitionDuration);
      let interval = setInterval(() => {
        if (duration < 0) { clearInterval(interval); }
        duration -= 50;
        try {
          this.chart.reflow();
        } catch (e) {
          // all right - component probably has been deleted.
          clearInterval(interval);
        }
      }, duration);
    }

    this.chart.reflow();
  }

  addAxis(axis: AxisDirective):number  {
    return this.options?.pushAxis(axis.getOptions());
  }

  addSeries(data) {
    this.options?.series.push(data);
  }

  setOptions(options: any) {
    this.options?.assign(options);
  }

  setTitle(title: string) {
    this.options?.setTitleText(title);
  }

  setType(type: string) {
    this.options?.chart.setType(type);
  }

  listenChartEvents() {
    this.options?.chart.listenForEvents(this._relatedComponent);
  }

  setPlotOptions(plotOptions: PlotOptions) {
    this.options?.setPlotOptions(plotOptions);
  }

  private __initOptions() {
    this.options = new HighchartOptions(this.elementRef.nativeElement);
  }

  startLoading() {
    this.chart.showLoading();
  }

  stopLoading() {
    this.chart.hideLoading();
  }
  setSize(width,height) {
    this.chart.setSize(width,height);
  }
  private getOptions() {
    return {
      ... this.options,
      chart: {... this.options?.chart}
    };
  }

}
