
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Collection } from '@app/shared/data/base.models';
import { BaseAPI } from '@app/shared/data/base.api';
import {
  LastLaunchScheduleInvoice,
  LastLaunchRebateUnit,
  LastLaunchSchedule,
  LastLaunchScheduleCoupon,
  LastLaunchScheduleDate, LastLaunchScheduleKeyword,
  LastLaunchScheduleProduct
} from '@app/shared/data/last-launch-schedule/last-launch-schedule.models';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { handleError } from '@app/helpers';

@Injectable()
export class LastLaunchScheduleAPI extends BaseAPI {
  public static transformFullPromotion(data) {
    const schedule = new LastLaunchSchedule(data);

    schedule.product = new LastLaunchScheduleProduct(data.product);
    schedule.keywords = LastLaunchScheduleKeyword.collect<LastLaunchScheduleKeyword>(schedule.keywords);
    schedule.coupons = LastLaunchScheduleCoupon.collect<LastLaunchScheduleCoupon>(schedule.coupons);
    schedule.rebateUnits = LastLaunchRebateUnit.collect<LastLaunchRebateUnit>(schedule.rebateUnits);
    schedule.invoices = LastLaunchScheduleInvoice.collect<LastLaunchScheduleInvoice>(schedule.invoices);

    if (schedule.coupon) {
      schedule.coupon = new Coupon(schedule.coupon);
    }

    return schedule;
  }

  constructor(protected http: HttpClient) {
    super();
  }

  /*
  * Gets promotion for id
  */
  public getPromotion(promoId): Observable<LastLaunchSchedule> {
    return this.http.get(this.constructFullUrl('api/last-launch/promotions/' + promoId), {}).pipe(
      map(data => LastLaunchScheduleAPI.transformFullPromotion(data)));
  }

  /*
  * Gets available dates for promotion
  */
  public availableDates(params = {}): Observable<Collection<LastLaunchScheduleDate>> {
    return this.http.get(this.constructFullUrl('api/last-launch/calendar'), { params: params }).pipe(
      map(data => LastLaunchScheduleDate.collect<LastLaunchScheduleDate>(data)));
  }

  public save(schedule: LastLaunchSchedule, coupon?: string): Observable<LastLaunchSchedule> {
    const body = {
      name: schedule.name,
      type: schedule.type,
      slot_date: schedule.starts_at,
      duration: schedule.duration,
    };

    if (coupon !== undefined) {
      body['coupon'] = coupon;
    }

    if (schedule.id) {
      return this.http.put(this.constructFullUrl('api/last-launch/promotions/' + schedule.id), body).pipe(
        map(data => LastLaunchScheduleAPI.transformFullPromotion(data)));
    }

    return this.http.post(this.constructFullUrl('api/last-launch/promotions'), body).pipe(
      map((data) => {
        return new LastLaunchSchedule(data);
      }),
      catchError(handleError));
  }

  /*
  * Searches users products for passed asin and returns product details if found
  */
  public searchProducts(promoId, query): Observable<Collection<LastLaunchScheduleProduct>> {
    return this.http.get(this.constructFullUrl('api/last-launch/promotions/' + promoId + '/product'), {
      params: {
        term: query
      }
    }).pipe(map(data => LastLaunchScheduleProduct.collect<LastLaunchScheduleProduct>(data)));
  }

  /*
  * Sets product for given promotion
  */
  public saveProduct(schedule: LastLaunchSchedule, product: LastLaunchScheduleProduct): Observable<LastLaunchSchedule> {
    return this.http.post(this.constructFullUrl('api/last-launch/promotions/' + schedule.id + '/product'), {
        ...product.getData(),
        moq: schedule.set_moq,
      }
    ).pipe(
      map(data => LastLaunchScheduleAPI.transformFullPromotion(data)),
      catchError(handleError)
    );
  }

  /*
  * Sets units for given promotion
  */
  public saveUnits(promoId, keywords: LastLaunchScheduleKeyword[]): Observable<LastLaunchSchedule> {
    return this.http.post(this.constructFullUrl('api/last-launch/promotions/' + promoId + '/keywords'), {
      // FIX issues with spaces in the URL -> https://www.amazon.com/dp/ASIN?keyword=bandana bib
      keywords: keywords.map(k => ({...k, value: (new URL(k.value)).toString()}))
    }).pipe(
      map(data => LastLaunchScheduleAPI.transformFullPromotion(data)),
      catchError(handleError)
    );
  }

  /*
  * Sets coupons for given promotion
  */
  public saveCoupons(promoId, coupons: string[]): Observable<LastLaunchSchedule> {
    return this.http.post(this.constructFullUrl('api/last-launch/promotions/' + promoId + '/coupons'), {
      coupons: coupons
    }).pipe(map(data => LastLaunchScheduleAPI.transformFullPromotion(data)));
  }

  /*
  * Pay Promotion
   */
  public pay(promoId: number, useWallet: boolean = false, toPayAmount: number): Observable<LastLaunchSchedule> {
    const body = {
      toPayAmount: toPayAmount,
    };

    if (useWallet) {
      body['useWallet'] = true;
    }

    return this.http.post(this.constructFullUrl('api/last-launch/promotions/' + promoId + '/pay'), body).pipe(
      map(data => LastLaunchScheduleAPI.transformFullPromotion(data)));
  }
}
