
import {map, merge, takeUntil} from 'rxjs/operators';
import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';
import { BehaviorSubject ,  Subject } from 'rxjs';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ReverseAsinActions } from '@app/shared/data/reverse-asin/reverse-asin.actions';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';

@Component({
  selector       : 'st-r2a-product-selection',
  templateUrl    : './r2a-product-selection.component.html',
  styleUrls      : ['./r2a-product-selection.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class R2aProductSelectionComponent implements OnInit, OnDestroy {
  public static E_CODE_ERROR = 1900;
  public static E_CODE_NO_PRODUCTS = 1901;
  public static E_CODE_GENERAL = 1902;

  protected errorCodes = {
    [R2aProductSelectionComponent.E_CODE_ERROR] : this.i18n('Something went wrong on our end. Please try again in a few minutes.'),
    [R2aProductSelectionComponent.E_CODE_NO_PRODUCTS] : this.i18n('No products found for specified Keyword'),
    [R2aProductSelectionComponent.E_CODE_GENERAL] : this.i18n('Amazon connection timed out due to high traffic. Please try again in a few minutes.'),
  };

  public keywordSearchText = '';
  private onDestroy$ = new Subject();

  public asinLimit = 5;
  public displayLimit = 50;
  public bsrColumn = 'bsr';
  public rankColumn = 'rank';

  @select(['reverse_asin', 'loading'])
  loading$: BehaviorSubject<boolean>;

  @select(['reverse_asin', 'product_search_in_progress'])
  searchInProgress$: BehaviorSubject<boolean>;

  @select(['reverse_asin', 'product_search_error'])
  searchError$: BehaviorSubject<number>;
  searchErrorText$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  @select(['reverse_asin', 'product_search_results'])
  searchResults$: BehaviorSubject<any[]>;
  public products$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

  public sortColumn$: BehaviorSubject<string> = new BehaviorSubject<string>('rank');
  public sortDesc$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public productsAreSet$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public selectedProducts: any[] = [];

  public translateBreadcrumbs: string[] = [
    this.i18n('Enter keyword or keyword phrase relevant to products you’d like to add as part of your R2A lookup')
  ];

  @ViewChild(ModalComponent, { static: true })
  private modal: ModalComponent;

  marketplace: { id: number; name: string; domain: string };

  constructor(protected router: Router,
              protected route: ActivatedRoute,
              protected store: NgRedux<AppState>,
              protected reverseAsinActions: ReverseAsinActions,
              private trackingService: RudderTrackingService,
              protected i18n: I18n,
  ) {}

  public ngOnInit(): void {
    this.searchError$.pipe(
        takeUntil(this.onDestroy$))
        .subscribe(code => this.searchErrorText$.next(
          code !== null
            ? '(' + code + ') ' + (
            code in this.errorCodes
              ? this.errorCodes[code] : this.errorCodes[R2aProductSelectionComponent.E_CODE_GENERAL]
            ) : '',
          ),
        );
    this.searchResults$.pipe(
        merge(this.sortDesc$.asObservable()),
        map((products: any[]) => {
          if (products.length > 0) {
            return Object.values(products.slice(0, this.displayLimit)).sort(this.getSortComparator());
          }
          return [];
        }),
        takeUntil(this.onDestroy$),)
        .subscribe((products: any[]) => {
          this.productsAreSet$.next(products.length > 0);
          this.products$.next(products);
        });
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  public onClose(result = false): boolean {
    if (result) {
      return true;
    }
    this.modal.close(true);
  }

  public minimize(): void {
    this.modal.close(true, true);
  }

  public abort(): void {
    this.store.dispatch(this.reverseAsinActions.findProductsForKeywordClear());
    this.modal.close(true, false);
  }

  public findProducts(): void {
    this.store.dispatch(this.reverseAsinActions.findProductsForKeyword(this.keywordSearchText.toLowerCase().trim()));

    this.trackingService.trackEvent('R2A Product Search - Button Clicked', {category: 'R2A Page'});
  }

  public sort(column: string): void {
    this.sortColumn$.next(column);
    this.sortDesc$.next(!this.sortDesc$.getValue());
    this.searchResults$.next(this.searchResults$.value);
  }

  public isSorted(column: string, descending: boolean) {
    return column === this.sortColumn$.getValue() && descending === this.sortDesc$.getValue();
  }

  public getAmazonUrl(asin: string) {
    return `https://www.${this.marketplace.domain}/dp/${asin}`;
  }

  public confirm() {
    this.modal.close(this.selectedProducts);
  }

  protected getSortComparator() {
    const sortDesc = this.sortDesc$.getValue();
    if (this.sortColumn$.getValue() === this.bsrColumn) {
      const nullBSR = Number.MAX_SAFE_INTEGER;
      return (a: any, b: any) => {
        const aBSR = a.bsr !== null ? a.bsr : nullBSR;
        const bBSR = b.bsr !== null ? b.bsr : nullBSR;
        return sortDesc ? bBSR - aBSR : aBSR - bBSR;
      };
    } else {
      return (a: any, b: any) => sortDesc ? b.rank - a.rank : a.rank - b.rank;
    }
  }
}
