import { Injectable } from '@angular/core';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { StAction } from '@app/shared/data/st-action';
import { KeywordGenerated } from '@app/shared/data/keyword-generated/keyword-generated.models';
import { Filter } from '@app/shared/components/filter-builder/filter/filter';

@Injectable()
export class KeywordGeneratedActions extends PaginationActions {
  static readonly _events = {
    suggestions_received: 'keyword_generator_suggestions_generated',
    suggestions_finished: 'keyword_generator_suggestions_finished',
  };

  static readonly TYPES = {
    SET_ACTIVE_KEYWORD   : '[KEYWORDGENERATED]SET_ACTIVE_KEYWORD',
    SUGGESTIONS_REQUESTED: '[KEYWORDGENERATED]SUGGESTIONS_REQUESTED',
    LOAD_RECENT          : '[KEYWORDGENERATED]LOAD_RECENT',
    SET_RECENT           : '[KEYWORDGENERATED]SET_RECENT',
    RECENT_FAILED        : '[KEYWORDGENERATED]RECENT_FAILED',
    APPLY_FILTERS        : '[KEYWORDGENERATED]APPLY_FILTERS',
    TOGGLE_UNFINISHED    : '[KEYWORDGENERATED]TOGGLE_UNFINISHED',
    RESET                : '[KEYWORDGENERATED]RESET',
    SET_FILTERED_KEYWORDS: '[KEYWORDGENERATED]SET_FILTERED_KEYWORDS',
    SUGGESTIONS_FINISHED : '[KEYWORDGENERATED]SUGGESTIONS_FINISHED',
    LOAD_SEARCH          : '[KEYWORDGENERATED]LOAD_SEARCH',
    LOAD_HISTORY         : '[KEYWORDGENERATED]LOAD_HISTORY',
    LOAD_HISTORY_SUCCEEDED         : '[KEYWORDGENERATED]LOAD_HISTORY_SUCCEEDED',
    LOAD_HISTORY_FAILED         : '[KEYWORDGENERATED]LOAD_HISTORY_FAILED',
  };

  protected model = KeywordGenerated;

  setActiveKeyword(keyword: string, group_id: string, button_loading: boolean): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.SET_ACTIVE_KEYWORD,
      data: {
        keyword       : keyword,
        group_id      : group_id,
        button_loading: button_loading
      }
    };
  }

  requestSuggestions(keyword: string, group_id: string, marketplace: { id: number }): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.SUGGESTIONS_REQUESTED,
      data: {
        keyword    : keyword,
        group_id   : group_id,
        marketplace: marketplace
      }
    };
  }

  loadSearch(groupId: string) {
    return {
      type: KeywordGeneratedActions.TYPES.LOAD_SEARCH,
      data: { groupId }
    };
  }

  loadRecent() {
    return {
      type: KeywordGeneratedActions.TYPES.LOAD_RECENT,
      data: {}
    };
  }

  setRecent(recent: any[]) {
    return {
      type: KeywordGeneratedActions.TYPES.SET_RECENT,
      data: { recent }
    };
  }

  loadRecentFailed(error: any) {
    return {
      type: KeywordGeneratedActions.TYPES.RECENT_FAILED,
      data: { error }
    };
  }

  applyFilters(filters: Filter[]): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.APPLY_FILTERS,
      data: filters
    };
  }

  toggleUnfinished(show: boolean): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.TOGGLE_UNFINISHED,
      data: show
    };
  }

  reset(): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.RESET,
      data: {}
    };
  }

  setFiltered(data): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.SET_FILTERED_KEYWORDS,
      data: { data }
    };
  }

  setSuggFinished(keywords, groupId): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.SUGGESTIONS_FINISHED,
      data: {
        keywords,
        group_id: groupId
      }
    };
  }

  loadHistory(): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.LOAD_HISTORY,
      data: {}
    }
  }

  loadHistorySucceeded(data): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.LOAD_HISTORY_SUCCEEDED,
      data: data
    }
  }

  loadHistoryFailed(error): StAction {
    return {
      type: KeywordGeneratedActions.TYPES.LOAD_HISTORY_FAILED,
      data: error
    }
  }
}
