import { Injectable } from "@angular/core";
import { Epic } from "redux-observable";
import { AppState } from "@app/shared/data/app-state.model";
import { Action } from "redux";
import { NgRedux } from "@angular-redux/store";
import { UsageActions } from "@app/shared/data/usage/usage.actions";
import { Usage } from "@app/shared/data/usage/usage.models";
import { UsageApi } from "@app/shared/data/usage/usage.api";

@Injectable()
export class UsageEpics {
  constructor(private usageApi: UsageApi, private ngRedux: NgRedux<AppState>) {}

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.updated,
      this.get,
    ];
  }

  get = store => next => {
    return action => {
      if (action.type == UsageActions.TYPES.GET_USAGE) {
        this.usageApi.get()
          .subscribe(
            usages => {
              this.ngRedux.dispatch(UsageActions.getUsageSucceeded(usages));
            },
            error => {
              this.ngRedux.dispatch(UsageActions.getUsageFailed(error));
            }
          );
      }
      return next(action);
    }
  };

  updated = store => next => {
    return action => {
      if (action.type == UsageActions.TYPES.USAGE_UPDATED) {
        action.data = Usage.collect(<Usage>(action.data.usages));
      }
      return next(action);
    }
  };
}
