import { CampaignDestinationEnum } from '@app/client/campaign-manager/campaign-manager-destination/campaign-manager-destination.component';
import { IAvailableFilter, IFilter, ISavedFilter } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';

export interface ICampaignManagerState {
  campaignList: ICampaign[];
  currentPage: number;
  lastPage: number;
  perPage: number;
  total: number;
  from: number;
  to: number;

  appliedFilters: IFilter[];
  savedFilters: ISavedFilter[];
  availableFilters: IAvailableFilter[];
  searchTerm: string;
  credentialId: null | number;
  sort: string;

  loadingCampaignList: boolean;
  savingFilters: boolean;
  savingFiltersError: boolean;
  loadingSavedFilters: boolean;
  loadingAvailableFilters: boolean;
  deletingView: boolean;

  imageUploaded: string;
  imageUploadingError: boolean;

  error: boolean;

  campaignManagerState: ICampaignState;
  updatingCampaignManagerState: boolean;
  campaignNameError: boolean;
  checkingCampaignName: boolean;
  loadingCampaign: boolean;
  errorLoadingCampaign: boolean;
  campaignManagerCampaignNameConfirmed: boolean;
}

export interface ICampaign {
  id: string;
  name: string;
  type: CampaignTypeEnum;
  product: string;
  image_url: string;
  status: CampaignStatusEnum;
  opt_ins?: number;
  created_at: string;
  detailsOpened?: boolean;
  qr_code?: IQrCode;
}

export interface IQrCode {
  color: string;
  logo_url: string;
  density: string;
}

export enum CampaignTypeEnum {
  POST_PURCHASE = 'post_purchase',
}

export enum RewardTypeEnum {
  REBATE = 'rebate',
  FREE_GIFT = 'mcf_gift',
  EBOOK = 'ebook',
  VIDEO = 'video',
  WARRANTY = 'warranty'
}

export enum CampaignStatusEnum {
  ACTIVE = 'active',
  PENDING = 'pending',
  FINISHED = 'finished',
}

export enum OrdersUrlEnum {
  FILTERED_BY_PRODUCT = 'filtered_by_product',
  FILTERED_BY_SELLER = 'filtered_by_seller',
}
export enum ReviewUrlEnum {
  PRODUCT_REVIEW = 'product_review',
  PRODUCT_DETAILS = 'product_details',
}

export const rewardTypeToCampaignTypeMap = {
  [RewardTypeEnum.EBOOK]: CampaignTypeEnum.POST_PURCHASE,
  [RewardTypeEnum.FREE_GIFT]: CampaignTypeEnum.POST_PURCHASE,
  [RewardTypeEnum.VIDEO]: CampaignTypeEnum.POST_PURCHASE,
  [RewardTypeEnum.WARRANTY]: CampaignTypeEnum.POST_PURCHASE,
};
export interface ICampaignManagerResponse {
  data: ICampaign[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  };
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    path: string;
    total: number;
    to: number;
  };
}

export enum SortByEnum {
  DATE = 'created_at',
  NAME = 'name',
  OPTINS = 'opt_ins',
  NONE = 'none'
}
export interface ISaveFilterResponse {
  data: ISavedFilter;
}

export interface ISavedFiltersListResponse {
  data: ISavedFilter[];
}
export interface ILoadAvailableFiltersResponse {
  data: IAvailableFilter[];
}

export interface IMcfGiftRewardSettingsVariations {
  sku: string;
  quantity: number;
  asin?: string;
  full_name?: string;
  image?: string;
}

export interface ICampaignState {
  id?: string;
  name?: string;
  slug?: string;
  type?: CampaignTypeEnum;
  destination: CampaignDestinationEnum;
  theme?: {
    logo_file?: any;
    logo_url?: string;
    chatbot_theme?: 'purple-orange'
  };
  product?: {
    short_name?: string;
    asin?: string;
    children?: string[];
    full_name?: string;
    image?: string;
  };
  included_customers?: {
    phone_number_rate?: string[];
    customer_rate?: string[];
    rejection_message?: {
      headline?: string;
      body?: string;
    };
  };
  rejection_headline?: string;
  rejection_body?: string;
  urls?: {
    orders_url?: OrdersUrlEnum;
    review_url?: ReviewUrlEnum;
  };
  thank_you_page?: {
    headline?: string;
    sub_headline?: string;
  };
  order_verification?: {
    status?: string;
  };
  reward?: {
    type?: RewardTypeEnum;
    currency?: 'USD';
    rebate_reward_settings?: {
      type?: string;
      amount?: number;
    };
    mcf_gift_reward_settings?: {
      product?: {
        parent?: string;
        variations?: IMcfGiftRewardSettingsVariations[];
      },
      shipping?: string;
    };
    video_reward_settings?: {
      url?: string,
    };
    ebook_reward_settings?: {
      pdf_url?: string,
      epub_url?: string,
      mobi_url?: string
    };
    warranty_reward_settings?: {
      lifetime?: boolean,
      duration?: number
    }
  };
  qr_code?: {
    density?: string;
    color?: string;
    logo_url?: string;
  };
  contact_email?: string;
  active?: boolean;
  status?: CampaignStatusEnum;
}

export interface ICheckCampaignSlugResponse {
  available: boolean;
  suggestions: string[];
}

export enum FileTypeEnum {
  PDF = 'pdf',
  MOBI = 'mobi',
  EPUB = 'epub'
}

export enum ShippingSpeedEnum {
  STANDARD = 'standard',
  EXPEDITED = 'expedited',
}
