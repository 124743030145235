export class SelectionModel<T> {
  private _selection: Set<T> = new Set();

  constructor(private _isMulti = false,
              initiallySelectedValues?: T[]) {

    if (initiallySelectedValues) {
      if (_isMulti) {
        initiallySelectedValues.forEach(value => this._markSelected(value));
      } else {
        this._markSelected(initiallySelectedValues[0]);
      }
    }
  }

  get selected(): T[] {
    return Array.from(this._selection.values());
  }

  /**
   * Selects a value or an array of values.
   */
  select(...values: T[]): void {
    values.forEach(value => this._markSelected(value));
  }

  /**
   * Toggles a value between selected and deselected.
   */
  toggle(value: T): boolean {
    let isSelected = this.isSelected(value);
    isSelected
      ? this.deselect(value)
      : this.select(value);

    return isSelected;
  }

  /**
   * Deselects a value or an array of values.
   */
  deselect(...values: T[]): void {
    values.forEach(value => this._unmarkSelected(value));
  }


  private _markSelected(value: T) {
    if (!this.isSelected(value)) {
      if (!this._isMulti) {
        this._unmarkAll();
      }

      this._selection.add(value);
    }
  }

  /** Clears out the selected values. */
  private _unmarkAll() {
    if (!this.isEmpty()) {
      this._selection = new Set();
    }
  }

  /** Deselects a value. */
  private _unmarkSelected(value: T) {
    if (this.isSelected(value)) {
      this._selection.delete(value);
    }
  }

  /**
   * Determines whether a value is selected.
   */
  isSelected(value: T): boolean {
    return this._selection.has(value);
  }

  /**
   * Clears all of the selected values.
   */
  clear(): void {
    this._unmarkAll();
  }

  public isEmpty() {
    return this._selection.size === 0;
  }

  get length(): number {
    return this._selection.size;
  }
}

