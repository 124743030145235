import { ControlValueAccessor, NgControl } from "@angular/forms";
import { ChangeDetectorRef, EventEmitter, Output, Directive } from "@angular/core";

@Directive()
export abstract class ValueAccessorBase<T> implements ControlValueAccessor {
  @Output('onSelect') selectEvent: EventEmitter<T> = new EventEmitter();
  protected innerValueChange: EventEmitter<T> = new EventEmitter<T>();

  constructor(protected cd: ChangeDetectorRef) {}
  propagateChange = (_: any) => {};
  // protected ngControl: NgControl;
  private innerValue: T;

  private changed = [];
  private touched = [];

  get value(): T {
    return this.innerValue;
  }

  set value(value: T) {
    if (this.innerValue !== value) {
      this.innerValue = value;
      this.changed.forEach(f => f(value));
      this.selectEvent.emit(value);
    }
  }

  writeValue(value: T) {
    this.innerValue = value;
    this.innerValueChange.next(value);
    if(this.cd)
      this.cd.markForCheck();
  }

  registerOnChange(fn: (value: T) => void) {
    this.changed.push(fn);
  }

  registerOnTouched(fn: () => void) {
    this.touched.push(fn);
  }

  touch() {
    this.touched.forEach(f => f());
  }

  setDisabledState(isDisabled: boolean): void {
  }
}
