import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Collection } from "@app/shared/data/base.models";
import { SystemStatsActions } from "@app/shared/data/admin/system-stats/system-stats.actions";
import { SystemStatsAPI } from "@app/shared/data/admin/system-stats/system-stats.api";
import { SystemStats } from "@app/shared/data/admin/system-stats/system-stats.models";

@Injectable()
export class SystemStatsEpics {
  constructor(private systemStatsActions: SystemStatsActions,
              private store: NgRedux<AppState>,
              private systemStatsAPI: SystemStatsAPI) {}

  public createEpic() {
    return [
      this.loadPage,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      let n = next(action);
      if (action.type == PaginationActions.getPagActionTypes(SystemStats).LOAD_PAGE) {
        let state = store.getState()['admin']['system_stats'];
        let params = { ...state['_be_pagination'] };

        this.systemStatsAPI.all(params)
          .subscribe(
            (stats: Collection<SystemStats>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemStatsActions.setPage(stats));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.systemStatsActions.loadPageFailed(response));
            },
            () => {}
          );
      }
      return n;
    }
  };
}
