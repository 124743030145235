import { NgRedux } from "@angular-redux/store";
import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthService } from "@app/core/services/auth.service";
import { getAmazonUrl } from "@app/helpers";
import { ProductManagerActions } from "@app/shared/data/product-manager/product-manager.actions";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-keyword-label-item',
  templateUrl: './keyword-label.item.component.html',
  styleUrls: ['./keyword-label.item.component.scss']
})
export class KeywordLabeltItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() onToggleKeywordDetails: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
    private ngRedux: NgRedux<any>,
    private productManagerActions: ProductManagerActions,
  ) { }

  getAmazonLink(): string {
    return getAmazonUrl(this.item.value.value, this.authService.getActiveCredential().amazon_domain);
  }

  correctKeyword(event: MouseEvent) {
    event.preventDefault();

    this.ngRedux.dispatch(this.productManagerActions.correctKeyword(this.item.value.asin, this.item.value.value, this.item.value.value_corrected));
  }
}
