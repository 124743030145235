import { Observable } from 'rxjs';
import { WrongFilterOperatorException } from '@app/shared/components/filter-builder/filter/filter.exceptions';

export class Filter {

  public field: string;
  public operator: string;
  public type: string;
  public value: any;

  public static filterOperator(value: any, filter: Filter, multiplier?: number): boolean {

    multiplier = multiplier ? multiplier : 1; // set default multiplier

    switch (filter.operator) {
      case '=':
      case 'equal to':
      case 'is':
      case 'has':
      case 'include':
      case 'have':        return value === filter.value;
      case '!=':
      case 'not equal to': return value !== filter.value;
      case '>':
      case 'greater than':
      case 'over':        return value > filter.value;
      case '>=':          return value >= filter.value;
      case '<':
      case 'less than':
      case 'under':       return value < filter.value;
      case '<=':          return value <= filter.value;
      case 'contains':    return value.includes(filter.value.toString());
      case 'excludes':    return ! value.includes(filter.value.toString());
      case 'exclude':     return ! value.includes(filter.value.toString());
      case 'between':     return this.filterBetween(false, value, filter, multiplier);
      case 'not between': return this.filterBetween(true, value, filter, multiplier);
      case 'has one of':  return this.filterHasOneOf(value, filter);
      case 'has all':     return this.filterHasAll(value, filter);
    }

    throw new WrongFilterOperatorException('Invalid filter operator ' + filter.operator);
  }

  private static filterHasOneOf(value: any[], filter: Filter) {
    return filter.value.some(r => value.indexOf(r) >= 0);
  }

  private static filterHasAll(value: any[], filter: Filter) {
    return filter.value.every(r => value.indexOf(r) !== -1);
  }

  private static filterBetween(negated: boolean, value: any, filter: Filter, multiplier: number) {
    if (value === false) {
      return false;
    }

    const percentageNumber = Math.floor(value * multiplier);
    const preNegation = (filter.value.from === null || percentageNumber >= filter.value.from)
      && (filter.value.to === null || percentageNumber <= filter.value.to);
    return negated ? !preNegation : preNegation;
  }
}

export interface FilterBuilderConfig {
  fields: {[key: string]: Field};
}

export interface Field {
  name: string;
  value?: string;
  type: string;
  source?: string;
  nullable?: boolean;
  options?: Observable<Option[]>;
  operators?: Operator[];
  range?: Range;
  multiplier?: number;
  defaultValue?: any;
  defaultOperator?: any;
  onForeignProduct?: boolean;
}

export interface Option {
  name: string;
  value: any;
}

export interface Operator {
  name: string;
  value: string;
}

export interface Range {
  min: Observable<number>;
  max: Observable<number>;
}
