
import {of as observableOf,  Observable } from 'rxjs';

import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { PrizmActions, PrizmProductActions, PrizmKeywordActions } from '@app/shared/data/prizm/prizm.actions';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { PrizmApi } from '@app/shared/data/prizm/prizm.api';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { PrizmProduct } from '@app/shared/data/prizm/prizm.models';
import { ExportPrizmService } from '@app/shared/services/export-prizm.service';
import {I18n} from "@ngx-translate/i18n-polyfill";

@Injectable()
export class PrizmEpics {
  constructor(
    private store: NgRedux<AppState>,
    private prizmProductActions: PrizmProductActions,
    private prizmKeywordActions: PrizmKeywordActions,
    private prizmActions: PrizmActions,
    private prizmApi: PrizmApi,
    private i18n: I18n
  ) { }

  /**
   * Create list of epics.
   *
   * @returns
   * @memberof PrizmEpics
   */
  public createEpic() {
    return [
      this.getProductResults,
      this.getCategories,
      this.getProductKeywords,
      this.getHistory,
      this.exportAll,
    ];
  }

  /**
   * Request backend for product results.
   * @param store
   */
  getProductResults = (store: NgRedux<AppState>) => (next: (action: StAction) => AppState) => (action: StAction) => {
    const n = next(action);
    if (action.type === PrizmProductActions.TYPES.GET_RESULTS
      || action.type === PaginationActions.getPagActionTypes(PrizmProduct).LOAD_PAGE) {
        const marketplaceId = store.getState()['prizm']['marketplaceId'];
        const reqObs = store.getState()['prizm']['product']['exampleLoaded']
          ? this.prizmApi.requestProductPrizmSearch(marketplaceId, store.getState()['prizm']['product']['filters'],
              store.getState()['prizm']['product']['pagination']) // Request backend for prizm results
          : this.prizmApi.requestExampleProductSearch(marketplaceId);

        reqObs
          .pipe(map(this.prizmProductActions.getResultsSucceeded)).pipe( // Create prizm results action
          catchError(error =>
            observableOf(
              this.prizmProductActions.getResultsFailed(this.i18n('Limits for prizm search reached'))
            ) // If request fails create failed action
          )).subscribe(this.store.dispatch); // Dispatch created action
    }
    return n;
  }

  /**
   * Request categories from backend.
   *
   * @memberof PrizmEpics
   */
  getCategories = (store: NgRedux<AppState>) => (next: (action: StAction) => AppState) => (action: StAction) => {
    const actionResult = next(action);
    if (action.type === PrizmActions.TYPES.GET_CATEGORIES) {
      const marketplaceId = store.getState()['prizm']['marketplaceId'];
      this.prizmApi.requestCategories(marketplaceId)
        .pipe(map(this.prizmActions.getCategoriesSucceeded))
        .catch(error => observableOf(this.prizmActions.getCategoriesFailed(error)))
        .subscribe(this.store.dispatch);
    }
    return actionResult;
  }

  /**
   * Request keywords from backend.
   *
   * @memberof PrizmEpics
   */
  getProductKeywords = (store: NgRedux<AppState>) => (next: (action: StAction) => AppState) => (action: StAction) => {
    if (action.type === PrizmKeywordActions.TYPES.GET_RESULTS) {
      const marketplaceId = store.getState()['prizm']['marketplaceId'];
      this.prizmApi.requestKeywords(marketplaceId, action.data.filters)
        .pipe(map(this.prizmKeywordActions.getResultsSucceeded)).pipe(
        catchError(error => observableOf(this.prizmKeywordActions.getResultsFailed(error))))
        .subscribe(this.store.dispatch);
    }
    return next(action);
  }

  /**
   * Get history from backend.
   *
   * @memberof PrizmEpics
   */
  getHistory = (store: NgRedux<AppState>) => (next: (action: StAction) => AppState) => (action: StAction) => {
    if (action.type === PrizmProductActions.TYPES.GET_HISTORY) {
      const marketplaceId = store.getState()['prizm']['marketplaceId'];
      this.prizmApi.requestHistory(marketplaceId, action.data.asin)
        .pipe(map(this.prizmProductActions.getHistorySucceeded)).pipe(
        catchError(error => observableOf(this.prizmProductActions.getHistoryFailed(error))))
        .subscribe(this.store.dispatch);
    }
    return next(action);
  }

  exportAll = (store: NgRedux<AppState>) => (next: (action: StAction) => AppState) => (action: StAction) => {
    if (action.type === PrizmProductActions.TYPES.EXPORT_ALL) {
      const paginationFilters = {
        ...store.getState()['prizm']['product']['pagination'],
        perPage: 300
      };
      const marketplaceId = store.getState()['prizm']['marketplaceId'];

      this.prizmApi.requestProductPrizmSearch(marketplaceId, store.getState()['prizm']['product']['filters'], paginationFilters)
        .subscribe((results) => {
          ExportPrizmService.exportToCsv(action.data.fileName, results.data);
        });
    }
    return next(action);
  }
}
