import { BaseModel } from "@app/shared/data/base.models";

export class Notice extends BaseModel {
  id: number;
  type: string;
  message: string;
  url: string;
  icon: string;
  onClick?: ()=>{};
  actionText?:string;
  ttl?: number;
}
