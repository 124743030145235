import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import {DecisionActions} from "@app/shared/data/decision/decision.actions";

const INITIAL_STATE: AppState = {
  data: {
    relevant: [],
    irrelevant: []
  },
  _loading: true,
  _saving: false,
  _error: null
};


export function decisionReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == DecisionActions.TYPES.LOAD_DECISIONS) {
    return {
      ...state,
      data: INITIAL_STATE['data'],
      _loading: true
    }
  }

  if(action.type == DecisionActions.TYPES.LOAD_DECISIONS_SUCCEEDED) {
    return {
      ...state,
      data: {
        relevant: action.data.relevant.map(x => ({value: x, relevant: true})),
        irrelevant: action.data.irrelevant.map(x => ({value: x, relevant: false}))
      },
      _loading: false
    }
  }

  if(action.type == DecisionActions.TYPES.LOAD_DECISIONS_FAILED) {
    return {
      ...state,
      _error: action.data.error,
      _loading: false
    }
  }

  if(action.type == DecisionActions.TYPES.SET_IRRELEVANT) {
    return {
      ...state,
      data: {
        relevant: state['data']['relevant'].filter(kw => kw.value != action.data.kw.value),
        irrelevant: [action.data.kw, ...state['data']['irrelevant']]
      }
    }
  }

  if(action.type == DecisionActions.TYPES.SET_RELEVANT) {
    return {
      ...state,
      data: {
        irrelevant: state['data']['irrelevant'].filter(kw => kw.value != action.data.kw.value),
        relevant: [action.data.kw, ...state['data']['relevant']]
      }
    }
  }

  if(action.type == DecisionActions.TYPES.SAVE_DECISIONS) {
    return {
      ...state,
      _saving: true,
      _error: null
    }
  }

  if(action.type == DecisionActions.TYPES.SAVE_DECISIONS_SUCCEEDED) {
    return {
      ...state,
      _saving: false
    }
  }

  if(action.type == DecisionActions.TYPES.SAVE_DECISIONS_FAILED) {
    return {
      ...state,
      _saving: false,
      _error: action.data.error
    }
  }

  return state;
}
