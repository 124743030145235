import { BaseModel } from '@app/shared/data/base.models';
import { DecimalPipe } from '@angular/common';


export enum ReverseAsinQuickAaddStates {
  READY,
  LOADING,
  ADDED,
  FAILED,
}

export class ReverseAsin extends BaseModel {
  static className = 'ReverseAsin';
  keyword: string;
  updated?: string;
  resultsCount?: number | boolean;
  impressionExact?: number | boolean;
  impressionBroad?: number | boolean;
  impressionP?: number | boolean;
  categories?: string[];
  sfr?: number | boolean;
  density?: number | boolean;
  rc_density?: number | boolean;
  sponsored?: string[] | boolean;
  in_sponsored: string[];
  myAsinTags?: string[];
  myAsinRank?: number|false;
  amazons_choice: string[];
  in_amazons_choice: string[];
  ranks: {[_:string]: number};
  density_asins: string[];
  report_card: boolean;
  avg_rank: number|false;
  min_rank: number|false;
  min_rank_asin: string|false;
  min_rank_parent_asin: string|null;
  myAsinRankSuccess: boolean|null;
  quickAddState?: ReverseAsinQuickAaddStates;
  myAsin?: {
    r: boolean;
    t: string[];
  };

  public isQuickAddAdded(): boolean {
    return this.quickAddState === ReverseAsinQuickAaddStates.ADDED;
  }

  public isQuickAddLoading(): boolean {
    return this.quickAddState === ReverseAsinQuickAaddStates.LOADING;
  }

  public isQuickAddReady(): boolean {
    return this.quickAddState === ReverseAsinQuickAaddStates.READY
      || this.quickAddState === ReverseAsinQuickAaddStates.FAILED;
  }

  public isQuickAddFailed(): boolean {
    return this.quickAddState === ReverseAsinQuickAaddStates.FAILED;
  }

  public falseIgnoreCompare = (a: number | boolean, b: number | boolean, desc: boolean): number => {
    if (a === b) return 0;

    if (a === false) return 1;
    if (b === false) return -1;

    if (a < b) return desc ? 1 : -1;
    return desc ? -1 : 1;
  };

  public sfrCompare = this.falseIgnoreCompare;
  public avg_rankCompare = this.falseIgnoreCompare;
  public min_rankCompare = this.falseIgnoreCompare;
  public myAsinRankCompare = this.falseIgnoreCompare;

  get sponsoredDensity(): number {
    if (Array.isArray(this.in_sponsored)) {
      return this.in_sponsored.length;
    }

    return 0;
  }

  get searchVolume(): string | number | boolean {
    if (this.impressionP !== false) {
      return this.impressionP < 200 ? '< 200' : Math.round(Number(this.impressionP)) || false;
    }
    return false;
  }

  get opportunityScore(): number | boolean {
    if (this.impressionP !== false && this.resultsCount !== false) {
      const val = Number(this.impressionP) / Math.max(Number(this.resultsCount) || 1, 1);
      return Math.round(val);
    }
    return false;
  }

  get matchTypeTags(): string[] {
    const array = [];

    if (this.sfr !== false) {
      array.push('B');
    } else {
      array.push('!B');
    }

    if (!!this.in_sponsored && this.in_sponsored.length > 0) {
      array.push('S');
    } else {
      array.push('!S');
    }

    if (this.report_card) {
      array.push('A');
    } else {
      array.push('!A');
    }

    return array;
  }

  get acBadges(): string[] {
    const array = [];

    if (!!this.amazons_choice && this.amazons_choice.length > 0) {
      array.push('WA');
    } else {
      array.push('WOA');
    }

    if (!!this.in_amazons_choice && this.in_amazons_choice.length > 0) {
      array.push('WT');
    } else {
      array.push('WOT');
    }

    return array;
  }

  get avgRank(): number|false {
    return this.avg_rank;
  }
}

export type ReverseAsinProductInfo = {
  title?: string;
  asin: string;
  children: string[];
  img: string;
  searchedAsin: string;
}[] | null;

export type ReverseAsinStats = {
  total: number;
  organic: number;
  sponsored: number;
  rc: number;
  ba: number;
} | null;

class MyAsin {
  T: boolean;
  R: boolean;
  S: boolean;
  O: boolean;
  MS: boolean;
  A: boolean;
  OP: boolean;
}
