import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, HostListener, Inject,
  InjectionToken, Input, OnInit, Optional, Output
} from '@angular/core';
import { KeyCode } from "@app/shared/layout/dropdown/keycodes.enum";

let _uniqueIdCounter = 0;

export class OptionSelectionChangeEvent {
  constructor(public target: OptionComponent, public internal: boolean = false) { }
}

export interface OptionParentComponent {
  multiple?: boolean;
}


export const OPTION_PARENT_COMPONENT =
               new InjectionToken<OptionParentComponent>('OPTION_PARENT_COMPONENT');

@Component({
  selector           : 'st-option',
  template           : `
    <li>
      <button type="button" class="button interface-component" [class.grey-bg]="theme == 'grey'" [class.white-bg]="theme == 'white'" [disabled]="disabled">
        <st-checkbox [(ngModel)]="_selected" *ngIf="multiple" [disabled]="disabled"></st-checkbox>
        <ng-content></ng-content>
      </button>
    </li>
  `,
  styleUrls      : ['./option.component.scss'],
  host               : {
    'role'                : 'option',
    '[attr.tabindex]'     : '_getTabIndex()',
    '[id]'                : '_id',
    '[attr.aria-selected]': '_selected.toString()',
    '[attr.aria-disabled]': '_disabled.toString()',
    '(click)'             : '_selectViaInteraction(false, $event)',
  },
  preserveWhitespaces: false,
})
export class OptionComponent implements OnInit {
  private _id = `st-option-${_uniqueIdCounter++}`;
  private _disabled: boolean = false;
  public _selected: boolean = false;

  @Input() value: any;
  @Input() disableKeydownEvents: boolean;
  @Input() disabled: boolean = false;
  @Output() onSelect = new EventEmitter<OptionSelectionChangeEvent>();

  @Input() theme: string = null;

  constructor(public elementRef: ElementRef,
              private changeDetectorRef: ChangeDetectorRef,
              @Optional() @Inject(forwardRef(() => OPTION_PARENT_COMPONENT)) private _parent: OptionParentComponent) {
  }

  ngOnInit(): void {}

  _getTabIndex() {
    return this._disabled ? -1 : 0;
  }

  get multiple() {
    return this._parent && this._parent.multiple;
  }

  @HostListener('keydown', ['$event'])
  _handleKeydown(event: KeyboardEvent): void {
    if (!this.disableKeydownEvents)
      switch (event.keyCode) {
        case KeyCode.Enter:
        case KeyCode.Space:
          this._selectViaInteraction();

          // Prevent the page from scrolling down and form submits.
          event.preventDefault();
          break;
      }
  }

  /**
   * Selects the option while indicating the selection came from the user. Used to
   * determine if the select's view -> model callback should be invoked.
   */
  _selectViaInteraction(internal = false, $event: Event = null): void {
    if ($event && $event['x'] == 0) return;
    if (!this._disabled || internal) {
      this._selected = true;
      this.markForCheck();
      this._emitSelectionChangeEvent(internal);
    }
  }


  private markForCheck() {
    if (!(<any>this.changeDetectorRef).destroyed) {
      this.changeDetectorRef.markForCheck();
    }
  }

  _emitSelectionChangeEvent(internal = false) {
    let selectionChange = new OptionSelectionChangeEvent(this, internal);
    this.onSelect.emit(selectionChange);
    this.markForCheck();
  }

  /** Sets focus onto this option. */
  focus(): void {
    const element = this._getHostElement();

    if (typeof element.focus === 'function') {
      element.focus();
    }
  }

  /** Gets the host DOM element. */
  _getHostElement(): HTMLElement {
    return this.elementRef.nativeElement;
  }

  deselect(): void {
    this._selected = false;
  }

  select(): void {
    this._selected = true;
  }

  get isSelected(): boolean {
    return this._selected;
  }

}
