<div class="table__column--label-corrected--wrapper" *ngIf="item.value.value_corrected">
  <st-icon icon="abc" (click)="correctKeyword($event)" [popper]="keywordValueCorrectedTooltip" popperTrigger="hover"
  popperPlacement="right"
  popperAppendTo="body"></st-icon>
</div>
<span class="table__column--label-keyword" (click)="onToggleKeywordDetails.emit($event)">{{item.value.value}}</span>
<a [href]="getAmazonLink()" target="_blank" class="table__column--label-amazon">
  <st-icon icon="amazon_new"></st-icon>
</a>

<popper-content #keywordValueCorrectedTooltip>
  <div class="keyword-value-corrected__wrapper">
    <div i18n>Amazon corrected keyword</div>
    <div class="keyword-value-corrected">{{ item.value.value_corrected }}</div>
    <div i18n>Click to accept or dismiss.</div>
  </div>
</popper-content>
