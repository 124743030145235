<div class="breadcrumb" [ngClass]="className">
    <span *ngFor="let bread of breadcrumb; let last = last;">
        <span class="current" *ngIf="bread.value == ':asin'">{{ activeAsin }}</span>
        <span class="current" *ngIf="bread.value == ':user'">{{ activeUserName }}</span>    
       
        <ng-container *ngIf="bread.value != ':asin' && bread.value != ':user'">
            <span class="page" *ngIf="!last && hasProp(bread, 'hyperLinkValue')"> <a [routerLink]="bread.hyperLinkValue"> {{bread.value}} </a> </span>
            <span class="page" *ngIf="!last && !hasProp(bread, 'hyperLinkValue')"> {{bread.value}} </span>
            <span class="current" *ngIf="last">{{ bread.value }}</span>
        </ng-container>
        <span class="separator" *ngIf="!last"></span>
    </span>
</div>