import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IDropdownOption } from '../models/IDropdownOptions';

@Component({
  selector: 'st-dropdown-v2',
  templateUrl: './dropdown-v2.component.html',
  styleUrls: ['./dropdown-v2.component.scss']
})
export class DropdownV2Component implements OnInit, OnChanges {
  @Input() options: IDropdownOption[];
  @Input() dropdownPosition: 'down'|'up' = 'down';
  @Input() preselectedOptionEmpty: false;
  @Input() preselectedOption: IDropdownOption;
  @Input() color: 'blue'|'transparent' = 'blue';
  @Input() disabled: false;
  @Input() disabledOptionText: '';

  @Output() selected: EventEmitter<IDropdownOption> = new EventEmitter();

  selectedOption: IDropdownOption;

  opened = false;

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() {
    if (this.preselectedOption) {
      this.selectedOption = this.preselectedOption;
    } else if (!this.preselectedOptionEmpty && this.options && this.options.length > 0) {
      this.selectedOption = this.options[0];
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if ('preselectedOption' in changes) {
      const previousValue = changes.preselectedOption.previousValue;
      const currentValue = changes.preselectedOption.currentValue;

      if (JSON.stringify(previousValue) !== JSON.stringify(currentValue)) {
        this.selectedOption = currentValue;
        this.cd.detectChanges();
      }
    }
  }

  toggleDropdown() {
    if (this.disabled) {
      return;
    }

    this.opened = !this.opened;
  }

  selectOption(option: IDropdownOption) {
    if (option.disabled) {
      return;
    }

    this.selectedOption = option;
    this.selected.emit(this.selectedOption);
    this.opened = false;
  }
}
