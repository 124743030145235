
import {takeUntil, take} from 'rxjs/operators';
import {
  ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, Injectable, Injector,
  NgZone, Renderer2, TemplateRef, Type, ViewContainerRef, ViewRef
} from '@angular/core';
import { PlaceableComponent, PositionService } from '@app/shared/components/popover/services/position.service';
import { Subscription ,  Subject } from 'rxjs';

export class ContentRef {
  constructor(public nodes: any[], public viewRef?: ViewRef, public componentRef?: ComponentRef<any>) { }
}

export class PopoverService<T extends PlaceableComponent> {

  private _windowRef: ComponentRef<T>;
  private placement: string;
  private _windowFactory: ComponentFactory<T>;

  private _container: string | ElementRef;
  private _contentRef: ContentRef;

  private _zoneSubscription: Subscription;

  private _context: any;

  private onDestroy$ = new Subject();

  constructor(private _viewContainerRef: ViewContainerRef,
    private _ngZone: NgZone,
    private _posService: PositionService,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private _injector: Injector,
    private _componentFactoryResolver: ComponentFactoryResolver) {
    this.onDestroy$.pipe(take(1)).subscribe(() => {
      this.close();
    });
  }

  private _getPosition() {
    return this._posService.positionElements(
      this._elementRef.nativeElement,
      this._windowRef.location.nativeElement,
      this.placement,
      this._container === 'body');
  }

  attach(compType: Type<T>) {
    this._windowFactory = this._componentFactoryResolver
      .resolveComponentFactory<T>(compType);

    return this;
  }

  to(container?: string | ElementRef) {
    this._container = container;

    return this;
  }

  position(placement: string) {
    this.placement = placement || 'top';

    return this;
  }

  withContext(context?: any) {
    this._context = context;
  }

  open(content?: string | TemplateRef<any>, ): ComponentRef<T> {
    if (!content) {
      return;
    }

    if (!this.isOpen()) {
      this._zoneSubscription = this._ngZone.onStable.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
        if (this._windowRef) {
          this._renderer.addClass(this._windowRef.location.nativeElement, this.placement);
          this._windowRef.instance.applyPlacement(this._getPosition());
        }
      });
      const index = typeof(content) === 'string' ? 0 : 1;
      this._contentRef = this._getContentRef(content);
      this._windowRef =
        this._viewContainerRef.createComponent(this._windowFactory, index, this._injector, this._contentRef.nodes);
    }

    if (this._container === 'body') {
      document
        .querySelector(this._container as string)
        .appendChild(this._windowRef.location.nativeElement);
    }

    return this._windowRef;
  }

  close() {
    if (this.isOpen()) {
      if (!!this._zoneSubscription) {
        this._zoneSubscription.unsubscribe();
        this._zoneSubscription = null;
      }

      this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._windowRef.hostView));
      this._windowRef = null;

      if (this._contentRef.viewRef) {
        this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._contentRef.viewRef));
        this._contentRef = null;
      }
    }
  }

  isInsideWindow($event: MouseEvent) {
    if (!this._windowRef) { return false; }
    const divRect = this._windowRef.location.nativeElement.getBoundingClientRect();

    return $event.clientX >= divRect.left && $event.clientX <= divRect.right &&
      $event.clientY >= divRect.top && $event.clientY <= divRect.bottom;
  }

  closeWhenLeave() {
    this._renderer.listen(this._windowRef.location.nativeElement, 'mouseleave', () => {
      this.close();
    });
  }

  isOpen(): boolean {
    return !!this._windowRef;
  }

  private _getContentRef(content: string | TemplateRef<any>, context?: any): ContentRef {
    if (!content) {
      return new ContentRef([]);
    } else if (content instanceof TemplateRef) {
      const viewRef = this._viewContainerRef.createEmbeddedView(<TemplateRef<T>>content, context || this._context);
      return new ContentRef([viewRef.rootNodes], viewRef);
    } else {
      return new ContentRef([[this._renderer.createText(`${content}`)]]);
    }
  }


  public destroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}

@Injectable()
export class PopoverServiceFactory<T extends PlaceableComponent> {
  constructor(private _componentFactoryResolver: ComponentFactoryResolver,
    private _ngZone: NgZone,
    private _injector: Injector,
    private _posService: PositionService,
    private _renderer: Renderer2,
    private _applicationRef: ApplicationRef,
    private _elementRef: ElementRef, ) { }

  createService(_viewContainerRef: ViewContainerRef, ): PopoverService<T> {
    return new PopoverService(
      _viewContainerRef,
      this._ngZone,
      this._posService,
      this._elementRef,
      this._renderer,
      this._injector,
      this._componentFactoryResolver
    );
  }
}
