import { Injectable } from '@angular/core';
import { User } from '@app/shared/data/user/user.models';

declare const FS: any;

@Injectable()
export class FullStoryService {
  public static identify(user: User) {
    if (window.hasOwnProperty('FS')) {
      FS.identify(user.id + '', {
        'email': user.email,
        'displayName': user.first_name + ' ' + user.last_name,
        'Business Name': user.business_name,
        'First Name': user.first_name,
        'Last Name': user.last_name,
        'Phone Number': user.phone_number,
        'Is Impersonated': user.is_impersonated,
        'Active Credential': user.active_credential,
        'Resolution': window.screen.width + '/' + window.screen.height,
      });
    }
  }

}
