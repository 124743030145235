import { NgRedux } from '@angular-redux/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { BrowseProductsModalComponent } from '@app/client/v2/common/browse-products-modal/browse-products-modal.component';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { AppState } from '@app/shared/data/app-state.model';
import { CampaignManagerActions } from '@app/shared/data/campaign-manager/campaign-manager.actions';
import {IProductManagerProduct} from '@app/shared/data/product-manager/product-manager.models';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
@Component({
  selector: 'st-campaign-manager-browse-products',
  templateUrl: './campaign-manager-browse-products.component.html',
  styleUrls: ['./campaign-manager-browse-products.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignManagerBrowseProductsComponent implements OnInit, OnDestroy {

  selectedProduct: {product: IProductManagerProduct, children: any[]};

  @Input() preselectedProduct = null;
  @Input() singleVariationSelection: boolean = false;
  @Input() productType: 'CAMPAIGN_PRODUCT' | 'REWARD_PRODUCT' = 'CAMPAIGN_PRODUCT';

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private rudderTracking: RudderTrackingService,
    private ngRedux: NgRedux<AppState>,
    private cd: ChangeDetectorRef,
    private campaignManagerActions: CampaignManagerActions,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    if (this.preselectedProduct) {
      this.selectedProduct = {
        product: this.preselectedProduct.parent || this.preselectedProduct, 
        children: this.preselectedProduct.children || this.preselectedProduct.variations};
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  browseProducts() {
    const modal = this.modalService.open(BrowseProductsModalComponent);
    modal.instance.preselectedProduct = this.selectedProduct;
    modal.instance.singleVariationSelection = this.singleVariationSelection;
    modal.instance.productType = this.productType;
    modal.instance.searchQuery$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe(() => {
        this.rudderTracking.trackEvent('Competitor Manager - Find Competitors - Product lookup');
      });

    modal.result.then((result) => {
      if (result) {
        this.selectedProduct = result;
        this.setSelectedProduct();
      }
    })
  }

  setSelectedProduct() {
    if (this.productType === 'CAMPAIGN_PRODUCT') {
      this.ngRedux.dispatch(this.campaignManagerActions.setCampaignProduct(this.selectedProduct));
      this.rudderTracking.trackEvent('CM - Product Selection & Campaign Name Action', {
        action: "Select product"
      });
    } else {
      this.ngRedux.dispatch(this.campaignManagerActions.setCampaignRewardProduct(this.selectedProduct));
      this.rudderTracking.trackEvent('CM - Reward Setup - Free Gift Product Selected');
    }
  }

  removeSelectedProduct() {
    if (this.productType === 'CAMPAIGN_PRODUCT') {
      this.ngRedux.dispatch(this.campaignManagerActions.setCampaignProduct(null));
      this.rudderTracking.trackEvent('CM - Product Selection & Campaign Name Action', {
        action: "Remove selected product"
      });
    } else {
      this.ngRedux.dispatch(this.campaignManagerActions.setCampaignRewardProduct(null));
    }
    this.selectedProduct = null;
    this.canDetectChanges && this.cd.detectChanges();
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }
}
