import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { PayoutAllCommisionAdmin } from "@app/shared/data/admin/payout-all-commisions/payout-commision.model";
import { PayoutAdminActions } from "@app/shared/data/admin/payout-affiliate/payout-admin.actions";

const INITIAL_STATE: AppState = {
  data   : new Collection<PayoutAllCommisionAdmin>([]),
  loading: false,
};

export function payoutAllCommisonsReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == PaginationActions.getPagActionTypes(PayoutAllCommisionAdmin).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-payouts-all-commisions');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true
    };
  }

  if (action.type == PayoutAdminActions.TYPES.PAY_SUCCEEDED) {
    return {
      ...state,
      data : state['data'].clone().map((pa: PayoutAllCommisionAdmin) => {
        if (pa.commision_id != action.data.commissionId)
          return pa;

        return pa.clone(PayoutAllCommisionAdmin, {
          status : "paid",
          paid_at: action.data.payedAt,
        });
      }),
      error: null,
    };
  }

  return paginationReducer(PayoutAllCommisionAdmin)(state, action);
}
