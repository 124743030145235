
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseAPI } from "@app/shared/data/base.api";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { AdminSurvey, AdminSurveyQuestion } from "@app/shared/data/admin/survey/survey.models";
import { handleError } from "@app/helpers";

@Injectable()
export class AdminSurveyAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public load(params): Observable<Collection<AdminSurvey>> {
    return this.http.get(this.constructFullUrl('api/admin/survey'), { params: params }).pipe(
      map(data => AdminSurvey.collect<AdminSurvey>(data)));
  }

  public get(survey: AdminSurvey): Observable<AdminSurvey> {
    return this.http.get(this.constructFullUrl(`api/admin/survey/${survey.id}`)).pipe(
      map(data => new AdminSurvey(data)));
  }

  public getResults(survey: AdminSurvey): Observable<AdminSurvey> {
    return this.http.get(this.constructFullUrl(`api/admin/survey/${survey.id}/results`)).pipe(
      map(data => new AdminSurvey(data)));
  }

  public save(survey: AdminSurvey): Observable<AdminSurvey> {
    let path = this.constructFullUrl(`api/admin/survey`);
    let request = null;
    let data = {
      id: survey.id,
      name: survey.name,
      description: survey.description,
      questions: survey.questions.map((q:AdminSurveyQuestion) => {
        return {
          id: q.id,
          value: q.value,
          options: q.options,
          required: q.required,
          type: q.type.value
        }
      }),
    };

    if (survey.id) {
      request = this.http.put(`${path}/${survey.id}`, data);
    } else {
      request = this.http.post(`${path}`, data);
    }

    return request.map(data => new AdminSurvey(data))
      .catch(handleError);
  }
}
