
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { WalletTransaction } from '@app/shared/data/wallet-transactions/wallet-transactions.models';

@Injectable()
export class GiftCardsApi extends BaseAPI {

  constructor(protected http: HttpClient) {
    super();
  }

  public config(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/wallet/gift-cards'));
  }

  public create(data: any): Observable<WalletTransaction> {
    // API expects prices in cents
    return this.http.post(this.constructFullUrl('api/wallet/gift-cards'), data).pipe(
      map(transaction => new WalletTransaction(transaction)));
  }

  public resend(id: number, email: string): Observable<WalletTransaction> {
    return this.http.put(this.constructFullUrl('api/wallet/gift-cards/' + id), { email: email }).pipe(
      map(transaction => new WalletTransaction(transaction)));
  }
}
