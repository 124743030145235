import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from '@app/app.routing';
import { CoreModule } from '@app/core/core.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from '@app/shared/interceptors/token.interceptor';
import { AuthorizationInterceptor } from '@app/shared/interceptors/authorization.interceptor';
import { ModalModule } from '@app/shared/components/modals/modal.module';
import { DialogModule } from '@app/shared/components/dialog/dialog.module';
import { PaymentInterceptor } from '@app/shared/interceptors/payment.interceptor';
import { BillingModule } from '@app/client/billing/billing.module';
import { GeneralInterceptor } from '@app/shared/interceptors/general.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ApiKeysModule } from '@app/client/api-keys/api-keys.module';
import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import { I18n } from '@ngx-translate/i18n-polyfill';
import localeEN from '@angular/common/locales/en';
import localeZHSimplified from '@angular/common/locales/zh-Hans';
import localeSL from '@angular/common/locales/sl';
import { IntercomModule } from 'ng-intercom';
import { environment } from '@env/environment';
import * as Sentry from "@sentry/angular";
import { Router } from '@angular/router';
import { AuthModule} from '@auth0/auth0-angular';
import { LogoutAuth0Component } from './logout-auth0.component';

registerLocaleData(localeEN, 'en');
registerLocaleData(localeZHSimplified, 'zh');
registerLocaleData(localeSL, 'sl');

@NgModule({
  imports     : [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    ModalModule,
    DialogModule,
    AppRoutingModule,
    BillingModule,
    ApiKeysModule,
    IntercomModule.forRoot({
      appId: environment.INTERCOM_APP_ID,
      updateOnRouterChange: true,
    }),
    AuthModule.forRoot({
      domain: environment.AUTH0_DOMAIN,
      clientId: environment.AUTH0_CLIENT_ID,
      audience: environment.AUTH0_AUDIENCE,
      // cacheLocation: 'localstorage',
      useRefreshTokens: true,
      useRefreshTokensFallback: true,
      scope: 'openid profile email offline_access',
    }),
  ],
  declarations: [
    AppComponent,
    LogoutAuth0Component,
  ],
  providers   : [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({}),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: GeneralInterceptor,
      multi   : true,
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: PaymentInterceptor,
      multi   : true,
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: AuthorizationInterceptor,
      multi   : true,
    },
    {
      provide : HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi   : true,
    },
    {
      provide: APP_BASE_HREF,
      useFactory: (locale) => {
        console.log('application APP_BASE_HREF locale: ', locale);
        return '/' + locale + '/';
      },
      deps: [LOCALE_ID]
    },
    I18n
  ],
  bootstrap   : [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {}
}
