<div class="product-list__table">
  <div class="product-list__table-header">
    <div class="product-list__table-header-column product-list__table-header-column--status">
      <div class="status-wrapper" [class.status-wrapper--disabled]="loadingProducts">
        <div class="status-line"></div>
        <div class="status-steps" [class.status-steps--active]="isFilterActive">
          <div
            (click)="filterProducts(activityStatusEnum.INACTIVE)"
            [class.status-handle--active]="activeFilterStatus === activityStatusEnum.INACTIVE"
            class="status-handle status-handle--0"
            [stTooltip]="getStatusHandleTooltip(activityStatusEnum.INACTIVE)"></div>
          <div
            (click)="filterProducts(activityStatusEnum.ACTIVE)"
            [class.status-handle--active]="activeFilterStatus === activityStatusEnum.ACTIVE"
            class="status-handle middle status-handle--1"
            [stTooltip]="getStatusHandleTooltip(activityStatusEnum.ACTIVE)"></div>
          <div
            (click)="filterProducts(activityStatusEnum.PRIORITY)"
            [class.status-handle--active]="activeFilterStatus === activityStatusEnum.PRIORITY"
            class="status-handle end status-handle--2"
            [stTooltip]="getStatusHandleTooltip(activityStatusEnum.PRIORITY)"></div>
        </div>
      </div>
    </div>
    <div class="product-list__table-header-column product-list__table-header-column--name" (click)="sortBy(sortByEnum.TITLE)" i18n>Product name <div class="sort-icon"></div></div>
    <div class="product-list__table-header-column product-list__table-header-column--asin" i18n>ASIN</div>
    <div class="product-list__table-header-column product-list__table-header-column--sales-rank product-list__column--center" (click)="sortBy(sortByEnum.RANK)" i18n>Sales Rank <div class="sort-icon"></div></div>
    <div class="product-list__table-header-column product-list__table-header-column--keywords product-list__column--center" i18n>Keywords </div>
    <div class="product-list__table-header-column product-list__table-header-column--price" (click)="sortBy(sortByEnum.PRICE)" i18n>Price <div class="sort-icon"></div></div>
    <div class="product-list__table-header-column product-list__table-header-column--toggle-details product-list__column--center"></div>
    <!-- <div class="product-list__table-header-column product-list__table-header-column--action product-list__column--center"></div> -->
  </div>

  <div class="loader" *ngIf="loadingProducts">
    <st-loader [lines]="5"></st-loader>
  </div>

  <div class="product-list__table-products--empty" *ngIf="!loadingProducts && !products?.length">
    <div class="product-list__table-products--empty-title" i18n>No products yet.</div>
    <div class="product-list__table-products--empty-link" *ngIf="activeTab === tabTypes.OTHER_PRODUCTS" (click)="addProductsManually()" i18n>Add Products Manually</div>
  </div>

  <div class="product-list__table-products" *ngIf="!loadingProducts">
    <div class="product-list__table-product"
      [style.zIndex]="products.length - i"
      [class.product-list__table-product--opened]="product.detailsOpened"
      [class.product-list__table-product--inactive]="product.status === activityStatusEnum.INACTIVE"
      *ngFor="let product of products; let i = index">
      <div class="product-list__table-product-column product-list__table-product-column--status">
        <div class="status-wrapper" [class.disabled]="settingStatus">
          <div class="status-line"></div>
          <div class="status-steps">
            <div (click)="onStatusClick(activityStatusEnum.INACTIVE, product, i)" class="status-step"></div>
            <div (click)="onStatusClick(activityStatusEnum.ACTIVE, product, i)" class="status-step"></div>
            <div (click)="onStatusClick(activityStatusEnum.PRIORITY, product, i)" class="status-step"></div>
          </div>
          <div [class]="'status-handle status-handle--' + product.status">
            <span [stTooltip]="tooltipActivityStatusText(product.status)"></span>
          </div>
        </div>
      </div>
      <div class="product-list__table-product-column product-list__table-product-column--name" (click)="selectProduct(product)">
        <div class="product-list__missing-product-label" *ngIf="product.missing_at">
          <st-icon
            icon="warning-yellow"
            i18n-stTooltip stTooltip="This product was not found during the syncing with Amazon Seller Central."
            position="right"></st-icon>
        </div>
        <img [src]="product.image_url" [alt]="product.title" class="product-image" onerror="this.src='/assets/img/placeholder.svg'" (click)="selectProduct(product)">
        <span class="product-title">{{product.title}}</span>
        <a (click)="$event.stopPropagation();" class="product-amazon-link" [href]="getAmazonLink(product)" target="_blank">
          <st-icon icon="amazon"></st-icon>
        </a>
      </div>
      <div class="product-list__table-product-column product-list__table-product-column--asin" (click)="selectProduct(product)">{{product.asin}}</div>
      <div class="product-list__table-product-column product-list__table-product-column--sales-rank" [class.product-list__table-product-column--active]="product.activeTab === 'graph'" (click)="toggleProductDetails($event, product, 'graph')">
        <div class="sales-rank">
          <div class="sales-rank__value">{{(product.sales_rank | number: '1.0-0') || '-'}}</div>
          <div class="sales-rank__title" i18n>Current</div>
        </div>
        <div class="sales-rank sales-rank--change" [class.sales-rank--change-up]="product.sales_rank_change > 0" [class.sales-rank--change-down]="product.sales_rank_change < 0">
          <div class="sales-rank__value">{{(product.sales_rank_change | number: '1.0-0') || '-'}}</div>
          <div class="sales-rank__title" i18n>Change</div>
        </div>
      </div>
      <div class="product-list__table-product-column product-list__column--center product-list__table-product-column--keywords" (click)="selectProduct(product)">{{product.keywords_count}}</div>
      <div class="product-list__table-product-column product-list__table-product-column--price" (click)="selectProduct(product)">
        <ng-container *ngIf="product.min_price?.amount === product.max_price?.amount">
          {{product.min_price?.amount | currency: product.min_price?.currency_code}}
        </ng-container>
        <ng-container *ngIf="product.min_price?.amount !== product.max_price?.amount">
          {{product.min_price?.amount | currency: product.min_price?.currency_code}} - {{product.max_price?.amount | currency: product.max_price?.currency_code}}
        </ng-container>
      </div>

      <div class="product-list__table-product-column product-list__table-product-column--toggle-details" [class.product-list__table-product-column--active]="product.activeTab === 'details'">
        <div class="icon-toggle-details" (click)="toggleProductDetails($event, product, 'details')" *ngIf="activeTab === tabTypes.MY_PRODUCTS">
          <st-icon icon="toggle-details"></st-icon>
        </div>
        <div *ngIf="activeTab === tabTypes.OTHER_PRODUCTS" class="icon-delete">
          <st-icon (click)="deleteProduct($event, product)" [class.disabled]="loadingDeleteProduct" icon="delete-v2"></st-icon>
        </div>
      </div>

      <!-- <div class="product-list__table-product-column product-list__table-product-column--action">
        <st-button-v2
          (clicked)="selectProduct(product)"
          color="v2_red"
          i18n-label
          [label]="product.keywords_count > 0 ? 'KEYWORDS' : 'SETUP'"
          size="small"
          justify="space-between"
          [icon]="buttonIcons.V2_ARROW_RIGHT"
          iconPosition="after"></st-button-v2>
      </div> -->

      <div class="product-list__product-details" *ngIf="product.detailsOpened" (click)="preventDefault($event)">
        <div class="product-list__product-details-tabs">
          <div class="product-list__product-details-tab"
            [class.product-list__product-details-tab--active]="product.activeTab === 'graph'"
            (click)="setActiveTab('graph', product)" i18n>BSR Graph</div>
          <div class="product-list__product-details-tab"
            [class.product-list__product-details-tab--active]="product.activeTab === 'details'"
            (click)="setActiveTab('details', product)" i18n>Product Details</div>
        </div>
        <div class="product-list__product-details-tabs-content">
          <div class="product-list__product-details-tabs-panel" *ngIf="product.activeTab === 'graph'">
            <div class="product-list__product-graph" (click)="preventDefault($event)">
              <div class="loader" *ngIf="loadingGraphData">
                <st-loader [lines]="5"></st-loader>
              </div>

              <st-stock-chart [rangeSelector]="{enabled: false}"
                              *ngIf="!loadingGraphData"
                              (selection)="graphSelectionChanged()">
                <axis [min]="1" [reversed]="true">
                  <line [data]="bsrGraph" name="Sales Rank" [options]="lineOptions"></line>
                </axis>
              </st-stock-chart>
            </div>
          </div>
          <div class="product-list__product-details-tabs-panel" *ngIf="product.activeTab === 'details'">
            <div class="product-list__product-details-section">
              <div class="product-list__product-details-section-header" i18n>Tags</div>
              <div class="product-list__product-details-tags">
                <st-tags [tags]="product.tags" [loading]="loadingTags" (onRemove)="removeTagFromProduct($event, product)" (onAdd)="addTagToProduct($event, product)"></st-tags>
              </div>
            </div>
            <div class="product-list__product-details-section">
              <div class="product-list__product-details-section-header" i18n>Product Details</div>
              <div class="product-list__product-details-parent-product">
                <div class="product-list__product-details-parent-product-column">
                  <div class="product-list__product-details-parent-product-wrapper">
                    <div class="product-list__product-details-parent-product-image" [style.backgroundImage]="'url(' + product.image_url + ')'"></div>
                  </div>
                </div>
                <div class="product-list__product-details-parent-product-column">
                  <div class="product-list__product-details-parent-product-line">
                    <div class="product-list__product-details-parent-product-line__title" i18n>Asin:</div>
                    <div class="product-list__product-details-parent-product-line__content">{{product.asin}}</div>
                  </div>
                  <div class="product-list__product-details-parent-product-line">
                    <div class="product-list__product-details-parent-product-line__title" i18n>Title:</div>
                    <div class="product-list__product-details-parent-product-line__content">{{product.title}}</div>
                  </div>
                  <div class="product-list__product-details-buttons" *ngIf="showResetButton">
                    <st-button-v2 color="v2_blue_empty" [disabled]="product.resettingProduct" [label]="product.resettingProduct ? 'Resetting product...' : 'Reset'" i18n-label (clicked)="resetProduct(product)" [icon]="buttonIcons.V2_RESET" [hoverIcon]="buttonIcons.V2_RESET_WHITE"></st-button-v2>
                  </div>
                </div>
              </div>
            </div>

            <div class="loader" *ngIf="loadingProductVariations">
              <st-loader [lines]="5"></st-loader>
            </div>

            <div class="product-list__product-details-section" *ngIf="productVariations?.length > 0 && !loadingProductVariations">
              <div class="product-list__product-details-section-header" i18n>Product Variations</div>
              <div class="product-list__table">
                <div class="product-list__table-header">
                  <div class="product-list__table-header-column product-list__table-header-column--name" i18n>Variation</div>
                  <div class="product-list__table-header-column product-list__table-header-column--asin" i18n>ASIN</div>
                </div>

                <div class="product-list__table-products">
                  <div class="product-list__table-product"
                    [style.zIndex]="productVariations?.length - i"
                    *ngFor="let product of productVariations; let i = index">
                    <div class="product-list__table-product-column product-list__table-product-column--name">
                      <img [src]="product.image_url" [alt]="product.title" class="product-image" onerror="this.src='/assets/img/placeholder.svg'">
                      <span class="product-title">{{product.title}}</span>
                    </div>

                    <div class="product-list__table-product-column product-list__table-product-column--asin">{{product.asin}}</div>
                  </div>
                </div>
              </div>

              <div class="product-list__product-details-footer">
                <div class="product-list__product-details-footer-column"></div>
                <div class="product-list__product-details-footer-column">
                  <st-pagination
                    [currentPage]="variationCurrentPage"
                    [pages]="variationPagesArray"
                    (goTofirstPage)="variationGoToFirstPage(product)"
                    (goToLastPage)="variationGoToLastPage(product)"
                    (goToNextPage)="variationNextPage(product)"
                    (goToPreviousPage)="variationPreviousPage(product)"
                    (changePage)="variationChangePage($event, product)"></st-pagination>
                </div>
                <div class="product-list__product-details-footer-column product-list__product-details-footer-column--pagination-info">
                  <st-pagination-info
                    [text]="currentDisplayedVariationProducts"
                    [disabled]="loadingProductVariations"
                    [options]="perPageOptions"
                    (onPageChange)="variationPageChange($event, product)"
                    [preSelectedOption]="preSelectedOption"></st-pagination-info>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
