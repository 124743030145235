import { BaseModel } from '@app/shared/data/base.models';

export class Usage extends BaseModel {
  id: number;
  plan_id: number;
  name: string;
  description: string;
  limit: number | 'Unlimited';
  limit_reached: number;
  percentage_reached: string;
  visible: boolean;
  color: string;
  percentage: number;

  public getRemainingUnits(): null | number {
    if (this.limit === 'Unlimited') {
      return null;
    }

    return this.limit - this.limit_reached;
  }
}
