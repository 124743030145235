import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbsNumberPipe } from '@app/shared/pipes/abs-number.pipe';
import { StrFilterPipe } from '@app/shared/pipes/str-filter.pipe';
import { FilterPipe } from '@app/shared/pipes/filter.pipe';
import { FilterFnPipe } from '@app/shared/pipes/filter-fn.pipe';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { DiscountedPricePipe } from '@app/shared/pipes/discounted-price.pipe';
import { TimeAgoPipe } from '@app/shared/pipes/nice-date-format.pipe';
import { PositiveNumberPipe } from '@app/shared/pipes/positive-number.pipe';
import { GroupByPipe } from '@app/shared/pipes/group-by.pipe';
import { SafeHtmlPipe } from '@app/shared/pipes/safe-html.pipe';
import { SearchPipeV2 } from './search-v2.pipe';
import { KeywordPipe } from './keyword-manager-pipe';
import { KeywordsPipe } from './keywords.pipe';
import { KeywordSortPipe } from './keyword-manager-sort.pipe';
import { StringLengthPipe } from '@app/shared/pipes/string-length.pipe';
import { OrderByPipe } from './order-by.pipe';
import { CredentialSortPipe } from './credential-sort.pipe';

const COMPONENTS = [
  FilterPipe,
  FilterFnPipe,
  StrFilterPipe,
  TruncatePipe,
  DiscountedPricePipe,
  SearchPipeV2,
  TimeAgoPipe,
  PositiveNumberPipe,
  GroupByPipe,
  AbsNumberPipe,
  SafeHtmlPipe,
  KeywordPipe,
  KeywordsPipe,
  OrderByPipe,
  KeywordSortPipe,
  CredentialSortPipe,
  StringLengthPipe
];
const DIRECTIVES = [];

@NgModule({
  imports     : [
    CommonModule
  ],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports     : [
    ...COMPONENTS, ...DIRECTIVES
  ]
})
export class PipesModule {
}
