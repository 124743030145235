import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { SystemSettingsApi } from '@app/shared/data/admin/system-settings/system-settings.api';
import { SystemSettingsActions } from '@app/shared/data/admin/system-settings/system-settings.actions';
import { SystemSettings } from '@app/shared/data/admin/system-settings/system-settings.models';
import { ToastService } from '@app/core/services/toast.service';

@Injectable()
export class SystemSettingsEpics {
  constructor(private systemSettingsApi: SystemSettingsApi,
              private systemSettingsActions: SystemSettingsActions,
              private toastService: ToastService,
              private ngRedux: NgRedux<AppState>) {}

  public createEpic() {
    return [
      this.getSettings,
      this.saveSettings,
    ];
  }

  getSettings = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == SystemSettingsActions.TYPES.GET) {
        this.systemSettingsApi.fetch()
          .subscribe(
            (settings: SystemSettings) => {
              this.ngRedux.dispatch(SystemSettingsActions.getSettingsSucceeded(settings));
            },
            (response) => {
              this.ngRedux.dispatch(SystemSettingsActions.getSettingsFailed(response));
            },
            () => {}
          );
      }

      return next(action);
    };
  };

  saveSettings = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == SystemSettingsActions.TYPES.SAVE) {
        this.systemSettingsApi.save(action.data)
          .subscribe(
            (settings: SystemSettings) => {
              this.ngRedux.dispatch(SystemSettingsActions.saveSuccedded(settings));
              this.toastService.success("Saved!",'Settings has been successfully updated!');
            },
            (response) => {
              this.ngRedux.dispatch(SystemSettingsActions.saveFailed(response));
              this.toastService.success("Error!",'Settings has NOT been updated!');
            },
            () => {}
          );
      }

      return next(action);
    };
  };
}
