import { BaseModel } from "@app/shared/data/base.models";

export class AdminPlan extends BaseModel {
  static className = 'AdminPlan';

  id: number;
  remote_id: string;
  name: string;
  price: number;
  display_price: number;
  feature: string;
  features: string[];
  end_after_days: number;
  available: boolean;
  available_at_registration: boolean;
  cancel_at_period_end: boolean;

  permissions?: any[];
  limits?: any[];
}

