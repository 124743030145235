import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';

@Injectable()
export class AsinCompareAPI extends BaseAPI {

  baseApiUrl = 'api/reverse-asin';

  constructor(protected http: HttpClient) {
    super();
  }

  public requestCompare(
    asin: string,
    groupId: string,
  ): Observable<any> {
    return this.http.get(
      this.constructFullUrl('api/reverse-asin/' + groupId + '/compare'),
      { params: { 'asin': asin }, observe: 'response' }
    );
  }
}
