import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { AmazonSnapshotActions } from '@app/shared/data/admin/amazon-snapshot/amazon-snapshot.actions';
import { AmazonSnapshotApi } from '@app/shared/data/admin/amazon-snapshot/amazon-snapshot.api';
import { AppState } from '@app/shared/data/app-state.model';

@Injectable()
export class AmazonSnapshotEpics {
  constructor(private amazonSnapshotActions: AmazonSnapshotActions,
              private store: NgRedux<AppState>,
              private toastService: ToastService,
              private amazonSnapshotApi: AmazonSnapshotApi) {}

  public createEpic() {
    return [
      this.show,
    ];
  }

  show = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == AmazonSnapshotActions.TYPES.SHOW) {
        this.amazonSnapshotApi.show(action.marketplace_id, action.data)
          .subscribe(
            (data: any) => {
              this.store.dispatch(this.amazonSnapshotActions.showSucceeded(data));
            },
            (response) => {
              this.store.dispatch(this.amazonSnapshotActions.showFailed(response));
              this.toastService.error('Derp!', response.error.error.message);
            },
            () => {}
          );
      }

      return next(action);
    }
  };
}
