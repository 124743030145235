import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { AlertIssue, BEAlert, UserAlerts } from '@app/shared/data/alert/alert.models';
import { AlertsService } from '@app/client/alerts/alerts.service';

@Injectable()
export class UserAlertActions extends PaginationActions {

  static readonly TYPES = {
    SET_ALERTS: '[USER_ALERT]SET_ALERTS',
    SAVE_SUCCESS: '[USER_ALERT]SAVE_SUCCESS',
    SAVE_FAILED: '[USER_ALERT]SAVE_FAILED',
    REMOVE: '[USER_ALERT]REMOVE',
    REMOVE_SUCCESS: '[USER_ALERT]REMOVE_SUCCESS',
    REMOVE_FAILED: '[USER_ALERT]REMOVE_FAILED',
    REMOVE_ALL: '[ALERT]REMOVE_ALL',
    ACKNOWLEDGE_ISSUE: '[USER_ALERT]ACKNOWLEDGE_ISSUE',
    ACKNOWLEDGE_ISSUE_SUCCESS: '[USER_ALERT]ACKNOWLEDGE_ISSUE_SUCCESS',
    ACKNOWLEDGE_ISSUE_FAILED: '[USER_ALERT]ACKNOWLEDGE_ISSUE_FAILED',
    ACKNOWLEDGE_ISSUES: '[USER_ALERT]ACKNOWLEDGE_ISSUES',
    ACKNOWLEDGE_ISSUES_SUCCESS: '[USER_ALERT]ACKNOWLEDGE_ISSUES_SUCCESS',
    ACKNOWLEDGE_ISSUES_FAILED: '[USER_ALERT]ACKNOWLEDGE_ISSUES_FAILED',
  };

  protected model = UserAlerts;

  setAlerts(results): StAction {
    return {
      type: UserAlertActions.TYPES.SET_ALERTS,
      data: results,
    };
  }

  saveSuccess(): StAction {
    return {
      type: UserAlertActions.TYPES.SAVE_SUCCESS,
      data: {},
    };
  }

  remove(alert: BEAlert) {
    return {
      type: UserAlertActions.TYPES.REMOVE,
      data: {
        alert: alert,
        source: AlertsService.userSource,
      },
    };
  }

  removeAll() {
    return {
      type: UserAlertActions.TYPES.REMOVE_ALL,
      data: {
        source: AlertsService.userSource,
      },
    };
  }

  removeSuccess(): StAction {
    return {
      type: UserAlertActions.TYPES.REMOVE_SUCCESS,
      data: {},
    };
  }

  removeFailed(error: any) {
    return {
      type: UserAlertActions.TYPES.REMOVE_FAILED,
      data: error,
    };
  }

  acknowledgeIssue(issue: AlertIssue): StAction {
    return {
      type: UserAlertActions.TYPES.ACKNOWLEDGE_ISSUE,
      data: {
        issue: issue,
      },
    };
  }

  acknowledgedIssueSucceeded(issue: AlertIssue): StAction {
    return {
      type: UserAlertActions.TYPES.ACKNOWLEDGE_ISSUE_SUCCESS,
      data: {
        issue: issue,
      },
    };
  }

  acknowledgedIssueFailed(error: any): StAction {
    return {
      type: UserAlertActions.TYPES.ACKNOWLEDGE_ISSUE_FAILED,
      data: {
        error: error,
      },
    };
  }

  acknowledgeIssues(): StAction {
    return {
      type: UserAlertActions.TYPES.ACKNOWLEDGE_ISSUES,
      data: {
        source: AlertsService.userSource,
      },
    };
  }

  acknowledgedIssuesSucceeded(): StAction {
    return {
      type: UserAlertActions.TYPES.ACKNOWLEDGE_ISSUES_SUCCESS,
      data: {},
    };
  }

  acknowledgedIssuesFailed(error: any): StAction {
    return {
      type: UserAlertActions.TYPES.ACKNOWLEDGE_ISSUES_FAILED,
      data: {
        error: error,
      },
    };
  }
}
