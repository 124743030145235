<div class="select interface-component"
     [ngClass]="{'interface-component': theme != 'big', 'selected': selected !== null}" stDropdown>
    <button [disabled]="this.disabled" type="button" class="button"
            [ngClass]="{'solid white field': theme == 'big',
             'interface-component': theme != 'big', 'grey-bg': theme == 'grey', 'white-bg': theme == 'white'}" stDropdownOpen>
        <ng-template #defaultLabelTemplate>
            {{selected}}
        </ng-template>

        <ng-template #defaultInnerLabelTemplate>
            {{selectedInner}}
        </ng-template>

        <input [disabled]="disabled" *ngIf="search" (input)="searchTextChanged.next($event.target.value)" [placeholder]="placeholder"
               class="placeholder-input" [value]="selectedInputText" type="text"/>

        <ng-template *ngIf="!search && selected !== null"
                     [ngTemplateOutlet]="labelTemplate || (innerHtmlLabel ? defaultInnerLabelTemplate : defaultLabelTemplate)"
                     [ngTemplateOutletContext]="{ selected: selected, selectedInner: selectedInner, selectedRaw: selectedRaw }">
        </ng-template>
        <ng-container *ngIf="!search && selected === null">
            <input *ngIf="search" (input)="searchTextChanged.next($event.target.value)" class="placeholder-input" type="text"/>
            <span *ngIf="!search" class="placeholder">{{ placeholder }}</span>
        </ng-container>
        <span *ngIf="loading" class="loading-circle">
            <div>
                <div class="loading-dots--dot"></div>
                <div class="loading-dots--dot"></div>
                <div class="loading-dots--dot"></div>
            </div>
        </span>
    </button>
    <ol class="options" [class.anchor-right]="anchorRight" [style.maxHeight.px]="height" tabindex="-1" [stNotClosableZone]="multiple" #options>
        <div class="transformer">
            <li *ngIf="multiple && showCheckUncheckButtons">
                <button (click)="selectAll()" type="button" class="button interface-component icon" [class.grey-bg]="theme == 'grey'" [class.white-bg]="theme == 'white'">
                    <st-icon icon="check" class="icon icon-m"></st-icon><span i18n>Check All</span>
                </button>
            </li>
            <li *ngIf="multiple && showCheckUncheckButtons">
                <button (click)="deselectAll()" type="button" class="button interface-component icon" [class.grey-bg]="theme == 'grey'" [class.white-bg]="theme == 'white'">
                    <st-icon icon="close" class="icon icon-m"></st-icon><span i18n>Uncheck All</span>
                </button>
            </li>
            <hr *ngIf="multiple && showCheckUncheckButtons" />
            <ng-content></ng-content>
        </div>
    </ol>
</div>
