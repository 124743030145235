import { Injectable } from '@angular/core';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { WalletTransaction, EmailLog } from '@app/shared/data/wallet-transactions/wallet-transactions.models';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '../base.models';

@Injectable()
export class WalletTransactionsActions extends PaginationActions {
  static readonly TYPES = {
    TOP_UP: '[WALLET_TRANSACTION]TOP_UP',
    TOP_UP_SUCCEEDED: '[WALLET_TRANSACTION]TOP_UP_SUCCEEDED',
    TOP_UP_FAILED: '[WALLET_TRANSACTION]TOP_UP_FAILED',
    SEARCH: '[WALLET_TRANSACTION]SEARCH',
    FILTER: '[WALLET_TRANSACTION]FILTER',
    RESEND: '[WALLET_TRANSACTION]RESEND',
    RESEND_SUCCEEDED: '[WALLET_TRANSACTION]RESEND_SUCCEEDED',
    RESEND_FAILED: '[WALLET_TRANSACTION]RESEND_FAILED',
    EXPORT: '[WALLET_TRANSACTION]EXPORT',
    EXPORT_FINISHED: '[WALLET_TRANSACTION]EXPORT_FINISHED',
    SEND_GIFT_CARD: '[WALLET_TRANSACTIONS]SEND_GIFT_CARD',
    SEND_GIFT_CARD_SUCCEEDED: '[WALLET_TRANSACTIONS]SEND_GIFT_CARD_SUCCEEDED',
    SEND_GIFT_CARD_FAILED: '[WALLET_TRANSACTIONS]SEND_GIFT_CARD_FAILED',
    MARK_AS_AVAILABLE: '[WALLET_TRANSACTIONS]MARK_AS_AVAILABLE',
    MARK_AS_AVAILABLE_SUCCEEDED: '[WALLET_TRANSACTIONS]MARK_AS_AVAILABLE_SUCCEEDED',
    MARK_AS_AVAILABLE_FAILED: '[WALLET_TRANSACTIONS]MARK_AS_AVAILABLE_FAILED',
    GET_STATS: '[WALLET_TRANSACTIONS]GET_STATS',
    GET_STATS_SUCCEEDED: '[WALLET_TRANSACTIONS]GET_STATS_SUCCEEDED',
    GET_STATS_FAILED: '[WALLET_TRANSACTIONS]GET_STATS_FAILED',
    LOAD_EMAIL_LOGS: '[WALLET_TRANSACTIONS]LOAD_EMAIL_LOGS',
    LOAD_EMAIL_LOGS_SUCCEEDED: '[WALLET_TRANSACTIONS]LOAD_EMAIL_LOGS_SUCCEEDED',
    LOAD_EMAIL_LOGS_FAILED: '[WALLET_TRANSACTIONS]LOAD_EMAIL_LOGS_FAILED',
  };

  protected model = WalletTransaction;

  topUp(amount: number, paymentMethod: string, checkAmount: number, remoteId: string = null, paymentIntent: string = null): StAction {
    return {
      type: WalletTransactionsActions.TYPES.TOP_UP,
      data: {
        amount,
        paymentMethod,
        checkAmount,
        remoteId,
        paymentIntent,
      }
    };
  }

  topUpSucceeded(transaction: WalletTransaction): StAction {
    return {
      type: WalletTransactionsActions.TYPES.TOP_UP_SUCCEEDED,
      data: {
        transaction
      }
    };
  }

  topUpFailed(error): StAction {
    return {
      type: WalletTransactionsActions.TYPES.TOP_UP_FAILED,
      data: {
        error
      }
    };
  }

  filter(filters: any): StAction {
    return {
      type: WalletTransactionsActions.TYPES.FILTER,
      data: {
        ...filters
      }
    };
  }

  resend(transaction: WalletTransaction, email: string): StAction {
    return {
      type: WalletTransactionsActions.TYPES.RESEND,
      data: {
        transaction,
        email
      }
    };
  }

  resendSucceeded(transaction: WalletTransaction): StAction {
    return {
      type: WalletTransactionsActions.TYPES.RESEND_SUCCEEDED,
      data: {
        transaction
      }
    };
  }

  resendFailed(transaction: WalletTransaction, error: any): StAction {
    return {
      type: WalletTransactionsActions.TYPES.RESEND_FAILED,
      data: {
        transaction,
        error
      }
    };
  }

  export(): StAction {
    return {
      type: WalletTransactionsActions.TYPES.EXPORT,
      data: {}
    };
  }

  exportFinished(): StAction {
    return {
      type: WalletTransactionsActions.TYPES.EXPORT_FINISHED,
      data: {}
    };
  }

  sendGiftCard(data: any): StAction {
    return {
      type: WalletTransactionsActions.TYPES.SEND_GIFT_CARD,
      data: {
        ...data
      }
    };
  }

  sendGiftCardSucceeded(): StAction {
    return {
      type: WalletTransactionsActions.TYPES.SEND_GIFT_CARD_SUCCEEDED,
      data: {}
    };
  }

  sendGiftCardFailed(error: any): StAction {
    return {
      type: WalletTransactionsActions.TYPES.SEND_GIFT_CARD_FAILED,
      data: {
        error
      }
    };
  }

  markAsAvailable(transactionId: number): StAction {
    return {
      type: WalletTransactionsActions.TYPES.MARK_AS_AVAILABLE,
      data: {
        transactionId
      }
    };
  }

  markAsAvailableSucceeded(transactionId: number): StAction {
    return {
      type: WalletTransactionsActions.TYPES.MARK_AS_AVAILABLE_SUCCEEDED,
      data: {
        transactionId
      }
    };
  }

  markAsAvailableFailed(transactionId: number): StAction {
    return {
      type: WalletTransactionsActions.TYPES.MARK_AS_AVAILABLE_FAILED,
      data: {
        transactionId
      }
    };
  }

  loadEmailLogs(transaction: WalletTransaction): StAction {
    return {
      type: WalletTransactionsActions.TYPES.LOAD_EMAIL_LOGS,
      data: {
        transaction
      }
    };
  }

  loadEmailLogsSucceeded(logs: Collection<EmailLog>): StAction {
    return {
      type: WalletTransactionsActions.TYPES.LOAD_EMAIL_LOGS_SUCCEEDED,
      data: {
        logs,
      }
    };
  }

  loadEmailLogsFailed(): StAction {
    return {
      type: WalletTransactionsActions.TYPES.LOAD_EMAIL_LOGS_FAILED,
      data: {
      }
    };
  }

  getStats(): StAction {
    return {
      type: WalletTransactionsActions.TYPES.GET_STATS,
      data: {}
    };
  }

  getStatsSucceeded(stats: {}): StAction {
    return {
      type: WalletTransactionsActions.TYPES.GET_STATS_SUCCEEDED,
      data: {
        ...stats
      }
    };
  }

  getStatsFailed(error: any): StAction {
    return {
      type: WalletTransactionsActions.TYPES.GET_STATS_FAILED,
      data: {
        error
      }
    };
  }
}
