import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { I18n } from "@ngx-translate/i18n-polyfill";


@Component({
  selector: 'st-textarea-tags-v2',
  templateUrl: './textarea-tags.component.html',
  styleUrls: ['./textarea-tags.component.scss'],
})
export class TextareaTagsComponent implements OnInit {
  @Input() placeholder = this.i18n('+ Add keywords/phrase');
  @Output() tagsChange: EventEmitter<string[]> = new EventEmitter();

  tags: string[] = [];
  value: string;

  constructor(private i18n: I18n) { }

  ngOnInit() { }

  removeTag(tag: string) {
    this.tags = this.tags.filter((internalTag: string) => internalTag !== tag);
    this.tagsChange.emit(this.tags);
  }

  addWord() {
    const value = this.value;

    if (!value || value.length === 0) {
      return;
    }

    this.tags = [
      ...this.tags,
      value
    ];

    this.value = null;
    this.tagsChange.emit(this.tags);
  }

  onPaste(event: ClipboardEvent) {
    const phrases = event.clipboardData.getData('text').split(/\r?\n/);

    if (phrases) {
      this.tags = [
        ...this.tags,
        ...phrases
      ];

      /**
       * This needs to be wrapper into a 0ms timeout because
       * otherwise the value keeps the input value and it doesnt clear.
       */
      setTimeout(() => {
        this.value = '';
        this.tagsChange.emit(this.tags);
      }, 0);
    }
  }
}
