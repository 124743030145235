<st-dialog #dialog="dialog">
  <div dialog-content>
    <div class="loading-wrapper" *ngIf="!secret">
      <st-icon icon="spinner" class="icon icon-l"></st-icon>
    </div>
    <div [hidden]="!secret">
      <div class="title" i18n>Update Credit Card</div>
      <div class="field-container">
        <label for="card-element" i18n>Card Details</label>
        <div id="card-element"></div>
      </div>

      <div class="field-container field-container--last" [class.error]="errorMessage">
        <label for="cardholderName" i18n>Cardholder Name</label>
        <input type="text" id="cardholderName" [(ngModel)]="cardholderName" [disabled]="saving" required>
      </div>
      <div class="separator"></div>
    </div>
  </div>
  <div dialog-footer>
    <st-error *ngIf="errorMessage">{{ errorMessage }}</st-error>

    <div class="buttons" *ngIf="secret">
      <st-button-v2 color="v2_red_empty" (clicked)="cancel()" [disabled]="saving" label="CANCEL" i18n-label></st-button-v2>
      <st-button-v2 color="v2_red" (clicked)="save()" [disabled]="saving" label="SAVE CHANGES" i18n-label></st-button-v2>
    </div>
  </div>
</st-dialog>
