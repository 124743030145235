export class ChartEvent {
  originalEvent: Event;
  context: any;

  constructor(event: Event, context) {
    this.originalEvent = event;
    this.context = context;
  }
}

const chartEvents = [
  'addSeries',
  'afterPrint',
  'beforePrint',
  'drilldown',
  'drillup',
  'load',
  'redraw',
  'selection'
];

const seriesEvents = [
  'click',
  'afterAnimate',
  'checkboxClick',
  'hide',
  'legendItemClick',
  'mouseOut',
  'mouseOver',
  'show'
];

const pointEvents = [
  'click',
  // 'remove',
  // 'select',
  // 'unselect',
  // 'mouseOut',
  // 'mouseOver',
  // 'update'
];

const xAxisEvents = [
  'afterBreaks',
  'afterSetExtremes',
  'pointBreak',
  'pointInBreak',
  'setExtremes'
];

const yAxisEvents = [
  'afterBreaks',
  'afterSetExtremes',
  'pointBreak',
  'pointInBreak',
  'setExtremes'
];

export {
  chartEvents,
  seriesEvents,
  pointEvents,
  xAxisEvents,
  yAxisEvents,
}
