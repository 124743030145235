import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { Collection, DownloadBlob } from '@app/shared/data/base.models';
import { WalletTransaction, EmailLog } from '@app/shared/data/wallet-transactions/wallet-transactions.models';
import { CustomHttpParamEncoder } from '@app/shared/UrlEncoders/get-param-encoder';
import { map } from 'rxjs/operators';

@Injectable()
export class WalletTransactionsApi extends BaseAPI {

  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<WalletTransaction>> {
    const httpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder, // needed to encode "+" in phone numbers
      fromObject: params
    });

    return this.http.get(this.constructFullUrl(`api/wallet/transactions`), { params: httpParams }).pipe(
      map(data => WalletTransaction.collect(data, WalletTransaction.BACKEND_PAGINATION)));
  }

  public markAsAvailable(id: number, params = {}): Observable<any> {
    return this.http.post(this.constructFullUrl(`api/wallet/transactions/` + id + `/available`), { params: params });
  }

  public create(amount: number, method: string, checkAmount: number, remoteId: string = null, paymentIntent: string = null): Observable<WalletTransaction> {
    const data = {
      amount: Math.round(amount * 100),
      payment_method: method,
      check_amount: Math.round(checkAmount * 100),
      remote_id: remoteId,
      payment_intent: paymentIntent,
    };

    // API expects prices in cents
    return this.http.post(this.constructFullUrl('api/wallet/transactions'), data).pipe(
      map(transaction => new WalletTransaction(transaction)));
  }

  public export(): Observable<DownloadBlob> {
    return this.http.post<any>(this.constructFullUrl('api/wallet/export-transactions'), {}, {responseType: 'blob' as 'json', observe: 'response'})
      .pipe(map(
        (resp: HttpResponse<any>) => {
          var filename = null;
          if (resp.headers.has('content-disposition')) {
            var contentDispositionHeader = resp.headers.get('content-disposition');
            var result = contentDispositionHeader.split(';')[1].trim().split('=')[1];
            filename = result.replace(/"/g, '');
          }

          return {
            filename,
            blob: new Blob([resp.body], { type: "octet/stream" })
          };
        }
      ));
  }

  public stats() {
    return this.http.get(this.constructFullUrl('api/wallet/transactions/stats'), {});
  }

  public logs(hash: string): Observable<Collection<EmailLog>> {
    return this.http.get(this.constructFullUrl(`api/wallet/transactions/` + hash + `/email-logs`)).pipe(
      map(data => EmailLog.collect(data)));
  }
}
