import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from "@angular/core";
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'st-radio-button-v2',
  templateUrl: './radio-button.component.html',
  styleUrls: ['./radio-button.component.scss']
})
export class RadioButtonComponent implements OnChanges, OnDestroy {
  @Input() checked = false;
  @Input() label?: string;
  @Input() name: string;
  @Input() disabled = false;
  
  id = uuidv4();
  radioChecked;

  @Output() changed: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnChanges() {
    this.radioChecked = this.checked;
  }

  ngOnDestroy() { }

  onChanged() {
    this.changed.emit(this.radioChecked);
  }

  toggle(event) {
    const checked = event.target.checked;
    this.radioChecked = checked;
    this.onChanged();
  }
}
