import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, OnInit } from '@angular/core';
import { PlaceableComponent, Position } from "@app/shared/components/popover/services/position.service";

@Component({
  selector       : 'st-tooltip-wrapper',
  template       : `
    <div class="arrow"></div>
    <div class="content">
      <ng-content></ng-content>
    </div>
  `,
  styleUrls      : ['./tooltip-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipWrapperComponent implements OnInit, PlaceableComponent, AfterViewInit {

  constructor(private _elementRef: ElementRef,) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  applyPlacement(position: Position) {
    this._elementRef.nativeElement.style.top = `${position.top}px`;
    this._elementRef.nativeElement.style.left = `${position.left}px`;
  }
}
