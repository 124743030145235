
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { Collection } from '@app/shared/data/base.models';
import { BlacklistEntry, BlacklistInvitation, BlacklistInvitationCollection, BlacklistSettings } from './blacklist.models';

@Injectable()
export class BlacklistApi extends BaseAPI {

  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<BlacklistEntry>> {
    return this.http.get(this.constructFullUrl(`api/wallet/blacklist`), { params: params }).pipe(
      map(data => BlacklistEntry.collect(data, BlacklistEntry.SIMPLE_PAGINATION)));
  }

  public addEntry(field: string, value: string): Observable<BlacklistEntry> {
    return this.http.post(this.constructFullUrl(`api/wallet/blacklist`), {
      field: field,
      value: value,
    }).pipe(map(data => new BlacklistEntry(data)));
  }

  public addEntries(entries: { field: string, value: string }[], note: string): Observable<any> {
    return this.http.post(this.constructFullUrl(`api/wallet/blacklist/bulk`), {
      entries: entries,
      note: note,
    });
  }

  public replaceEntry(oldEntry: BlacklistEntry, value: string, note: string): Observable<BlacklistEntry> {
    return this.http.put(this.constructFullUrl(`api/wallet/blacklist`), {
      field: oldEntry.field,
      value: oldEntry.value,
      new_value: value,
      note: note,
    }).pipe(map(data => new BlacklistEntry(data)));
  }

  public removeEntry(field: string, value: string): Observable<BlacklistEntry> {
    return this.http.request('delete', this.constructFullUrl(`api/wallet/blacklist`), {
      body: {
        field: field,
        value: value,
      }
    }).pipe(map(data => new BlacklistEntry(data)));
  }

  public invitations(): Observable<BlacklistInvitationCollection> {
    return this.http.get(this.constructFullUrl(`api/wallet/blacklist/share`)).pipe(
      map(data => {
        return {
          sent: BlacklistInvitation.collect<BlacklistInvitation>(data['sent']),
          received: BlacklistInvitation.collect<BlacklistInvitation>(data['received'])
        };
      }));
  }

  public share(email: string): Observable<BlacklistInvitation> {
    return this.http.post(this.constructFullUrl(`api/wallet/blacklist/share`), { email: email }).pipe(
      map(data => new BlacklistInvitation(data)));
  }

  public acceptInvitation(id: number): Observable<BlacklistInvitation> {
    return this.http.put(this.constructFullUrl(`api/wallet/blacklist/share/` + id), {}).pipe(
      map(data => new BlacklistInvitation(data)));
  }

  public removeInvitation(id: number): Observable<any> {
    return this.http.delete(this.constructFullUrl(`api/wallet/blacklist/share/` + id));
  }

  public loadSettings(): Observable<BlacklistSettings> {
    return this.http.get(this.constructFullUrl(`api/wallet/blacklist-settings`)).pipe(
      map(data => new BlacklistSettings(data)));
  }

  public saveSettings(settings: BlacklistSettings): Observable<BlacklistSettings> {
    return this.http.post(this.constructFullUrl(`api/wallet/blacklist-settings`), settings.getData()).pipe(
      map(data => new BlacklistSettings(data)));
  }
}
