
import { BaseModel } from '@app/shared/data/base.models';

export class PlanPermission {
  description: string;
  id: number;
  is_system: boolean;
  name: string;
  slug: string;
}
export class Plan extends BaseModel {
  id: number;
  remote_id: string;
  name: string;
  price: number;
  annual: boolean;
  display_price: number;
  feature: string;
  features: string[];
  permissions: PlanPermission[];
  end_after_days: number;
  monthly_price?: number;
  walletPayoutFee?: number;
  fees?: PlanFee[];
}

export class PlanFee {
  amount: number;
  fixed_amount: number;
  minimum: number;
  slug:
    'credit_card_processing' |
    'paypal_service' |
    'paypal_processing' |
    'paypal_flat' |
    'ach_service' |
    'ach_processing' |
    'credit_card_service' |
    'phone_number_scoring_noninitial_check' |
    'mastercard_gift_card_service' |
    'phone_number_scoring_initial_check';
  type: 'fixed'|'percentage';
}
