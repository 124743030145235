import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { Usage } from "@app/shared/data/usage/usage.models";

@Injectable()
export class UsageActions {
  static readonly TYPES = {
    GET_USAGE          : '[USAGE]GET_USAGE',
    GET_USAGE_SUCCEEDED: '[USAGE]GET_USAGE_SUCCEEDED',
    GET_USAGE_FAILED   : '[USAGE]GET_USAGE_FAILED',

    // EVENTS
    LIMITS_REACHED: '[USAGE]LIMITS_REACHED',
    USAGE_UPDATED : '[USAGE]USAGE_UPDATED',
  };

  static getUsage(): StAction {
    return {
      type: UsageActions.TYPES.GET_USAGE,
      data: null
    }
  }

  static getUsageSucceeded(usage: Collection<Usage>): StAction {
    return {
      type: UsageActions.TYPES.GET_USAGE_SUCCEEDED,
      data: usage
    }
  }

  static getUsageFailed(error): StAction {
    return {
      type: UsageActions.TYPES.GET_USAGE_FAILED,
      data: error
    }
  }

  static limitsReached(): StAction {
    return {
      type: UsageActions.TYPES.LIMITS_REACHED,
      data: true
    }
  }
}
