import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { paginationReducer } from "@app/shared/data/pagination/pagination.reducers";
import { LS } from "@app/shared/services/local-storage.service";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { PayoutByPlanAdmin } from "@app/shared/data/admin/payout-by-plan/payout-by-plan.model";

const INITIAL_STATE: AppState = {
  data      : new Collection<PayoutByPlanAdmin>([]),
  loading   : false,
  _is_paying: false,
  error     : null,
};

export function payoutByPlanReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == PaginationActions.getPagActionTypes(PayoutByPlanAdmin).LOAD_PAGE) {
    LS.forActiveUser().setPerPage(action.data.pagination.perPage, 'admin-payouts-by-plan');

    return {
      ...state,
      _be_pagination: {
        page : action.data.pagination.page,
        sort : action.data.pagination.sort,
        limit: action.data.pagination.perPage
      },
      loading       : true,
      _is_paying    : false,
      error         : null
    };
  }

  return paginationReducer(PayoutByPlanAdmin)(state, action);
}
