
import {of as observableOf, from as observableFrom,  Subject ,  Observable } from 'rxjs';

import {delay, mergeMap, map, switchMap, takeUntil, filter} from 'rxjs/operators';
import { AfterViewInit, ChangeDetectionStrategy, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Notice } from '@app/shared/data/notice/notice.models';
import { Collection } from '@app/shared/data/base.models';
import { NoticesService } from '@app/shared/layout/notices-list/notices.service';
import { NgRedux, select } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { NoticeActions } from '@app/shared/data/notice/notice.actions';
import { Router } from '@angular/router';

@Component({
  selector : 'st-notices-list',
  templateUrl: './notices-list.component.html',
  styleUrls: ['./notices-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class NoticesListComponent implements OnInit, OnDestroy, AfterViewInit {
  private _destroy$: Subject<void> = new Subject<void>();

  data: Collection<Notice>;

  @select(['notices', 'system'])
  systemNotices$: Observable<Collection<Notice>>;

  @select(['notices', 'user'])
  userNotices$: Observable<Collection<Notice>>;

  constructor(private noticesService: NoticesService,
              private store: NgRedux<AppState>,
              private router: Router,
  ) {}

  @HostListener('window:resize', [])
  onResize() {
    this.noticesService.updateLayout();
  }

  ngAfterViewInit(): void {
    this.delayResize();

    this.userNotices$.pipe(
    takeUntil(this._destroy$))
    .subscribe(() => {
      this.delayResize();
    });
  }
  resize() {
    this.noticesService.updateLayout();
  }

  delayResize() {
    setTimeout( () => {
      this.resize();
    }, 700);
  }

  addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  onClick(handler: () => {} | null) {
    if (handler) {
      handler();
    }
  }

  goToUrl(url) {
    if (url !== '') {
      this.router.navigate([url]);
    }
  }

  ngOnInit(): void {
    this.noticesService.loadSystemNotices();
    this.noticesService.loadUserBanners();

    this.systemNotices$.pipe(
      filter(coll => Boolean(coll)),
      switchMap((coll: Collection<Notice>) =>
      observableFrom(coll.toArray()).pipe(mergeMap(x => observableOf(x).pipe(delay(x.ttl * 1000))))),
      map(NoticeActions.noticeDeleted),
      takeUntil(this._destroy$),)
      .subscribe(this.store.dispatch.bind(this.store));
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
