import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService as Auth0AuthService } from '@auth0/auth0-angular';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';

@Component({
  template: ``,
})
export class LogoutAuth0Component implements OnInit, OnDestroy {
  constructor(private authService: AuthService,
              private auth0Service: Auth0AuthService,
              private route: ActivatedRoute,
              private router: Router) {
  }

  private onDestroy$ = new Subject();
  private returnUrl = '/';

  ngOnInit() {
    this.route.queryParams.pipe(takeUntil(this.onDestroy$)).subscribe(params => {
      if ('return' in params) {
        this.returnUrl = params['return'];
      }

      this.authService.logout().then(
        (user) => {
          if (user) {
            this.router.navigate(['/admin']);
          } else {
            const queryParams = new HttpParams().set('return', this.returnUrl);

            this.auth0Service.logout({
              returnTo: window.location.origin + '/en/auth/sign-in?' + queryParams.toString(),
            });
          }
        }
      );
    });
  }


  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
