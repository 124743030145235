import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ComponentsModule } from '@app/shared/components/components.module';
import { LayoutModule } from '@app/shared/layout/layout.module';
import { PipesModule } from '@app/shared/pipes/pipes.module';
import { MaxBytesValueValidator } from '@app/shared/validators/max-bytes.directive';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
  imports     : [
    CommonModule,
    RouterModule,
    PipesModule,
    ComponentsModule,
    ClipboardModule,
  ],
  declarations: [
    MaxBytesValueValidator
  ],
  exports     : [
    CommonModule,
    LayoutModule,
    PipesModule,
    ComponentsModule,
    ClipboardModule,
    RouterModule,
    FormsModule,
  ]
})
export class SharedModule {
}
