import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { Subscription } from '@app/shared/data/subscription/subscription.models';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';

const INITIAL_STATE: AppState = {
  data: new Collection<Subscription>([]),
  _loading: false,
  _success: false,
  _deleting: false,
  _error: null,
  _newSubscription: null
};

export function subscriptionReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === SubscriptionActions.TYPES.LOAD || action.type === 'billable_status_changed') {
    return {
      ...state,
      _loading: true,
      _error: null,
      _newSubscription: null
    };
  }

  if (action.type === SubscriptionActions.TYPES.CREATE) {
    return {
      ...state,
      _error: null,
      _newSubscription: null
    };
  }

  if (action.type === SubscriptionActions.TYPES.LOAD_SUCCEEDED) {
    return {
      ...state,
      data: action.data,
      _loading: false,
      _success: true,
      _error: null
    };
  }

  if (action.type === SubscriptionActions.TYPES.LOAD_FAILED) {
    return {
      ...state,
      _loading: false,
      _error: action.data
    };
  }

  if (action.type === SubscriptionActions.TYPES.CREATE_SUCCEEDED) {
    return {
      ...state,
      data: action.data,
      _newSubscription: action.data.data[action.data.data.length - 1]
    };
  }

  if (action.type === SubscriptionActions.TYPES.CANCEL) {
    return {
      ...state,
      _deleting: true,
      _error: null
    };
  }

  if (action.type === SubscriptionActions.TYPES.CANCEL_SUCCEEDED) {
    const data = state['data'].map((subscription) => {
      if (subscription.id === action.data['subscription'].id) {
        return action.data['subscription'];
      }
      return subscription;
    }).filter((subscription: Subscription) => subscription.active || subscription.on_trial || subscription.is_incomplete || subscription.on_grace_period);

    return {
      ...state,
      data: data,
      _deleting: false,
      _error: null
    };
  }

  if (action.type === SubscriptionActions.TYPES.CANCEL_FAILED) {
    return {
      ...state,
      _deleting: false,
      _error: action.data
    };
  }

  if (action.type === SubscriptionActions.TYPES.CREATE_FAILED) {
    return {
      ...state,
      _error: action.data
    };
  }

  if (action.type === SubscriptionActions.TYPES.EMPTY_CACHE) {
    state = INITIAL_STATE;
    return {
      ...state,
    };
  }

  return state;
}
