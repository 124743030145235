<ng-container *ngIf="item.value">
  <img src="/assets/img/icons/cross-gray.svg" *ngIf="!item.value.value" class="table-icon--cross">
  <span class="value" *ngIf="item.value.value">{{item.value.value}}</span>
  <img src="/assets/img/icons/star-gold.svg" class="value-star"
       popperTrigger="hover"
       popperPlacement="bottom"
       popperAppendTo="body"
       [popper]="popperTooltip"
       *ngIf="item.value.star">
</ng-container>


<popper-content #popperTooltip>
  <div class="popper--wrapper" [innerHTML]="item.value?.tooltip"></div>
</popper-content>
