import { isLowerCase } from '@app/helpers';
import { Filter } from '@app/shared/components/filter-builder/filter/filter';
import { AlertsService } from '@app/client/alerts/alerts.service';

export class Alert {

  public id: number;
  public asin: string;
  public internal_id: number;
  public filter: Filter;
  public channel_id: number;
  public type_val: string;
  public selected_users: number[];
  public selected_destinations: AlertDestination[];
  private keywordSelectionTypes: string[] = [AlertsService.rankingType, AlertsService.indexType];
  private apiPromotionSelectionTypes: string[] = [AlertsService.apiPromoCodeCountType];
  public template_id?: number;
  public save_template?: boolean;
  public update_template?: boolean;
  public be_alert_id?: number;

  constructor(obj) {
    obj && Object.assign(this, obj);
  }

  public static getRandomId() {
    return Math.floor(Math.random() * Math.floor(99999));
  }

  public static hasUsersSelected(selectedUserIds: number[]): boolean {
    return selectedUserIds && selectedUserIds.length > 0;
  }

  public static hasValidDestinations(selectedDestinations: AlertDestination[]): boolean {
    return selectedDestinations
      && selectedDestinations.length > 0
      && selectedDestinations.filter(d => !d.validated).length === 0;
  }

  hasValidTypeValue(): boolean {
    return this.type_val && this.type_val !== '' && isLowerCase(this.type_val);
  }

  needsToEnterKeywords(): boolean {
    return this.filter && this.keywordSelectionTypes.indexOf(this.filter.field) !== -1;
  }

  needsToSelectAPIPromotion(): boolean {
    return this.filter && this.apiPromotionSelectionTypes.indexOf(this.filter.field) !== -1;
  }

  hasUsersSelected(): boolean {
    return Alert.hasUsersSelected(this.selected_users);
  }

  hasValidDestinations(): boolean {
    return Alert.hasValidDestinations(this.selected_destinations);
  }

  getDestinations() {
    return this.selected_destinations.map((d: AlertDestination) => d.value);
  }

  compareTo(b: Alert) {
    return this.asin === b.asin
      && this.filter.field === b.filter.field
      && this.filter.operator === b.filter.operator
      && this.filter.value === b.filter.value
      && this.channel_id === b.channel_id
      && this.type_val === b.type_val
      && JSON.stringify(this.selected_users) === JSON.stringify(b.selected_users)
      && JSON.stringify(this.getDestinations()) === JSON.stringify(b.getDestinations());
  }
}

export class AlertChannel {

  public static appChannel = 1;
  public static emailChannel = 2;
  public static smsChannel = 3;
  public static manyChatChannel = 4;
  public static webhookChannel = 5;

  public static noSelection = 0;
  public static userSelection = 1;
  public static inputSelection = 2;

  id: number;
  name: string;
  description: string;
  selection: number;

  constructor(id, selection, name, description) {
    this.id = id;
    this.selection = selection;
    this.name = name;
    this.description = description;
  }

  noSelectionNeeded(): boolean {
    return this.selection === AlertChannel.noSelection;
  }

  needsToSelectUsers(): boolean {
    return this.selection === AlertChannel.userSelection;
  }

  needsToEnterInputs(): boolean {
    return this.selection === AlertChannel.inputSelection;
  }
}

export class AlertDestination {
  id: number;
  value: string;
  validated = false;

  constructor() {
    this.id = Alert.getRandomId();
  }
}
