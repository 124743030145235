
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { BaseAPI } from "@app/shared/data/base.api";
import { LastLaunchPromotion } from "@app/shared/data/last-launch-promotion/last-launch-promotion.models";

@Injectable()
export class LastLaunchPromotionAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<LastLaunchPromotion>> {
    return this.http.get(this.constructFullUrl('api/last-launch/promotions'), { params: params }).pipe(
      map(data => LastLaunchPromotion.collect<LastLaunchPromotion>(data, LastLaunchPromotion.BACKEND_PAGINATION)));
  }

  public cancel(id): Observable<any> {
    return this.http.delete(this.constructFullUrl('api/last-launch/promotions/' + id));
  }
}
