
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { InvoicesCollection } from "@app/shared/data/invoice/invoice.models";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { BaseAPI } from "@app/shared/data/base.api";

export interface IInvoiceAPI {
  all(startingAafter: string, endingBefore: string): Observable<InvoicesCollection>;
  download(id: string);
}

@Injectable()
export class InvoiceAPI extends BaseAPI implements IInvoiceAPI {
  protected path = 'api/billing/invoices';

  constructor(protected http: HttpClient) {
    super();
  }

  public all(startingAafter: string = null, endingBefore: string = null): Observable<InvoicesCollection> {
    let params = {};

    if (startingAafter) {
      params['starting_after'] = startingAafter;
    }

    if (endingBefore) {
      params['ending_before'] = endingBefore;
    }

    return this.http.get<InvoicesCollection>(this.constructFullUrl(this.path), {
      params: params,
    });
  }

  public download(id: string) {
    return this.http.get(this.constructFullUrl(this.path + '/' + id), { responseType: "blob" }
    ).pipe(map(
      (res) => {
        return new Blob([res], { type: 'application/pdf' })
      }));
  }
}

@Injectable()
export class WalletInvoiceAPI extends InvoiceAPI{
  protected path = 'api/billing/wallet-invoices';

  constructor(protected http: HttpClient) {
    super(http);
  }
}
