
import {of as observableOf, Observable} from 'rxjs';

import {mergeMap, first, zip, catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import {DecisionActions} from "@app/shared/data/decision/decision.actions";
import {DecisionAPI} from "@app/shared/data/decision/decision.api";
import {NgRedux} from "@angular-redux/store";
import {AppState} from "@app/shared/data/app-state.model";

@Injectable()
export class DecisionEpics {

  constructor(private store: NgRedux<AppState>,
              private decisionApi: DecisionAPI,
              private decisionActions: DecisionActions) {}
  public createEpic() {
    return [
      this.load,
      this.save,
    ];
  }

  load = store => next => action => {
    if (action.type == DecisionActions.TYPES.LOAD_DECISIONS) {
      this.decisionApi.all(action.data.asin).pipe(
        map(this.decisionActions.loadSucceeded.bind(this.decisionActions)),
        catchError((error) => observableOf(this.decisionActions.loadFailed(error))),)
        .subscribe(this.store.dispatch.bind(this.store));
    }
    return next(action);
  };

  save = store => next => action => {
    if(action.type == DecisionActions.TYPES.SAVE_DECISIONS) {
      this.store.select(['decisions', 'data']).pipe(
        map(data => ({
          to_relevant  : data['relevant'].filter(k => !k.relevant).map(k => k.value),
          to_irrelevant: data['irrelevant'].filter(k => k.relevant).map(k => k.value)
        })),
        zip(this.store.select(['active_product', 'data', 'asin'])),
        first(),
        mergeMap(([data, asin]) => this.decisionApi.store(asin, data)),
        map(this.decisionActions.saveSucceeded.bind(this.decisionActions)),
        catchError((error) => observableOf(this.decisionActions.saveFailed(error))),)
        .subscribe(this.store.dispatch.bind(this.store));
    }
    return next(action);
  }
}
