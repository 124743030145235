import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { AffiliateAPI } from "@app/shared/data/affiliate/affiliate.api";
import { AffiliateActions } from "@app/shared/data/affiliate/affiliate.actions";
import { Collection } from "@app/shared/data/base.models";
import { Affiliate } from "@app/shared/data/affiliate/affiliate.models";

@Injectable()
export class AffiliateEpics {
  constructor(private affiliateActions: AffiliateActions,
              private affiliateAPI: AffiliateAPI,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == AffiliateActions.getPagActionTypes(Affiliate).LOAD_PAGE) {
        const params = {
          ...action.data.pagination,
          limit: action.data.pagination.perPage,
          sort : action.data.pagination.sort.join(',')
        };
        this.affiliateAPI.all(params)
          .subscribe(
            (affiliates: Collection<Affiliate>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.affiliateActions.setPage(affiliates));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.affiliateActions.loadPageFailed(response));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };
}
