
import {startWith, delay, tap, merge, mergeMap, take, zip, share, takeUntil, switchMapTo, distinctUntilChanged, switchMap, filter, map } from 'rxjs/operators';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ValueAccessorBase } from "@app/shared/layout/forms/lib/base-value-accessor";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { BehaviorSubject ,  Observable ,  Subject ,  pipe } from "rxjs";

@Component({
  selector   : 'st-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls  : ['./range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers      : [
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RangeSliderComponent),
      multi      : true,
    },
  ],
})
export class RangeSliderComponent extends ValueAccessorBase<{from:number, to:number}> implements OnChanges, OnDestroy, OnInit {
  constructor(protected cd: ChangeDetectorRef) {
    super(cd);
  }

  @Input()
  max: number = 100;
  @Input()
  min: number = 0;

  @Input()
  precision: number = 0.01;

  @Input("graphPoints")
  graphPoints: {x: number, y:number}[] = null;

  @Input("freqDataset")
  freqDataset: number[] = [];

  @Input()
  groupWidth: number = 20;

  disabled: boolean = false;
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cd.markForCheck();
  }

  @ViewChild("inputLeft", { static: true })
  inputLeft: ElementRef;

  @ViewChild("inputRight", { static: true })
  inputRight: ElementRef;


  typing: Subject<boolean> = new Subject<boolean>();

  private onDestroy$ = new Subject();
  ngOnDestroy() {
    this.onDestroy$.next(true);
  }

  keyDown(e:KeyboardEvent) {
    if(!this.disabled && ((e.key >='0' && e.key <= '9') || e.key == "Backspace")) {
      this.typing.next(true);
    }
  }

  inputKeyUp(e:KeyboardEvent, input = 0) {
    if(e.key == 'Enter' || e.key == 'Escape') {
      this.rangeContainer.nativeElement.focus();
    }
    else if(e.key == 'Tab') {
      if(this.movingSlider$.getValue() == 0) {
        this.inputRight.nativeElement.focus();
        this.movingSlider$.next(1);
      } else {
        this.inputLeft.nativeElement.focus();
        this.movingSlider$.next(0);
      }
    }
    else if(input == 0)
      this.innerValueChange.next({from: (e.target as any).value, to:this.toVal$.getValue()});
    else
      this.innerValueChange.next({from: this.fromVal$.getValue(), to:(e.target as any).value});
  }

  focusLost(e:FocusEvent) {
    if(e.relatedTarget != this.inputLeft.nativeElement
      && e.relatedTarget != this.inputRight.nativeElement)
      this.typing.next(false);
  }

  ngOnChanges(changes: SimpleChanges) {

    if(changes.graphPoints) {
      this.points.next(this.graphPoints);
    }

    if(changes.freqDataset) {
      if(this.freqDataset)
        this.freqDataset$.next(this.freqDataset);
      else
        this.freqDataset$.next([]);
    }

    if(changes.min || changes.max) {
      if(this.min !== null && this.max !== null) {
        this.minMax.next({min: this.min, max: this.max > this.min ? this.max : this.min + 1});

        if(!this.isDirty.getValue()) {
          this.fromVal$.next(this.minMax.getValue().min);
          this.toVal$.next(this.minMax.getValue().max);
        }
      }
    }
  }

  private minMax: BehaviorSubject<{min:number, max:number}> = new BehaviorSubject({min: 0, max: 100});
  private freqDataset$ = new BehaviorSubject<number[]>([]);

  // public left$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  // private right$: BehaviorSubject<number> = new BehaviorSubject<number>(40);

  private toPercentPipe = (minMax) => map((x: number) => (x - minMax.min)/(minMax.max-minMax.min));

  private containerOffsets$: BehaviorSubject<{l:number, r:number, w:number, h:number}> = new BehaviorSubject({l: 0, r:0, w:0, h:0});

  public fromVal$: BehaviorSubject<number> = new BehaviorSubject<number>(this.min);
  public toVal$: BehaviorSubject<number> = new BehaviorSubject<number>(this.max);

  public fromLeftOffset$: Observable<number> = this.minMax.pipe(switchMap(minMax => this.containerOffsets$.pipe(switchMap(offs => this.fromVal$.pipe((this.toPercentPipe(minMax)),map(x => offs.w * x),)))));
  public toLeftOffset$: Observable<number> = this.minMax.pipe(switchMap(minMax => this.containerOffsets$.pipe(switchMap(offs => this.toVal$.pipe((this.toPercentPipe(minMax)),map(x => offs.w * x),)))));
  public toRightOffset$: Observable<number> = this.containerOffsets$.pipe(switchMap(offs => this.toLeftOffset$.pipe(map(x => offs.w - x))));

  public sliderOffsets$: Observable<number[]> = this.fromLeftOffset$.pipe(switchMap(x => this.toLeftOffset$.pipe(map(y => [x, y]))));

  public isDirty: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private mouseToValPipe = (offs, minMax) =>
    pipe(
      map((e: MouseEvent|TouchEvent) => e instanceof TouchEvent ? e.touches[0].clientX : e.clientX),
      map(x => minMax.min + (minMax.max - minMax.min) * (x - offs.l)/offs.w),
      map( x => Math.ceil(x/this.precision)*this.precision),
      map( x => Math.round(x*100)/100),
      map(x => x < minMax.min ? minMax.min : x), // Check value bounds
      map(x => x > minMax.max ? minMax.max : x) // Check value bounds
    );

  private slideMoverPipe = (slider) =>
    pipe(
      filter(([s, _]) => s == slider),
      map(([_, x]) => x),
      filter(x => slider === 0 ? x <= this.toVal$.getValue() : x >= this.fromVal$.getValue())
    );

  private mouseEvent$ = new Subject<MouseEvent>();
  private mouseClickEvent$ = new Subject<MouseEvent>();
  private mouseUpEvent$ = new Subject<MouseEvent>();
  private movingSlider$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  private ctx: CanvasRenderingContext2D;


  @ViewChild('rangeContainer', { static: true })
  rangeContainer: ElementRef;

  @ViewChild('canvas', { static: true })
  canvas: ElementRef;

  ngAfterViewInit() {
    // set default value
    this.fromVal$.next((this.value && this.value.from) ? this.value.from : this.min);
    this.toVal$.next((this.value && this.value.to) ? this.value.to : this.max);

    this.typing.pipe(
        distinctUntilChanged(),
        filter(x => !!x),
        switchMapTo(this.movingSlider$),
        map(s => s == 0 ? this.inputLeft.nativeElement : this.inputRight.nativeElement),
        takeUntil(this.onDestroy$),)
        .subscribe(x => {
          if(document.activeElement != x) {
              x.value = "";
              x.focus();
          }
        });

    this.typing.pipe(
        distinctUntilChanged(),
        filter(x => !x),
        takeUntil(this.onDestroy$),)
        .subscribe(() => {
          this.inputLeft.nativeElement.value = this.fromVal$.getValue();
          this.inputRight.nativeElement.value = this.toVal$.getValue();
        });

    const slide$ = this.minMax.pipe(switchMap(minMax => this.containerOffsets$.pipe(
      switchMap(offs =>
        this.movingSlider$.pipe(
          switchMap(slider => this.mouseEvent$.pipe((this.mouseToValPipe(offs, minMax)),map(x => [slider, x]),)))
      ))),share(),);

    // Slide left slider
    slide$.pipe((this.slideMoverPipe(0)),
      takeUntil(this.onDestroy$),)
      .subscribe(this.fromVal$.next.bind(this.fromVal$));

    // Slide right slider
    slide$.pipe((this.slideMoverPipe(1)),
      takeUntil(this.onDestroy$),)
      .subscribe(this.toVal$.next.bind(this.toVal$));


    // When clicked determinate which slider to move
    this.minMax.pipe(switchMap(minMax => this.containerOffsets$.pipe(
      switchMap(offs =>
        this.mouseClickEvent$.pipe((this.mouseToValPipe(offs, minMax)),switchMap(x => this.fromVal$.pipe(zip(this.toVal$),take(1),map(arr => [x, ...arr]),)),)
      ))),
      map(([x , l, r]) =>  l == r ? (x > l ? 1 : 0) : (Math.abs(x - l) < Math.abs(x - r) ? 0 : 1)),
      takeUntil(this.onDestroy$),)
      .subscribe(this.movingSlider$.next.bind(this.movingSlider$));

    // When clicked mark as dirty
    this.isDirty.pipe(filter(x => !x),switchMapTo(this.mouseClickEvent$.pipe(take(1))),
      takeUntil(this.onDestroy$),)
      .subscribe(() => {
        this.isDirty.next(true);
      });

    // Emit value change on value change
    this.fromVal$.pipe(mergeMap(f => this.toVal$.pipe(map(t => ({from: f, to: t})))),
      takeUntil(this.onDestroy$),)
      .subscribe((x) => this.value = x);


    // Detect ng model chnges
    this.minMax.pipe(switchMap(minMax => this.innerValueChange.pipe(map(fmto => ({...fmto, ...minMax})))),
      merge(this.minMax.pipe(switchMap(minMax => this.fromVal$.pipe(take(1),zip(this.toVal$.pipe(take(1))),map(([from, to]) => ({from, to, ...minMax})),)))),
      distinctUntilChanged(),
      takeUntil(this.onDestroy$),)
      .subscribe(({from, to, min, max}) => {

        if(from === null)
          from = min;

        if(to === null)
          to = max;

        // Limits
        if(from <= min)
          from = min;
        if(to >= max)
          to = max;
        if(from > to)
          from = to;

        if(!from) from = 0;
        if(!to) to = 0;

        this.fromVal$.next(from);
        this.toVal$.next(to);

      });

    const canvasEl: HTMLCanvasElement = this.canvas.nativeElement;

    this.ctx = canvasEl.getContext('2d');


    this.calculateBounds();

    //leftRange, rightRange, width, height, pts:
    this.containerOffsets$.pipe(
      map(offs => ({...offs, w: offs.w * window.devicePixelRatio, h: offs.h * window.devicePixelRatio})),
      tap(x => this.canvas.nativeElement.setAttribute('width', x.w)),
      tap(x => this.canvas.nativeElement.setAttribute('height', x.h)),
      switchMap(offs => this.sliderOffsets$.pipe(map(l => ({range: [l[0] * window.devicePixelRatio, l[1] * window.devicePixelRatio], width: offs.w, height: offs.h})))),
      switchMap(l => this.points.pipe(map(points => ({...l, pts: points})))),
      switchMap(l => this.minMax.pipe(map(minMax => ({...l, ...minMax})))),
      map(l => ({...l, pts: this.normalizePoints(l.pts, l.min, l.max, 1, 1, l.width-1, l.height-5)})),
      takeUntil(this.onDestroy$),)
      .subscribe(args => this.drawGraph(args.range[0], args.range[1], args.width, args.height, args.pts));

    this.freqDataset$.pipe(
      switchMap(freqDataset => this.containerOffsets$.pipe(map(offs => [freqDataset, offs.w/this.groupWidth]))),
      switchMap(l => this.minMax.pipe(map(minMax => [...l, minMax.min, minMax.max]))),
      takeUntil(this.onDestroy$),)
      .subscribe(args => this.handlePoints.apply(this, args));

    this.showGraph$.pipe(filter(x => x),delay(10),takeUntil(this.onDestroy$),).subscribe(() => this.calculateBounds());

    this.fromVal$.pipe(takeUntil(this.onDestroy$)).subscribe(this.resizeInput.bind(this, this.inputLeft.nativeElement));
    this.toVal$.pipe(takeUntil(this.onDestroy$)).subscribe(this.resizeInput.bind(this, this.inputRight.nativeElement));

    this.typing.pipe(switchMap(typing =>
        this.fromVal$.pipe(filter(() => !typing || this.inputLeft.nativeElement.value != ""))
      ),
      takeUntil(this.onDestroy$),)
      .subscribe(x => this.inputLeft.nativeElement.value = x);


    this.typing.pipe(switchMap(typing =>
        this.toVal$.pipe(filter(() => !typing || this.inputRight.nativeElement.value != ""))
      ),
      takeUntil(this.onDestroy$),)
      .subscribe(x => this.inputRight.nativeElement.value = x);

    this.typing.next(false);
  }

  private handlePoints(freqDataset, groupcount, min = 0, max = 100) {
    if(!freqDataset || freqDataset.length <= 0) {
      this.points.next([]);
      return;
    }

    const step = (max - min) / groupcount;
    freqDataset.sort();

    // Count frequencies by group
    const groups = freqDataset.reduce((acc, x) => {
      const group = Math.floor((x-min)/step);

      if(group in acc)
        acc[group]++;
      else
        acc[group] = 1;

      return acc;
    },{});

    let graphPoints = [{x: min, y: 0}];
    for(let g = 0; g < groupcount; g++) {
      graphPoints.push({x: min + (step * g), y: groups[g] || 0});
      graphPoints.push({x: Math.min(min + (step * (g+1)), max), y: groups[g] || 0});
    }
    graphPoints.push({x: max, y:0});

    this.points.next(graphPoints);
  }

  private normalizePoints(pts: {x: number, y:number}[], xmin, xmax, startx, starty, endx, endy) {
    const y = pts.map(x => x.y);

    const ymax = Math.max(...y);
    const ymin = Math.min(...y);

    return pts.map(p => ({x: this.normalize(p.x, xmin, xmax, startx, endx), y: this.normalize(p.y, ymin, ymax, starty, endy)}));
  }

  private normalize(val, start1, stop1, start2, stop2) {
    return start2 + (((val-start1)/(stop1-start1)) * (stop2-start2));
  }

  public dragging$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMouseDown(event: MouseEvent){
    if(this.disabled) return true;

    if(event.target == this.inputLeft.nativeElement
      || event.target == this.inputRight.nativeElement) {
        (event.target as any).focus();
        this.movingSlider$.next(event.target == this.inputLeft.nativeElement ? 0 : 1);
        this.typing.next(true);
        return true;
    }
    this.mouseClickEvent$.next(event);

    this.onMouseMove(event);
    document.addEventListener("mousemove", this.onMouseMove);
    document.addEventListener("touchmove", this.onMouseMove);

    this.dragging$.next(true);

    return true;
  }

  resizeInput(obj) {
    if (!obj.savesize) obj.savesize=obj.size;
    obj.size=Math.max(obj.savesize,obj.value.length + 1);
  }

  @HostListener('window:mouseup', ["$event"])
  @HostListener('window:touchend', ["$event"])
  @HostListener('window:touchcancel', ["$event"])
  onMouseUp(event) {
    this.dragging$.next(false);
    this.mouseUpEvent$.next(event);
    document.removeEventListener("mousemove", this.onMouseMove);
    document.removeEventListener("touchmove", this.onMouseMove);
  }

  @HostListener('window:resize')
  onResize() {
    this.calculateBounds();
  }

  private isMouseOver$ = new BehaviorSubject<boolean>(false);

  @HostListener('mouseenter')
  mousteEnter() {
    this.calculateBounds();
    this.isMouseOver$.next(true);
  }

  @HostListener('mouseleave')
  mousteLeave() {
    this.isMouseOver$.next(false);
  }

  private onMouseMove = this.mouseEvent$.next.bind(this.mouseEvent$);

  private points = new BehaviorSubject<{x: number, y: number}[]>([]);

  public showGraph$: Observable<boolean> = this.points.pipe(
    switchMap(p =>  this.isMouseOver$.pipe(map(a => [a ,p && p.length > 0]))),
    switchMap(([a, p]) => this.dragging$.pipe(map(b => p && (a || b)))),
    distinctUntilChanged(),);

  public showLabels$: Observable<boolean> = this.typing.pipe(startWith(false),
      switchMap( a => this.isMouseOver$.pipe(map(b => a || b))),
      switchMap( a => this.dragging$.pipe(map(b => !this.disabled && (a || b)))),);


  public showGraphContainer$: Observable<boolean> = this.showGraph$.pipe(
    filter(x => !x),
    delay(200),
    merge(this.showGraph$.pipe(filter(x => x))),);

  private drawGraph(leftRange, rightRange, width, height, pts: {x: number, y:number}[]) {
    const dpi = window.devicePixelRatio;

    // Clear background
    // this.ctx.fillStyle="rgba(0,0,0,0)";
    // this.ctx.fillRect(0, 0, width, height);
    this.ctx.clearRect(0, 0, width, height);

    this.ctx.beginPath();

    this.ctx.moveTo(0, height + 50);

    if(pts.length > 0) {
      // this.ctx.moveTo(pts[0].x, height - pts[0].y);
      for(let i=0; i < pts.length; i++){
        const pt=pts[i];
        this.ctx.lineTo(pt.x, height - pt.y);
      }
    }

    this.ctx.lineTo(width, height + 50);

    this.ctx.save();

    // Create a clipping area from the path
    // All new drawing will be contained inside
    // the clipping area
    this.ctx.clip();

    this.ctx.fillStyle='#F8F9FD';
    this.ctx.fillRect(0, 0, width, height);

    this.ctx.fillStyle='#fcddd6';
    this.ctx.fillRect(leftRange, 0, rightRange-leftRange,height);

    this.ctx.restore();

    this.ctx.strokeStyle="#E7E8EC";
    this.ctx.lineWidth = dpi;
    this.ctx.stroke();


    this.ctx.beginPath();
    // Draw left line
    this.ctx.moveTo(leftRange + dpi, 0);
    this.ctx.lineTo(leftRange + dpi, height);

    // Draw right line
    this.ctx.moveTo(rightRange - dpi, 0);
    this.ctx.lineTo(rightRange - dpi, height);

    this.ctx.strokeStyle="#FD603D";
    this.ctx.lineWidth=2 * dpi;
    this.ctx.stroke();

    this.ctx.save();
    this.ctx.beginPath();
    this.ctx.rect(leftRange, 0, rightRange-leftRange,height);
    this.ctx.clip();

    this.ctx.beginPath();
    if(pts.length > 0) {
      this.ctx.moveTo(pts[0].x, height - pts[0].y);
      for(let i=0;i<pts.length;i++){
        const pt=pts[i];
        this.ctx.lineTo(pt.x, height - pt.y);
      }
    }

    this.ctx.strokeStyle="#FD603D";
    this.ctx.lineWidth = dpi;
    this.ctx.stroke();

    this.ctx.restore();
  }

  private calculateBounds() {
    this.containerOffsets$.next({
      l: this.rangeContainer.nativeElement.getBoundingClientRect().left + window.pageXOffset,
      r: this.rangeContainer.nativeElement.getBoundingClientRect().left + window.pageXOffset + this.rangeContainer.nativeElement.clientWidth,
      w: this.rangeContainer.nativeElement.clientWidth,
      h: this.canvas.nativeElement.clientHeight
    });
  }

  public resetDirty() {
    this.isDirty.next(false);
  }

  ngOnInit(): void {}
}
