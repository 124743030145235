<st-modal #modal="modal" [showInstructions]="false" [showOnlyBody]="true" [scrollable]="false">
    <div modal-body class="content">
        <div class="title">{{ title }}</div>
        <div class="text">{{ subtitle }}</div>
        <span class="close-icon" (click)="close()">
            <st-icon icon="cross-v2"></st-icon>
        </span>

        <div class="products-wrapper">
        <div class="products-wrapper__header">
            <div class="campaign-manager-search-wrapper">
            <input class="campaign-manager-search-wrapper__input" type="text" placeholder="Search Products" [(ngModel)]="searchProductString" (ngModelChange)="searchProducts()">
            <st-icon class="campaign-manager-search-wrapper__icon" icon="search_blue"></st-icon>
            </div>
        </div>

        <st-product-list-table
            [products]="products"
            [productVariations]="productVariations"
            [loadingProducts]="loadingProducts"
            [loadingProductVariations]="loadingProductVariations"
            [perPageOptions]="perPageOptions"
            [preSelectedOption]="preSelectedOption"
            [variationPerPage]="variationPerPage"
            [variationCurrentPage]="variationCurrentPage"
            [variationTotal]="variationTotal"
            [variationPagesArray]="variationPagesArray"
            [variationFrom]="variationFrom"
            [variationTo]="variationTo"
            [variationLastPage]="variationLastPage"
            [singleVariationSelection]="singleVariationSelection"
            [showVariations]="showVariations"
            [showNoneOption]="showNoneOption"
            [selectedProductChildren]="selectedProductChildren"
            [noneText]="noneText"
            [selectedProductAsin]="selectedProduct?.product?.asin || selectedProduct"
            (onSortBy)="sortBy($event)">
        </st-product-list-table>

        <div class="campaign-manager__footer">
            <div class="campaign-manager__footer-column"></div>
            <div class="campaign-manager__footer-column">
            <st-pagination
                [currentPage]="currentPage"
                [pages]="pagesArray"
                (goTofirstPage)="goToFirstPage()"
                (goToLastPage)="goToLastPage()"
                (goToNextPage)="nextPage()"
                (goToPreviousPage)="previousPage()"
                (changePage)="selectPerPage($event)"></st-pagination>
            </div>
            <div class="campaign-manager__footer-column campaign-manager__footer-column--info">
            <st-pagination-info
                [text]="currentDisplayedProducts"
                [disabled]="loadingProducts"
                [options]="perPageOptions"
                (onPageChange)="selectPerPage($event)"
                [preSelectedOption]="preSelectedOption"></st-pagination-info>
            </div>
        </div>
        </div>

        <div class="products__footer">
        <st-button-v2 color="v2_red_empty" (clicked)="close()" label="CANCEL"></st-button-v2>
        <st-button-v2 color="v2_red" (clicked)="confirm()" label="CONFIRM SELECTION" [disabled]="(!showNoneOption && !selectedProduct) || (showVariations && !selectedProduct.children?.length)"></st-button-v2>
        </div>
    </div>
</st-modal>
  