import { PipeTransform, Injectable, Pipe } from '@angular/core';
import { IProductManagerKeyword } from '../data/product-manager/product-manager.models';

@Pipe({
  name: 'stKeywords'
})
@Injectable()
export class KeywordsPipe implements PipeTransform {
  transform(items: IProductManagerKeyword[], filters: string[]): IProductManagerKeyword[] {
    if (!items) {
      return [];
    }

    return items.filter((item: IProductManagerKeyword) => {
      return filters.includes(item.priority.toLowerCase());
    });
  }
}
