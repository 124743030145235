import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from "@angular/core";
import { DialogService } from "@app/shared/components/dialog/dialog.service";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { ISavedFilter } from "../advanced-filters/advanced-filters-modal/advanced-filters-modal.component";

@Component({
  selector: 'st-saved-views',
  templateUrl: 'saved-views.component.html',
  styleUrls: ['saved-views.component.scss']
})
export class SavedViewsComponent {
  @Input()
  savedViews: ISavedFilter[];

  _appliedView: ISavedFilter = null;
  @Input()
  set appliedView(view: null|ISavedFilter){
    this._appliedView = view;
  }

  @Output() viewApplied: EventEmitter<null | ISavedFilter> = new EventEmitter();
  @Output() viewDeleted: EventEmitter<ISavedFilter> = new EventEmitter();
  @Output() opened: EventEmitter<void> = new EventEmitter();

  savedViewsOpened = false;

  constructor(private cd: ChangeDetectorRef, private dialogService: DialogService, private i18n: I18n) { }

  toggleSavedViewsModal() {
    this.savedViewsOpened = !this.savedViewsOpened;

    if (this.savedViewsOpened) {
      this.opened.emit();
    }
  }

  close() {
    this.savedViewsOpened = false;
  }

  applyView(view: null | ISavedFilter) {
    this._appliedView = view;
    this.viewApplied.emit(view);

    this.savedViewsOpened = false;

    this.cd.detectChanges();
  }

  deleteView(view: ISavedFilter) {
    this.dialogService.confirm(this.i18n('Are you sure you want to delete this view?'),
      '',
      this.i18n('Yes, Delete'),
      this.i18n('Cancel'),
      true)
      .then((ok) => {
        if (ok) {
          if (this._appliedView === view) {
            this.applyView(null);
          }

          this.viewDeleted.emit(view);
        }
      });
  }
}
