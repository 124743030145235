import {BaseModel, Collection} from '@app/shared/data/base.models';
import {environment} from '@env/environment';

/**
 * uses ISO 639-2 for @name
 * https://en.wikipedia.org/wiki/List_of_ISO_639-2_codes
 * uses ISO 639-1 for @code
 * https://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
 */
export class Localization extends BaseModel {
  name: string;
  code: string;

  public static getAll(): Collection<Localization> {
    const locals = new Collection<Localization>();
    locals.push(new Localization({
      name : 'ENG',
      code : 'en'
    }));
    locals.push(new Localization({
      name : 'CHI',
      code : 'zh'
    }));
    // locals.push(new Localization({
    //   name : 'SLV',
    //   code : 'sl'
    // }));
    return locals;
  }

  public static get(code: string): Localization {
    const filtered = this.getAll().filter((localization: Localization) => localization.code === code);
    return filtered.length > 0 ? filtered.first() : null;
  }

  public static getDefault(): string {
    return environment.LOCALIZATION;
  }

  public static validateOrGetDefault(local: string): string {
    return Localization.isSupported(local) ? local : Localization.getDefault();
  }

  public static isSupported(local: string): boolean {
    return Localization.get(local) !== null;
  }

  public static isTranslated(code: string): boolean {
    return code !== 'en' ? this.isSupported(code) : false;
  }
}
