import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';

@Injectable()
export class AsinCompareActions {
  static readonly EVENTS = {
    asin_compare_finished: 'my_asin_compare_finished',
  };

  static readonly TYPES = {
    COMPARE                   : '[ASIN-COMPARE]COMPARE',
    COMPARE_CHILDREN          : '[ASIN-COMPARE]COMPARE_CHILDREN',
    COMPARE_SUCCEEDED         : '[ASIN-COMPARE]COMPARE_SUCCEEDED',
    COMPARE_FAILED            : '[ASIN-COMPARE]COMPARE_FAILED',
    COMPARE_CANCEL            : '[ASIN-COMPARE]COMPARE_CANCEL',
    COMPARE_REFRESH           : '[ASIN-COMPARE]COMPARE_REFRESH',
  };

  compare(asin: string, groupId: string, backgroundLoading?: boolean): StAction {
    return {
      type: AsinCompareActions.TYPES.COMPARE,
      data: {
        asin,
        groupId,
        backgroundLoading
      }
    };
  }

  compareRefresh(backgroundLoading = true): StAction {
    return {
      type: AsinCompareActions.TYPES.COMPARE_REFRESH,
      data: {
        backgroundLoading
      }
    };
  }

  compareFailed(error): StAction {
    return {
      type: AsinCompareActions.TYPES.COMPARE_FAILED,
      data: {
        error
      }
    };
  }

  compareSucceeded(results): StAction {
    return {
      type: AsinCompareActions.TYPES.COMPARE_SUCCEEDED,
      data: {
        results
      }
    };
  }

  cancelSearch(): StAction {
    return {
      type: AsinCompareActions.TYPES.COMPARE_CANCEL,
      data: {}
    };
  }

  compareChildren(children): StAction {
    return {
      type: AsinCompareActions.TYPES.COMPARE_CHILDREN,
      data: {children}
    };
  }
}
