import { BaseModel } from '@app/shared/data/base.models';

export class Coupon extends BaseModel {
  code: string;
  feature: string;
  plan?: string;
  percent_off?: number;
  amount_off?: number;
  description: string;
  redemption_count?: number;
  valid: boolean;
  valid_until: string;
  duration: string;
  duration_in_months?: number;
  duration_text: string;
  promotion?: string;
  plans?: string[];

  public constructor(data = {}) {
    super(data);
    if (!this.feature) {
      this.feature = 'Keywords';
    }
  }
}
