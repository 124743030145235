import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { LsTaskActions } from "@app/shared/data/tasks/ls-task/ls-task.actions";
import { LsTask } from "@app/shared/data/tasks/ls-task/ls-task.models";
import {KeywordGeneratedActions} from "@app/shared/data/keyword-generated/keyword-generated.actions";

const INITIAL_STATE: AppState = {
  data: []
};

export function lsTaskReducers(state: AppState = INITIAL_STATE, action: StAction) {
  if(action.type == LsTaskActions.TYPES.LIST_LOADED) {
    return {
      ...state,
      data: action.data.list.reduce((acc, task) => {
        return {
          ...acc,
          [task.id]: task
        }
      }, state['data'])
    }
  }

  if(action.type == LsTaskActions.TYPES.REMOVE) {
    let data = {...state['data']};
    delete data[action.data.id];

    return{
      ...state,
      data: data
    }
  }

  if(action.type == KeywordGeneratedActions._events.suggestions_finished) {
    const group_id = action.data.group_id;

    return {
        ...state,
        data: {
            ...state['data'],
            [group_id]: {...state['data'][group_id], finished_at: Date.now()}
        }
    }
  }


  return state;
}
