import { NgRedux } from '@angular-redux/store';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';
import { ApiTokenActions } from '@app/shared/data/api-token/api-token.actions';
import { ApiToken } from '@app/shared/data/api-token/api-token.models';
import { AppState } from '@app/shared/data/app-state.model';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { ClipboardService } from 'ngx-clipboard';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'st-api-keys-warning-modal',
  templateUrl: './api-keys-warning-modal.component.html',
  styleUrls: ['./api-keys-warning-modal.component.scss'],
})
export class ApiKeysWarningModalComponent implements OnInit, OnDestroy {
  apiToken: ApiToken;
  loadingToken: boolean;

  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;

  private readonly destroy$: Subject<void> = new Subject<void>();

  constructor(
    private _clipboardService: ClipboardService,
    private toastService: ToastService,
    private i18n: I18n,
    private ngRedux: NgRedux<AppState>,
  ) { }

  ngOnInit() {
    this.ngRedux.select(['tokens', 'currentToken'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((token: ApiToken) => {
        this.apiToken = token;
      });

    this.ngRedux.select(['tokens', 'loadingCurrentToken'])
      .pipe(takeUntil(this.destroy$))
      .subscribe((loading: boolean) => {
        this.loadingToken = loading;
      });
  }

  ngOnDestroy() {
    this.ngRedux.dispatch(ApiTokenActions.clearSingleToken());
    this.destroy$.next();
    this.destroy$.complete();
  }

  onCopy() {
    this._clipboardService.copyFromContent(this.apiToken.value);
    this.toastService.info(
      this.i18n('Token Copied'),
      this.i18n('Token: {{hash}}', { hash: this.apiToken.value })
    );

    this.modal.close();
  }
}
