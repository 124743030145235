import { Component } from '@angular/core';
import { ActiveModal } from '@app/shared/components/modals/modal.service';

@Component({
  templateUrl: './product-manager-listing-manager-published-modal.component.html',
  styleUrls: ['./product-manager-listing-manager-published-modal.component.scss'],
})
export class ProductManagerListingManagerPublishedModalComponent {

  constructor(private activeModal: ActiveModal) {}

  confirmed() {
    this.activeModal.close();
  }
}
