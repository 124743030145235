<div class="container-title">
  <div class="container-title__text" i18n>Upgrade Plan</div>
  <div class="container-title__links">
    <a href="{{fullBillingUrl}}" target="_blank">
      <span i18n>See our cancellation policy</span>
    </a>
    <a href="{{fullPricingUrl}}" target="_blank">
      <span i18n>See full pricing</span>
      <st-icon icon="link"></st-icon>
    </a>
  </div>
</div>
<div class="plan-type" [class.plan-type--loading]="loading">
  <div class="empty"></div>
  <div class="gradient-wrapper">
    <div class="toggle-wrapper">
      <div class="label" i18n [class.selected]="selectedPlanType === 'monthly'" (click)="selectPlanType(planTypeEnum.MONTHLY)">MONTHLY</div>
      <div class="label" i18n [class.selected]="selectedPlanType === 'annual'" (click)="selectPlanType(planTypeEnum.ANNUAL)">YEARLY</div>
      <div class="toggle" [class.right]="selectedPlanType === 'annual'"></div>
    </div>
  </div>
  <div class="save__wrapper">
    <div class="save__yearly-plans" i18n>Save up to 20% by subscribing to yearly plans</div>
  </div>
</div>
<span class="loading" *ngIf="loading" i18n>Loading...</span>
<div class="plans-wrapper" [class.plans-wrapper--loading]="loading">
  <div class="plans-dots">
    <div class="plans-dots__dot" [class.plans-dots__dot--current]="i === selectedIndex" *ngFor="let dot of plans; let i = index"></div>
  </div>
  <st-icon icon="carousel-arrow" (click)="navigatePlans(directions.BACK)" class="plans-navigation plans-navigation--prev"></st-icon>
  <st-icon icon="carousel-arrow" (click)="navigatePlans(directions.FORWARD)" class="plans-navigation plans-navigation--next"></st-icon>
  <div class="plans-inner-wrapper" #plansInnerWrapper>
    <div class="plans" #wrapper [ngStyle]="{ 'grid-template-columns': 'repeat(' + plans?.length + ', 1fr)'}">
      <ng-container *ngIf="!loading">
        <div
          class="plan"
          [class.plan--selected]="plan.id === selected?.id"
          [class.plan--free]="isFreePlan(plan)"
          (click)="onSelect(plan)"
          [style.width]="planWidth"
          *ngFor="let plan of plans">
          <st-icon class="plan--selected-icon" *ngIf="plan.id === activeSubscription?.plan?.id" width="30" height="30" icon="st-check-new"></st-icon>
          <div class="plan-name">{{plan.name}}</div>
          <div class="plan-separator"></div>
          <div class="plan-description" *ngIf="isFreePlan(plan)" i18n>Free plan is only available as a one-time monthly offer</div>
          <div class="plan-price">
            <st-plan-price [plan]="plan" [coupon]="selected?.canRedeemCouponCode ? coupon : null"></st-plan-price>
          </div>
          <div class="plan-old-price" *ngIf="plan.annual && isCouponCodeApplied(selected)">
            <st-plan-price [plan]="plan"></st-plan-price>
          </div>
          <div class="coupon-applied" *ngIf="isCouponCodeApplied(plan)">
            <st-icon icon="icon-coupon"></st-icon>
            <div class="coupon-applied__text" i18n>Coupon Applied</div>
          </div>
          <div class="plan-fees">
            <div class="plan-fees__title" i18n>Wallet Payout Fees</div>
            <div class="plan-fees__value">{{plan.walletPayoutFee}}%</div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #loadingPlan>
  <div class="plan {{plan}}" *ngFor="let plan of [1,2,3,4]">
    <h3>
      <st-loader></st-loader>
    </h3>
    <div>
      <st-loader></st-loader>
    </div>
    <br>
    <div>
      <st-loader></st-loader>
    </div>
    <br>
    <div>
      <st-loader></st-loader>
    </div>
    <br>
    <div class="price">
      <span class='sign-number'>
        <st-loader></st-loader>
      </span>
    </div>
    <a class="button" i18n>Select</a>
  </div>
</ng-template>
