import { NgRedux } from "@angular-redux/store";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild, ViewRef } from "@angular/core";
import { PaymentService } from "@app/core/services/payment.service";
import { DialogService } from "@app/shared/components/dialog/dialog.service";
import { ModalComponent } from "@app/shared/components/modals/modal/modal.component";
import { AppState } from "@app/shared/data/app-state.model";
import { Collection } from "@app/shared/data/base.models";
import { Coupon } from "@app/shared/data/coupon/coupon.models";
import { Plan } from "@app/shared/data/plan/plan.models";
import { SubscriptionActions } from "@app/shared/data/subscription/subscription.actions";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { Subject } from "rxjs";
import { filter, first, takeUntil } from "rxjs/operators";

@Component({
  selector: 'st-upgrade-subscription-modal-component',
  templateUrl: './upgrade-subscription-modal.component.html',
  styleUrls: ['./upgrade-subscription-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UpgradeSubscriptionModalComponent implements OnInit, OnDestroy {
  coupon: Coupon = new Coupon();
  _selected = null;
  initialPlans: Collection<Plan>;
  plan: Plan;
  updateDisabled = false;
  data?: any;
  updatingPlan: boolean;

  couponCode: string;
  validatingCouponCode: boolean;
  couponErrorMessage: string;

  @ViewChild('modal', { static: true }) modal: ModalComponent;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private cd: ChangeDetectorRef,
    private ngRedux: NgRedux<AppState>,
    private subscriptionActions: SubscriptionActions,
    private dialogService: DialogService,
    private i18n: I18n,
    private paymentService: PaymentService,
  ) { }

  ngOnInit() {
    if (this.data) {
      this.coupon = this.data.coupon;
      this.couponCode = this.data.coupon && this.data.coupon.code;
      this.plan = this.data.plan;
    }
    this.verifyCode();
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  save() {
    //noinspection TypeScriptValidateTypes
    this.updatingPlan = true;
    this.ngRedux.dispatch(this.subscriptionActions.create(this.plan, this.coupon));
    this.ngRedux.select(['subscriptions', '_error'])
    .pipe(
      filter(e => !!e),
      first(),
      takeUntil(this.destroy$)
    ).subscribe(
      () => {
        this.dialogService.info(
          this.i18n('Sorry.'),
          this.i18n('Payment failed.'),
          null, true
        );

        this.updatingPlan = false;

        if (this.canDetectChanges) {
          this.cd.detectChanges();
        }
      }
    );
  }

  verifyCode() {

    if (!Boolean(this.couponCode)) {
      return;
    }

    const coupon: any = {
      code: this.couponCode,
      valid: false,
      feature: 'Keywords',
      plan: this.plan.remote_id,
    };

    this.validatingCouponCode = true;

    this.paymentService.validateCoupon(coupon).then(
      (coupon: Coupon) => {
        this.coupon = coupon;
        this.couponErrorMessage = null;
        this.validatingCouponCode = false;

        if (this.canDetectChanges) {
          this.cd.detectChanges();
        }
      },
      (response) => {
        this.couponErrorMessage = response.message;
        this.validatingCouponCode = false;

        if (this.canDetectChanges) {
          this.cd.detectChanges();
        }
      }
    );
  }

  cancel() {
    this.modal.close();
  }

  get canRedeemCouponCode(): boolean {
    if (!this.plan || !this.plan.permissions) {
      return false;
    }

    return this.plan.permissions.map((permission) => permission.slug).includes('plan-coupon.redeem');
  }

  get getTotalPrice(): number {
    if (!this.coupon || !this.coupon.valid) {
      return this.plan.display_price;
    }

    return this.plan.display_price - this.getCouponAmountOff;
  }

  get getCouponAmountOff(): number {
    if (!this.coupon || !this.coupon.valid) {
      return 0;
    }

    return this.coupon.percent_off ? this.plan.display_price * ( this.coupon.percent_off * 0.01 ) : this.coupon.amount_off;
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }
}
