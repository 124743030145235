import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BaseAPI } from '@app/shared/data/base.api';
import { Observable } from 'rxjs';

export class DataCollectionApi extends BaseAPI {
  constructor(protected baseUrl: string, protected http: HttpClient) {
    super();
  }

  public getSSInfo(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/admin/data-collection/' + this.baseUrl));
  }

  public start(): Observable<any> {
    return this.http.post(this.constructFullUrl('api/admin/data-collection/' + this.baseUrl + '/start'), {});
  }

  public update(data): Observable<any> {
    return this.http.put(this.constructFullUrl('api/admin/data-collection/' + this.baseUrl), data);
  }

  public stop(): Observable<any> {
    return this.http.post(this.constructFullUrl('api/admin/data-collection/' + this.baseUrl + '/stop'), {});
  }

  public reset(): Observable<any> {
    return this.http.post(this.constructFullUrl('api/admin/data-collection/' + this.baseUrl + '/reset'), {});
  }
}

@Injectable()
export class OrdersSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('orders-sync', http);
  }
}

@Injectable()
export class OrderItemsSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('order-items-sync', http);
  }
}

@Injectable()
export class OrderReturnsSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('order-returns-sync', http);
  }
}

@Injectable()
export class FinancialEventsSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('financial-events-sync', http);
  }
}

@Injectable()
export class BrandAnalyticsSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('ba-sync', http);
  }
}

@Injectable()
export class LastSearchSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('last-search-sync', http);
  }
}

@Injectable()
export class ProductSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('product-sync', http);
  }
}

@Injectable()
export class ProductStockSyncDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('product-stock-sync', http);
  }
}

@Injectable()
export class KeywordRankingDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('keywords-ranking-sync', http);
  }
}

@Injectable()
export class NewKeywordRankingDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('new-keywords-ranking-sync', http);
  }
}

@Injectable()
export class BaKeywordRankingDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('brand-analytics-keywords-ranking-sync', http);
  }
}

@Injectable()
export class SalesViralLaunchDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('sales-vl-sync', http);
  }
}

@Injectable()
export class SalesJungleScoutDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('sales-js-sync', http);
  }
}

@Injectable()
export class SalesJungleScoutByCategoryDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('sales-js-by-category-sync', http);
  }
}

@Injectable()
export class FeedbacksDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('feedbacks-sync', http);
  }
}

@Injectable()
export class ReviewCountStatsDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('review-count-stats-sync', http);
  }
}

@Injectable()
export class SocilitationActionsDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('solicitation-actions-sync', http);
  }
}

@Injectable()
export class SellerSpriteKeywordHistoryDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('kw-history-ss-sync', http);
  }
}

@Injectable()
export class KWDHPopulateDataCollectionApi extends DataCollectionApi {
  constructor(protected http: HttpClient) {
    super('kdwh-populate', http);
  }
}
