import { Directive, ElementRef, Host, HostListener, OnDestroy } from '@angular/core';
import { DropdownDirective } from "@app/shared/layout/dropdown/dropdown.directive";
import { KeyCode } from "@app/shared/layout/dropdown/keycodes.enum";

@Directive({
  selector: '[stDropdownOpen]'
})
export class DropdownOpenDirective implements OnDestroy {

  private closeEventListener: (event: Event) => void;

  constructor(@Host() public dropdown: DropdownDirective,
              public elementRef: ElementRef) {
    this.closeEventListener = (event: MouseEvent) => {
      this.closeIfInClosableZone(event);
    };
    this.dropdown.dropdownOpen = this;
  }

  open() {
    if (this.dropdown.isOpened())
      return;

    this.dropdown.open();

    document.addEventListener("click", this.closeEventListener, true);
  }

  close() {
    if (!this.dropdown.isOpened())
      return;

    this.dropdown.close();
    document.removeEventListener("click", this.closeEventListener, true);
  }

  @HostListener("click")
  toggleDropdown() {
    if (this.dropdown.isOpened()) {
      this.close();
    } else {
      this.open();
    }
  }

  @HostListener("keydown", ["$event"])
  dropdownKeyDown(event: KeyboardEvent) {
    switch (event.which) {
      case KeyCode.ArrowDown:
        this.open();
        break;
      case KeyCode.ArrowUp:
      case KeyCode.Esc:
        this.close();
        break;
      case KeyCode.Enter:
        this.open();
        break;
    }
  }

  ngOnDestroy() {
    document.removeEventListener("click", this.closeEventListener, true);
  }

  private closeIfInClosableZone(event: Event) {
    if (event.target !== this.elementRef.nativeElement
      && !this.elementRef.nativeElement.contains(event.target)
      && this.dropdown.isInClosableZone(<HTMLElement> event.target)) {

      this.dropdown.close();
      document.removeEventListener("click", this.closeEventListener, true);
    }
  }

}
