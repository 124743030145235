<div (mouseleave)="!isSidebarExpanded && selectItem(null)">
  <div class="sidebar-wrapper" [@showHide]="isSidebarExpanded" [class.collapse]="!isSidebarExpanded" stSidemenuOffClick
    (clickedOutsideMenuItem)="selectedItem = null" (scroll)="setSubmenuPosition()">
    <div class="section">
      <div class="row dashboard top-menu-item" (mouseenter)="!isSidebarExpanded && selectItem(null)">
        <a #dashboard="routerLinkActive" routerLinkActive="active" [routerLink]="['/products']" (click)="reset()"
          class="long-name">
          <st-icon [icon]="'c6_logo_white'" class="icon-s left logo-img" [width]="20" [height]="20"></st-icon>
          <span class="logo-text">SellerTools</span>
        </a>
      </div>

      <a #productManager="routerLinkActive" routerLinkActive="active" [routerLink]="['/product-manager']"
        class="row top-menu-item" (click)="reset()" (mouseenter)="!isSidebarExpanded && selectItem(null)" i18n-stTooltip
        [stTooltip]="!isSidebarExpanded && 'Product Manager'" position="right">
        <span class="long-name">
          <st-icon [icon]="productManager.isActive ? 'product_manager_active' : 'product_manager_new'" [width]="18"
            [height]="20" class="icon-xs left"></st-icon>
          <span i18n>Product Manager</span>
        </span>
      </a>
      <a #productManager="routerLinkActive" routerLinkActive="active" *ngIf="hasNewR2A" [routerLink]="['/r2a-v2']"
        class="row top-menu-item" (click)="reset()">
        <span class="long-name" i18n-stTooltip [stTooltip]="!isSidebarExpanded && 'R2A V2'" position="right">
          <st-icon [icon]="productManager.isActive ? 'competition_active' : 'competition'" [width]="18" [height]="19"
            class="icon-xs left"></st-icon>
          <span i18n>R2A</span>
          <span class="new-badge" i18n>NEW</span>
        </span>
      </a>

      <div class="row top-menu-item" *ngIf="(competitionResearchEnabled$ | async) && !hasNewR2A"
        (click)="isSidebarExpanded && selectItem(itemTypes.COMPETITION)"
        (mouseenter)="!isSidebarExpanded && selectItem(itemTypes.COMPETITION)"
        (mouseleave)="!isSidebarExpanded && selectItem(itemTypes.COMPETITION)"
        [class.active]="activeRoute === 'COMPETITION'" [class.selected]="selectedItem === 'COMPETITION'">
        <span class="long-name">
          <st-icon
            [icon]="selectedItem === 'COMPETITION' || activeRoute === 'COMPETITION' ? 'competition_active' : 'competition'"
            [width]="18" [height]="19" class="icon-xs left"></st-icon>
          <span class="expand-group">
            <span i18n>Competition Research</span>
            <st-icon icon="chevron-right" class="arrow-icon icon-xs right"></st-icon>
          </span>
        </span>
      </div>

      <div class="row top-menu-item" (click)="isSidebarExpanded && selectItem(itemTypes.AUTOMATION)"
        (mouseenter)="!isSidebarExpanded && selectItem(itemTypes.AUTOMATION)"
        [class.active]="activeRoute === 'AUTOMATION'" [class.selected]="selectedItem === 'AUTOMATION'">
        <span class="long-name">
          <st-icon
            [icon]="selectedItem === 'AUTOMATION' || activeRoute === 'AUTOMATION' ? 'automation_active' : 'automation'"
            [width]="18" [height]="18" class="icon-xs left"></st-icon>
          <span class="expand-group">
            <span i18n>Automation</span>
            <st-icon icon="chevron-right" class="arrow-icon icon-xs right"></st-icon>
          </span>
        </span>
      </div>

      <a #customerList="routerLinkActive" routerLinkActive="active" [routerLink]="['/customers-list']"
        class="row top-menu-item" *ngIf="customerListEnabled$ | async" (click)="reset()"
        (mouseenter)="!isSidebarExpanded && selectItem(null)" i18n-stTooltip
        [stTooltip]="!isSidebarExpanded && 'Customer List'" position="right">
        <span class="long-name">
          <st-icon [icon]="customerList.isActive ? 'customer_list_active' : 'customer_list_new'" [width]="18"
            [height]="19" class="icon-xs left"></st-icon>
          <span i18n>Customer List</span>
          <span class="new-badge" i18n>BETA</span>
        </span>
      </a>

      <a #campaignManager="routerLinkActive" routerLinkActive="active" [routerLink]="['/campaign-manager']"
        *ngIf="campaignManagerEnabled$ | async" class="row top-menu-item" (click)="reset()"
        (mouseenter)="!isSidebarExpanded && selectItem(null)" i18n-stTooltip
        [stTooltip]="!isSidebarExpanded && 'Campaign Manager'" position="right">
        <span class="long-name">
          <st-icon [icon]="campaignManager.isActive ? 'campaign_manager_active' : 'campaign_manager'" [width]="18"
            [height]="20" class="icon-xs left"></st-icon>
          <span i18n>Campaign Manager</span>
          <span class="new-badge" i18n>BETA</span>
        </span>
      </a>

      <div class="row top-menu-item" *ngIf="enableWallet$ | async"
        (click)="isSidebarExpanded && selectItem(itemTypes.WALLET)"
        (mouseenter)="!isSidebarExpanded && selectItem(itemTypes.WALLET)" [class.active]="activeRoute === 'WALLET'"
        [class.selected]="selectedItem === 'WALLET'">
        <span class="long-name">
          <st-icon
            [icon]="selectedItem === itemTypes.WALLET || activeRoute === itemTypes.WALLET ? 'wallet_active' : 'wallet_new'"
            [width]="18" [height]="16" class="icon-xs left"></st-icon>
          <span class="expand-group">
            <span i18n>Wallet</span>
            <st-icon icon="chevron-right" class="arrow-icon icon-xs right"></st-icon>
          </span>
        </span>
      </div>

      <a #alerts="routerLinkActive" routerLinkActive="active" [routerLink]="['/alerts/amz-alert']"
        *ngIf="enableAlerts$ | async" class="row top-menu-item" (click)="reset()"
        (mouseenter)="!isSidebarExpanded && selectItem(null)" i18n-stTooltip
        [stTooltip]="!isSidebarExpanded && 'Alerts'" position="right">
        <span class="long-name">
          <st-icon [icon]="alerts.isActive ? 'alerts_active' : 'alerts'" [width]="18" [height]="18"
            class="icon-xs left"></st-icon>
          <span i18n>Alerts</span>
        </span>
      </a>

      <a #keywordInsights="routerLinkActive" routerLinkActive="active" [routerLink]="['/keyword-insights']"
        *ngIf="keywordInsightsAccess$ | async" class="row top-menu-item" (click)="reset()"
        (mouseenter)="!isSidebarExpanded && selectItem(null)" i18n-stTooltip
        [stTooltip]="!isSidebarExpanded && 'Keyword Insights'" position="right">
        <span class="long-name">
          <st-icon [icon]="keywordInsights.isActive ? 'keyword_insights_active' : 'keyword_insights'" [width]="20"
            [height]="15" class="icon-xs left keyword-icon"></st-icon>
          <span i18n>Keyword Insights</span>
        </span>
      </a>
    </div>

    <div class="section">
      <div class="row top-menu-item" (click)="isSidebarExpanded && selectItem(itemTypes.NOTIFICATIONS)"
        (mouseenter)="!isSidebarExpanded && selectItem(itemTypes.NOTIFICATIONS)"
        [class.selected]="selectedItem === 'NOTIFICATIONS'">
        <span class="long-name">
          <st-icon [icon]="selectedItem === 'NOTIFICATIONS' ? 'notifications_active' : 'notifications'" [width]="18"
            [height]="17" class="icon-xs left"></st-icon>
          <span class="expand-group">
            <span i18n>Notifications</span>
            <st-icon icon="chevron-right" class="arrow-icon icon-xs right"></st-icon>
          </span>
        </span>
      </div>

      <div *ngIf="enableCredentials" class="row credential top-menu-item" (click)="onCredentialsClick()"
        (mouseenter)="!isSidebarExpanded && selectedItem !== 'CREDENTIALS' && selectItem(itemTypes.CREDENTIALS)"
        [class.active]="activeRoute === 'CREDENTIALS'" [class.selected]="selectedItem === 'CREDENTIALS'">
        <span class="long-name" i18n-stTooltip
          [stTooltip]="!isSidebarExpanded && !activeCredential?.id && 'Add Credentials'" position="right">
          <st-icon class="icon-xs left flag" [width]="16" [height]="16"
            [icon]="activeCredential?.marketplace?.toLowerCase().split(' ')[1]"></st-icon>
          <span class="expand-group">
            <span i18n>{{activeCredential?.name || 'Add Credentials'}}</span>
            <st-icon *ngIf="activeCredential?.id" icon="chevron-right" class="arrow-icon icon-xs right"></st-icon>
          </span>
        </span>
      </div>

      <a #knowledgeBase="routerLinkActive" routerLinkActive="active" target="_blank" href="https://help.seller.tools/en"
        class="row top-menu-item" (click)="reset()" (mouseenter)="!isSidebarExpanded && selectItem(null)" i18n-stTooltip
        [stTooltip]="!isSidebarExpanded && 'Knowledge Base'" position="right">
        <span class="long-name">
          <st-icon [icon]="knowledgeBase.isActive ? 'knowledge_base_active' : 'knowledge_base'" [width]="18"
            [height]="16" class="icon-xs left"></st-icon>
          <span i18n>Knowledge Base</span>
        </span>
      </a>

      <div class="row top-menu-item" (click)="isSidebarExpanded && selectItem(itemTypes.SETTINGS)"
        (mouseenter)="!isSidebarExpanded && selectItem(itemTypes.SETTINGS)" [class.active]="activeRoute === 'SETTINGS'"
        [class.selected]="selectedItem === 'SETTINGS'">
        <span class="long-name">
          <st-icon [icon]="selectedItem === 'SETTINGS' || activeRoute === 'SETTINGS' ? 'settings_active' : 'settings'"
            [width]="18" [height]="18" class="icon-xs left"></st-icon>
          <span class="expand-group">
            <span i18n>Settings</span>
            <st-icon icon="chevron-right" class="arrow-icon icon-xs right"></st-icon>
          </span>
        </span>
      </div>
    </div>
  </div>
  <st-icon icon="toggle-navigation" [width]="20" [height]="20" class="toggle-icon" [class.collapse]="!isSidebarExpanded"
    (click)="toggleNavigation()"></st-icon>
  <st-sidebar-submenu *ngIf="selectedItem === 'COMPETITION'" class="submenu" [submenuItems]="competitionSubMenu">
  </st-sidebar-submenu>
  <st-sidebar-submenu *ngIf="selectedItem === 'AUTOMATION'" class="submenu" [submenuItems]="automationSubMenu">
  </st-sidebar-submenu>
  <st-sidebar-submenu *ngIf="selectedItem === itemTypes.WALLET" class="submenu" [submenuItems]="walletSubMenu">
  </st-sidebar-submenu>
  <st-notifications *ngIf="selectedItem === 'NOTIFICATIONS'" class="submenu top large"
    (click)="stopPropagation($event)"></st-notifications>
  <st-credentials *ngIf="selectedItem === 'CREDENTIALS' && activeCredential?.id" class="submenu top large"
    [activeCredentials]="activeCredential"></st-credentials>
  <st-sidebar-submenu *ngIf="selectedItem === 'SETTINGS'" class="submenu top large" [submenuItems]="settingsSubMenu"
    size="large"></st-sidebar-submenu>
</div>
