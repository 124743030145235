<st-modal [hideFooter]="true" #modal="modal" [showInstructions]="false" size="small">
  <div modal-header class="modal-header">
    <div>
      <h2 i18n>Add Products Manually</h2>
      <p i18n>Paste ASINs in order to add Products to this list </p>
    </div>
  </div>
  <div modal-body class="content">
    <div class="asins">
      <div class="asin" *ngFor="let asin of pastedAsins; let i = index">
        <div class="asin__title">ASIN {{i + 1}}</div>
        <input
          type="text"
          placeholder="PASTE ASIN HERE"
          i18n-placeholder
          [disabled]="addingProducts"
          class="asin__input" 
          (change)="onAsinChange($event, i)"
          [ngModel]="pastedAsins[i].value"
          [class.asin__input--error]="asin.value && isAsinNotValid(asin.value)"
          (paste)="onPaste($event, i)">
        <span class="asin__error" *ngIf="asin.value && isAsinNotValid(asin.value)" i18n>Not a valid ASIN format</span>
      </div>

      <div class="asin-add-new" [class.asin-add-new--disabled]="addingProducts" (click)="addNewAsin()">+</div>
    </div>

    <div class="footer">
      <st-button-v2 label="CANCEL" [disabled]="addingProducts" i18n-label color="v2_red_empty" (click)="close()"></st-button-v2>
      <st-button-v2 [label]="addingProducts ? 'ADDING PRODUCTS...' : 'ADD'" [disabled]="!areAllAsinsValid || addingProducts" i18n-label color="v2_red" (click)="addAsins()"></st-button-v2>
    </div>
  </div>
</st-modal>
