
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { BaseAPI } from "@app/shared/data/base.api";
import { Credential } from "@app/shared/data/credential/credential.models";

@Injectable()
export class CredentialAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public create(data): Observable<Credential> {
    return this.http.post(this.constructFullUrl('api/credentials'), data).pipe(
      map(d => new Credential(d)));
  }

  public update(credential: Credential): Observable<Credential> {
    return this.http.put(this.constructFullUrl('api/credentials/' + credential.id), credential.getData()).pipe(
      map(d => new Credential(d)));
  }

  public remove(credential: Credential): Observable<any> {
    return this.http.delete(this.constructFullUrl('api/credentials/' + credential.id));
  }

  public getCredentialLimits(credential: Credential): Observable<any> {
    return this.http.get(this.constructFullUrl(`api/credentials/${credential.id}/usages`));
  }

  public usage(credential: Credential): Observable<any> {
    return this.http.delete(this.constructFullUrl(`api/users/limits/${credential.id}`));
  }
}
