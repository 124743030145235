<div class="table-column-content" (click)="toggleKeywordDetails($event)">{{ (item.value?.sfr_rank |
  number: '1.0-0') || '-' }}</div>
<st-icon *ngIf="item.value?.sfr_rank && !item.value?.sfrGraphLoading && item.value?.sfrGraphOpened"
  class="blue-info"
  icon="blue-info" (click)="getBrandAnalytics()"
  [popper]="keywordSFRGraphData"
  popperTrigger="hover"
  popperPlacement="bottom"
  popperAppendTo="body"></st-icon>

<st-icon *ngIf="item.value?.sfr_rank && !item.value?.sfrGraphLoading && !item.value?.sfrGraphOpened"
  class="blue-info"
  icon="blue-info" (click)="getBrandAnalytics()"
  [stTooltip]="informationTooltip"></st-icon>

<div class="table-sfr-icon-loading" *ngIf="item.value?.sfrGraphLoading"></div>

<popper-content #keywordSFRGraphData>
  <div class="keyword-sfr-graph-data">
    <div class="keyword-sfr-graph-data__product" *ngFor="let product of item.value?.products">
      <div class="keyword-sfr-graph-data__product-title">{{product.title}}</div>
      <div class="keyword-sfr-graph-data__product-body">
        <div class="keyword-sfr-graph-data__product-body-item">
          <div class="keyword-sfr-graph-data__product-body-item-title" i18n>#1 Clicked ASIN</div>
          <div class="keyword-sfr-graph-data__product-body-item-value">{{product.asin}}</div>
        </div>
        <div class="keyword-sfr-graph-data__product-body-item">
          <div class="keyword-sfr-graph-data__product-body-item-title" i18n>#1 Clicked Share</div>
          <div class="keyword-sfr-graph-data__product-body-item-value">{{(product.click_share * 0.01) |
            percent:'1.2-2'}}</div>
        </div>
        <div class="keyword-sfr-graph-data__product-body-item">
          <div class="keyword-sfr-graph-data__product-body-item-title" i18n>#1 Conversion Share</div>
          <div class="keyword-sfr-graph-data__product-body-item-value">{{(product.conversion_share * 0.01) |
            percent:'1.2-2'}}</div>
        </div>
      </div>
    </div>
  </div>
</popper-content>
