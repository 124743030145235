import { Axis, Chart, Point, SeriesOptionsType, TooltipOptions } from "highcharts";
import { ChartEvent, seriesEvents, yAxisEvents } from "@app/shared/components/charts/models/chart.events";
import { LineDirective } from "@app/shared/components/charts/directives/line.directive";

export class SeriesModel {
  data = [];
  name: string;
  yAxis: number;
  id: string;
  type: string;
  cursor: string;
  visible: boolean;
  color: string;
  point: any = {events:{}};
  tooltip: TooltipOptions;
  events: {};
  step: 'left' | 'center' | 'right';

  constructor(options: any = {}) {
    Object.assign(this, options);
  }

  public setData(data: Array<number | [number, number] | [string, number]>) {
    this.data = data;
  }

  public setName(name: string) {
    this.name = name;
  }

  public setYAxis(index: number) {
    this.yAxis = index;
  }

  public setId(id: string) {
    this.id = id;
  }

  public setType(type: string) {
    this.type = type;
  }

  public setPointerCursor() {
    this.cursor = 'pointer';
  }

  public setVisible(isVisible: boolean) {
    this.visible = isVisible;
  }

  public setColor(color: string) {
    this.color = color;
  }

  public setStep(position: 'left' | 'center' | 'right') {
    this.step = position;
  }

  public show() {
    this.setVisible(true);
  }

  public hide() {
    this.setVisible(false);
  }

  public setTooltip(tooltip: TooltipOptions) {
    this.tooltip = tooltip;
  }

  public listenForEvents(component: LineDirective) {
    this.events = {}; // reset events!

    seriesEvents.forEach((eventType) => {
      this.events[eventType] = (event: Event) => {
        component[eventType].emit(new ChartEvent(event, this));
      }
    });
  }

}
