import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Plan } from '@app/shared/data/plan/plan.models';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { Credential } from '@app/shared/data/credential/credential.models';
import { User } from '@app/shared/data/user/user.models';
import { Action } from 'redux';
import { ConnectSPApiOriginEnum } from '../review-request/review-request.models';

@Injectable()
export class UsersActions {
  static readonly EVENTS = {
    USER_VERIFIED     : 'user_verified',
    WALLET_BALANCE_HAS_CHANGED: 'wallet_balance_has_changed',
  };

  static readonly TYPES = {
    OVERRIDE: '[USER]OVERRIDE',

    // Token
    REFRESH_TOKEN          : '[USER]REFRESH_TOKEN_STARTED',
    REFRESH_TOKEN_SUCCEEDED: '[USER]REFRESH_TOKEN_SUCCEEDED',
    REFRESH_TOKEN_FAILED   : '[USER]REFRESH_TOKEN_FAILED',

    GET_API_TOKEN          : '[USER]GET_API_TOKEN_STARTED',
    GET_API_TOKEN_SUCCEEDED: '[USER]GET_API_TOKEN_SUCCEEDED',
    GET_API_TOKEN_FAILED   : '[USER]GET_API_TOKEN_FAILED',

    // Auth
    ATTEMPT_AUTH          : '[USER]ATTEMPT_AUTH_STARTED',
    ATTEMPT_AUTH_SUCCEEDED: '[USER]ATTEMPT_AUTH_SUCCEEDED',
    ATTEMPT_AUTH_FAILED   : '[USER]ATTEMPT_AUTH_FAILED',

    // Registration
    ATTEMPT_REGISTRATION          : '[USER]ATTEMPT_REGISTRATION',
    ATTEMPT_REGISTRATION_SUCCEEDED: '[USER]ATTEMPT_REGISTRATION_SUCCEEDED',
    ATTEMPT_REGISTRATION_FAILED   : '[USER]ATTEMPT_REGISTRATION_FAILED',

    // Logout
    ATTEMPT_LOGOUT          : '[USER]ATTEMPT_LOGOUT',
    ATTEMPT_LOGOUT_SUCCEEDED: '[USER]ATTEMPT_LOGOUT_SUCCEEDED',

    // Switch Credential
    SWITCH_ACTIVE_CREDENTIAL          : '[USER]SWITCH_ACTIVE_CREDENTIAL',
    SWITCH_ACTIVE_CREDENTIAL_SUCCEEDED: '[USER]SWITCH_ACTIVE_CREDENTIAL_SUCCEEDED',
    SWITCH_ACTIVE_CREDENTIAL_FAILED   : '[USER]SWITCH_ACTIVE_CREDENTIAL_FAILED',

    // Credit card
    ADD_CREDIT_CARD          : '[USER]ADD_CREDIT_CARD',
    ADD_CREDIT_CARD_STARTED  : '[USER]ADD_CREDIT_CARD_STARTED',
    ADD_CREDIT_CARD_SUCCEEDED: '[USER]ADD_CREDIT_CARD_SUCCEEDED',
    ADD_CREDIT_CARD_FAILED   : '[USER]ADD_CREDIT_CARD_FAILED',

    // Forms
    REGISTRATION_SET_SELECTED_PLAN: '[USER]REGISTRATION_SET_SELECTED_PLAN',
    REGISTRATION_SET_COUPON_CODE  : '[USER]REGISTRATION_SET_COUPON_CODE',
    SET_PROFILE_FORM              : '[USER]SET_PROFILE_FORM',

    // Update Profile
    UPDATE_PROFILE          : '[USER]UPDATE_PROFILE',
    UPDATE_PROFILE_SUCCEEDED: '[USER]UPDATE_PROFILE_SUCCEEDED',
    UPDATE_PROFILE_FAILED   : '[USER]UPDATE_PROFILE_FAILED',

    // Credentials
    ADD_CREDENTIAL             : '[USER]ADD_CREDENTIAL',
    ADD_CREDENTIAL_SUCCEEDED   : '[USER]ADD_CREDENTIAL_SUCCEEDED',
    ADD_CREDENTIAL_FAILED      : '[USER]ADD_CREDENTIAL_FAILED',
    UPDATE_CREDENTIAL          : '[USER]UPDATE_CREDENTIAL',
    UPDATE_CREDENTIAL_SUCCEEDED: '[USER]UPDATE_CREDENTIAL_SUCCEEDED',
    UPDATE_CREDENTIAL_FAILED   : '[USER]UPDATE_CREDENTIAL_FAILED',
    REMOVE_CREDENTIAL          : '[USER]REMOVE_CREDENTIAL',
    REMOVE_CREDENTIAL_SUCCEEDED: '[USER]REMOVE_CREDENTIAL_SUCCEEDED',
    REMOVE_CREDENTIAL_FAILED   : '[USER]REMOVE_CREDENTIAL_FAILED',
    GET_CREDENTIAL_LIMITS      : '[USER]GET_CREDENTIAL_LIMITS',
    GET_CREDENTIAL_LIMITS_SUCCEEDED: '[USER]GET_CREDENTIAL_LIMITS_SUCCEEDED',
    GET_CREDENTIAL_LIMITS_FAILED: '[USER]GET_CREDENTIAL_LIMITS_FAILED',

    // Verification Email
    RESEND_VERIFICATION_EMAIL: '[USER]RESEND_VERIFICATION_EMAIL',
    VERIFICATION_SUCCEEDED   : '[USER]VERIFICATION_SUCCEEDED',

    // Limits
    LIMITS_REACHED                   : '[USER]LIMITS_REACHED',
    TRIAL_ACCOUNT_TIME_LEFT          : '[USER]TRIAL_ACCOUNT_TIME_LEFT',
    TRIAL_ACCOUNT_TIME_LEFT_SUCCEEDED: '[USER]TRIAL_ACCOUNT_TIME_LEFT_SUCCEEDED',
    TRIAL_ACCOUNT_CHANGE_COUPON      : '[USER]TRIAL_ACCOUNT_CHANGE_COUPON',

    // Coaching offer
    SET_COACHING_OFFER_STATE      : '[USER]SET_COACHING_OFFER_STATE',

    // Update credit card
    UPDATE_CARD_PARAMETER_MISSING : '[USER]UPDATE_CARD_PARAMETER_MISSING',

    // SELLING PARTNER API
    CONNECT_SELLING_PARTNER_API: '[USER] CONNECT_SELLING_PARTNER_API',
    CONNECT_SELLING_PARTNER_API_SUCCESS: '[USER] CONNECT_SELLING_PARTNER_API_SUCCESS',
    CONNECT_SELLING_PARTNER_API_ERROR: '[USER] CONNECT_SELLING_PARTNER_API_ERROR',
    CREDENTIAL_SP_API_CONNECTED: '[USER] CREDENTIAL_SP_API_CONNECTED',
  };

  override(user: User): StAction {
    return {
      type: UsersActions.TYPES.OVERRIDE,
      data: user
    };
  }

  /**
   *
   * @returns {{type: string}}
   */
  refreshToken(): StAction {
    return {
      type: UsersActions.TYPES.REFRESH_TOKEN,
      data: null
    };
  }

  refreshTokenSucceeded(data): StAction {
    return {
      type: UsersActions.TYPES.REFRESH_TOKEN_SUCCEEDED,
      data: data
    };
  }

  refreshTokenFailed(error): StAction {
    return {
      type: UsersActions.TYPES.REFRESH_TOKEN_FAILED,
      data: error
    };
  }

  getApiToken(): StAction {
    return {
      type: UsersActions.TYPES.GET_API_TOKEN,
      data: null
    };
  }

  getApiTokenSucceeded(data): StAction {
    return {
      type: UsersActions.TYPES.GET_API_TOKEN_SUCCEEDED,
      data: data
    };
  }

  getApiTokenFailed(error): StAction {
    return {
      type: UsersActions.TYPES.GET_API_TOKEN_FAILED,
      data: error
    };
  }

  attemptAuth(data): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_AUTH,
      data: data
    };
  }

  attemptAuthSucceeded(data): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_AUTH_SUCCEEDED,
      data: data
    };
  }

  attemptAuthFailed(error): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_AUTH_FAILED,
      data: error
    };
  }

  attemptRegistration(data): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_REGISTRATION,
      data: data
    };
  }

  attemptRegistrationSucceeded(data): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_REGISTRATION_SUCCEEDED,
      data: data
    };
  }

  attemptRegistrationFailed(data): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_REGISTRATION_FAILED,
      data: data
    };
  }

  attemptLogout(): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_LOGOUT,
      data: null
    };
  }

  attemptLogoutSucceeded(data): StAction {
    return {
      type: UsersActions.TYPES.ATTEMPT_LOGOUT_SUCCEEDED,
      data: data
    };
  }

  switchActiveCredential(data): StAction {
    return {
      type: UsersActions.TYPES.SWITCH_ACTIVE_CREDENTIAL,
      data: data
    };
  }

  switchCredentialSucceeded(data): StAction {
    return {
      type: UsersActions.TYPES.SWITCH_ACTIVE_CREDENTIAL_SUCCEEDED,
      data: data
    };
  }

  switchCredentialFailed(error): StAction {
    return {
      type: UsersActions.TYPES.SWITCH_ACTIVE_CREDENTIAL_FAILED,
      data: error
    };
  }

  addCreditCardStarted(): StAction {
    return {
      type: UsersActions.TYPES.ADD_CREDIT_CARD_STARTED,
      data: {}
    };
  }

  addCreditCard(id): StAction {
    return {
      type: UsersActions.TYPES.ADD_CREDIT_CARD,
      data: {
        id: id
      }
    };
  }

  addCreditCardSucceeded(data): StAction {
    return {
      type: UsersActions.TYPES.ADD_CREDIT_CARD_SUCCEEDED,
      data: data
    };
  }

  addCreditCardFailed(data): StAction {
    return {
      type: UsersActions.TYPES.ADD_CREDIT_CARD_FAILED,
      data: data
    };
  }

  registrationSetSelectedPlan(plan: Plan): StAction {
    return {
      type: UsersActions.TYPES.REGISTRATION_SET_SELECTED_PLAN,
      data: {
        plan: plan
      }
    };
  }

  updateProfile(data: any): StAction {
    return {
      type: UsersActions.TYPES.UPDATE_PROFILE,
      data: data
    };
  }

  updateProfileSucceeded(data: any, type: string): StAction {
    return {
      type: UsersActions.TYPES.UPDATE_PROFILE_SUCCEEDED,
      data: {
        user: data,
        type: type
      }
    };
  }

  updateProfileFailed(error: any, type: string): StAction {
    return {
      type: UsersActions.TYPES.UPDATE_PROFILE_FAILED,
      data: {
        error: error,
        type : type
      }
    };
  }

  addCredential(data): StAction {
    return {
      type: UsersActions.TYPES.ADD_CREDENTIAL,
      data: data
    };
  }

  addCredentialSucceeded(credential: Credential): StAction {
    return {
      type: UsersActions.TYPES.ADD_CREDENTIAL_SUCCEEDED,
      data: credential
    };
  }

  addCredentialFailed(error): StAction {
    return {
      type: UsersActions.TYPES.ADD_CREDENTIAL_FAILED,
      data: error
    };
  }

  updateCredential(credential: Credential): StAction {
    return {
      type: UsersActions.TYPES.UPDATE_CREDENTIAL,
      data: {
        credential: credential
      }
    };
  }

  updateCredentialSucceeded(credential: Credential): StAction {
    return {
      type: UsersActions.TYPES.UPDATE_CREDENTIAL_SUCCEEDED,
      data: {
        credential: credential
      }
    };
  }

  updateCredentialFailed(error: any): StAction {
    return {
      type: UsersActions.TYPES.UPDATE_CREDENTIAL_FAILED,
      data: error
    };
  }

  removeCredential(credential: Credential): StAction {
    return {
      type: UsersActions.TYPES.REMOVE_CREDENTIAL,
      data: credential
    };
  }

  removeCredentialSucceeded(credential: Credential): StAction {
    return {
      type: UsersActions.TYPES.REMOVE_CREDENTIAL_SUCCEEDED,
      data: credential
    };
  }

  removeCredentialFailed(error: any): StAction {
    return {
      type: UsersActions.TYPES.REMOVE_CREDENTIAL_FAILED,
      data: error
    };
  }

  getCredentialLimits(credential: Credential): StAction {
    return {
      type: UsersActions.TYPES.GET_CREDENTIAL_LIMITS,
      data: credential
    };
  }

  getCredentialLimitsSucceeded(credential: Credential, limits: any): StAction {
    return {
      type: UsersActions.TYPES.GET_CREDENTIAL_LIMITS_SUCCEEDED,
      data: {
        credential,
        limits
      }
    };
  }

  getCredentialLimitsFailed(error: any): StAction {
    return {
      type: UsersActions.TYPES.GET_CREDENTIAL_LIMITS_FAILED,
      data: error
    };
  }

  trialAccountTimeLeft(): StAction {
    return {
      type: UsersActions.TYPES.TRIAL_ACCOUNT_TIME_LEFT,
      data: null
    };
  }

  trialAccountTimeLeftSucceeded(timeLeft): StAction {
    return {
      type: UsersActions.TYPES.TRIAL_ACCOUNT_TIME_LEFT_SUCCEEDED,
      data: timeLeft
    };
  }

  changeTrialCoupon(trialCouponCode): StAction {
    return {
      type: UsersActions.TYPES.TRIAL_ACCOUNT_CHANGE_COUPON,
      data: trialCouponCode
    };
  }

  setProfileForm(user: User): StAction {
    return {
      type: UsersActions.TYPES.SET_PROFILE_FORM,
      data: user
    };
  }

  setCoachingOfferState(state: boolean): StAction {
    return {
      type: UsersActions.TYPES.SET_COACHING_OFFER_STATE,
      data: state
    };
  }

  updateCreditCardParameterMissing(error: string = null): StAction {
    return {
      type: UsersActions.TYPES.UPDATE_CARD_PARAMETER_MISSING,
      data: error,
    }
  }

  connectSellingPartnerAPI(credentialId: number, marketplaceId: number, origin: ConnectSPApiOriginEnum): StAction {
    return {
      type: UsersActions.TYPES.CONNECT_SELLING_PARTNER_API,
      data: {
        credentialId,
        marketplaceId,
        origin,
      },
    };
  }

  connectSellingPartnerAPIWithAmazonCallback(credentialId: number, marketplaceId: number, origin: ConnectSPApiOriginEnum, amazonCallbackUri: string, amazonState: string): StAction {
    return {
      type: UsersActions.TYPES.CONNECT_SELLING_PARTNER_API,
      data: {
        credentialId,
        marketplaceId,
        origin,
        amazonCallbackUri,
        amazonState,
      },
    };
  }

  connectSellingPartnerAPISuccess(state: string, merchantId: string): StAction {
    return {
      type: UsersActions.TYPES.CONNECT_SELLING_PARTNER_API_SUCCESS,
      data: {
        state,
        merchantId,
      }
    };
  }

  connectSellingPartnerAPIError(): Action {
    return {
      type: UsersActions.TYPES.CONNECT_SELLING_PARTNER_API_ERROR,
    }
  }

  credentialSPAConnected(): Action {
    return {
      type: UsersActions.TYPES.CREDENTIAL_SP_API_CONNECTED,
    }
  }

  static registrationSetCouponCode(couponCode: Coupon): StAction {
    return {
      type: UsersActions.TYPES.REGISTRATION_SET_COUPON_CODE,
      data: {
        coupon_code: couponCode || new Coupon()
      }
    };
  }

  static resendVerificationEmail(user: User): StAction {
    return {
      type: UsersActions.TYPES.RESEND_VERIFICATION_EMAIL,
      data: user
    };
  }

  static verificationSucceeded(): StAction {
    return {
      type: UsersActions.TYPES.VERIFICATION_SUCCEEDED,
      data: true
    };
  }
}
