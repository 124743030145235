import { Component, EventEmitter, Input, Output } from "@angular/core";
import { V2TableColumn } from "../../table.models";

@Component({
  selector: 'st-table-column-refresh',
  templateUrl: './table-columns.refresh.component.html',
  styleUrls: ['./table-columns.refresh.component.scss']
})
export class TableColumnRefreshComponent {
  @Input() column: V2TableColumn;
  @Output() onRefresh: EventEmitter<any> = new EventEmitter();

  keywordIndex() {
    this.onRefresh.emit();
  }
}
