import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import {ProductChild} from "@app/shared/data/product-child/product-child.models";

@Injectable()
export class ProductChildActions extends PaginationActions {
  protected model = ProductChild;

  static readonly TYPES = {};
}
