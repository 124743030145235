<ng-container *ngIf="item.value">
  <st-dot-loader *ngIf="item.value.loading" size="8px"></st-dot-loader>
  <div class="keyword-circle"
    [popper]="trackedData"
    popperTrigger="hover"
    popperPlacement="bottom"
    popperAppendTo="body"
    *ngIf="!item.value.loading"
    (click)="!item.value.disabled &&
      trackKeyword.emit({ keyword: item.value.value, disabled: item.value.disabled, tracked: item.value.tracked });
      item.value.loading = true"
    [class.keyword-circle--tracked]="item.value.tracked"
    [class.keyword-circle--disabled]="item.value.disabled"
    [class.keyword-circle--track]="!item.value.tracked">
    <span *ngIf="!item.value.tracked">+</span>
    <img *ngIf="item.value.tracked" src="/assets/img/icons/tick-white.svg">
  </div>
</ng-container>
<ng-container *ngIf="!item.value"></ng-container>

<popper-content #trackedData>
  <div class="tracked-keyword-popup">
    <ng-container *ngIf="item.value?.tracked" i18n>Keyword is tracked. Click to remove tracking for it.</ng-container>
    <ng-container *ngIf="!item.value?.tracked" i18n>Add keyword to <br> your product</ng-container>
  </div>
</popper-content>
