import { Component, Input } from "@angular/core";

@Component({
  selector: 'st-error-v2',
  templateUrl: './error-v2.component.html',
  styleUrls: ['./error-v2.component.scss'],
})
export class ErrorV2Component {
  @Input() errorMessage: string;
}
