import { Component, EventEmitter, Input, Output } from "@angular/core";
import { I18n } from "@ngx-translate/i18n-polyfill";
import { SortByEnum, V2TableColumn } from "../../table.models";

@Component({
  selector: 'st-table-column-priority',
  templateUrl: './table-columns.priority.component.html',
  styleUrls: ['./table-columns.priority.component.scss']
})
export class TableColumnPriorityComponent {
  @Input() column: V2TableColumn;
  @Input() activePriorityFilter: string;
  @Input() currentSortBy: SortByEnum;
  @Input() isFilterActive: boolean;

  @Output() onFilterByPriority: EventEmitter<any> = new EventEmitter();
  @Output() onSortBy: EventEmitter<any> = new EventEmitter();

  sortByEnum = SortByEnum;

  constructor(
    private i18n: I18n,
  ) { }

  getStatusHandleTooltip(priority: 'VL'|'L'|'M'|'H'): string {
    if (priority === this.activePriorityFilter) {
      return this.i18n('Click here to reset priority filter');
    }

    const statusMapper = {
      'VL': this.i18n('Very Low'),
      'L': this.i18n('Low'),
      'M': this.i18n('Medium'),
      'H': this.i18n('High'),
    };

    return this.i18n(`Click to filter {{status}} priority keywords`, { status: statusMapper[priority] });
  }

  filterByPriority(priority: string) {
    this.onFilterByPriority.emit(priority);
  }

  sortBy() {
    this.onSortBy.emit(this.column.sortBy);
  }
}
