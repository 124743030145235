<div class="header">
  <div class="header-titles">
    <h2 class="header__title" i18n>Products</h2>
    <h3 class="header__subtitle" i18n>Choose a product from the list below to begin the optimization process. </h3>
  </div>
</div>

<div class="product-list-tabs" [class.product-list-tabs--loading]="loadingProducts">
  <div
    (click)="setActiveProductTab(tabTypes.MY_PRODUCTS)"
    class="product-list-tab"
    [class.product-list-tab--active]="activeTab === tabTypes.MY_PRODUCTS">
    <div class="product-list-tab__title" i18n>My Products</div>
    <div class="product-list-tab__count">({{total || 0}})</div>
  </div>
  <div
    (click)="setActiveProductTab(tabTypes.OTHER_PRODUCTS)"
    class="product-list-tab"
    [class.product-list-tab--active]="activeTab === tabTypes.OTHER_PRODUCTS">
    <div class="product-list-tab__title" i18n>Other Products</div>
    <div class="product-list-tab__count" *ngIf="otherProductsTabSeen">({{foreignTotal || 0}})</div>
  </div>
</div>
<div class="product-list">
  <div class="product-list__header">
    <div class="product-list__header--column">
      <div class="product-list-search-wrapper">
        <input class="product-list-search-wrapper__input" type="text" placeholder="SEARCH PRODUCTS" [(ngModel)]="searchProductString" (ngModelChange)="searchProducts()">
        <st-icon class="product-list-search-wrapper__icon" icon="search_blue"></st-icon>
      </div>
      <div class="advanced" (click)="getAvailableTags()">
        <st-advanced-filters
            [loading]="loadingAvailableFilters"
            [selectedFilters]="selectedFilters"
            [availableFilters]="availableFilters"
            (filtersApplied)="setFilters($event)"
            data-qa="pm_advanced_filters"
        ></st-advanced-filters>
      </div>
    </div>
    <st-button-v2 (click)="syncProducts()"
                  *ngIf="activeTab === tabTypes.MY_PRODUCTS"
                  color="v2_blue_empty" label="REFRESH THE LIST" i18n-label
                  [disabled]="syncingProductsWithAmazon"
                  [popper]="productSyncTooltip"
                  popperAppendTo="body"
                  popperTrigger="hover"
                  popperPlacement="bottom"
                  [icon]="buttonIcons.V2_RESET" [hoverIcon]="buttonIcons.V2_RESET_WHITE">
    </st-button-v2>

    <st-button-v2 (click)="addProductsManually()"
                  *ngIf="activeTab === tabTypes.OTHER_PRODUCTS"
                  color="v2_blue_empty" label="+ ADD PRODUCTS MANUALLY" i18n-label>
    </st-button-v2>

    <!-- <st-advanced-filters (filtersApplied)="setFilters($event)"></st-advanced-filters>
    <div class="saved-filters-wrapper">
      <div class="saved-filters-button" (click)="toggleSavedViewsModal()" i18n>Saved Views</div>
      <div class="saved-filters-dropdown" [class.saved-filters-dropdown--visible]="savedViewsOpened">
        <div class="saved-filters-dropdown__content" [class.saved-filters-dropdown__content--empty]="savedFilters?.length === 0">
          <div class="saved-filters-dropdown__empty" *ngIf="savedFilters?.length === 0"></div>
          <div class="saved-filters-dropdown__empty-text" *ngIf="savedFilters?.length === 0" i18n>No Existing Views</div>

          <div class="saved-filters-list">
            <div class="saved-filters-list__filter" *ngFor="let filter of savedFilters">
              <div class="saved-filters-list__filter-column saved-filters-list__filter-column--name">{{filter.name}}</div>
              <div class="saved-filters-list__filter-column">
                <st-button-v2 (clicked)="applyFilters(filter)" i18n-label label="Apply" color="transparent" size="small"></st-button-v2>
              </div>
              <div class="saved-filters-list__filter-column">
                <st-icon icon="red-remove"></st-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="product-list__tabs">
      <div class="product-list__tabs-tab" [class.product-list__tabs-tab--active]="activeTab === 'active'">
        <span>Active List</span> <span>({{products?.length || 0}})</span>
      </div>
      <div class="product-list__tabs-tab" [class.product-list__tabs-tab--active]="activeTab === 'archived'">
        <span>Archived</span> <span>({{archivedProducts?.length || 0}})</span>
      </div>
    </div> -->
  </div>

  <div class="selected-filters-wrapper" *ngIf="selectedFilters?.length > 0">
    <div class="selected-filters__column selected-filters__column--title" i18n>Filters Applied:</div>
    <div class="selected-filters__column">
      <div class="selected-filters">
        <div class="selected-filter" *ngFor="let filter of selectedFilters">
          <div class="selected-filter__value">{{filter.title}} {{operatorTranslator.translate(filter.operator)}} {{filter.valueAsAString || filter.value}}</div>
          <st-icon class="selected-filter__remove" (click)="removeFilter(filter)" icon="close"></st-icon>
        </div>
      </div>
    </div>
    <!-- <div class="selected-filters__column selected-filters__column--button-wrapper">
      <st-button-v2 (clicked)="openSaveFiltersModal()" color="gray" i18n-label label="Save Filters as View"></st-button-v2>
    </div> -->
  </div>

  <st-product-manager-product-list-table
    [products]="activeProducts"
    [productVariations]="productVariations"
    [loadingProducts]="loadingProducts"
    [loadingGraphData]="loadingGraphData"
    [loadingProductVariations]="loadingProductVariations"
    [perPageOptions]="perPageOptions"
    [preSelectedOption]="preSelectedOption"
    [variationPerPage]="variationPerPage"
    [variationCurrentPage]="variationCurrentPage"
    [variationTotal]="variationTotal"
    [variationPagesArray]="variationPagesArray"
    [variationFrom]="variationFrom"
    [variationTo]="variationTo"
    [variationLastPage]="variationLastPage"
    [currentDisplayedVariationProducts]="currentDisplayedVariationProducts"
    [bsrGraph]="bsrGraph"
    [activeTab]="activeTab"
    (onSortBy)="sortBy($event)"
    (onToggleProductDetails)="toggleProductDetails($event)"
    (onVariationGoToFirstPage)="variationGoToFirstPage($event)"
    (onVariationGoToLastPage)="variationGoToLastPage($event)"
    (onVariationNextPage)="variationNextPage($event)"
    (onVariationPreviousPage)="variationPreviousPage($event)"
    (onVariationChangePage)="variationChangePage($event)"
    (onVariationPageChange)="variationPageChange($event)"
    (onSelectProduct)="selectProduct($event)"
    (onProductReset)="resetProduct($event)"
    (onActiveTab)="setActiveTab($event)">
  </st-product-manager-product-list-table>

  <div class="product-list__footer">
    <div class="product-list__footer-column"></div>
    <div class="product-list__footer-column">
      <st-pagination
        [currentPage]="currentPage"
        [pages]="pagesArray"
        (goTofirstPage)="goToFirstPage()"
        (goToLastPage)="goToLastPage()"
        (goToNextPage)="nextPage()"
        (goToPreviousPage)="previousPage()"
        (changePage)="changePage($event)"></st-pagination>
    </div>
    <div class="product-list__footer-column product-list__footer-column--info">
      <st-pagination-info
          [text]="currentDisplayedProducts"
          [disabled]="loadingProducts"
          [options]="perPageOptions"
          (onPageChange)="pageChange($event)"
          [preSelectedOption]="preSelectedOption"></st-pagination-info>
    </div>
  </div>
</div>

<popper-content #productSyncTooltip>
  <div i18n *ngIf="syncingProductsWithAmazon">
    Sync is already in progress
  </div>
  <div i18n *ngIf="!syncingProductsWithAmazon">
    Click to refresh products from Amazon Seller Central
  </div>
</popper-content>
