<div class="{{'wrapper ' + activeSubscription?.plan?.name?.toLowerCase()}}">
  <ng-container *ngIf="loading">
    <st-loader [lines]="12"></st-loader>
  </ng-container>

  <ng-container *ngIf="!loading && !activeSubscription" i18n>
    You do not have active subscription
  </ng-container>

  <ng-container *ngIf="!loading && activeSubscription">
    <div class="top">
      <div class="current">
        <st-icon icon="check-blue-new"></st-icon>
        <span class="plan-name">{{ activeSubscription?.plan?.name }}</span>
        <span class="period" i18n>{{activeSubscription?.plan?.annual ? 'YEARLY' : 'MONTHLY'}}</span>
        <span class="period period--cancelled" *ngIf="subscriptionCancelled()" i18n>CANCELLED</span>
        <span class="period period--cancelled" *ngIf="canceling" i18n>CANCELING ...</span>
        <span class="period period--trialing" *ngIf="subscriptionTrialing()" i18n>ON TRIAL</span>
      </div>
      <div class="price">
        <span class="sign-number">
          <span class="sign">$</span>
          <span class="number">
            {{ activeSubscription?.plan?.price | stDiscountedPrice:activeSubscription?.couponObj | number:'1.0-2' }}
          </span>
          <div class="price-period">/ {{activeSubscription?.plan?.annual ? 'YR' : 'MO'}}</div>
        </span>
      </div>
    </div>

    <div *ngIf="!activeSubscription?.plan?.annual && savingAmount" class="yearly-savings">
      <span i18n>Switch to yearly billing and save <span class="yearly-savings__amount">${{savingAmount}}</span> per year!</span>
    </div>
    <div class="coupon-applied" *ngIf="isCouponCodeApplied">
      <st-icon icon="icon-coupon"></st-icon>
      <div class="coupon-applied__text" i18n>Coupon Applied</div>
    </div>
    <div class="validity-outer-wrapper">
      <div class="validity-wrapper">
        <div class="validity">
          <span i18n *ngIf="!subscriptionCancelled(); else validUntil">Next billing date:</span>
          <ng-template #validUntil><span i18n>Valid until:</span></ng-template>
          <span class="date">{{ activeSubscription?.billingPeriod | date:'MMMM dd, yyyy'}}</span>
        </div>
      </div>
      <div class="notice-wrapper" *ngIf="(subscriptionHasChanged() && !subscriptionCancelled()) && !loading">
        <st-icon icon="warning-yellow"></st-icon>
        <div class="notice notice--downgrade" [stTranslate]="{ newPlanName: nextSubscription.plan.name,createdAt: nextSubscription.created_at, activeFrom: nextSubscription.trial_ends_at }" i18n>
          You downgraded to %newPlanName Plan on %createdAt. Your downgraded plan will be active from %activeFrom.
        </div>
      </div>
      <span *ngIf="!subscriptionHasChanged() && !subscriptionTrialing() && !isFree()" class="cancel" (click)="cancelSubscription()" [class.cancel--disabled]="canceling" i18n>Cancel Subscription Plan</span>
      <span *ngIf="subscriptionCancelled()" class="cancel" (click)="resubscribe()" [class.cancel--disabled]="subscribingToPlan" i18n>Resubscribe</span>
    </div>
  </ng-container>
</div>
