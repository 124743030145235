import { Injectable } from '@angular/core';
import { User } from '@app/shared/data/user/user.models';
import { environment } from '@env/environment';

@Injectable()
export class ProfitWellService {

  constructor() {
  }

  public init(user: User) {
    if (environment.PROFIT_WELL_ID) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.setAttribute('id', 'profitwell-js');
      script.setAttribute('data-pw-auth', environment.PROFIT_WELL_ID);
      script.innerHTML = `(function(i,s,o,g,r,a,m){i[o]=i[o]||function(){(i[o].q=i[o].q||[]).push(arguments)};
        a=s.createElement(g);m=s.getElementsByTagName(g)[0];a.async=1;a.src=r+'?auth='+
        s.getElementById(o+'-js').getAttribute('data-pw-auth');m.parentNode.insertBefore(a,m);
        })(window,document,'profitwell','script','https://public.profitwell.com/js/profitwell.js');
        profitwell('start', ${JSON.stringify(user.getProfitWellSettings())});`;

      document.body.appendChild(script);
    }

  }
}
