import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { Coupon } from "@app/shared/data/coupon/coupon.models";
import { CouponActions } from "@app/shared/data/coupon/coupon.actions";

const INITIAL_STATE: AppState = {
  data    : new Collection<Coupon>([]),
  loading : false,
  creating: false,
  removing: false,
  _error  : null,
};

export function couponReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type == CouponActions.TYPES.LOAD_COUPONS) {
    return {
      ...state,
      loading: true,
      _error : null,
    }
  }

  if (action.type == CouponActions.TYPES.LOAD_COUPONS_SUCCEEDED) {
    return {
      ...state,
      data   : action.data,
      loading: false,
      _error : null,
    }
  }

  if (action.type == CouponActions.TYPES.LOAD_COUPONS_FAILED) {
    return {
      ...state,
      loading: false,
      _error : action.data,
    }
  }

  if (action.type == CouponActions.TYPES.CREATE) {
    return {
      ...state,
      creating: true,
      _error  : null
    }
  }

  if (action.type == CouponActions.TYPES.CREATE_SUCCEEDED) {
    return {
      ...state,
      data    : state['data'].push(action.data),
      creating: false,
      _error  : null
    }
  }

  if (action.type == CouponActions.TYPES.CREATE_FAILED) {
    return {
      ...state,
      creating: false,
      _error  : action.data
    }
  }

  if (action.type == CouponActions.TYPES.REMOVE) {
    return {
      ...state,
      removing: true,
      _error  : null
    }
  }

  if (action.type == CouponActions.TYPES.REMOVE_SUCCEEDED) {
    return {
      ...state,
      data    : state['data'].filter(c => c.code != action.data.code),
      removing: false,
      _error  : null
    }
  }

  if (action.type == CouponActions.TYPES.REMOVE_FAILED) {
    return {
      ...state,
      removing: false,
      _error  : action.data
    }
  }

  return state;
}
