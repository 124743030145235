import { PipeTransform, Injectable, Pipe } from "@angular/core";

@Pipe({
  name: 'stStrFilter'
})
@Injectable()
export class StrFilterPipe implements PipeTransform {
  transform(items: any[], field: string, value: string, exact: boolean = true, negate: boolean = false): any[] {
    let fields = field.split(',');

    value = (value + '').toLowerCase().trim();

    if(value.length <= 0) return items;

    if (!items) return [];
    return items.filter((it, i) => {
      for (let f of fields) {
        let val = this.get_recursive(it, f).toLowerCase();

        if (exact) {
          if (val == value.trim()) return !negate;
        }
        else {
          if (val.includes(value.trim())) return !negate;
        }
      }

      return negate;
    });
  }

  get_recursive(item:any, field: string): string {
    const fields = field.split('.');

    for (let f of fields) {
      item = item[f];
    }
    return item + "";
  }
}
