import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from "@app/shared/components/dialog/dialog.component";
import { DialogService } from "@app/shared/components/dialog/dialog.service";
import { ConfirmComponent } from "@app/shared/components/dialog/confirm/confirm.component";
import { PromptComponent } from "@app/shared/components/dialog/prompt/prompt.component";
import { InfoComponent } from "@app/shared/components/dialog/info/info.component";
import { ButtonsModule } from "@app/shared/layout/forms/button/buttons.module";
import { FormsModule } from "@angular/forms";
import { ProgressComponent } from "@app/shared/components/dialog/progress/progress.component";
import { LayoutModule } from "@app/shared/layout/layout.module";
import { ProgressBarModule } from '@app/shared/components/progress-bar/progress-bar.module';
import { MultiButtonDialogComponent } from '@app/shared/components/dialog/multi-button-dialog/multi-button-dialog.component';
import { LayoutV2Module } from '@app/shared/layout/v2/layout-v2.module';
import { ProductManagerListingManagerPublishedModalComponent } from '@app/client/v2/product-manager/product-manager-listing-manager-published-modal/product-manager-listing-manager-published-modal.component';

@NgModule({
  imports        : [
    CommonModule,
    FormsModule,
    ButtonsModule,
    LayoutModule,
    ProgressBarModule,
    LayoutV2Module
  ],
  declarations   : [
    DialogComponent,
    ConfirmComponent,
    PromptComponent,
    InfoComponent,
    ProgressComponent,
    MultiButtonDialogComponent,
    ProductManagerListingManagerPublishedModalComponent,
  ],
  exports        : [
    DialogComponent
  ],
  providers      : [
    DialogService
  ],
})
export class DialogModule {
}
