import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { IFilter, OperatorEnum } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { ToastService } from '@app/core/services/toast.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { AppState } from '../app-state.model';
import { CampaignManagerActions } from './campaign-manager.actions';
import { CampaignManagerAPI } from './campaign-manager.api';
import {
  ICampaignManagerState, ISavedFiltersListResponse, ISaveFilterResponse,
} from './campaign-manager.models';

@Injectable()
export class CampaignManagerEpics {
  constructor(
    private campaignManagerActions: CampaignManagerActions,
    private campaignManagerApi: CampaignManagerAPI,
    private ngRedux: NgRedux<AppState>,
    private router: Router,
    private toastService: ToastService,
    private i18n: I18n,
  ) { }

  /**
   *
   * @returns {Epic<Action, AppState>}
   */
  public createEpic() {
    return [
      this.getCampaignList,
      this.uploadFile,
      this.saveFilters,
      this.loadSavedFilters,
      this.loadAvailableFilters,
      this.getCampaignById,
      this.addCampaign,
      this.updateCampaign,
      this.deleteCampaign,
      this.checkSlug,
    ];
  }

  getCampaignList = (store: NgRedux<AppState>) => next => {
    return (action) => {
      const response = next(action);
      const state: ICampaignManagerState = store.getState()['campaign_manager'];

      if (action.type === CampaignManagerActions.TYPES.LOAD_CAMPAIGN_LIST) {
        const filters = [];

        state.appliedFilters.forEach((filter: IFilter) => {
          // each value of multiselect filters should be passed on their own
          // so [country, ==, [sl,at]] becomes [country, ==, sl] and [country,==,at]
          if (Array.isArray(filter.value)) {
            filter.value.forEach(value => filters.push({ ...filter, value }));
          } else {
            filters.push(filter);
          }
        });

        if (state.searchTerm !== '') {
          filters.push({
            field: '*',
            operator: OperatorEnum.CONTAINS,
            value: state.searchTerm,
          });
        }

        this.campaignManagerApi.getCampaignList(state.currentPage, state.perPage, state.sort, filters)
          .subscribe(
            (res) => {
              this.ngRedux.dispatch(this.campaignManagerActions.campaignListLoaded(res));
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.campaignListLoadedError(error));
            },
          );
      }

      if (action.type === CampaignManagerActions.TYPES.SET_FILTERS) {
        this.ngRedux.dispatch(this.campaignManagerActions.loadCampaignList(1));
      }

      if (action.type === CampaignManagerActions.TYPES.SEARCH) {
        this.ngRedux.dispatch(this.campaignManagerActions.loadCampaignList(1));
      }

      return response;
    };
  }

  uploadFile = () => next => {
    return (action) => {
      if ([CampaignManagerActions.TYPES.UPLOAD_FILE, CampaignManagerActions.TYPES.UPLOAD_THEME_LOGO_FILE, CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE].includes(action.type)) {
        if (!action.data.file && action.type === CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE) {
          this.ngRedux.dispatch(this.campaignManagerActions.uploadEbookFileSuccess({url: action.data.file, type: action.data.type}));
          return;
        }
        this.campaignManagerApi.uploadFile(action.data.file)
          .subscribe(
            (response: any) => {
              if (action.type === CampaignManagerActions.TYPES.UPLOAD_FILE) {
                this.ngRedux.dispatch(this.campaignManagerActions.uploadFileSuccess(response));
              } else if (action.type === CampaignManagerActions.TYPES.UPLOAD_THEME_LOGO_FILE) {
                this.ngRedux.dispatch(this.campaignManagerActions.uploadThemeLogoFileSuccess(response));
              } else if (action.type === CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE) {
                this.ngRedux.dispatch(this.campaignManagerActions.uploadEbookFileSuccess({...response, type: action.data.type}));
              }
            },
            (error) => {
              if (action.type === CampaignManagerActions.TYPES.UPLOAD_FILE) {
                this.ngRedux.dispatch(this.campaignManagerActions.uploadFileError(error));
              } else if (action.type === CampaignManagerActions.TYPES.UPLOAD_THEME_LOGO_FILE) {
                this.ngRedux.dispatch(this.campaignManagerActions.uploadThemeLogoFileError(error));
              } else if (action.type === CampaignManagerActions.TYPES.UPLOAD_EBOOK_FILE) {
                this.ngRedux.dispatch(this.campaignManagerActions.uploadEbookFileError(error));
              }
            },
          );
      }
      return next(action);
    };
  }

  checkSlug = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.SET_CAMPAIGN_CHECK_SLUG) {
        const state: ICampaignManagerState = store.getState()['campaign_manager'];

        this.campaignManagerApi.checkCampaignSlug(action.data, state.campaignManagerState.id)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.campaignManagerActions.setCampaignCheckSlugSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.setCampaignCheckSlugError(error));
            },
          );
      }
      return next(action);
    };
  }

  saveFilters = () => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.SAVE_FILTERS) {
        this.campaignManagerApi.saveFilters(action.data.name, action.data.filters)
          .subscribe(
            (response: ISaveFilterResponse) => {
              this.ngRedux.dispatch(this.campaignManagerActions.saveFiltersSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.saveFiltersError(error));
            },
          );
      }
      return next(action);
    };
  }

  loadSavedFilters = () => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.LOAD_SAVED_FILTERS) {
        this.campaignManagerApi.getSavedFilters()
          .subscribe(
            (response: ISavedFiltersListResponse) => {
              this.ngRedux.dispatch(this.campaignManagerActions.loadSavedFiltersSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.loadSavedFiltersError(error));
            },
          );
      }
      return next(action);
    };
  }

  loadAvailableFilters = () => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.LOAD_AVAILABLE_FILTERS) {
        this.campaignManagerApi.loadAvailableFilters()
          .subscribe(
            (res) => {
              this.ngRedux.dispatch(this.campaignManagerActions.loadAvailableFiltersSuccess(res));
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.loadAvailableFiltersError(error));
            }
          )
      }

      return next(action);
    };
  }

  getCampaignById = () => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.GET_CAMPAIGN) {
        this.campaignManagerApi.getCampaign(action.data)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.campaignManagerActions.getCampaignSuccess(response));
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.getCampaignError(error));
            },
          );
      }
      return next(action);
    };
  }

  addCampaign = () => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.ADD_CAMPAIGN) {
        this.campaignManagerApi.addCampaign(action.data.campaign)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.campaignManagerActions.addCampaignSuccess(response.data));
              const currentUrl = this.router.url;

              if (action.data.redirectToMainPage) {
                this.router.navigate(['/campaign-manager']);
              } else {
                this.router.navigate([currentUrl.replace('new', response.data.id)]);
              }

              this.toastService.success(
                response.data.name,
                this.i18n('Campaign saved!')
              );
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.addCampaignError(error));

              this.toastService.error(
                this.i18n('Error'),
                this.i18n('Error when saving campaign')
              );
            },
          );
      }
      return next(action);
    };
  }

  updateCampaign = () => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.UPDATE_CAMPAIGN) {
        this.campaignManagerApi.updateCampaign(action.data.campaign)
          .subscribe(
            (response) => {
              this.ngRedux.dispatch(this.campaignManagerActions.updateCampaignSuccess(response.data));

              this.toastService.success(
                response.data.name,
                this.i18n('Campaign Updated!')
              );

              if (action.data.redirectToMainPage) {
                this.router.navigate(['/campaign-manager']);
              }
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.updateCampaignError(error));

              this.toastService.error(
                this.i18n('Error'),
                this.i18n('Error when updating campaign')
              );
            },
          );
      }
      return next(action);
    };
  }

  deleteCampaign = () => next => {
    return (action) => {
      if (action.type === CampaignManagerActions.TYPES.DELETE_CAMPAIGN) {
        this.campaignManagerApi.updateCampaign(action.data)
          .subscribe(
            () => {
              this.ngRedux.dispatch(this.campaignManagerActions.deleteCampaignSuccess());
            },
            (error) => {
              this.ngRedux.dispatch(this.campaignManagerActions.deleteCampaignError(error));
            },
          );
      }
      return next(action);
    };
  }
}
