import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { AlertGroup, AlertIssue, AlertTemplate, BEAlert, ProductAlerts } from '@app/shared/data/alert/alert.models';
import { AlertsService } from '@app/client/alerts/alerts.service';

@Injectable()
export class AlertActions extends PaginationActions {

  static readonly TYPES = {
    LOAD_MODAL_DATA: '[ALERT]LOAD_MODAL_DATA',
    SET_MODAL_DATA: '[ALERT]SET_MODAL_DATA',
    SET_MODAL_DATA_FAILED: '[ALERT]SET_MODAL_DATA_FAILED',
    CLEAR_MODAL_DATA: '[ALERT]CLEAR_MODAL_DATA',
    SET_ALERTS: '[ALERT]SET_ALERTS',
    RESET_ALERTS: '[ALERT]RESET_ALERTS',
    UPDATE_ALERTS: '[ALERT]UPDATE_ALERTS',
    LOAD_PRODUCTS_DATA: '[ALERT]LOAD_PRODUCTS_DATA',
    SAVE: '[ALERT]SAVE',
    SAVE_SUCCESS: '[ALERT]SAVE_SUCCESS',
    SAVE_FAILED: '[ALERT]SAVE_FAILED',
    REMOVE: '[ALERT]REMOVE',
    REMOVE_SUCCESS: '[ALERT]REMOVE_SUCCESS',
    REMOVE_FAILED: '[ALERT]REMOVE_FAILED',
    REMOVE_FOR_ASIN: '[ALERT]REMOVE_FOR_ASIN',
    REMOVE_ALL: '[ALERT]REMOVE_ALL',
    ACKNOWLEDGE_ISSUE: '[ALERT]ACKNOWLEDGE_ISSUE',
    ACKNOWLEDGE_ISSUE_SUCCESS: '[ALERT]ACKNOWLEDGE_ISSUE_SUCCESS',
    ACKNOWLEDGE_ISSUE_FAILED: '[ALERT]ACKNOWLEDGE_ISSUE_FAILED',
    ACKNOWLEDGE_ISSUES_FOR_PRODUCT: '[ALERT]ACKNOWLEDGE_ISSUES_FOR_PRODUCT',
    ACKNOWLEDGE_ISSUES: '[ALERT]ACKNOWLEDGE_ISSUES',
    ACKNOWLEDGE_ISSUES_SUCCESS: '[ALERT]ACKNOWLEDGE_ISSUES_SUCCESS',
    ACKNOWLEDGE_ISSUES_FAILED: '[ALERT]ACKNOWLEDGE_ISSUES_FAILED',
    TEMPLATE_SAVE: '[ALERT]TEMPLATE_SAVE',
    TEMPLATE_SAVE_SUCCESS: '[ALERT]TEMPLATE_SAVE_SUCCESS',
    TEMPLATE_SAVE_FAILED: '[ALERT]TEMPLATE_SAVE_FAILED',
  };

  protected model = ProductAlerts;

  setAlerts(results): StAction {
    return {
      type: AlertActions.TYPES.SET_ALERTS,
      data: results,
    };
  }

  resetAlerts(): StAction {
    return {
      type: AlertActions.TYPES.RESET_ALERTS,
      data: {},
    };
  }

  updateAlerts(results): StAction {
    return {
      type: AlertActions.TYPES.UPDATE_ALERTS,
      data: results,
    };
  }

  loadProductsData(products: ProductAlerts[]): StAction {
    return {
      type: AlertActions.TYPES.LOAD_PRODUCTS_DATA,
      data: {
        products: products,
      },
    };
  }

  save(subscription: AlertGroup, source: string): StAction {
    return {
      type: AlertActions.TYPES.SAVE,
      data: {
        subscription: subscription,
        source: source,
      },
    };
  }

  saveSuccess(): StAction {
    return {
      type: AlertActions.TYPES.SAVE_SUCCESS,
      data: {},
    };
  }

  saveFailed(error: any) {
    return {
      type: AlertActions.TYPES.SAVE_FAILED,
      data: error,
    };
  }

  remove(alert: BEAlert, asin: string = null) {
    return {
      type: AlertActions.TYPES.REMOVE,
      data: {
        alert: alert,
        asin: asin,
      },
    };
  }

  removeSuccess(): StAction {
    return {
      type: AlertActions.TYPES.REMOVE_SUCCESS,
      data: {},
    };
  }

  removeFailed(error: any) {
    return {
      type: AlertActions.TYPES.REMOVE_FAILED,
      data: error,
    };
  }

  removeAll() {
    return {
      type: AlertActions.TYPES.REMOVE_ALL,
      data: {
        source: AlertsService.productSource,
      },
    };
  }

  removeForAsin(asin: string) {
    return {
      type: AlertActions.TYPES.REMOVE_FOR_ASIN,
      data: {
        source: AlertsService.productSource,
        asin: asin,
      },
    };
  }

  loadModalData(asin: string): StAction {
    return {
      type: AlertActions.TYPES.LOAD_MODAL_DATA,
      data: {
        asin: asin,
      },
    };
  }

  setModalData(alertGroup: AlertGroup): StAction {
    return {
      type: AlertActions.TYPES.SET_MODAL_DATA,
      data: {
        subscription: alertGroup,
      },
    };
  }

  setModalDataFailed(error: any): StAction {
    return {
      type: AlertActions.TYPES.SET_MODAL_DATA_FAILED,
      data: {
        error: error,
      },
    };
  }

  clearModalData(): StAction {
    return {
      type: AlertActions.TYPES.CLEAR_MODAL_DATA,
      data: null,
    };
  }

  acknowledgeIssue(issue: AlertIssue): StAction {
    return {
      type: AlertActions.TYPES.ACKNOWLEDGE_ISSUE,
      data: {
        issue: issue,
      },
    };
  }

  acknowledgedIssueSucceeded(issue: AlertIssue): StAction {
    return {
      type: AlertActions.TYPES.ACKNOWLEDGE_ISSUE_SUCCESS,
      data: {
        issue: issue,
      },
    };
  }

  acknowledgedIssueFailed(error: any): StAction {
    return {
      type: AlertActions.TYPES.ACKNOWLEDGE_ISSUE_FAILED,
      data: {
        error: error,
      },
    };
  }

  acknowledgeIssuesForProduct(asin: string): StAction {
    return {
      type: AlertActions.TYPES.ACKNOWLEDGE_ISSUES_FOR_PRODUCT,
      data: {
        asin: asin,
        source: AlertsService.productSource,
      },
    };
  }

  acknowledgeIssues(): StAction {
    return {
      type: AlertActions.TYPES.ACKNOWLEDGE_ISSUES,
      data: {
        source: AlertsService.productSource,
      },
    };
  }

  acknowledgedIssuesSucceeded(): StAction {
    return {
      type: AlertActions.TYPES.ACKNOWLEDGE_ISSUES_SUCCESS,
      data: {},
    };
  }

  acknowledgedIssuesFailed(error: any): StAction {
    return {
      type: AlertActions.TYPES.ACKNOWLEDGE_ISSUES_FAILED,
      data: {
        error: error,
      },
    };
  }

  saveTemplate(template: AlertTemplate): StAction {
    return {
      type: AlertActions.TYPES.TEMPLATE_SAVE,
      data: {
        template: template,
      },
    };
  }

  saveTemplateSuccess(): StAction {
    return {
      type: AlertActions.TYPES.TEMPLATE_SAVE_SUCCESS,
      data: {},
    };
  }

  saveTemplateFailed(error: any) {
    return {
      type: AlertActions.TYPES.TEMPLATE_SAVE_FAILED,
      data: error,
    };
  }
}
