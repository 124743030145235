import { PipeTransform, Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'stAbsNumber'
})
@Injectable()
export class AbsNumberPipe implements PipeTransform {
  transform(value: number): number {
    if (isNaN(value)) {
      return value;
    }

    return Math.abs(value);
  }
}
