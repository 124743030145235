import { StAction } from '@app/shared/data/st-action';
import { Action } from 'redux';
import { Injectable } from '@angular/core';

@Injectable()
export class BrowseProductsActions {
  static readonly _events = {
    browse_products_finished: 'competition_research_product_search:finished'
  };

  static readonly TYPES = {
    BROWSE_BY_KEYWORD: '[BROWSE_PRODUCTS]BROWSE_BY_KEYWORD',
    BROWSE_BY_ASINS: '[BROWSE_PRODUCTS]BROWSE_BY_ASINS',
    BROWSE_PRODUCTS_ERROR: '[BROWSE_PRODUCTS]ERROR',
    BROWSE_PRODUCTS_CLEAR: '[BROWSE_PRODUCTS]CLEAR',
  };

  browseByAsins(asins: string[] = null): StAction {
    return {
      type: BrowseProductsActions.TYPES.BROWSE_BY_ASINS,
      data: {asins}
    };
  }

  browseByKeyword(keyword: string): StAction {
    return {
      type: BrowseProductsActions.TYPES.BROWSE_BY_KEYWORD,
      data: {keyword}
    };
  }

  clearSearch(): Action {
    return {
      type: BrowseProductsActions.TYPES.BROWSE_PRODUCTS_CLEAR,
    };
  }

  searchCompetitorsProductsError(error: any): StAction {
    return {
      type: BrowseProductsActions.TYPES.BROWSE_PRODUCTS_ERROR,
      data: {error}
    };
  }

}
