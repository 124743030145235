import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '@app/shared/data/base.models';
import { SystemSettings } from '@app/shared/data/admin/system-settings/system-settings.models';
import { SystemSettingsActions } from '@app/shared/data/admin/system-settings/system-settings.actions';

const INITIAL_STATE: AppState = {
  data   : new Collection<SystemSettings>([]),
  loading: false,
  saving: false,
  error  : null,
};

export function systemSettingsReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == SystemSettingsActions.TYPES.GET) {
    return {
      loading: true,
      data: null,
      error  : null,
      saving: false,
    };
  }

  if (action.type == SystemSettingsActions.TYPES.GET_SUCCEEDED) {
    return {
      data: action.data,
      loading: false,
      error  : null,
      saving: false,
    };
  }

  if (action.type == SystemSettingsActions.TYPES.GET_FAILED) {
    return {
      data: null,
      loading: false,
      error  : action.data,
      saving: false,
    };
  }

  if (action.type == SystemSettingsActions.TYPES.SAVE) {
    return {
      ...state,
      saving: true,
    };
  }

  if (action.type == SystemSettingsActions.TYPES.SAVE_FAILED) {
    return {
      ...state,
      saving: false
    };
  }

  if (action.type == SystemSettingsActions.TYPES.SAVE_SUCCEEDED) {
    return {
      ...state,
      data: action.data,
      saving: false
    };
  }

  return state
}
