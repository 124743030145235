import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Collection } from "@app/shared/data/base.models"
import { LastLaunchActions } from "@app/shared/data/admin/last-launch/last-launch.actions";
import { LastLaunchAPI } from "@app/shared/data/admin/last-launch/last-launch.api";
import {
  LastLaunchAdminPromotion,
  LastLaunchAdminKeyword,
  LastLaunchAdminSlot,
  LastLaunchAdminProduct,
  LastLaunchAdminCoupon,
  LastLaunchAdminScheduledEmail, LastLaunchAdminNote
} from "@app/shared/data/admin/last-launch/last-launch.models";
import { Credential } from "@app/shared/data/credential/credential.models";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";
import { UserAdminAPI } from "@app/shared/data/admin/user/user-admin.api";
import { LastLaunchScheduleAPI } from "@app/shared/data/last-launch-schedule/last-launch-schedule.api";
import { LastLaunchScheduleProduct } from "@app/shared/data/last-launch-schedule/last-launch-schedule.models";

@Injectable()
export class LastLaunchEpics {
  constructor(private lastLaunchActions: LastLaunchActions,
              private store: NgRedux<AppState>,
              private userAdminAPI: UserAdminAPI,
              private lastLaunchScheduleAPI: LastLaunchScheduleAPI,
              private lastLaunchAPI: LastLaunchAPI) {
  }

  public createEpic() {
    return [
      this.loadPage,
      this.save,
      this.remove,
      this.setFullPromotion,
      this.loadCalendar,
      this.loadScheduledEmails,
      this.loadScheduledEmail,
      this.saveScheduledEmail,
      this.saveCalendarSlot,
      this.loadUsers,
      this.searchProduct,
      this.saveNote,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      let n = next(action);
      if (action.type == PaginationActions.getPagActionTypes(LastLaunchAdminPromotion).LOAD_PAGE || action.type == LastLaunchActions.TYPES.FILTER) {
        let state = store.getState()['admin']['last_launch'];
        let params = {
          ...state['_be_pagination'],
          ...state['_filters'],
          sort: state['_be_pagination']['sort'].join(',')
        };

        this.lastLaunchAPI.all(params)
          .subscribe(
            (promotions: Collection<LastLaunchAdminPromotion>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.setPage(promotions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.loadPageFailed(response.error));
            },
            () => {
            }
          );
      }
      return n;
    }
  };

  save = (store: NgRedux<AppState>) => next => {
    return (action) => {
      let n = next(action);
      if (action.type == LastLaunchActions.TYPES.SAVE) {
        this.lastLaunchAPI.save(action.data)
          .subscribe(
            (promotion: LastLaunchAdminPromotion) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.saveSucceeded(promotion));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.saveFailed(response.error.error));
            },
            () => {
            }
          );
      }
      return n;
    }
  };

  remove = (store: NgRedux<AppState>) => next => {
    return (action) => {
      let n = next(action);
      if (action.type == LastLaunchActions.TYPES.REMOVE) {
        this.lastLaunchAPI.remove(action.data.promotion)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.removeSucceeded(action.data.promotion));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.removeFailed(response.error));
            },
            () => {
            }
          );
      }
      return n;
    }
  };

  setFullPromotion = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.SET_FULL_PROMOTION) {
        let r = next(action);
        if (action.data.id == 'new') {
          let promotion: LastLaunchAdminPromotion = new LastLaunchAdminPromotion();

          promotion.keywords = LastLaunchAdminKeyword.collect<LastLaunchAdminKeyword>([]);
          promotion.user = new UserAdmin();
          promotion.credential = new Credential();
          promotion.product = new LastLaunchAdminProduct();
          promotion.coupons = LastLaunchAdminCoupon.collect<LastLaunchAdminCoupon>([]);
          promotion.notes = LastLaunchAdminNote.collect<LastLaunchAdminNote>([]);

          //noinspection TypeScriptValidateTypes
          this.store.dispatch(this.lastLaunchActions.setFullPromotionSucceeded(promotion));
        } else {
          this.lastLaunchAPI.get(action.data.id)
            .subscribe(
              (promotion: LastLaunchAdminPromotion) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.lastLaunchActions.setFullPromotionSucceeded(promotion));
              },
              (response) => {
                console.log("response", response);
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.lastLaunchActions.setFullPromotionFailed(response.error));
              }
            );
        }
        return r;
      }
      return next(action);
    }
  };

  loadCalendar = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.LOAD_CALENDAR) {
        this.lastLaunchAPI.getCalendarSlots(action.data.from, action.data.to)
          .subscribe(
            (slots: Collection<LastLaunchAdminSlot>) => {
              this.store.dispatch(this.lastLaunchActions.loadCalendarSucceeded(slots));
            },
            (response) => {
              this.store.dispatch(this.lastLaunchActions.loadCalendarFailed(response.error));
            }
          )
      }
      return next(action);
    }
  };

  loadScheduledEmails = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAILS) {
        this.lastLaunchAPI.getScheduledEmails(action.data.from, action.data.to, action.data.type)
          .subscribe(
            (scheduledEmails: Collection<LastLaunchAdminScheduledEmail>) => {
              this.store.dispatch(this.lastLaunchActions.loadScheduledEmailsSucceeded(scheduledEmails));
            },
            (response) => {
              this.store.dispatch(this.lastLaunchActions.loadScheduledEmailsFailed(response.error));
            }
          )
      }
      return next(action);
    }
  };

  loadScheduledEmail = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.LOAD_SCHEDULED_EMAIL) {
        let r = next(action);
        if (action.data.id == 'new') {
          let scheduledEmail: LastLaunchAdminScheduledEmail = new LastLaunchAdminScheduledEmail();
          scheduledEmail.date = new Date().toISOString();

          //noinspection TypeScriptValidateTypes
          this.store.dispatch(this.lastLaunchActions.loadScheduledEmailSucceeded(scheduledEmail));
        } else {
          this.lastLaunchAPI.getScheduledEmail(action.data.id)
            .subscribe(
              (scheduledEmail: LastLaunchAdminScheduledEmail) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.lastLaunchActions.loadScheduledEmailSucceeded(scheduledEmail));
              },
              (response) => {
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.lastLaunchActions.loadScheduledEmailFailed(response.error));
              }
            );
        }
        return r;
      }
      return next(action);
    }
  };

  saveScheduledEmail = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.SAVE_SCHEDULED_EMAIL) {
        let r = next(action);
        this.lastLaunchAPI.saveScheduledEmail(action.data.scheduledEmail)
          .subscribe(
            (scheduledEmail: LastLaunchAdminScheduledEmail) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.saveScheduledEmailSucceeded(scheduledEmail));
            },
            (response) => {
              console.log("response", response);
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.lastLaunchActions.saveScheduledEmailFailed(response.error));
            }
          );
        return r;
      }
      return next(action);
    }
  };

  saveCalendarSlot = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.SAVE_CALENDAR_SLOT) {
        this.lastLaunchAPI.saveCalendarSlot(action.data.date, action.data.available)
          .subscribe(
            (slot: LastLaunchAdminSlot) => {
              this.store.dispatch(this.lastLaunchActions.saveCalendarSlotSucceeded(slot));
            },
            (response) => {
              this.store.dispatch(this.lastLaunchActions.saveCalendarSlotFailed(response.error));
            }
          )
      }
      return next(action);
    }
  };

  loadUsers = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.LOAD_USERS) {
        this.userAdminAPI.all({ searchTerm: action.data.email, 'load[]': 'credentials' })
          .subscribe(
            (users: Collection<UserAdmin>) => {
              this.store.dispatch(this.lastLaunchActions.loadUsersSucceeded(users));
            },
            (response) => {
              this.store.dispatch(this.lastLaunchActions.loadUsersFailed(response.error));
            }
          )
      }
      return next(action);
    }
  };

  searchProduct = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.SEARCH_PRODUCT) {
        this.lastLaunchScheduleAPI.searchProducts(action.data.promotionId, action.data.term)
          .subscribe(
            (products: Collection<LastLaunchScheduleProduct>) => {
              this.store.dispatch(this.lastLaunchActions.searchProductSucceeded(new LastLaunchAdminProduct(products.first().getData())));
            },
            (error) => {
              this.store.dispatch(this.lastLaunchActions.searchProductFailed(error));
            }
          )
      }
      return next(action);
    }
  };

  saveNote = store => next => {
    return (action) => {
      if (action.type == LastLaunchActions.TYPES.ADD_NOTE) {
        this.lastLaunchAPI.addNote(action.data.promotionId, action.data.note)
          .subscribe(
            (note: LastLaunchAdminNote) => {
              this.store.dispatch(this.lastLaunchActions.addNoteSucceeded(action.data.promotionId, note));
            },
            (error) => {
              this.store.dispatch(this.lastLaunchActions.addNoteFailed(error));
            }
          )
      }
      return next(action);
    }
  }
}
