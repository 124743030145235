import { FullStoryService } from '@app/core/services/full-story.service';
import { AppState } from '@app/shared/data/app-state.model';
import { User } from '@app/shared/data/user/user.models';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { StAction } from '@app/shared/data/st-action';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { LocalStorageService } from '@app/shared/services/local-storage.service';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { UserAdminActions } from '@app/shared/data/admin/user/user-admin.actions';
import { timezones } from '@app/shared/data/timezones';
import { Credential } from 'app/shared/data/credential/credential.models';
import { SentryTrackingService } from '@app/shared/tracking/sentry.service';

const emptyUserOrUserFromStorage = () => {
  try {
    const userLS = LocalStorageService.getUser();
    if (userLS !== null) {
      FullStoryService.identify(userLS);
      return userLS;
    }
  } catch (error) {
    console.log('UserReducer: LocalStorage error', error);
  }
  return new User();
};

const user = emptyUserOrUserFromStorage();

if (user) {
  (new SentryTrackingService()).identifyUser(user);
}

export interface IUserState extends AppState {
  data: User,
  trialAccountTimeLeft: any,
  api_token: null | string,
  forms: {
    'credit-card-sign-up': {
      cardholderName: string;
      cardNumber: string;
      expiryMonth: string;
      expiryYear: string;
      cvc: string;
      coupon_code: Coupon;
      plan?: any;
    },
    'basic-profile': {
      first_name: string;
      last_name: string;
      business_name: string;
      email_address: string;
      phone_number: string;
      timezone: {
        id: number,
        value: string;
        label: string;
      };
      _error: any
    },
    'password-profile': {
      current_password: string;
      password: string;
      password_confirmation: string;
      _error: string;
    },
    'paypal-email': {
      paypal_email: string;
    }
  },
  _error: any,
  _is_updating: boolean;
  _is_refreshing_token: boolean;
  _is_refreshing_api_token: boolean;
  _is_attempting_auth: boolean;
  _is_attempting_registration: boolean;
  _is_attempting_logout: boolean;
  _is_switching_credential: boolean;
  _is_adding_credit_card: boolean;
  _is_subscribing_to_plan: boolean;
  _is_adding_credential: boolean;
  _is_updating_credential: boolean;
  _is_removing_credential: boolean;
  _is_getting_credential_limits: boolean;
}

const INITIAL_STATE: IUserState = {
  data: user,
  trialAccountTimeLeft: null,
  api_token: null,
  forms: {
    'credit-card-sign-up': {
      cardholderName: '',
      cardNumber: '',
      expiryMonth: '',
      expiryYear: '',
      cvc: '',
      coupon_code: new Coupon(),
    },
    'basic-profile': {
      first_name: user.first_name,
      last_name: user.last_name,
      business_name: user.business_name,
      email_address: user.email,
      phone_number: user.phone_number,
      timezone: timezones.find(t => t.id === user.timezone),
      _error: ''
    },
    'password-profile': {
      current_password: '',
      password: '',
      password_confirmation: '',
      _error: ''
    },
    'paypal-email': {
      paypal_email: user.paypal_email || '',
    }
  },
  _error: null,
  _is_updating: false,
  _is_refreshing_token: false,
  _is_refreshing_api_token: false,
  _is_attempting_auth: false,
  _is_attempting_registration: false,
  _is_attempting_logout: false,
  _is_switching_credential: false,
  _is_adding_credit_card: false,
  _is_subscribing_to_plan: false,
  _is_adding_credential: false,
  _is_updating_credential: false,
  _is_removing_credential: false,
  _is_getting_credential_limits: false
};

export function userReducer(state: IUserState = INITIAL_STATE, action: StAction): IUserState {
  if (action.type === UsersActions.TYPES.OVERRIDE) {
    return {
      ...state,
      ...{ data: action.data },
    };
  }

  if (action.type === UsersActions.TYPES.REFRESH_TOKEN) {
    return {
      ...state,
      _is_refreshing_token: true,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.REFRESH_TOKEN_SUCCEEDED) {
    return {
      ...state,
      ...{ data: action.data },
      _is_refreshing_token: false,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.REFRESH_TOKEN_FAILED) {
    console.log('Refresh user token error:', action.data);

    const userWithToken = state['data'].withToken(null);

    return {
      ...state,
      ...{ data: userWithToken },
      _is_refreshing_token: false,
      _error: action.data,
    };
  }

  if (action.type === UsersActions.TYPES.GET_API_TOKEN) {
    return {
      ...state,
      api_token: null,
      _is_refreshing_api_token: true,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.GET_API_TOKEN_SUCCEEDED) {
    return {
      ...state,
      api_token: action.data.api_token,
      _is_refreshing_api_token: false,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.GET_API_TOKEN_FAILED) {
    return {
      ...state,
      _is_refreshing_token: false,
      _error: action.data,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_AUTH) {
    return {
      ...state,
      _is_attempting_auth: true,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_AUTH_SUCCEEDED) {
    FullStoryService.identify(action.data);
    return {
      ...state,
      ...{ data: action.data },
      _is_attempting_auth: false,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_AUTH_FAILED) {

    return {
      ...state,
      _is_attempting_auth: false,
      _error: action.data,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_REGISTRATION) {
    return {
      ...state,
      _is_attempting_registration: true,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_REGISTRATION_SUCCEEDED) {
    return {
      ...state,
      ...{ data: action.data },
      forms: {
        ...state['forms'],
        'paypal-email': {
          paypal_email: action.data.paypal_email || '',
        },
      },
      _is_attempting_registration: false,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_REGISTRATION_FAILED) {
    console.log('Registration Attempt Failed with error:', action.data);

    return {
      ...state,
      _is_attempting_registration: false,
      _error: action.data,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_LOGOUT) {
    return {
      ...state,
      _is_attempting_logout: true,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.ATTEMPT_LOGOUT_SUCCEEDED) {
    return {
      ...state,
      _is_attempting_logout: false,
      data: action.data,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.SWITCH_ACTIVE_CREDENTIAL) {
    return {
      ...state,
      _is_switching_credential: true,
      _error: action.data,
    };
  }

  if (action.type === UsersActions.TYPES.SWITCH_ACTIVE_CREDENTIAL_SUCCEEDED) {
    return {
      ...state,
      ... { data: action.data },
      _is_switching_credential: false,
      _error: null,
    };
  }

  if (action.type === UsersActions.TYPES.SWITCH_ACTIVE_CREDENTIAL_FAILED) {
    return {
      ...state,
      _is_switching_credential: false,
      _error: action.data,
    };
  }

  if (action.type === UsersActions.TYPES.ADD_CREDIT_CARD_STARTED) {
    return {
      ...state,
      _is_adding_credit_card: true,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.ADD_CREDIT_CARD_SUCCEEDED) {
    return {
      ...state,
      _is_adding_credit_card: false,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.ADD_CREDIT_CARD_FAILED) {
    return {
      ...state,
      _is_adding_credit_card: false,
      _error: action.data
    };
  }

  if (action.type === SubscriptionActions.TYPES.CREATE) {
    return {
      ...state,
      _is_subscribing_to_plan: true,
      _error: null
    };
  }

  if (action.type === SubscriptionActions.TYPES.CREATE_SUCCEEDED) {
    return {
      ...state,
      ...{ data: state['data'].finishRegistration() },
      _is_subscribing_to_plan: false,
      _error: null
    };
  }

  if (action.type === SubscriptionActions.TYPES.CREATE_FAILED) {
    console.log('Subscribing To Plan failed with error:', action.data);

    return {
      ...state,
      _is_subscribing_to_plan: false,
      _error: action.data
    };
  }

  if (action.type === UsersActions.TYPES.REGISTRATION_SET_SELECTED_PLAN) {
    return {
      ...state,
      forms: {
        ...state['forms'],
        'credit-card-sign-up': {
          ...state['forms']['credit-card-sign-up'],
          plan: action.data.plan
        }
      }
    };
  }

  if (action.type === UsersActions.TYPES.REGISTRATION_SET_COUPON_CODE) {
    return {
      ...state,
      forms: {
        ...state['forms'],
        'credit-card-sign-up': {
          ...state['forms']['credit-card-sign-up'],
          coupon_code: action.data.coupon_code
        }
      }
    };
  }

  if (action.type === UsersActions.TYPES.UPDATE_PROFILE) {
    return {
      ...state,
      _is_updating: true,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.UPDATE_PROFILE_SUCCEEDED) {
    const newUser = state['data'].clone(User, action.data.user.getData([
      'business_name',
      'first_name',
      'last_name',
      'phone_number',
      'email',
      'paypal_email',
      'timezone',
      'is_verified',
      'last_version_checked',
      'billing_information',
      'token',
    ]));

    if (action.data.type === 'basic_information') {
      return {
        ...state,
        ...{ data: newUser },
        forms: {
          ...state['forms'],
          'basic-profile': {
            ...state['forms']['basic-profile'],
            _error: ''
          }
        },
        _is_updating: false,
        _error: null
      };
    }

    if (action.data.type === 'password_update') {

      return {
        ...state,
        ...{ data: newUser },
        forms: {
          ...state['forms'],
          'password-profile': {
            ...state['forms']['password-profile'],
            _error: ''
          }
        },
        _is_refreshing_token: false,
        _is_updating: false,
        _error: null
      };
    }

    if (action.data.type === 'version_checked') {
      newUser.last_unread_app_update = true;

      return {
        ...state,
        data: newUser,
        _is_updating: false,
        _error: null
      };
    }

    return {
      ...state,
      ...{ data: newUser },
      _is_updating: false,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.UPDATE_PROFILE_FAILED) {
    console.log('Updating Profile failed with error:', action.data.error);

    if (action.data.type === 'basic_information') {
      return {
        ...state,
        forms: {
          ...state['forms'],
          'basic-profile': {
            ...state['forms']['basic-profile'],
            _error: action.data.error
          }
        },
        _is_updating: false,
      };
    }

    if (action.data.type === 'password_update') {
      return {
        ...state,
        forms: {
          ...state['forms'],
          'password-profile': {
            ...state['forms']['password-profile'],
            _error: action.data.error
          }
        },
        _is_updating: false,
      };
    }

    return {
      ...state,
      _is_updating: false,
      _error: action.data.error.error
    };
  }

  if (action.type === UsersActions.TYPES.ADD_CREDENTIAL) {
    return {
      ...state,
      _is_adding_credential: true,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.ADD_CREDENTIAL_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].pushCredential(action.data),
      _is_adding_credential: false,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.ADD_CREDENTIAL_FAILED) {
    return {
      ...state,
      _is_adding_credential: false,
      _error: action.data
    };
  }

  if (action.type === UsersActions.TYPES.UPDATE_CREDENTIAL) {
    return {
      ...state,
      _is_updating_credential: true,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.TRIAL_ACCOUNT_CHANGE_COUPON) {
    return {
      ...state,
      trialCouponCode: action.data
    };
  }

  if (action.type === UsersActions.TYPES.UPDATE_CREDENTIAL_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].updateCredential(action.data.credential),
      _is_updating_credential: false,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.UPDATE_CREDENTIAL_FAILED) {
    return {
      ...state,
      _is_updating_credential: false,
      _error: action.data
    };
  }

  if (action.type === UsersActions.TYPES.REMOVE_CREDENTIAL) {
    return {
      ...state,
      _is_removing_credential: true,
      _error: null
    };
  }


  if (action.type === UsersActions.TYPES.REMOVE_CREDENTIAL_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].removeCredential(action.data),
      _is_removing_credential: false,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.REMOVE_CREDENTIAL_FAILED) {
    return {
      ...state,
      _is_removing_credential: false,
      _error: action.data
    };
  }

  if (action.type === UsersActions.TYPES.GET_CREDENTIAL_LIMITS) {
    return {
      ...state,
      _is_getting_credential_limits: true,
      _error: null
    };
  }

  if (action.type === UsersActions.TYPES.GET_CREDENTIAL_LIMITS_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].updateCredentialLimits(action.data.credential, action.data.limits),
      _is_getting_credential_limits: false,
    };
  }

  if (action.type === UsersActions.TYPES.GET_CREDENTIAL_LIMITS_FAILED) {
    return {
      ...state,
      _error: action.data,
      _is_getting_credential_limits: false,
    };
  }

  if (action.type === UserAdminActions.TYPES.IMPERSONATE_SUCCEEDED) {
    return {
      ...state,
      data: action.data.user.setIsImpersonating(),
    };
  }

  if (action.type === UsersActions.TYPES.VERIFICATION_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].setIsVerified(),
    };
  }

  if (action.type === UsersActions.EVENTS.WALLET_BALANCE_HAS_CHANGED) {
    return {
      ...state,
      data: state['data'].setWalletBalance(action.data['balance'] / 100),
    };
  }

  if (action.type === UsersActions.TYPES.TRIAL_ACCOUNT_TIME_LEFT_SUCCEEDED) {
    return {
      ...state,
      trialAccountTimeLeft: action.data
    };
  }

  if (action.type === UsersActions.TYPES.UPDATE_CARD_PARAMETER_MISSING) {
    return {
      ...state,
      _error: action.data
    };
  }

  if (action.type === UsersActions.TYPES.SET_PROFILE_FORM) {
    return {
      ...state,
      forms: {
        ...state['forms'],
        'basic-profile': {
          ...state['forms']['basic-profile'],
          first_name: action.data.first_name,
          last_name: action.data.last_name,
          business_name: action.data.business_name,
          email_address: action.data.email,
          phone_number: action.data.phone_number,
          _error: ''
        }
      },
    };
  }

  if (action.type === UsersActions.TYPES.SET_COACHING_OFFER_STATE) {
    return {
      ...state,
      data: state['data'].setCoachingOfferState(action.data)
    };
  }

  if (action.type === UsersActions.TYPES.CREDENTIAL_SP_API_CONNECTED) {
    const credential = state.data.allCredentials.find((credential: Credential) => credential.id === state.data.active_credential);
    credential.is_spa_connected = true;

    return {
      ...state,
      data: state['data'].updateCredential(credential)
    }
  }

  return state;
}
