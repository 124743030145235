import { Component} from '@angular/core';

@Component({
  selector : 'st-access-academy',
  template : `
    <div class="access-academy-button">
      <p i18n>Looking for more information on how to use these features? Check out our complete Knowledge Base to get answers to your most important questions!</p>
      <a href="https://help.seller.tools/en/collections/2591770-seller-tools-manychat" target="_blank" i18n>Access Academy</a>
    </div>`,
  styleUrls: ['./access-academy.component.scss'],
})
export class AccessAcademyComponent {}
