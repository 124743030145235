import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import { AffiliateYourCommision } from "@app/shared/data/affiliate/your-commision/your-commision.model";

@Injectable()
export class AffiliateYourCommisionActions extends PaginationActions {
  protected model = AffiliateYourCommision;

  static readonly TYPES = {
    FILTER:    '[AFFILIATEYOURCOMMISION]FILTER',
    COMMISION:    '[AFFILIATEYOURCOMMISION]COMMISION',
  };

  filter(filters): StAction {
    return {
      type: AffiliateYourCommisionActions.TYPES.FILTER,
      data: filters
    }
  }

  commision(commision: AffiliateYourCommision): StAction {
    return {
      type: AffiliateYourCommisionActions.TYPES.COMMISION,
      data: {
        commision: commision,
      }
    }
  }
}
