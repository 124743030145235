import { NgRedux } from '@angular-redux/store';
import * as configcat from 'configcat-js';
import { IConfigCatClient } from 'configcat-common/lib/ConfigCatClient';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { distinctUntilChanged, filter, map, take } from 'rxjs/operators';
import { User as ConfigCatUser } from 'configcat-common/lib/RolloutEvaluator';

import { AppState } from '../../data/app-state.model';
import { User } from '../../data/user/user.models';
import { BehaviorSubject ,  Observable } from 'rxjs';

export enum FeatureFlaggingEnum {
  KEYWORD_WIZARD = 'feature_new_keyword_manager_enabled', // Example
  NEW_R2A = 'feature_new_r2a',
  R2A_HIDE_SPONSORED_DATA = 'feature_temp_r2a_hide_sponsored',
  R2A_SHOW_OEP_DATA = 'feature_temp_r2a_show_oep_data',
  SPA_CONNECTION = 'feature_temp_spa_connection',
  SMARTLOOK_TRACKING = 'feature_smartlook_tracking',
  C6_ATTRIBUTION_SCRIPT = 'feature_temp_c6_attribution_script',
  COUPON_ON_REGISTRATION_PAGE = 'feature_temp_coupon_on_registration_page',
  C6_Q4_PROMOTION = 'feature_temp_force_q4_carbon6_promotion',
  ENABLE_ST_ALERTS = 'feature_temp_enable_st_alerts',
  ENABLE_ST_PPCS = 'feature_temp_enable_ppcs',
  ENABLE_ST_AFFILIATES = 'feature_temp_enable_st_affiliates',
  ENABLE_ST_WALLET = 'feature_temp_enable_st_wallet',
  ENABLE_ST_LAST_LAUNCH = 'feature_temp_enable_st_last_launch',
}

export interface ISetting {
  settingKey: FeatureFlaggingEnum;
  settingValue: boolean;
}

@Injectable()
export class FeatureFlaggingService {
  configCat: IConfigCatClient;
  logger;
  user: User;

  featureFlaggingSubject: BehaviorSubject<ISetting[]|ISetting> = new BehaviorSubject([]);

  constructor(private ngRedux: NgRedux<AppState>) {
    const options: configcat.IJSAutoPollOptions = {
      pollIntervalSeconds: environment.CONFIG_CAT_POLL_INTERVAL,
      logger: this.logger,
      configChanged: () => {
        this.updateData();
      }
    };

    if (!environment.CONFIG_CAT_SDK_KEY) {
      return;
    }

    if (environment.CONFIG_CAT_BASE_URL) {
      options.baseUrl = environment.CONFIG_CAT_BASE_URL;
    }

    this.logger = configcat.createConsoleLogger(environment.CONFIG_CAT_LOGGING_LEVEL);
    this.configCat = configcat.createClientWithAutoPoll(environment.CONFIG_CAT_SDK_KEY, options);

    this.ngRedux.select(['user', 'data'])
      .pipe(
        filter((user: User) => Boolean(user)),
        take(1),
      ).subscribe((user: User) => {
        this.user = user;
        this.updateData();
      });
  }

  private updateData() {
    try {
      this.configCat.getAllValues((settingValues: ISetting[]) => {
        this.featureFlaggingSubject.next(settingValues);
      }, this.configCatUser);
    } catch (error) {
      console.error("update config cat error:", error);
      setTimeout(() => {
        this.updateData();
      }, 1000);
    }
  }

  /**
   * This will return all feature flags
   */
  public getAllData(): Observable<any> {
    return this.featureFlaggingSubject.asObservable();
  }

  /**
   * This will return only the feature flag passed in as a parameter
   * @param flagName FeatureFlaggingEnum
   */
  public getFeatureFlag(flagName: FeatureFlaggingEnum): Observable<ISetting> {
    return this.featureFlaggingSubject.pipe(
      map((settings: any) => {
        return settings.find((setting: ISetting) => setting.settingKey === flagName);
      }),
      distinctUntilChanged(),
    );
  }

  get configCatUser(): ConfigCatUser {
    if (!this.user || !Number.isInteger(this.user.id)) {
      return;
    }

    return new ConfigCatUser(this.user.id.toString(), this.user.email);
  }
}
