import { BaseModel } from "@app/shared/data/base.models";

export class Invitation extends BaseModel {
  id: number;
  email: string;
  first_name?: string;
  last_name?: string;
  business_name?: string;
  token: string;
  invited_at: string;
  accepted_at?: string;
  credential?: {
    id: number,
    name: string,
    owner_id: number,
  };
}
