import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutModule } from "@app/shared/layout/layout.module";
import { TabsComponent } from "@app/shared/components/tabs/tabs.component";
import { TabComponent } from "@app/shared/components/tabs/tab/tab.component";
import { PopoverModule } from "@app/shared/components/popover/popover.module";

@NgModule({
  imports     : [
    CommonModule,
    LayoutModule,
    PopoverModule,
  ],
  declarations: [
    TabComponent,
    TabsComponent,
  ],
  exports     : [
    TabComponent,
    TabsComponent,
  ],
})
export class TabsModule {
}
