import { Component, EventEmitter, Input, Output } from "@angular/core";
import { AuthService } from "@app/core/services/auth.service";
import { getAmazonUrl } from "@app/helpers";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-keyword-r2a-item',
  templateUrl: './keyword-r2a.item.component.html',
  styleUrls: ['./keyword-r2a.item.component.scss']
})
export class KeywordR2AItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() onToggleKeywordDetails: EventEmitter<any> = new EventEmitter();

  constructor(
    private authService: AuthService,
  ) { }

  getAmazonLink(): string {
    return getAmazonUrl(this.item.value.value, this.authService.getActiveCredential().amazon_domain);
  }
}
