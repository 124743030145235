import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { CommissionActions } from "@app/shared/data/commission/commission.actions";
import { CommissionAPI } from "@app/shared/data/commission/commission.api";
import { Commission } from "@app/shared/data/commission/commission.models";

@Injectable()
export class CommissionEpics {
  constructor(private commissionActions: CommissionActions,
              private commissionAPI: CommissionAPI,
              private ngRedux: NgRedux<AppState>) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == CommissionActions.getPagActionTypes(Commission).LOAD_PAGE) {
        const params = {
          ...action.data.pagination,
          limit: action.data.pagination.perPage,
          sort : action.data.pagination.sort.join(',')
        };
        this.commissionAPI.all(params)
          .subscribe(
            (commissions: Collection<Commission>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.commissionActions.setPage(commissions));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.commissionActions.loadPageFailed(response));
            },
            () => {

            }
          );
      }
      return next(action);
    }
  };
}
