export enum InvoicesTypeEnum {
  SUBSCRIPTIONS = 'subscriptions',
  OTHER = 'other',
}

export interface Invoice {
  id: string;
  date: string;
  total: string;
  description: string;
  downloading?: boolean;
}

export interface InvoicesCollection {
  data: Invoice[];
  has_more: boolean;
}
