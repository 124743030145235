import { BaseModel } from "@app/shared/data/base.models";

export class SystemNotificationAdmin extends BaseModel {
  static className = 'SystemNotificationAdmin';

  id: number;
  from: string;
  to: string;
  message: string;
  icon: string;
  url?: string; // external link to status.seller.tools if provided!
  type: string;
  isActive?: boolean;
}
