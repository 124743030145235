import {
  Directive,
  TemplateRef
} from "@angular/core";

@Directive({
  selector: '[no-content]',
})
export class NoContentDirective {
  constructor(public template:TemplateRef<any>) {};
}
