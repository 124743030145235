import { NgRedux } from '@angular-redux/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewRef } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { AppState } from '@app/shared/data/app-state.model';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import {
  IProductManagerOtherProduct,
  IProductManagerProduct,
  IProductManagerState,
  IProductManagerVariation,
} from '@app/shared/data/product-manager/product-manager.models';
import { ButtonIconEnum } from '@app/shared/layout/v2/forms/button/button.component';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import { FilterTypeEnum, IAvailableFilter, IFilter, ISavedFilter, OperatorTranslator, } from '../../common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { SaveFiltersAsViewModalComponent } from '../../common/save-filters-as-view-modal/save-filters-as-view-modal.component';
import { ProductManagerService } from '../product-manager.service';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { ProductManagerAddProductsManuallyComponent } from '../product-list-add-products-manually-modal/product-list-add-products-manually-modal.component';

export enum SortByEnum {
  STATUS = 'status',
  TITLE = 'title',
  RANK = 'sales_rank',
  KEYWORDS = 'keywords',
  PRICE = 'price',
  REVIEWS = 'reviews',
}

export enum SortOrderEnum {
  DESC = '-',
  ASC = '',
}

export class IFieldOrder {
  field: SortByEnum;
  order: SortOrderEnum;
}

export enum ProductListTabEnum {
  MY_PRODUCTS = 'MY_PRODUCTS',
  OTHER_PRODUCTS = 'OTHER_PRODUCTS'
}

@Component({
  selector: 'st-product-manager-product-list',
  templateUrl: './product-manager-product-list.component.html',
  styleUrls: ['./product-manager-product-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductManagerProductListComponent implements OnInit, OnDestroy {
  perPage = this.productManagerService.perPage;
  perPageOptions = this.productManagerService.perPageOptions;
  preSelectedOption = this.productManagerService.preSelectedOption;
  variationPreSelectedOption = this.productManagerService.preSelectedOption;
  currentPage = 1;
  total: number;
  foreignTotal: number;
  pagesArray: number[];
  initDone: boolean;
  from: number;
  to: number;
  lastPage: number;

  variationPerPage = 20;
  variationCurrentPage = 1;
  variationTotal: number;
  variationPagesArray: number[];
  variationFrom: number;
  variationTo: number;
  variationLastPage: number;

  selectedFilters: IFilter[] = [];
  savedFilters: ISavedFilter[] = [];
  availableFilters: IAvailableFilter[] = [];
  loadingAvailableFilters: boolean = false;

  products: IProductManagerProduct[];
  productVariations: IProductManagerVariation[];
  archivedProducts: IProductManagerProduct[];
  loadingProducts = true;
  loadingProductVariations = false;
  selectedProduct: IProductManagerProduct;
  savedViewsOpened = false;

  otherProducts: IProductManagerOtherProduct[];

  searchProductString: string;
  searchQuery$ = new Subject<string>();

  buttonIcons = ButtonIconEnum;
  sortByEnum = SortByEnum;

  defaultSortBy: IFieldOrder[] = [{
    field: SortByEnum.STATUS,
    order: SortOrderEnum.DESC
  }, {
    field: SortByEnum.TITLE,
    order: SortOrderEnum.ASC
  }];
  currentSortBy: IFieldOrder[] = this.defaultSortBy;
  activeTab: ProductListTabEnum = ProductListTabEnum.MY_PRODUCTS;
  tabTypes = ProductListTabEnum;

  bsrGraph: number[][];
  loadingGraphData: boolean;

  otherProductsTabSeen: boolean;

  timerProductSelection: number;

  public lineOptions = {
    marker: {
      enabled: true,
      radius: 3
    }
  };

  private readonly destroy$: Subject<void> = new Subject<void>();
  public syncingProductsWithAmazon: boolean;

  constructor(
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
    private cd: ChangeDetectorRef,
    private productManagerService: ProductManagerService,
    private modalService: ModalService,
    private dialogService: DialogService,
    private i18n: I18n,
    private router: Router,
    private rudderTracking: RudderTrackingService,
    public operatorTranslator: OperatorTranslator,
  ) {
  }

  ngOnInit() {
    this.ngRedux.dispatch(this.productManagerActions.resetState());
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch));
    this.productManagerService.reset();

    this.ngRedux.select('product_manager')
        .pipe(
          takeUntil(this.destroy$),
        )
        .subscribe((productManagerState: IProductManagerState) => {
          this.loadingProducts = productManagerState.loadingProducts;
          this.loadingProductVariations = productManagerState.loadingProductVariations;
          this.productVariations = productManagerState.productVariationsState.products;
          this.products = productManagerState.productsState.products;
          this.otherProducts = productManagerState.productsState.otherProducts;
          this.from = productManagerState.productsState.from;
          this.to = productManagerState.productsState.to;
          this.perPage = productManagerState.productsState.per_page || 20;
          this.currentPage = productManagerState.productsState.current_page || 1;
          this.total = productManagerState.productsState.total;
          this.foreignTotal = productManagerState.productsState.foreign_total;
          this.lastPage = productManagerState.productsState.last_page;
          this.variationPerPage = productManagerState.productVariationsState.per_page || 20;
          this.variationCurrentPage = productManagerState.productVariationsState.current_page || 1;
          this.variationTotal = productManagerState.productVariationsState.total;
          this.variationFrom = productManagerState.productVariationsState.from;
          this.variationTo = productManagerState.productVariationsState.to;
          this.variationLastPage = productManagerState.productVariationsState.last_page;
          this.syncingProductsWithAmazon = productManagerState.syncingProductsWithAmazon;

          this.loadingAvailableFilters = productManagerState.loadingAvailableTagFilters;
          if(productManagerState.availableTagFilters) {
            this.availableFilters = this.mapAvailableFilters(productManagerState.availableTagFilters);
          }

          this.loadingGraphData = productManagerState.loadingGraphData;
          this.bsrGraph = productManagerState.graphData.dataPoints;

          const numberOfPages = Math.ceil((this.isForeignFetch ? this.foreignTotal : this.total) / this.perPage);

          if (!isNaN(numberOfPages)) {
            this.pagesArray = Array(numberOfPages).fill(0).map((_x, i) => i + 1);
          }

          const numberOfVariationPages = Math.ceil(this.variationTotal / this.variationPerPage);

          if (!isNaN(numberOfVariationPages)) {
            this.variationPagesArray = Array(numberOfVariationPages).fill(0).map((_x, i) => i + 1);
          }

          this.timerProductSelection = performance.now();

          if (this.canDetectChanges) {
            this.cd.detectChanges();
          }
        });

    this.searchQuery$
        .pipe(
          debounceTime(500),
          distinctUntilChanged(),
          takeUntil(this.destroy$)
        ).subscribe((value: string) => {
      this.currentPage = 1;
      this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch, value));
    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  setActiveProductTab(tab: ProductListTabEnum) {
    if (this.loadingProducts) {
      return;
    }

    this.activeTab = tab;

    if (this.activeTab === ProductListTabEnum.OTHER_PRODUCTS && !this.otherProductsTabSeen) {
      this.otherProductsTabSeen = true;
    }

    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch));
  }

  addProductsManually() {
    this.modalService.open(ProductManagerAddProductsManuallyComponent)
  }

  searchProducts() {
    this.rudderTracking.trackEvent('Product Manager - Search');
    this.searchQuery$.next(this.searchProductString);
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.initDone = false;
      this.currentPage--;
      this.fetchProductsOnNavigationChange();
    }
  }

  nextPage() {
    if (this.currentPage < this.pagesArray.length - 1) {
      this.initDone = false;
      this.currentPage++;
      this.fetchProductsOnNavigationChange();
    }
  }

  goToFirstPage() {
    this.currentPage = 1;
    this.initDone = false;
    this.fetchProductsOnNavigationChange();
  }

  goToLastPage() {
    this.currentPage = this.lastPage;
    this.initDone = false;
    this.fetchProductsOnNavigationChange();
  }

  changePage(page: number) {
    this.initDone = false;
    this.currentPage = page;
    this.fetchProductsOnNavigationChange();
  }

  variationPreviousPage(product: IProductManagerProduct) {
    if (this.variationCurrentPage > 1) {
      this.variationCurrentPage--;
      this.fetchVariationProductsOnNavigationChange(product);
    }
  }

  variationNextPage(product: IProductManagerProduct) {
    if (this.variationCurrentPage < this.variationPagesArray.length) {
      this.variationCurrentPage++;
      this.fetchVariationProductsOnNavigationChange(product);
    }
  }

  variationGoToFirstPage(product: IProductManagerProduct) {
    this.variationCurrentPage = 1;
    this.fetchVariationProductsOnNavigationChange(product);
  }

  variationGoToLastPage(product: IProductManagerProduct) {
    this.variationCurrentPage = this.variationLastPage;
    this.fetchVariationProductsOnNavigationChange(product);
  }

  variationChangePage(event: { page: number, product: IProductManagerProduct }) {
    this.variationCurrentPage = event.page;
    this.fetchVariationProductsOnNavigationChange(event.product);
  }

  fetchVariationProductsOnNavigationChange(product: IProductManagerProduct) {
    this.ngRedux.dispatch(this.productManagerActions.getProductVariations(product.asin, this.variationCurrentPage, this.variationPerPage));
  }

  fetchProductsOnNavigationChange() {
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch));
  }

  selectProduct(product: IProductManagerProduct) {
    this.selectedProduct = product;
    this.ngRedux.dispatch(this.productManagerActions.setActiveProduct(product));
    this.rudderTracking.trackEvent('Product Manager - Selecting product', {
      time: Math.trunc(performance.now() - this.timerProductSelection)
    });
    if (this.selectedProduct.keywords_count) {
      this.router.navigate(['/product-manager', 'keyword-manager', product.asin]);
    } else {
      this.router.navigate(['/product-manager', 'competitors', product.asin]);
    }
  }

  sortBy(field: SortByEnum) {
    const existing = this.currentSortBy.find((filedOrder: IFieldOrder) => filedOrder.field === field);

    // if field is already on the order list, toggle the sorting
    if (existing !== undefined) {
      existing.order = existing.order === SortOrderEnum.DESC ? SortOrderEnum.ASC : SortOrderEnum.DESC;
    } else {
      this.currentSortBy = [{
        field: SortByEnum.STATUS,
        order: SortOrderEnum.DESC
      }, {
        field,
        order: SortOrderEnum.ASC,
      }];
    }

    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch));
  }

  toggleProductDetails(event: IProductManagerProduct & { tab: 'graph'|'details' }) {
    this.ngRedux.dispatch(this.productManagerActions.toggleProduct(event.asin));

    if (event.detailsOpened) {
      return;
    }

    if (event.tab === 'details') {
      this.ngRedux.dispatch(this.productManagerActions.setActiveTab('details', event.asin));
      this.ngRedux.dispatch(this.productManagerActions.getProductVariations(event.asin, this.variationCurrentPage, this.variationPerPage));
    } else if (event.tab === 'graph') {
      this.ngRedux.dispatch(this.productManagerActions.setActiveTab('graph', event.asin));
      this.ngRedux.dispatch(this.productManagerActions.getGraphData(event.asin, ['bsr']));
    }
  }

  toggleSavedViewsModal() {
    this.savedViewsOpened = !this.savedViewsOpened;
  }

  setFilters(filters: IFilter[]) {
    this.selectedFilters = filters;
    this.getProductsWithTag();
  }

  removeFilter(filter: IFilter) {
    this.selectedFilters = this.selectedFilters.filter((internalFilter: IFilter) => internalFilter.title !== filter.title);
    this.getProductsWithTag();
  }

  getProductsWithTag() {
    const tags = this.selectedFilters.find(filter => filter.field === 'tags');
    const tagsArray = tags?.valueAsAString?.split(', ')?.map(tag => 'include,' + tag);
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch, null, tagsArray));
  }

  getAvailableTags() {
    this.ngRedux.dispatch(this.productManagerActions.loadAvailableTagFilters());
  }

  openSaveFiltersModal() {
    this.modalService.open(SaveFiltersAsViewModalComponent, {
      filters: this.selectedFilters
    });
  }

  applyFilters(filter: ISavedFilter) {
    // TODO
  }

  archiveProduct(product: IProductManagerProduct) {
    this.dialogService.confirm(
      this.i18n('Are you sure you want to archive this product?'),
      this.i18n('By archiving this product all keywords in the keyword manager for this ASIN will be archived as well. Previous data on this ASIN will not be accessible. Please proceed with caution. You are removing all keywords. Make sure to export keywords first if you need to move them to another ASIN.')
    )
        .then(
          (confirmation) => {
            if (confirmation) {
              this.ngRedux.dispatch(this.productManagerActions.archiveProducts([product.asin]));
            }
          });
  }

  resetProduct(product: IProductManagerProduct) {
    this.dialogService.prompt(
      this.i18n('Are you sure you want to reset this product?'),
      this.i18n('By resetting this product all keywords in the keyword manager for this ASIN will be reset as well. Previous data on this ASIN will not be accessible. Please proceed with caution. You are removing all keywords. Make sure to export keywords first if you need to move them to another ASIN.<br><br> <strong>Please enter the reason for resetting this product below</strong>:')
    )
        .then((reason: string) => {
          if (reason) {
            product.resettingProduct = true;
            this.ngRedux.dispatch(this.productManagerActions.resetProducts(product.asin, reason));
          }
        });
  }

  public syncProducts(): void {
    if (this.syncingProductsWithAmazon) {
      return;
    }

    this.dialogService.confirm(
      this.i18n('Confirm re-sync'),
      this.i18n('Are you sure you want to re-sync products?')
    ).then(
      (confirmation) => {
        if (confirmation) {
          //noinspection TypeScriptValidateTypes
          this.ngRedux.dispatch(this.productManagerActions.resyncProducts());
          this.rudderTracking.trackEvent('Product Manager - Trigger Sync Products');
        }
      }
    );
  }

  refreshList() {
    this.rudderTracking.trackEvent('Product Manager - Refresh list');
    this.currentPage = 1;
    this.currentSortBy = this.defaultSortBy;
    this.searchProductString = null;
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch));
  }

  pageChange(event: IDropdownOption) {
    this.currentPage = 1;
    this.perPage = event.id as number;
    this.preSelectedOption = {
      id: event.id,
      label: event.id.toString()
    };
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, this.isForeignFetch));
    this.rudderTracking.trackEvent('Product Manager - Changed items per page', {
      count: this.perPage
    });
  }

  variationPageChange(event: { event: IDropdownOption, product: IProductManagerVariation }) {
    this.variationCurrentPage = 1;
    this.variationPerPage = event.event.id as number;
    this.variationPreSelectedOption = {
      id: event.event.id,
      label: event.event.id.toString()
    };
    this.ngRedux.dispatch(this.productManagerActions.getProductVariations(event.product.asin, this.variationCurrentPage, this.variationPerPage));
  }

  setActiveTab(event: { tab: 'graph' | 'details', product }) {
    this.ngRedux.dispatch(this.productManagerActions.setActiveTab(event.tab, event.product.asin));

    if (event.tab === 'graph') {
      this.ngRedux.dispatch(this.productManagerActions.getGraphData(event.product.asin, ['bsr']));
    } else if (event.tab === 'details') {
      this.ngRedux.dispatch(this.productManagerActions.getProductVariations(event.product.asin, this.variationCurrentPage, this.variationPerPage));
    }
  }

  mapAvailableFilters(tags: any[]) {
    const filters: IAvailableFilter[] = [];
    tags = tags.map(tag => new Object({id: tag.id, label: tag.value}));
    filters.push(
      {
        field: "tags",
        operators: {
          '==': {type: FilterTypeEnum.MULTISELECT, options: tags}
        },
        title: "Tags",
        search: true
      });
    return filters;
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }

  get currentDisplayedVariationProducts(): string {
    return `Showing ${this.variationFrom || 0} - ${this.variationTo || 0} of ${this.variationTotal || 0}`;
  }

  get currentDisplayedProducts(): string {
    if (this.isForeignFetch) {
      return `Showing ${this.from || 0} - ${this.to || 0} of ${this.foreignTotal || 0}`;
    }

    return `Showing ${this.from || 0} - ${this.to || 0} of ${this.total || 0}`;
  }

  get activeProducts() {
    return this.activeTab === ProductListTabEnum.MY_PRODUCTS
     ? this.products
     : this.otherProducts;
  }

  get isForeignFetch(): boolean {
    return this.activeTab === ProductListTabEnum.OTHER_PRODUCTS;
  }
}
