import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Promotion } from '@app/shared/data/promotion/promotion.models';

@Injectable()
export class PromotionActions {

  static readonly TYPES = {
    FETCH_PROMOTION: '[PROMOTION]FETCH_ACTIVE_PROMOTION',
    FETCH_PROMOTION_SUCCESS: '[PROMOTION]FETCH_PROMOTION_SUCCESS',
    FETCH_PROMOTION_FAILED: '[PROMOTION]FETCH_PROMOTION_FAILED',
  };

  fetchPromotion(token: string): StAction {
    return {
      type: PromotionActions.TYPES.FETCH_PROMOTION,
      data: {token}
    };
  }

  fetchPromotionSucceed(promotion: Promotion): StAction {
    return {
      type: PromotionActions.TYPES.FETCH_PROMOTION_SUCCESS,
      data: promotion
    };
  }

  fetchPromotionFailed(error: any) {
    return {
      type: PromotionActions.TYPES.FETCH_PROMOTION_FAILED,
      data: error
    };
  }
}
