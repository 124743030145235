import { AbstractControl, FormControl, ValidatorFn, Validators, FormGroup, ValidationErrors, AsyncValidatorFn } from '@angular/forms';
import { Injectable } from '@angular/core';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { PhoneNumberUtil, PhoneNumber } from 'google-libphonenumber';
import { AuthService } from './auth.service';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Coupon } from '@app/shared/data/coupon/coupon.models';

const phoneUtil = PhoneNumberUtil.getInstance();

@Injectable()
export class ValidatorService extends Validators {
  private customMessages = {};

  constructor(private i18n: I18n) {
    super();
  }

  static maxLines(maxLines: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const length: number = control.value ? control.value.split('\n').length : 0;
      return length > maxLines ?
        { 'maxlinelength': { 'requiredLength': maxLines, 'actualLength': length } } :
        null;
    };
  }

  static maxLength(maxLength: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const length: number = ValidatorService.charsCount(control.value);
      return length > maxLength ?
        { 'maxlength': { 'requiredLength': maxLength, 'actualLength': length } } :
        null;
    };
  }

  static charsCount(str: string) {
    if (str == null) {
      return 0;
    }
    str = str.replace(/<(?:.|\n)*?>/gm, '');
    return str ? str.length : 0;
  }

  static maxBytes(maxBytes: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (control.value && typeof control.value === 'string') {
        length = ValidatorService.byteLength(control.value);
      }
      return length > maxBytes ?
        { 'maxbytes': { 'requiredLength': maxBytes, 'actualLength': length } } :
        null;
    };
  }

  static byteLength(str: string) {
    // returns the byte length of an utf8 string
    str = str.replace(/[,;]/g, ''); // punctuation do not contribute to the length limit
    str = str.replace(/<(?:.|\n)*?>/gm, '');
    let s = str.length;
    for (let i = str.length - 1; i >= 0; i--) {
      const code = str.charCodeAt(i);
      if (code > 0x7f && code <= 0x7ff) {
        s++;
      } else if (code > 0x7ff && code <= 0xffff) {
        s += 2;
      }
      if (code >= 0xDC00 && code <= 0xDFFF) {
        i--;
      } // trail surrogate
    }
    return s;
  }

  static Required(control) {
    if (control.value === '') {
      return { 'required': true };
    }
  }

  static RequiredStar(control) {
    if (control.value === '') {
      return { 'requiredStar': true };
    }
  }

  static MaxNumberOfLines(control, number) {
    if (control.value) {
      if (control.value.split('\n').length > number) {
        return { 'requiredFirstName': true };
      }
    }
  }

  // control = an instance of formControl
  static FirstNameEmpty(control) {
    if (control.value === '') {
      return { 'requiredFirstName': true };
    }
  }

  static LastNameEmpty(control) {
    if (control.value === '') {
      return { 'requiredLastName': true };
    }
  }

  static EmailEmpty(control) {
    if (control.value === '') {
      return { 'requiredEmail': true };
    }
  }

  static PasswordEmpty(control) {
    if (control.value === '') {
      return { 'requiredPassword': true };
    }
  }

  static PasswordConfirmation(control) {
    if (control.value === '') {
      return { 'requiredConfirmPassword': true };
    }
  }

  static EmailValidator(control) {
    if (!control.value) {
      return null;
    }

    const values = control.value.split('\n');

    for (let i = 0; i < values.length; i++) {
      // tslint:disable-next-line:max-line-length
      if (values[i] && !values[i].match(/^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/)) {
        if (values.length > 1) {
          return { 'invalidEmailAddresses': true };
        }
        return { 'invalidEmailAddress': true };
      }
    }

    return null;
  }

  static AmazonOrderID(control) {
    if (!control.value) {
      return null;
    }

    const values = control.value.split('\n');

    for (let i = 0; i < values.length; i++) {
      if (values[i] && !values[i].match(/^(\d{3}-\d{7}-\d{7})$/)) {
        if (values.length > 1) {
          return { 'invalidAmazonOrderIDs': true };
        }
        return { 'invalidAmazonOrderID': true };
      }
    }

    return null;
  }

  static PhoneNumberValidatorWithCountryCode(countryCodeField: string) {
    return function (control: FormControl) {
      if (!control.value) {
        return null;
      }

      const frm = control.parent;

      let countryCode = frm.get(countryCodeField).value;
      let phoneNumber = control.value;

      return ValidatorService._phoneNumberValidate(countryCode + ' ' + phoneNumber);
    }
  }

  static _phoneNumberValidate(value: string) {
    const values = value.split('\n');

    for (let i = 0; i < values.length; i++) {
      const value = values[i].replace(/\s/g, '');
      if (!value.match(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,7}$/im)) {
        if (values.length > 1) {
          return { 'invalidPhoneNumbers': true };
        }
        return { 'invalidPhoneNumber': true };
      }
    }
  }

  // todo: use Twilio A to check if phone number is valid or not!
  static PhoneNumberValidator(control: FormControl) {
    if (!control.value) {
      return null;
    }
    return ValidatorService._phoneNumberValidate(control.value);
  }

  static PhoneNumberValidatorE164(control) {
    if (!control.value) {
      return null;
    }
    try {
      if (!phoneUtil.isValidNumber(phoneUtil.parse(control.value))) {
        return { 'invalidPhoneNumbers': true };
      }
    } catch (error) {
      return { 'invalidPhoneNumbers': true };
    }
    return null;
  }

  static PasswordValidator(control) {
    // {6,}             - Assert password is at least 6 characters
    // (?=.*[0-9])      - Assert a string has at least one number
    if (control.value !== null && control.value.match(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]).{6,}$/)) {
      return null;
    } else {
      return { 'invalidPassword': true };
    }
  }

  static URLValidator(control) {
    const reg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
    const value = control.value;
    if (value !== null && value.match(reg)) {
      return null;
    }
    return { 'invalidUrl': true };
  }

  static FieldEqual(comparedField: string, attributeName: string) {
    return (control) => {
      if (control.parent === undefined) {
        return null;
      }
      const first = control.value;
      const second = control.parent?.controls[comparedField].value;

      if (first === second) {
        return null;
      }

      return { 'equalField': { 'attributeName': attributeName } };
    };
  }

  static ProductNotSelected(control) {
    if (control.value === '') {
      return { 'requiredSelectProduct': true };
    }
  }

  static KeywordEmpty(control) {
    if (control.value === '') {
      return { 'requiredKeyword': true };
    }
  }

  static MWSAuthToken(control) {
    if (control.value.length === 45 && control.value.toLowerCase().substring(0, 9) === 'amzn.mws.') {
      return null;
    }
    return { 'MWSAuthToken': true };
  }

  static MerchantID(control) {
    // TODO: not sure if it must always contain 14 characters or is it possible to have longer/shorter ID
    if ((control.value.length <= 30 && control.value.length > 10) && control.value === control.value.toUpperCase()) {
      return null;
    }
    return { 'MerchantID': true };
  }

  static CreditCard(control) { // TODO: add logic
    // if()
    //   return { 'InvalidCreditCard': true};
    return null;
  }

  static PositiveNumber(control) {
    return +control.value > 0 ? null : { 'positiveNumber': true };
  }

  static defaultCouponBuilder = (control: AbstractControl): Coupon => {
    return new Coupon({
      code: control.value,
      valid: false,
      feature: 'Keywords',
      plan: 'free_monthly',
    });
  }

  static couponCodeValidator(
        authService: AuthService,
        buildCouponCallback: (AbstractControl) => Coupon = ValidatorService.defaultCouponBuilder
    ): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors> => {
      control.metadata = {};

      if (control.value.length <= 0) {
        return of(null);
      }

      return authService
        .validateCoupon(buildCouponCallback(control))
        .pipe(
          map((c: Coupon) => {
            control.metadata = {coupon: c};
            return null;
          }),
          catchError(() => {
            return of({'invalidCouponCode': true });
          }),
        );
    };
  }

  getErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      'required': this.i18n('This field is required'),
      'requiredStar': '*',
      'requiredLastName': this.i18n('Last name is required'),
      'requiredFirstName': this.i18n('First name is required'),
      'requiredEmail': this.i18n('Email is required'),
      'requiredPassword': this.i18n('Password is required'),
      'requiredConfirmPassword': this.i18n('Password confirmation is required'),
      'equalField': this.i18n('{{attribute}} mismatch', { attribute: validatorValue.attributeName }),
      'invalidRepeatPassword': this.i18n('Confirm password is required'),
      'invalidEmailAddress': this.i18n('Invalid email address'),
      'invalidEmailAddresses': this.i18n('One of more invalid email adresses'),
      'invalidAmazonOrderID': this.i18n('Invalid Amazon order ID'),
      'invalidAmazonOrderIDs': this.i18n('One of more invalid Amazon order IDs'),
      'invalidPassword': this.i18n('Password must be at least 6 characters, contain 1 number and 1 uppercase letter.'),
      'requiredSelectProduct': this.i18n('Product should be selected'),
      'requiredKeyword': this.i18n('Please enter keyword(s)'),
      'invalidPhoneNumber': this.i18n('Invalid Phone Number.'),
      'invalidPhoneNumbers': this.i18n('One of more invalid phone numbers'),
      'invalidUrl': this.i18n('Invalid URL.'),
      'max': this.i18n('Maximum number is {{max}}', { max: validatorValue.max }),
      'min': this.i18n('Minimum number is {{min}}', { min: validatorValue.min }),
      'maxlength': this.i18n('Maximum length is {{length}}', { length: validatorValue.requiredLength }),
      'maxbytes': this.i18n('Maximum bytes is {{length}}', { length: validatorValue.requiredLength }),
      'maxlinelength': this.i18n('Maximum number of lines is {{length}}!', { length: validatorValue.requiredLength }),
      'validateEqual': this.i18n('Password mismatch'),
      'MWSAuthToken': this.i18n('Provided MWS Auth Token is invalid'),
      'MerchantID': this.i18n('Provided Merchant ID is invalid'),
      'InvalidCreditCard': this.i18n('Provided Credit Card number is invalid'),
      'positiveNumber': this.i18n('The field under validation must be a positive number'),
      'invalidCouponCode': this.i18n('Invalid coupon code'),
    };

    if (validatorName in config) {
      return config[validatorName];
    } else if (this.customMessages[validatorName] !== undefined) {
      return this.customMessages[validatorName];
    }

    return validatorValue;
  }

  customValidator(testFn: (value: any) => boolean, message: string): ValidatorFn {
    const validatorName = 'validator_' + Date.now();
    this.customMessages[validatorName] = message;

    return (control: FormControl) => {
      if (testFn(control.value)) {
        const val = {};
        val[validatorName] = {
          valid: false
        };

        return val;
      }
      return null;
    };
  }
}

export const atLeastOne = (controls: string[], validator: ValidatorFn) => (
  group: FormGroup,
): ValidationErrors | null => {
  const hasAtLeastOne =
    group &&
    group.controls &&
    Object.keys(group.controls).filter(c => controls.indexOf(c) >= 0).some(k => !validator(group.controls[k]));

  return hasAtLeastOne ? null : { atLeastOne: true };
};

export const allGroup = (name: string, validator: ValidatorFn) => (
  group: FormGroup,
): ValidationErrors | null => {
  const hasAll =
    group &&
    group.controls &&
    Object.keys(group.controls).every(k => !validator(group.controls[k]));

  return hasAll ? null : { [name]: true };
};

export const atLeastOneGroup = (controls: string[], validator: ValidatorFn) => (
  group: FormGroup,
): ValidationErrors | null => {
  const hasAtLeastOne =
    group &&
    group.controls &&
    Object.keys(group.controls).filter(c => controls.indexOf(c) >= 0).some(c => {
      let subGroup = group.controls[c] as FormGroup;
      return Object.keys(subGroup.controls).some(k => !validator(subGroup.controls[k]));
    });

  return hasAtLeastOne ? null : { atLeastOne: true };
};
