<div [class]="'table table--' + tableId" [class.table-fixed]="hasFixedColumns">
  <div class="table-scroll__outer" [class.table-header--columns-expanded]="columnsWidened" [class.table-scroll__outer--loading]="loadingItems">
    <div class="table-scroll" [style.minWidth]="scrollMinWidth">
      <div class="table-top-header" *ngIf="columnsTopHeader" [style.gridTemplateColumns]="gridTemplateColumnsTopHeader">
        <ng-container *ngFor="let column of columnsTopHeader">
          <div [class]="getColumnTopHeaderClassName(column)"
            [style.backgroundColor]="column.background"
            *ngIf="column.numberOfColumns">
            {{column.value}}
          </div>
        </ng-container>
      </div>
      <div class="table-header"
        [class.table-header--sort-asc]="currentSortDirection === 'ASC'"
        [class.table-header--sort-desc]="currentSortDirection === 'DESC'"
        [style.gridTemplateColumns]="gridTemplateColumns"
        *ngIf="!loadingItems">
        <div class="table-header-resizer" (click)="resizeFirstColumn()" *ngIf="showResizableIcon">
          <img class="table-header-resizer__icon" src="/assets/img/icons/resize.png">
        </div>
        <div class="table-header__column table-header__column--actions" *ngIf="showActionsColumn">
          <st-checkbox-v2 (changed)="toggleAll($event)" [checked]="areAllItemsChecked" class="keyword-checkbox"></st-checkbox-v2>
        </div>

        <ng-container
          *ngFor="let column of columns; let i = index">
          <ng-container *ngIf="!column.hide">
            <div [class]="getColumnClassName(column)"
              [class.table-header__column--sort-active]="column.sortBy && currentSortBy === column.sortBy"
              [class.table-header__column--sort]="column.sortBy"
              *ngIf="column.tooltip"
              i18n-stTooltip
              [stTooltip]="column.tooltip">
              <st-table-column-string (onSortBy)="sortBy($event)" [column]="column" *ngIf="column.type === columnTypes.STRING"></st-table-column-string>
              <st-table-column-track-keyword [column]="column" *ngIf="column.type === columnTypes.TRACK_KEYWORD" (trackKeywords)="trackKeywords($event)"></st-table-column-track-keyword>
              <st-table-column-priority (onSortBy)="sortBy($event)" [activePriorityFilter]="activePriorityFilter" (onFilterByPriority)="filterByPriority($event)" [isFilterActive]="isFilterActive" [column]="column" *ngIf="column.type === columnTypes.PRIORITY"></st-table-column-priority>
              <st-table-column-refresh (onRefresh)="onRefresh()" [column]="column" *ngIf="column.type === columnTypes.REFRESH"></st-table-column-refresh>
            </div>

            <div [class]="getColumnClassName(column)"
              [class.table-header__column--sort-active]="column.sortBy && currentSortBy === column.sortBy"
              [class.table-header__column--sort]="column.sortBy"
              *ngIf="!column.tooltip">
              <st-table-column-string (onSortBy)="sortBy($event)" [column]="column" *ngIf="column.type === columnTypes.STRING"></st-table-column-string>
              <st-table-column-track-keyword [column]="column" *ngIf="column.type === columnTypes.TRACK_KEYWORD" (trackKeywords)="trackKeywords($event)"></st-table-column-track-keyword>
              <st-table-column-priority (onSortBy)="sortBy($event)" [activePriorityFilter]="activePriorityFilter" (onFilterByPriority)="filterByPriority($event)" [isFilterActive]="isFilterActive" [column]="column" *ngIf="column.type === columnTypes.PRIORITY"></st-table-column-priority>
              <st-table-column-refresh (onRefresh)="onRefresh()" [column]="column" *ngIf="column.type === columnTypes.REFRESH"></st-table-column-refresh>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div class="table-list">
        <div class="loader" *ngIf="loadingItems">
          <st-loader [lines]="5"></st-loader>
        </div>
        <div class="table-list--empty" *ngIf="!loadingItems && data?.length === 0" i18n>No items</div>
        <ng-container *ngIf="!loadingItems && data?.length > 0">
          <div [class]="'table-item table-item--' + item.id" [style.gridTemplateColumns]="gridTemplateColumns" [class.table-item--loading]="loadingItems" [class.table-item--selected]="item.checked"
            [class.table-item--opened]="item.graphOpened"
            *ngFor="let item of data; let i = index">
            <div class="table__column table__column--actions" [style.transform]="getFixedColumnsTopOffset(item, i)" *ngIf="showActionsColumn">
              <st-checkbox-v2 (changed)="toggleItem($event, item)" [checked]="item.checked"
                class="keyword-checkbox"></st-checkbox-v2>
            </div>
            <ng-container *ngFor="let column of item.columns; let columnIndex=index">
              <div
                [class]="getTableItemColumnClassName(column)"
                [class.table-list__product-graph--loading]="item.loadingGraphData"
                [class.table-list__product-graph--opened]="item.graphOpened"
                [style.backgroundColor]="column.background"
                *ngIf="!columns[columnIndex]?.hide"
                (click)="column.onClick ? column.onClick($event, item) : null"
                [style.transform]="getFixedColumnsTopOffset(item, i, column)">
                  <st-table-default-item [item]="column" *ngIf="column.type === itemTypes.DEFAULT"></st-table-default-item>
                  <st-table-checked-item [item]="column" *ngIf="column.type === itemTypes.CHECKED"></st-table-checked-item>
                  <st-table-asins-item [item]="column" *ngIf="column.type === itemTypes.ASINS"></st-table-asins-item>
                  <st-table-date-item [item]="column" *ngIf="column.type === itemTypes.DATE"></st-table-date-item>
                  <st-table-button-item [item]="column" (clicked)="onButtonClicked.emit({event: $event, item: item})" *ngIf="column.type === itemTypes.BUTTON"></st-table-button-item>
                  <st-table-number-with-star-item [item]="column" *ngIf="column.type === itemTypes.NUMBER_WITH_STAR"></st-table-number-with-star-item>
                  <st-table-keyword-label-item [item]="column" *ngIf="column.type === itemTypes.KEYWORD_LABEL" (onToggleKeywordDetails)="onToggleKeywordDetails.emit({event: $event, item: item})"></st-table-keyword-label-item>
                  <st-table-keyword-r2a-item [item]="column" *ngIf="column.type === itemTypes.KEYWORD_R2A"></st-table-keyword-r2a-item>
                  <st-table-track-keyword-item [item]="column" *ngIf="column.type === itemTypes.TRACK_KEYWORD" (trackKeyword)="trackSingleKeyword($event)"></st-table-track-keyword-item>
                  <st-table-priority-item [item]="column" (onPriorityChange)="onPriorityChange.emit({priority: $event, item: item})" *ngIf="column.type === itemTypes.PRIORITY"></st-table-priority-item>
                  <st-table-rank-item [item]="column" *ngIf="column.type === itemTypes.RANK"></st-table-rank-item>
                  <st-table-indexed-item [item]="column" *ngIf="column.type === itemTypes.INDEXED"></st-table-indexed-item>
                  <st-table-sfr-item [item]="column" (onGetBrandAnalytics)="onGetBrandAnalytics.emit(item)" (onToggleKeywordDetails)="onToggleKeywordDetails.emit({event: $event, item: item})" *ngIf="column.type === itemTypes.SFR"></st-table-sfr-item>
                  <div class="container">
                    <st-table-product-graph-item [item]="column" *ngIf="item.graphOpened && column.type === itemTypes.GRAPH_DATA" (click)="preventDefault($event)"></st-table-product-graph-item>
                  </div>
                  <st-table-marketplace-item [item]="column" *ngIf="column.type === itemTypes.MARKETPLACE"></st-table-marketplace-item>
                  <st-table-status-item [item]="column" *ngIf="column.type === itemTypes.STATUS" (clicked)="onStatusChange.emit({event: $event, item: item})"></st-table-status-item>
                  <st-table-action-item [item]="column" *ngIf="column.type === itemTypes.ACTION" (clicked)="onAction.emit({event: $event, item: item})"></st-table-action-item>
                  <st-table-products-list-item [item]="column" *ngIf="column.type === itemTypes.PRODUCTS_LIST" (clicked)="onAction.emit({event: $event, item: item})"></st-table-products-list-item>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
