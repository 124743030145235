import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { AppState } from "@app/shared/data/app-state.model";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { UserAdminActions } from "@app/shared/data/admin/user/user-admin.actions";
import { UserAdminAPI } from "@app/shared/data/admin/user/user-admin.api";
import { Collection } from "@app/shared/data/base.models";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";
import { User } from "@app/shared/data/user/user.models";
import { ToastService } from "@app/core/services/toast.service";
import { IntercomService } from "@app/core/services/intercom.service";
import { AuthService } from "@app/core/services/auth.service";

@Injectable()
export class UserAdminEpics {
  constructor(
    private userAdminActions: UserAdminActions,
    private store: NgRedux<AppState>,
    private userAdminAPI: UserAdminAPI,
    private toastService: ToastService,
    private intercomService: IntercomService,
    private authService: AuthService,
  ) {}

  public createEpic() {
    return [
      this.loadPage,
      this.impersonate,
      this.setFullUser,
      this.addTrialDays,
      this.updateUserDetails,
      this.updateCommissions,
      this.addAdditionalPlan,
      this.removeAdditionalPlan,
      this.updatePermissions,
      this.transferCredentials
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      let n = next(action);
      if (action.type == PaginationActions.getPagActionTypes(UserAdmin).LOAD_PAGE || action.type == UserAdminActions.TYPES.FILTER) {
        let state = store.getState()['admin']['user'];
        let params = { ...state['_be_pagination'], ...state['_filters'] };

        this.userAdminAPI.all(params)
          .subscribe(
            (users: Collection<UserAdmin>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.setPage(users));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.loadPageFailed(response));
            },
            () => {}
          );
      }
      return n;
    }
  };

  impersonate = store => next => {
    return (action) => {
      if (action.type == UserAdminActions.TYPES.IMPERSONATE) {
        this.userAdminAPI.impersonate(action.data.user, action.data.privileged)
          .subscribe(
            (user: User) => {
              //noinspection TypeScriptValidateTypes
              this.authService.storeToken(user.token);
              this.intercomService.update(user);
              this.store.dispatch(this.userAdminActions.impersonateSucceeded(user));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.impersonateFailed(response));
            }
          )
      }
      return next(action);
    }
  };

  setFullUser = store => next => {
    return (action) => {
      if (action.type == UserAdminActions.TYPES.SET_FULL_USER) {
        this.userAdminAPI.get(action.data.user)
          .subscribe(
            (user: UserAdmin) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.setFullUserSucceeded(user));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.setFullUserFailed(response));
            }
          )
      }
      return next(action);
    }
  };

  addTrialDays = store => next => {
    return (action) => {
      if (action.type == UserAdminActions.TYPES.ADD_TRIAL_DAYS) {
        this.userAdminAPI.addTrialDays(action.data.user, action.data.days)
          .subscribe(
            () => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.setFullUser(this.store.getState()['admin']['user']['full_user']['id']));
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.addTrialDaysSucceeded());
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(this.userAdminActions.addTrialDaysFailed(response));
            }
          );
      }
      return next(action);
    }
  };

  updateUserDetails = store => next => action => {
    if (action.type == UserAdminActions.TYPES.UPDATE_USER) {
      this.userAdminAPI.updateUser(action.data.user.id, action.data.changes).subscribe(
        (user: UserAdmin) => {
          this.store.dispatch(this.userAdminActions.updateUserSucceeded(user));
        },
        (data) => {
          const message = typeof data.error.error.message == "string" ? data.error.error.message : Object.values(data.error.error.message).join(",");

          this.toastService.error("Updating user failed", message);
          this.store.dispatch(this.userAdminActions.updateUserFailed(action.user, action.data.changes, data.error.error));
        }
      );
    }
    return next(action);
  };

  updateCommissions = store => next => action => {
    if (action.type == UserAdminActions.TYPES.UPDATE_COMMISSION) {
      this.userAdminAPI.updateCommissions(action.data.user.id, action.data.commissionRatio, action.data.percentOff).subscribe(
        (user: UserAdmin) => {
          this.store.dispatch(this.userAdminActions.updateSucceeded(user));
        },
        (data) => {
          const message = typeof data.error.error.message == "string" ? data.error.error.message : Object.values(data.error.error.message).join(",");

          this.toastService.error("Updating user failed", message);
          this.store.dispatch(this.userAdminActions.updateFailed(action.user, data.error.error));
        }
      );
    }
    return next(action);
  };

  addAdditionalPlan = store => next => action => {
    if (action.type == UserAdminActions.TYPES.ADD_ADDITIONAL_PLAN) {
      this.userAdminAPI.addAdditionalPlan(action.data.user, action.data.plan).subscribe(
        (plans) => {
          this.store.dispatch(this.userAdminActions.addAdditionalPlanSucceeded(action.data.user, plans.data));
        },
        (response) => {
          this.store.dispatch(this.userAdminActions.addAdditionalPlanFailed(action.data.user, action.data.plan, response.error));
        }
      )
    }
    return next(action);
  };

  removeAdditionalPlan = store => next => action => {
    if (action.type == UserAdminActions.TYPES.REMOVE_ADDITIONAL_PLAN) {
      this.userAdminAPI.removeAdditionalPlan(action.data.user, action.data.plan).subscribe(
        (plans) => {
          this.store.dispatch(this.userAdminActions.removeAdditionalPlanSucceeded(action.data.user, plans.data));
        },
        (response) => {
          this.store.dispatch(this.userAdminActions.removeAdditionalPlanFailed(action.data.user, action.data.plan, response.error));
        }
      )
    }
    return next(action);
  };

  updatePermissions = store => next => action => {
    if (action.type == UserAdminActions.TYPES.UPDATE_PERMISSIONS) {
      this.userAdminAPI.updatePermissions(action.data.user.id, action.data.changes).subscribe(
        (user: UserAdmin) => {
          this.store.dispatch(this.userAdminActions.updatePermissionsSucceeded(user));
        },
        (response) => {
          this.store.dispatch(this.userAdminActions.updatePermissionsFailed(action.data.user, action.data.plan, response.error));
        }
      )
    }
    return next(action);
  };

  transferCredentials = store => next => action => {
    if (action.type === UserAdminActions.TYPES.TRANSFER_CREDENTIALS) {
      this.userAdminAPI.transferCredentials(action.data.credential_id, action.data.toUser).subscribe(
        () => {
          this.store.dispatch(this.userAdminActions.transferCredentialsSucceeded(action.data.toUser));
          this.store.dispatch(this.userAdminActions.setFullUser(action.data.fromUser.id));
        },
        (error) => {
          this.store.dispatch(this.userAdminActions.transferCredentialsFailed(action.data.toUser, error.error));
        }
      )
    }
    return next(action);
  }
}
