import { BaseModel } from '@app/shared/data/base.models';

export class ProductChild extends BaseModel {
  static className = 'ProductChild';

  asin?: string;
  sku?: string;
  parent_asin?: string;
  image_url?: string;
  title?: string;
  description?: string;
  protected ?: boolean;
}
