
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Collection } from '@app/shared/data/base.models';
import { BaseAPI } from '@app/shared/data/base.api';
import { CreditCard } from '@app/shared/data/credit-card/credit-card.models';

@Injectable()
export class CreditCardAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public create(): Observable<any> {
    return this.http.get(this.constructFullUrl('api/billing/cards/create')).pipe(map(response => response['secret']));
  }

  public store(token, def = true): Observable<CreditCard> {
    return this.http.post(this.constructFullUrl('api/billing/cards'), {
      'token'  : token,
      'default': def
    }).pipe(map(d => new CreditCard(d)));
  }

  public all(): Observable<Collection<CreditCard>> {
    return this.http.get(this.constructFullUrl('api/billing/cards')).pipe(
      map(data => CreditCard.collect<CreditCard>(data)));
  }
}
