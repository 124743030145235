import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { Collection } from "@app/shared/data/base.models";
import { PayoutAdminActions } from "@app/shared/data/admin/payout-affiliate/payout-admin.actions";
import { PayoutAdminAPI } from "@app/shared/data/admin/payout-affiliate/payout-admin.api";
import { PayoutAdmin } from "@app/shared/data/admin/payout-affiliate/payout-admin.models";
import { ToastService } from "@app/core/services/toast.service";

@Injectable()
export class PayoutAdminEpics {
  constructor(private affiliateActions: PayoutAdminActions,
              private affiliateAPI: PayoutAdminAPI,
              private ngRedux: NgRedux<AppState>,
              private toastService: ToastService) {}

  /**
   //  *
   //  * @returns {Epic<Action, AppState>}
   //  */
  public createEpic() {
    return [
      this.loadPage,
      this.pay
    ];
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PayoutAdminActions.getPagActionTypes(PayoutAdmin).LOAD_PAGE) {
        const params = {
          ...action.data.pagination,
          limit: action.data.pagination.perPage,
          sort : action.data.pagination.sort.join(',')
        };
        this.affiliateAPI.payouts(params)
          .subscribe(
            (affiliates: Collection<PayoutAdmin>) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.affiliateActions.setPage(affiliates));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.affiliateActions.loadPageFailed(response));
            },
            () => {}
          );
      }
      return next(action);
    }
  };

  pay = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == PayoutAdminActions.TYPES.PAY) {
        this.affiliateAPI.pay(action.data.commisionId)
          .subscribe(
            () => {
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.ngRedux.dispatch(this.affiliateActions.payFailed(action.data.commisionId, response.error.error));
            }
          )
      }
      return next(action);
    }
  };
}
