import { Pipe, PipeTransform, OnDestroy, ChangeDetectorRef, NgZone } from '@angular/core';
import { DatePipe } from '@angular/common';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Pipe({
  name: 'stTimeAgo',
  pure: false
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  private timer: number;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private ngZone: NgZone,
              private i18n: I18n) {}

  ngOnDestroy(): void {
    this.removeTimer();
  }

  transform(value: string) {
    this.removeTimer();

    if (!value) {
      return value;
    }

    const d = this.dateFromString(value);
    const seconds = Math.round(Math.abs((this.now() - d.getTime()) / 1000));

    if (seconds > 86400) { // More than 1 day
      const datePipe = new DatePipe('en-US');
      value = datePipe.transform(value, 'dd. LLLL yyyy');
      return value;
    }

    this.initTimer(seconds);

    return this.format(seconds);

  }

  private format(seconds) {
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));

    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return this.i18n('Just now');
    } else if (seconds <= 90) {
      return this.i18n('a minute ago');
    } else if (minutes <= 45) {
      return this.i18n('{{val}} minutes ago', {val: minutes});
    } else if (minutes <= 90) {
      return this.i18n('an hour ago');
    } else if (hours <= 22) {
      return this.i18n('{{val}} hours ago', {val: hours});
    } else if (hours <= 36) {
      return this.i18n('a day ago');
    } else if (days <= 25) {
      return this.i18n('{{val}} days ago', {val: days});
    } else if (days <= 45) {
      return this.i18n('a month ago');
    } else if (days <= 345) {
      return this.i18n('{{val}} months ago', {val: months});
    } else if (days <= 545) {
      return this.i18n('a year ago');
    }

    // (days > 545)
    return this.i18n('{{val}} years ago', {val: years});
  }

  private initTimer(seconds) {
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }

  private dateFromString(str) {
    if (typeof str === 'object') {
      return str;
    }
    const a = str.split(/[^0-9]/).map(s => parseInt(s, 10));
    return new Date(a[0], a[1] - 1 || 0, a[2] || 1, a[3] || 0, a[4] || 0, a[5] || 0, a[6] || 0);
  }

  private now() {
    const today = new Date();
    const timezoneOffset = today.getTimezoneOffset() / 60;

    today.setHours(today.getHours() + timezoneOffset);

    return today.getTime();
  }
}
