import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Survey } from "@app/shared/data/survey/survey.models";
import { SurveyActions } from "@app/shared/data/survey/survey.actions";

export interface SurveyAppState extends AppState {
  data: Survey;
  loading: boolean;
  error?: string;
  submitting: boolean;
}

const INITIAL_STATE: SurveyAppState = {
  data: null,
  loading: false,
  submitting: false,
}

export function surveyReducer(state: SurveyAppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == SurveyActions.TYPES.GET) {
    return {
      data   : null,
      loading: true,
      error  : null,
    }
  }

  if (action.type == SurveyActions.TYPES.GET_SUCCEEDED) {
    return {
      ...state,
      data   : action.data,
      loading: false,
      error  : null
    }
  }

  if (action.type == SurveyActions.TYPES.GET_FAILED) {
    return {
      ...state,
      data   : null,
      loading: false,
      error  : action.data
    }
  }

  if (action.type == SurveyActions.TYPES.GET_FAILED) {
    return {
      ...state,
      data   : null,
      loading: false,
      error  : action.data
    }
  }

  if (action.type == SurveyActions.TYPES.SUBMIT) {
    return {
      ...state,
      submitting: true,
      error  : null
    }
  }

  if (action.type == SurveyActions.TYPES.SUBMIT_SUCCEEDED) {
    return {
      ...state,
      submitting: false,
      error  : null
    }
  }

  if (action.type == SurveyActions.TYPES.SUBMIT_FAILED) {
    return {
      ...state,
      submitting: false,
      error  : action.data
    }
  }

  return state;
}
