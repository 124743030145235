import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { FePaginationCollection } from '@app/shared/data/base.models';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { KeywordGenerated } from '@app/shared/data/keyword-generated/keyword-generated.models';
import { KeywordGeneratedActions } from '@app/shared/data/keyword-generated/keyword-generated.actions';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';

const INITIAL_STATE: AppState = {
  data          : new FePaginationCollection<KeywordGenerated>([]),
  _data         : new FePaginationCollection<KeywordGenerated>([]),
  loading       : false,
  button_loading: true,
  recent_loading: true,
  group_id      : '',
  recent        : [],
  filters       : { filters: [], showUnfinished: true },
  filtered      : new FePaginationCollection<KeywordGenerated>([]),
  keyword       : null,
  searchDone    : false,
  history       : [],
  history_loading: false,
  history_error: null,
};

export function keywordGeneratedReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type === KeywordGeneratedActions.TYPES.SET_ACTIVE_KEYWORD) {
    return {
      ...state,
      data          : new FePaginationCollection<KeywordGenerated>([]),
      _data         : new FePaginationCollection<KeywordGenerated>([]),
      active_keyword: action.data.keyword,
      button_loading: action.data.button_loading,
      group_id      : action.data.group_id,
      searchDone    : false
    };
  }

  if ((action.type === KeywordGeneratedActions._events.suggestions_finished
    || action.type === KeywordGeneratedActions.TYPES.SUGGESTIONS_FINISHED) && action.data.group_id === state['group_id']) {

    if (!!action.data.error && action.data.error.length > 0) {
      return {
        ...state,
        button_loading: false,
        _data         : KeywordGenerated.collect([], KeywordGenerated.FRONTEND_PAGINATION),
        error         : action.data.error.message,
        searchDone    : true
      };
    }

    return {
      ...state,
      button_loading: false,
      _data         : KeywordGenerated.collect(Object.values(action.data.keywords), KeywordGenerated.FRONTEND_PAGINATION),
      recent        : state['recent'].map(x => x.keyword === action.data.keyword ? x : { ...x, finished_at: action.data.finished_at }),
      searchDone    : true
    };
  }

  if (action.type === PaginationActions.getPagActionTypes(KeywordGenerated).APPEND_DATA) {
    return {
      ...state,
      _data: (state as any)._data.clone().updateOrPushMany(action.data, 'keyword', false, (old, nw) => {
        for (const k in old) {
          if (!(k in nw) || nw[k] === false) {
            nw[k] = old[k];
          }
        }
        return nw;
      })
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.RESET) {
    return {
      ...state,
      data    : new FePaginationCollection<KeywordGenerated>([]),
      _data   : new FePaginationCollection<KeywordGenerated>([]),
      filtered: new FePaginationCollection<KeywordGenerated>([]),
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.LOAD_RECENT) {
    return {
      ...state,
      recent_loading: true,
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.SET_RECENT) {
    return {
      ...state,
      recent_loading: false,
      recent        : action.data.recent
    };
  }

  if (action.type === PaginationActions.getPagActionTypes(KeywordGenerated).LOAD_PAGE) {
    state = {
      ...state,
      searchDone: false
    };
  }

  if (action.type === PaginationActions.getPagActionTypes(KeywordGenerated).LOAD_PAGE_FAILED) {
    state = {
      ...state,
      button_loading: false
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.APPLY_FILTERS) {
    return {
      ...state,
      filters: { ...state['filters'], filters: [...action.data || []] }
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.TOGGLE_UNFINISHED) {
    return {
      ...state,
      filters: { ...state['filters'], showUnfinished: action.data }
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.SET_FILTERED_KEYWORDS) {
    return {
      ...state,
      filtered: action.data.data
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.SUGGESTIONS_REQUESTED) {
    return {
      ...state,
      keyword: action.data.keyword,
      searchDone    : false,
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.LOAD_HISTORY) {
    return {
      ...state,
      history_loading: true,
      history_error: null,
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.LOAD_HISTORY_FAILED) {
    return {
      ...state,
      history_loading: false,
      history_error: action.data,
    };
  }

  if (action.type === KeywordGeneratedActions.TYPES.LOAD_HISTORY_SUCCEEDED) {
    return {
      ...state,
      history_loading: false,
      history: action.data,
      history_error: null,
    };
  }

  return paginationReducer(KeywordGenerated)(state, action);
}

