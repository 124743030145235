import { Component, ElementRef, Input, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import QrCodeWithLogo from "qrcode-with-logos";
import ColorContrastCalc from "color-contrast-calc";

const ColorWhite = '#ffffff';
const ColorBlack = '#173042';

@Component({
  selector: 'st-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnInit {
  @ViewChild('canvas', { static: true }) canvas: ElementRef;

  @Input() url: string = '';
  @Input() color: string = '';
  @Input() density: string = '';
  @Input() logoUrl?: string = '';
  @Input() width?: number = 120;

  @Output() colorBackground: EventEmitter<string> = new EventEmitter();

  QrCode: QrCodeWithLogo;
  colorContrastCalc = ColorContrastCalc.ColorContrastCalc;

  constructor() { }

  ngOnInit() {
    if (this.canvas) {
      this.renderQrCode();
    }
  }

  ngOnChanges() {
    if (this.canvas) {
      this.renderQrCode();
    }
  }

  renderQrCode() {
    if (!this.canvas || !this.url) {
      return;
    }

    let logoSize = this.density === "L" ? 0.1 : (this.density === "M" ? 0.2 : 0.35); 

    let backgroundColor = this.getBackgroundColor();
    this.colorBackground.emit(backgroundColor);

    this.QrCode = new QrCodeWithLogo({
      canvas: this.canvas.nativeElement, 
      content: this.url, 
      width: this.width, 
      nodeQrCodeOptions: {
        margin: 0,
        errorCorrectionLevel: this.density,
        color: {
          dark: this.color || ColorBlack,
          light: backgroundColor 
        }
      },
      logo: this.logoUrl && {
        src: this.logoUrl,
        logoSize: logoSize,
        borderRadius: 0
      }
    });

    this.QrCode.toCanvas();
  }

  getBackgroundColor() {
    const qrColor = this.colorContrastCalc.colorFrom(this.color || ColorBlack);
    const qrBackgroundColor = this.colorContrastCalc.colorFrom(ColorWhite);
 
    if (qrColor.contrastRatioAgainst(qrBackgroundColor) < 1.5) {
      return ColorBlack;
    } else {
      return ColorWhite;
    }
  }

}
