import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[stTranslate]',
})
export class TranslateDirective implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('stTranslate')
  stTranslate: {};

  @Input('html')
  html = false;

  constructor(private el: ElementRef) {
  }

  ngOnInit() {
    for (const [key, value] of Object.entries(this.stTranslate)) {
      const fullKey =  `%${key}`;
      const currentText = this.el.nativeElement.innerText;
      if (currentText.indexOf(fullKey) !== -1) {
        if (this.html) {
          this.el.nativeElement.innerHTML = this.el.nativeElement.innerText.replace(fullKey, value);
        } else {
          this.el.nativeElement.innerText = this.el.nativeElement.innerText.replace(fullKey, value);
        }
      } else {
        console.warn(`Translation string corrupted. Searching for '${fullKey}' in: ${currentText}`);
      }
    }
  }
}
