import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Notification } from '@app/shared/data/notification/notification.models';
import { Collection } from '@app/shared/data/base.models';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';

export interface NotificationAction extends StAction {
}

@Injectable()
export class UnreadNotificationsActions extends PaginationActions {
  protected model = Notification;

  static readonly _events = {
    socket_notification_added: 'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
  };

  static readonly TYPES = {

    FETCH_NOTIFICATIONS               : '[UNREAD_NOTIFICATIONS]FETCH_NOTIFICATIONS',
    FETCH_NOTIFICATIONS_SUCCESS       : '[UNREAD_NOTIFICATIONS]FETCH_NOTIFICATIONS_SUCCESS',
    FETCH_NOTIFICATIONS_FAILED        : '[UNREAD_NOTIFICATIONS]FETCH_NOTIFICATIONS_FAILED',
    ADD_NOTIFICATION                  : '[UNREAD_NOTIFICATIONS]ADD_NOTIFICATION',
    READ_ALL_NOTIFICATIONS_SUCCEEDED  : '[UNREAD_NOTIFICATIONS]READ_ALL_SUCCEEDED',
    READ_NOTIFICATION                 : '[UNREAD_NOTIFICATIONS]READ_NOTIFICATION',
    READ_ALL_NOTIFICATIONS            : '[UNREAD_NOTIFICATIONS]READ_ALL_NOTIFICATIONS',
    OPEN_NOTIFICATION_DROPDOWN        : '[UNREAD_NOTIFICATIONS]OPEN_NOTIFICATION_DROPDOWN',
  };


  static fetchNotifications(params = {}): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.FETCH_NOTIFICATIONS,
      data: params,
    };
  }

  static fetchingNotificationsFailed(error: any): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.FETCH_NOTIFICATIONS_FAILED,
      data: error,
    };
  }

  static fetchNotificationsSucceeded(notifications: Collection<object>): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.FETCH_NOTIFICATIONS_SUCCESS,
      data: notifications,
    };
  }

  static addNotification(notification: object): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.ADD_NOTIFICATION,
      data: notification,
    };
  }

  static readNotification(notification: Notification): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.READ_NOTIFICATION,
      data: notification,
    };
  }

  static readAllNotifications(filter): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.READ_ALL_NOTIFICATIONS,
      data: filter,
    };
  }

  static readAllNotificationsSucceeded(): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.READ_ALL_NOTIFICATIONS_SUCCEEDED,
      data: null,
    };
  }

  static openNotificationsDropdown(): NotificationAction {
    return {
      type: UnreadNotificationsActions.TYPES.OPEN_NOTIFICATION_DROPDOWN,
      data: null,
    };
  }

}
