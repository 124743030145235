import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { Collection } from '../base.models';
import { ExchangeRate } from './exchange-rates.models';
import { ExchangeRatesActions } from './exchange-rates.actions';

interface ExchangeRatesState extends AppState {
  data: Collection<ExchangeRate>;
  loading: boolean;
  error: any;
}

const INITIAL_STATE: ExchangeRatesState = {
  data: new Collection(),
  loading: false,
  error: null,
};

export function exchangeRatesReducer(state: ExchangeRatesState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === ExchangeRatesActions.TYPES.LOAD) {
    return {
      ...state,
      data: new Collection(),
      loading: true,
      error: null,
    };
  }

  if (action.type === ExchangeRatesActions.TYPES.LOAD_SUCCEEDED) {
    return {
      ...state,
      data: action.data.rates,
      loading: false,
      error: null,
    };
  }

  if (action.type === ExchangeRatesActions.TYPES.LOAD_FAILED) {
    return {
      ...state,
      data: new Collection(),
      loading: false,
      error: action.data.error,
    };
  }

  return state;
}
