import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import { PayoutAllCommisionAdmin } from "@app/shared/data/admin/payout-all-commisions/payout-commision.model";

@Injectable()
export class PayoutAllCommisionAdminActions extends PaginationActions {
  protected model = PayoutAllCommisionAdmin;

  static readonly TYPES = {
    FILTER   : '[PAYOUTALLCOMMISIONADMIN]FILTER',
    COMMISION: '[PAYOUTALLCOMMISIONADMIN]COMMISION',
  };

  filter(filters): StAction {
    return {
      type: PayoutAllCommisionAdminActions.TYPES.FILTER,
      data: filters
    }
  }

  commision(commision: PayoutAllCommisionAdmin): StAction {
    return {
      type: PayoutAllCommisionAdminActions.TYPES.COMMISION,
      data: {
        commision: commision,
      }
    }
  }
}
