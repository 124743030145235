import { Component, Input } from "@angular/core";

@Component({
  selector : 'st-loader',
  template : '<div [style.width.%]="_width"><span *ngFor="let i of times"></span></div>',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  n: number = 1;
  times = [0];
  _width = 100;

  get lines(): number {
    return this.n;
  }

  @Input()
  set lines(val) {
    this.n = val;
    this.times = Array(val).fill(0);
  }

  get width(): number {
    return this._width;
  }

  @Input()
  set width(val) {
    this._width = val;
  }

}
