<div class="title">
  <div class="title__word" i18n>Settings</div>
  <div class="title__word" i18n>Billing</div>
</div>
<div class="content-grid white-boxes-layout vertical-layout">
  <div class="top horizontal-layout">
    <div class="box upgrade">
      <h4 class="box-title" i18n>Current Subscription Plan</h4>
      <st-billing-current-plan
        [currentPlans]="keywordPlans"
        [activeSubscription]="activeSubscription"
        [nextSubscription]="nextSubscription"
        [subscribingToPlan]="subscribingToPlan$ | async"
        [loading]="loadingSubscriptions$ | async"
        [canceling]="cancelingSubscription$ | async"
        (onResubscribe)="save($event)">
        </st-billing-current-plan>
    </div>
    <div class="box billing-info">
      <h4 class="box-title" i18n>Billing Information</h4>
      <div class="info text">
        <ng-container *ngIf="billingInformation$ | async as billingInformation">
          <p *ngFor="let info of billingInformation">
            {{ info }}
          </p>
        </ng-container>
      </div>
      <div class="buttons">
        <div class="button-link" (click)="openEditBillingInformationModal()" i18n>Update Details</div>
        <div class="button-link button-link--credit-card" (click)="openChooseAnotherCardModal()" i18n>Update Credit Card</div>
      </div>
    </div>
  </div>

  <div class="upgrade-plan-container" *ngIf="!(loadingPlans$ | async) && keywordPlans?.length > 0">
    <st-billing-plan-change
      [currentPlans]="keywordPlans"
      [loading]="loadingPlans$ | async"
      [activeSubscription]="activeSubscription"
      [nextSubscription]="nextSubscription"
      [subscribingToPlan]="subscribingToPlan$ | async"
      [coupon]="nextSubscription?.couponObj"
      (planSelected)="onSelect($event)">
    </st-billing-plan-change>

    <div class="bottom horizontal-layout">
      <div *ngIf="!usageSummary" class="loader">
        <st-progress-bar [loading]="true"></st-progress-bar>
        <st-progress-bar [loading]="true"></st-progress-bar>
        <st-progress-bar [loading]="true"></st-progress-bar>
        <st-progress-bar [loading]="true"></st-progress-bar>
      </div>
      <div class="bars-wrapper" *ngIf="selected && selected.features">
        <div class="bars">
          <ng-container *ngFor="let feature of showAllUsageSummary ? featuresList : (featuresList | slice:0:4)">
            <ng-container *ngIf="getFeatureUsageSummary(feature)">
              <st-progress-bar
                [newLayout]="true"
                [label]="feature"
                style="blue"
                [value]="getFeatureUsageSummary(feature)?.limit_reached"
                [max]="getFeatureUsageSummary(feature)?.limit"
                [percentage]="getFeatureUsageSummary(feature)?.percentage_reached"></st-progress-bar>
            </ng-container>
          </ng-container>

          <div class="upgrade-plan-wrapper">
            <span class="show-all-usage-summary" (click)="showAllUsageSummary = !showAllUsageSummary">
              <ng-container *ngIf="!showAllUsageSummary" i18n>Show Complete Usage Summary</ng-container>
              <ng-container *ngIf="showAllUsageSummary" i18n>Show Less</ng-container>
            </span>
            <st-button-v2
              color="v2_red"
              (clicked)="save()"
              [disabled]="subscribingToPlan$|async"
              [label]="activeSubscription ? 'UPGRADE PLAN' : 'SUBSCRIBE'"
              [loading]="subscribingToPlan$ | async"></st-button-v2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="ppcs">
    <h4 class="box-title" i18n>Update PPCS Plan</h4>
    <st-billing-ppcs-plan-change
        [plans]="ppcsPlans"
        [loading]="loadingPlans$ | async"
        [activeSubscription]="activePPCSSubscription"
        [nextSubscription]="nextPPCSSubscription"
        [subscribingToPlan]="subscribingToPlan$ | async"
        [cancelingSubscription]="cancelingSubscription$ | async"></st-billing-ppcs-plan-change>
  </div>

  <h4 class="box-title box-title--invoices" i18n>Invoices</h4>
  <div class="invoices">
    <div class="tabs-wrapper">
      <div [class.selected]="selectedInvoicesType === invoicesTypeEnum.SUBSCRIPTIONS" (click)="selectInvoicesType(invoicesTypeEnum.SUBSCRIPTIONS)" i18n>
          Subscription Invoices
      </div>
      <div [class.selected]="selectedInvoicesType === invoicesTypeEnum.OTHER" (click)="selectInvoicesType(invoicesTypeEnum.OTHER)" i18n>
          Other Invoices
      </div>
    </div>

    <div class="invoices-container">
      <st-loader *ngIf="loadingInvoices" [lines]="6"></st-loader>
      <ng-container *ngIf="!loadingInvoices">
        <div *ngIf="invoices && !invoices.length" i18n>
          No invoices.
        </div>
        <div *ngIf="invoices && invoices.length">
          <div class="invoices__header">
            <div class="invoices__header-cell" i18n>Date</div>
            <div class="invoices__header-cell" i18n>Amount</div>
          </div>

          <div class="invoices__body">
            <cdk-virtual-scroll-viewport itemSize="10" class="example-viewport">
              <div class="invoices__body-row"
                *cdkVirtualFor="let invoice of invoices">
                <div class="invoices__body-cell invoices__body-cell--date">{{invoice.date}}</div>
                <div class="invoices__body-cell invoices__body-cell--total">{{invoice.total}}</div>
                <div class="invoices__body-cell">
                  <div class="download-icon" [class.download-icon--loading]="invoice.downloading" (click)="downloadInvoice(invoice)">
                    <st-icon icon="download-new"></st-icon>
                  </div>
                  <span class="invoices__body-cell--downloading" *ngIf="invoice.downloading" i18n>Downloading...</span>
                </div>
              </div>
            </cdk-virtual-scroll-viewport>
          </div>

          <div class="invoices__pagination">
            <div class="pagination">
              <div class="pagination-item" [class.pagination-item--disabled]="!hasPreviousInvoicesPage" (click)="previousInvoicesPage()">
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.19022 9.9212L5.34872 9.76271C5.45377 9.65767 5.45377 9.48735 5.34872 9.38228L0.966454 4.99999L5.34872 0.617698C5.45377 0.512654 5.45377 0.342334 5.34872 0.237267L5.19022 0.0787828C5.08517 -0.0262609 4.91485 -0.0262609 4.80981 0.0787828L0.0787828 4.80979C-0.0262609 4.91483 -0.0262609 5.08515 0.0787828 5.19022L4.80981 9.92122C4.91485 10.0263 5.08517 10.0263 5.19022 9.9212Z" fill="#173042"/>
                  </svg>

                PREVIOUS
              </div>
              <div class="pagination-item" [class.pagination-item--disabled]="!hasNextInvoicesPage" (click)="nextInvoicesPage()">
                NEXT
                <div class="pagination-item--rotate">
                  <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.19022 9.9212L5.34872 9.76271C5.45377 9.65767 5.45377 9.48735 5.34872 9.38228L0.966454 4.99999L5.34872 0.617698C5.45377 0.512654 5.45377 0.342334 5.34872 0.237267L5.19022 0.0787828C5.08517 -0.0262609 4.91485 -0.0262609 4.80981 0.0787828L0.0787828 4.80979C-0.0262609 4.91483 -0.0262609 5.08515 0.0787828 5.19022L4.80981 9.92122C4.91485 10.0263 5.08517 10.0263 5.19022 9.9212Z" fill="#173042" />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
