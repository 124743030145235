import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { ITopUpSource } from './wallet-top-up-sources.models';

export interface IACHSecretResponse {
  secret: string;
  account?: null | string;
}

export interface IListSourcesResponse {
  data: ITopUpSource[];
}

@Injectable()
export class WalletTopUpSourcesApi extends BaseAPI {

  constructor(protected http: HttpClient) {
    super();
  }

  public getACHSecret(): Observable<IACHSecretResponse> {
    return this.http.get<IACHSecretResponse>(this.constructFullUrl(`api/wallet/top-up-sources/ach-secret`));
  }

  public get(): Observable<IListSourcesResponse> {
    return this.http.get<IListSourcesResponse>(this.constructFullUrl(`api/wallet/top-up-sources`));
  }

  public linkACH(setupIntentId: string, paymentMethodId: string): Observable<IListSourcesResponse> {
    return this.http.post<IListSourcesResponse>(this.constructFullUrl(`api/wallet/top-up-sources/ach`), {
      setup_intent_id: setupIntentId,
      payment_method_id: paymentMethodId,
    });
  }

  public removeACH(): Observable<IListSourcesResponse> {
    return this.http.delete<IListSourcesResponse>(this.constructFullUrl(`api/wallet/top-up-sources/ach`));
  }
}
