import { Injectable } from "@angular/core";
import { AppState } from "@app/shared/data/app-state.model";
import { NgRedux } from "@angular-redux/store";
import { LayoutActions } from "@app/shared/layout/layout.actions";
import { Observable } from "rxjs";
import { Toast } from "@app/shared/layout/toasts/toast.model";

@Injectable()
export class ToastService {

  private counter: number = 0;
  public toasts$: Observable<Toast[]>;

  constructor(private ngRedux: NgRedux<AppState>, private layoutActions: LayoutActions) {
    this.toasts$ = ngRedux.select(['layout', 'toasts']);
  }

  private positions = [
    'top-right', 'top-left', 'top-center', 'bottom-right', 'bottom-left', 'bottom-center'
  ];

  add(title: string, message: string, type?: string, position?: string, onClick?: (e: Event) => void, onUndo?: (e: Event) => void): void {
    if (!type) {
      type = 'info';
    }

    if (!position || this.positions.indexOf(position) < 0) {
      position = this.positions[0];
    }

    if (!onClick) {
      onClick = null;
    }

    if (!onUndo) {
      onUndo = null;
    }

    let toast = <Toast>{
      id      : this.counter++,
      title   : title,
      message : message,
      type    : type,
      position: position,
      duration: 8,
      onClick : onClick,
      onUndo  : onUndo
    };

    //noinspection TypeScriptValidateTypes
    this.ngRedux.dispatch(this.layoutActions.addToast(toast));
  }

  remove(toast: Toast): void {
    //noinspection TypeScriptValidateTypes
    this.ngRedux.dispatch(this.layoutActions.removeToast(toast));
  }

  success(title: string, message: string, onClick?: (e: Event) => void, onUndo?: (e: Event) => void) {
    this.add(title, message, 'success', null, onClick, onUndo);
  }

  info(title: string, message: string, onClick?: (e: Event) => void, onUndo?: (e: Event) => void) {
    this.add(title, message, 'info', null, onClick, onUndo);
  }

  warning(title: string, message: string, onClick?: (e: Event) => void, onUndo?: (e: Event) => void) {
    this.add(title, message, 'warn', null, onClick, onUndo);
  }

  error(title: string, message: string, onClick?: (e: Event) => void, onUndo?: (e: Event) => void) {
    this.add(title, message, 'error', null, onClick, onUndo);
  }
}
