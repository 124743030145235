import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ToastsListComponent } from "@app/shared/layout/toasts/toasts-list.component";
import { ToastComponent } from "@app/shared/layout/toasts/toast.component";
import { SvgIconModule } from "@app/shared/layout/icon/svg-icon.module";


@NgModule({
  imports     : [
    CommonModule,
    SvgIconModule
  ],
  declarations: [
    ToastComponent,
    ToastsListComponent,
  ],
  exports  : [
    ToastsListComponent,
  ]
})
export class ToastsModule {}
