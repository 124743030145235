import { NgModule } from '@angular/core';
import { CommonModule } from "@angular/common";
import { DropdownModule } from "@app/shared/layout/dropdown/dropdown.module";
import { SelectOptionDirective } from "@app/shared/components/select/select-option.directive";
import { SelectComponent } from "@app/shared/components/select/select.component";
import { FormsModule } from "@angular/forms";
import { OptionComponent } from "@app/shared/components/select/option.component";
import { LabelTemplateDirective } from "@app/shared/components/select/directives/label-template.directive";
import { CheckboxModule } from "@app/shared/components/checkbox/checkbox.module";
import { SvgIconModule } from "@app/shared/layout/icon/svg-icon.module";

let COMPONENTS = [
  SelectComponent,
  OptionComponent
];
let DIRECTIVES = [
  SelectOptionDirective,
  LabelTemplateDirective,
];


@NgModule({
  imports     : [
    CommonModule,
    FormsModule,
    CheckboxModule,
    DropdownModule,
    SvgIconModule
  ],
  declarations: [
    ...COMPONENTS, ...DIRECTIVES,
  ],
  exports     : [
    ...COMPONENTS, ...DIRECTIVES
  ]
})
export class SelectModule {
}
