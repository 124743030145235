import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LineLoaderComponent } from '@app/shared/layout/line-loader/line-loader.component';

@NgModule({
  imports     : [
    CommonModule,
  ],
  declarations: [
    LineLoaderComponent,
  ],
  exports     : [
    LineLoaderComponent,
  ],
})
export class LineLoaderModule {
}
