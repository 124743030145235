import { AppState } from '@app/shared/data/app-state.model';
import { SubAccount } from '@app/shared/data/sub-account/sub-account.models';
import { Collection } from '@app/shared/data/base.models';
import { StAction } from '@app/shared/data/st-action';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { SubAccountActions } from '@app/shared/data/sub-account/sub-account.actions';

const INITIAL_STATE: AppState = {
  data: new Collection<SubAccount>([]),
  loading: false,
  sending_invitation: false,
  removing_invitation: false,
  error: null,
};

export function subAccountReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {
  if (action.type === SubAccountActions.TYPES.SEND_INVITATION) {
    return {
      ...state,
      sending_invitation: true,
      error: null,
    };
  }

  if (action.type === SubAccountActions.TYPES.SEND_INVITATION_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].clone().updateOrPush(action.data, 'email', true),
      sending_invitation: false,
      error: null,
    };
  }

  if (action.type === SubAccountActions.TYPES.SEND_INVITATION_FAILED) {
    return {
      ...state,
      sending_invitation: false,
      error: action.data.error.error,
    };
  }

  if (action.type === SubAccountActions.TYPES.REMOVE_INVITATION) {
    return {
      ...state,
      removing_invitation: true,
      error: null,
    };
  }

  if (action.type === SubAccountActions.TYPES.REMOVE_INVITATION_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].clone().map(
        (subAccount: SubAccount) => {
          if (subAccount.id === action.data.subAccount.id) {
            return subAccount.setDeleted();
          }
          return subAccount;
        }
      ),
      removing_invitation: false,
      error: null,
    };
  }

  if (action.type === SubAccountActions.TYPES.REMOVE_INVITATION_FAILED) {
    return {
      ...state,
      removing_invitation: false,
      error: action.data,
    };
  }

  if (action.type === SubAccountActions.TYPES.UPDATE_INVITATION_SUCCEEDED) {
    return {
      ...state,
      data: state['data'].clone().map(
        (subAccount: SubAccount) => {
          if (subAccount.id === action.data.subAccount.id) {
            return action.data['subAccount'];
          }

          return subAccount;
        }
      )
    };
  }

  if (action.type === SubAccountActions.TYPES.SET_SUB_ACCOUNTS) {
    return {
      ...state,
      data: action.data,
    };
  }

  return paginationReducer(SubAccount)(state, action);
}
