import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseAPI } from "@app/shared/data/base.api";

@Injectable()
export class LsTaskApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public index() {
      const url = this.constructFullUrl('api/keyword-generator');
      return this.http.get(url);
  }

  public find(id) {
    const url = this.constructFullUrl('api/keyword-generator/' + id);
    return this.http.get(url);
  }

  public delete(id) {
    const url = this.constructFullUrl('api/keyword-generator/' + id);
    return this.http.delete(url);
  }

  public open(id) {
    const url = this.constructFullUrl('api/keyword-generator/' + id + "/open");
    return this.http.post(url, []);
  }
}
