import { SortByKeywordEnum } from "../product-manager/product-manager-keyword-manager/product-manager-keyword-manager.component";

export enum V2TableColumnType {
  STRING = 'STRING',
  PRIORITY = 'PRIORITY',
  REFRESH = 'REFRESH',
  TRACK_KEYWORD = 'TRACK_KEYWORD'
};

export enum V2TableDataItemType {
  DEFAULT = 'DEFAULT',
  CHECKED = 'CHECKED',
  NUMBER_WITH_STAR = 'NUMBER_WITH_STAR',
  KEYWORD_LABEL = 'KEYWORD_LABEL',
  KEYWORD_R2A = 'KEYWORD_R2A',
  TRACK_KEYWORD = 'TRACK_KEYWORD',
  RANK = 'RANK',
  PRIORITY = 'PRIORITY',
  INDEXED = 'INDEXED',
  SFR = 'SFR',
  GRAPH_DATA = 'GRAPH_DATA',
  ASINS = 'ASINS',
  DATE = 'DATE',
  BUTTON = 'BUTTON',
  MARKETPLACE = 'MARKETPLACE',
  STATUS = 'STATUS',
  ACTION = 'ACTION',
  PRODUCTS_LIST = 'PRODUCTS_LIST'
};

export enum V2TableColumnTopHeaderEnum {
  TITLE = 'TITLE',
  TRACK_KEYWORD = 'TRACK_KEYWORD',
  MY_ASIN = 'MY_ASIN',
  TARGET_ASIN = 'TARGET_ASIN',
  KEYWORD_METRICS = 'KEYWORD_METRICS',
}

export interface V2TableColumnTopHeader {
  id: V2TableColumnTopHeaderEnum;
  isFixed?: boolean;
  value: string;
  numberOfColumns: number;
  background?: string;
}

export interface V2TableColumn {
  id?: string;
  type: V2TableColumnType;
  title: string;
  hideTitle?: boolean;
  hideSort?: boolean;
  tooltip?: string;
  isFixed?: boolean;
  sortBy?: SortByEnum | SortByKeywordEnum;
  loading?: boolean;
  hide?: boolean;
  canToggle?: boolean;
  data?: { [key: string]: any };
}

export interface V2TableDataRecord {
  id?: string;
  type: V2TableDataItemType;
  hideEmptyValue?: boolean;
  background?: string;
  value: any;
  loadingGraphData?: boolean;
  graphOpened?: boolean;
  isFixed?: boolean;
  isPercentage?: boolean;
  isText?: boolean;
  onClick?: () => void;
}

export interface V2TableDataItem {
  columns: V2TableDataRecord[];
  id?: string;
  value?: any;
  checked?: boolean;
  loadingGraphData?: boolean;
  graphOpened?: boolean;
}

export enum ResizableColumnEnum {
  HEADER_PRIORITY = 'HEADER_PRIORITY',
  BODY_PRIORITY = 'BODY_PRIORITY',
  TABLE = 'TABLE'
}

export enum SortByEnum {
  STATUS = 'status',
  TITLE = 'title',
  RANK = 'sales_rank',
  KEYWORDS = 'keywords',
  PRICE = 'price',
  REVIEWS = 'reviews',
  DATE = 'created_at',
  NAME = 'name',
  EMAIL = 'email',
  NONE = 'none',
  OPTINS = 'opt_ins',
  ORGANIC_RANK = 'rank',
  RANKED_RESULTS = 'number_of_competitors',
  SFR = 'sfr_rank',
  VOLUME = 'impressions',
  SALES = 'sales',
  SALES_RATE = 'sales_rate',
  CLICK_DOMINANCE = 'click_dominance',
  SALES_DOMINANCE = 'search_dominance',
  PRIORITY = 'priority',
  INDEXED = 'indexed',
};
