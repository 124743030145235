import { StAction } from '../st-action';
import { CustomerListActions } from './customer-list.actions';
import {
  ICheckPhoneNumberResponse,
  ICustomer,
  ICustomerListResponse,
  ICustomerListState,
  ILoadAvailableFiltersResponse,
  ISavedFiltersListResponse,
  ISaveFilterResponse,
  SortByEnum,
  TypeListEnum
} from './customer-list.models';

const INITIAL_STATE: ICustomerListState = {
  customerList: [],
  currentPage: 1,
  lastPage: null,
  perPage: 30,
  sort: SortByEnum.NONE,
  total: null,
  from: null,
  to: null,
  stats: {
    all_customers_counter: null,
    vip_list_customers_counter: null,
    deny_list_customers_counter: null,
    unrated_phone_numbers_counter: 0,
  },

  appliedFilters: [],
  appliedView: null,
  savedFilters: [],
  availableFilters: [],
  list: TypeListEnum.NONE,
  searchTerm: '',
  credentialId: null,

  loadingCustomerList: false,
  checkingPhoneNumber: null,
  phoneNumberChecked: false,
  phoneNumberScore: null,
  phoneNumberCheckError: null,
  addingNewCustomer: false,
  newCustomerAdded: null,
  updatingCustomer: false,
  updatedCustomer: null,
  deletingCustomers: false,
  deletedCustomers: false,
  importingCustomers: false,
  importingCustomersId: null,
  importingCustomersProgress: 0,
  importingCustomersError: null,
  importingCustomersStats: null,
  savingFilters: false,
  savingFiltersError: false,
  loadingSavedFilters: false,
  loadingAvailableFilters: false,
  deletingView: false,
  checkingMissingPhoneNumberScores: false,
  checkingMissingPhoneNumberScoresError: null,
  movingCustomers: false,
  movedCustomers: false,
  exportingCustomers: false,
  exportedCustomers: null,
  error: false
}

export function customerListReducer(state: ICustomerListState = INITIAL_STATE, action: StAction): ICustomerListState {
  switch (action.type) {
    case CustomerListActions.TYPES.LOAD_CUSTOMER_LIST:
      let updates = { ...action.data };

      if (action.data.currentPage === null) {
        delete updates.currentPage;
      }
      if (action.data.perPage === null) {
        delete updates.perPage;
      }
      if (action.data.sort === null) {
        delete updates.sort;
      }

      return {
        ...state,
        ...updates,
        loadingCustomerList: true,
      };

    case CustomerListActions.TYPES.CUSTOMER_LIST_LOADED:
      const data: ICustomerListResponse = action.data;
      return {
        ...state,
        customerList: data.data,
        currentPage: data.meta.current_page,
        lastPage: data.meta.last_page,
        perPage: data.meta.per_page,
        total: data.meta.total || 0,
        from: data.meta.from || 0,
        to: data.meta.to || 0,
        stats: data.stats,
        loadingCustomerList: false,
        checkingMissingPhoneNumberScores: data.flags.missing_phone_ratings_check_in_progress,
      };

    case CustomerListActions.TYPES.CHECK_PHONE_NUMBER:
      return {
        ...state,
        checkingPhoneNumber: action.data.phoneNumber,
        phoneNumberCheckError: null,
        phoneNumberChecked: false,
      };

    case CustomerListActions.TYPES.CHECK_PHONE_NUMBER_SUCCESS:
      const checkPhoneNumberResponse: ICheckPhoneNumberResponse = action.data;
      const phoneNumber = checkPhoneNumberResponse.data.phone_number;

      const customerList = state.customerList.map((customer: ICustomer) => {
        if (customer.phone_numbers.includes(phoneNumber)) {
          customer.phone_number_scores[phoneNumber] = checkPhoneNumberResponse.data;
        }

        return customer;
      });

      return {
        ...state,
        customerList: customerList,
        checkingPhoneNumber: null,
        phoneNumberChecked: true,
        phoneNumberScore: action.data.data.score,
      };

    case CustomerListActions.TYPES.CHECK_PHONE_NUMBER_ERROR:
      return {
        ...state,
        checkingPhoneNumber: null,
        phoneNumberChecked: true,
        phoneNumberCheckError: action.data.message,
      };

    case CustomerListActions.TYPES.RESET_PHONE_NUMBER:
      return {
        ...state,
        checkingPhoneNumber: null,
        phoneNumberChecked: false,
        phoneNumberScore: null,
        phoneNumberCheckError: null,
      }

    case CustomerListActions.TYPES.ADD_CUSTOMER_RESET:
      return {
        ...state,
        addingNewCustomer: false,
        error: false,
        newCustomerAdded: null
      }

    case CustomerListActions.TYPES.ADD_CUSTOMER:
      return {
        ...state,
        addingNewCustomer: true,
        error: false
      };

    case CustomerListActions.TYPES.ADD_CUSTOMER_SUCCESS:
      return {
        ...state,
        addingNewCustomer: false,
        newCustomerAdded: action.data
      };

    case CustomerListActions.TYPES.ADD_CUSTOMER_ERROR:
      return {
        ...state,
        addingNewCustomer: false,
        error: action.data
      };

    case CustomerListActions.TYPES.UPDATE_CUSTOMER_RESET:
      return {
        ...state,
        updatingCustomer: false,
        error: false,
        updatedCustomer: null
      }

    case CustomerListActions.TYPES.UPDATE_CUSTOMER:
      return {
        ...state,
        updatingCustomer: true,
        error: false
      };

    case CustomerListActions.TYPES.UPDATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        updatingCustomer: false,
        updatedCustomer: true,
      }

    case CustomerListActions.TYPES.UPDATE_CUSTOMER_ERROR:
      return {
        ...state,
        updatingCustomer: false,
        error: action.data
      };

    case CustomerListActions.TYPES.DELETE_CUSTOMER_RESET:
      return {
        ...state,
        deletingCustomers: false,
        deletedCustomers: false,
        error: false,
      }

    case CustomerListActions.TYPES.DELETE_CUSTOMER:
      return {
        ...state,
        deletingCustomers: true,
        error: false
      };

    case CustomerListActions.TYPES.DELETE_CUSTOMER_SUCCESS:
      return {
        ...state,
        deletingCustomers: false,
        deletedCustomers: true
      };

    case CustomerListActions.TYPES.DELETE_CUSTOMER_ERROR:
      return {
        ...state,
        deletingCustomers: false,
        error: action.data
      };

    case CustomerListActions.TYPES.SET_FILTERS:
      return {
        ...state,
        appliedFilters: action.data.filters,
        appliedView: null, // remove "applied view" as only one can be used at the same time
      };

    case CustomerListActions.TYPES.SET_LIST:
      return {
        ...state,
        list: action.data.list,
      };

    case CustomerListActions.TYPES.SEARCH:
      return {
        ...state,
        searchTerm: action.data.searchTerm,
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS:
      return {
        ...state,
        importingCustomers: true,
        importingCustomersId: null,
        importingCustomersProgress: 0,
        importingCustomersError: null,
        importingCustomersStats: null
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS_RESET:
      return {
        ...state,
        importingCustomers: false,
        importingCustomersId: null,
        importingCustomersProgress: 0,
        importingCustomersError: null,
        importingCustomersStats: null
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        importingCustomersError: false,
        importingCustomersId: action.data.id,
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS_ERROR:
      return {
        ...state,
        importingCustomers: false,
        importingCustomersError: true
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS_PROGRESS_CHANGED:
      if (state.importingCustomersId !== action.data.data.request_id) {
        return state;
      }

      return {
        ...state,
        importingCustomersProgress: action.data.data.percentage
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS_WALLET_PROGRESS:
      const importedWallet = action.data.data.email + action.data.data.phone;
      if (action.data.data.total === importedWallet) {
        return {
          ...state,
          importingCustomersProgress: 100,
          importingCustomers: false
        };
      }

      return {
        ...state,
        importingCustomersProgress: importedWallet * 100 / action.data.data.total
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS_BLACKLIST_PROGRESS:
      const importedBlacklist = action.data.data.email + action.data.data.phone;
      if (action.data.data.total === importedBlacklist) {
        return {
          ...state,
          importingCustomersProgress: 100,
          importingCustomers: false
        };
      }

      return {
        ...state,
        importingCustomersProgress: importedBlacklist * 100 / action.data.data.total
      };

    case CustomerListActions.TYPES.IMPORT_CUSTOMERS_FINISHED:
      return {
        ...state,
        importingCustomers: false,
        importingCustomersId: null,
        importingCustomersProgress: 100,
        importingCustomersStats: action.data.stats
      };

    case CustomerListActions.TYPES.SAVE_FILTERS:
      return {
        ...state,
        savingFilters: true,
        savingFiltersError: false
      };

    case CustomerListActions.TYPES.SAVE_FILTERS_SUCCESS:
      const response: ISaveFilterResponse = action.data;

      const savedFilters = [
        ...state.savedFilters,
        response.data
      ];

      return {
        ...state,
        savedFilters,
        savingFilters: false,
      };

    case CustomerListActions.TYPES.SAVE_FILTERS_ERROR:
      return {
        ...state,
        savingFilters: false,
        savingFiltersError: true
      };

    case CustomerListActions.TYPES.LOAD_SAVED_FILTERS:
      return {
        ...state,
        loadingSavedFilters: true,
      };

    case CustomerListActions.TYPES.LOAD_SAVED_FILTERS_SUCCESS:
      const savedFiltersListResponse: ISavedFiltersListResponse = action.data;

      return {
        ...state,
        loadingSavedFilters: false,
        savedFilters: savedFiltersListResponse.data
      };

    case CustomerListActions.TYPES.LOAD_AVAILABLE_FILTERS:
      return {
        ...state,
        loadingAvailableFilters: true,
      };

    case CustomerListActions.TYPES.LOAD_AVAILABLE_FILTERS_SUCCESS:
      const availableFiltersResponse: ILoadAvailableFiltersResponse = action.data;

      return {
        ...state,
        loadingAvailableFilters: false,
        availableFilters: availableFiltersResponse.data
      };

    case CustomerListActions.TYPES.LOAD_AVAILABLE_FILTERS_ERROR:
      return {
        ...state,
        loadingAvailableFilters: false,
      };

    case CustomerListActions.TYPES.APPLY_VIEW:
      return {
        ...state,
        appliedFilters: [], // remove "manual" filters as only one can be used at the same time
        appliedView: action.data,
      };

    case CustomerListActions.TYPES.DELETE_VIEW:
      return {
        ...state,
        deletingView: true,
      };

    case CustomerListActions.TYPES.DELETE_VIEW_SUCCESS:
      return {
        ...state,
        deletingView: false,
      };

    case CustomerListActions.TYPES.DELETE_VIEW_ERROR:
      return {
        ...state,
        deletingView: false,
      };

    case CustomerListActions.TYPES.MOVE_CUSTOMERS_RESET:
      return {
        ...state,
        movingCustomers: false,
        error: false,
        movedCustomers: null
      };

    case CustomerListActions.TYPES.MOVE_CUSTOMERS:
      return {
        ...state,
        movingCustomers: true,
        error: false
      };

    case CustomerListActions.TYPES.MOVE_CUSTOMERS_SUCCESS:
      return {
        ...state,
        movingCustomers: false,
        movedCustomers: true,
      };

    case CustomerListActions.TYPES.MOVE_CUSTOMERS_ERROR:
      return {
        ...state,
        movingCustomers: false,
        error: action.data
      };

    case CustomerListActions.TYPES.CHECK_MISSING_PHONE_NUMBER_SCORES:
      return {
        ...state,
        checkingMissingPhoneNumberScores: true,
        checkingMissingPhoneNumberScoresError: null,
      };

    case CustomerListActions.EVENTS.CHECK_MISSING_PHONE_RATINGS_FINISHED:
      return {
        ...state,
        checkingMissingPhoneNumberScores: false,
        checkingMissingPhoneNumberScoresError: action.data,
      };

    case CustomerListActions.TYPES.CHECK_MISSING_PHONE_NUMBER_SCORES_ERROR:
      return {
        ...state,
        checkingMissingPhoneNumberScores: false,
        checkingMissingPhoneNumberScoresError: action.data,
      };

    case CustomerListActions.TYPES.EXPORT_CUSTOMERS:
      return {
        ...state,
        exportingCustomers: true,
        error: false
      };

    case CustomerListActions.TYPES.EXPORT_CUSTOMERS_SUCCESS:
      return {
        ...state,
        exportingCustomers: false,
        exportedCustomers: action.data,
      };

    case CustomerListActions.TYPES.EXPORT_CUSTOMERS_ERROR:
      return {
        ...state,
        exportingCustomers: false,
        error: action.data
      };

    case CustomerListActions.TYPES.EXPORT_CUSTOMERS_RESET:
      return {
        ...state,
        exportingCustomers: false,
        exportedCustomers: null,
        error: false
      };

    case CustomerListActions.TYPES.SET_CREDENTIAL_ID:
      return {
        ...state,
        credentialId: action.data.credentialId,
      };

    case CustomerListActions.TYPES.LOAD_CUSTOMER_ORDER_ITEMS:
      return {
        ...state,
        customerList: state.customerList.map((customer: ICustomer) => {
          if (customer.id === action.data.customerId) {
            delete customer.orderItems;
          }

          return customer;
        })
      };

    case CustomerListActions.TYPES.LOAD_CUSTOMER_ORDER_ITEMS_SUCCESS:
      return {
        ...state,
        customerList: state.customerList.map((customer: ICustomer) => {
          if (customer.id === action.data.customerId) {
            customer.orderItems = action.data.orderItems;
            let today = new Date();
            customer.orderItems.completed.map((order) => order.valid_warranty = new Date(order.warranty_to) > today || !order.warranty_to);
          }

          return customer;
        })
      };

    case CustomerListActions.TYPES.LOAD_CUSTOMER_ORDER_ITEMS_ERROR:
      return {
        ...state,
        customerList: state.customerList.map((customer: ICustomer) => {
          if (customer.id === action.data.customerId) {
            customer.detailsOpened = false;
          }

          return customer;
        })
      };

    default:
      return state;
  }
}
