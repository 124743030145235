import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ToastService } from '@app/core/services/toast.service';
import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { ExchangeRatesActions } from './exchange-rates.actions';
import { ExchangeRatesApi } from './exchange-rates.api';
import { ExchangeRate } from './exchange-rates.models';
import { Collection } from '../base.models';

@Injectable()
export class ExchangeRatesEpics {
  constructor(
    private exchangeRatesApi: ExchangeRatesApi,
    protected toastService: ToastService,
    private exchangeRatesActions: ExchangeRatesActions) {
  }

  public createEpic() {
    return [
      this.load,
    ];
  }

  load = (store: NgRedux<AppState>) => (next: (action: StAction) => AppState) => {
    return (action: StAction) => {
      const n = next(action);

      if (action.type === ExchangeRatesActions.TYPES.LOAD) {
        this.exchangeRatesApi.get()
          .subscribe(
            (rates: Collection<ExchangeRate>) => {
              store.dispatch(this.exchangeRatesActions.loadSucceeded(rates));
            },
            (response) => {
              store.dispatch(this.exchangeRatesActions.loadFailed(response.error));
            },
          );
      }

      return n;
    };
  }
}
