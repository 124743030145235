import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DotLoaderComponent } from '@app/shared/layout/dot-loader/dot-loader.component';

@NgModule({
  imports     : [],
  declarations: [
    DotLoaderComponent,
  ],
  exports     : [
    DotLoaderComponent,
  ],
})
export class DotLoaderModule {
}
