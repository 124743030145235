<button class="button interface-component {{ buttonClass }}"
        [type]="type"
        [ngClass]="{icon:icon, attention: attention, active: active}"
        [disabled]="disabled || loading">
  <!--<span *ngIf="icon" class="icon">-->
    <!--<st-icon [icon]="icon"></st-icon>-->
  <!--</span>-->
    <st-icon (click)="noNoting($event)" icon="spinner"
             class="icon-responsive" *ngIf="loading"></st-icon>
    <st-icon *ngIf="!loading" icon="{{ icon }}"
             (click)="noNoting($event)" class="icon icon-m"></st-icon>
    <ng-content></ng-content>
</button>
