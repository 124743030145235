import { PipeTransform, Injectable, Pipe } from '@angular/core';

@Pipe({
  name: 'stDiscountedPrice',
  pure: false
})
@Injectable()
export class DiscountedPricePipe implements PipeTransform {
  transform(value: any, discount: any): any {
    if (discount == null || !discount.valid || !discount.code) {
      return value;
    }

    if (discount.percent_off != null) {
      const num = value * (1 - discount.percent_off / 100);
      return Math.round(num * 100) / 100;
    }

    const v = Math.round(value - discount.amount_off);

    if (v < 0) {
      return 0;
    }

    return v;
  }
}
