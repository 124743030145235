
import {of as observableOf,  Subscription ,  Subject ,  BehaviorSubject ,  Observable } from 'rxjs';

import {catchError, tap, switchMap, skip, merge, delay, takeUntil, map, bufferWhen, take, filter} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { Collection, FePaginationCollection } from '@app/shared/data/base.models';
import { KeywordGeneratedActions } from '@app/shared/data/keyword-generated/keyword-generated.actions';
import { KeywordGeneratedAPI } from '@app/shared/data/keyword-generated/keyword-generated.api';
import { KeywordGenerated } from '@app/shared/data/keyword-generated/keyword-generated.models';
import { StAction } from '@app/shared/data/st-action';
import { ToastService } from '@app/core/services/toast.service';
import { environment } from '@env/environment';
import { HttpResponse } from '@angular/common/http';
import { Filter } from '@app/shared/components/filter-builder/filter/filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { getTranslationFromResponse } from '@app/helpers';

@Injectable()
export class KeywordGeneratedEpics {
  private loadPageSubscription: Subscription = null;
  private appender$: Subject<any> = new Subject();
  private appenderCloser$: Subject<any> = new Subject<any>();
  private isReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private static unComparator = (x, y) => {
    return x === undefined || y === undefined || x === y; // Prevent double requests
  }

  private static filterUnfinished(showUnfinished: boolean, kw: KeywordGenerated) {
    return showUnfinished ? true : kw.impressionExact !== false && kw.impressionBroad !== false && kw.resultsCount !== false;
  }

  constructor(private keywordGeneratedActions: KeywordGeneratedActions,
    private store: NgRedux<AppState>,
    private keywordGeneratedAPI: KeywordGeneratedAPI,
    private toastService: ToastService,
    private i18n: I18n) {
  }

  public createEpic() {
    return [
      this.initData,
      this.loadPage,
      this.socketEvent,
      this.requestSuggestions,
      this.loadRecent,
      this.resetData,
      this.loadHistory
    ];
  }

  socketEvent = store => next => {
    return (action: StAction) => {
      // TODO: check if group id is same -> action.groupId (action.data.group_id)
      switch (action.type) {
        case KeywordGeneratedActions._events.suggestions_received:
          if (action.data.group_id === store.getState()['keyword_generator']['group_id']) {
            const keywords = Object.keys(action.data.keywords).map(function (kw) {
              return new KeywordGenerated(action.data.keywords[kw]);
            });

            this.appender$.next(keywords);
          }
          break;
        case KeywordGeneratedActions._events.suggestions_finished:
          if (action.data.group_id === store.getState()['keyword_generator']['group_id']) {
            console.log('FINISH...', action.data.group_id, Object.values(action.data.keywords).length);
          }
          // TODO: Fix data handling on frontend
          break;
      }
      return next(action);
    };
  }

  requestSuggestions = store => next => {
    // TODO: change active keyword, reset suggestions
    return (action) => {
      if (action.type === KeywordGeneratedActions.TYPES.SUGGESTIONS_REQUESTED) {
        this.isReady$.pipe(filter(x => x),take(1),).subscribe(() => {
          this.store.dispatch(this.keywordGeneratedActions.setActiveKeyword(action.data['keyword'], action.data['group_id'], true));

          this.appenderCloser$.next(true);
          this.appender$.complete();
          this.appender$ = new Subject<any>();
          this.appender$.pipe(
            bufferWhen(() =>
              this.appender$.pipe(delay(5000),
                merge(this.appender$.pipe(skip(environment.BULK_BUFFER_SIZE),take(1),)),)
            ),
            map(x => [].concat(...x)),
            map(this.keywordGeneratedActions.appendData.bind(this.keywordGeneratedActions)),
            takeUntil(this.appenderCloser$),)
            .subscribe(this.store.dispatch.bind(this.store));

          // Sockets
          this.keywordGeneratedAPI.requestSuggestions(action.data['keyword'], action.data['group_id'], action.data['marketplace'])
            .subscribe(
              (resp: HttpResponse<Collection<KeywordGenerated>>) => {
                const suggestions = resp.body;
                const keywords = Object.keys(suggestions).map(function (kw) {
                  return new KeywordGenerated(suggestions[kw]);
                });

                if (resp.status === 206) {
                  this.store.dispatch(this.keywordGeneratedActions.appendData(keywords));
                } if (resp.status !== 202) {
                  this.store.dispatch(this.keywordGeneratedActions.setSuggFinished(keywords, action.data['group_id']));
                }
                
                this.store.dispatch(this.keywordGeneratedActions.loadRecent());
              },
              (error) => {
                console.log('RESPONSE FAILED!!');
                console.log(error);
                //noinspection TypeScriptValidateTypes
                this.store.dispatch(this.keywordGeneratedActions.loadPageFailed(error));
              },
              () => {
              }
            );
        });
      }
      return next(action);
    };
  }

  initData = store => next => {
    return (action) => {
      if (action.type === this.keywordGeneratedActions.initData().type) {
        const nextAction = next(action);
        this.store.dispatch(this.keywordGeneratedActions.setData(new FePaginationCollection));
        this.store.dispatch(this.keywordGeneratedActions.setActiveKeyword(action.data['keyword'], action.data['group_id'], false));

        this.isReady$.next(true);

        return nextAction;
      }
      return next(action);
    };
  }

  resetData = store => next => action => {
    if (action.type === KeywordGeneratedActions.TYPES.RESET) {
      this.isReady$.next(false);
    }
    return next(action);
  }

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type === KeywordGeneratedActions.getPagActionTypes(KeywordGenerated).LOAD_PAGE) {

        if (!('_data' in store.getState()['keyword_generator'])) {
          //noinspection TypeScriptValidateTypes
          this.store.dispatch(this.keywordGeneratedActions.loadPageFailed(this.i18n('Data not initialized')));
          return;
        }

        if (this.loadPageSubscription) {
          this.loadPageSubscription.unsubscribe();
        }

        // Listen for base temp _data change
        this.loadPageSubscription = this.store
          .select(['keyword_generator', '_data'], KeywordGeneratedEpics.unComparator).pipe(

          switchMap(data => this.store.select(['keyword_generator', 'filters']).pipe(map(filters => ({ data, filters })))),
          map(this.createFiltered.bind(this)),
          tap(data => this.store.dispatch(this.keywordGeneratedActions.setFiltered(data))),)
          .subscribe((data: FePaginationCollection<KeywordGenerated>) => {
            this.createPage(data, action);
          });
      }

      if (action.type == KeywordGeneratedActions.getPagActionTypes(KeywordGenerated).LOAD_PAGE_FAILED) {
        this.toastService.error(
          this.i18n('Request failed'),
          getTranslationFromResponse([
            {
              code: 'error.ls_failed',
              translation: this.i18n('Error getting keyword suggestions.')
            },
            {
              code: 'error.limits_reached',
              translation: this.i18n(
                'Maximum number of Last Searches reached! Limit is {{limit}} searches / billing period',
                {limit: action.data.error.error.message}
              )
            },
            {
              code: 'forbidden',
              translation: this.i18n('Access denied for the marketplace')
            }], action.data)
        );
      }
      return next(action);
    };
  }

  private createFiltered(
    { data, filters }: { data: FePaginationCollection<KeywordGenerated>, filters: { filters: Filter[], showUnfinished: boolean } }
  ) {
    const filterObjects: Filter[] = filters.filters;
    const showUnfinished: boolean = filters.showUnfinished;
    const filtersAreSet = filterObjects && Object.keys(filterObjects).length !== 0;

    // Skip filtering if unfinished products should be visible and no filters are set
    if (!data || (showUnfinished && !filtersAreSet)) {
      return data;
    }

    const filterFn = (kw: KeywordGenerated) => {
      const kwComplete = KeywordGeneratedEpics.filterUnfinished(showUnfinished, kw);

      if (filtersAreSet) {
        return !filterObjects.some((filter: Filter) => { // Returns false if none of the conditions match
          switch (filter.field) {
            case 'keyword'        :
              if (!Filter.filterOperator(kw.keyword, filter)) {
                return true;
              }
              break;
            case 'word_count':
              if (!(kwComplete && Filter.filterOperator(kw.keyword.trim().split(/\s+/).length, filter))) {
                return true;
              }
              break;
            case 'result_count':
              if (!(kwComplete && Filter.filterOperator(kw.resultsCount, filter))) {
                return true;
              }
              break;
            case 'categories':
              if (!(kwComplete && Filter.filterOperator(kw.categories, filter))) {
                return true;
              }
              break;
            case 'impressions_exact':
              if (!(kwComplete && Filter.filterOperator(kw.impressionExact, filter))) {
                return true;
              }
              break;
            case 'impressions_broad':
              if (!(kwComplete && Filter.filterOperator(kw.impressionBroad, filter))) {
                return true;
              }
              break;
            case 'impressions_p':
              if (!(kwComplete && Filter.filterOperator(kw.impressionP, filter))) {
                return true;
              }
              break;
            default:
              console.error('Incorrect filter field: ', filter);
              return true;
          }
        });
      } else {
        return kwComplete;
      }
    };

    return data.filter(filterFn) as FePaginationCollection<KeywordGenerated>;
  }

  loadRecent = store => next => action => {
    if (action.type === KeywordGeneratedActions.TYPES.LOAD_RECENT) {
      // Request
      this.keywordGeneratedAPI.requestRecent().subscribe((data) => {
        this.store.dispatch(this.keywordGeneratedActions.setRecent(data));
      }, (error) => {
        this.store.dispatch(this.keywordGeneratedActions.loadRecentFailed(error.error));
      });
    }
    return next(action);
  }

  loadHistory = store => next => action => {
    if (action.type === KeywordGeneratedActions.TYPES.LOAD_HISTORY) {
      this.keywordGeneratedAPI.requestHistory().pipe(
        map((data) => this.keywordGeneratedActions.loadHistorySucceeded(data)),
        catchError((error) => observableOf(this.keywordGeneratedActions.loadHistoryFailed(error))),)
        .subscribe(this.store.dispatch.bind(this));
    }
    return next(action);
  }

  private createPage(_data: FePaginationCollection<KeywordGenerated>, action) {
    if (!_data) {
      return;
    }

    setTimeout(() => this.store.dispatch(this.keywordGeneratedActions.setPage(_data.createPage(action.data.pagination))), 0);
  }
}
