
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { BaseAPI } from '@app/shared/data/base.api';
import { Observable } from 'rxjs';
import { SystemSettings } from '@app/shared/data/admin/system-settings/system-settings.models';

@Injectable()
export class SystemSettingsApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public fetch(): Observable<SystemSettings> {
    return this.http.get(this.constructFullUrl(`api/admin/system-settings`)).pipe(
      map(data => new SystemSettings(data)));
  }

  public save(settings: SystemSettings): Observable<SystemSettings> {
    return this.http.put(this.constructFullUrl(`api/admin/system-settings/0`), settings).pipe(
      map(data => new SystemSettings(data)));
  }
}
