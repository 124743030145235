import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { LogoutAuth0Component } from './logout-auth0.component';

const routes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('@app/guest/guest.module').then(m => m.GuestModule)
  },
  {
    path: '',
    loadChildren: () => import('@app/client/client.module').then(m => m.ClientModule)
  }, {
    path: 'auth/logout',
    component: LogoutAuth0Component,
    data: {
      isLoadingPage: true
    }
  }, {
    path        : 'admin',
    loadChildren: () => import('@app/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path      : 'docs',
    loadChildren: () => import('@app/client/docs/docs.module').then(m => m.DocsModule),
  },
  {
    path      : '**',
    redirectTo: '',
    pathMatch : 'full'
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      relativeLinkResolution: 'legacy',
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabled',
      // preloadingStrategy: PreloadAllModules,
    })
  ],
  exports: [
    RouterModule
  ],
})
export class AppRoutingModule {
}
