import { AfterViewInit, Directive, EventEmitter, Input, Output, ViewChildren } from '@angular/core';
import { ChartEvent } from '@app/shared/components/charts/models/chart.events';
import { AxisModel } from '@app/shared/components/charts/models/axis.model';
import { AxisOptions } from 'highcharts';
import { HighchartsService } from '@app/shared/components/charts/highcharts.service';
import { LineDirective } from '@app/shared/components/charts/directives/line.directive';
import { Subject } from 'rxjs';

@Directive({
  selector: 'axis',
})
export class AxisDirective implements AfterViewInit {
  @Output() afterBreaks = new EventEmitter<ChartEvent>();
  @Output() afterSetExtremes = new EventEmitter<ChartEvent>();
  @Output() pointBreak = new EventEmitter<ChartEvent>();
  @Output() pointInBreak = new EventEmitter<ChartEvent>();
  @Output() setExtremes = new EventEmitter<ChartEvent>();

  public ID: Subject<number> = new Subject();

  private _options = new AxisModel();

  @Input()
  set options(options: AxisOptions) {
    this._setOption(options);
  }

  @Input()
  set title(title) {
    this._setOption({
      title: { text: title },
    });
  }
  @Input()
  set min(value: number) {
    this._setOption({
      min: value,
    });
  }

  @Input()
  set max(value: number) {
    this._setOption({
      max: value,
    });
  }

  @Input()
  set reversed(value: boolean) {
    this._setOption({
      reversed: value,
    });
  }

  @Input()
  set opposite(value: boolean) {
    this._setOption({
      opposite: value,
    });
  }
  @Input()
  set allowDecimals(value: boolean) {
    this._setOption({
      allowDecimals: value,
    });
  }

  @Input()
  set visible(value: boolean) {
    this._setOption({
      visible: value,
    });
  }

  constructor(private _highchartsService: HighchartsService) {
  }

  private _setOption(opt) {
    this._options = Object.assign(this._options, opt);
  }

  ngAfterViewInit(): void {
    this.ID.next(this._highchartsService.addAxis(this));
  }

  getOptions(): AxisModel {
    this._options.listenForEvents(this);
    return this._options;
  }
}
