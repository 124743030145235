<div class="table-column__keyword-r2a" *ngIf="item.value">
  <div class="table-column__keyword-r2a-value">{{item.value.value}}</div>
  <div class="table-column__keyword-r2a-category" *ngIf="!!item.value.categories && item.value.categories.length > 0"
       popperTrigger="hover"
       popperPlacement="bottom"
       popperAppendTo="body"
       [popper]="categoriesTooltip"
  >
    {{item.value.categories[0]}}
  </div>
</div>
<a [href]="getAmazonLink()" target="_blank" class="table__column--label-amazon" *ngIf="item.value">
  <st-icon icon="amazon_new"></st-icon>
</a>


<popper-content #categoriesTooltip>
  <div class="popper--wrapper">
    {{ item.value?.categories.join(", ") }}
  </div>
</popper-content>
