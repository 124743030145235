import { Injectable } from '@angular/core';
import { Collection } from '@app/shared/data/base.models';
import { NgRedux } from '@angular-redux/store';
import { AppState } from '@app/shared/data/app-state.model';
import { NoticeActions } from '@app/shared/data/notice/notice.actions';
import { StAction } from '@app/shared/data/st-action';
import { NoticeApi } from '@app/shared/data/notice/notice.api';
import { Notice } from '@app/shared/data/notice/notice.models';

@Injectable()
export class NoticeEpics {

  constructor(private store: NgRedux<AppState>,
              private noticeApi: NoticeApi) {
  }

  public createEpic() {
    return [
      this.socketEvent,
      this.loadNotices,
    ];
  }

  socketEvent = store => next => {
    return (action: StAction) => {
      switch (action.type) {
        case NoticeActions._events.notice_created:
          return this.store.dispatch(NoticeActions.newNoticeCreated(new Notice(action.data.data)));
        case NoticeActions._events.notice_updated:
          return this.store.dispatch(NoticeActions.noticeUpdated(new Notice(action.data.data)));
        case NoticeActions._events.notice_deleted:
          return this.store.dispatch(NoticeActions.noticeDeleted(new Notice(action.data.data)));
      }
      return next(action);
    };
  };

  loadNotices = store => next => {
    return (action: StAction) => {
      if (action.type == NoticeActions.TYPES.LOAD_SYSTEM_NOTICES) {

        this.noticeApi.all(action.data)
          .subscribe(
            (notice: Collection<Notice>) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(NoticeActions.loadNotificationsSucceeded(notice));
            },
            (response) => {
              //noinspection TypeScriptValidateTypes
              this.store.dispatch(NoticeActions.loadNotificationsFailed(response));
            },
            () => {

            },
          );
      }

      return next(action);
    };
  };
}
