import { AppState } from "@app/shared/data/app-state.model";
import { Collection } from "@app/shared/data/base.models";
import { StAction } from "@app/shared/data/st-action";
import { Usage } from "@app/shared/data/usage/usage.models";
import { UsageActions } from "@app/shared/data/usage/usage.actions";

const INITIAL_STATE: AppState = {
  data   : new Collection<Usage>([]),
  loading: false,
  error  : null,
};

export function usagesReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type == UsageActions.TYPES.GET_USAGE) {
    return {
      ...state,
      loading: true,
      error  : null
    }
  }

  if (action.type == UsageActions.TYPES.GET_USAGE_SUCCEEDED || action.type == UsageActions.TYPES.USAGE_UPDATED) {
    return {
      ...state,
      data   : action.data,
      loading: false,
      error  : null
    };
  }

  if (action.type == UsageActions.TYPES.GET_USAGE_FAILED) {
    return {
      ...state,
      loading: false,
      error  : action.data
    };
  }

  return state;
}
