
import {catchError} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { BaseAPI } from "@app/shared/data/base.api";
import { Observable } from "rxjs";
import { Survey } from "@app/shared/data/survey/survey.models";
import { handleError } from "@app/helpers";

@Injectable()
export class SurveyAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public get(surveyId: string): Observable<Survey> {
    return this.http.get<Survey>(this.constructFullUrl(`api/survey/${surveyId}`)).pipe(catchError(handleError));
  }

  public submit(survey: Survey): Observable<any> {
    return this.http.post(this.constructFullUrl(`api/survey/submit`), survey).pipe(
      catchError(handleError));
  }
}
