import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatatableComponent } from './datatable.component';
import { SvgIconModule } from '@app/shared/layout/icon/svg-icon.module';
import { ColmComponent } from '@app/shared/components/datatable/components/colm.component';
import { HeadDirective } from '@app/shared/components/datatable/directives/head.directive';
import { CellDirective } from '@app/shared/components/datatable/directives/cell.directive';
import { ColmBuilderDirective } from '@app/shared/components/datatable/directives/colm-builder.directive';
import { ColmPipe } from '@app/shared/components/datatable/pipes/colm-filter.pipe';
import { SelectModule } from '@app/shared/components/select/select.module';
import { StPagerComponent } from '@app/shared/components/datatable/components/st-pager.component';
import { FormsModule } from '@angular/forms';
import { NoContentDirective } from '@app/shared/components/datatable/directives/no-content.directive';
import {
  AccordionDirective,
  AccordionTemplateDirective, AccordionToggleDirective, AccordionOpenDirective
} from '@app/shared/components/datatable/directives/accordion-content.directive';
import {PopoverModule} from '@app/shared/components/popover/popover.module';
import {StSortPipe} from '@app/shared/components/datatable/pipes/sort.pipe';
import { StSimplePagerComponent } from './components/st-simple-pager.component';
import { LineLoaderModule } from '@app/shared/layout/line-loader/line-loader.module';
import { MyForOfModule } from './directives/my-for.module';

@NgModule({
  imports     : [
    CommonModule,
    SvgIconModule,
    SelectModule,
    FormsModule,
    PopoverModule,
    LineLoaderModule,
    MyForOfModule,
  ],
  declarations: [
    DatatableComponent,
    ColmComponent,
    StPagerComponent,
    StSimplePagerComponent,
    HeadDirective,
    CellDirective,
    ColmBuilderDirective,
    NoContentDirective,
    ColmPipe,
    StSortPipe,

    AccordionDirective,
    AccordionTemplateDirective,
    AccordionToggleDirective,
    AccordionOpenDirective,
  ],
  exports     : [
    DatatableComponent,
    ColmComponent,
    HeadDirective,
    CellDirective,
    NoContentDirective,

    AccordionDirective,
    AccordionTemplateDirective,
    AccordionToggleDirective,
    AccordionOpenDirective,
  ]
})
export class DatatableModule {}
