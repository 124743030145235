import { LayoutActions } from "@app/shared/layout/layout.actions";
import { AppState } from "@app/shared/data/app-state.model";
import { StAction } from "@app/shared/data/st-action";
import { Toast } from "@app/shared/layout/toasts/toast.model";

export interface LayoutState {
  left_sidebar_opened: boolean;
  toasts: Array<Toast>;
}

const initialState: LayoutState = {
  left_sidebar_opened: false,
  toasts             : [],
};

export function layoutReducer(state = initialState, action: StAction): AppState {

  switch (action.type) {
    case LayoutActions.TYPES.ADD_TOAST: {
      return Object.assign({}, state, {
        toasts: [...state.toasts, action.data]
      });
    }

    case LayoutActions.TYPES.REMOVE_TOAST: {
      return Object.assign({}, state, {
        toasts: state.toasts.filter(toast =>
          toast.id !== action.data['id']
        )
      });
    }

    case LayoutActions.TYPES.CLOSE_LEFT_SIDENAV: {
      return Object.assign({}, state, {
        left_sidebar_opened: false
      });
    }

    case LayoutActions.TYPES.OPEN_LEFT_SIDENAV: {
      return Object.assign({}, state, {
        left_sidebar_opened: true
      });
    }

    default:
      return state;
  }
}
