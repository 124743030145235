import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { NoticeActions } from '@app/shared/data/notice/notice.actions';
import { Collection } from '@app/shared/data/base.models';
import { Notice } from '@app/shared/data/notice/notice.models';

interface NoticeState extends AppState {
  system: Collection<Notice>;
  user: Collection<Notice>;
  height: number;
}

const INITIAL_STATE: NoticeState = {
  system: new Collection(),
  user  : new Collection(),
  height: 0
};

export function noticeReducer(state: NoticeState = INITIAL_STATE, action: StAction): NoticeState {
  if (action.type === NoticeActions.TYPES.SET_NOTICES_HEIGHT)
    return {
      ...state,
      height: action.data + 0
    };

  if (action.type == NoticeActions.TYPES.NEW_SYSTEM_NOTICE_CREATED) {
    return {
      ...state,
      system: state.system.clone().push(action.data)
    };
  }

  if (action.type == NoticeActions.TYPES.LOAD_SYSTEM_NOTICES_SUCCESS)
    return {
      ...state,
      system: action.data
    };

  if (action.type == NoticeActions.TYPES.ADD_USER_NOTICE)
    return {
      ...state,
      user: state.user.exists(notice => notice.id == action.data.id)
        ? state.user
        : state.user.clone().push(action.data)
    };

  if (action.type == NoticeActions.TYPES.SYSTEM_NOTICE_UPDATED) {
    return {
      ...state,
      system: state.system.clone().updateOrPush(action.data)
    };
  }

  if (action.type == NoticeActions.TYPES.SYSTEM_NOTICE_DELETED) {
    return {
      ...state,
      system: state.system.filter(n => n.id != action.data.id)
    };
  }

  if (action.type == NoticeActions.TYPES.REMOVE_USER_NOTICE)
    return {
      ...state,
      user: state.user.filter(notice => notice.id !== action.data.id)
    };


  return state;
}
