import { Injectable } from '@angular/core';
import { BaseAPI } from '@app/shared/data/base.api';
import { HttpClient, HttpParams } from '@angular/common/http';
import { CustomHttpParamEncoder } from '@app/shared/UrlEncoders/get-param-encoder';

@Injectable()
export class BrowseProductsApi extends BaseAPI {
  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  browseByAsins(asins: string[]) {
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });

    asins.forEach((searchedAsin: string) => {
      params = params.append('asins[]', searchedAsin);
    });

    return this.http.get(this.constructFullUrl(`api/v2/competition-research/search`), {params});
  }

  browseByKeyword(keyword: string) {
    let params: HttpParams = new HttpParams({
      encoder: new CustomHttpParamEncoder(),
    });

    params = params.set('keyword', keyword);

    return this.http.get(this.constructFullUrl(`api/v2/competition-research/search`), {params});
  }
}
