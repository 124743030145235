import { NgModule } from '@angular/core';

import { BillingRoutingModule, } from '@app/client/billing/billing.routing';
import { InvoiceAPI } from '@app/shared/data/invoice/invoice.api';
import { SharedModule } from '@app/shared/shared.module';
import { CreditCardAPI } from '@app/shared/data/credit-card/credit-card.api';
import { PlanAPI } from '@app/shared/data/plan/plan.api';
import { InvoicesComponent } from '@app/client/billing/modals/invoices.component';
import { BillingCurrentPlanComponent } from '@app/client/billing/partials/current-plan.component';
import { StFormsModule } from '@app/shared/layout/forms/forms.module';
import { EditBillingInformationComponent } from '@app/client/billing/modals/edit-billing-information.component';
import { ChooseAnotherCardComponent } from '@app/client/billing/modals/choose-another-card.component';
import { CancellationSurveyComponent } from '@app/client/billing/cancellation-survey/cancellation-survey.component';
import { CheckboxModule } from '@app/shared/components/checkbox/checkbox.module';
import { SurveyQuestionComponent } from './cancellation-survey/survey-question/survey-question.component';
import { UpdatePlanModule } from '@app/client/modals/update-plan/update-plan.module';
import { TrackingModule } from '@app/shared/tracking/tracking.module';
import { UpgradeSubscriptionModalComponent } from './modals/upgrade-subscription-modal/upgrade-subscription-modal.component';
import { LayoutV2Module } from '@app/shared/layout/v2/layout-v2.module';
import { PlanPriceModule } from './partials/plan-price.module';
import { V2CommonModule } from '../v2/common/common.module';
import { DatatableModule } from '@app/shared/components/datatable/datatable.module';
import { BillingComponent } from './billing.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { MyForOfModule } from '@app/shared/components/datatable/directives/my-for.module';

@NgModule({
  imports        : [
    BillingRoutingModule,
    SharedModule,
    StFormsModule,
    CheckboxModule,
    UpdatePlanModule,
    TrackingModule.forRoot(),
    LayoutV2Module,
    PlanPriceModule,
    V2CommonModule,
    DatatableModule,
    ScrollingModule,
    MyForOfModule,
  ],
  exports        : [],
  declarations   : [
    BillingComponent,
    InvoicesComponent,
    BillingCurrentPlanComponent,
    EditBillingInformationComponent,
    ChooseAnotherCardComponent,
    CancellationSurveyComponent,
    SurveyQuestionComponent,
    UpgradeSubscriptionModalComponent,
  ],
  providers      : [
    InvoiceAPI,
    CreditCardAPI,
    PlanAPI,
  ],
})
export class BillingModule {
}
