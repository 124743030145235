import { BaseModel, Collection } from "@app/shared/data/base.models";

export class SystemStats extends BaseModel {
  static className = 'SystemStats';
  id: number;

  number_of_users_counter: number;
  number_of_sub_users_counter: number;
  number_of_credentials_counter: number;
  product_all_counter: number;
  product_parent_counter: number;
  product_child_counter: number;
  keywords_all_counter: number;
  keywords_unique_counter: number;
  keywords_high_counter: number;
  keywords_medium_counter: number;
  keywords_low_counter: number;
  keywords_starred_counter: number;
  keywords_low_starred_counter: number;

  created_at: string;
}
