export enum IFullfillmentChannel {
  FBA = 'FBA',
  FBM = 'FBM',
}

export interface IReviewRequestSettings {
  enabled: boolean;
  fulfillment_channel: IFullfillmentChannel[];
  time: string;
  days_after_delivery: number;
  exclude_feedback: number[];
  exclude_promotions: string[];
  exclude_customer_type: string[];
  exclude_others: string[]
  repeat_orders: number[];
}

export interface IReviewRequestOverride {
  repeat_orders: number[];
  enabled: boolean;
}

export interface IReviewRequestSettingsState {
  id: number;
  hash: string;
  settings: IReviewRequestSettings;
  overrides: {
    [key: string]: IReviewRequestOverride;
  }
}

export interface IReviewRequestState {
  overallSettings: IReviewRequestSettingsState;
  loadingSettings: boolean;
  productsState: IReviewRequestProductsResponse;
  loadingProducts: boolean;
  settingsUpdated: boolean;

  sentRequestsGraph: number[][];
  reviewGrowthGraph: number[][];
  loadingSentRequestsGraph: boolean;
  loadingReviewGrowthGraph: boolean;
}

export interface IReviewRequestProduct {
  img_url: string;
  sku: string;
  parent_asin: string;
  asin: string;
  title: string;
  detailsOpened?: boolean;
  graphOpened?: boolean;
  tags?: string[];
}

export interface IReviewRequestProductsResponse {
  data: IReviewRequestProduct[];
  current_page: number;
  first_page_url: string;
  last_page_url: string;
  next_page_url: string;
  prev_page_url: string;
  path: string;
  per_page: number;
  to: number;
  from: number;
  total: number;
  last_page: number;
}

export interface IReviewRequestGraphResponse {
  date: string;
  value: number;
}

export enum ConnectSPApiOriginEnum {
  REVIEW_REQUEST = 'REVIEW_REQUEST',
  CREDENTIALS = 'CREDENTIALS',
  AMZ_MARKETPLACE = 'AMZ_MARKETPLACE',
}
