
import {map} from 'rxjs/operators';
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { SubAccount } from "@app/shared/data/sub-account/sub-account.models";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";

@Injectable()
export class SubAccountAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  all(credentialId: number): Observable<Collection<SubAccount>> {
    return this.http.get(this.constructFullUrl('api/credentials/' + credentialId + '/sub-accounts')).pipe(
      map(data => SubAccount.collect<SubAccount>(data, SubAccount.BACKEND_PAGINATION)));
  }

  update(credentialId: number, id: number, changes): Observable<SubAccount> {
    return this.http.put(this.constructFullUrl('api/credentials/' + credentialId + '/sub-accounts/' + id), { permissions: changes }).pipe(
      map(data => new SubAccount(data['data'])));
  }

  remove(credentialId: number, id: number): Observable<any> {
    return this.http.delete(this.constructFullUrl('api/credentials/' + credentialId + '/sub-accounts/' + id));
  }

  sendInvitation(credentialId: number, email: string): Observable<SubAccount> {
    return this.http.post(this.constructFullUrl('api/credentials/' + credentialId + '/sub-accounts'), { email: email }).pipe(
      map(data => new SubAccount(data['data'])));
  }
}
