import { NgRedux } from '@angular-redux/store';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { AppState } from '@app/shared/data/app-state.model';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import {
  IProductManagerProduct,
  IProductManagerState,
  IProductManagerVariation,
  ProductActivityStatusEnum,
} from '@app/shared/data/product-manager/product-manager.models';
import { ButtonIconEnum } from '@app/shared/layout/v2/forms/button/button.component';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {
  IFieldOrder,
  ProductListTabEnum,
  SortByEnum,
  SortOrderEnum,
} from '../product-manager-product-list/product-manager-product-list.component';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { AuthService } from '@app/core/services/auth.service';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { ProductManagerAddProductsManuallyComponent } from '../product-list-add-products-manually-modal/product-list-add-products-manually-modal.component';
import { ToastService } from '@app/core/services/toast.service';

export const productManagerDefaultSortBy = [{
  field: SortByEnum.STATUS,
  order: SortOrderEnum.DESC
}, {
  field: SortByEnum.TITLE,
  order: SortOrderEnum.ASC
}];

@Component({
  selector: 'st-product-manager-product-list-table',
  templateUrl: 'product-list-table.component.html',
  styleUrls: ['product-list-table.component.scss']
})
export class ProductManagerProductListTableComponent implements OnInit, OnDestroy {
  selectedProduct: IProductManagerProduct;
  sortByEnum = SortByEnum;
  activityStatusEnum = ProductActivityStatusEnum;

  lineOptions = {
    marker: {
      enabled: true,
      radius : 3
    },
  };

  @Input() products: IProductManagerProduct[];
  @Input() loadingProducts: boolean;
  @Input() loadingGraphData: boolean;
  @Input() bsrGraph: number[][];
  @Input() loadingProductVariations: boolean;
  @Input() productVariations: IProductManagerVariation[];
  @Input() perPageOptions: IDropdownOption[];
  @Input() preSelectedOption: IDropdownOption;

  @Input() variationPerPage: number;
  @Input() variationCurrentPage: number;
  @Input() variationTotal: number;
  @Input() variationPagesArray: number[];
  @Input() variationFrom: number;
  @Input() variationTo: number;
  @Input() variationLastPage: number;
  @Input() currentDisplayedVariationProducts: string;
  @Input() showResetButton = true;
  @Input() activeTab: ProductListTabEnum;

  @Output() onSortBy: EventEmitter<SortByEnum> = new EventEmitter();
  @Output() onToggleProductDetails: EventEmitter<IProductManagerProduct & { tab: 'details'|'graph' }> = new EventEmitter();

  @Output() onVariationGoToFirstPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationGoToLastPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationNextPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationPreviousPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationChangePage: EventEmitter<{page: number, product: IProductManagerProduct}> = new EventEmitter();
  @Output() onVariationPageChange: EventEmitter<{ event: IDropdownOption, product: IProductManagerVariation }> = new EventEmitter();
  @Output() onSelectProduct: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onProductReset: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onActiveTab: EventEmitter<{ tab: 'graph'|'details', product: IProductManagerProduct }> = new EventEmitter();

  buttonIcons = ButtonIconEnum;
  activeFilterStatus: ProductActivityStatusEnum = null;

  currentStatus: ProductActivityStatusEnum = null;
  currentProductIndex = 0;
  settingStatus = false;
  loadingTags = false;
  loadingDeleteProduct = false;

  defaultSortBy: IFieldOrder[] = productManagerDefaultSortBy;

  tabTypes = ProductListTabEnum;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private dialogService: DialogService,
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
    private i18n: I18n,
    private rudderTracking: RudderTrackingService,
    private authService: AuthService,
    private modalService: ModalService,
    private toastService: ToastService
  ) {}

  ngOnInit() {
    this.ngRedux.select('product_manager')
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((productManagerState: IProductManagerState) => {

        if (this.loadingDeleteProduct && !productManagerState.deletingProduct) {
          if (productManagerState.deletingProductError) {
            this.toastService.error(this.i18n('Error'), productManagerState.deletingProductError.error.message);
          }
          if (productManagerState.deletingProductSuccess) {
            this.toastService.success(this.i18n('Success'), this.i18n('Product deleted'));
          }
        }

        this.settingStatus = productManagerState.settingProductStatus;
        this.loadingTags = productManagerState.settingProductTags || productManagerState.removingProductTags;
        this.loadingDeleteProduct = productManagerState.deletingProduct;

        if (productManagerState.setProductStatusError) {
          this.resetStatus();
        }
      });
  }

  sortBy(sort: SortByEnum) {
    this.onSortBy.emit(sort);
  }

  addProductsManually() {
    this.modalService.open(ProductManagerAddProductsManuallyComponent);
  }

  toggleProductDetails(event: MouseEvent, product: IProductManagerProduct, tab: 'details'|'graph') {
    this.preventDefault(event);
    event.stopImmediatePropagation();

    this.onToggleProductDetails.emit({...product, tab});
  }

  preventDefault(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  addTagToProduct(tag: string, product: IProductManagerProduct): void {
    if (!tag) {
      return;
    }
    this.ngRedux.dispatch(this.productManagerActions.setProductTags([tag], product.asin));

    this.rudderTracking.trackEvent(`Product Manager - Added tag`, {
      existing: product.tags.length
    });
  }

  resetProduct(product: IProductManagerProduct) {
    this.rudderTracking.trackEvent('Product Manager - Reset');
    this.onProductReset.emit(product);
  }

  removeTagFromProduct(tag: string, product: IProductManagerProduct) {
    this.ngRedux.dispatch(this.productManagerActions.removeProductTags([tag], product.asin));
  }

  isProductTagListEmpty(product: IProductManagerProduct): boolean {
    return !product.tags || product.tags.length === 0;
  }

  variationGoToFirstPage(product: IProductManagerProduct) {
    this.onVariationGoToFirstPage.emit(product);
  }

  variationGoToLastPage(product: IProductManagerProduct) {
    this.onVariationGoToLastPage.emit(product);
  }

  variationNextPage(product: IProductManagerProduct) {
    this.onVariationNextPage.emit(product);
  }

  variationPreviousPage(product: IProductManagerProduct) {
    this.onVariationPreviousPage.emit(product);
  }

  variationChangePage(page: number, product: IProductManagerProduct) {
    this.onVariationChangePage.emit({page, product});
  }

  variationPageChange(event: IDropdownOption, product: IProductManagerVariation) {
    this.onVariationPageChange.emit({event, product});
  }

  selectProduct(product: IProductManagerProduct) {
    this.selectedProduct = product;
    this.onSelectProduct.emit(product);
  }

  setActiveTab(tab: 'graph' | 'details', product: IProductManagerProduct) {
    this.rudderTracking.trackEvent(`Product Manager - Opened ${tab}`);
    this.onActiveTab.emit({tab, product});
  }

  filterProducts(status: ProductActivityStatusEnum) {
    if (this.loadingProducts) {
      return;
    }
    if (this.activeFilterStatus === status) {
      this.activeFilterStatus = null;
      this.ngRedux.dispatch(this.productManagerActions.getProducts(1, 20, this.defaultSortBy, false, '', []));
    } else {
      this.activeFilterStatus = status;
      this.ngRedux.dispatch(this.productManagerActions.getProducts(1, 20, this.defaultSortBy, false, '', [], status));
    }
  }

  onStatusClick(status: ProductActivityStatusEnum, product: IProductManagerProduct, index: number) {
    if (this.settingStatus) {
      return;
    }
    this.currentStatus = product.status;
    this.currentProductIndex = index;
    product.status = status;

    if (product.status === ProductActivityStatusEnum.INACTIVE) {
      this.dialogService.confirm(
        this.i18n('Warning'),
        this.i18n('Are you sure you want to inactivate this product? All the attached data will be wiped.'),
        this.i18n('OK'),
        this.i18n('CANCEL'),
        true
      )
        .then(
          (ok) => {
            if (!ok) {
              this.resetStatus();
              return;
            }
            this.ngRedux.dispatch(this.productManagerActions.setProductStatus(ProductActivityStatusEnum.INACTIVE, product.asin));
          }
        );
    } else {
      this.ngRedux.dispatch(this.productManagerActions.setProductStatus(product.status, product.asin));
    }
  }

  resetStatus() {
    this.products[this.currentProductIndex].status = this.currentStatus;
  }

  tooltipActivityStatusText(status: ProductActivityStatusEnum) {
    switch (status) {
      case ProductActivityStatusEnum.PRIORITY:
        return this.i18n('PRIORITY');
      case ProductActivityStatusEnum.ACTIVE:
        return this.i18n('ACTIVE');
      case ProductActivityStatusEnum.INACTIVE:
      default:
        return this.i18n('ARCHIVED');
    }
  }

  graphSelectionChanged() {
    this.rudderTracking.trackEvent('Product Manager - Graph selection changed');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAmazonLink(product: IProductManagerProduct): string {
    return `https://${this.authService.getActiveCredential()?.amazon_domain}/dp/${product.asin}`;
  }

  getStatusHandleTooltip(status: ProductActivityStatusEnum): string {
    if (status === this.activeFilterStatus) {
      return this.i18n('Click here to reset filter');
    }

    const statusMapper = {
      [ProductActivityStatusEnum.PRIORITY]: this.i18n('high priority'),
      [ProductActivityStatusEnum.ACTIVE]: this.i18n('active'),
      [ProductActivityStatusEnum.INACTIVE]: this.i18n('inactive'),
    };

    return this.i18n('Click to filter {{status}} products', { status: statusMapper[status] });
  }

  deleteProduct(event: Event, product: IProductManagerProduct) {
    event.stopImmediatePropagation();
    if (this.loadingDeleteProduct || !product) {
      return;
    }
    this.dialogService.confirm(
      this.i18n('Are you sure you want to delete this product?'),
      this.i18n('By confirming this action, you will permanently remove product from your list.'),
      this.i18n('YES, DELETE'),
      this.i18n('CANCEL'),
      true
    )
      .then(
        (ok) => {
          if (ok) {
            this.ngRedux.dispatch(this.productManagerActions.deleteProduct(product.asin));
          }
        }
      );
  }

  get isFilterActive(): boolean {
    return this.activeFilterStatus !== null && !this.loadingProducts;
  }
}
