import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import { AffiliateSummaryCommision } from "@app/shared/data/affiliate/summary-commision/summary-commision.model";

@Injectable()
export class AffiliateSummaryCommisionActions extends PaginationActions {
  protected model = AffiliateSummaryCommision;

  static readonly TYPES = {
    FILTER:    '[AFFILIATESUMMARYCOMMISION]FILTER',
    COMMISION:    '[AFFILIATESUMMARYCOMMISION]COMMISION',
  };

  filter(filters): StAction {
    return {
      type: AffiliateSummaryCommisionActions.TYPES.FILTER,
      data: filters
    }
  }

  commision(commision: AffiliateSummaryCommision): StAction {
    return {
      type: AffiliateSummaryCommisionActions.TYPES.COMMISION,
      data: {
        commision: commision,
      }
    }
  }
}
