import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';

@Injectable()
export class KeywordGeneratedAPI extends BaseAPI {
  baseApiUrl = 'api/keyword-generator';

  constructor(protected http: HttpClient) {
    super();
  }

  public requestSuggestions(keyword: string, group_id: string, marketplace: { id: number }): Observable<any> {
    return this.http.post(this.constructFullUrl(this.baseApiUrl + '/suggestions'), {
      keyword              : keyword,
      group_id             : group_id,
      amazon_marketplace_id: marketplace.id
    }, { observe: 'response' });
  }

  public requestRecent(): Observable<any> {
    return this.http.get(this.constructFullUrl(this.baseApiUrl + '/recent?limit=10'));
  }

  public requestHistory(): Observable<any> {
    return this.http.get(this.constructFullUrl(this.baseApiUrl + '?history=true'));
  }
}
