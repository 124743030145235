import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { Injectable } from '@angular/core';
import {
  LastLaunchSchedule,
  LastLaunchScheduleDate, LastLaunchScheduleKeyword, LastLaunchScheduleProduct
} from '@app/shared/data/last-launch-schedule/last-launch-schedule.models';
import { Collection } from '@app/shared/data/base.models';
import { StAction } from '@app/shared/data/st-action';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class LastLaunchScheduleActions extends PaginationActions {
  static readonly TYPES = {
    OBTAIN_AVAILABLE_DATES          : '[LASTLAUNCHSCHEDULE]OBTAIN_AVAILABLE_DATES',
    OBTAIN_AVAILABLE_DATES_SUCCEEDED: '[LASTLAUNCHSCHEDULE]OBTAIN_AVAILABLE_DATES_SUCCEEDED',

    SAVE_PROMOTION          : '[LASTLAUNCHSCHEDULE]SAVE_PROMOTION',
    SAVE_PROMOTION_SUCCEEDED: '[LASTLAUNCHSCHEDULE]SAVE_PROMOTION_SUCCEEDED',
    SAVE_PROMOTION_FAILED   : '[LASTLAUNCHSCHEDULE]SAVE_PROMOTION_FAILED',

    SAVE_PRODUCT          : '[LASTLAUNCHSCHEDULE]SAVE_PRODUCT',
    SAVE_PRODUCT_SUCCEEDED: '[LASTLAUNCHSCHEDULE]SAVE_PRODUCT_SUCCEEDED',
    SAVE_PRODUCT_FAILED   : '[LASTLAUNCHSCHEDULE]SAVE_PRODUCT_FAILED',

    SAVE_COUPONS          : '[LASTLAUNCHSCHEDULE]SAVE_COUPONS',
    SAVE_COUPONS_SUCCEEDED: '[LASTLAUNCHSCHEDULE]SAVE_COUPONS_SUCCEEDED',
    SAVE_COUPONS_FAILED   : '[LASTLAUNCHSCHEDULE]SAVE_COUPONS_FAILED',

    SAVE_UNITS          : '[LASTLAUNCHSCHEDULE]SAVE_UNITS',
    SAVE_UNITS_SUCCEEDED: '[LASTLAUNCHSCHEDULE]SAVE_UNITS_SUCCEEDED',
    SAVE_UNITS_FAILED   : '[LASTLAUNCHSCHEDULE]SAVE_UNITS_FAILED',

    LOAD_PROMOTION          : '[LASTLAUNCHSCHEDULE]LOAD_PROMOTION',
    LOAD_PROMOTION_SUCCEEDED: '[LASTLAUNCHSCHEDULE]LOAD_PROMOTION_SUCCEEDED',
    LOAD_PROMOTION_FAILED   : '[LASTLAUNCHSCHEDULE]LOAD_PROMOTION_FAILED',

    SET_PRODUCT: '[LASTLAUNCHSCHEDULE]SET_PRODUCT',

    SEARCHING_FOR_PRODUCT          : '[LASTLAUNCHSCHEDULE]SEARCHING_FOR_PRODUCT',
    SEARCHING_FOR_PRODUCT_SUCCEEDED: '[LASTLAUNCHSCHEDULE]SEARCHING_FOR_PRODUCT_SUCCEEDED',
    SEARCHING_FOR_PRODUCT_FAILED   : '[LASTLAUNCHSCHEDULE]SEARCHING_FOR_PRODUCT_FAILED',

    PAY                                    : '[LASTLAUNCHSCHEDULE]PAY',
    PAY_SUCCEEDED                          : '[LASTLAUNCHSCHEDULE]PAY_SUCCEEDED',
    PAY_FAILED                             : '[LASTLAUNCHSCHEDULE]PAY_FAILED',
    PAYMENT_FAILED_BECAUSE_SLOT_HAS_EXPIRED: '[LASTLAUNCHSCHEDULE]PAYMENT_FAILED_BECAUSE_SLOT_HAS_EXPIRED',
  };

  static readonly SOCKET_TYPES = {
    STATUS_HAS_CHANGED: 'last_launch_status_has_changed',
  };

  protected model = LastLaunchSchedule;

  constructor(private i18n: I18n) {
    super();
  }

  obtainAvailableDates(scheduleId): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.OBTAIN_AVAILABLE_DATES,
      data: {
        scheduleId: scheduleId,
      }
    };
  }

  obtainAvailableDatesSucceeded(dates: Collection<LastLaunchScheduleDate>): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.OBTAIN_AVAILABLE_DATES_SUCCEEDED,
      data: {
        dates: dates,
      }
    };
  }

  savePromotion(schedule: LastLaunchSchedule, coupon?: string): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_PROMOTION,
      data: {
        schedule: schedule,
        coupon: coupon,
      }
    };
  }

  savePromotionSucceeded(schedule: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_PROMOTION_SUCCEEDED,
      data: {
        schedule: schedule,
      }
    };
  }

  savePromotionFailed(error: any): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_PROMOTION_FAILED,
      data: {
        error: error,
      }
    };
  }

  saveProduct(schedule: LastLaunchSchedule, product: LastLaunchScheduleProduct): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_PRODUCT,
      data: {
        schedule: schedule,
        product : product,
      }
    };
  }

  saveProductSucceeded(schedule: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_PRODUCT_SUCCEEDED,
      data: {
        schedule: schedule,
      }
    };
  }

  saveProductFailed(error: any): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_PRODUCT_FAILED,
      data: {
        error: error,
      }
    };
  }

  saveCoupons(schedule: LastLaunchSchedule, coupons: string[]): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_COUPONS,
      data: {
        schedule: schedule,
        coupons : coupons,
      }
    };
  }

  saveCouponsSucceeded(schedule: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_COUPONS_SUCCEEDED,
      data: {
        schedule: schedule,
      }
    };
  }

  saveCouponsFailed(error: any): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_COUPONS_FAILED,
      data: {
        error: error,
      }
    };
  }

  saveUnits(schedule: LastLaunchSchedule, keywords: Collection<LastLaunchScheduleKeyword>): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_UNITS,
      data: {
        schedule: schedule,
        keywords: keywords.toArray(),
      }
    };
  }

  saveUnitsSucceeded(schedule: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_UNITS_SUCCEEDED,
      data: {
        schedule: schedule,
      }
    };
  }

  saveUnitsFailed(error: any): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SAVE_UNITS_FAILED,
      data: {
        error: error,
      }
    };
  }

  loadPromotion(promoId, type: string): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.LOAD_PROMOTION,
      data: {
        promoId: promoId,
        type
      }
    };
  }

  loadPromotionSucceeded(schedule: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.LOAD_PROMOTION_SUCCEEDED,
      data: {
        schedule: schedule,
      }
    };
  }

  loadPromotionFailed(error: any): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.LOAD_PROMOTION_FAILED,
      data: {
        error: error,
      }
    };
  }

  searchForProduct(promotion_id: number, query: string): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SEARCHING_FOR_PRODUCT,
      data: {
        promotion_id: promotion_id,
        query       : query
      }
    };
  }

  setProduct(schedule: LastLaunchSchedule, product: LastLaunchScheduleProduct): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SET_PRODUCT,
      data: {
        schedule: schedule,
        product: product
      }
    };
  }

  searchForProductSucceeded(products: Collection<LastLaunchScheduleProduct>): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SEARCHING_FOR_PRODUCT_SUCCEEDED,
      data: {
        products: products
      }
    };
  }

  searchForProductFailed(error): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.SEARCHING_FOR_PRODUCT_FAILED,
      data: {
        error: error,
      }
    };
  }

  pay(schedule: LastLaunchSchedule, useWallet: boolean = false, toPayAmount: number): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.PAY,
      data: {
        schedule: schedule,
        useWallet: useWallet,
        toPayAmount: toPayAmount,
      }
    };
  }

  paySucceeded(schedule: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.PAY_SUCCEEDED,
      data: {
        schedule: schedule,
      }
    };
  }

  paymentFailedBecauseSlotHasExpired(schedule: LastLaunchSchedule): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.PAYMENT_FAILED_BECAUSE_SLOT_HAS_EXPIRED,
      data: {
        schedule: schedule,
        error: this.i18n('Your Launch Slot Reservation has expired. Please select a new available launch date.')
      }
    };
  }

  payFailed(error): StAction {
    return {
      type: LastLaunchScheduleActions.TYPES.PAY_FAILED,
      data: {
        error: error,
        message: this.i18n('Your payment method has failed, and your launch has not been scheduled.  Please try again.')
      }
    };
  }
}
