import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { PlanAPI } from '@app/shared/data/plan/plan.api';
import { AppUpdateComponent } from '@app/client/modals/app-update/app-update.component';
import { PipesModule } from '@app/shared/pipes/pipes.module';

@NgModule({
  imports        : [
    SharedModule,
    PipesModule,
  ],
  exports        : [],
  declarations   : [
    AppUpdateComponent,
  ],
  providers      : [
    PlanAPI,
  ],
})
export class AppUpdateModule {}
