import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { BillingComponent } from './billing.component';
import { CancellationSurveyComponent } from "@app/client/billing/cancellation-survey/cancellation-survey.component";
import { UpdatePlanComponent } from "@app/client/modals/update-plan/update-plan.component";

const routes: Routes = [
  {
    path     : '',
    component: BillingComponent,
  },
  {
      path     : 'cancellation-survey',
    component: CancellationSurveyComponent,
    // outlet   : 'modal',
  },
  {
      path     : 'update-plan',
    component: UpdatePlanComponent,
    // outlet   : 'modal',
  }
];

@NgModule({
  imports  : [
    RouterModule.forChild(routes)
  ],
  exports  : [
    RouterModule
  ],
  providers: []
})
export class BillingRoutingModule {
}

