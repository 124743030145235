import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  HostListener,
  Input,
  QueryList,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { CollapsiblesDirective } from '@app/shared/layout/collapsible/collapsibles.directive';
import { DropdownDirective } from '@app/shared/layout/dropdown/dropdown.directive';
import { calculateWidth } from '@app/helpers';
import { Subject } from 'rxjs';

@Component({
  selector   : '.collapsible',
  templateUrl: 'collapsible.component.html'
})
export class CollapsibleComponent implements AfterViewInit {
  constructor(private vc: ViewContainerRef,
              private renderer: Renderer2,
              private cd: ChangeDetectorRef) {}

  @ContentChildren(CollapsiblesDirective)
  children: QueryList<CollapsiblesDirective> = new QueryList<CollapsiblesDirective>();

  @ViewChild('transformer', { read: ViewContainerRef, static: true })
  nestedDropdown: ViewContainerRef = null;

  @ViewChild(DropdownDirective, { static: true })
  dropdownDirective: DropdownDirective = null;

  @Input('st-collapsed-label')
  collapsedLabel = 'More';

  @Input('st-fully-collapsed-label')
  fullyCollapsedLabel = 'Actions';

  @Input('collapsed-theme')
  theme: string = null;

  group: CollapsiblesDirective [] = [];
  isShown$: Subject<boolean> = new Subject<boolean>();

  private nestedGroup: CollapsiblesDirective [] = [];
  private cwidth = 0;

  ngAfterViewInit(): void {
    this.cwidth = 0;
    this.isShown$.next(true);
    this.group = this.children.toArray().map(cd => {
      // Create list item element
      cd.li = this.renderer.createElement('li');

      return cd;
    });
    this.cd.detectChanges();
    this.onResize();
  }

  private resetPosition() {
    this.isShown$.next(false);
    while (this.nestedGroup.length > 0) {
      this.unGroupElement(this.nestedGroup.shift());
    }
  }

  @HostListener('window:resize', [])
  protected onResize() {
    this.resetPosition();

    if (this.dropdownDirective.elementRef.nativeElement.offsetParent) {
      this.cwidth = calculateWidth(this.dropdownDirective.elementRef.nativeElement);
    }

    while (this.group.length > 0) {
      let overlapping = false;
      const gap = this.vc.element.nativeElement.clientWidth - this.cwidth - 5;

      for (const ge of this.group) {
        if (ge.el.nativeElement.offsetLeft + ge.el.nativeElement.offsetWidth > gap) {
          overlapping = true;
          break;
        }
      }

      if (!overlapping) {
        break;
      }

      this.isShown$.next(true);

      this.groupElement(this.group.pop());
    }
  }

  private groupElement(ge: CollapsiblesDirective) {
    // Remove element from initial position
    this.renderer.removeChild(this.vc.element.nativeElement, ge.el.nativeElement);

    // Add child to grouped dropdown
    if (ge.dropdown)
      this.dropdownDirective.addNestedDropdown(ge.dropdown);

    this.renderer.appendChild(ge.li, ge.el.nativeElement);
    const refChild = this.nestedGroup.length > 0 ? this.nestedGroup[0].li : null;
    this.renderer.insertBefore(this.nestedDropdown.element.nativeElement, ge.li, refChild);

    // Set theme for select
    if (this.theme) {
      ge.setTheme(this.theme);
    }


    // Add element to new group
    this.nestedGroup.unshift(ge);
  }

  private unGroupElement(ge: CollapsiblesDirective) {
    // Remove child from grouped dropdown
    this.renderer.removeChild(this.nestedDropdown.element.nativeElement, ge.li);

    if (ge.dropdown) {
      this.dropdownDirective.removeNestedDropdown(ge.dropdown);
    }

    // Append child to old position
    this.renderer.insertBefore(this.vc.element.nativeElement, ge.el.nativeElement, this.dropdownDirective.elementRef.nativeElement);

    // Remove theme style
    ge.resetTheme();

    this.group.push(ge);
  }

}
