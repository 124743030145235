import {
  of as observableOf,
  throwError as observableThrowError,
  timer as observableTimer,
  merge as observableMerge,
  Subscription,
  Subject,
  Observable
} from 'rxjs';

import {
  catchError,
  switchMap,
  tap,
  take,
  skip,
  merge,
  delay,
  map,
  bufferWhen,
  takeUntil,
  filter,
  flatMap,
  switchMapTo
} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { FePaginationCollection } from '@app/shared/data/base.models';
import { StAction } from '@app/shared/data/st-action';
import { ToastService } from '@app/core/services/toast.service';
import { environment } from '@env/environment';
import { ReverseAsinActions } from '@app/shared/data/reverse-asin/reverse-asin.actions';
import { ReverseAsinAPI } from '@app/shared/data/reverse-asin/reverse-asin.api';
import { ReverseAsin } from '@app/shared/data/reverse-asin/reverse-asin.models';
import { KeywordGenerated } from '@app/shared/data/keyword-generated/keyword-generated.models';
import { KeywordGeneratedActions } from '@app/shared/data/keyword-generated/keyword-generated.actions';
import { R2aTaskActions } from '@app/shared/data/tasks/r2a-task/r2a-task.actions';
import { Filter } from '@app/shared/components/filter-builder/filter/filter';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { getTranslationFromResponse } from '@app/helpers';
import { AsinCompareActions } from '@app/shared/data/asin-compare/asin-compare.actions';

@Injectable()
export class ReverseAsinEpics {
  constructor(private reverseAsinActions: ReverseAsinActions,
              private store: NgRedux<AppState>,
              private reverseAsinAPI: ReverseAsinAPI,
              private toastService: ToastService,
              private r2aTaskActions: R2aTaskActions,
              private i18n: I18n,
              private asinCompareActions: AsinCompareActions) {
  }

  private loadPageSubscription: Subscription = null;

  private appender$: Subject<any> = new Subject();
  private appenderCloser$: Subject<any> = new Subject<any>();

  private static unComparator = (x, y) => {
    return x === undefined || y === undefined || x === y; // Prevent double requests
  };

  private static filterUnfinished(showUnfinished: boolean, kw: ReverseAsin) {
    return showUnfinished ? true : kw.impressionExact !== false && kw.impressionBroad !== false && kw.resultsCount !== false;
  }

  public createEpic() {
    return [
      this.initData,
      this.loadPage,
      this.socketEvent,
      this.requestKeywords,
      this.loadHistory,
      this.findProductsForKeyword,
    ];
  }

  socketEvent = store => next => {
    return (action: StAction) => {
      //TODO: check if group id is same -> action.groupId (action.data.group_id)
      switch (action.type) {
        case ReverseAsinActions._events.keywords_received:
          if (action.data.group_id == store.getState()['reverse_asin']['group_id']) {
            const keywords = Object.keys(action.data.keywords).map(function (kw) {
              return new ReverseAsin(action.data.keywords[kw]);
            });

            this.appender$.next(keywords);
          }
          break;

        // Suggestion data report witch carries data updates
        case KeywordGeneratedActions._events.suggestions_received:
          if (action.data.group_id == store.getState()['reverse_asin']['group_id']) {
            const keywords = Object.keys(action.data.keywords).map(function (kw) {
              return new ReverseAsin(action.data.keywords[kw]);
            });

            this.appender$.next(keywords);
          }
          break;

        case ReverseAsinActions._events.progress:
          if (action.data.group_id === store.getState()['reverse_asin']['group_id']) {
            this.store.dispatch(this.reverseAsinActions.setProgress(action.data.step, action.data.progress));
          }
          break;
      }
      return next(action);
    };
  };

  requestKeywords = store => next => (action) => {
    const actionReturn = next(action);

    if (action.type == ReverseAsinActions.TYPES.KEYWORDS_REQUESTED) {

      this.store.dispatch(this.reverseAsinActions.setActiveAsin(action.data['asins'][0], action.data['group_id'], true));

      this.appenderCloser$.next(true);
      this.appender$.complete();
      this.appender$ = new Subject<any>();
      this.appender$.pipe(
        bufferWhen(() =>
          this.appender$.pipe(
            delay(400),
            merge(this.appender$.pipe(skip(environment.BULK_BUFFER_SIZE), take(1))),
          )
        ),
        map(x => [].concat(...x).filter((e, i, arr) => i === arr.map(o => o.keyword).indexOf(e.keyword))),
        map(this.reverseAsinActions.appendData.bind(this.reverseAsinActions)),
        takeUntil(this.appenderCloser$),)
        .subscribe(this.store.dispatch.bind(this.store));


      observableMerge( // Retry in intervals for results
        observableTimer(0, environment.R2A_REQUEST_INTERVAL),
        observableTimer(environment.R2A_TIMEOUT).pipe( // Fail after specified timeout
          switchMapTo(observableThrowError({error: {error: {error_code: 'error.search_timeout'}}}))
        )
      ).pipe(
        takeUntil(this.store.select(['reverse_asin', 'search_done']).pipe(filter(x => !!x))),
        flatMap(() => this.reverseAsinAPI.requestKeywords(
          action.data['asins'],
          action.data['compare_asin'],
          action.data['group_id'],
          action.data['marketplace'],
          action.data['depth'],
          action.data['resolveChildren'],
        ))
      ).subscribe(
        (data: any) => {
          if (Array.isArray(data.keywords)) {
            const groupID = action.data['group_id'];
            const compareASIN = action.data['compare_asin'];
            const keywords = Object.keys(data.keywords).map(function (kw) {
              return new KeywordGenerated(data.keywords[kw]);
            });

            this.store.dispatch(this.reverseAsinActions.setFinished(data.info, keywords, data.stats, groupID));

            if (compareASIN) {
              this.store.dispatch(this.asinCompareActions.compare(compareASIN, groupID));
            }
          } else {
            if ('step' in data && 'progress' in data) {
              this.store.dispatch(this.reverseAsinActions.setProgress(data.step, data.progress));
            }
          }

          this.store.dispatch(this.r2aTaskActions.listSearches());
        },
        (error) => {
          console.log('RESPONSE FAILED!!');
          console.log(error);
          //noinspection TypeScriptValidateTypes
          this.store.dispatch(this.reverseAsinActions.loadPageFailed(error));
        },
        () => {
        },
      );
    }
    return actionReturn;
  };

  initData = store => next => {
    return (action) => {
      if (action.type == this.reverseAsinActions.initData().type) {
        const nextAction = next(action);
        this.store.dispatch(this.reverseAsinActions.setData(new FePaginationCollection));
        this.store.dispatch(this.reverseAsinActions.setActiveAsin(action.data['asin'], action.data['group_id'], false));

        return nextAction;
      }
      return next(action);
    };
  };

  loadPage = (store: NgRedux<AppState>) => next => {
    return (action) => {
      if (action.type == ReverseAsinActions.getPagActionTypes(ReverseAsin).LOAD_PAGE) {

        if (!('_data' in store.getState()['reverse_asin'])) {
          //noinspection TypeScriptValidateTypes
          this.store.dispatch(this.reverseAsinActions.loadPageFailed(this.i18n('Data not initialized')));
          return;
        }

        if (this.loadPageSubscription) {
          this.loadPageSubscription.unsubscribe();
        }

        // Listen for base temp _data change
        this.loadPageSubscription = this.store
          .select(['reverse_asin', '_data'], ReverseAsinEpics.unComparator).pipe(
            tap(data => this.store.dispatch(this.reverseAsinActions.setCompleteData(data))),
            switchMap(data => this.store.select(['reverse_asin', 'filters']).pipe(map(filters => ({data, filters})))),
            map(this.createFiltered.bind(this)),
            tap(data => this.store.dispatch(this.reverseAsinActions.setFiltered(data))),)
          .subscribe((data: FePaginationCollection<ReverseAsin>) => {
            this.createPage(data, action);
          });
      }

      if (action.type == ReverseAsinActions.getPagActionTypes(ReverseAsin).LOAD_PAGE_FAILED) {
        this.toastService.error(
          this.i18n('Request failed'),
          getTranslationFromResponse([
            {
              code: 'error.r2a_failed',
              translation: this.i18n('Error getting R2A keywords.')
            },
            {
              code: 'error.search_timeout',
              translation: this.i18n('R2A search timed out. Please try again later.')
            },
            {
              code: 'error.limits_reached',
              translation: this.i18n(
                'Maximum number of R2A searches reached! Limit is {{limit}} searches / billing period',
                {limit: action.data.error.error.message}
              )
            },
            {
              code: 'forbidden',
              translation: this.i18n('Access denied for the marketplace')
            }], action.data)
        );
      }
      return next(action);
    };
  };

  loadHistory = (store: NgRedux<AppState>) => next => action => {
    if (action.type === ReverseAsinActions.TYPES.LOAD_HISTORY) {
      this.reverseAsinAPI.loadHistory().pipe(
        map(data => this.reverseAsinActions.loadHistorySucceeded(data)),
        catchError(error => observableOf(this.reverseAsinActions.loadHistoryFailed(error))),)
        .subscribe(this.store.dispatch.bind(this));
    }
    return next(action);
  };

  findProductsForKeyword = store => next => action => {
    if (action.type === ReverseAsinActions.TYPES.FIND_PRODUCTS_FOR_KEYWORD) {
      this.reverseAsinAPI.findProductsForKeyword(action.data.keyword).subscribe(
        () => {
        },
        (error) => {
          this.store.dispatch(this.reverseAsinActions.findProductsForKeywordFailed(error));
        }
      );
    }
    return next(action);
  };

  private createFiltered(
    {data, filters}:
      { data: FePaginationCollection<ReverseAsin>, filters: { filters: Filter[], showUnfinished: boolean } },
  ) {

    const filterObjects: Filter[] = filters.filters;
    const showUnfinished: boolean = filters.showUnfinished;
    const filtersAreSet = filterObjects && Object.keys(filterObjects).length !== 0;

    // Skip filtering if unfinished products should be visible and no filters are set
    if (!data || (showUnfinished && !filtersAreSet)) {
      return data;
    }

    const filterFn = (kw: ReverseAsin) => {
      const kwComplete = ReverseAsinEpics.filterUnfinished(showUnfinished, kw);

      if (filtersAreSet) {
        return !filterObjects.some((filter: Filter) => { // Returns false if none of the conditions match
          let value: any = false;

          // Pass if filter is array contain but comparison array is empty
          if (['has one of', 'has all'].includes(filter.operator) && !(Array.isArray(filter.value) && filter.value.length)) {
            return false;
          }

          switch (filter.field) {
            case 'word_count':
              value = kw.keyword.trim().split(/\s+/).length;
              break;
            case 'sfr':
              value = kw.sfr;
              break;
            case 'result_count':
              value = kw.resultsCount;
              break;
            case 'categories':
              value = kw.categories;
              break;
            case 'impressions_p':
              value = kw.impressionP;
              break;
            case 'opportunity_score':
              value = kw.opportunityScore;
              break;
            case 'kw_filter':
              value = kw.keyword.toLowerCase();
              break;
            case 'density':
              value = kw.density;
              break;
            case 'rc_density':
              value = kw.rc_density;
              break;
            case 'sponsored_count':
              value = kw.sponsoredDensity;
              break;
            case 'match_type':
              value = kw.matchTypeTags;
              break;
            case 'my_asin':
              value = kw.myAsinTags;
              break;
            case 'amazons_choice':
              value = kw.acBadges;
              break;
            case 'organic_ranking':
              value = kw.avgRank;
              break;
            case 'my_asin_rank':
              value = kw.myAsinRank;
              break;
            case 'min_rank':
              value = kw.min_rank;
              break;
            default:
              console.error('Incorrect filter field: ', filter);
              return true;
          }

          return !(kwComplete && Filter.filterOperator(value, filter));
        });
      } else {
        return kwComplete;
      }
    };

    return data.filter(filterFn) as FePaginationCollection<ReverseAsin>;
  }

  private createPage(_data: FePaginationCollection<ReverseAsin>, action) {
    if (!_data) {
      return;
    }
    //noinspection TypeScriptValidateTypes
    setTimeout(() => this.store.dispatch(this.reverseAsinActions.setPage(_data.createPage(action.data.pagination))), 0);
  }
}
