
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { BaseAPI } from "@app/shared/data/base.api";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import {
  LastLaunchAdminPromotion,
  LastLaunchAdminKeyword,
  LastLaunchAdminSlot,
  LastLaunchAdminProduct,
  LastLaunchAdminCoupon,
  LastLaunchAdminScheduledEmail, LastLaunchAdminNote
} from "@app/shared/data/admin/last-launch/last-launch.models";
import { Credential } from "@app/shared/data/credential/credential.models";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";

@Injectable()
export class LastLaunchAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<LastLaunchAdminPromotion>> {
    return this.http.get(this.constructFullUrl('api/admin/last-launch'), { params: params }).pipe(
      map(data => LastLaunchAdminPromotion.collect<LastLaunchAdminPromotion>(data, LastLaunchAdminPromotion.BACKEND_PAGINATION)));
  }

  public get(id): Observable<LastLaunchAdminPromotion> {
    return this.http.get(this.constructFullUrl('api/admin/last-launch/' + id)).pipe(
      map(p => LastLaunchAPI.transformFullPromotion(p)));
  }

  public save(data): Observable<LastLaunchAdminPromotion> {
    let obs: Observable<Object>;

    data['keywords'] = data['keywords'].toArray();
    data['coupons'] = data['coupons'].toArray();

    if (data.id)
      obs = this.http.put(this.constructFullUrl('api/admin/last-launch/' + data.id), data);
    else
      obs = this.http.post(this.constructFullUrl('api/admin/last-launch'), data);

    return obs.pipe(map(p => LastLaunchAPI.transformFullPromotion(p)));
  }

  public remove(promotion: LastLaunchAdminPromotion): Observable<Object> {
    return this.http.delete(this.constructFullUrl('api/admin/last-launch/' + promotion.id));
  }

  public getCalendarSlots(from, to): Observable<Collection<LastLaunchAdminSlot>> {
    return this.http.get(this.constructFullUrl('api/admin/last-launch/calendar'), {
      params: {
        from: from,
        to  : to
      }
    }).pipe(map(data => LastLaunchAdminSlot.collect(data)))
  }

  public getScheduledEmails(from, to, type): Observable<Collection<LastLaunchAdminScheduledEmail>> {
    return this.http.get(this.constructFullUrl('api/admin/last-launch-scheduled-emails'), {
      params: {
        from: from,
        to  : to,
        type: type,
      }
    }).pipe(map(data => LastLaunchAdminScheduledEmail.collect(data)))
  }

  public getScheduledEmail(id): Observable<LastLaunchAdminScheduledEmail> {
    return this.http.get(this.constructFullUrl('api/admin/last-launch-scheduled-emails/' + id)
    ).pipe(map(data => new LastLaunchAdminScheduledEmail(data)))
  }

  public saveScheduledEmail(scheduledEmail: LastLaunchAdminScheduledEmail): Observable<LastLaunchAdminScheduledEmail> {
    let obs: Observable<Object>;

    if (scheduledEmail.id)
      obs = this.http.put(this.constructFullUrl('api/admin/last-launch-scheduled-emails/' + scheduledEmail.id), scheduledEmail);
    else
      obs = this.http.post(this.constructFullUrl('api/admin/last-launch-scheduled-emails'), scheduledEmail);

    return obs.pipe(map(data => new LastLaunchAdminScheduledEmail(data)));
  }

  public saveCalendarSlot(date: string, available: number): Observable<LastLaunchAdminSlot> {
    return this.http.post(this.constructFullUrl('api/admin/last-launch/calendar'), {
      date     : date,
      available: available
    }).pipe(map(slot => new LastLaunchAdminSlot(slot)))
  }

  public loadUsers(email): Observable<Collection<UserAdmin>> {
    return this.http.get(this.constructFullUrl('api/admin/last-launch/users'), {
      params: {
        email: email,
      }
    }).pipe(
      map(c => UserAdmin.collect<UserAdmin>(c)))
  }

  public addNote(promoId: number, note: string): Observable<LastLaunchAdminNote> {
    return this.http.post(this.constructFullUrl('api/admin/last-launch/' + promoId + '/notes'), { note: note }).pipe(
      map(data => new LastLaunchAdminNote(data)));
  }

  private static transformFullPromotion(p) {
    p['user'] = new UserAdmin(p['user']);
    p['credential'] = new Credential(p['credential']);
    p['product'] = new LastLaunchAdminProduct(p['product']);
    p['keywords'] = LastLaunchAdminKeyword.collect<LastLaunchAdminKeyword>(p['keywords']);
    p['coupons'] = LastLaunchAdminCoupon.collect<LastLaunchAdminCoupon>(p['coupons']);

    p['notes'] = LastLaunchAdminNote.collect<LastLaunchAdminNote>(p['notes']);

    return new LastLaunchAdminPromotion(p);
  }
}
