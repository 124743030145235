import { Component, HostListener } from '@angular/core';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import {I18n} from '@ngx-translate/i18n-polyfill';

@Component({
  selector : 'st-confirm',
  templateUrl : './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  message = '';
  yesButtonText = this.i18n('Ok');
  noButtonText = this.i18n('Cancel');
  title = this.i18n('Message');
  showIcon = false;
  warningText?: string;
  confirmText?: string;

  @HostListener('document:keydown.escape', ['$event'])
  onEscKeydown() {
    this.cancel();
  }

  constructor(private activeModal: ActiveModal,
              private i18n: I18n) {}

  cancel(): void {
    this.activeModal.close(false);
  }

  confirm(): void {
    this.activeModal.close(true);
  }

  public setMessage(message: string): void {
    this.message = message;
  }

  public setYesButtonText(yesButtonText: string) {
    this.yesButtonText = yesButtonText;
  }

  public setNoButtonText(noButtonText: string) {
    this.noButtonText = noButtonText;
  }

  public setTitle(title: string) {
    this.title = title;
  }

  public setIcon(showIcon: boolean) {
    this.showIcon = showIcon;
  }

  public setWarningText(text: string) {
    this.warningText = text;
  }

  public setConfirmText(text: string) {
    this.confirmText = text;
  }
}
