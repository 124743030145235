import { AppState } from '@app/shared/data/app-state.model';
import { paginationReducer } from '@app/shared/data/pagination/pagination.reducers';
import { StAction } from '@app/shared/data/st-action';
import { LS } from '@app/shared/services/local-storage.service';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { AlertIssue, UserAlerts } from '@app/shared/data/alert/alert.models';
import { UserAlertActions } from '@app/shared/data/alert/user/user-alert.actions';

const INITIAL_STATE: AppState = {
  alerts: new UserAlerts(),
  loading: false,
  acknowledging: false,
  errors: null,
};

export function userAlertReducer(state: AppState = INITIAL_STATE, action: StAction): AppState {

  if (action.type === PaginationActions.getPagActionTypes(UserAlerts).LOAD_PAGE) {
    LS.forActiveCredential().setPerPage(action.data.pagination.perPage, 'alerts');

    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === UserAlertActions.TYPES.SET_ALERTS) {
    return {
      ...state,
      alerts: action.data,
      loading: false,
      acknowledging: false,
    };
  }

  if (action.type === UserAlertActions.TYPES.REMOVE
    || action.type === UserAlertActions.TYPES.REMOVE_ALL) {
    return {
      ...state,
      loading: true,
    };
  }

  if (action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUE
    || action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUES) {
    return {
      ...state,
      acknowledging: true,
    };
  }

  if (action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUE_SUCCESS) {
    return {
      ...state,
      acknowledging: false,
      alerts: state['alerts']['_issues'].filter((issue: AlertIssue) => issue.id !== action.data.issue.id),
    };
  }

  if (action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUE_FAILED
    || action.type === UserAlertActions.TYPES.ACKNOWLEDGE_ISSUES_FAILED) {
    return {
      ...state,
      acknowledging: false,
    };
  }

  return paginationReducer(UserAlerts)(state, action);
}
