import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  EventEmitter,
  OnChanges,
  OnDestroy,
  Optional,
  Output,
  QueryList,
} from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import {
  Event,
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkWithHref
} from '@angular/router';


@Directive({
  selector: '[stRouterLinkActive]',
  exportAs: 'stRouterLinkActive',
})
export class RouterLinkActiveDirective implements OnChanges, OnDestroy, AfterContentInit {
  @ContentChildren(RouterLink, { descendants: true })
  links: QueryList<RouterLink>;

  @ContentChildren(RouterLinkWithHref, { descendants: true })
  linksWithHrefs: QueryList<RouterLinkWithHref>;

  private routerEventsSubscription: Subscription;
  public readonly isActive: boolean = false;

  /**
   *
   * You can use the output `isActiveChange` to get notified each time the link becomes
   * active or inactive.
   *
   * Emits:
   * true  -> Route is active
   * false -> Route is inactive
   *
   * ```
   * <a
   *  routerLink="/user/bob"
   *  stRouterLinkActive
   *  (isActiveChange)="this.onRouterLinkActive($event)">Bob</a>
   * ```
   */
  @Output() readonly isActiveChange: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private router: Router,
    private readonly cdr: ChangeDetectorRef,
    @Optional() private link?: RouterLink,
    @Optional() private linkWithHref?: RouterLinkWithHref
  ) {
    this.routerEventsSubscription = router.events.subscribe((s: Event) => {
      if (s instanceof NavigationEnd) {
        this.update();
      }
    });
  }

  ngAfterContentInit(): void {
    this.update();
  }

  ngOnChanges(): void {
    this.update();
  }

  /** @nodoc */
  ngOnDestroy(): void {
    this.routerEventsSubscription.unsubscribe();
  }

  private update(): void {
    if (!this.links || !this.linksWithHrefs || !this.router.navigated) {
      return;
    }

    Promise.resolve().then(() => {
      const hasActiveLinks = this.hasActiveLinks();
      if (this.isActive !== hasActiveLinks) {
        (this as any).isActive = hasActiveLinks;
        this.cdr.markForCheck();

        // Emit on isActiveChange after classes are updated
        this.isActiveChange.emit(hasActiveLinks);
      }
    });
  }

  private isLinkActive(router: Router): (link: (RouterLink | RouterLinkWithHref)) => boolean {
    return (link: RouterLink | RouterLinkWithHref) => link.urlTree ? router.isActive(link.urlTree, false) : false;
  }

  private hasActiveLinks(): boolean {
    const isActiveCheckFn = this.isLinkActive(this.router);
    return this.link && isActiveCheckFn(this.link) ||
      this.linkWithHref && isActiveCheckFn(this.linkWithHref) ||
      this.links.some(isActiveCheckFn) || this.linksWithHrefs.some(isActiveCheckFn);
  }
}
