import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipDirective } from "@app/shared/components/popover/tooltip/tooltip.directive";
import { TooltipWrapperComponent } from "@app/shared/components/popover/tooltip/tooltip-wrapper.component";
import { PositionService } from "@app/shared/components/popover/services/position.service";
import { PopupDirective } from "@app/shared/components/popover/popup/popup.directive";
import { PopupWrapperComponent } from "@app/shared/components/popover/popup/popup-wrapper.component";
import { ClickPopupDirective } from "@app/shared/components/popover/popup/click-popup.directive";

@NgModule({
  imports        : [
    CommonModule,
  ],
  declarations   : [
    TooltipDirective,
    PopupDirective,
    ClickPopupDirective,

    TooltipWrapperComponent,
    PopupWrapperComponent,
  ],
  providers      : [
    PositionService,
  ],
  exports        : [
    TooltipDirective,
    PopupDirective,
    ClickPopupDirective
  ]
})
export class PopoverModule {}
