import { Component, Input, OnChanges, SimpleChanges, ViewEncapsulation } from '@angular/core';

@Component({
  selector   : 'st-icon',
  templateUrl: './icon.component.html',
  styleUrls  : ['./icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class IconComponent implements OnChanges {
  @Input('icon')
  icon: string;

  @Input('pointer')
  pointer = false;

  @Input('source')
  iconSource = 'app-svg';
  url = null;

  @Input('width')
  width = 14;

  @Input('height')
  height = 14;

  ngOnChanges(changes: SimpleChanges) {
    if(this.icon === 'iso-color') {
      this.icon = 'logo_v2'
    }
    this.iconSource = 'app-svg';
    if (this.icon === undefined) {
      this.url = null;
      console.warn('Specified icon [' + this.icon + '] does not exists.');
    } else {
      switch (this.icon) {
        case 'bell':
          this.url = 'assets/img/header/' + this.icon + '.svg';
          break;
        case 'loading-bar':
          this.url = 'assets/img/loaders/' + this.icon + '.svg';
          break;
        case 'iso':
        case 'st-text':
        case 'arrow-down':
        case 'arrow-down-red':
        case 'amazon':
        case 'amazon_new':
        case 'amazon_new_white':
        case 'arrow-left':
        case 'arrow-right':
        case 'help':
        case 'bulk':
        case 'copy-to':
        case 'circle':
        case 'circle-filled':
        case 'cross':
        case 'cross-small':
        case 'cross-blue':
        case 'circled-tick':
        case 'check-vp':
        case 'expand':
        case 'export':
        case 'copy':
        case 'alerts':
        case 'graph':
        case 'warn':
        case 'menu':
        case 'letter-i':
        case 'reindex':
        case 'remove':
        case 'sync':
        case 'iso-color':
        case 'switch':
        case 'alert-red':
        case 'amazons-choice':
        case 'amazons-choice-grey':
        case 'best-seller':
        case 'prizm-amazons-choice':
        case 'prizm-best-seller':
        case 'phone-gray':
        case 'phone-blue':
        case 'by-asin':
        case 'wizard':
        case 'warning':
        case 'link':
        case 'manual':
        case 'home':
        case 'spellcheck':
        case 'google-chrome':
        case 'api':
        case 'directions':
        case 'desktop-app':
        case 'review_request':
        case 'chevron-right':
        case 'customer_list':
        case 'search_v2':
        case 'search_blue':
        case 'search_white':
        case 'close':
        case 'red-info':
        case 'blue-info':
        case 'red-remove':
        case 'cross-red':
        case 'credentials':
        case 'red-warning':
        case 'logout':
        case 'product_manager':
        case 'refresh-index':
        case 'abc':
        case 'sign-up-email':
        case 'sign-up-password':
        case 'sign-up-user':
        case 'error-v2':
        case 'warning-yellow':
        case 'toggle-details':
        case 'red-error':
        case 'blue-success':
        case 'check-blue-new':
        case 'carousel-arrow':
        case 'st-check-new':
        case 'download-new':
        case 'icon-coupon':
        case 'show':
        case 'hide':
          this.url = 'assets/img/icons/' + this.icon + '.svg';
          break;
        case 'alert':
        case 'alerts_active':
        case 'automation':
        case 'automation_active':
        case 'campaign_manager_active':
        case 'campaign_manager':
        case 'competition':
        case 'competition_active':
        case 'customer_list_active':
        case 'customer_list_new':
        case 'knowledge_base_active':
        case 'knowledge_base':
        case 'keyword_insights_active':
        case 'keyword_insights':
        case 'logo_active':
        case 'logo_new':
        case 'logo_title':
        case 'logo_title_active':
        case 'notifications':
        case 'notifications_active':
        case 'product_manager_active':
        case 'product_manager_new':
        case 'settings':
        case 'settings_active':
        case 'wallet_active':
        case 'wallet_new':
            this.url = 'assets/img/icons/' + this.icon + '.svg';
            this.iconSource = 'img';
            break;
        case 'arrow-down-v2':
        case 'arrow-down-blue-v2':
        case 'arrow-up-blue-v2':
        case 'check-v2':
        case 'cross-v2':
        case 'delete-v2':
        case 'toggle-navigation':
        case 'logo_v2':
        case 'remove-v2':
        case 'remove-gray':
        case 'success-v2':
        case 'edit-v2':
        case 'copy-v2':
        case 'empty-v2':
          this.url = 'assets/img/icons/v2/' + this.icon + '.svg';
          break;
        case 'marketplaces':
        case 'au':
        case 'ca':
        case 'cn':
        case 'de':
        case 'es':
        case 'eu':
        case 'fr':
        case 'in':
        case 'it':
        case 'jp':
        case 'mx':
        case 'sl':
        case 'uk':
        case 'us':
          this.url = 'assets/img/flags-v2/' + this.icon + '.svg';
          break;
        case 'amazon_gift_card':
        case 'amazon_gift_card_link':
        case 'mastercard_gift_card':
        case 'visa_gift_card':
        case 'pickreward':
        case 'paypal':
        case 'venmo':
          this.url = 'assets/img/card/' + this.icon + '.svg';
          break;

        case 'st-drafts':
          this.url = 'assets/img/sidebar/icons/drafts.svg';
          break;

        case 'logo-full':
          this.url = 'assets/img/logo-full.svg';
          break;

        case 'stripe-logo':
          this.url = 'assets/img/log-in/' + this.icon + '.svg';
          break;

        case 'spinner':
          this.iconSource = 'spinner';
          break;
        case 'mat:home':
          this.iconSource = 'material';
          this.icon = 'home';
          break;

        case 'last-launch':
          this.url = 'assets/img/sidebar/icons/last-launch.svg';
        break;

        case 'prizm':
        this.url = 'assets/img/sidebar/icons/prizm.svg';
        break;

        case 'promotions':
        this.url = 'assets/img/sidebar/icons/promotions.svg';
        break;

        case 'last-search':
        this.url = 'assets/img/sidebar/icons/last-search.svg';
        break;

        case 'services':
        this.url = 'assets/img/sidebar/icons/services.svg';
        break;

        case 'r2a':
        this.url = 'assets/img/sidebar/icons/r2a.svg';
        break;

        case 'down-arrow':
        this.url = 'assets/img/sidebar/icons/down-arrow.svg';
        break;

        case 'right-arrow':
        this.url = 'assets/img/sidebar/icons/right-arrow.svg';
        break;

        case 'trial':
          this.url = 'assets/img/icons/notices/trial.svg';
          break;

        case 'zapier':
          this.url = '/assets/img/sidebar/icons/zapier.svg';
          break;

        case 'ppcs':
          this.url = '/assets/img/sidebar/icons/ppcs.svg';
          break;

        case 'many_chat':
          this.url = '/assets/img/sidebar/icons/many_chat.svg';
          break;

        case 'api-keys':
          this.url = '/assets/img/sidebar/icons/api-keys.svg';
          break;

        case 'flows':
          this.url = '/assets/img/sidebar/icons/flows.svg';
          break;

        case 'circle-blue-a':
        case 'circle-blue-b':
        case 'circle-blue-p':
        case 'circle-blue-r':
        case 'circle-blue-s':
        case 'circle-blue-t':
        case 'circle-gray-a':
        case 'circle-gray-b':
        case 'circle-gray-p':
        case 'circle-gray-r':
        case 'circle-gray-s':
        case 'circle-gray-t':
          this.url = 'assets/img/icons/letters/' + this.icon + '.svg';
          break;

        case 'blacklist':
          this.url = '/assets/img/sidebar/icons/blacklist.svg';
          break;

        case 'c6_logo_black':
        case 'c6_logo_white':
          this.url = 'assets/img/icons/c6-logo/' + this.icon + '.svg';
          this.iconSource = 'img';
          break;

        default:
          // console.log("Specified icon [" + this.icon + '] does not exists.');
          this.iconSource = 'material';
      }
    }
  }
}
