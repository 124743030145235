import { BaseModel, Collection } from '@app/shared/data/base.models';

export class BlacklistEntry extends BaseModel {
  static className = 'BlacklistEntry';

  public static readonly SOURCE_API = 'api';
  public static readonly SOURCE_MANUAL = 'manual';
  public static readonly SOURCE_AUTO = 'auto';

  value: string;
  field: string;
  note: string;
  source: string;
  invitations?: BlacklistInvitation[];
  created_at: string;
  is_editable: boolean;
  is_deleted: boolean;
  is_whitelisted: boolean;
}

export class BlacklistUser extends BaseModel {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  pic_url?: string;
}

export class BlacklistInvitation extends BaseModel {
  id: number;
  inviter: BlacklistUser;
  list_owner: BlacklistUser;
  invitee: BlacklistUser;
  accepted_at?: string;
  revoked_at?: string;
}

export interface BlacklistInvitationCollection {
  sent: Collection<BlacklistInvitation>;
  received: Collection<BlacklistInvitation>;
}

export class BlacklistSettings extends BaseModel {
  add_when_order_is_refunded: boolean;
  add_when_order_is_returned: boolean;
  add_when_order_is_canceled: boolean;
}
