// same as array_get() on php
export function dotVal(obj, key: string, def: any = null): any {
  const parts = key.split('.');

  for (let i = 0; i < parts.length; i++) {
    if (!obj.hasOwnProperty(parts[i])) {
      return def;
    }

    obj = obj[parts[i]];
  }

  return obj;
}
