
import {catchError,  map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { BaseAPI } from '@app/shared/data/base.api';
import { Invitation } from '@app/shared/data/invitation/invitation.models';
import { handleError } from '@app/helpers';

@Injectable()
export class InvitationApi extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public get(invitation: Invitation): Observable<Invitation> {
    return this.http.get(this.constructFullUrl(`api/sub-invitations/${invitation.token}`))
               .pipe(
                 map(response => new Invitation(response['data'])),
               ).pipe(
               catchError(handleError));
  }

  public accept(invitation: Invitation): Observable<Invitation> {
    return this.http.post(this.constructFullUrl(`api/sub-invitations/${invitation.token}/accept`), {})
               .pipe(
                 map(response => new Invitation(response['data'])),
               ).pipe(
               catchError(handleError));
  }
}
