
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { BaseAPI } from "@app/shared/data/base.api";
import { Commission } from "@app/shared/data/commission/commission.models";

@Injectable()
export class CommissionAPI extends BaseAPI {
  constructor(protected http: HttpClient) {
    super();
  }

  public all(params = {}): Observable<Collection<Commission>> {
    return this.http.get(this.constructFullUrl('api/partnership/commissions'), { params: params }).pipe(
      map(data => Commission.collect<Commission>(data, Commission.BACKEND_PAGINATION)));
  }
}
