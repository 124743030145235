import { PipeTransform, Injectable, Pipe } from "@angular/core";

@Pipe({
    name: 'stFilterFn'
})
@Injectable()
export class FilterFnPipe implements PipeTransform {
    transform(items: any[], testFn: (item:any) => boolean): any[] {
        if (!items) return [];
        return items.filter(testFn);
    }
}
