<st-dialog [disableClose]="true" #dialog="dialog">
    <div dialog-header class="header">
        <div class="question">{{ title }}</div>
    </div>
    <div dialog-content>
        <p>{{message}}</p>
        <st-progress-bar
            [percentage]="progress$ | async"
            [maxFakeProgress]="maxFakeProgress"
        ></st-progress-bar>
    </div>
    <div dialog-footer *ngIf="enableAbort">
        <st-button (click)="close()">{{ buttonText }}</st-button>
    </div>
</st-dialog>
