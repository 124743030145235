import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import { PayoutByPlanAdmin } from "@app/shared/data/admin/payout-by-plan/payout-by-plan.model";

@Injectable()
export class PayoutByPlanAdminActions extends PaginationActions {
  protected model = PayoutByPlanAdmin;

  static readonly TYPES = {
    FILTER: '[PAYOUTBYPLANADMIN]FILTER',
    BYPLAN: '[PAYOUTBYPLANADMIN]COMMISION'
  };

  filter(filters): StAction {
    return {
      type: PayoutByPlanAdminActions.TYPES.FILTER,
      data: filters
    }
  }

  byPlanCommision(byPlanCommision: PayoutByPlanAdmin): StAction {
    return {
      type: PayoutByPlanAdminActions.TYPES.BYPLAN,
      data: {
        commision: byPlanCommision,
      }
    }
  }
}
