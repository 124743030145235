
import {map} from 'rxjs/operators';
import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { Collection } from "@app/shared/data/base.models";
import { BaseAPI } from "@app/shared/data/base.api";
import { Notice } from "@app/shared/data/notice/notice.models";
import { SocketService } from '@app/core/socket/socket.service';

@Injectable()
// todo(z): remove
export class NoticeApi extends BaseAPI {

  private _events = {
    notice_created    : 'system_notice_created',
    notice_update     : 'system_notice_updated',
    notice_deleted    : 'system_notice_deleted',
  };

  constructor(protected http: HttpClient,
              @Inject('SocketService') private _socket: SocketService,
              ) {
    super();
  }

  public all(params = {}): Observable<Collection<Notice>> {
    return this.http.get(this.constructFullUrl('api/notices'), { params: params }).pipe(
      map(data => Notice.collect<Notice>(data)));
  }

  onNewNoticeCreated(): Observable<Notice> {
    return this._noticesListener(this._events.notice_created);
  }

  onNoticeUpdated(): Observable<Notice> {
    return this._noticesListener(this._events.notice_update);
  }

  onNoticeDeleted(): Observable<Notice> {
    return this._noticesListener(this._events.notice_deleted);
  }

  private _noticesListener(event_type: string): Observable<Notice> {
    return this._socket.onPublic(event_type).pipe(
      map((data: any) => {
        return new Notice(data.data);
      }));
  }
}
