<div class="text-left {{ theme }}" style="display: flex; flex-wrap: wrap;">
  <span class="tag" *ngFor="let tag of activeTags">
    <span class="text">{{ tag }}</span>
    <span class="remove" (click)="!disabled && removeTag(tag)"><st-icon icon="clear" class="icon-xs"></st-icon></span>
  </span>

    <span *ngIf="!!!maxItems || activeTags.length < maxItems" class="add-tag-form">
    <form class="form" action="#" (ngSubmit)="addTag(true, false)">
      <input
          *ngIf="!disabled"
          name="tag"
          type="text"
          [maxlength]="tagLength"
          class=""
          placeholder="{{ placeholder }}"
          autocomplete="off"
          [stTooltip]="tagText"
          position="top-left"
          [(ngModel)]="newTagInputValue"
          (blur)="addTag(false, true)"
          (paste)="doPaste($event)"
          (keypress)="doKeyInput($event)" #taginput>

      <ol class="suggestions"
          *ngIf="newTagInputValue.length && (autocompleteItems | stFilterFn:filterSuggestions()).length">
          <li *ngFor="let t of autocompleteItems | stFilterFn:filterSuggestions()" class="pointer"
              (mousedown)="$event.preventDefault()" (click)="addTag(true, false, t.value)">
              <button class="button interface-component white-bg">{{ t.value }}</button>
          </li>
      </ol>
    </form>
  </span>
</div>
