import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SurveyQuestion, SurveyQuestionOption } from '@app/shared/data/survey/survey.models';
import { ICheckboxChanged } from '@app/shared/layout/v2/forms/checkbox/checkbox.component';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';

@Component({
  selector   : 'st-survey-question',
  templateUrl: './survey-question.component.html',
  styleUrls  : ['./survey-question.component.scss'],
})
export class SurveyQuestionComponent {
  @Input() question: SurveyQuestion;
  @Input() count: number;
  @Output() onCheckboxChange: EventEmitter<{ questionId: number, optionId: number }> = new EventEmitter();
  @Output() onDrodpownChange: EventEmitter<{ questionId: number, optionId: string }> = new EventEmitter();
  @Output() onTextChange: EventEmitter<{ questionId: number, value: string }> = new EventEmitter();

  constructor() { }

  optionSelected(data: ICheckboxChanged) {
    this.onCheckboxChange.emit({ questionId: this.question.id, optionId: data.id as number });
  }

  textChange(event) {
    this.onTextChange.emit({ questionId: this.question.id, value: event.target.value });
  }

  dropdownSelected(event: IDropdownOption) {
    this.onDrodpownChange.emit({ questionId: this.question.id, optionId: event.id as string });
  }

  get selectOptions(): IDropdownOption[] {
    return this.question.options.map((question: SurveyQuestionOption) => {
      return {
        id: question.id,
        label: question.value as string,
      };
    });
  }
}
