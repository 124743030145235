import { BaseModel } from "@app/shared/data/base.models";

export class AffiliateYourCommision extends BaseModel {
    static className = 'AffiliateYourCommision';
    user_id      : number;
    commision_id : number;
    coupon       : string;
    lead         : string;
    plan         : string;
    redeemed     : string;
    commision    : number;
    scheduled_for: string;
    cancelled_at : string;
    status       : string;
}
