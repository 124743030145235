import {
  Component,
  EventEmitter,
  Input,
  Output, forwardRef, HostListener, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Subject } from "rxjs";
import {I18n} from "@ngx-translate/i18n-polyfill";
import { merge, map, takeUntil } from 'rxjs/operators';

@Component({
  selector   : 'st-tag-list',
  templateUrl: './tag-list.component.html',
  styleUrls  : ['./tag-list.component.scss'],
  providers  : [
    {
      provide    : NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TagListComponent),
      multi      : true,
    },
  ],
})
export class TagListComponent implements ControlValueAccessor, OnDestroy {

  writeValue(obj: any[]): void {
    if (!obj) return;
    this.activeTags = obj.map((item) => {
      return this.displayBy
        ? item[this.displayBy]
        : item;
    });
    this.cd.markForCheck();
  }

  constructor(private cd: ChangeDetectorRef,
              private i18n: I18n) {
  }


  private onTouched$ = new Subject();

  registerOnChange(fn: any): void {
    this.onAdd.pipe(
      merge(this.onRemove),
      map(() => this.activeTags),
      takeUntil(this.onDestroy$)
    ).subscribe(fn)
  }

  registerOnTouched(fn: any): void {
    this.onTouched$.pipe(takeUntil(this.onDestroy$)).subscribe(fn)
  }

  public activeTags: Array<any> = [];
  public newTagInputValue: string = "";

  @Input() autocompleteItems: Array<any>;
  @Input() maxItems: number = null;
  @Input() addOnBlur: boolean = false;
  @Input() displayBy: string = null;
  @Input() placeholder: string = this.i18n('+ TAG');
  @Input() theme: string = "default";
  @Input() tagText: string = this.i18n('Add new Tag');
  @Input() disabled: boolean = false;
  @Input() tagLength: number = 15;

  @Input() validators: ((string) => boolean)[];
  @Input() tagsTooltip: string = null;
  @Input() inputTooltip: string = null;

  @Output() public onRemove: EventEmitter<any> = new EventEmitter<any>();
  @Output() public onAdd: EventEmitter<any> = new EventEmitter<any>();

  @Output() public status: EventEmitter<any> = new EventEmitter<any>();

  @Input() public delimiters: string[] = [];

  @ViewChild('taginput') inputElement: ElementRef;

  private onDestroy$ = new Subject();
  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  /**
   *
   * @param tag
   */
  removeTag(tag) {
    this.statusChanged(true);

    this.onRemove.next(tag);
    this.activeTags.splice(this.activeTags.indexOf(tag), 1);

    this.statusChanged(false);
  }

  /**
   *
   * @param forceAdd
   * @param delay
   * @param tagValue
   */
  addTag(forceAdd: boolean = false, delay = false, tagValue: string = null) {
    this.statusChanged(true);

    // Easiest workaround for suggestions click (because "onBlur" is called before "suggestion click"
    if (tagValue == null)
      tagValue = this.newTagInputValue;

    tagValue = tagValue.toUpperCase();

    if (this.activeTags.filter((i: any) => {
        return i == tagValue;
      }).length > 0)
      return false;

    if (this.validators) {
      for (let i = 0; i < this.validators.length; i++) {
        if (!this.validators[i](tagValue))
          return false;
      }
    }

    if (tagValue != '' && (forceAdd || this.addOnBlur)) {
      this.newTagInputValue = '';

      this.onAdd.next(tagValue);
      this.activeTags.push(tagValue);
    }

    this.statusChanged(false);
    return true;
  }

  /**
   *
   * @returns {(item:any)=>boolean}
   */
  filterSuggestions() {
    return (item: any) => {
      return item.value.indexOf(this.newTagInputValue.toUpperCase()) !== -1 &&
        this.activeTags.filter((i: any) => {
          return i == item.value;
        }).length == 0;
    }
  }

  /**
   *
   * @param working
   */
  private statusChanged(working) {
    this.status.next(working);
  }


  private doPaste(e) {
    let toPaste = e.clipboardData.getData("text/plain");
    let str = this.newTagInputValue = this.newTagInputValue + toPaste;

    if (this.delimiters && this.delimiters.length > 0) {
      const seperateRegExp = new RegExp(this.delimiters.join('|'), 'g');
      let kws = toPaste.split(seperateRegExp);

      const invalidKws = kws.filter(kw => !this.addTag(false, false, kw));

      this.newTagInputValue = invalidKws.join(' ');
    }

    e.preventDefault();
  }

  private doKeyInput(e) {
    if (this.delimiters.includes(String.fromCharCode(e.charCode)))
      if (this.addTag(false, false, this.newTagInputValue))
        e.preventDefault();
  }

  @HostListener('click', ['$event'])
  public focus() {
    if (this.inputElement)
      this.inputElement.nativeElement.focus();
  }
}
