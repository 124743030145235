<st-dialog #dialog="dialog">
  <div dialog-header class="modal-header"></div>
  <div dialog-content class="content">
    <st-icon icon="check-v2"></st-icon>
    <p i18n>You've successfully published your listing on Amazon</p>
  </div>
  <div dialog-footer class="footer">
    <st-button-v2 (clicked)="confirmed()" color="v2_red_empty" label="OK" i18n-label></st-button-v2>
  </div>
</st-dialog>
