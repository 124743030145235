import { NgModule } from "@angular/core";
import { CommonModule } from '@angular/common';
import { WhiteBoxComponent } from "@app/client/v2/white-box/white-box.component";
import { AdvancedFiltersComponent } from "@app/client/v2/common/advanced-filters/advanced-filters.component";
import { AdvancedFiltersModalComponent, OperatorTranslator } from './advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { LayoutV2Module } from "@app/shared/layout/v2/layout-v2.module";
import { SharedModule } from "@app/shared/shared.module";
import { PaginationComponent } from "../pagination/pagination.component";
import { SavedViewsComponent } from "./saved-views/saved-views.component";
import { PaginationInfoComponent } from "../pagination-info/pagination-info.component";
import { NewWhiteBoxComponent } from '../new-white-box/new-white-box.component';
import { TagsComponent } from './tags/tags.component';
import { BrowseProductsModalComponent } from './browse-products-modal/browse-products-modal.component';
import { ProductListTableComponent } from "./browse-products-modal/product-list-table/product-list-table.component";

const components = [
  WhiteBoxComponent,
  AdvancedFiltersComponent,
  AdvancedFiltersModalComponent,
  SavedViewsComponent,
  PaginationComponent,
  PaginationInfoComponent,
  NewWhiteBoxComponent,
  TagsComponent,
  BrowseProductsModalComponent,
  ProductListTableComponent
];

@NgModule({
  imports: [
    LayoutV2Module,
    CommonModule,
    SharedModule
  ],
  declarations: [
    ...components,
  ],
  exports: [
    ...components,
  ],
  providers: [
    OperatorTranslator,
  ],
  entryComponents: [AdvancedFiltersModalComponent, BrowseProductsModalComponent]
})
export class V2CommonModule { }
