import { NgRedux } from '@angular-redux/store';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewRef } from '@angular/core';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';
import { AppState } from '@app/shared/data/app-state.model';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import { IProductManagerProduct, IProductManagerState, IProductManagerVariation } from '@app/shared/data/product-manager/product-manager.models';
import { ButtonIconEnum } from '@app/shared/layout/v2/forms/button/button.component';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import {
  IFieldOrder,
  SortByEnum,
  SortOrderEnum
} from '../product-manager-product-list/product-manager-product-list.component';
import { ProductManagerService } from '../product-manager.service';

@Component({
  templateUrl: './product-manager-copy-keyword-modal.component.html',
  styleUrls: ['./product-manager-copy-keyword-modal.component.scss'],
})
export class ProductManagerCopyKeywordModalComponent implements OnInit, OnDestroy {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;
  tags: string[] = [];
  data?: any;

  asin: string;

  perPage = this.productManagerService.perPage;
  perPageOptions = this.productManagerService.perPageOptions;
  preSelectedOption = this.productManagerService.preSelectedOption;
  currentPage = 1;
  total: number;
  pagesArray: number[];
  initDone: boolean;
  from: number;
  to: number;
  lastPage: number;

  variationPerPage = 20;
  variationCurrentPage = 1;
  variationTotal: number;
  variationPagesArray: number[];
  variationFrom: number;
  variationTo: number;
  variationLastPage: number;

  products: IProductManagerProduct[];
  productVariations: IProductManagerVariation[];
  archivedProducts: IProductManagerProduct[];
  loadingProducts = true;
  loadingProductVariations = false;
  selectedProduct: IProductManagerProduct;
  savedViewsOpened = false;

  searchProductString: string;
  searchQuery$ = new Subject<string>();

  buttonIcons = ButtonIconEnum;
  sortByEnum = SortByEnum;
  bsrGraph: number[][];
  loadingGraphData: boolean;

  defaultSortBy: IFieldOrder[] =  [{
    field: SortByEnum.STATUS,
    order: SortOrderEnum.DESC
  }, {
    field: SortByEnum.TITLE,
    order: SortOrderEnum.ASC
  }];

  currentSortBy: IFieldOrder[] = this.defaultSortBy;
  private destroy$: Subject<void> = new Subject();

  constructor(
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
    private productManagerService: ProductManagerService,
    private cd: ChangeDetectorRef,
  ) { }

  ngOnInit() {
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy));
    this.asin = this.data.asin;

    this.ngRedux.select('product_manager')
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((productManagerState: IProductManagerState) => {
        this.loadingProducts = productManagerState.loadingProducts;
        this.loadingProductVariations = productManagerState.loadingProductVariations;
        this.productVariations = productManagerState.productVariationsState.products;
        this.products = productManagerState.productsState.products;
        this.from = productManagerState.productsState.from;
        this.to = productManagerState.productsState.to;
        this.perPage = productManagerState.productsState.per_page || 20;
        this.currentPage = productManagerState.productsState.current_page || 1;
        this.total = productManagerState.productsState.total;
        this.lastPage = productManagerState.productsState.last_page;
        this.variationPerPage = productManagerState.productVariationsState.per_page || 20;
        this.variationCurrentPage = productManagerState.productVariationsState.current_page || 1;
        this.variationTotal = productManagerState.productVariationsState.total;
        this.variationFrom = productManagerState.productVariationsState.from;
        this.variationTo = productManagerState.productVariationsState.to;
        this.variationLastPage = productManagerState.productVariationsState.last_page;

        this.loadingGraphData = productManagerState.loadingGraphData;
        this.bsrGraph = productManagerState.graphData.dataPoints;

        const numberOfPages = Math.ceil(this.total / this.perPage);

        if (!isNaN(numberOfPages)) {
          this.pagesArray = Array(numberOfPages).fill(0).map((_x, i) => i + 1);
        }

        const numberOfVariationPages = Math.ceil(this.variationTotal / this.variationPerPage);

        if (!isNaN(numberOfVariationPages)) {
          this.variationPagesArray = Array(numberOfVariationPages).fill(0).map((_x, i) => i + 1);
        }

        if (this.canDetectChanges) {
          this.cd.detectChanges();
        }
      });

    this.searchQuery$
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        takeUntil(this.destroy$)
      ).subscribe((value: string) => {
        this.currentPage = 1;
        this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy, false, value));
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  sortBy(field: SortByEnum) {
    const existing = this.currentSortBy.find((filedOrder: IFieldOrder) => filedOrder.field === field);

    // if field is already on the order list, toggle the sorting
    if (existing !== undefined) {
      existing.order = existing.order === SortOrderEnum.DESC ? SortOrderEnum.ASC : SortOrderEnum.DESC;
    } else {
      this.currentSortBy = [{
        field: SortByEnum.STATUS,
        order: SortOrderEnum.DESC
      }, {
        field,
        order: SortOrderEnum.ASC,
      }];
    }

    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy));
  }

  toggleProductDetails(event: IProductManagerProduct & { tab: 'graph'|'details' }) {
    this.ngRedux.dispatch(this.productManagerActions.toggleProduct(event.asin));

    if (event.detailsOpened) {
      return;
    }

    if (event.tab === 'details') {
      this.ngRedux.dispatch(this.productManagerActions.setActiveTab('details', event.asin));
      this.ngRedux.dispatch(this.productManagerActions.getProductVariations(event.asin, this.variationCurrentPage, this.variationPerPage));
    } else if (event.tab === 'graph') {
      this.ngRedux.dispatch(this.productManagerActions.setActiveTab('graph', event.asin));
      this.ngRedux.dispatch(this.productManagerActions.getGraphData(event.asin, ['bsr']));
    }
  }

  toggleGraph(product: IProductManagerProduct) {
    this.ngRedux.dispatch(this.productManagerActions.getGraphData(product.asin, ['bsr']));
  }

  previousPage() {
    if (this.currentPage > 1) {
      this.initDone = false;
      this.currentPage--;
      this.fetchProductsOnNavigationChange();
    }
  }

  nextPage() {
    if (this.currentPage < this.pagesArray.length - 1) {
      this.initDone = false;
      this.currentPage++;
      this.fetchProductsOnNavigationChange();
    }
  }

  goToFirstPage() {
    this.currentPage = 1;
    this.initDone = false;
    this.fetchProductsOnNavigationChange();
  }

  goToLastPage() {
    this.currentPage = this.lastPage;
    this.initDone = false;
    this.fetchProductsOnNavigationChange();
  }

  changePage(page: number) {
    this.initDone = false;
    this.currentPage = page;
    this.fetchProductsOnNavigationChange();
  }

  variationPreviousPage() {
    if (this.variationCurrentPage > 1) {
      this.variationCurrentPage--;
      this.fetchProductsOnNavigationChange();
    }
  }

  variationNextPage() {
    if (this.variationCurrentPage < this.variationPagesArray.length - 1) {
      this.variationCurrentPage++;
      this.fetchProductsOnNavigationChange();
    }
  }

  variationGoToFirstPage() {
    this.variationCurrentPage = 1;
    this.fetchProductsOnNavigationChange();
  }

  variationGoToLastPage() {
    this.variationCurrentPage = this.variationLastPage;
    this.fetchProductsOnNavigationChange();
  }

  variationPageChange(event: {event: IDropdownOption, product: IProductManagerVariation}) {
    this.variationCurrentPage = 1;
    this.variationPerPage = event.event.id as number;
    this.ngRedux.dispatch(this.productManagerActions.getProductVariations(event.product.asin, this.variationCurrentPage, this.variationPerPage));
  }

  pageChange(event: IDropdownOption) {
    this.currentPage = 1;
    this.perPage = event.id as number;
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy));
  }

  variationChangePage(page: number) {
    this.variationCurrentPage = page;
    this.fetchProductsOnNavigationChange();
  }

  selectProduct(product: IProductManagerProduct) {
    this.selectedProduct = product;
  }

  fetchProductsOnNavigationChange() {
    this.ngRedux.dispatch(this.productManagerActions.getProducts(this.currentPage, this.perPage, this.currentSortBy));
  }

  cancel() {
    this.modal.close();
  }

  searchProducts() {
    this.searchQuery$.next(this.searchProductString);
  }

  save() {
    if (!this.selectedProduct) {
      return;
    }

    this.ngRedux.dispatch(this.productManagerActions.copyKeywords(this.asin, [this.selectedProduct.asin]));
    this.modal.close();
  }

  onTagsChange(tags: string[]) {
    this.tags = tags;
  }

  get confirmButtonDisabled(): boolean {
    return !Boolean(this.selectedProduct);
  }

  get canDetectChanges() {
    return this.cd && !(this.cd as ViewRef).destroyed;
  }

  get currentDisplayedVariationProducts(): string {
    return `Showing ${this.variationFrom} - ${this.variationTo} of ${this.variationTotal}`;
  }

  get currentDisplayedProducts(): string {
    return `Showing ${this.from} - ${this.to} of ${this.total}`;
  }
}
