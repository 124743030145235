import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { ValidatorService } from '@app/core/services/validator.service';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux, select } from '@angular-redux/store';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AuthService } from '@app/core/services/auth.service';
import { UsersActions } from '@app/shared/data/user/user.actions';
import { ActiveModal } from '@app/shared/components/modals/modal.service';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { skip, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'st-edit-billing-information',
  templateUrl: './edit-billing-information.component.html',
  styleUrls: ['./edit-billing-information.component.scss']
})
export class EditBillingInformationComponent implements OnInit, OnDestroy {
  public editBillingInformationForm: FormGroup;
  @select(['user', '_is_updating'])
  public saving$;

  public translateBreadcrumbs: string[] = [this.i18n('Edit Billing Information'), 'SellerTools'];

  private onDestroy$ = new EventEmitter();

  constructor(private authService: AuthService,
              private ngRedux: NgRedux<AppState>,
              private activeDialog: ActiveModal,
              private userActions: UsersActions,
              private formBuilder: FormBuilder,
              private i18n: I18n) {
  }

  ngOnInit(): void {
    const billingInformation = this.authService.getUser().billing_information || [];

    this.editBillingInformationForm = this.formBuilder.group({
      info: [billingInformation.join('\n'), [ValidatorService.RequiredStar, ValidatorService.maxLength(255), ValidatorService.maxLines(5)]],
    });

    this.saving$.pipe(
      takeUntil(this.onDestroy$),
      skip(1),
    ).subscribe((saving) => {
      if (!saving) {
        this.activeDialog.close();
      }
    });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  save() {
    const information = this.editBillingInformationForm.getRawValue().info;

    this.ngRedux.dispatch(this.userActions.updateProfile({
      information,
      action: 'billing_information',
    }));
  }

  abort() {
    this.activeDialog.close();
  }
}
