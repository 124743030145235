import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal/modal.component';
import { ModalService, ActiveModal } from "@app/shared/components/modals/modal.service";
import { SvgIconModule } from "@app/shared/layout/icon/svg-icon.module";
import { BreadcrumbModule } from "@app/shared/layout/breadcrumb/breadcrumb.module";
import { ModalOverlay } from "@app/shared/components/modals/modal/modal.component";

@NgModule({
  imports     : [
    CommonModule,
    SvgIconModule,
  ],
  declarations: [
    ModalComponent,
    ModalOverlay
  ],
  exports     : [
    ModalComponent,
    ModalOverlay
  ],
  providers   : [
    ModalService,
    ActiveModal
  ]
})
export class ModalModule {
}
