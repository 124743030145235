import { Injectable } from '@angular/core';
import { PaginationActions } from '@app/shared/data/pagination/pagination.actions';
import { StAction } from '@app/shared/data/st-action';
import { AppVersion } from '@app/shared/data/version/version.models';

@Injectable()
export class VersionActions extends PaginationActions {
  protected model = AppVersion;

  static readonly TYPES = {
    FILTER                    : '[VERSION_ADMIN]FILTER',

    EDITING_VERSION           : '[VERSION_ADMIN]EDITING_VERSION',
    EDITING_VERSION_SUCCEEDED : '[VERSION_ADMIN]EDITING_VERSION_SUCCEEDED',
    EDITING_VERSION_FAILED    : '[VERSION_ADMIN]EDITING_VERSION_FAILED',

    SAVE                      : '[VERSION_ADMIN]SAVE',
    SAVE_SUCCEEDED            : '[VERSION_ADMIN]SAVE_SUCCEEDED',
    SAVE_FAILED               : '[VERSION_ADMIN]SAVE_FAILED',

    REMOVE                    : '[VERSION_ADMIN]REMOVE',
    REMOVE_SUCCEEDED          : '[VERSION_ADMIN]REMOVE_SUCCEEDED',
    REMOVE_FAILED             : '[VERSION_ADMIN]REMOVE_FAILED',
  };

  static readonly EVENTS = {
    NEW_VERSION_RELEASED: 'new_version_released',
  };

  filter(filters): StAction {
    return {
      type: VersionActions.TYPES.FILTER,
      data: filters,
    };
  }

  setEditingVersion(id): StAction {
    return {
      type: VersionActions.TYPES.EDITING_VERSION,
      data: {
        id: id,
      }
    };
  }

  setEditingVersionSucceeded(version: AppVersion): StAction {
    return {
      type: VersionActions.TYPES.EDITING_VERSION_SUCCEEDED,
      data: {
        version: version,
      }
    };
  }

  setEditingVersionFailed(error: any): StAction {
    return {
      type: VersionActions.TYPES.EDITING_VERSION_FAILED,
      data: {
        error: error,
      }
    };
  }

  save(data): StAction {
    return {
      type: VersionActions.TYPES.SAVE,
      data: data
    };
  }

  saveSucceeded(version: AppVersion): StAction {
    return {
      type: VersionActions.TYPES.SAVE_SUCCEEDED,
      data: {
        version: version,
      }
    };
  }

  saveFailed(error: any): StAction {
    return {
      type: VersionActions.TYPES.SAVE_FAILED,
      data: {
        error: error,
      }
    };
  }

  remove(version: AppVersion): StAction {
    return {
      type: VersionActions.TYPES.REMOVE,
      data: {
        version: version,
      }
    };
  }

  removeSucceeded(version: AppVersion): StAction {
    return {
      type: VersionActions.TYPES.REMOVE_SUCCEEDED,
      data: {
        version: version,
      }
    };
  }

  removeFailed(error: any): StAction {
    return {
      type: VersionActions.TYPES.REMOVE_FAILED,
      data: {
        error: error,
      }
    };
  }
}
