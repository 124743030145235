import { Component, Input } from "@angular/core";

@Component({
  selector : 'st-line-loader',
  template : '<div class="loading-bar" [class.loading]="loading">&nbsp;</div>',
  styleUrls: ['./line-loader.component.scss']
})
export class LineLoaderComponent {
  @Input()
  loading = true;
}
