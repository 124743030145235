import { PipeTransform, Injectable, Pipe } from '@angular/core';
import { IFilter, OperatorEnum } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { IProductManagerKeyword } from '../data/product-manager/product-manager.models';

@Pipe({
  name: 'stKeywordPipe'
})
@Injectable()
export class KeywordPipe implements PipeTransform {
  transform(keywords: IProductManagerKeyword[], filters: IFilter[]): IProductManagerKeyword[] {
    if (!keywords) return [];
    if (!filters || filters.length === 0) return keywords || [];

    return keywords.filter((keyword: IProductManagerKeyword) => {
      // This has to be set otherwise indexed filter doesn't work if indexed property isn't set on keyword
      if (!Object.keys(keyword).includes('indexed')) {
        keyword.indexed = false;
      }

      const canDisplay = filters.every((filter: IFilter) => {
        let through = true;

        if ([
          'rank',
          'impressions',
          'sfr_rank',
          'sales_rate',
          'number_of_competitors',
          'click_dominance',
          'search_dominance'
        ].includes(filter.field)) {
          // These 3 are displayed with Angular percentage pipe, we need to multiply with 100 to get numbers
          let value = keyword[filter.field];
          if ([
            'sales_rate',
            'click_dominance',
            'search_dominance',
          ].includes(filter.field)) {
            value = keyword[filter.field] * 100;            
          }

          if (filter.operator === OperatorEnum.GREATER_EQUALS) {
            through = value >= filter.value;
          }
          
          if (filter.operator === OperatorEnum.EQUALS) {
            through = value === filter.value;
          }
          
          if (filter.operator === OperatorEnum.LESS_EQUALS) {
            through = value <= filter.value;
          }
        }

        if (['indexed'].includes(filter.field)) {
          const indexedMapper = {
            0: true,
            1: false,
          };

          through = keyword[filter.field] === indexedMapper[filter.value];
        }

        if (['priority'].includes(filter.field)) {
          through = filter.value.includes(keyword[filter.field]);
        }

        if (['set_data'].includes(filter.field)) {
          through = filter.value.every((value: string) => {
            return Boolean(keyword[value]);
          });
        }

        return through;
      });

      return canDisplay;
    });
  }
}
