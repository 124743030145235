import { Component, EventEmitter, Input, Output } from '@angular/core';
import { V2TableDataRecord } from '../../table.models';

@Component({
  selector: 'st-table-status-item',
  templateUrl: './status.item.component.html',
  styleUrls: ['./status.item.component.scss']
})
export class StatusItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() clicked: EventEmitter<void> = new EventEmitter();
}
