<div class="status-wrapper">
  <div class="status-line"></div>
  <div class="status-steps" [class.status-steps--active]="isFilterActive">
    <div class="status-handle status-handle--0" [class.status-handle--active]="activePriorityFilter === 'VL'"
      (click)="filterByPriority('VL')" position="left" [stTooltip]="getStatusHandleTooltip('VL')"></div>
    <div class="status-handle middle status-handle--1"
      [class.status-handle--active]="activePriorityFilter === 'L'" (click)="filterByPriority('L')"
      position="left" [stTooltip]="getStatusHandleTooltip('L')"></div>
    <div class="status-handle end status-handle--2" [class.status-handle--active]="activePriorityFilter === 'M'"
      (click)="filterByPriority('M')" position="left" [stTooltip]="getStatusHandleTooltip('M')"></div>
    <div class="status-handle end status-handle--3" [class.status-handle--active]="activePriorityFilter === 'H'"
      (click)="filterByPriority('H')" position="left" [stTooltip]="getStatusHandleTooltip('H')"></div>
  </div>
</div>
<div class="sort-icon-wrapper"
  *ngIf="!column.hideSort"
  (click)="sortBy()">
  <div class="sort-icon sort-icon--down"></div>
  <div class="sort-icon sort-icon--up"></div>
</div>
