import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { AdminPlan } from "@app/shared/data/admin/plan/plan.models";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";

@Injectable()
export class AdminPlanActions extends PaginationActions {
  protected model = AdminPlan;

  static readonly EVENTS = {};

  static readonly TYPES = {
    FILTER: '[ADMINPLAN]FILTER',

    GET          : '[ADMINPLAN]GET',
    GET_SUCCEEDED: '[ADMINPLAN]GET_SUCCEEDED',
    GET_FAILED   : '[ADMINPLAN]GET_FAILED',

    SAVE          : '[ADMINPLAN]SAVE',
    SAVE_SUCCEEDED: '[ADMINPLAN]SAVE_SUCCEEDED',
    SAVE_FAILED   : '[ADMINPLAN]SAVE_FAILED',

    REMOVE          : '[ADMINPLAN]REMOVE',
    REMOVE_SUCCEEDED: '[ADMINPLAN]REMOVE_SUCCEEDED',
    REMOVE_FAILED   : '[ADMINPLAN]REMOVE_FAILED',
  };

  static filter(filters): StAction {
    return {
      type: AdminPlanActions.TYPES.FILTER,
      data: filters
    }
  }

  static save(plan: AdminPlan, limits, permissionsChanges): StAction {
    return {
      type: AdminPlanActions.TYPES.SAVE,
      data: {
        plan              : plan,
        limits            : limits,
        permissionsChanges: permissionsChanges,
      }
    }
  }

  static saveSuccedded(plan: AdminPlan): StAction {
    return {
      type: AdminPlanActions.TYPES.SAVE_SUCCEEDED,
      data: plan
    }
  }

  static saveFailed(error): StAction {
    return {
      type: AdminPlanActions.TYPES.SAVE_FAILED,
      data: error
    }
  }

  static getPlan(plan: AdminPlan): StAction {
    if (isNaN(plan.id)) {
      return {
        type: AdminPlanActions.TYPES.GET_SUCCEEDED,
        data: new AdminPlan()
      };
    }
    return {
      type: AdminPlanActions.TYPES.GET,
      data: plan
    };
  }

  static getPlanSucceeded(plan: AdminPlan): StAction {
    return {
      type: AdminPlanActions.TYPES.GET_SUCCEEDED,
      data: plan
    }
  }

  static getPlanFailed(error): StAction {
    return {
      type: AdminPlanActions.TYPES.GET_FAILED,
      data: error
    }
  }
}
