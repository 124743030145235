import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { Highcharts } from '@app/shared/components/charts/highcharts.service';
import { AxisDirective } from '@app/shared/components/charts/directives/axis.directive';
import { BarDirective } from '@app/shared/components/charts/directives/bar.directive';
import { LineDirective } from '@app/shared/components/charts/directives/line.directive';
import { ChartComponent } from '@app/shared/components/charts/chart.component';
import { StockChartComponent } from "@app/shared/components/charts/stock-chart.component";
import { FlagsDirective } from "@app/shared/components/charts/directives/flags.directive";
import * as _HighStock from "highcharts/highstock";
import { GlobalOptions } from "highcharts";
import { LegendDirective } from "@app/shared/components/charts/directives/legend.directive";
import { PrizmChartComponent } from './prizm-chart.component';
import { Options } from 'highcharts';

const CHART_DIRECTIVES = [
  AxisDirective,
  BarDirective,
  LineDirective,
  FlagsDirective,
  LegendDirective,
];

const CHART_COMPONENTS = [
  ChartComponent,
  StockChartComponent,
  PrizmChartComponent,
];

export function chartsModuleFactory(highchartLibraryKey: string, highchartsModules: Array<Function>) {
  let highchartLibrary = highchartLibraryKey === HighStock ? _HighStock : null;

  if(highchartsModules) {
    highchartsModules.forEach((module) => {
      module(highchartLibrary);
    });
  }

  highchartLibrary.setOptions(<Options>{
   lang: {
      thousandsSep: ','
    },
    colors: ['#5CB4C9', '#9C69A9', '#FFD700', '#b74cee', '#6ecb00', '#f7c429', '#00dba5', '#0081db', '#00badb']
  });
  return highchartLibrary;
}

export const HC_MODULES = new InjectionToken<string>('HC_MODULES');
export const HC_LIBRARY = new InjectionToken<Array<Function>>('HC_LIBRARY');
export const HighStock = 'HighStock';

@NgModule({
  declarations: [
    ...CHART_DIRECTIVES,
    ...CHART_COMPONENTS,
  ],
  exports     : [
    ...CHART_DIRECTIVES,
    ...CHART_COMPONENTS,
  ],
})
export class ChartsModule {
  static forRoot(highchartLibrary: string,
                 ...highchartsModules: Array<Function>): ModuleWithProviders<ChartsModule> {
    return {
      ngModule : ChartsModule,
      providers: [
        [{
          provide: HC_MODULES,
          useValue:  highchartsModules,
        },
        {
          provide: HC_LIBRARY,
          useValue: highchartLibrary,
        }],
        {
          provide : Highcharts,
          useFactory: chartsModuleFactory,
          deps: [HC_LIBRARY, HC_MODULES]
        },
      ],
    };
  }
}
