import { Directive, Input, OnInit } from '@angular/core';
import { HighchartsService } from '@app/shared/components/charts/highcharts.service';
import { LegendModel } from "@app/shared/components/charts/models/legend.model";

@Directive({
  selector: 'legend',
})
export class LegendDirective implements OnInit {

  @Input()
  set enabled(enabled: boolean) {
    this._options.setEnabled(enabled);
  };

  @Input()
  set checkboxes(checkboxes:boolean) {
    if (checkboxes) {
      this._options.labelFormatter = function () {
        return `
                <button type="chart-checkbox" class='chart-checkbox' style="color: ${this.color};" name="${this.name}" id="${this.index}"></button>
                ${this.name}`;
      };
      this._options.useHTML = true;
    }
  }

  private _options = new LegendModel();

  constructor(private _highchartsService: HighchartsService) {
  }

  ngOnInit(): void {
    this._highchartsService.options.legend = this.getOptions();
  }

  getOptions(): {} {
    return { ... this._options};
  }

}
