import { Component, EventEmitter, Input, Output } from "@angular/core";
import { V2TableDataRecord } from "../../table.models";

export enum ActionEnum {
  EDIT = 'edit',
  DELETE = 'delete-v2',
  BUTTON = 'button'
}

@Component({
  selector: 'st-table-action-item',
  templateUrl: './action.item.component.html',
  styleUrls: ['./action.item.component.scss']
})
export class ActionItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() clicked: EventEmitter<ActionEnum> = new EventEmitter();

  actionEnum = ActionEnum;
}
