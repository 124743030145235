import { ChangeDetectorRef, Component, Injectable, ViewChild } from '@angular/core';
import { ModalComponent } from '@app/shared/components/modals/modal/modal.component';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { BehaviorSubject } from 'rxjs';

export enum OperatorEnum {
  EQUALS = '==',
  NOT_EQUALS = '!=',
  RANGE = '..',
  NOT_RANGE = '!.',
  CONTAINS = '=@',
  NOT_CONTAINS = '!@',
  ENDS_WITH = '=$',
  NOT_ENDS_WITH = '!$',
  GREATER = '>',
  GREATER_EQUALS = '>=',
  LESS = '<',
  LESS_EQUALS = '<=',
}

export enum FilterTypeEnum {
  INPUT = 'input',
  INPUT_SINGLE = 'input_single',
  POSITIVE_NUMBER = 'positive_number',
  DATE = 'date',
  DROPDOWN = 'dropdown',
  MULTISELECT = 'multiselect',
}

export interface IFilter {
  field: string;
  operator: OperatorEnum;
  value: any;
  title?: string; // translated name of filter
  valueAsAString?: string; // translated value
}

export interface IAvailableFilter {
  field: string;
  operators: {
    [key in OperatorEnum]?: IFilterOptions
  };
  title: string; // translated name of filter
  search?: boolean;
  show?: boolean;
}

export interface IFilterOptions {
  type: FilterTypeEnum;
  options?: IDropdownOption[];
}

export interface ISavedFilter {
  id?: number;
  filters: IFilter[];
  name: string;
}

@Injectable()
export class OperatorTranslator {
  constructor(private i18n: I18n) { }

  public translate(operator: OperatorEnum): string {
    switch (operator) {
      case OperatorEnum.RANGE: return this.i18n('In Range');
      case OperatorEnum.NOT_RANGE: return this.i18n('Not In Range');
      case OperatorEnum.EQUALS: return this.i18n('Equals to');
      case OperatorEnum.NOT_EQUALS: return this.i18n('Not equal to');
      case OperatorEnum.CONTAINS: return this.i18n('Contains');
      case OperatorEnum.NOT_CONTAINS: return this.i18n('Don\'t contain');
      case OperatorEnum.ENDS_WITH: return this.i18n('Ends with');
      case OperatorEnum.NOT_ENDS_WITH: return this.i18n('Does not end with');
      case OperatorEnum.GREATER: return this.i18n('Greater than');
      case OperatorEnum.GREATER_EQUALS: return this.i18n('Greater than or equal to');
      case OperatorEnum.LESS: return this.i18n('Less than');
      case OperatorEnum.LESS_EQUALS: return this.i18n('Less than or equal to');
    }
  }
}

export function filterOperator(value: any, filter: IFilter): boolean {
  switch (filter.operator) {
    case OperatorEnum.EQUALS:         return value === filter.value;
    case OperatorEnum.NOT_EQUALS:     return value !== filter.value;
    case OperatorEnum.GREATER:        return value > filter.value;
    case OperatorEnum.GREATER_EQUALS: return value >= filter.value;
    case OperatorEnum.LESS:           return value < filter.value;
    case OperatorEnum.LESS_EQUALS:    return value <= filter.value;
    case OperatorEnum.CONTAINS:       return value.includes(filter.value.toString());
    case OperatorEnum.NOT_CONTAINS:   return ! value.includes(filter.value.toString());
    default :                         return false;
  }
}


@Component({
  selector: 'st-advanced-filters-modal',
  templateUrl: './advanced-filters-modal.component.html',
  styleUrls: ['./advanced-filters-modal.component.scss']
})
export class AdvancedFiltersModalComponent {
  @ViewChild(ModalComponent) modal: ModalComponent;

  filters: {
    id: any,
    label: string,
    operator: IDropdownOption,
    value: any,
    valueAdditional?: any,
    type: FilterTypeEnum,
    search?: boolean,
    multiselectOptions: any,
    show?: boolean
  }[] = [];

  filterOperators: { [key: string]: IDropdownOption[] } = {};
  filterOptions: {
    [key: string]: {
      [key in OperatorEnum]?: IFilterOptions }
  } = {};

  filterTypeEnum = FilterTypeEnum;
  filtersApplied: BehaviorSubject<IFilter[]> = new BehaviorSubject(null);
  loading = false;
  columns = 1;
  selectedFilters;

  advancedFiltersTitle: string;
  additionalFiltersTitle: string;
  additionalFiltersTitleIndex: number;

  constructor(private cd: ChangeDetectorRef, private operatorTranslator: OperatorTranslator) { }

  setSelectedFilters(filters: IFilter[]) {
    if (!filters || !this.filters) {
      return;
    }
    this.selectedFilters = filters;

    filters.forEach((selectedFilter) => {
      const filter = this.filters.find(f => f.id === selectedFilter.field);
      if (filter.show === false) {
        return ;
      }
      const operator =  this.filterOperators[selectedFilter.field].find(f => f.id === selectedFilter.operator);
      const options: IFilterOptions = this.getFilterOptions(selectedFilter.field, selectedFilter.operator);

      switch (filter.type) {
        case FilterTypeEnum.INPUT:
        case FilterTypeEnum.INPUT_SINGLE:
          filter.operator = operator;
          filter.value = selectedFilter.value;
          break;

        case FilterTypeEnum.DROPDOWN:
          filter.value = options.options.find(opt => opt.id === selectedFilter.value);
          break;

        case FilterTypeEnum.MULTISELECT:
          filter.value = selectedFilter.value.map(val => options.options.find(opt => opt.id === val));
          filter.multiselectOptions = this.getMultiselectOptions(filter);
          break;

        default:
          if (operator.id === OperatorEnum.GREATER_EQUALS) {
            filter.value = selectedFilter.value;
          } else {
            filter.valueAdditional = selectedFilter.value;
          }
      }
    });
  }

  setAvailableFilters(value: IAvailableFilter[], clear: boolean = true) {
    if (!value) {
      return;
    }

    if (clear) {
      this.clearFilters();
      this.filters = [];
      this.filterOperators = {};
      this.filterOptions = {};
    }

    value.forEach((filterOption: IAvailableFilter) => {
      const filterField = {
        id: filterOption.field,
        label: filterOption.title,
        operator: null,
        value: null,
        valueAdditional: null,
        type: null,
        search: filterOption.search,
        multiselectOptions: [],
        show: filterOption.show
      };

      this.filterOperators[filterOption.field] = Object.keys(filterOption.operators).map((operator: OperatorEnum) => {
        return {
          id: operator.toString(),
          label: this.operatorTranslator.translate(operator),
        };
      });

      for (const [operator, options] of Object.entries(filterOption.operators)) {
        if (!(filterOption.field in this.filterOptions)) {
          this.filterOptions[filterOption.field] = {};
        }

        this.filterOptions[filterOption.field][operator] = options;
      }

      filterField.type = (this.filterOptions[filterOption.field][OperatorEnum.EQUALS] || this.filterOptions[filterOption.field][OperatorEnum.CONTAINS] || this.filterOptions[filterOption.field][Object.keys(this.filterOptions[filterOption.field])[0]]).type;
      this.filters.push(filterField);

      if (filterField.type === FilterTypeEnum.DROPDOWN || filterField.type === FilterTypeEnum.MULTISELECT) {
        this.setFilterOperator(this.filterOperators[filterOption.field].find(filter => filter.id === OperatorEnum.EQUALS || filter.id === OperatorEnum.CONTAINS), filterField);
      }

      if (filterField.type === FilterTypeEnum.MULTISELECT) {
        filterField.multiselectOptions = this.getMultiselectOptions(filterField);
      }

      if (filterField.type === FilterTypeEnum.INPUT_SINGLE) {
        this.setFilterOperator(this.filterOperators[filterOption.field][0], filterField);
      }
    });

    if (this.selectedFilters?.length) {
      this.setSelectedFilters(this.selectedFilters);
    }
  }

  setAdditionalFilters(value: IAvailableFilter[]) {
    this.setAvailableFilters(value, false);
  }

  applyFilters() {
    const selectedFilters = [];
    this.filters.forEach(filter => {
      if (filter.operator && (filter.value === 0 || Boolean(filter.value))) {
        selectedFilters.push(filter);
        return;
      }
      if (filter.type === FilterTypeEnum.DATE || filter.type === FilterTypeEnum.POSITIVE_NUMBER) {
        if (filter.value === 0 || Boolean(filter.value)) {
          selectedFilters.push({
            id: filter.id,
            label: filter.label,
            operator: this.filterOperators[filter.id].find(f => f.id === OperatorEnum.GREATER_EQUALS),
            value: filter.value,
            type: filter.type
          });
        }
        if (filter.valueAdditional) {
          selectedFilters.push({
            id: filter.id,
            label: filter.label,
            operator: this.filterOperators[filter.id].find(f => f.id === OperatorEnum.LESS_EQUALS),
            value: filter.valueAdditional,
            type: filter.type
          });
        }
      }
    });

    this.filtersApplied.next(
      selectedFilters.map(filter => {
      if (filter.value !== 0 && !Boolean(filter.value)) {
        return;
      }
      let value = filter.value;
      let valueAsAString;

      if (Array.isArray(value)) {
        value = filter.value.map((entry: IDropdownOption) => {
          return entry.id;
        });

        valueAsAString = filter.value.map((entry: IDropdownOption) => {
          return entry.label;
        }).join(', ');
      } else if (typeof value === 'object' && 'id' in value && 'label' in value) {
        value = value.id;
        valueAsAString = filter.value.label;
      }

      const tmp: IFilter = {
        field: filter.id.toString(),
        operator: filter.operator.id as OperatorEnum,
        value: value,
        title: filter.label
      };

      if (valueAsAString) {
        tmp.valueAsAString = valueAsAString;
      }

      return tmp;
    }));
    this.modal.close();
  }

  setFilterOperator(option: IDropdownOption, filterField: any) {
    filterField.operator = option;
    filterField.value = null;
  }

  setMultiselectValue(value: { checked: boolean, option: IDropdownOption }, filter: any) {
    if (!filter.value) {
      filter.value = [];
    }

    if (value.checked) {
      filter.value.push(value.option);
    } else {
      filter.value = filter.value.filter(v => v.id !== value.option.id);
    }
    filter.multiselectOptions = this.getMultiselectOptions(filter);
  }

  clearFilters() {
    this.filters.forEach((filter) => {
      if (filter.operator && this.filterOptions[filter.id][filter.operator.id].type === FilterTypeEnum.INPUT) {
        filter.operator = null;
      }
      filter.value = null;
      filter.valueAdditional = null;
      if (filter.type === FilterTypeEnum.MULTISELECT) {
        filter.multiselectOptions = this.getMultiselectOptions(filter);
      }
    });
    this.cd.detectChanges();
  }

  getMultiselectOptions(filter: any) {
    const value = filter.value || [];

    const options = this.filterOptions[filter.id][filter.operator.id].options || [];

    return options.map(function (val: IDropdownOption) {
      return {
        ...val,
        checked: value.find(v => v.id === val.id) !== undefined,
      };
    });
  }

  getFilterOptions(field: string, operator: OperatorEnum) {
    return this.filterOptions[field][operator];
  }

  cancelFilters() {
    this.modal.close();
  }
}
