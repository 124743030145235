import { Injectable } from '@angular/core';
import { IFilter, ISavedFilter } from '@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component';
import { Action } from 'redux';
import { IErrorResponse } from '../base.models';
import { StAction } from '../st-action';
import {
  ICheckPhoneNumberResponse,
  ICustomerListResponse,
  ISavedFiltersListResponse,
  ICustomer,
  TypeListEnum,
  IAddCustomerResponse,
  ISaveFilterResponse,
  ILoadAvailableFiltersResponse,
  IOrderItemCollection,
  ImportSourceEnum
} from './customer-list.models';

@Injectable()
export class CustomerListActions {
  static readonly EVENTS = {
    CHECK_MISSING_PHONE_RATINGS_FINISHED: '[CUSTOMER_LIST]CHECK_MISSING_PHONE_RATINGS_FINISHED',
  };

  static readonly TYPES = {
    LOAD_CUSTOMER_LIST: '[CUSTOMER_LIST]LOAD_CUSTOMER_LIST',
    CUSTOMER_LIST_LOADED: '[CUSTOMER_LIST]CUSTOMER_LIST_LOADED',
    CUSTOMER_LIST_LOADED_ERROR: '[CUSTOMER_LIST]CUSTOMER_LIST_LOADED_ERROR',
    LOAD_CUSTOMER_ORDER_ITEMS: '[CUSTOMER_LIST]LOAD_CUSTOMER_ORDER_ITEMS',
    LOAD_CUSTOMER_ORDER_ITEMS_SUCCESS: '[CUSTOMER_LIST]LOAD_CUSTOMER_ORDER_ITEMS_SUCCESS',
    LOAD_CUSTOMER_ORDER_ITEMS_ERROR: '[CUSTOMER_LIST]LOAD_CUSTOMER_ORDER_ITEMS_ERROR',
    ADD_CUSTOMER: '[CUSTOMER_LIST]ADD_CUSTOMER',
    ADD_CUSTOMER_SUCCESS: '[CUSTOMER_LIST]ADD_CUSTOMER_SUCCESS',
    ADD_CUSTOMER_ERROR: '[CUSTOMER_LIST]ADD_CUSTOMER_ERROR',
    ADD_CUSTOMER_RESET: '[CUSTOMER_LIST]ADD_CUSTOMER_RESET',
    UPDATE_CUSTOMER_RESET: '[CUSTOMER_LIST]UPDATE_CUSTOMER_RESET',
    UPDATE_CUSTOMER: '[CUSTOMER_LIST]UPDATE_CUSTOMER',
    UPDATE_CUSTOMER_SUCCESS: '[CUSTOMER_LIST]UPDATE_CUSTOMER_SUCCESS',
    UPDATE_CUSTOMER_ERROR: '[CUSTOMER_LIST]UPDATE_CUSTOMER_ERROR',
    DELETE_CUSTOMER: '[CUSTOMER_LIST]DELETE_CUSTOMER',
    DELETE_CUSTOMER_SUCCESS: '[CUSTOMER_LIST]DELETE_CUSTOMER_SUCCESS',
    DELETE_CUSTOMER_ERROR: '[CUSTOMER_LIST]DELETE_CUSTOMER_ERROR',
    DELETE_CUSTOMER_RESET: '[CUSTOMER_LIST]DELETE_CUSTOMER_RESET',
    CHECK_PHONE_NUMBER: '[CUSTOMER_LIST]CHECK_PHONE_NUMBER',
    RESET_PHONE_NUMBER: '[CUSTOMER_LIST]RESET_PHONE_NUMBER',
    CHECK_PHONE_NUMBER_SUCCESS: '[CUSTOMER_LIST]CHECK_PHONE_NUMBER_SUCCESS',
    CHECK_PHONE_NUMBER_ERROR: '[CUSTOMER_LIST]CHECK_PHONE_NUMBER_ERROR',
    SET_FILTERS: '[CUSTOMER_LIST]SET_FILTERS',
    SET_LIST: '[CUSTOMER_LIST]SET_LIST',
    SEARCH: '[CUSTOMER_LIST]SEARCH',
    IMPORT_CUSTOMERS: '[CUSTOMER_LIST]IMPORT_CUSTOMERS',
    IMPORT_CUSTOMERS_RESET: '[CUSTOMER_LIST]IMPORT_CUSTOMERS_RESET',
    IMPORT_CUSTOMERS_SUCCESS: '[CUSTOMER_LIST]IMPORT_CUSTOMERS_SUCCESS',
    IMPORT_CUSTOMERS_ERROR: '[CUSTOMER_LIST]IMPORT_CUSTOMERS_ERROR',
    IMPORT_CUSTOMERS_FINISHED: '[CUSTOMER_LIST]IMPORT_CUSTOMERS_FINISHED',
    IMPORT_CUSTOMERS_PROGRESS_CHANGED: '[CUSTOMER_LIST]IMPORT_CUSTOMERS_PROGRESS_CHANGED',
    IMPORT_CUSTOMERS_BLACKLIST_PROGRESS: '[CUSTOMER_LIST]IMPORT_CUSTOMERS_BLACKLIST_PROGRESS',
    IMPORT_CUSTOMERS_WALLET_PROGRESS : '[CUSTOMER_LIST]IMPORT_CUSTOMERS_WALLET_PROGRESS',
    SAVE_FILTERS: '[CUSTOMER_LIST]SAVE_FILTERS',
    SAVE_FILTERS_SUCCESS: '[CUSTOMER_LIST]SAVE_FILTERS_SUCCESS',
    SAVE_FILTERS_ERROR: '[CUSTOMER_LIST]SAVE_FILTERS_ERROR',
    LOAD_SAVED_FILTERS: '[CUSTOMER_LIST]LOAD_SAVED_FILTERS',
    LOAD_SAVED_FILTERS_SUCCESS: '[CUSTOMER_LIST]LOAD_SAVED_FILTERS_SUCCESS',
    LOAD_SAVED_FILTERS_ERROR: '[CUSTOMER_LIST]LOAD_SAVED_FILTERS_ERROR',
    LOAD_AVAILABLE_FILTERS: '[CUSTOMER_LIST]LOAD_AVAILABLE_FILTERS',
    LOAD_AVAILABLE_FILTERS_SUCCESS: '[CUSTOMER_LIST]LOAD_AVAILABLE_FILTERS_SUCCESS',
    LOAD_AVAILABLE_FILTERS_ERROR: '[CUSTOMER_LIST]LOAD_AVAILABLE_FILTERS_ERROR',
    APPLY_VIEW: '[CUSTOMER_LIST]APPLY_VIEW',
    DELETE_VIEW: '[CUSTOMER_LIST]DELETE_VIEW',
    DELETE_VIEW_SUCCESS: '[CUSTOMER_LIST]DELETE_VIEW_SUCCESS',
    DELETE_VIEW_ERROR: '[CUSTOMER_LIST]DELETE_VIEW_ERROR',
    CHECK_MISSING_PHONE_NUMBER_SCORES: '[CUSTOMER_LIST]CHECK_MISSING_PHONE_NUMBER_SCORES',
    CHECK_MISSING_PHONE_NUMBER_SCORES_SUCCESS: '[CUSTOMER_LIST]CHECK_MISSING_PHONE_NUMBER_SCORES_SUCCESS',
    CHECK_MISSING_PHONE_NUMBER_SCORES_ERROR: '[CUSTOMER_LIST]CHECK_MISSING_PHONE_NUMBER_SCORES_ERROR',
    MOVE_CUSTOMERS: '[CUSTOMER_LIST]MOVE_CUSTOMERS',
    MOVE_CUSTOMERS_SUCCESS: '[CUSTOMER_LIST]MOVE_CUSTOMERS_SUCCESS',
    MOVE_CUSTOMERS_ERROR: '[CUSTOMER_LIST]MOVE_CUSTOMERS_ERROR',
    MOVE_CUSTOMERS_RESET: '[CUSTOMER_LIST]MOVE_CUSTOMERS_RESET',
    EXPORT_CUSTOMERS: '[CUSTOMER_LIST]EXPORT_CUSTOMERS',
    EXPORT_CUSTOMERS_SUCCESS: '[CUSTOMER_LIST]EXPORT_CUSTOMERS_SUCCESS',
    EXPORT_CUSTOMERS_ERROR: '[CUSTOMER_LIST]EXPORT_CUSTOMERS_ERROR',
    EXPORT_CUSTOMERS_RESET: '[CUSTOMER_LIST]EXPORT_CUSTOMERS_RESET',
    SET_CREDENTIAL_ID: '[CUSTOMER_LIST]SET_CREDENTIAL_ID',
  }

  resetPhoneNumber(): Action {
    return {
      type: CustomerListActions.TYPES.RESET_PHONE_NUMBER,
    };
  }

  checkPhoneNumber(phoneNumber: string): StAction {
    return {
      type: CustomerListActions.TYPES.CHECK_PHONE_NUMBER,
      data: {
        phoneNumber,
      }
    }
  }

  checkPhoneNumberSuccess(data: ICheckPhoneNumberResponse): StAction {
    return {
      type: CustomerListActions.TYPES.CHECK_PHONE_NUMBER_SUCCESS,
      data,
    }
  }

  checkPhoneNumberError(message: string): StAction {
    return {
      type: CustomerListActions.TYPES.CHECK_PHONE_NUMBER_ERROR,
      data: {
        message,
      },
    }
  }

  loadCustomerList(currentPage: number = null, perPage: number = null, sort: string = null): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_CUSTOMER_LIST,
      data: {
        currentPage,
        perPage,
        sort
      }
    };
  }

  loadCustomerOrderItems(customerId: string): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_CUSTOMER_ORDER_ITEMS,
      data: {
        customerId,
      }
    };
  }

  loadCustomerOrderItemsSuccess(customerId: string, orderItems: IOrderItemCollection): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_CUSTOMER_ORDER_ITEMS_SUCCESS,
      data: {
        customerId,
        orderItems
      }
    };
  }

  loadCustomerOrderItemsError(customerId: string): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_CUSTOMER_ORDER_ITEMS_ERROR,
      data: {
        customerId,
      }
    };
  }

  customerListLoaded(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.CUSTOMER_LIST_LOADED,
      data: data
    };
  }

  customerListLoadedError(data: ICustomerListResponse): StAction {
    return {
      type: CustomerListActions.TYPES.CUSTOMER_LIST_LOADED_ERROR,
      data: data
    };
  }

  addCustomerReset(): StAction {
    return {
      type: CustomerListActions.TYPES.ADD_CUSTOMER_RESET,
      data: null
    };
  }

  addCustomer(payload: ICustomer): StAction {
    return {
      type: CustomerListActions.TYPES.ADD_CUSTOMER,
      data: payload
    };
  }

  addCustomerSuccess(data: IAddCustomerResponse): StAction {
    return {
      type: CustomerListActions.TYPES.ADD_CUSTOMER_SUCCESS,
      data
    };
  }

  addCustomerError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.ADD_CUSTOMER_ERROR,
      data,
    };
  }

  updateCustomerReset(): StAction {
    return {
      type: CustomerListActions.TYPES.UPDATE_CUSTOMER_RESET,
      data: null
    };
  }

  updateCustomer(customerId: string, payload: ICustomer): StAction {
    return {
      type: CustomerListActions.TYPES.UPDATE_CUSTOMER,
      data: {
        payload,
        customerId
      },
    };
  }

  updateCustomerSuccess(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.UPDATE_CUSTOMER_SUCCESS,
      data
    };
  }

  updateCustomerError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.UPDATE_CUSTOMER_ERROR,
      data,
    };
  }

  deleteCustomerReset(): StAction {
    return {
      type: CustomerListActions.TYPES.DELETE_CUSTOMER_RESET,
      data: null
    };
  }

  deleteCustomer(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.DELETE_CUSTOMER,
      data
    };
  }

  deleteCustomerSuccess(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.DELETE_CUSTOMER_SUCCESS,
      data
    };
  }

  deleteCustomerError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.DELETE_CUSTOMER_ERROR,
      data,
    };
  }
  setFilters(filters: IFilter[]): StAction {
    return {
      type: CustomerListActions.TYPES.SET_FILTERS,
      data: {
        filters
      }
    };
  }

  setList(list: TypeListEnum): StAction {
    return {
      type: CustomerListActions.TYPES.SET_LIST,
      data: {
        list
      }
    };
  }

  search(searchTerm: string): StAction {
    return {
      type: CustomerListActions.TYPES.SEARCH,
      data: {
        searchTerm
      }
    };
  }


  importCustomers(source: ImportSourceEnum, customers?: string[], apiKey?: string, properties?: string[]): StAction {
    return {
      type: CustomerListActions.TYPES.IMPORT_CUSTOMERS,
      data: {
        source,
        customers,
        apiKey,
        properties
      },
    };
  }

  resetImportCustomers(): StAction {
    return {
      type: CustomerListActions.TYPES.IMPORT_CUSTOMERS_RESET,
      data: null
    };
  }

  importCustomersSuccess(id: string): StAction {
    return {
      type: CustomerListActions.TYPES.IMPORT_CUSTOMERS_SUCCESS,
      data: {
        id
      }
    };
  }

  importCustomersError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.IMPORT_CUSTOMERS_ERROR,
      data
    };
  }

  saveFilters(filters: ISavedFilter): StAction {
    return {
      type: CustomerListActions.TYPES.SAVE_FILTERS,
      data: filters
    };
  }

  saveFiltersSuccess(data: ISaveFilterResponse): StAction {
    return {
      type: CustomerListActions.TYPES.SAVE_FILTERS_SUCCESS,
      data: data
    };
  }

  saveFiltersError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.SAVE_FILTERS_ERROR,
      data
    };
  }

  moveCustomersReset(): StAction {
    return {
      type: CustomerListActions.TYPES.MOVE_CUSTOMERS_RESET,
      data: null
    };
  }

  moveCustomers(customersID: string[], listType: string): StAction {
    return {
      type: CustomerListActions.TYPES.MOVE_CUSTOMERS,
      data: {customersID, listType}
    };
  }

  moveCustomersSuccess(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.MOVE_CUSTOMERS_SUCCESS,
      data
    };
  }

  moveCustomersError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.MOVE_CUSTOMERS_ERROR,
      data,
    };
  }

  exportCustomers(customersID: string[]): StAction {
    return {
      type: CustomerListActions.TYPES.EXPORT_CUSTOMERS,
      data: customersID
    };
  }

  exportCustomersSuccess(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.EXPORT_CUSTOMERS_SUCCESS,
      data
    };
  }

  exportCustomersError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.EXPORT_CUSTOMERS_ERROR,
      data,
    };
  }

  exportCustomersReset(): StAction {
    return {
      type: CustomerListActions.TYPES.EXPORT_CUSTOMERS_RESET,
      data: null
    };
  }

  loadSavedFilters(): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_SAVED_FILTERS,
      data: null
    };
  }

  loadSavedFiltersSuccess(data: ISavedFiltersListResponse): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_SAVED_FILTERS_SUCCESS,
      data
    };
  }
  loadSavedFiltersError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_SAVED_FILTERS_ERROR,
      data
    };
  }

  loadAvailableFilters(): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_AVAILABLE_FILTERS,
      data: null
    };
  }

  loadAvailableFiltersSuccess(data: ILoadAvailableFiltersResponse): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_AVAILABLE_FILTERS_SUCCESS,
      data
    };
  }

  loadAvailableFiltersError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.LOAD_AVAILABLE_FILTERS_ERROR,
      data
    };
  }

  applyView(data: null|ISavedFilter): StAction {
    return {
      type: CustomerListActions.TYPES.APPLY_VIEW,
      data
    };
  }

  deleteView(data: ISavedFilter): StAction {
    return {
      type: CustomerListActions.TYPES.DELETE_VIEW,
      data
    };
  }

  deleteViewSuccess(): StAction {
    return {
      type: CustomerListActions.TYPES.DELETE_VIEW_SUCCESS,
      data: null
    };
  }

  deleteViewError(data: any): StAction {
    return {
      type: CustomerListActions.TYPES.DELETE_VIEW_ERROR,
      data
    };
  }

  checkMissingPhoneNumberScores(): StAction {
    return {
      type: CustomerListActions.TYPES.CHECK_MISSING_PHONE_NUMBER_SCORES,
      data: null
    };
  }

  checkMissingPhoneNumberScoresSuccess(): StAction {
    return {
      type: CustomerListActions.TYPES.CHECK_MISSING_PHONE_NUMBER_SCORES_SUCCESS,
      data: null
    };
  }

  checkMissingPhoneNumberScoresError(data: IErrorResponse): StAction {
    return {
      type: CustomerListActions.TYPES.CHECK_MISSING_PHONE_NUMBER_SCORES_ERROR,
      data
    }
  }

  setCredentialId(credentialId: null|number): StAction {
    return {
      type: CustomerListActions.TYPES.SET_CREDENTIAL_ID,
      data: {
        credentialId
      }
    };
  }
}
