import { StAction } from "@app/shared/data/st-action";


export abstract class PaginationActions {
  static getPagActionTypes(model:{className:string}) {
    const name = model.className.toUpperCase();
    return  {
      LOAD_PAGE: '['+name+']PAG_LOAD_PAGE',
      SET_PAGE: '['+name+']PAG_SET_PAGE',
      LOAD_PAGE_FAILED: '['+name+']PAG_LOAD_PAGE_FAILED',
      INIT_DATA: '['+name+']PAG_INIT_DATA',
      SET_DATA: '['+name+']PAG_SET_DATA',
      APPEND_DATA: '['+name+']PAG_APPEND_DATA'
    }
  }

  protected abstract model: {className:string};

  /**
   * @returns {{type: string}}
   */
  initData(context = null): StAction {
    return {
      type: PaginationActions.getPagActionTypes(this.model).INIT_DATA,
      data: {
        context
      }
    };
  }

  /**
   * @returns {{type: string}}
   */
  setData(results): StAction {
    return {
      type: PaginationActions.getPagActionTypes(this.model).SET_DATA,
      data: results
    };
  }

  /**
   * @returns {{type: string}}
   */
  appendData(results): StAction {
    return {
      type: PaginationActions.getPagActionTypes(this.model).APPEND_DATA,
      data: results
    };
  }

  /**
   * @returns {{type: string}}
   */
  loadPage(page:number, perPage:number = 50, sort:string[] = [], context = null): StAction {
    return {
      type: PaginationActions.getPagActionTypes(this.model).LOAD_PAGE,
      data: {
        pagination: {
          page,
          perPage,
          sort
        },
        context
      }
    };
  }

  /**
   * @returns {{type: string}}
   */
  loadPageFailed(error): StAction {
    return {
      type: PaginationActions.getPagActionTypes(this.model).LOAD_PAGE_FAILED,
      data: error
    };
  }

  /**
   * @returns {{type: string}}
   */
  setPage(results): StAction {
    return {
      type: PaginationActions.getPagActionTypes(this.model).SET_PAGE,
      data: results
    };
  }
}
