import { NgRedux } from '@angular-redux/store';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ValidatorService } from '@app/core/services/validator.service';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { AppState } from '@app/shared/data/app-state.model';
import { CampaignManagerActions } from '@app/shared/data/campaign-manager/campaign-manager.actions';
import { CampaignStatusEnum, ICampaignManagerState } from '@app/shared/data/campaign-manager/campaign-manager.models';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { environment } from '@env/environment';
import { I18n } from '@ngx-translate/i18n-polyfill';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

export function stripCampaignName(campaignName: string): string {
  if (!campaignName) {
    return;
  }

  return campaignName.replace(/[^a-zA-Z0-9]/g, ' ').replace(/\s+/g, '-').toLowerCase();
}

@Component({
  styleUrls: ['./campaign-manager-setup-product-selection-campaign-name.component.scss'],
  templateUrl: './campaign-manager-setup-product-selection-campaign-name.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CampaignManagerSetupProductSelectionCampaignNameComponent implements OnInit, OnDestroy {
  productName: string;
  campaignName: string;
  emailAddress: string;
  emailAddressControl = new FormControl('', [ValidatorService.Required, ValidatorService.EmailValidator]);
  campaignNameControl = new FormControl('', [ValidatorService.Required]);
  urlDestination: string;
  checkingCampaignName: boolean;
  campaignNameError: boolean;
  campaignNameChecked: boolean = false;
  selectedProduct: any;
  campaignManagerNameConfirmed: boolean;
  campaignIsActive: boolean = false;

  productShortNameMaxLength = 64;

  private urlDestinationPrefix: string = environment.CHAT_BASE_URL;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private modalService: ModalService,
    private campaignManagerActions: CampaignManagerActions,
    private ngRedux: NgRedux<AppState>,
    private cd: ChangeDetectorRef,
    private i18n: I18n,
    private rudderTracking: RudderTrackingService
  ) { }

  ngOnInit() {
    this.ngRedux.select('campaign_manager')
      .pipe(takeUntil(this.destroy$))
      .subscribe((campaignManagerState: ICampaignManagerState) => {
        if (campaignManagerState.campaignManagerState.product) {
          this.productName = campaignManagerState.campaignManagerState.product.short_name;
        }

        this.campaignManagerNameConfirmed = campaignManagerState.campaignManagerCampaignNameConfirmed;
        this.campaignName = campaignManagerState.campaignManagerState.name;
        this.checkingCampaignName = campaignManagerState.checkingCampaignName;
        this.campaignNameError = campaignManagerState.campaignNameError;
        this.selectedProduct = campaignManagerState.campaignManagerState.product;
        this.emailAddress = campaignManagerState.campaignManagerState.contact_email;
        this.campaignIsActive = campaignManagerState.campaignManagerState.status === CampaignStatusEnum.ACTIVE;

        if (campaignManagerState.campaignManagerState.contact_email || this.emailAddressControl.valid) {
          this.emailAddressControl.setValue(campaignManagerState.campaignManagerState.contact_email);
        }

        if (campaignManagerState.campaignManagerState.name || this.campaignNameControl.valid) {
          this.campaignNameControl.setValue(campaignManagerState.campaignManagerState.name);

          if (this.campaignManagerNameConfirmed) {
            this.campaignNameControl.disable();
          }
        }

        this.cd.detectChanges();
      });

    this.campaignNameControl.valueChanges.pipe(
      debounceTime(300),
      map((v) => this.campaignNameControl.valid ? v : null),
      filter(v => (v !== this.campaignName) && !this.campaignManagerNameConfirmed),
      distinctUntilChanged(),
      ).subscribe((v) => {
        if (this.campaignIsActive) {
          return;
        }

        this.ngRedux.dispatch(this.campaignManagerActions.setCampaignRewardCampaignName(v));
    
        this.campaignNameChecked = true;
        this.ngRedux.dispatch(this.campaignManagerActions.setCampaignCheckSlug(stripCampaignName(v)));

        this.rudderTracking.trackEvent('CM - Product Selection & Campaign Name Action', {
          action: "Change campaign name"
        });
      });

    this.emailAddressControl.valueChanges.pipe(
      debounceTime(300),
      map((v) => this.emailAddressControl.valid ? v : null),
      filter(v => v !== this.emailAddress),
      distinctUntilChanged(),
      ).subscribe(
        v => {
          this.ngRedux.dispatch(this.campaignManagerActions.setCampaignContactEmail(v));
          this.rudderTracking.trackEvent('CM - Product Selection & Campaign Name Action', {
            action: "Change support email"
          });
        }
      );

      this.rudderTracking.trackEvent('CM - Campaign Setup - Product Selection Page Loaded');
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  browseProducts() {
   if (this.selectedProduct) {
      this.rudderTracking.trackEvent('CM - Product Selection & Campaign Name Action', {
        action: "Change selected product"
      });
    }
  }

  updateProductName() {
    if (this.productName && this.productName.length > 64) {
      return;
    }

    this.ngRedux.dispatch(this.campaignManagerActions.setCampaignRewardCampaignProductShortName(this.productName));
    this.rudderTracking.trackEvent('CM - Product Selection & Campaign Name Action', {
      action: "Change short product name"
    });
  }

  updateUrlDestination() {
    this.ngRedux.dispatch(this.campaignManagerActions.setCampaignRewardCampaignUrlDestination(this.urlDestination));
  }


  get urlDestinationSlug(): string {
    if (this.campaignName) {
      return `${this.urlDestinationPrefix}/${stripCampaignName(this.campaignName)}`;
    }

    return this.i18n('The link will appear here.');
  }
}
