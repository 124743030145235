import { environment } from '@env/environment';

export const AMAZON_DOMAIN_US = 'amazon.com';
export const AMAZON_DOMAIN_CA = 'amazon.ca';
export const AMAZON_DOMAIN_UK = 'amazon.co.uk';
export const AMAZON_DOMAIN_DE = 'amazon.de';
export const AMAZON_DOMAIN_ES = 'amazon.es';
export const AMAZON_DOMAIN_FR = 'amazon.fr';
export const AMAZON_DOMAIN_IT = 'amazon.it';

export interface IMarketplace {
  id: number;
  name: string;
  country: string;
  currency: string;
  domain: string;
  region: string;
  kwgen: boolean;
  apiUrl: string;
}

export const marketplaces: IMarketplace[] = [
  {
    id    : 1,
    name  : 'Amazon US',
    country  : 'United States',
    currency  : 'USD',
    domain: AMAZON_DOMAIN_US,
    region: 'na',
    kwgen: true,
    apiUrl: 'https://sellercentral.amazon.com',
  },
  {
    id    : 2,
    name  : 'Amazon CA',
    country  : 'Canada',
    currency  : 'CAD',
    domain: AMAZON_DOMAIN_CA,
    region: 'na',
    kwgen: false,
    apiUrl: 'https://sellercentral.amazon.com',
  },
  {
    id    : 4,
    name  : 'Amazon UK',
    country  : 'United Kingdom',
    currency  : 'GBP',
    domain: AMAZON_DOMAIN_UK,
    region: 'eu',
    kwgen: !environment.production,
    apiUrl: 'https://sellercentral-europe.amazon.com',
  },
  {
    id    : 5,
    name  : 'Amazon DE',
    country  : 'Germany',
    currency  : 'EUR',
    domain: AMAZON_DOMAIN_DE,
    region: 'eu',
    kwgen: !environment.production,
    apiUrl: 'https://sellercentral-europe.amazon.com',
  },
  {
    id    : 6,
    name  : 'Amazon ES',
    country  : 'Spain',
    currency  : 'EUR',
    domain: AMAZON_DOMAIN_ES,
    region: 'eu',
    kwgen: false,
    apiUrl: 'https://sellercentral-europe.amazon.com',
  },
  {
    id    : 7,
    name  : 'Amazon FR',
    country  : 'France',
    currency  : 'EUR',
    domain: AMAZON_DOMAIN_FR,
    region: 'eu',
    kwgen: false,
    apiUrl: 'https://sellercentral-europe.amazon.com',
  },
  {
    id    : 8,
    name  : 'Amazon IT',
    country  : 'Italy',
    currency  : 'EUR',
    domain: AMAZON_DOMAIN_IT,
    region: 'eu',
    kwgen: !environment.production,
    apiUrl: 'https://sellercentral-europe.amazon.com',
  }
];

export function generateRedirectUrl(marketplaceId: string|number, state: string): string {
  const marketPlace: IMarketplace = marketplaces.find((marketplace) => marketplace.id === marketplaceId)
  let version = '';

  // Add this to non-testing environments
  if (!environment.production) {
    version = '&version=beta';
  }

  return `${marketPlace.apiUrl as string}/apps/authorize/consent?application_id=${environment.SELLER_CENTRAL_APP_ID}&state=${state}${version}`;
}
