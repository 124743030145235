import { StAction } from '../st-action';
import { ProductManagerActions } from './product-manager.actions';
import {
  IAddedCompetitorProduct,
  IProductManagerGraphDataValues,
  IProductManagerKeyword,
  IProductManagerKeywordGraphData,
  IProductManagerPriorityChange,
  IProductManagerProduct,
  IProductManagerState,
  IProductManagerVariation,
} from './product-manager.models';
import { createSelector } from 'reselect';

const NOT_RANKED = 999;
const INITIAL_STATE: IProductManagerState = {
  activeProduct: null,
  loadingSingleProduct: false,
  syncingProductsWithAmazon: true,
  productsState: {
    products: [],
    otherProducts: [],
    current_page: null,
    path: null,
    per_page: null,
    to: 0,
    from: 0,
    total: 0,
    foreign_total: 0,
    last_page: null,
    closeAddProductsModal: false,
  },
  productVariationsState: {
    products: [],
    current_page: null,
    path: null,
    per_page: null,
    to: 0,
    from: 0,
    total: 0,
    last_page: null,
  },
  graphData: {
    dataPoints: null,
  },
  listingManager: {
    allData: null,
    draft: null,
    listings: null,
  },
  competitorsList: {
    customerProducts: null,
    loadingCustomerProducts: false,
    customerProductsError: null
  },
  keywords: {
    phrases: null,
    words: null,
    progress: 0,
    sfrGraphData: null,
  },
  keywordGraph: {
    rank: null,
    impressions: null,
  },
  loadingProducts: false,
  loadingProductVariations: false,
  loadingGraphData: false,
  loadingDraftsAndListings: false,
  loadingKeywords: false,
  loadingKeywordsWS: false,
  loadingKeywordsGraph: false,
  addingCompetitorsProducts: false,
  removingCompetitorsProducts: false,
  updatingKeywordsPriority: false,
  settingProductStatus: false,
  setProductStatusError: false,
  settingProductTags: false,
  setProductTagsError: false,
  removingProductTags: false,
  removingProductTagsError: false,
  removingKeyword: false,
  upgradePlanBannerVisible: false,
  upgradePlanBannerTitle: null,
  upgradePlanBannerMessage: null,
  loadingAvailableTagFilters: false,
  availableTagFilters: null,
  deletingProduct: false,
  deletingProductError: null,
  deletingProductSuccess: false,
};

export const keywordSortMapper = {
  h: 1,
  m: 2,
  l: 3,
  vl: 4
};

export function productManagerReducer(state: IProductManagerState = INITIAL_STATE, action: StAction): IProductManagerState {
  switch (action.type) {
    case ProductManagerActions.TYPES.GET_PRODUCTS:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          products: state.productsState.products.map((product: IProductManagerProduct) => ({...product, detailsOpened: false})),
          otherProducts: state.productsState.otherProducts.map((product: IProductManagerProduct) => ({...product, detailsOpened: false})),
        },
        loadingProducts: true,
      };

    case ProductManagerActions.TYPES.GET_PRODUCTS_SUCCESS:
      const productsKey = action.data.isForeignFetch ?
        'otherProducts' : 'products';

      const totalKey = action.data.isForeignFetch ?
        'foreign_total' : 'total';

      return {
        ...state,
        productsState: {
          ...state.productsState,
          [productsKey]: action.data.data,
          current_page: action.data.meta.current_page,
          from: action.data.meta.from,
          per_page: action.data.meta.per_page,
          path: action.data.meta.path,
          [totalKey]: action.data.meta.total,
          to: action.data.meta.to,
          last_page: action.data.meta.last_page,
        },
        syncingProductsWithAmazon: action.data.meta.current_mws_sync_start_at !== null,
        loadingProducts: false,
      };

    case ProductManagerActions.TYPES.RESYNC_PRODUCTS:
      return {
        ...state,
        syncingProductsWithAmazon: true,
      };

    case ProductManagerActions.TYPES.RESYNC_PRODUCTS_COMPLETE:
      return {
        ...state,
        syncingProductsWithAmazon: INITIAL_STATE.syncingProductsWithAmazon,
      };

    case ProductManagerActions.TYPES.GET_PRODUCTS_ERROR:
      return {
        ...state,
        loadingProducts: false,
      };

    case ProductManagerActions.TYPES.GET_VARIATIONS:
      return {
        ...state,
        loadingProductVariations: true,
      };

    case ProductManagerActions.TYPES.TOGGLE_PRODUCT:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          products: (state.productsState.products || []).map((product: IProductManagerProduct) => {
            return {
              ...product,
              detailsOpened: action.data.asin === product.asin ? !product.detailsOpened : false,
              checked: action.data.asin === product.asin ? !product.checked : false,
            };
          }),
        }
      };

    case ProductManagerActions.TYPES.TOGGLE_VARIATION_CHECK:
      return {
        ...state,
        productVariationsState: {
          ...state.productVariationsState,
          products: (state.productVariationsState.products || []).map((product: IProductManagerVariation) => {
            return {
              ...product,
              checked: action.data === product.asin ? !product.checked : product.checked,
            };
          }),
        }
      };

    case ProductManagerActions.TYPES.UNCHECK_ALL_PRODUCTS:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          products: (state.productsState.products || []).map((product: IProductManagerProduct) => {
            return {
              ...product,
              checked: false,
            };
          }),
        }
      };

    case ProductManagerActions.TYPES.SET_PRODUCT_STATUS:
      return {
        ...state,
        settingProductStatus: true,
        setProductStatusError: false
      };

    case ProductManagerActions.TYPES.SET_PRODUCT_STATUS_SUCCESS:
      return {
        ...state,
        settingProductStatus: false,
      };

    case ProductManagerActions.TYPES.SET_PRODUCT_STATUS_ERROR:
      return {
        ...state,
        settingProductStatus: false,
        setProductStatusError: true
      };

    case ProductManagerActions.TYPES.SET_PRODUCT_TAGS:
      return {
        ...state,
        settingProductTags: true,
        setProductTagsError: false
      };

    case ProductManagerActions.TYPES.SET_PRODUCT_TAGS_SUCCESS:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          products: (state.productsState.products || []).map((product: IProductManagerProduct) => {
            return {
              ...product,
              tags: action.data.asin === product.asin ? action.data.tags : product.tags,
            };
          }),
        },
        settingProductTags: false,
      };

    case ProductManagerActions.TYPES.SET_PRODUCT_TAGS_ERROR:
      return {
        ...state,
        settingProductTags: false,
        setProductTagsError: true
      };

    case ProductManagerActions.TYPES.REMOVE_PRODUCT_TAGS:
      return {
        ...state,
        removingProductTags: true,
        removingProductTagsError: false
      };

    case ProductManagerActions.TYPES.REMOVE_PRODUCT_TAGS_SUCCESS:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          products: (state.productsState.products || []).map((product: IProductManagerProduct) => {
            return {
              ...product,
              tags: action.data.asin === product.asin ? action.data.tags : product.tags,
            };
          }),
        },
        removingProductTags: false,
      };

    case ProductManagerActions.TYPES.REMOVE_PRODUCT_TAGS_ERROR:
      return {
        ...state,
        removingProductTags: false,
        removingProductTagsError: true
      };

    case ProductManagerActions.TYPES.SET_ACTIVE_TAB:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          products: (state.productsState.products || []).map((product: IProductManagerProduct) => {
            let activeTab = null;

            if (action.data.asin === product.asin) {
              activeTab = action.data.tab;
            }

            return {
              ...product,
              activeTab,
            };
          })
        }
      };

    case ProductManagerActions.TYPES.GET_VARIATIONS_SUCCESS:
      return {
        ...state,
        loadingProductVariations: false,
        productVariationsState: {
          ...state.productVariationsState,
          products: action.data.data.map((product: IProductManagerVariation) => ({...product, checked: true})),
          current_page: action.data.meta.current_page,
          from: action.data.meta.from,
          per_page: action.data.meta.per_page,
          path: action.data.meta.path,
          total: action.data.meta.total,
          to: action.data.meta.to,
          last_page: action.data.meta.last_page,
        },
      };

    case ProductManagerActions.TYPES.GET_GRAPH:
      return {
        ...state,
        loadingGraphData: true,
      };

    case ProductManagerActions.TYPES.GET_GRAPH_SUCCESS:
      // We only display BSR at the moment
      const data = action.data.data ? action.data.data[0] : null;

      return {
        ...state,
        loadingGraphData: false,
        graphData: {
          dataPoints: data
            ? data.values
                  .map((dataPoint: IProductManagerGraphDataValues) => {
                    return [Date.parse(dataPoint.date_time), dataPoint.value];
                  })
              // filter out not properly formatted date values
                  .filter((datapoint: number[]) => !isNaN(datapoint[0]))
            : [],
        }
      };

    case ProductManagerActions.TYPES.GET_GRAPH_ERROR:
      return {
        ...state,
        loadingGraphData: false
      };

    case ProductManagerActions.TYPES.ADD_COMPETITOR_PRODUCT:
      return {
        ...state,
        addingCompetitorsProducts: true,
      };

    case ProductManagerActions.TYPES.ADD_COMPETITOR_PRODUCT_SUCCESS:
      let newProducts = [];

      action.data
        // to add only newly added products to the list, we need to filter out products with error
            .filter((productVariation: { data: IAddedCompetitorProduct }) => !productVariation.data.error)
            .forEach((productVariation: { data: IAddedCompetitorProduct }) => {
              newProducts = [
                ...newProducts,
                productVariation.data,
              ];
            });

      return {
        ...state,
        addingCompetitorsProducts: false,
        competitorsList: {
          ...state.competitorsList,
          customerProducts: [
            ...(state.competitorsList.customerProducts || []),
            ...newProducts,
          ],
        }
      };

    case ProductManagerActions.TYPES.ADD_COMPETITOR_PRODUCT_ERROR:
      return {
        ...state,
        addingCompetitorsProducts: false,
        competitorsList: {
          ...state.competitorsList,
        }
      };

    case ProductManagerActions.TYPES.REMOVE_COMPETITOR_PRODUCT:
      return {
        ...state,
        removingCompetitorsProducts: true,
      };

    case ProductManagerActions.TYPES.REMOVE_COMPETITOR_PRODUCT_SUCCESS:
      return {
        ...state,
        removingCompetitorsProducts: false,
        competitorsList: {
          ...state.competitorsList,
          customerProducts: state.competitorsList.customerProducts
                                 .filter((product: IProductManagerVariation) => !action.data.asins.includes(product.asin)),
        }
      };

    case ProductManagerActions.TYPES.REMOVE_COMPETITOR_PRODUCT_ERROR:
      return {
        ...state,
        removingCompetitorsProducts: false,
      };

    case ProductManagerActions.TYPES.GET_COMPETITION_PRODUCTS:
      return {
        ...state,
        competitorsList: {
          ...state.competitorsList,
          loadingCustomerProducts: true,
        },
      };

    case ProductManagerActions.TYPES.GET_COMPETITION_PRODUCTS_SUCCESS:
      return {
        ...state,
        competitorsList: {
          ...state.competitorsList,
          loadingCustomerProducts: false,
          customerProducts: action.data.data,
        },
      };

    case ProductManagerActions.TYPES.GET_COMPETITION_PRODUCTS_ERROR:
      return {
        ...state,
        competitorsList: {
          ...state.competitorsList,
          loadingCustomerProducts: false,
        },
      };

    case ProductManagerActions.TYPES.GET_PRODUCT:
      return {
        ...state,
        loadingSingleProduct: true,
      };

    case ProductManagerActions.TYPES.SET_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: action.data.product.data || action.data.product,
        loadingSingleProduct: false,
      };

    case ProductManagerActions.TYPES.CLEAR_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: null,
        loadingSingleProduct: false,
      };

    case ProductManagerActions.TYPES.RESET_STATE:
      return INITIAL_STATE;

    case ProductManagerActions.TYPES.RESET_ACIVE_PRODUCT:
      return {
        ...state,
        activeProduct: null,
      };

    case ProductManagerActions.TYPES.GET_DRAFT_AND_LISTINGS:
      return {
        ...state,
        loadingDraftsAndListings: true,
      };

    case ProductManagerActions.TYPES.GET_DRAFT_AND_LISTINGS_SUCCESS:
      const productData = action.data.data.find((product) => product.asin === action.data.asin);

      return {
        ...state,
        loadingDraftsAndListings: false,
        listingManager: {
          ...state.listingManager,
          allData: action.data.data,
          draft: productData ? productData.draft : null,
          listings: productData ? productData.listings : null,
        },
      };

    case ProductManagerActions.TYPES.SET_CURRENT_DRAFT_VERSION:
      const newProduct = state.listingManager.allData.find((product) => product.asin === action.data.asin);
      let draft = state.listingManager.draft;
      let listings = state.listingManager.listings;

      // If no draft is found in the store, select the draft from parent product
      if (newProduct) {
        if (newProduct.draft) {
          draft = newProduct.draft;
        }

        if (listings && listings.length > 0) {
          listings = newProduct.listings;
        }
      }

      return {
        ...state,
        listingManager: {
          ...state.listingManager,
          draft,
          listings,
        },
      };

    case ProductManagerActions.TYPES.GET_DRAFT_AND_LISTINGS_ERROR:
      return {
        ...state,
        loadingDraftsAndListings: false,
      };

    case ProductManagerActions.TYPES.SAVE_DRAFT:
      return {
        ...state,
        loadingDraftsAndListings: true,
      };

    case ProductManagerActions.TYPES.SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        loadingDraftsAndListings: false,
        listingManager: {
          ...state.listingManager,
          draft: action.data,
        },
      };

    case ProductManagerActions.TYPES.SAVE_DRAFT_ERROR:
      return {
        ...state,
        loadingDraftsAndListings: false,
      };

    case ProductManagerActions.TYPES.PUBLISH_DRAFT:
      return {
        ...state,
        loadingDraftsAndListings: true,
      };

    case ProductManagerActions.TYPES.PUBLISH_DRAFT_SUCCESS:
      return {
        ...state,
        loadingDraftsAndListings: false,
        listingManager: {
          ...state.listingManager,
          draft: action.data,
          listings: [
            {
              ...action.data,
              created_at: new Date(),
            },
            ...state.listingManager.listings || [],
          ]
        },
      };

    case ProductManagerActions.TYPES.PUBLISH_DRAFT_ERROR:
      return {
        ...state,
        loadingDraftsAndListings: false,
      };

    case ProductManagerActions.TYPES.GET_KEYWORDS:
      return {
        ...state,
        loadingKeywords: true,
      };

    case ProductManagerActions.TYPES.GET_KEYWORDS_SUCCESS:
      const keywords = sortKeywords(action.data.data);

      return {
        ...state,
        loadingKeywords: false,
        keywords: {
          ...state.keywords,
          phrases: keywords,
          words: parseWordsFromKeywords(keywords),
        }
      };

    case ProductManagerActions.TYPES.GET_KEYWORDS_HYDRATION_FINISHED:
      return {
        ...state,
        loadingKeywordsWS: false,
        keywords: {
          ...state.keywords,
          progress: 100,
        },
      };

    case ProductManagerActions.TYPES.GET_KEYWORDS_ERROR:
      return {
        ...state,
        loadingKeywords: false,
      };

    case ProductManagerActions.TYPES.UPDATE_KEYWORDS:
      return {
        ...state,
        updatingKeywordsPriority: true,
        keywords: {
          ...state.keywords,
          phrases: state.keywords.phrases.map(k => ({
            ...k,
            priority: action.data.keywords.findIndex(o => o.keyword === k.value) > -1 ? action.data.keywords[0].priority : k.priority,
          })),
        },
      };

    case ProductManagerActions.TYPES.TOGGLE_ALL_TABLE_ITEMS:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          phrases: state.keywords.phrases.map(p => ({
            ...p,
            checked: action.data,
          })),
        }
      };

    case ProductManagerActions.TYPES.TOGGLE_SINGLE_TABLE_ITEM:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          phrases: state.keywords.phrases.map(p => (p.value === action.data.value ? { ...p, checked: action.data.toggle } : p))
        }
      };

    case ProductManagerActions.TYPES.UPDATE_KEYWORDS_SUCCESS:
      return {
        ...state,
        updatingKeywordsPriority: false,
      };

    case ProductManagerActions.TYPES.UPDATE_KEYWORDS_ERROR:
      return {
        ...state,
        updatingKeywordsPriority: false,
      };

    case ProductManagerActions.TYPES.ADD_KEYWORDS:
      return {
        ...state,
        loadingKeywords: true,
      };

    case ProductManagerActions.TYPES.ADD_KEYWORDS_ERROR:
      return {
        ...state,
        loadingKeywords: false,
      };

    case ProductManagerActions._events.reset_products_success:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          products: state.productsState.products.map((product: IProductManagerProduct) => {
            return {
              ...product,
              keywords_count: product.asin === action.data.asin ? action.data.product.keywords.count : product.keywords_count,
              resettingProduct: product.asin === action.data.asin ? false : product.resettingProduct,
            };
          }),
        },
      };

    case ProductManagerActions.TYPES.ANALYZE_KEYWORDS:
      return {
        ...state,
        loadingKeywordsWS: true,
        keywords: {
          ...state.keywords,
          progress: 10,
        }
      };

    case ProductManagerActions._events.competition_research_keyword_search_finished:
      if (!state.activeProduct || state.activeProduct.asin !== action.data.asin) {
        return state;
      }

      // Check if keyword search failed
      if (!!action.data.error && action.data.error.code !== undefined) {
        return {
          ...state,
          loadingKeywordsWS: false,
          keywords: {
            ...state.keywords,
            progress: 100,
          },
        };
      }

      return {
        ...state,
        keywords: {
          ...state.keywords,
          progress: 33,
          phrases: action.data.keywords.map((phrase: string) => {
            return {
              value: phrase,
              priority: 'VL'
            };
          }),
        }
      };

    case ProductManagerActions._events.keyword_manager_priority_changes:

      if (!state.activeProduct || state.activeProduct.asin !== action.data.asin) {
        return state;
      }

      return {
        ...state,
        keywords: {
          ...state.keywords,
          progress: 66,
          phrases: (state.keywords.phrases || []).map((keyword: IProductManagerKeyword) => {
            const updatedKeyword: IProductManagerPriorityChange =
              action.data.changes
                    .find((newKeyword: IProductManagerPriorityChange) => keyword.value === newKeyword.value);

            if (updatedKeyword) {
              keyword = {
                ...keyword,
                priority: updatedKeyword.newPriority || 'VL'
              };
            }

            return {
              ...keyword,
            };
          }),
        },
      };

    case ProductManagerActions.TYPES.BULK_COPY_KEYWORDS:
      return {
        ...state,
      };

    case ProductManagerActions.TYPES.BULK_COPY_KEYWORDS_SUCCESS:
      return {
        ...state,
      };

    case ProductManagerActions.TYPES.BULK_COPY_KEYWORDS_ERROR:
      return {
        ...state,
      };

    case ProductManagerActions.TYPES.BULK_DELETE_KEYWORDS:
      return {
        ...state,
        removingKeyword: true,
        keywords: {
          ...state.keywords,
        }
      };

    case ProductManagerActions.TYPES.BULK_DELETE_KEYWORDS_SUCCESS:
      return {
        ...state,
        removingKeyword: false,
        keywords: {
          ...state.keywords,
          phrases: (state.keywords.phrases || []).filter((kw: IProductManagerKeyword) => !action.data.includes(kw.value)),
        }
      };

    case ProductManagerActions.TYPES.GET_KEYWORD_GRAPH:
      return {
        ...state,
        loadingKeywordsGraph: true,
        keywords: {
          ...state.keywords,
          phrases: (state.keywords.phrases || []).map((keyword: IProductManagerKeyword) => {
            return {
              ...keyword,
              graphOpened: keyword.value === action.data.keyword ? !keyword.graphOpened : false,
            };
          })
        }
      };

    case ProductManagerActions.TYPES.GET_KEYWORD_GRAPH_SUCCESS:
      return {
        ...state,
        loadingKeywordsGraph: false,
        keywordGraph: {
          rank: mapGraphData(action.data.data.rank)
            .filter((datapoint: number[]) => datapoint[1] < NOT_RANKED), // remove not ranked points from graph
          impressions: mapGraphData(action.data.data.impressions),
        },
      };

    case ProductManagerActions.TYPES.GET_KEYWORD_GRAPH_ERROR:
      return {
        ...state,
        loadingKeywordsGraph: false,
      };

    case ProductManagerActions.TYPES.REFRESH_INDEX:
      if (!state.activeProduct || state.activeProduct.asin !== action.data.asin) {
        return state;
      }

      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          index_check_at: (new Date()).toISOString(),
          index_check_finished_at: null,
        }
      };

    case ProductManagerActions.TYPES.REFRESH_INDEX_ERROR:
      if (!state.activeProduct || state.activeProduct.asin !== action.data.asin) {
        return state;
      }

      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          index_check_finished_at: state.activeProduct.index_check_at,
        }
      };

    case ProductManagerActions._events.product_manager_keyword_index_check_finished:
      if (!state.activeProduct || state.activeProduct.asin !== action.data.asin) {
        return state;
      }

      return {
        ...state,
        activeProduct: {
          ...state.activeProduct,
          index_check_finished_at: (new Date()).toISOString(),
        }
      };

    case ProductManagerActions.TYPES.CORRECT_KEYWORD:
      return {
        ...state,
        updatingKeywordsPriority: true,
        keywords: {
          ...state.keywords,
          phrases: (state.keywords.phrases || []).map((phrase: IProductManagerKeyword) => {
            const correctingKeyword = phrase.value === action.data.keyword;

            return {
              ...phrase,
              correctingKeyword,
            };
          })
        }
      };

    case ProductManagerActions.TYPES.CORRECT_KEYWORD_SUCCESS:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          phrases: (state.keywords.phrases || []).map((phrase: IProductManagerKeyword) => {
            let newValue = phrase.value;
            let newValueCorrected = phrase.value_corrected;

            if (action.data.keyword === phrase.value) {
              newValue = action.data.updatedKeyword;
              newValueCorrected = null;
            }

            return {
              ...phrase,
              value: newValue,
              correctingKeyword: false,
              value_corrected: newValueCorrected,
            };
          }),
        },
        updatingKeywordsPriority: false,
      };

    case ProductManagerActions.TYPES.CORRECT_KEYWORD_ERROR:
      return {
        ...state,
        updatingKeywordsPriority: false,
        keywords: {
          ...state.keywords,
          phrases: (state.keywords.phrases || []).map((phrase: IProductManagerKeyword) => ({...phrase, correctingKeyword: false})),
        }
      };

    case ProductManagerActions.TYPES.GET_BRAND_ANALYTICS_KEYWORD:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          phrases: (state.keywords.phrases || []).map((keyword: IProductManagerKeyword) => {
            return {
              ...keyword,
              sfrGraphLoading: keyword.value === action.data.keyword,
              sfrGraphOpened: false,
            };
          }),
          sfrGraphData: null
        }
      };

    case ProductManagerActions.TYPES.GET_BRAND_ANALYTICS_KEYWORD_SUCCESS:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          sfrGraphData: action.data.data.products,
          phrases: (state.keywords.phrases || []).map((keyword: IProductManagerKeyword) => {
            return {
              ...keyword,
              sfrGraphOpened: keyword.value === action.data.keyword,
              sfrGraphLoading: false,
            };
          })
        },
      };

    case ProductManagerActions.TYPES.GET_BRAND_ANALYTICS_KEYWORD_ERROR:
      return {
        ...state,
        keywords: {
          ...state.keywords,
          phrases: (state.keywords.phrases || []).map((keyword: IProductManagerKeyword) => {
            return {
              ...keyword,
              sfrGraphLoading: false,
            };
          })
        },
      };

    case ProductManagerActions.TYPES.SHOW_UPGRADE_PLAN_BANNER:
      return {
        ...state,
        upgradePlanBannerVisible: true,
        upgradePlanBannerTitle: action.data.title,
        upgradePlanBannerMessage: action.data.message,
      };

    case ProductManagerActions.TYPES.HIDE_UPGRADE_PLAN_BANNER:
      return {
        ...state,
        upgradePlanBannerVisible: false,
        upgradePlanBannerTitle: null,
        upgradePlanBannerMessage: null,
      };

    case ProductManagerActions.TYPES.ADD_FOREIGN_PRODUCTS_CLOSE_MODAL:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          closeAddProductsModal: true,
        },
      };

    case ProductManagerActions.TYPES.ADD_FOREIGN_PRODUCTS_SUCCESS:
      return {
        ...state,
        productsState: {
          ...state.productsState,
          closeAddProductsModal: false,
        },
      };

    case ProductManagerActions.TYPES.TOGGLE_ALL_VARIATIONS:
      return {
        ...state,
        productVariationsState: {
          ...state.productVariationsState,
          products: (state.productVariationsState.products || []).map((product: IProductManagerVariation) => {
            return {
              ...product,
              checked: action.data,
            };
          }),
        }
      };

      case ProductManagerActions.TYPES.LOAD_AVAILABLE_TAG_FILTERS:
        return {
          ...state,
          loadingAvailableTagFilters: true,
        };

      case ProductManagerActions.TYPES.LOAD_AVAILABLE_TAG_FILTERS_SUCCESS:
        return {
          ...state,
          loadingAvailableTagFilters: false,
          availableTagFilters: action.data.tags
        };

      case ProductManagerActions.TYPES.LOAD_AVAILABLE_TAG_FILTERS_ERROR:
        return {
          ...state,
          loadingAvailableTagFilters: false,
        };

      case ProductManagerActions.TYPES.DELETE_PRODUCT:
        return {
          ...state,
          deletingProduct: true,
          deletingProductError: false,
          deletingProductSuccess: false
        };

      case ProductManagerActions.TYPES.DELETE_PRODUCT_SUCCESS:
        return {
          ...state,
          productsState: {
            ...state.productsState,
            otherProducts: state.productsState.otherProducts.filter(product => product.asin !== action.data),
            foreign_total: state.productsState.foreign_total - 1,
          },
          deletingProduct: false,
          deletingProductError: false,
          deletingProductSuccess: true
        };

      case ProductManagerActions.TYPES.DELETE_PRODUCT_ERROR:
        return {
          ...state,
          deletingProduct: false,
          deletingProductError: action.data.error
        };

    default:
      return state;
  }
}

function mapGraphData(data: IProductManagerKeywordGraphData[]): any[][] {
  return data
    .map((item: IProductManagerKeywordGraphData) => {
      return [Date.parse(item.date), Math.ceil(item.value), item.asin];
    })
    // filter out not properly formatted date values
    .filter((datapoint: number[]) => !isNaN(datapoint[0]))
    // sort by the date
    .sort((a: [number, number, number], b: [number, number, number]) => a[0] - b[0]);
}

function flatten(array) {
  return !Array.isArray(array) ? array : [].concat.apply([], array.map(flatten));
}

function sortKeywords(keywords: IProductManagerKeyword[]): IProductManagerKeyword[] {
  return keywords.sort((a: IProductManagerKeyword, b: IProductManagerKeyword) => {
    return keywordSortMapper[a.priority.toLowerCase()] - keywordSortMapper[b.priority.toLowerCase()];
  });
}

function parseWordsFromKeywords(keywords: IProductManagerKeyword[]) {
  return [
    ...new Map(
      flatten(
        keywords.map((keyword) => {
          return keyword.value.split(' ').map((word) => {
            return {value: word, priority: keyword.priority.toLowerCase()};
          });
        })
      )
        // In case that there is word with 'H' and 'VL' priority, this keyword must be marked as 'H'.
        // We need to sort the mapped words by the priority DESC
        // to have the word with higher priority after the word with lower priority
        // so when we map the words to a map, lower prioritized word will be overridden by the higher priority word.
        .sort((a, b) => keywordSortMapper[b.priority.toLowerCase()] - keywordSortMapper[a.priority.toLowerCase()])
        .map((item) => [item.value, item])
    ).values()
  ];
}

export const products = createSelector(
  [state => state['product_manager']['productsState']['products']],
  (data: IProductManagerProduct[]) => data || INITIAL_STATE.productsState.products
);

export const isLoadingProducts = createSelector(
  [state => state['product_manager']['loadingProducts']],
  (data: boolean) => data || INITIAL_STATE.loadingProducts
);
