import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressBarComponent } from '@app/shared/components/progress-bar/progress-bar.component';
import { LoaderModule } from '@app/shared/layout/loader/loader.module';

@NgModule({
  imports     : [
    CommonModule,
    LoaderModule,
  ],
  declarations: [
    ProgressBarComponent,
  ],
  exports     : [
    ProgressBarComponent,
  ],
})
export class ProgressBarModule {
}
