<img src="/assets/img/icons/check.svg" *ngIf="item.value.indexed" [popper]="keywordIndexTooltip" popperTrigger="hover"
  popperPlacement="bottom" popperAppendTo="body" class="table-icon--check">
<img src="/assets/img/icons/cross-gray.svg" *ngIf="!item.value.indexed" [popper]="keywordIndexTooltip"
  popperTrigger="hover" popperPlacement="bottom" popperAppendTo="body" class="table-icon--cross">

<popper-content #keywordIndexTooltip>
  <div class="keyword-index-tooltip">
    <div *ngIf="item.value?.indexed_date; else notChecked">
      <span i18n>Checked at: </span>
      <span>{{item.value?.indexed_date | date:'MM/dd/yyyy'}}</span>
      <span>{{item.value?.indexed_date | date:'hh:mm a'}}</span>
    </div>
    <ng-template #notChecked i18n>
      Index check in progress
    </ng-template>
  </div>
</popper-content>
