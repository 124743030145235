import { Component, Input, Output, EventEmitter } from '@angular/core';
import { V2TableColumn } from '../table.models';

@Component({
  selector: 'st-toggle-columns',
  templateUrl: './toggle-columns.component.html',
  styleUrls: ['./toggle-columns.component.scss']
})
export class ToggleColumnsComponent {

  @Input() columns: V2TableColumn[];
  @Input() disabled: boolean = false;

  opened: boolean = false;

  @Output() columnToggled: EventEmitter<number> = new EventEmitter();

  toggleColumn(index: number) {
    this.columnToggled.emit(index);
  }

  toggleDropdown() {
    if (this.disabled) {
      return;
    }

    this.opened = !this.opened;
  }

}
