import { AppState } from '@app/shared/data/app-state.model';
import { StAction } from '@app/shared/data/st-action';
import { ITopUpSource } from './wallet-top-up-sources.models';
import { WalletTopUpSourcesActions } from './wallet-top-up-sources.actions';

export interface IWalletTopUpSourcesState extends AppState {
  data: ITopUpSource[];
  loading: boolean;
  creating: boolean;
  removing: boolean;
  error: any;
}

const INITIAL_STATE: IWalletTopUpSourcesState = {
  data: [],
  loading: false,
  creating: false,
  removing: false,
  error: null,
};

export function walletTopUpSourcesReducer(state: IWalletTopUpSourcesState
   = INITIAL_STATE, action: StAction): AppState {
  if (action.type === WalletTopUpSourcesActions.TYPES.LOAD_SOURCES) {
    return {
      ...state,
      data: [],
      loading: true,
      error: null,
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.LOAD_SOURCES_SUCCEEDED) {
    return {
      ...state,
      data: action.data['sources'],
      loading: false,
      error: null,
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.LOAD_SOURCES_FAILED) {
    return {
      ...state,
      data: [],
      loading: false,
      error: action.data['error'],
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.START_ACH_MANDATE_FLOW) {
    return {
      ...state,
      creating: true,
      error: null,
    };
  }
  if (action.type === WalletTopUpSourcesActions.TYPES.FINISH_ACH_MANDATE_FLOW) {
    return {
      ...state,
      creating: false,
      error: null,
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.LINK_ACH) {
    return {
      ...state,
      creating: true,
      error: null,
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.LINK_ACH_SUCCEEDED) {
    return {
      ...state,
      data: action.data['sources'],
      creating: false,
      error: null,
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.LINK_ACH_FAILED) {
    return {
      ...state,
      creating: false,
      error: action.data['error'],
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.REMOVE_ACH) {
    return {
      ...state,
      removing: true,
      error: null,
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.REMOVE_ACH_SUCCEEDED) {
    return {
      ...state,
      data: action.data['sources'],
      removing: false,
      error: null,
    };
  }

  if (action.type === WalletTopUpSourcesActions.TYPES.REMOVE_ACH_FAILED) {
    return {
      ...state,
      removing: false,
      error: action.data['error'],
    };
  }

  return state;
}
