import { Injectable } from '@angular/core';
import { StAction } from '@app/shared/data/st-action';
import { Wallet } from './wallet.models';

@Injectable()
export class WalletActions {
  static readonly TYPES = {
    LOAD: '[WALLET]LOAD',
    LOAD_SUCCEEDED: '[WALLET]LOAD_SUCCEEDED',
    LOAD_FAILED: '[WALLET]LOAD_FAILED'
  };

  load(): StAction {
    return {
      type: WalletActions.TYPES.LOAD,
      data: {
      }
    };
  }

  loadSucceeded(wallet: Wallet): StAction {
    return {
      type: WalletActions.TYPES.LOAD_SUCCEEDED,
      data: {
        wallet,
      }
    };
  }

  loadFailed(error: any): StAction {
    return {
      type: WalletActions.TYPES.LOAD_FAILED,
      data: {
        error,
      }
    };
  }
}
