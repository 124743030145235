import { Component, EventEmitter, Input, Output } from "@angular/core";
import { V2TableColumn } from "../../table.models";

@Component({
  selector: 'st-table-column-track-keyword',
  templateUrl: './table-columns.track-keyword.component.html',
  styleUrls: ['./table-columns.track-keyword.component.scss']
})
export class TableColumnTrackKeywordComponent {
  @Input() column: V2TableColumn;
  @Output() trackKeywords: EventEmitter<{ trackAll: boolean }> = new EventEmitter();

  opened: boolean = false;

  toggleOpened() {
    if (this.column?.data?.disabled) {
      return;
    }
    this.opened = !this.opened;
  }
}
