<div class="product-select-wrapper tab white-boxes-layout">
    <div class="product-search">
        <h3 class="page-title" i18n>Find Products</h3>
        <div class="product-selection-description" i18n>
            Enter Title or Parent ASIN you'd like to create Alerts for and then click Select.
        </div>
        <div class="search-box" stNotClosableZone>
            <input type="text" [(ngModel)]="searchQuery" debounceKeyUp [debounceTime]="600"
                   [disabled]="(searching$ | async)"
                   (onEvent)="searchWithQuery()"
                   class="field interface-component" name="query" i18n-placeholder placeholder="Search&hellip;">
            <button type="submit">
                <st-icon class="search-box-button" [icon]="(searching$ | async) ? 'spinner' : 'search'"></st-icon>
            </button>
        </div>
        <div class="box exposed-content">
            <ng-container>
                <div style="text-align: center" *ngIf="(searchErrors$ | async)">
                    {{ (searchErrors$ | async) }}
                </div>
                <div *ngIf="!(searchErrors$ | async)">
                    <div class="loading-dots" *ngIf="searching$ | async">
                        <div class="loading-dots--dot"></div>
                        <div class="loading-dots--dot"></div>
                        <div class="loading-dots--dot"></div>
                    </div>
                    <p class="product-selection-no-results" *ngIf="(searchResults$ | async)?.length && !(searching$ | async)" i18n>
                        Use search above to add products.
                    </p>
                    <ul class="product-list" *ngIf="!(searching$ | async)">
                        <li *ngFor="let product of (searchResults$ | async)">
                            <button class="product">
                                <div class="picture"><img [src]="product.image_url"></div>
                                <div class="texts">
                                    <div class="name">{{ product.title}}</div>
                                    <div class="asin"><span i18n>ASIN:</span> {{ product.asin }}</div>
                                </div>
                                <st-button class="product-modifier-button" icon="add" (click)="selectProduct(product)" i18n>Select</st-button>
                            </button>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="product-selection">
        <h3 class="page-title" i18n>Selected Products</h3>
        <div class="product-selection-description" i18n>
            List of selected products, click Remove to clear from list.
        </div>
        <div class="box exposed-content">
            <p class="product-selection-no-results" *ngIf="!(selectedProducts$ | async)?.length" i18n>No product selected.</p>
            <ul class="product-list">
                <li *ngFor="let product of (selectedProducts$ | async)">
                    <button class="product">
                        <div class="picture"><img [src]="product.image_url"></div>
                        <div class="texts">
                            <div class="name">{{ product.title | stTruncate: '50'}}</div>
                            <div class="asin"><span i18n>ASIN:</span> {{ product.asin }}</div>
                        </div>
                        <st-button class="product-modifier-button" icon="clear" (click)="deselectProduct(product)" i18n>Remove</st-button>
                    </button>
                </li>
            </ul>
        </div>
    </div>
</div>
