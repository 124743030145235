import { ISearchedProduct, ISearchProductsState } from '@app/shared/data/browse-products/browse-products.models';
import { StAction } from '@app/shared/data/st-action';
import { BrowseProductsActions } from '@app/shared/data/browse-products/browse-products.actions';
import { createSelector } from 'reselect';

const INITIAL_STATE: ISearchProductsState = {
  data: [],
  currentSearchTerm: null,
  error: null,
  searching: false
};

export function browseProductsReducer(state: ISearchProductsState = INITIAL_STATE, action: StAction): ISearchProductsState {

  if (action.type === BrowseProductsActions._events.browse_products_finished) {
    let data = [];

    if (!action.data.error || !action.data.error.length) {
      if (action.data.search_key === state.currentSearchTerm) {
        data = action.data.products;
      } else {
        data = state.data || [];
      }
    }

    return {
      ...state,
      data,
      searching: false,
      error: action.data.error ? action.data.error.code : null,
    };
  }

  if (action.type === BrowseProductsActions.TYPES.BROWSE_BY_KEYWORD ||
    action.type === BrowseProductsActions.TYPES.BROWSE_BY_ASINS) {
    return {
      ...state,
      searching: true,
      currentSearchTerm: action.data.keyword || action.data.asins.join(','),
    };
  }

  if (action.type === BrowseProductsActions.TYPES.BROWSE_PRODUCTS_CLEAR) {
    return {
      ...state,
      searching: false,
      currentSearchTerm: INITIAL_STATE['currentSearchTerm'],
      data: INITIAL_STATE['data'],
    };
  }

  if (action.type === BrowseProductsActions.TYPES.BROWSE_PRODUCTS_ERROR) {
    return {
      ...state,
      searching: false,
    };
  }

  return state;
}

export const results = createSelector(
  [state => state['browse_products']['data']],
  (data: ISearchedProduct[]) => data || INITIAL_STATE['data']
);

export const browsingErrors = createSelector(
  [state => state['browse_products']['error']],
  (error: string) => error || INITIAL_STATE['error']
);

export const isSearchingProducts = createSelector(
  [state => state['browse_products']['searching']],
  (loading: boolean) => loading
);
