import { IAvailableFilter, IFilter, ISavedFilter } from "@app/client/v2/common/advanced-filters/advanced-filters-modal/advanced-filters-modal.component";
import { IErrorResponse } from "../base.models";

export interface ICustomerListState {
  customerList: ICustomer[];
  currentPage: number;
  lastPage: number;
  perPage: number;
  total: number;
  from: number;
  to: number;
  stats: ICustomerStats;

  appliedFilters: IFilter[];
  appliedView: ISavedFilter;
  savedFilters: ISavedFilter[];
  availableFilters: IAvailableFilter[];
  list: TypeListEnum;
  searchTerm: string;
  credentialId: null | number;
  sort: string;

  loadingCustomerList: boolean;
  checkingPhoneNumber: null | string;
  phoneNumberChecked: boolean;
  phoneNumberScore: number;
  phoneNumberCheckError: null | string;
  addingNewCustomer: boolean;
  newCustomerAdded: IAddCustomerResponse;
  updatingCustomer: boolean,
  updatedCustomer: boolean,
  deletingCustomers: boolean,
  deletedCustomers: boolean,
  importingCustomers: boolean,
  importingCustomersId: null,
  importingCustomersProgress: number,
  importingCustomersError: boolean,
  importingCustomersStats: IImportCustomersStats;
  savingFilters: boolean;
  savingFiltersError: boolean;
  loadingSavedFilters: boolean;
  loadingAvailableFilters: boolean;
  deletingView: boolean;
  checkingMissingPhoneNumberScores: boolean;
  checkingMissingPhoneNumberScoresError: IErrorResponse;

  movingCustomers: boolean,
  movedCustomers: boolean,
  exportingCustomers: boolean,
  exportedCustomers: any,
  error: boolean;
}

export interface ICustomer {
  id?: string;
  names: string[];
  emails: string[];
  phone_numbers: string[];
  list: string;
  on_global_deny_list?: boolean;
  phone_number_scores?: IPhoneNumberScoreList;
  score?: string;
  created_at?: string;
  detailsOpened?: boolean;
  offered?: number;
  completed_orders?: number;
  selectedPhoneNumber?: string;
  check_phone_number_score?: boolean;
  checked?: boolean;
  flags?: string[];
  order_ids?: string[];

  orderItems?: IOrderItemCollection;
}

export interface IOrderItemCollection {
  completed: IOrderItem[];
  unconfirmed: string[];
}

export interface IOrderItem {
  credential_id: number;
  order_id: string;
  order_item_id: number;
  asin: string;
  title: null | string;
  img_url: null | string;
  price: null | string;
  currency: null | string;
  status: OrderItemStatusEnum;
  warranty_from?: string;
  warranty_to?: string;
  valid_warranty?: boolean;
}

export enum OrderItemStatusEnum {
  STATUS_CANCELED = 'canceled',
  STATUS_REFUNDED = 'refunded',
  STATUS_RETURNED = 'returned',
  STATUS_ORDERED = 'ordered'
}

export interface ICustomerStats {
  all_customers_counter: number;
  vip_list_customers_counter: number;
  deny_list_customers_counter: number;
  unrated_phone_numbers_counter: number;
}
export interface IPhoneNumberScoreList {
  [key: string]: IPhoneNumberScore
}
export interface IPhoneNumberScore {
  phone_number: string;
  score: number;
  created_at: string;
}

export interface IImportCustomersStats {
  total: number;
  with_phone: number;
  with_email: number;
  with_phone_and_email: number;
}

export interface ICustomerListFlags {
  missing_phone_ratings_check_in_progress: boolean;
}

export interface ICustomerListResponse {
  data: ICustomer[];
  links: {
    first: string;
    last: string;
    prev: string;
    next: string;
  }
  meta: {
    current_page: number;
    from: number;
    last_page: number;
    per_page: number;
    path: string;
    total: number;
    to: number;
  },
  flags: ICustomerListFlags,
  stats: ICustomerStats
}

export interface ICheckPhoneNumberResponse {
  data: IPhoneNumberScore
}
export interface IAddCustomerResponse {
  data: ICustomer;
  balance_too_low: boolean;
}

export interface IImportManyChatCustomersResponse {
  data: {
    id: string;
  };
}

export interface ISaveFilterResponse {
  data: ISavedFilter;
}

export interface ISavedFiltersListResponse {
  data: ISavedFilter[];
}
export interface ILoadAvailableFiltersResponse {
  data: IAvailableFilter[];
}

export interface ILoadCustomerOrderItemsSuccessResponse {
  data: IOrderItemCollection;
}

export enum TypeListEnum {
  NONE = 'none',
  VIP = 'vip',
  DENY = 'deny'
}

export enum SortByEnum {
  DATE = 'created_at',
  NAME = 'name',
  EMAIL = 'email',
  NONE = 'none'
};

export enum ImportSourceEnum {
  MANY_CHAT = 'manychat',
  WALLET = 'wallet',
  BLACKLIST = 'blacklist',
};
