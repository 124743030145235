import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { Injectable } from "@angular/core";
import { SystemNotificationAdmin } from "@app/shared/data/admin/system-notification/system-notification.models";
import { StAction } from "@app/shared/data/st-action";

@Injectable()
export class SystemNotificationAdminActions extends PaginationActions {
  protected model = SystemNotificationAdmin;

  static readonly TYPES = {
    SAVE            : '[SYSTEMNOTIFICATIONADMIN]SAVE',
    SAVE_SUCCEEDED  : '[SYSTEMNOTIFICATIONADMIN]SAVE_SUCCEEDED',
    SAVE_FAILED     : '[SYSTEMNOTIFICATIONADMIN]SAVE_FAILED',
    FILTER          : '[SYSTEMNOTIFICATIONADMIN]FILTER',
    REMOVE          : '[SYSTEMNOTIFICATIONADMIN]REMOVE',
    REMOVE_SUCCEEDED: '[SYSTEMNOTIFICATIONADMIN]REMOVE_SUCCEEDED',
    REMOVE_FAILED   : '[SYSTEMNOTIFICATIONADMIN]REMOVE_FAILED',
  };

  filter(filters): StAction {
    return {
      type: SystemNotificationAdminActions.TYPES.FILTER,
      data: filters
    }
  }

  remove(notification: SystemNotificationAdmin): StAction {
    return {
      type: SystemNotificationAdminActions.TYPES.REMOVE,
      data: {
        notification: notification,
      }
    }
  }

  removeSucceeded(): StAction {
    return {
      type: SystemNotificationAdminActions.TYPES.REMOVE_SUCCEEDED,
      data: {}
    }
  }

  removeFailed(error: any): StAction {
    return {
      type: SystemNotificationAdminActions.TYPES.REMOVE_FAILED,
      data: error
    }
  }

  save(notification: SystemNotificationAdmin): StAction {
    return {
      type: SystemNotificationAdminActions.TYPES.SAVE,
      data: {
        notification: notification,
      }
    }
  }

  saveSucceeded(notification: SystemNotificationAdmin): StAction {
    return {
      type: SystemNotificationAdminActions.TYPES.SAVE_SUCCEEDED,
      data: {
        notification: notification,
      }
    }
  }

  saveFailed(error: any): StAction {
    return {
      type: SystemNotificationAdminActions.TYPES.SAVE_FAILED,
      data: error
    }
  }
}
