import { Injectable } from "@angular/core";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { StAction } from "@app/shared/data/st-action";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";
import { User } from "@app/shared/data/user/user.models";
import { Plan } from "@app/shared/data/plan/plan.models";
import { Credential } from "@app/shared/data/credential/credential.models";

@Injectable()
export class UserAdminActions extends PaginationActions {
  protected model = UserAdmin;

  static readonly TYPES = {
    FILTER               : '[USERADMIN]FILTER',
    IMPERSONATE          : '[USERADMIN]IMPERSONATE',
    IMPERSONATE_SUCCEEDED: '[USERADMIN]IMPERSONATE_SUCCEEDED',
    IMPERSONATE_FAILED   : '[USERADMIN]IMPERSONATE_FAILED',

    SET_FULL_USER          : '[USERADMIN]SET_FULL_USER',
    SET_FULL_USER_SUCCEEDED: '[USERADMIN]SET_FULL_USER_SUCCEEDED',
    SET_FULL_USER_FAILED   : '[USERADMIN]SET_FULL_USER_FAILED',

    ADD_TRIAL_DAYS          : '[USERADMIN]ADD_TRIAL_DAYS',
    ADD_TRIAL_DAYS_SUCCEEDED: '[USERADMIN]ADD_TRIAL_DAYS_SUCCEEDED',
    ADD_TRIAL_DAYS_FAILED   : '[USERADMIN]ADD_TRIAL_DAYS_FAILED',

    UPDATE_COMMISSION: '[USERADMIN]UPDATE_COMMISSION',
    UPDATE_SUCCEEDED : '[USERADMIN]UPDATE_SUCCEEDED',
    UPDATE_FAILED    : '[USERADMIN]UPDATE_FAILED',

    ADD_ADDITIONAL_PLAN          : '[USERADMIN]ADD_ADDITIONAL_PLAN',
    ADD_ADDITIONAL_PLAN_SUCCEEDED: '[USERADMIN]ADD_ADDITIONAL_PLAN_SUCCEEDED',
    ADD_ADDITIONAL_PLAN_FAILED   : '[USERADMIN]ADD_ADDITIONAL_PLAN_FAILED',

    REMOVE_ADDITIONAL_PLAN          : '[USERADMIN]REMOVE_ADDITIONAL_PLAN',
    REMOVE_ADDITIONAL_PLAN_SUCCEEDED: '[USERADMIN]REMOVE_ADDITIONAL_PLAN_SUCCEEDED',
    REMOVE_ADDITIONAL_PLAN_FAILED   : '[USERADMIN]REMOVE_ADDITIONAL_PLAN_FAILED',

    UPDATE_PERMISSIONS          : '[USERADMIN]UPDATE_PERMISSIONS',
    UPDATE_PERMISSIONS_SUCCEEDED: '[USERADMIN]UPDATE_PERMISSIONS_SUCCEEDED',
    UPDATE_PERMISSIONS_FAILED   : '[USERADMIN]UPDATE_PERMISSIONS_FAILED',

    UPDATE_USER         : '[USERADMIN]UPDATE_USER',
    UPDATE_USER_SUCCEEDED: '[USERADMIN]UPDATE_USER_SUCCEEDED',
    UPDATE_USER_FAILED   : '[USERADMIN]UPDATE_USER_FAILED',

    TRANSFER_CREDENTIALS : '[USERADMIN]TRANSFER_CREDENTIALS',
    TRANSFER_CREDENTIALS_SUCCEEDED : '[USERADMIN]TRANSFER_CREDENTIALS_SUCCEEDED',
    TRANSFER_CREDENTIALS_FAILED : '[USERADMIN]TRANSFER_CREDENTIALS_FAILED',
  };

  filter(filters): StAction {
    return {
      type: UserAdminActions.TYPES.FILTER,
      data: filters
    }
  }

  impersonate(user: UserAdmin, privileged: boolean = false): StAction {
    return {
      type: UserAdminActions.TYPES.IMPERSONATE,
      data: {
        user: user,
        privileged: privileged
      }
    }
  }

  impersonateSucceeded(user: User): StAction {
    return {
      type: UserAdminActions.TYPES.IMPERSONATE_SUCCEEDED,
      data: {
        user: user
      }
    };
  }

  impersonateFailed(error: any): StAction {
    return {
      type: UserAdminActions.TYPES.IMPERSONATE_FAILED,
      data: error
    };
  }

  setFullUser(id): StAction {
    return {
      type: UserAdminActions.TYPES.SET_FULL_USER,
      data: {
        user: id,
      }
    }
  }

  setFullUserSucceeded(user: UserAdmin): StAction {
    return {
      type: UserAdminActions.TYPES.SET_FULL_USER_SUCCEEDED,
      data: {
        user: user,
      }
    }
  }

  setFullUserFailed(error: any): StAction {
    return {
      type: UserAdminActions.TYPES.SET_FULL_USER_FAILED,
      data: error
    }
  }

  addTrialDays(user: UserAdmin, days: number): StAction {
    return {
      type: UserAdminActions.TYPES.ADD_TRIAL_DAYS,
      data: {
        user: user,
        days: days
      }
    }
  }

  addTrialDaysSucceeded(): StAction {
    return {
      type: UserAdminActions.TYPES.ADD_TRIAL_DAYS_SUCCEEDED,
      data: {}
    }
  }

  addTrialDaysFailed(error): StAction {
    return {
      type: UserAdminActions.TYPES.ADD_TRIAL_DAYS_FAILED,
      data: error
    }
  }

  updateCommissions(user: UserAdmin, commissionRatio: Number, percentOff: Number) {
    return {
      type: UserAdminActions.TYPES.UPDATE_COMMISSION,
      data: {
        user,
        commissionRatio,
        percentOff
      }
    }
  }

  updateSucceeded(user: UserAdmin) {
    return {
      type: UserAdminActions.TYPES.UPDATE_SUCCEEDED,
      data: {
        user
      }
    }
  }

  updateFailed(user: UserAdmin, error: any) {
    return {
      type: UserAdminActions.TYPES.UPDATE_FAILED,
      data: {
        user,
        error
      }
    }
  }

  addAdditionalPlan(user: UserAdmin, plan: Plan): StAction {
    return {
      type: UserAdminActions.TYPES.ADD_ADDITIONAL_PLAN,
      data: {
        user: user,
        plan: plan,
      }
    }
  }

  addAdditionalPlanSucceeded(user: UserAdmin, plans): StAction {
    return {
      type: UserAdminActions.TYPES.ADD_ADDITIONAL_PLAN_SUCCEEDED,
      data: {
        user : user,
        plans: plans,
      }
    }
  }

  addAdditionalPlanFailed(user: UserAdmin, plan: Plan, error: any): StAction {
    return {
      type: UserAdminActions.TYPES.ADD_ADDITIONAL_PLAN_FAILED,
      data: {
        user : user,
        plan : plan,
        error: error,
      }
    }
  }

  removeAdditionalPlan(user: UserAdmin, plan: Plan): StAction {
    return {
      type: UserAdminActions.TYPES.REMOVE_ADDITIONAL_PLAN,
      data: {
        user: user,
        plan: plan,
      }
    }
  }

  removeAdditionalPlanSucceeded(user: UserAdmin, plans): StAction {
    return {
      type: UserAdminActions.TYPES.REMOVE_ADDITIONAL_PLAN_SUCCEEDED,
      data: {
        user : user,
        plans: plans,
      }
    }
  }

  removeAdditionalPlanFailed(user: UserAdmin, plan: Plan, error: any): StAction {
    return {
      type: UserAdminActions.TYPES.REMOVE_ADDITIONAL_PLAN_FAILED,
      data: {
        user : user,
        plan : plan,
        error: error,
      }
    }
  }

  updatePermissions(user: UserAdmin, changes): StAction {
    return {
      type: UserAdminActions.TYPES.UPDATE_PERMISSIONS,
      data: {
        user   : user,
        changes: changes,
      }
    }
  }

  updatePermissionsSucceeded(user: UserAdmin): StAction {
    return {
      type: UserAdminActions.TYPES.UPDATE_PERMISSIONS_SUCCEEDED,
      data: {
        user: user,
      }
    }
  }

  updatePermissionsFailed(user: UserAdmin, changes, error): StAction {
    return {
      type: UserAdminActions.TYPES.UPDATE_PERMISSIONS_FAILED,
      data: {
        user   : user,
        changes: changes,
        error  : error,
      }
    }
  }

  updateUser(user: UserAdmin, changes): StAction {
    return {
      type: UserAdminActions.TYPES.UPDATE_USER,
      data: {
        user   : user,
        changes: changes,
      }
    }
  }

  updateUserSucceeded(user: UserAdmin): StAction {
    return {
      type: UserAdminActions.TYPES.UPDATE_USER_SUCCEEDED,
      data: {
        user: user,
      }
    }
  }

  updateUserFailed(user: UserAdmin, changes, error): StAction {
    return {
      type: UserAdminActions.TYPES.UPDATE_USER_FAILED,
      data: {
        user   : user,
        changes: changes,
        error  : error,
      }
    }
  }

  transferCredentials(credential_id: Number, toUser: UserAdmin, fromUser: UserAdmin): StAction {
    return {
      type: UserAdminActions.TYPES.TRANSFER_CREDENTIALS,
      data: {
        toUser,
        fromUser,
        credential_id,
      }
    }
  }

  transferCredentialsSucceeded(user: UserAdmin): StAction {
    return {
      type: UserAdminActions.TYPES.TRANSFER_CREDENTIALS_SUCCEEDED,
      data: {
        user: user,
      }
    }
  }

  transferCredentialsFailed(user: UserAdmin, error): StAction {
    return {
      type: UserAdminActions.TYPES.TRANSFER_CREDENTIALS_FAILED,
      data: {
        user: user,
        error: error
      }
    }
  }
}
