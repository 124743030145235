import { BaseModel } from '@app/shared/data/base.models';
import { Coupon } from '@app/shared/data/coupon/coupon.models';
import { Plan } from '../plan/plan.models';

export class Subscription extends BaseModel {
  id: number;
  name: string;
  plan_id: string;
  stripe_client_secret?: string;
  stripe_payment_method?: string;
  coupon?: string;
  couponObj?: Coupon;
  trial_ends_at?: Date;
  ends_at?: Date;
  created_at: Date;
  active: boolean;
  on_trial: boolean;
  is_incomplete: boolean;
  on_grace_period: boolean;
  canceled: boolean;
  plan?: Plan;

  get billingPeriod() {
    if (Boolean(this.ends_at)) {
      return new Date(this.ends_at);
    }
    if (Boolean(this.trial_ends_at)) {
      return new Date(this.trial_ends_at);
    }

    if (!Boolean(this.plan)) {
      return null;
    }

    const createdAt =  new Date(this.created_at);

    let period = 1; // month
    if (this.plan.annual) {
      period = 12; // a year
    }

    do {
      createdAt.setMonth(createdAt.getMonth() + period);
    } while (createdAt < new Date());

    return createdAt;
  }
}
