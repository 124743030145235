import { Component, EventEmitter, Input, Output } from "@angular/core";
import { V2TableDataRecord } from "../../table.models";

@Component({
  selector: 'st-table-button-item',
  templateUrl: './button.item.component.html',
  styleUrls: ['./button.item.component.scss']
})
export class ButtonItemTableItemComponent {
  @Input() item: V2TableDataRecord;
  @Output() clicked: EventEmitter<void> = new EventEmitter();
}
