import { environment } from "@env/environment";
import { User } from "@app/shared/data/user/user.models";

export class LocalStorageService {
  static readonly USER_KEY = environment.LOCALSTORAGE_KEY + '-user';

  public static forActiveUser() {
    const user:User = LocalStorageService.getUser();
    if(!user) return LocalStorageService.nullStorage();
    return new LocalStorageService(environment.LOCALSTORAGE_KEY + '_U' + user.id);
  }

  public static forActiveCredential() {
    const user:User = LocalStorageService.getUser();
    if(!user || !user.active_credential) return LocalStorageService.nullStorage();
    return new LocalStorageService(environment.LOCALSTORAGE_KEY + '_' + user.active_credential);
  }

  public static forGlobal() {
    return new LocalStorageService(environment.LOCALSTORAGE_KEY);
  }

  public static forProduct(asin:string) {
    const user:User = LocalStorageService.getUser();
    if(!user || !user.active_credential) return LocalStorageService.nullStorage();
    return new LocalStorageService(environment.LOCALSTORAGE_KEY + '_' + user.active_credential + '_' + asin);
  }

  private static nullStorage() {
    return new LocalStorageService(null);
  }

  public static rememberReferrer(url: string) {
    if (url !== null && url !== '') {
      localStorage.setItem('referrer', url);
    }
  }

  public static getUser() {
    const serialized = localStorage.getItem(this.USER_KEY);
    if (serialized !== null) {
      return new User(JSON.parse(serialized));
    }
    return null;
  }

  constructor(private baseKey: string) {}

  public get(key:string, parser: (x:string) => any = null): any {
    if(this.baseKey === null) return null;
    const serialized = localStorage.getItem(this.baseKey + '-' + key);
    if(parser) return parser(serialized);
    return serialized;
  }

  public set(key:string, value): void {
    if(this.baseKey === null) return;
    localStorage.setItem(this.baseKey + '-' + key, JSON.stringify(value));
  }

  public clear(): void {
    localStorage.clear();
  }

  public getTasks(): {[s: string]: any} {
    return this.get('tasks', JSON.parse);
  }

  public getIsTableColumnExpanded(): boolean {
    return Boolean(this.get('tableColumnExpanded'));
  }

  public setIsTableColumnExpanded(expanded: boolean) {
    return this.set('tableColumnExpanded', expanded);
  }

  public setTasks(tasks: any) {
    return this.set('tasks', tasks);
  }

  public getPerPage(key = ''): number {
    return this.get(key +'perpage', parseInt);
  }

  public setPerPage(perPage: number, key = '') {
    this.set(key +'perpage', perPage);
  }

  public getUserAdminFilters(): {[s: string]: any} {
    return this.get('useradminfilters', JSON.parse);
  }

  public getTransactionFilters(): {[s: string]: any} {
    return this.get('transactionfilters', JSON.parse);
  }

  public getBlacklistFilters(): {[s: string]: any} {
    return this.get('blacklistfilters', JSON.parse);
  }

  public setUserAdminFilters(filters = {}) {
    this.set('useradminfilters', filters);
  }

  public getAdminPayoutsFilters(): {[s: string]: any} {
    return this.get('adminpayoutsfilters', JSON.parse);
  }

  public setAdminPayoutsFilters(filters = {}) {
    this.set('adminpayoutsfilters', filters);
  }

  public getColumnFilters() {
    return this.get('columnfilters', JSON.parse);
  }

  public setColumnFilters(filters = []) {
    this.set('columnfilters', filters);
  }

  public getListinManageFilters(){
    return this.get('listingmanagerfilters',JSON.parse);
  }

  public static hasUserValidUtmTrackingTags(): boolean {
    const user = LocalStorageService.getUser();

    if (!user) {
      return false;
    }

    const utmForCheck = ['utm_medium', 'utm_term', 'utm_content', 'utm_source'];
    let hasUtmTags = false;

    utmForCheck.forEach((tag: string) => {
      if (user[tag]) {
        hasUtmTags = true;
      }
    });

    return hasUtmTags;
  }

  /**
   * Subscribe to any change of value in storage.
   */
  public static subscribe(key, func) {
    window.addEventListener('storage', (event) => {
      if (key == event.key)
        func(event.newValue, event.oldValue);
    }, true);
  }
}
export class LS extends LocalStorageService { }
