import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from "@angular/core";
import { v4 as uuidv4 } from 'uuid';

export interface ICheckboxChanged {
  label: string;
  checked: boolean;
  id: number | string;
}

@Component({
  selector: 'st-checkbox-v2',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() checked = false;
  @Input() label: string;
  @Input() disabled = false;
  @Input() color: 'red' | 'transparent' = 'transparent';
  @Input() id: number | string = uuidv4();

  @Output() changed: EventEmitter<ICheckboxChanged> = new EventEmitter();

  constructor(private cd: ChangeDetectorRef) { }

  ngOnInit() { }

  ngOnDestroy() { }

  onChanged() {
    this.changed.emit({
      label: this.label,
      checked: this.checked,
      id: this.id,
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.checked && changes.checked.previousValue !== changes.checked.currentValue) {
      this.checked = changes.checked.currentValue;
      this.cd.detectChanges();
    }
  }

  toggle(value: boolean) {
    this.checked = value;
    this.cd.detectChanges();
    this.onChanged();
  }
}
