import { Injectable } from "@angular/core";
import { StAction } from "@app/shared/data/st-action";
import { Collection } from "@app/shared/data/base.models";
import { AdminCoupon } from "@app/shared/data/admin/coupon/coupon.models";
import { PaginationActions } from "@app/shared/data/pagination/pagination.actions";
import { UserAdmin } from "@app/shared/data/admin/user/user-admin.models";

@Injectable()
export class AdminCouponActions extends PaginationActions {
  protected model = AdminCoupon;

  static readonly TYPES = {
    LOAD_COUPONS          : '[ADMIN_COUPON]LOAD_COUPONS',
    LOAD_COUPONS_SUCCEEDED: '[ADMIN_COUPON]LOAD_COUPONS_SUCCEEDED',
    LOAD_COUPONS_FAILED   : '[ADMIN_COUPON]LOAD_COUPONS_FAILED',

    CREATE          : '[ADMIN_COUPON]CREATE',
    CREATE_SUCCEEDED: '[ADMIN_COUPON]CREATE_SUCCEEDED',
    CREATE_FAILED   : '[ADMIN_COUPON]CREATE_FAILED',

    REMOVE          : '[ADMIN_COUPON]REMOVE',
    REMOVE_SUCCEEDED: '[ADMIN_COUPON]REMOVE_SUCCEEDED',
    REMOVE_FAILED   : '[ADMIN_COUPON]REMOVE_FAILED',
    SEARCH          : '[ADMIN_COUPON]SEARCH',
  };

  loadCouponsSucceeded(coupons: Collection<AdminCoupon>): StAction {
    return {
      type: AdminCouponActions.TYPES.LOAD_COUPONS_SUCCEEDED,
      data: coupons
    }
  }

  loadCouponsFailed(error: any): StAction {
    return {
      type: AdminCouponActions.TYPES.LOAD_COUPONS_FAILED,
      data: error
    }
  }

  create(code): StAction {
    return {
      type: AdminCouponActions.TYPES.CREATE,
      data: code
    }
  }

  createSucceeded(coupon: AdminCoupon): StAction {
    return {
      type: AdminCouponActions.TYPES.CREATE_SUCCEEDED,
      data: coupon
    }
  }

  createFailed(error): StAction {
    return {
      type: AdminCouponActions.TYPES.CREATE_FAILED,
      data: error
    }
  }

  remove(croupon: AdminCoupon): StAction {
    return {
      type: AdminCouponActions.TYPES.REMOVE,
      data: croupon
    }
  }

  removeSucceeded(croupon: AdminCoupon): StAction {
    return {
      type: AdminCouponActions.TYPES.REMOVE_SUCCEEDED,
      data: croupon
    }
  }

  removeFailed(error): StAction {
    return {
      type: AdminCouponActions.TYPES.REMOVE_FAILED,
      data: error
    }
  }

  searchCoupons(searchTerm :string): StAction {
    return {
      type: AdminCouponActions.TYPES.SEARCH,
      data: searchTerm
    }
  }
}
