import { NgRedux } from '@angular-redux/store';
import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import { AppState } from '@app/shared/data/app-state.model';
import { ProductManagerActions } from '@app/shared/data/product-manager/product-manager.actions';
import {
  IProductManagerProduct,
  IProductManagerState,
  IProductManagerVariation,
  ProductActivityStatusEnum,
} from '@app/shared/data/product-manager/product-manager.models';
import { ButtonIconEnum } from '@app/shared/layout/v2/forms/button/button.component';
import { IDropdownOption } from '@app/shared/layout/v2/forms/models/IDropdownOptions';
import { Subject ,  combineLatest } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';
import { AuthService } from '@app/core/services/auth.service';
import { IFieldOrder, SortByEnum, SortOrderEnum } from '@app/client/v2/product-manager/product-manager-product-list/product-manager-product-list.component';
import { ICampaignManagerState } from '@app/shared/data/campaign-manager/campaign-manager.models';


@Component({
  selector: 'st-product-list-table',
  templateUrl: 'product-list-table.component.html',
  styleUrls: ['product-list-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductListTableComponent implements OnInit, OnDestroy {
  sortByEnum = SortByEnum;
  activityStatusEnum = ProductActivityStatusEnum;

  lineOptions = {
    marker: {
      enabled: true,
      radius : 3
    },
  };

  @Input() products: IProductManagerProduct[];
  @Input() loadingProducts: boolean;
  @Input() loadingGraphData: boolean;
  @Input() bsrGraph: number[][];
  @Input() loadingProductVariations: boolean;
  @Input() productVariations: IProductManagerVariation[];
  @Input() perPageOptions: IDropdownOption[];
  @Input() preSelectedOption: IDropdownOption;

  @Input() variationPerPage: number;
  @Input() variationCurrentPage: number;
  @Input() variationTotal: number;
  @Input() variationPagesArray: number[];
  @Input() variationFrom: number;
  @Input() variationTo: number;
  @Input() variationLastPage: number;
  @Input() currentDisplayedVariationProducts: string;
  @Input() showResetButton = true;
  @Input() selectedProductAsin: string = null;
  @Input() selectedProductChildren: string[] = null;

  @Input() singleVariationSelection?: boolean = false;
  @Input() showVariations: boolean;
  @Input() showNoneOption: boolean;
  @Input() noneText: string;

  @Output() onSortBy: EventEmitter<SortByEnum> = new EventEmitter();
  @Output() onVariationGoToFirstPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationGoToLastPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationNextPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationPreviousPage: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onVariationChangePage: EventEmitter<{page: number, product: IProductManagerProduct}> = new EventEmitter();
  @Output() onVariationPageChange: EventEmitter<{ event: IDropdownOption, product: IProductManagerVariation }> = new EventEmitter();
  @Output() onSelectProduct: EventEmitter<{product: IProductManagerProduct, children: string[]}> = new EventEmitter();
  @Output() onProductReset: EventEmitter<IProductManagerProduct> = new EventEmitter();
  @Output() onActiveTab: EventEmitter<{ tab: 'graph'|'details', product: IProductManagerProduct }> = new EventEmitter();

  buttonIcons = ButtonIconEnum;
  activeFilterStatus: ProductActivityStatusEnum;

  currentStatus: ProductActivityStatusEnum = null;
  settingStatus = false;

  defaultSortBy: IFieldOrder[] = [{
    field: SortByEnum.STATUS,
    order: SortOrderEnum.DESC
  }, {
    field: SortByEnum.TITLE,
    order: SortOrderEnum.ASC
  }];

  allChecked: boolean = false;
  allVariationsChecked: boolean = false;

  maxTableHeight: number = 1000;

  private destroy$: Subject<void> = new Subject();

  constructor(
    private ngRedux: NgRedux<AppState>,
    private productManagerActions: ProductManagerActions,
    private rudderTracking: RudderTrackingService,
    private authService: AuthService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    setTimeout(() => {
      const modal = document.getElementsByClassName('modal')[0];
      this.maxTableHeight = modal?.clientHeight - 420;
    },0);

    this.ngRedux.select('product_manager')
      .pipe(
        takeUntil(this.destroy$),
      )
      .subscribe((productManagerState: IProductManagerState) => {
        this.settingStatus = productManagerState.settingProductStatus;
        if (productManagerState.productVariationsState.products) {
          this.allVariationsChecked = this.isAllVariationsChecked(productManagerState);
        }
        if (productManagerState.productVariationsState.products && productManagerState.productVariationsState.products.length && this.singleVariationSelection && this.allVariationsChecked) {
          this.toggleAllLocalVariations(productManagerState);
          productManagerState.productVariationsState.products[0].checked = true;
        }
        this.cd.detectChanges();
      });

    combineLatest(
      [this.ngRedux.select('product_manager'), this.ngRedux.select('campaign_manager')]
    ).pipe(
      filter(([productsState, campaignState]: [IProductManagerState, ICampaignManagerState]) => {
        return Boolean(productsState.productsState && productsState.productsState.products) &&
          productsState.productsState.products.length > 0 &&
          !productsState.loadingProducts &&
          Boolean(campaignState.campaignManagerState.product && campaignState.campaignManagerState.product.asin)
      }),
      take(1),
      takeUntil(this.destroy$),
    ).subscribe(([productsState, _campaignState]: [IProductManagerState, ICampaignManagerState]) => {
      const product = productsState.productsState.products.find(p => p.asin === this.selectedProductAsin);
      if (product) {
        this.toggleProductDetails(product);
      }
    });

    this.ngRedux.select('product_manager')
      .pipe(
        filter((productManagerState: IProductManagerState) => {
          return Boolean(productManagerState.productVariationsState.products && productManagerState.productVariationsState.products.length)
        }),
        take(1),
        takeUntil(this.destroy$),
      )
      .subscribe((productManagerState: IProductManagerState) => {
        if (this.selectedProductChildren && this.selectedProductChildren.length) {
          this.toggleAllLocalVariations(productManagerState);
          productManagerState.productVariationsState.products.map((product: IProductManagerVariation) => {
            product.checked = this.selectedProductChildren.indexOf(product.asin) !== -1;
          });
          this.allVariationsChecked = this.isAllVariationsChecked(productManagerState);
        } else if(this.singleVariationSelection) {
          this.toggleAllLocalVariations(productManagerState);
          productManagerState.productVariationsState.products[0].checked = true;
        }
      });
  }

  sortBy(sort: SortByEnum) {
    this.onSortBy.emit(sort);
  }

  toggleProductDetails(product: IProductManagerProduct, event?: MouseEvent) {
    if (event) {
      this.preventDefault(event);
      event.stopImmediatePropagation();
    }

    this.ngRedux.dispatch(this.productManagerActions.uncheckAllProducts());
    this.ngRedux.dispatch(this.productManagerActions.toggleProduct(product?.asin));

    if (this.showVariations) {
      this.ngRedux.dispatch(this.productManagerActions.getProductVariations(product?.asin, this.variationCurrentPage, this.variationPerPage));
    }
  }

  selectNone() {
    this.selectedProductAsin = null;
    this.ngRedux.dispatch(this.productManagerActions.uncheckAllProducts());
  }

  preventDefault(event: MouseEvent) {
    event.preventDefault();
    event.stopImmediatePropagation();
  }

  resetProduct(product: IProductManagerProduct) {
    this.rudderTracking.trackEvent('Product Manager - Reset');
    this.onProductReset.emit(product);
  }

  isProductTagListEmpty(product: IProductManagerProduct): boolean {
    return !product.tags || product.tags.length === 0;
  }

  variationGoToFirstPage(product: IProductManagerProduct) {
    this.onVariationGoToFirstPage.emit(product);
  }
  variationGoToLastPage(product: IProductManagerProduct) {
    this.onVariationGoToLastPage.emit(product);
  }

  variationNextPage(product: IProductManagerProduct) {
    this.onVariationNextPage.emit(product);
  }

  variationPreviousPage(product: IProductManagerProduct) {
    this.onVariationPreviousPage.emit(product);
  }
  
  variationChangePage(page: number, product: IProductManagerProduct) {
    this.onVariationChangePage.emit({page, product});
  }

  variationPageChange(event: IDropdownOption, product: IProductManagerVariation) {
    this.onVariationPageChange.emit({event, product});
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  getAmazonLink(product: IProductManagerProduct): string {
    return `https://${this.authService.getActiveCredential()?.amazon_domain}/dp/${product.asin}`;
  }

  toggleAllVariations() {
    if (!this.singleVariationSelection) {
      this.rudderTracking.trackEvent('CM - Product Selection', {
        action: !this.allVariationsChecked ? "Include all variations" : "Exclude all variations"
      });
    }
    this.allVariationsChecked = !this.allVariationsChecked;
    this.ngRedux.dispatch(this.productManagerActions.toggleAllVariations(this.allVariationsChecked));
  }

  toggleAllLocalVariations(productManagerState: IProductManagerState) {
    productManagerState.productVariationsState.products.map((product: IProductManagerVariation) => {
        product.checked = false;
      });
    this.allVariationsChecked = this.isAllVariationsChecked(productManagerState);
  }

  isAllVariationsChecked(productManagerState: IProductManagerState) {
    return productManagerState.productVariationsState.products.every((product: IProductManagerVariation) => {
      return product.checked;
    });
  }

  variationChanged(variation: IProductManagerVariation) {
    if (this.singleVariationSelection) {
      this.ngRedux.dispatch(this.productManagerActions.toggleAllVariations(false));
    }
    this.rudderTracking.trackEvent('CM - Product Selection', {
      action: !variation.checked ? "Include variation" : "Exclude variation"
    });
    this.ngRedux.dispatch(this.productManagerActions.toggleVariationCheck(variation.asin));
    this.cd.detectChanges();
  }
}
