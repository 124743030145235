
import { from as observableFrom, throwError as observableThrowError, Observable } from 'rxjs';

import { mergeMap, catchError } from 'rxjs/operators';
import { HttpInterceptor } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { ModalService } from '@app/shared/components/modals/modal.service';
import { ChooseAnotherCardComponent } from '@app/client/billing/modals/choose-another-card.component';
import { ToastService } from '@app/core/services/toast.service';
import { IConfirmCardPaymentResponse, PaymentService } from '@app/core/services/payment.service';
import { I18n } from '@ngx-translate/i18n-polyfill';

@Injectable()
export class PaymentInterceptor implements HttpInterceptor {
  constructor(private dialogService: DialogService,
    private modalService: ModalService,
    private toastService: ToastService,
    private paymentService: PaymentService,
    private i18n: I18n) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return next.handle(request).pipe(catchError(response => {
      let error = response.error;

      if ('error' in error) {
        error = error.error;
      }

      if (response.status === 402) {
        let observable;

        switch (error.error_code) {
          case 'error.payment_required':
            observable = this.paymentRequired(request, next, response, error);
            break;

          case 'stripe.no_payment_source':
            observable = this.noPaymentSource(request, next, response);
            break;

          case 'stripe.card_declined':
            observable = this.cardDeclined(request, next, response, error);
            break;

          case 'stripe.payment_incomplete':
            observable = this.paymentIncomplete(request, next, response, error);
            break;
          default:
            return observableThrowError(response);
        }

        return observable.pipe(
          catchError(((error) => {
            if (error.status === 402) {
              this.toastService.warning(
                this.i18n('Payment canceled'),
                this.i18n('Payment has been canceled!')
              );
              return observableThrowError({
                status: 402,
                error: {
                  error: {
                    message: this.i18n('Payment canceled'),
                    error_code: 'error.payment_canceled'
                  }
                }
              });

            }

            return observableThrowError(response);
          }))
        );
      }
      return observableThrowError(response);
    }));
  }

  /**
   *
   * @param request
   * @param next
   * @param response
   * @param error
   */
  private paymentRequired(request: HttpRequest<any>, next: HttpHandler, response: any, error: any): Observable<any> {
    const obs = observableFrom(this.dialogService.confirm(
      this.i18n('Payment required'),
      error.message,
      this.i18n('Pay'),
      this.i18n('Cancel')
    ));
    return obs.pipe(mergeMap((confirmed) => {
      if (confirmed) {
        return this.intercept(request.clone({
          setHeaders: {
            'st-pay': '1'
          }
        }), next);
      }
      return observableThrowError(response);
    }));
  }

  /**
   *
   * @param request
   * @param next
   * @param response
   */
  private noPaymentSource(request: HttpRequest<any>, next: HttpHandler, response: any): Observable<any> {
    return this.addCreditCard(request, next, response);
  }

  /**
   *
   * @param request
   * @param next
   * @param response
   * @param error
   */
  private cardDeclined(request: HttpRequest<any>, next: HttpHandler, response: any, error: any): Observable<any> {
    const promise = this.dialogService.confirm(
      this.i18n('Payment error'),
      error.message,
      this.i18n('Add new card'),
      this.i18n('Cancel')
    );

    return observableFrom(promise).pipe(
      mergeMap((confirmed) => {
        if (confirmed) {
          return this.addCreditCard(request, next, response);
        }
        return observableThrowError(response);
      }),
      catchError(() => {
        return observableThrowError(response);
      }));
  }

  /**
   *
   * @param request
   * @param next
   * @param response
   * @param error
   */
  private paymentIncomplete(request: HttpRequest<any>, next: HttpHandler, response: any, error: any): Observable<any> {
    return observableFrom(this.paymentService.confirmCardPayment(error.client_secret, error.payment_method, error.stripe_account)).pipe(
      mergeMap((resp: IConfirmCardPaymentResponse) => {
        if (Boolean(resp.paymentIntent)) {
          return this.intercept(this.withPaymentIntentResult(request, resp.paymentIntent.id), next);
        }

        return observableThrowError(resp.error);
      }));
  }

  /**
   * @param request
   * @param next
   * @param response
   */
  private addCreditCard(request: HttpRequest<any>, next: HttpHandler, response: any): Observable<any> {
    try {
      const comp = this.modalService.open(ChooseAnotherCardComponent);

      return observableFrom(comp.result).pipe(
        mergeMap((confirmed) => {
          if (confirmed) {
            // Repeat initial request
            return this.intercept(request, next);
          }

          return observableThrowError(response);
        }));
    } catch (e) {
      console.log('error', e);

      return observableThrowError(e);
    }
  }

  /**
   * Add Authorization header to passed request.
   *
   * @param req
   * @param paymentIntent
   * @returns {HttpRequest<any>} - new instance
   */
  private withPaymentIntentResult(req: HttpRequest<any>, paymentIntent): HttpRequest<any> {
    return req.clone({
      body: {
        ...req.body,
        payment_intent: paymentIntent
      }
    });
  }
}
