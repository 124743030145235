<div class="wrapper" [class.active]="item.value?.active" [class.not-active]="!item.value?.active">
  <ng-container *ngIf="item.value?.active">
    <span i18n>ACTIVE</span>
    <st-icon icon="check-blue-new"></st-icon>
  </ng-container>
  <ng-container *ngIf="!item.value?.active">
    <span i18n>DISABLED</span>
    <st-icon icon="error-v2"></st-icon>
    <st-button-v2
      class="status-button"
      [color]="item.value?.buttonColor || 'primary'"
      [label]="item.value?.buttonText || 'Apply'"
      [disabled]="item.value?.disabled"
      (clicked)="clicked.emit(item.value?.onClick)"
      i18n-label>
    </st-button-v2>
  </ng-container>
</div>
