import { Observable ,  Observer } from 'rxjs';
import { share } from 'rxjs/operators';

export class SocketChannel {
  name: string;

  private _socket;
  private _events: Observable<any>[] = [];
  private _auth: any;

  // private subscribedEvents: Set<string> = new Set();

  constructor(socket, name: string, auth: any = {}) {
    this._socket = socket;
    this.name = name;
    this._auth = auth;

    this.subscribe();
    this.reconnect();
  }

  subscribe(): void {
    this._socket.emit('subscribe', {
      channel: this.name,
      auth   : this._auth || {},
    });
  }

  reconnect(): void {
    this._socket.on('reconnect', () => {
      this.subscribe();
    });
  }

  on(event: string): Observable<{ name: string, data: any }> {
    if (this._events[event]) {
      return this._events[event];
    }

    this._events[event] = new Observable(
      (obs: Observer<{ name: string, data: any }>) => {
        this._socket.on(event, (channel, data) => {
          // emit value only if it is in same channel!
          if (this.name === channel) {
            event = data.__event || event;
            delete(data.__event); // unset __event property
            obs.next({ name: event, data: data });
          }
        });
      },
    ).pipe(share())

    return this._events[event];
  }

  unsubscribe() {
    Object.keys(this._events).forEach(event => {
      this._socket.removeListener(event);
      delete this._events[event];
    });

    this._socket.emit('unsubscribe', {
      channel: this.name,
      auth   : this._auth || {}
    });
  }
}
