import { BaseModel } from '@app/shared/data/base.models';

export class AppVersion extends BaseModel {
  static className = 'AppVersion';

  id: number;
  title: string;
  description: string;
  created_at: any;

}

export class AppVersionUpdate extends BaseModel {
  id: number;
  update: string;
}
