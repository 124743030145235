import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable ,  of } from 'rxjs';
import { BaseAPI } from '../base.api';
import { IReviewRequestGraphResponse, IReviewRequestOverride, IReviewRequestProductsResponse, IReviewRequestSettings } from './review-request.models';

@Injectable()
export class ReviewRequestAPI extends BaseAPI {
  constructor(
    protected http: HttpClient
  ) {
    super();
  }

  getSettings(): Observable<IReviewRequestSettings> {
    return this.http.get<IReviewRequestSettings>(this.constructFullUrl('api/review-request/settings'));
  }

  updateSettings(settings: IReviewRequestSettings, overrides: { [key: string]: IReviewRequestOverride } = {}) {
    return this.http.post<IReviewRequestSettings>(this.constructFullUrl('api/review-request/settings'), { settings, overrides });
  }

  getProducts(page: number, limit: number = 20, sort: string, term?: string) {
    let params: HttpParams = new HttpParams()
      .set('page', page.toString())
      .set('sort', sort)
      .set('limit', limit.toString());

    if (term) {
      params = params.set('term', term);
    }

    return this.http.get<IReviewRequestProductsResponse>(this.constructFullUrl('api/products-by-sku'), { params });
  }

  getSentRequestsGraph(time: number, granularity: string = 'day') {
    let params: HttpParams = new HttpParams().set('time', time.toString()).set('granularity', granularity);

    return this.http.get<IReviewRequestGraphResponse[]>(this.constructFullUrl('api/review-request/stats/sent-requests'), { params });
  }

  getReviewGrowthGraph(time: number) {
    let params: HttpParams = new HttpParams().set('time', time.toString());

    return this.http.get<IReviewRequestGraphResponse[]>(this.constructFullUrl('api/review-request/stats/review-growth'), { params });
  }
}
