import { SocketService, UserService } from '@app/core/socket/socket.service';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { SocketChannel } from '@app/core/socket/socket.channel';
import { SocketConfig } from '@app/core/socket/socket.config';
import {
  SocketMiddleware, TokenRefreshMiddleware,
  WildcardEmiterMiddleware,
} from '@app/core/socket/socket.middleware';
import { User } from '@app/shared/data/user/user.models';
import { filter, flatMap, share } from 'rxjs/operators';

declare let io: any;

@Injectable()
export class SocketIoService implements SocketService {

  private socket;
  private _socketConnected$ = new BehaviorSubject<boolean>(false);

  connected$ = this._socketConnected$.asObservable();
  channels: SocketChannel[] = [];

  constructor(@Inject('SocketConfig') private config: SocketConfig,
    @Inject('UserService') private userService: UserService, ) {
    this.socket = this._initSocketIO();
    this._handleTokenRefresh();
    this._handleConnections();
  }

  onPublic(eventName: string): Observable<any> {
    const name = this.config.public_channel;

    return this.channel(name).on(eventName);
  }

  onUser(eventName: string): Observable<any> {
    const name = this.config.user_channel;
    return this.userService.getUser$()
      .pipe(
        filter(user => user.id),
        flatMap(user => this.channel(`private-${name}.${user.id}`).on(eventName))
      );
  }

  onCredential(eventName: string): Observable<any> {
    const name = this.config.credentials_channel;

    return this.userService.activeCredential$
      .pipe(
        filter(activeCredenital => !!activeCredenital),
        flatMap(activeCredenital =>
          this.channel(`private-${name}.${activeCredenital}`)
            .on(eventName)
        )
      );
  }

  onCredentialOwner(eventName: string): Observable<any> {
    const name = this.config.credential_owner_channel;

    return this.userService.getUser$()
      .pipe(
        filter(user => user.id),
        flatMap((user: User) => {
          const activeCredential = this.userService.getActiveCredential();

          if (null !== activeCredential && activeCredential.id) {
            user = activeCredential.owner;
          }

          return this.channel(`private-${name}.${user.id}`)
            .on(eventName);
        })
      );
  }

  onAdmin(eventName: string): Observable<any> {
    const name = this.config.admin_channel;


    return this.userService.getUser$()
      .pipe(
        filter(user => user.id && user.isAdmin()),
        flatMap(user => this.channel(`private-${name}`).on(eventName))
      );
  }

  public leaveAllChannels() {
    Object.keys(this.channels).forEach((channelName: string) => {
      (<SocketChannel>this.channels[channelName]).unsubscribe();
    });

  }

  private channel(name): SocketChannel {
    if (!this.channels[name]) {
      this.channels[name] = new SocketChannel(this.socket, name, this.config.auth);
    }

    return this.channels[name];
  }

  private _initSocketIO() {
    if (typeof io === 'undefined') {
      throw new Error('Socket.io client not found. It should be globally available!');
    }

    // support wildcard event listener!
    const socket = io(this.config.host, this.config);
    const oldOnevent = socket.onevent;
    const middlewares: SocketMiddleware[] = this._getMiddlewares();

    socket.onevent = function (packet) {
      /* Emmit default events! */
      oldOnevent.apply(socket, arguments);

      for (const middleware of middlewares) {
        // process middleware
        const next = middleware.next(packet);

        // if we wan't to fire event with this middleware lets to it!
        if (next) {
          oldOnevent.apply(socket, [next]);
        }
      }

    };

    return socket;
  }

  private _handleTokenRefresh() {
    this.userService.token$
      .pipe(
        share(),
      )
      .subscribe(
        (token) => {
          if (token) {
            this.config.auth.headers.Authorization = `Bearer ${token}`;
            this.socket.emit('reconnect');
          } else {
            this.socket.emit('disconnect');
            this.channels = [];
          }
        });
  }

  private _handleConnections() {
    this.socket.on('connect', () => {
      this._socketConnected$.next(true);
    });

    this.socket.on('reconnect', () => {
      this._socketConnected$.next(true);
    });

    this.socket.on('disconnect', () => {
      this._socketConnected$.next(false);
    });
  }

  private _getMiddlewares(): SocketMiddleware[] {
    return [
      new WildcardEmiterMiddleware(),
      new TokenRefreshMiddleware(this.userService),
    ];
  }
}
