import { BaseModel } from '@app/shared/data/base.models';

export class ApiPromotion extends BaseModel {

  static className = 'ApiPromotion';

  id: number;
  hash: string;
  name: string;
  asin: string;
  percent_off: number;
  amount_off: number;

  coupons: string[];

  _saved?: boolean;
  _saved_coupons?: boolean;
}


export class ProductChild extends BaseModel {

  static className = 'ProductChild';

  image_url: string;
  title: string;
  asin: string;
  sku: string;
  parent_asin: string;
  protected: boolean;

}
