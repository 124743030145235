import { BaseModel } from "@app/shared/data/base.models";

export class SessionLog extends BaseModel {

  static className = 'SessionLog';
  id: number;
  user_agent: string;
  ip: string;
  event_type: string;
  email: string;
  created_at: string;
  _browser: string;

  constructor(data) {
    super(data);
    this.identifyBrowser(this.user_agent);
  }

  get browser() {
    return this._browser;
  }

  private identifyBrowser(userAgent) {
    var regexps = {
          'Chrome' : [/Chrome\/(\S+)/],
          'Firefox': [/Firefox\/(\S+)/],
          'MSIE'   : [/MSIE (\S+);/],
          'Opera'  : [
            /Opera\/.*?Version\/(\S+)/, /* Opera 10 */
            /Opera\/(\S+)/                  /* Opera 9 and older */
          ],
          'Safari' : [/Version\/(\S+).*?Safari\//]
        },
        re, m, browser, version;

    if (userAgent === undefined)
      userAgent = navigator.userAgent;

    let elements = 2;

    let browserString = null;
    for (browser in regexps)
      while (re = regexps[browser].shift())
        if (m = userAgent.match(re)) {
          version = (m[1].match(new RegExp('[^.]+(?:\.[^.]+){0,' + --elements + '}')))[0];
          browserString = browser + ' ' + version;
          break;
        }

    this._browser = browserString;
  }

}
