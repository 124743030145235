import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { ModalComponent } from "@app/shared/components/modals/modal/modal.component";
import { IFilter, ISavedFilter, OperatorTranslator } from "../advanced-filters/advanced-filters-modal/advanced-filters-modal.component";

@Component({
  selector: 'st-save-filters-as-view-modal',
  templateUrl: 'save-filters-as-view-modal.component.html',
  styleUrls: ['save-filters-as-view-modal.component.scss']
})
export class SaveFiltersAsViewModalComponent implements OnInit {
  @ViewChild(ModalComponent, { static: true }) modal: ModalComponent;
  data: any;

  @Input()
  public onSave: (value: ISavedFilter) => void | Promise<any> = () => { };

  viewName: string = '';
  filters: IFilter[] = [];
  saving: boolean = false;
  error: string = '';

  constructor(public operatorTranslator: OperatorTranslator) { }

  ngOnInit() {
    const filters = this.data ? this.data.filters : null;

    if (filters) {
      this.filters = filters;
    }
  }

  cancel() {
    this.modal.close();
  }

  save() {
    this.saving = true;

    const result = this.onSave({
      filters: this.filters,
      name: this.viewName,
    }) || Promise.resolve();

    result.then(() => {
      this.modal.close();
      this.saving = false;
    }, (error: Error) => {
      this.error = error.message;
      this.saving = false;
    });
  }
}
