import { Injectable } from "@angular/core";
import { Collection } from "@app/shared/data/base.models";
import { StAction } from "@app/shared/data/st-action";
import { Plan } from "@app/shared/data/plan/plan.models";

@Injectable()
export class PlanActions {
  static readonly TYPES = {
    LOAD          : '[PLAN]LOAD',
    LOAD_SUCCEEDED: '[PLAN]LOAD_SUCCEEDED',
    LOAD_FAILED   : '[PLAN]LOAD_FAILED',
  };

  static load(params: any = {}): StAction {
    return {
      type: PlanActions.TYPES.LOAD,
      data: params,
    }
  }

  static loadSucceeded(plans: Collection<Plan>): StAction {
    return {
      type: PlanActions.TYPES.LOAD_SUCCEEDED,
      data: plans,
    }
  }

  static loadFailed(error: any): StAction {
    return {
      type: PlanActions.TYPES.LOAD_FAILED,
      data: error,
    }
  }
}
