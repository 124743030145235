import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { BaseAPI } from "@app/shared/data/base.api";
import { SessionLog } from "@app/shared/data/session-logs/session-logs.models";
import { Collection } from "@app/shared/data/base.models";

@Injectable()
export class SessionLogsApi extends BaseAPI {

  constructor(protected http: HttpClient,) {
    super();
  }

  public all(promotion: SessionLog, params = {}): Observable<any> {
    return this.http.get<any>(this.constructFullUrl(`api/session-logs`), { params: params });
  }
}
