import { ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { Plan } from '@app/shared/data/plan/plan.models';
import { Collection } from '@app/shared/data/base.models';
import { PaymentService } from '@app/core/services/payment.service';
import { AppState } from '@app/shared/data/app-state.model';
import { NgRedux } from '@angular-redux/store';
import { SubscriptionActions } from '@app/shared/data/subscription/subscription.actions';
import { DialogService } from '@app/shared/components/dialog/dialog.service';
import { Subject } from 'rxjs';
import { Subscription } from '@app/shared/data/subscription/subscription.models';
import { RudderTrackingService } from '@app/shared/tracking/tracking.service';

@Component({
  selector   : 'st-billing-ppcs-plan-change',
  templateUrl: './ppcs-plan-change.component.html',
  styleUrls  : ['./ppcs-plan-change.component.scss'],
})
export class PpcsPlanChangeComponent implements OnDestroy {
  @Input()
  public plans: Collection<Plan>;

  @Input()
  public loading = true;

  @Input()
  public subscribingToPlan = false;

  @Input()
  public cancelingSubscription = false;

  @Input()
  public activeSubscription = null;

  @Input()
  public nextSubscription = null;

  public buttonLoadingPlanId = null;

  private destroy$ = new Subject<void>();

  constructor(private ngRedux: NgRedux<AppState>,
              private ref: ChangeDetectorRef,
              private paymentService: PaymentService,
              private trackingService: RudderTrackingService,
              private dialogService: DialogService,
              private subscriptionActions: SubscriptionActions) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public save(plan: Plan) {
    this.buttonLoadingPlanId = plan.remote_id;

    //noinspection TypeScriptValidateTypes
    this.trackingService.trackEvent('Subscribe PPCS - Button Clicked', {category: 'Billing Page'});
    this.ngRedux.dispatch(this.subscriptionActions.create(plan));
  }

  public cancel(subscription: Subscription) {
    this.buttonLoadingPlanId = subscription.plan_id;

    this.ngRedux.dispatch(this.subscriptionActions.cancel(subscription));
  }
}
